export const fetchAutoJobDetails = (user_id) => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_AUTO_JOB_DETAILS_REQUEST" });
    try {
      const backendUrl = `${process.env.REACT_APP_API_URL}/get_autojob_details`; // Update the backend URL
      const response = await fetch(backendUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id }),
      });
      const data = await response.json();
      dispatch({ type: "FETCH_AUTO_JOB_DETAILS_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "FETCH_AUTO_JOB_DETAILS_FAILURE",
        payload: error.message,
      });
      console.error("Error fetching auto job details:", error.message);
      throw error;
    }
  };
};
