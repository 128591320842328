import { useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import supabase from "components/superBaseConfig";
import AlertModal from "../../../components/AlertModal/index.jsx";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import InactivityPopup from "components/InactivityPopup";
import { getEnvURL } from "utils/getEnvURL.js";

const RecruiterRoboLogo31Icon = styled.img`
  position: absolute;
  top: 0.5px;
  left: -5px;
  width: 79px;
  height: 91px;
  object-fit: cover;
`;
const RecruiterRoboLogo31Wrapper = styled.div`
  position: relative;
  width: 80px;
  height: 92px;
  overflow: hidden;
  flex-shrink: 0;
`;
const WebsiteName = styled.div`
  position: relative;
  line-height: 135.5%;
  font-weight: 600;
  display: inline-block;
  width: 213.3px;
  height: 34.2px;
  flex-shrink: 0;
  color: #314ca3;
  top: 34.5px;
  right: 6.5px;
  font-size: 20.5px;
`;
const FrameParent = styled.div`
  position: absolute;
  top: 50px;
  left: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const SignInTo = styled.div`
  position: relative;
  font-size: 38.44px;
  line-height: 62.47px;
  font-weight: 600;
  text-align: center;
  z-index: 0;
`;
const InputLabelmedium = styled.div`
  position: absolute;
  top: 0%;
  left: calc(50% - 317.15px);
  line-height: 34.84px;
`;

const RectangleParent = styled.div`
  position: absolute;
  top: 36px;
  left: calc(50% - 317.15px);
  width: 634.3px;
  height: 62.5px;
  font-size: 19.22px;
  color: var(--color-darkgray-100);
`;
const Email = styled.div`
  position: relative;
  width: 634.3px;
  height: 98.5px;
  z-index: 1;
`;
const RectangleGroup = styled.div`
  position: absolute;
  top: 36px;
  left: calc(50% - 317.15px);
  width: 634.3px;
  height: 62.5px;
  font-size: 19.22px;
`;
const Email1 = styled.div`
  position: absolute;
  margin: 0 !important;
  height: 51.95%;
  top: 110.23%;
  bottom: -62.18%;
  left: calc(50% - 317.15px);
  width: 634.3px;
  z-index: 2;
`;
const SignInToDhiwiseParent = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 28.14px;
`;
const InputLabelsmall = styled.div`
  position: relative;
  font-size: 21.62px;
  line-height: 34.84px;
  text-align: right;
  cursor: pointer;
`;
const SubmitChild = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 317.15px);
  border-radius: 7.21px;
  background-color: var(--color-darkslateblue);
  width: 634.3px;
  height: 60.1px;
`;
const LogIn = styled.div`
  position: absolute;
  top: 14.4px;
  left: calc(50% - 27.65px);
  line-height: 31.23px;
`;
const Submit = styled.div`
  position: relative;
  width: 634.3px;
  height: 60.1px;
  color: var(--color-white);
`;
const OrContinueWith1 = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 72.05px);
  line-height: 31.23px;
`;
const OrContinueWithChild = styled.div`
  position: absolute;
  top: 15px;
  left: calc(50% + 91.85px);
  background-color: var(--color-silver-100);
  border-top: 1.2px solid var(--color-silver-100);
  box-sizing: border-box;
  width: 216.2px;
  height: 1.2px;
`;
const OrContinueWithItem = styled.div`
  position: absolute;
  top: 15px;
  left: calc(50% - 308.15px);
  background-color: var(--color-silver-100);
  border-top: 1.2px solid var(--color-silver-100);
  box-sizing: border-box;
  width: 217.4px;
  height: 1.2px;
`;
const OrContinueWith = styled.div`
  position: relative;
  width: 615.1px;
  height: 32px;
  text-align: left;
  color: var(--color-silver-100);
`;
const LoginChild = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 317.15px);
  border-radius: 7.21px;
  border: 1.2px solid var(--color-gray-400);
  box-sizing: border-box;
  width: 634.3px;
  height: 60.1px;
`;
const LoginParent = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 317.15px);
  width: 634.3px;
  height: 60.1px;
`;
const Google1Icon = styled.img`
  position: relative;
  width: 28.8px;
  height: 28.8px;
  overflow: hidden;
  flex-shrink: 0;
`;
const SignInWith = styled.div`
  position: relative;
  line-height: 31.23px;
`;
const Google1Parent = styled.div`
  position: absolute;
  top: 14.4px;
  left: calc(50% - 104.85px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7.21px;
`;
const Facebook1Parent = styled.div`
  position: absolute;
  top: 14.4px;
  left: calc(50% - 104.25px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7.21px;
`;
const LoginGroup = styled.div`
  position: absolute;
  top: 79.3px;
  left: calc(50% - 317.15px);
  width: 634.3px;
  height: 60.1px;
`;
const Linkedin11Parent = styled.div`
  position: absolute;
  top: 14.4px;
  left: calc(50% - 104.25px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7.21px;
`;
const LoginContainer = styled.div`
  position: absolute;
  top: 158.6px;
  left: calc(50% - 317.15px);
  width: 634.3px;
  height: 60.1px;
`;
const GroupParent = styled.div`
  position: relative;
  width: 634.3px;
  height: 218.6px;
`;
const InputLabelsmallParent = styled.div`
  position: absolute;
  top: 317.1px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 28.14px;
  text-align: center;
  font-size: 19.22px;
  color: var(--color-gray-400);
`;
const SignUp = styled.span`
  text-decoration: underline;
`;
const DontHaveAnContainer = styled.div`
  position: absolute;
  top: 778.4px;
  left: 122.9px;
  font-size: 24.44px;
  line-height: 135.5%;
  font-family: var(--font-poppins);
  color: var(--color-black);
  cursor: pointer;
`;
const FrameGroup = styled.div`
  position: absolute;
  top: 205.7px;
  left: calc(50% - 317px);
  width: 634.3px;
  height: 811.3px;
  font-size: 21.62px;
  color: var(--color-darkslategray-200);
  font-family: var(--font-inter);
`;
const RecruiterRoboSignInlogInRoot = styled.div`
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  height: 100vh;
  overflow: scroll;
  text-align: left;
  font-size: 26.06px;
  color: var(--color-gray-300);
  font-family: var(--font-poppins);
`;

const TabRecruiterRoboSignInlogIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [type, setType] = useState("password");
  const [showInactivityPopup, setShowInactivityPopup] = useState(false);
  const [inactivityPopupContent, setInactivityPopupContent] = useState("");
  const [inactivityPopupHeading, setInactivityPopupHeading] = useState("");
  const [inactivityPopupSubtext, setInactivityPopupSubtext] = useState("");
  const [icon, setIcon] = useState(eyeOff);
  const changeVisibility = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  useEffect(() => {
    const accessToken = Cookies.get('accessToken');
    if (!accessToken) {
      localStorage.removeItem('lastActivityTime');
    }
  }, []);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get("loggedOutDueToInactivity") === "true") {
      setInactivityPopupHeading("Session Inactive");
      setInactivityPopupSubtext(
        "You have been logged out due to inactivity.",
      );
      setShowInactivityPopup(true);
    } else if (queryParams.get("loggedOutDueToSessionInvalidity") === "true") {
      setInactivityPopupHeading("Session Invalid");
      setInactivityPopupSubtext(
        "You have been logged out because your account was accessed from a new browser, device, or IP address.",
      );
      setShowInactivityPopup(true);
    }
  }, [location.search]);

  // useEffect(() => {
  //   const loggedOutDueToInactivity = localStorage.getItem("loggedOutDueToInactivity");
  //   if (loggedOutDueToInactivity) {
  //     setShowInactivityPopup(true);
  //     localStorage.removeItem("loggedOutDueToInactivity");
  //   }
  // }, []);

  const onInputLabelSmallTextClick = useCallback(() => {
    navigate("/forgotpassword");
  }, [navigate]);

  const onDontHaveAnClick = useCallback(() => {
    navigate("/signup");
  }, [navigate]);

  const onLogo = useCallback(() => {
    navigate("/");
  }, [navigate]);
  const frontEndURL = getEnvURL();
  const onGmailLogin = useCallback(async () => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        queryParams: {
          access_type: "offline",
          prompt: "consent",
        },
        redirectTo: `${frontEndURL}/jobseeker-dashboard`,
      },
    });

    localStorage.removeItem('lastActivityTime');

    const receivedData = data;
    const receivedError = error;

    if (receivedError) {
      navigate("/login");
    } else {
      //alert(JSON.stringify(receivedData));
      const accessToken = JSON.stringify(receivedData);
      Cookies.set("accessToken", accessToken, { expires: 7 });
    }
  });

  const onLinkedinLogin = useCallback(async () => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'linkedin_oidc',
      options: {
        queryParams: {
          access_type: "offline",
          prompt: "consent",
        },
        redirectTo: `${frontEndURL}/jobseeker-dashboard`,
      },
    });

    localStorage.removeItem('lastActivityTime');

    if (error) {
      navigate("/login");
    } else {
      const accessToken = JSON.stringify(data);
      Cookies.set("accessToken", accessToken, { expires: 7 });
    }
  }, [navigate]);

  const onFacebookLogin = useCallback(async () => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: "facebook",
      options: {
        queryParams: {
          access_type: "offline",
          prompt: "consent",
        },
        redirectTo: `${frontEndURL}/jobseeker-dashboard`,
      },
    });

    localStorage.removeItem('lastActivityTime');
    if (error) {
      navigate("/login");
    } else {
      const accessToken = JSON.stringify(data);
      Cookies.set("accessToken", accessToken, { expires: 7 });
    }
  }, [navigate]);

  const [error, setError] = useState(false);
  const [loadingVal, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      // alert(email);
      // alert(password);

      try {
        const { data, error } = await supabase.auth.signInWithPassword({
          email: email,
          password: password,
        });

        localStorage.removeItem('lastActivityTime');

        // alert("Data returned is" + JSON.stringify(data));
        // alert("User data is " + JSON.stringify(supabase.auth.getUser()));
        if (!error) {
          //alert("Data returned is" + JSON.stringify(data));
          const userId = data.user.id;
          const accessToken = JSON.stringify(data.session.access_token);

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/create-session`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                userId: userId,
              }),
            },
          );

          const sessionData = await response.json();
          const sessionId = sessionData.sessionId;

          Cookies.set("accessToken", accessToken, { expires: 7 });
          Cookies.set("sessionId", sessionId, { expires: 7 });

          setLoading(false);
          navigate("/jobseeker-dashboard");
          window.location.href = `${frontEndURL}/jobseeker-dashboard`;
        } else {
          ////console.log("loading is set false");
          setLoading(false);
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 3000);
          // alert("Please try again");
        }
      } catch (error) {
        setLoading(false);
        ////console.log("loading is set false");
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
        // alert(error);
      }
    },
    [email, password],
  );

  const onLogin = useCallback(
    (e) => {
      setLoading(true);
      ////console.log("loading is set true");
      handleSubmit(e);
    },
    [navigate, handleSubmit],
  );

  const handleClosePopup = () => {
    setShowInactivityPopup(false);
  };

  return (
    <RecruiterRoboSignInlogInRoot>
      {loadingVal && <AlertModal msg="loading" />}
      {error && <AlertModal msg="error" />}
      {showInactivityPopup && (
        <InactivityPopup
          isOpen={showInactivityPopup}
          onClose={handleClosePopup}

          heading={inactivityPopupHeading}
          subtext={inactivityPopupSubtext}
        />
      )}
      <FrameParent>
        <RecruiterRoboLogo31Wrapper>
          <RecruiterRoboLogo31Icon
            alt=""
            src="/recroid.png"
            onClick={onLogo}
            style={{ cursor: "pointer" }}
          />
        </RecruiterRoboLogo31Wrapper>
        <WebsiteName
          onClick={onLogo}
          style={{ cursor: "pointer", color: "#314CA3" }}
        >
          RecRoid
        </WebsiteName>
      </FrameParent>
      <form onSubmit={onLogin}>
        <FrameGroup>
          <SignInToDhiwiseParent>
            <SignInTo>Sign in to RecRoid</SignInTo>
            <Email>
              <InputLabelmedium>Email</InputLabelmedium>
              <RectangleParent>
                <input
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "calc(50% - 317.15px)",
                    borderRadius: "9.61px",
                    backgroundColor: "var(--color-white)",
                    border: "1.2px solid var(--color-gainsboro)",
                    boxSizing: "border-box",
                    width: "634.3px",
                    height: "62.5px",
                  }}
                  type="email"
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  placeholder="Enter Email"
                  value={email}
                  required
                />
              </RectangleParent>
            </Email>
            <Email1>
              <InputLabelmedium>Password</InputLabelmedium>
              <RectangleGroup>
                <div className="pass">
                  <input
                    style={{
                      position: "absolute",
                      top: "0px",
                      left: "calc(50% - 317.15px)",
                      borderRadius: "9.61px",
                      backgroundColor: "var(--color-white)",
                      border: "1.2px solid var(--color-gainsboro)",
                      boxSizing: "border-box",
                      width: "634.3px",
                      height: "62.5px",
                    }}
                    type={type}
                    placeholder="Enter Password"
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                    value={password}
                    required
                  />
                  <div
                    style={{ position: "absolute", left: 580, top: 19 }}
                    className="flex  justify-end items-center pl-3 !border-colors !rounded-[12px] "
                    onClick={changeVisibility}
                  >
                    <Icon icon={icon} size={23} style={{ color: "grey" }} />
                  </div>
                </div>
              </RectangleGroup>
            </Email1>
          </SignInToDhiwiseParent>
          <InputLabelsmallParent>
            <InputLabelsmall
              onClick={onInputLabelSmallTextClick}
              style={{ cursor: "pointer" }}
            >
              Forgot Password?
            </InputLabelsmall>

            <button type="submit">
              <Submit style={{ cursor: "pointer" }}>
                <SubmitChild />
                <LogIn>Log in</LogIn>
              </Submit>
            </button>

            <OrContinueWith>
              <OrContinueWith1>Or continue with</OrContinueWith1>
              <OrContinueWithChild />
              <OrContinueWithItem />
            </OrContinueWith>
            <GroupParent>
              <LoginParent style={{ cursor: "pointer" }}>
                <LoginParent>
                  <LoginChild />
                </LoginParent>
                <Google1Parent
                  onClick={onGmailLogin}
                  style={{ cursor: "pointer" }}
                >
                  <Google1Icon alt="" src="/google-1.svg" />
                  <SignInWith>Sign in with Google</SignInWith>
                </Google1Parent>
              </LoginParent>
              <LoginGroup>
                <LoginParent style={{ cursor: "pointer" }}>
                  <LoginChild />
                </LoginParent>
                <Facebook1Parent
                  onClick={onFacebookLogin}
                  style={{ cursor: "pointer" }}
                >
                  <Google1Icon alt="" src="/facebook-1.svg" />
                  <SignInWith>Sign in with Facebook</SignInWith>
                </Facebook1Parent>
              </LoginGroup>
              <LoginContainer>
                <LoginParent style={{ cursor: "pointer" }}>
                  <LoginChild />
                </LoginParent>
                <Linkedin11Parent
                  onClick={onLinkedinLogin}
                  style={{ cursor: "pointer" }}
                >
                  <Google1Icon alt="" src="/linkedin-1-1.svg" />
                  <SignInWith>Sign in with Linkedin</SignInWith>
                </Linkedin11Parent>
              </LoginContainer>
            </GroupParent>
          </InputLabelsmallParent>
          <DontHaveAnContainer onClick={onDontHaveAnClick}>
            {`Don’t have an account? `}
            <SignUp>Sign up</SignUp>
          </DontHaveAnContainer>
        </FrameGroup>
      </form>
    </RecruiterRoboSignInlogInRoot>
  );
};

export default TabRecruiterRoboSignInlogIn;
