import PropTypes from "prop-types";
import { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HamburgerMenu from "components/HamburgerMenu";
import "./Container.css"

const Container = ({ className = "", mainhead, subtext }) => {
  const navigate = useNavigate();
  const onLogo = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onButtonContainerClick = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  // const style = {
  //   backgroundImage: "linear-gradient(-11.22deg, #F9F1FF 45%, rgba(190, 225, 236, 0.63) 55%)",
  //   height: '100%', // Example height
  //   width: '100%', // Example width
  // };
  return (
    <section
      className={`self-stretch flex flex-col items-start justify-start max-w-full shrink-0 text-center text-2[24px]-4 text-black font-montserrat-alternates ${className}`}
    >
      <header className="self-stretch h-[125px] flex flex-row items-start justify-start pt-[22px] pb-[24.8px] pr-[30px] pl-8 box-border gap-[14px] max-w-full text-center text-mid-2 text-black font-poppins">
        <div className="h-[125px] w-[1440px] relative bg-white hidden max-w-full" />
        <div className="self-stretch w-[95.3px] flex flex-col items-start justify-start py-0 pr-3 pl-0 box-border relative top-[-7px]">
          <div className="self-stretch flex-1  flex flex-col items-center justify-center py-[6.2px] px-[5px] z-[1]">
            <img
              className=" cursor-pointer  self-stretch flex-1 relative max-w-full  max-h-full object-contain w-[59px] h-[69px]"
              loading="lazy"
              alt=""
              src="/recroid.png"
              onClick={onLogo}
            />
          </div>
        </div>
        <div className="flex-1 flex flex-col items-start justify-start pt-[10.7px] px-0 pb-0 box-border max-w-full">
          <div className="w-[422.6px] flex flex-row items-start justify-between gap-[20px] max-w-full "  >
              <b className=" cursor-pointer  h-[56.9px] w-[73.7px] relative text-[18px] leading-[135.5%] font-semibold text-[#314CA3] relative right-[42px] top-[17px] inline-block shrink-0 z-[1] mqmob1:right-[30px]  mqmob1:top-[15.5px]">
              RecRoid
            </b>
            {/* <div className="flex flex-col items-start justify-start pt-[16.3px] px-0 pb-0">
              <div className="cursor-pointer  text-[#314CA3] relative leading-[135.5%] font-medium inline-block min-w-[68px] whitespace-nowrap z-[1]">Products</div>
            </div>
            <div className="flex flex-col items-start justify-start pt-[16.3px] px-0 pb-0">
              <div className="cursor-pointer  text-[#314CA3] relative leading-[24px] font-medium inline-block min-w-[60px] z-[1]">
                Pricing
              </div>
            </div>
            <div className="flex flex-col items-start justify-start pt-[16.3px] px-0 pb-0">
              <a href="/about-us">
                <div className=" text-[#314CA3] relative leading-[24px] font-medium inline-block min-w-[78px] whitespace-nowrap z-[1]">
                  About us
                </div>
              </a>
            </div> */}
          </div>
        </div>
        
        <div className="hamburger">
          <HamburgerMenu/>
        </div>
       
        <div

          className=" text-xl font-medium right-fields flex flex-row justify-between w-[410px] gap-[2px] dashboards"
        >
          <div
            className="overlay w-[26px] h-[25px]   absolute top-[-8px] left-[-57px] z-20 transform rotate-[-78deg]"
            style={{ backgroundColor: "white" }}
          ></div>
          <img
            src="/comingSoon4.png"
            alt=""
            className=" transform rotate-0 w-[115px] h-[39px]  absolute  top-[1.5px] left-[-53px] z-10"
          />

          <Link to="/recruiter-login" className="company">
            <div
              //  className="job-seeker "
              style={{
                border: "2px solid #314ca3",
                color: "#314ca3",
                padding: 12,
                left: -37,
                paddingLeft: 60,
              }}
              // style={{
              //   border: "2px solid #314ca3",
              //   color: "#314ca3",
              // padding: 15,
              //   borderRadius: 10,
              //   paddingLeft: 60,
              //   left: -20 }}

              className="job-seekers landingpage  hover:bg-gray-50 relative rounded-[8px]  "
            >
              Employer
            </div>
          </Link>
          <div
            className="job-seeker rounded-[8px]"
            onClick={onButtonContainerClick}
            style={{ border: "2px solid #314ca3", padding: 14 }}
          >
            <div
              style={{ color: "#314ca3" }}
              className="job-seekers landingpage"
            >
              Job seekers
            </div>
          </div>
        </div>
      </header>
      <div className="self-stretch bg-gradient-to-r from-[#abfbff] from-100% via-[#BEE1EC] via-13% to-[#F9F1FF] to-0% overflow-hidden flex flex-col items-start justify-start pt-[57px] pb-[47px] pr-5 pl-[111px] box-border gap-[3px] max-w-full z-[4] mt-[-13px] mq450:pl-5 mq450:box-border mqmob1:pl-[55px] mqmob1:box-border" >
        <div className="flex flex-row items-start justify-start py-0 px-1">
          <b className="relative leading-[54px] text-[49px] z-[5] mq450:text-[27px] mq450:leading-[32px] mqmob1:text-17xl mqmob1:leading-[43px] ">
            {mainhead}
          </b>
        </div>
        <div className="relative left-[6px] text-[24px] leading-[29px] text-left inline-block max-w-full z-[5] mq450:text-lgi mq450:leading-[23px] mqmob1:text-lgi mqmob1:leading-[23px]">
          {subtext}
        </div>
      </div>
    </section>
  );
};

Container.propTypes = {
  className: PropTypes.string,
};

export default Container;
