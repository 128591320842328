import PropTypes from "prop-types";
import { useMemo } from "react";

const FrameComponent1 = ({
  className = "",
  selectDate,
  uiwdate,
  propHeight,
  propWidth,
  onChange, // Add onChange prop
}) => {
  const uiwdateIconStyle = useMemo(() => {
    return {
      height: propHeight,
      width: propWidth,
    };
  }, [propHeight, propWidth]);
  const today = new Date().toISOString().split("T")[0];

  const handleDateChange = (e) => {
    const date = e.target.value;
    onChange(date); // Call onChange with the selected date
  };

  return (
    <div
      className={`flex-1 flex flex-col items-start justify-start gap-[17px] min-w-[326px] max-w-full text-center text-[24px] text-black font-montserrat-alternates ${className}`}
    >
      <div className="flex flex-row items-start justify-start gap-[6px]">
        <div className="relative leading-[29px] font-medium z-[1] mq450:text-lgi mq450:leading-[23px]">
          {selectDate}
        </div>
        <div className="flex flex-col items-start justify-start pt-[11px] px-0 pb-0">
          <div className="w-[7px] h-[7px] relative rounded-[50%] bg-crimson z-[1]" />
        </div>
      </div>
      <input
        min={today}
        className="h-[67px] mqtab1:h-[3.5rem] mqtab2:h-[3.2rem]  mqmob1:h-[3.2rem] w-[501px] relative rounded-3xs bg-whitesmoke box-border  max-w-full border-[1px] border-solid border-darkgray text-[19px] pl-[1.3rem]"
        type="date"
        onChange={handleDateChange} // Add onChange handler
      />
    </div>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
  selectDate: PropTypes.string,
  uiwdate: PropTypes.string,

  /** Style props */
  propHeight: PropTypes.any,
  propWidth: PropTypes.any,
  onChange: PropTypes.func.isRequired, // Ensure onChange is required
};

export default FrameComponent1;
