import { combineReducers } from "redux";
import careerReducer from "./careerAdvice";
import jobSearchReducer from "./jobSearch";
import jobSeekerDashboard from "./jobSeekerDashboard";
import profilePersonalData from "./profilePersonalData";
import interviewPrepData from "./interviewPrepData";
import autoJobData from "./autoJobData";
import profilePreferenceData from "./profilePreferenceData";
import notificationsReducer from "./notificationsReducer";
import profileAccountType from "./profileAccountType";
import paymentIntentReducer from "./paymentIntent";
import profileProfessionalReducer from "./profileProfessional";
import profileGetlinksReducer from "./profileGetlinks";
import profileUploadLinks from "./profileUploadLinks";
import profilePictureReducer from "./profilePicture";
import subscriptionReducer from "./subscriptionData";


export default combineReducers({
    jobSeekerDashboard,
    careerReducer,
    jobSearchReducer,
    profileAccountType,
    profilePersonalData,
    profilePreferenceData,
    notificationsReducer,
    profileUploadLinks,
    profileProfessionalReducer,
    profileGetlinksReducer,
    interviewPrepData,
    autoJobData,
    paymentIntentReducer,
    profilePictureReducer,
    subscriptionReducer
})