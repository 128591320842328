import { Line, Text } from "components";
import { Link } from "react-router-dom";

const ProfileHeader2 = (props) => {
  const { activeTab } = props;

  return (
    <div className="flex flex-row sm:gap-10 items-start justify-between pr-3 w-[60%] mqtab1:w-[70%] md:w-full overflow-x-auto scrollbar-hidden-personal">
      <div>
        <Link to="/profile-personal">
          <Text
            className={`sm:text-sm mqlap:text-[1.12vw] mqtab1:text-[1.17vw] ${
              activeTab === "personal"
                ? "text-indigo-600"
                : "text-blue_gray-400"
            } text-xl`}
            size="txtInterMedium20"
          >
            Personal
          </Text>
        </Link>
        {activeTab === "personal" ? (
          <Line className="bg-indigo-600 h-[3px] rounded-tl-[10px] rounded-tr-[10px] w-full" />
        ) : (
          ""
        )}
      </div>

      <div>
        <Link to="/profile-preferences">
          <Text
            className={ 
              activeTab === "preferences"
                ? "text-indigo-600 text-xl sm:text-sm mqlap:text-[1.12vw] mqtab1:text-[1.17vw]"
                : "text-blue_gray-400 text-xl sm:text-sm mqlap:text-[1.12vw] mqtab1:text-[1.17vw]"
            }
            size="txtInterMedium20"
          >
            Preferences
          </Text>
        </Link>
        {activeTab === "preferences" && (
          <Line className="bg-indigo-600 h-[3px] rounded-tl-[10px] rounded-tr-[10px] w-full" />
        )}
      </div>

      <div>
        <Link to="/profile-upload">
          <Text
            className={
              activeTab === "upload"
                ? "text-indigo-600 text-xl sm:text-sm mqlap:text-[1.12vw] mqtab1:text-[1.17vw]"
                : "text-blue_gray-400 text-xl sm:text-sm mqlap:text-[1.12vw] mqtab1:text-[1.17vw]"
            }
            size="txtInterMedium20Indigo600"
          >
            Upload
          </Text>
        </Link>
        {activeTab === "upload" ? (
          <Line className="bg-indigo-600 h-[3px] rounded-tl-[10px] rounded-tr-[10px] w-full" />
        ) : (
          ""
        )}
      </div>

      <div>
        <Link to="/profile-professional">
          <Text
            className={
              activeTab === "professional"
                ? "text-indigo-600 text-xl sm:text-sm mqlap:text-[1.12vw] mqtab1:text-[1.17vw]"
                : "text-blue_gray-400 text-xl sm:text-sm mqlap:text-[1.12vw] mqtab1:text-[1.17vw]"
            }
            size="txtInterMedium20"
          >
            Professional
          </Text>
        </Link>
        {activeTab === "professional" ? (
          <Line className="bg-indigo-600 h-[3px] rounded-tl-[10px] rounded-tr-[10px] w-full" />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export { ProfileHeader2 };
