import Sidebar1 from "pages/RecruiterDashBoardPages/RecruiterSidebar";
import { Button, List, Text } from "components";
import BillingSubHeader from "pages/RecruiterDashBoardPages/BillingSubHeaderRecruiter";
import TopHeader from "pages/RecruiterDashBoardPages/TopHeader";
import React,{useState} from "react";


const BillingPreferencesRecruiter = () => {

  const [billingCompanyName, setBillingCompanyName] = useState('');
  const [billingEmail, setBillingEmail] = useState('');
  const [billingCountry, setBillingCountry] = useState('');
  const [billingAddress1, setBillingAddress1] = useState('');
  const [billingAddress2, setBillingAddress2] = useState('');
  const [billingPincode, setBillingPincode] = useState('');
  const [billingPONumber, setBillingPONumber] = useState('');

const saveBillingData=()=>{

  //backend code here 
  //console.log(billingCompanyName,billingEmail,billingCountry,billingAddress1,billingAddress2,billingPincode,billingPONumber)
}
  return (
    <>
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <Sidebar1 clickedTab={"Billing"} />

          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
            <TopHeader header={"Billing"} />

            <div className="bg-white-A700 flex flex-col items-center justify-start p-7 sm:px-5 rounded-[15px] w-[94%] md:w-full">
              <div className="flex flex-col gap-[43px] justify-start mb-[199px] mt-2.5 w-[99%] md:w-full">
                <BillingSubHeader billingheader={4} />

                <div className="flex flex-col gap-10 items-start justify-start mb-[75px] ml-1.5 md:ml-[0] w-full">
                  <Text
                    className="text-gray-500 text-xl w-[88%] sm:w-full"
                    size="txtInterMedium20"
                  >
                    Changes to these preferences will apply to future invoices
                    only. If you need a past invoice reissued please contact at
                    roborecruiter@gmail.com
                  </Text>
                  <div className="flex flex-col gap-[41px] items-start justify-start w-full md:w-full">
                    <div className="flex flex-col gap-8 items-start justify-start w-full md:w-full">
                      <List
                        className="sm:flex-col flex-row  grid md:grid-cols-1 grid-cols-2 justify-start w-full md:w-full"
                        orientation="horizontal"
                      >
                        <div className="flex flex-col items-start justify-between w-full">
                          <Text
                            className="text-base text-black-900"
                            size="txtInterMedium16Black900"
                          >
                            Company name
                          </Text>
                          <Text
                            className="mt-1.5 text-blue_gray-400 text-sm w-[85%] sm:w-full"
                            size="txtInterRegular14"
                          >
                            If specified, this name will appear on invoices
                            instead of your organisation name.
                          </Text>
                          <input
                           value={billingCompanyName}
                           onChange={(e) => setBillingCompanyName(e.target.value)}
                            name="addressCounter_One"
                            placeholder="Company"
                            className="leading-[normal] p-3 placeholder:text-blue_gray-900 text-[15px] text-left border border-gray-300 border-solid mt-4 w-[79%] rounded-[12px]"
                          />
                        </div>
                        <div className="flex flex-col items-start justify-between w-full">
                          <Text
                            className="text-base text-black-900"
                            size="txtInterMedium16Black900"
                          >
                            Billing email
                          </Text>
                          <Text
                            className="mt-1.5 text-blue_gray-400 text-sm w-[79%] sm:w-full"
                            size="txtInterRegular14"
                          >
                            Invoices and other billing notifications will be
                            sent here (in addition to being sent to the owners
                            of your organisation).
                          </Text>
                          <div className="h-[47px] md:h-[65px] mt-[18px] relative w-[100%] sm:w-full">
                            <input
                             value={billingEmail}
                             onChange={(e) => setBillingEmail(e.target.value)}
                              type="email"
                              name="company name"
                              placeholder="roborecruiter@gmail.com"
                              className="leading-[normal] p-3 placeholder:text-blue_gray-900 text-[15px] text-left border border-gray-300 border-solid w-[79%] rounded-[12px]"
                            />
                          </div>
                        </div>
                      </List>

                      <div className="sm:flex-col flex-row  grid md:grid-cols-1 grid-cols-2 justify-start w-full md:w-full">
                        <div className="flex flex-col items-start justify-start w-full md:w-full">
                          <Text
                            className="text-base text-black-900"
                            size="txtInterMedium16Black900"
                          >
                            Primary business address
                          </Text>
                          <Text
                            className="mt-1.5 text-blue_gray-400 text-sm w-[79%]"
                            size="txtInterRegular14"
                          >
                            This is the physical address of the company
                            purchasing Roborecruiter services and is used to
                            calculate any applicable sales tax.
                          </Text>
                          <input
                           value={billingCountry}
                           onChange={(e) => setBillingCountry(e.target.value)}
                            name="group195"
                            placeholder="Country"
                            className="leading-[normal] p-3 placeholder:text-blue_gray-900 text-[15px] text-left border border-gray-300 border-solid mt-[18px] w-[79%] rounded-[12px]"
                          />
                          <input
                           value={billingAddress1}
                           onChange={(e) => setBillingAddress1(e.target.value)}
                            name="addressCounter"
                            placeholder="Address 1"
                            className="leading-[normal] p-3 placeholder:text-blue_gray-900 text-[15px] text-left border border-gray-300 border-solid mt-4  w-[79%] rounded-[12px]"
                            //wrapClassName="border border-gray-300 border-solid mt-4  w-[79%]"
                          />
                          <input
                          value={billingAddress2}
                          onChange={(e) => setBillingAddress2(e.target.value)}
                            name="addressCounter_One"
                            placeholder="Address 2"
                            className="leading-[normal] p-3 placeholder:text-blue_gray-900 text-[15px] text-left border border-gray-300 border-solid mt-4  w-[79%] rounded-[12px]"
                          />
                          <input
                          value={billingPincode}
                          onChange={(e) => setBillingPincode(e.target.value)}
                            name="group198"
                            placeholder="Pincode"
                            className="leading-[normal] p-3 placeholder:text-blue_gray-900 text-[15px] text-left border border-gray-300 border-solid mt-4  w-[79%] rounded-[12px]"
                            type="number"
                          />
                        </div>
                        <div className="flex flex-col items-start justify-start w-full md:w-full">
                          <Text
                            className="text-base text-black-900"
                            size="txtInterMedium16Black900"
                          >
                            Purchase order (PO) number
                          </Text>
                          <Text
                            className="mt-[9px] text-blue_gray-400 text-sm"
                            size="txtInterRegular14"
                          >
                            Your PO will be displayed on future invoices.
                          </Text>
                          <input
                          value={billingPONumber}
                          onChange={(e) => setBillingPONumber(e.target.value)}
                            name="group195_One"
                            placeholder="USA"
                            className="leading-[normal] p-3 placeholder:text-blue_gray-900 text-[15px] text-left border border-gray-300 border-solid mt-4  w-[79%] rounded-[12px]"
                          />
                        </div>
                      </div>
                    </div>
                    <Button onClick={saveBillingData} className="cursor-pointer font-medium leading-[normal] min-w-[190px] text-center text-lg">
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingPreferencesRecruiter;
