import React, { useEffect, useState } from "react";
import { Text } from "components";
import {
  AddCancelSection,
  AddMoreSection,
  EditDeleteIconsSection,
  SaveCancleSection,
  ToggleArrow,
} from "pages/JobSeekerDashBoardPages/ProfileProfessional/IconsComponents";

const ProfessionalAwards = (props) => {
  const { dataOfUser, setAwards, setUserPersonalData,handleDataUpdate } = props;
  const [editedWorkDetails, setEditedWorkDetails] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [selfEdit, setSelfEdit] = useState(false);
  const [editWork, setWorkEdit] = useState(false);
  const [editPosition, setEditPosition] = useState("");
  const [newPosition, setNewPosition] = useState("");
  const [isAddMoreClicked, setIsAddMoreClicked] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      Object.entries(dataOfUser).forEach(([key, text]) => {
        if (key.includes("Awards") && text !== undefined) {
          const editedDetails = Array.isArray(text) ? text : text.split(",");
          setEditedWorkDetails(editedDetails);
          setAwards(editedDetails);
        }
      });
    }, 5000);
  }, [dataOfUser]);

  const toggleDeclaration = () => {
    setWorkEdit(!editWork);
    setSelfEdit(true);
    setEditingIndex(-1);
    setIsAddMoreClicked(false);
  };

  const editItemButton = (index) => {
    setEditingIndex(index);
    setSelfEdit(false);
    setEditPosition(editedWorkDetails[index]);
  };

  const saveChanges = () => {
    if (editingIndex !== -1) {
      const updatedWorkDetails = [...editedWorkDetails];
      updatedWorkDetails[editingIndex] = editPosition;

      setAwards(updatedWorkDetails);
      setEditedWorkDetails(updatedWorkDetails);

      dataOfUser["Awards"] = [...updatedWorkDetails];
      setUserPersonalData(dataOfUser);

      setEditingIndex(-1);
    }
  };

  const handleAddMoreClick = () => {
    setIsAddMoreClicked(!isAddMoreClicked);
  };

  const addNewWorkExperience = () => {
    if (newPosition) {
      const updatedWorkDetails = [...editedWorkDetails];
      updatedWorkDetails.push(newPosition);

      setEditedWorkDetails(updatedWorkDetails);
      setAwards(updatedWorkDetails);

      setNewPosition("");

      dataOfUser["Awards"] = [...updatedWorkDetails];
      setUserPersonalData(dataOfUser);
    }
  };

  const deleteWork = (index) => {
    const updatedWorkDetails = [...editedWorkDetails];
    updatedWorkDetails.splice(index, 1);

    setEditedWorkDetails(updatedWorkDetails);
    setAwards(updatedWorkDetails);

    dataOfUser["Awards"] = [...updatedWorkDetails];
    setUserPersonalData(dataOfUser);

    setIsAddMoreClicked(true);
  };

  const cancelChanges = () => {
    setEditingIndex(-1);
  };

  const cancelAdd = () => {
    setIsAddMoreClicked(false);
  };

  return (
    <div className="bg-white-A700 flex flex-col w-[94%] rounded-[20px] mqlap:text-[1.05vw] mqtab1:text-[1.15vw]">
      <ToggleArrow
        toggleDeclaration={toggleDeclaration}
        imgPath="images/img_tabler_award_filled.svg"
        sectionHeading="Awards"
        editWork={editWork}
      />

      <div>
        {editedWorkDetails.length > 0 && (
          <div className="ml-[3px]">
            {editedWorkDetails.map((work, index) => (
              <div key={index}>
                <div className="flex flex-row justify-between items-center ">
                  {index === editingIndex && !selfEdit ? (
                    <div className="flex flex-row justify-between w-[80%] gap-[9px] items-center mb-[3px] md:ml-[0] sm:ml-[31px] ml-[75px] md:px-5 md:w-full">
                      <div class="w-full sm:ml-[31px] sm:flex sm:flex-col">
                      <input
                        placeholder="Award"
                        className="work-edit-input  sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                        value={editPosition}
                        onChange={(e) => {setEditPosition(e.target.value);handleDataUpdate()}}
                        required
                      ></input>
                      </div>

                      <SaveCancleSection
                        saveChanges={saveChanges}
                        cancleChanges={cancelChanges}
                      />
                    </div>
                  ) : (
                    <div className="w-[80%]">
                      {editWork ? (
                        <Text className="pl-[90px]  sm:text-[10px] break-words overflow-hidden mqlap:text-[0.95vw] mqtab1:text-[0.95vw]">
                          {work}
                        </Text>
                      ) : null}
                    </div>
                  )}
                  {index !== editingIndex &&
                    editWork &&
                    editedWorkDetails.length >= 1 && (
                      <EditDeleteIconsSection
                        index={index}
                        editItemButton={editItemButton}
                        deleteWork={deleteWork}
                      />
                    )}
                </div>
                {editWork && <div className="rectangle" />}
              </div>
            ))}
          </div>
        )}
        {!isAddMoreClicked && editWork && (
          <AddMoreSection handleAddMoreClick={handleAddMoreClick} />
        )}
        {isAddMoreClicked && editWork && (
          <AddCancelSection
          handleDataUpdate={handleDataUpdate}
            newPosition={newPosition}
            setNewPosition={setNewPosition}
            addNewWorkExperience={addNewWorkExperience}
            cancelAdd={cancelAdd}
            page="Awards"
          />
        )}
      </div>
    </div>
  );
};

export default ProfessionalAwards;
