import { useCallback } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const RecruiterRoboLogo31Icon = styled.img`
  position: absolute;
  top: 8.1px;
  left: 0px;
  width: 68.5px;
  height: 67.1px;
  object-fit: cover;
`;
const RecruiterRoboLogo31Wrapper = styled.div`
  position: relative;
  width: 68.5px;
  height: 82.5px;
  overflow: hidden;
  flex-shrink: 0;
`;
const WebsiteName = styled.div`
  position: relative;
  color: #161616;
  line-height: 135.5%;
  font-weight: 600;
  display: inline-block;
  width: 150.5px;
  height: 24.1px;
  flex-shrink: 0;
`;
const FrameParent = styled.div`
  position: absolute;
  top: 45px;
  left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const ForgotPassword = styled.div`
  position: relative;
  font-size: 14.62px;
  font-weight: 600;
  color: var(--color-gray-100);
`;
const EnterTheEmail = styled.div`
  position: relative;
  display: inline-block;
  width: 308.9px;
`;
const InputLabelmedium = styled.div`
  position: absolute;
  top: 0%;
  left: calc(50% - 154.5px);
  line-height: 16.97px;
`;
const GroupChild = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 154.5px);
  border-radius: 4.68px;
  background-color: var(--color-white);
  border: 0.6px solid var(--color-gainsboro);
  box-sizing: border-box;
  width: 309px;
  height: 30.4px;
`;
// const InputPlaceholdermediumon = styled.div`
//   position: absolute;
//   top: 25%;
//   left: calc(50% - 147.5px);
//   line-height: 15.22px;
// `;
const RectangleParent = styled.div`
  position: absolute;
  top: 17.6px;
  left: calc(50% - 154.5px);
  width: 309px;
  height: 30.4px;
  font-size: 9.36px;
  color: var(--color-darkgray-100);
`;
const Email = styled.div`
  position: relative;
  width: 309px;
  height: 48px;
  font-size: 10.53px;
  color: var(--color-darkslategray-200);
  font-family: var(--font-inter);
`;
const SubmitChild = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 154.5px);
  border-radius: 3.51px;
  background-color: var(--color-darkslateblue);
  width: 309px;
  height: 29.3px;
`;
const LogIn = styled.div`
  position: absolute;
  top: calc(50% - 7.85px);
  left: calc(50% - 15.6px);
  line-height: 15.22px;
`;
const Submit = styled.div`
  position: relative;
  width: 309px;
  height: 29.3px;
  cursor: pointer;
  text-align: center;
  font-size: 9.36px;
  color: var(--color-white);
  font-family: var(--font-inter);
`;
const ForgotPasswordParent = styled.div`
  position: absolute;
  top: 174px;
  left: calc(50% - 154.5px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 19.19px;
  font-size: 10.05px;
  color: var(--color-darkslategray-100);
`;
const ResetPasswordemailAddressRoot = styled.div`
  position: fixed;
  background-color: var(--color-whitesmoke);
  width: 100%;
  height: 100vh;
  overflow: hidden;
  text-align: left;
  font-size: 18.39px;
  color: var(--color-gray-300);
  font-family: var(--font-poppins);
`;

const UniversityMobResetPasswordemailAddress = () => {
  const navigate = useNavigate();

  const onSubmitContainerClick = useCallback(() => {
    navigate("/newpassword");
  }, [navigate]);
  const onLogo = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <ResetPasswordemailAddressRoot>
      <FrameParent>
        <RecruiterRoboLogo31Wrapper>
          <RecruiterRoboLogo31Icon
            alt=""
            src="/recruiter-robo-logo-3-1@2x.png"
            onClick={onLogo}style={{cursor:"pointer"}}
          />
        </RecruiterRoboLogo31Wrapper>
        <WebsiteName onClick={onLogo}style={{cursor: "pointer", color: "rgb(90, 198, 200)"}}>Recruiter Robo</WebsiteName>
      </FrameParent>
      <ForgotPasswordParent>
        <ForgotPassword>Forgot Password?</ForgotPassword>
        <EnterTheEmail>
          Enter the email address you used when you joined and we’ll send you
          link to reset your pasword.
        </EnterTheEmail>
        <EnterTheEmail>
          For security reasons we do NOT store your password. So rest assured
          that we will never send your password via email.
        </EnterTheEmail>
        <Email>
          <InputLabelmedium>Email</InputLabelmedium>
          <RectangleParent>
            <GroupChild />
            <input
                style={{
                  fontSize: "12px",
                  position: "absolute",
                  top: "0px",
                  left: "calc(50% - 151.7px)",
                  borderRadius: "var(--br-8xs-6)",
                  backgroundColor: "var(--color-white)",
                  border: "0.6px solid var(--color-gainsboro)",
                  boxSizing: "border-box",
                  width: "303.4px",
                  height: "29.9px",
                }}
                type="email"
                placeholder="Enter Password"
              />
          </RectangleParent>
        </Email>
        <Submit onClick={onSubmitContainerClick}style={{cursor:"pointer"}}>
          <SubmitChild />
          <LogIn>Submit</LogIn>
        </Submit>
      </ForgotPasswordParent>
    </ResetPasswordemailAddressRoot>
  );
};

export default UniversityMobResetPasswordemailAddress;
