import "./index.css";
const GaugeDashboard = (props) => {
  const { Applications } = props;
  const svgScore = Applications;

  return (
    <div
      
      className="score-wrap"
    >
      <div className="score">
        <div className="score-bar">
          <div className="placeholder">{progressBar(100)}</div>
          <div  className="score-circle">{progressBar(svgScore, true)}</div>
        </div>
        <div  className="score-value">
          <div  className="score-number">{Math.round(svgScore)}</div>
          <div  className="score-name">applications</div>
        </div>
      </div>
    </div>
  );
};

function progressBar(widthPerc, gradient = false) {
  const radius = 87;
  const dashArray = (Math.PI * radius * widthPerc) / 100;

  return (
    <svg width="800" height="820">
      <circle
        cx="100"
        cy="100"
        r={radius}
        fill="none"
        strokeWidth="25"
        strokeDashoffset={-1 * Math.PI * radius}
        strokeDasharray={`${dashArray} 10000`}
        stroke={gradient ? "url(#score-gradient)" : "#5298fe"}
      ></circle>
      {gradient && (
        <defs>
          <linearGradient id="score-gradient">
            <stop offset="100%" stopColor="#8ebcfe" />
          </linearGradient>
        </defs>
      )}
    </svg>
  );
}

export default GaugeDashboard;
