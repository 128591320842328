import { setAccountType } from "actions/AccountType";
import { fetchSubscriptionData } from "actions/SubscriptionData";
import { Text } from "components";
import LogoutPopup from "components/LogoutPopup";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th'; // Covers 11th, 12th, 13th
    switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
    }
};

// Function to format the date into "15th June 2023" format
const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'long' });
    const year = date.getFullYear();
    const ordinalSuffix = getOrdinalSuffix(day);
    return `${day}${ordinalSuffix} ${month} ${year}`;
};

// Function to calculate the days remaining until the trial expires
const calculateDaysLeft = (trialExpireDate) => {
    const now = new Date();
    const expireDate = new Date(trialExpireDate);
    const timeDiff = expireDate - now;
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    return daysLeft > 0 ? daysLeft : 0; // Ensure no negative values
};


const PaidUser = ({ planExpireAt, user_id, planStartedAt, currentPlan }) => {

    const [cancelPopup, setCancelPopup] = useState(false)
    const subscriptionPerMonth = 799;// price including 30% off (base price 940-15%=> 799)
    const subscriptionPerYear = 7655; // price including 30% off (base price 11141-30%=> 7999)
    const formattedStartDate = formatDate(planStartedAt);
    const formattedExpireDate = formatDate(planExpireAt);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const dispatch = useDispatch();
    const handlePlanCancel = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/cancel-subscription`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ user_id }),
            });
            setCancelPopup(false);
            if (response.ok) {

                dispatch(setAccountType(user_id))
                dispatch(fetchSubscriptionData(user_id))
            } else {
                console.error("Failed to cancel subscription");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };


    return (
        <>
            <div className="capitalize	w-[100%] flex flex-col justify-between border border-blue_gray-100 border-solid items-start center mt-[15px] p-[19px] mqlap:py-[16px] mqtab1:py-[17px] rounded-lg border-[#e3e4e8]">
                <div onClick={() => { setIsOpen1(!isOpen1); setIsOpen3(false); setIsOpen2(false) }} className="w-[100%] flex justify-between">
                    <div>
                        <Text className="font-medium text-black-900 text-xl font-semibold sm:text-sm mqlap:text-[1.03vw] mqtab1:text-[1.1vw]">
                            Active Plan - {currentPlan} plan
                        </Text>

                    </div>
                    <div className="flex flex-row justify-center items-center flex gap-[10px]">
                        <Text style={{ color: "#314ca3" }} className="text-lg font-semibold mqlap:text-[1.03vw] mqtab1:text-[1.1vw]">See more plans</Text>
                        {!isOpen1 ? <ChevronDown color="#314ca3" className="h-[2.1rem] w-[2.1rem] mqlap:h-[1.9vw] mqlap:w-[1.9rem] mqtab1:h-[1.65vw] mqtab1:w-[1.65rem]" strokeWidth={4} /> : <ChevronUp color="#314ca3" className="h-[2.1rem] w-[2.1rem] mqlap:h-[1.9vw] mqlap:w-[1.9rem] mqtab1:h-[1.65vw] mqtab1:w-[1.65rem]" strokeWidth={4} />}
                    </div>
                </div>
                {isOpen1 && (
                    <div className="self-end w-[500px] mqtab1:w-[31vw] mqlap:w-[27vw] top-full bg-white border border-blue_gray-100 border-solid p-4 rounded-lg shadow-lg border-[#e3e4e8]">
                        <div className="flex flex-col gap-[5px] mqlap:gap-[3px] mqtab1:gap-[3.5px]">
                            <Text className="text-[19px] font-bold mqlap:text-[1vw] mqtab1:text-[1.03vw]">Monthly Subscription</Text>
                            <Text className="text-[18px] font-medium mqlap:text-[0.9vw] mqtab1:text-[0.9vw]">₹{subscriptionPerMonth} per Month</Text>
                            <Text className="text-sm font-normal mqlap:text-[0.8vw] mqtab1:text-[0.8vw]">Your current membership will continue until {formattedExpireDate} after which you will be charged ₹799/month</Text>
                            {/* <button style={{ borderColor: "#314ca3", color: "#314ca3" }} className="font-bold px-4 py-2 text-white w-full border border-blue_gray-100 border-solid mb-[10px] rounded-md">Subscribe</button> */}
                        </div>
                        <div className="flex flex-col gap-[5px] mt-[10px]">
                            <Text className="text-[19px] font-bold mqlap:text-[1vw] mqtab1:text-[1.03vw]">Yearly Subscription</Text>
                            <Text className="text-[17px] font-medium mqlap:text-[0.9vw] mqtab1:text-[0.9vw]">₹{subscriptionPerYear} per Year</Text>
                            <Text className="text-sm font-normal mqlap:text-[0.8vw] mqtab1:text-[0.8vw]">Your current membership will continue until {formattedExpireDate} after which you will be charged ₹7655/year</Text>
                            {/* <button style={{ borderColor: "#314ca3", color: "#314ca3" }} className="font-bold px-4 py-2 text-white w-full border border-blue_gray-100 border-solid mb-[10px] rounded-md">Subscribe</button> */}
                        </div>
                    </div>
                )}

            </div>

            <div className="capitalize	w-[100%] flex flex-col justify-between border border-blue_gray-100 border-solid items-start center mt-[15px] p-[19px] mqlap:py-[16px] mqtab1:py-[17px] rounded-lg border-[#e3e4e8]">
                <div onClick={() => { setIsOpen3(!isOpen3); setIsOpen2(false); setIsOpen1(false) }} className="w-[100%] flex justify-between">
                    <div>
                        <Text className="font-medium text-black-900 text-xl font-semibold sm:text-sm mqlap:text-[1.03vw] mqtab1:text-[1.1vw]">
                            Manage your subscription
                        </Text>
                    </div>
                    <div className="flex flex-row justify-center items-center flex gap-[10px]">
                        <Text style={{ color: "#314ca3" }} className="text-lg font-semibold mqlap:text-[1.03vw] mqtab1:text-[1.1vw]">Update, Cancel and more</Text>
                        {!isOpen3 ? <ChevronDown color="#314ca3" className="h-[2.1rem] w-[2.1rem] mqlap:h-[1.9vw] mqlap:w-[1.9rem] mqtab1:h-[1.65vw] mqtab1:w-[1.65rem]" strokeWidth={4} /> : <ChevronUp color="#314ca3" className="h-[2.1rem] w-[2.1rem] mqlap:h-[1.9vw] mqlap:w-[1.9rem] mqtab1:h-[1.65vw] mqtab1:w-[1.65rem]" strokeWidth={4} />}
                    </div>
                </div>
                {isOpen3 && (
                    <div className="self-end w-[500px] mqtab1:w-[31vw] mqlap:w-[27vw] bg-white border border-blue_gray-100 border-solid p-4 rounded-lg shadow-lg border-[#e3e4e8]">
                        <div className="flex flex-col gap-[5px]">
                            <Text className="text-[19px] font-bold mqlap:text-[1vw] mqtab1:text-[1.03vw]">Cancel plan</Text>
                            <Text className="text-lg font-medium mqlap:text-[0.9vw] mqtab1:text-[0.9vw]">By canceling your plan you will lose access to your benefits.</Text>
                            <button onClick={() => { setCancelPopup(true) }} style={{ borderColor: "#314ca3", color: "#314ca3" }} className="font-bold px-4 py-2 text-white w-full border border-blue_gray-100 border-solid mb-[10px] rounded-md mqlap:text-[1vw] mqtab1:text-[1.03vw]">Cancel</button>
                        </div>
                        {/* <div className="flex flex-col gap-[5px] mt-[10px]">
                          <Text className="text-2xl font-bold">Upgrade plan on 15th June 2024</Text>
                          <Text className="text-xl font-medium">Upgrade your membership on .</Text>
                          <button style={{ borderColor: "#314ca3", color: "#314ca3" }} className="font-bold px-4 py-2 text-white w-full border border-blue_gray-100 border-solid mb-[10px] rounded-md">Upgrade</button>
                        </div> */}
                    </div>
                )}
            </div>

            <div className="capitalize	 w-[100%] flex flex-col justify-between border border-blue_gray-100 border-solid items-start center mt-[15px] p-[19px] mqlap:py-[16px] mqtab1:py-[17px] rounded-lg border-[#e3e4e8]">
                <div onClick={() => { setIsOpen2(!isOpen2); setIsOpen3(false); setIsOpen1(false) }} className="w-[100%] flex justify-between">
                    <Text className="font-medium text-black-900 text-xl font-semibold sm:text-sm mqlap:text-[1.03vw] mqtab1:text-[1.03vw]">
                        Renewal on  {formattedExpireDate}
                    </Text>

                    <div className="flex flex-row justify-center items-center flex gap-[10px]">
                        <Text style={{ color: "#314ca3" }} className="text-lg font-semibold mqlap:text-[1.03vw] mqtab1:text-[1.03vw]">See more Details</Text>
                        {!isOpen2 ? <ChevronDown color="#314ca3" className="h-[2.1rem] w-[2.1rem] mqlap:h-[1.9vw] mqlap:w-[1.9rem] mqtab1:h-[1.65vw] mqtab1:w-[1.65rem]" strokeWidth={4} /> : <ChevronUp color="#314ca3" className="h-[2.1rem] w-[2.1rem] mqlap:h-[1.9vw] mqlap:w-[1.9rem] mqtab1:h-[1.65vw] mqtab1:w-[1.65rem]" strokeWidth={4} />}
                    </div>
                </div>
                {isOpen2 && (
                    <div className="w-[500px] mqtab1:w-[31vw] mqlap:w-[27vw] top-full self-end bg-white border border-blue_gray-100 border-solid p-4 rounded-lg shadow-lg border-[#e3e4e8]">
                        <div className="flex flex-col gap-[5px]">
                            <Text className="text-[19px] font-bold mqlap:text-[1vw] mqtab1:text-[1.03vw]">Last Payment</Text>
                            <Text className="text-[17px] font-medium mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"> {formattedStartDate}</Text>
                            {/* <button style={{ borderColor: "#314ca3", color: "#314ca3" }} className="font-bold px-4 py-2 text-white w-full border border-blue_gray-100 border-solid mb-[10px] rounded-md">Print Receipt</button> */}
                        </div>
                    </div>
                )}
            </div>

            <LogoutPopup
                goTo="/login"
                isOpen={cancelPopup}
                onClose={() => setCancelPopup(false)}
                onConfirmLogout={handlePlanCancel}
                mainHeadText="Cancel Plan"
                hoverText="Click Yes button to  Cancel your benefits."
                subText=" By canceling your plan you will lose access to your benefits."
                cancelButton="No"
                yesButton="Yes"
            />

        </>)
}
export default PaidUser