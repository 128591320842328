import { Line, Text } from "components";
import { Link } from "react-router-dom";

const BillingSubHeaderRecruiter = (props) => {
  const { billingheader } = props;

  return (
    <div className="flex sm:flex-col flex-row sm:gap-10 items-start justify-between pr-3 w-[60%] md:w-full">
      <div>
        <Text
          className={
            billingheader === 1
              ? "text-indigo-600 text-xl"
              : "text-blue_gray-400 text-xl w-auto"
          }
          size={
            billingheader === 1
              ? "txtInterMedium20"
              : "txtInterMedium20Indigo600"
          }
        >
          <Link to="/recruiter/billing-overview">Overview</Link>
        </Text>
        {billingheader === 1 && (
          <Line className="bg-indigo-600 h-[3px] rounded-tl-[10px] rounded-tr-[10px] w-full" />
        )}
      </div>
      <div className="flex flex-col gap-2 items-center justify-start w-auto">
        <Text
          className={
            billingheader === 2
              ? "text-indigo-600 text-xl"
              : "text-blue_gray-400 text-xl w-auto"
          }
          size={
            billingheader === 2
              ? "txtInterMedium20"
              : "txtInterMedium20Indigo600"
          }
        >
          <Link to="/recruiter/billing-payment">Payment methods</Link>
        </Text>
        {billingheader === 2 && (
          <Line className="bg-indigo-600 h-[3px] rounded-tl-[10px] rounded-tr-[10px] w-full" />
        )}
      </div>
      <div>
        <Text
          className={
            billingheader === 3
              ? "text-indigo-600 text-xl"
              : "text-blue_gray-400 text-xl w-auto"
          }
          size={
            billingheader === 3
              ? "txtInterMedium20"
              : "txtInterMedium20Indigo600"
          }
        >
          <Link to="/recruiter/billing-history">Billing history</Link>
        </Text>
        {billingheader === 3 && (
          <Line className="bg-indigo-600 h-[3px] rounded-tl-[10px] rounded-tr-[10px] w-full" />
        )}
      </div>
      <div>
        <Text
          className={
            billingheader === 4
              ? "text-indigo-600 text-xl"
              : "text-blue_gray-400 text-xl w-auto"
          }
          size={
            billingheader === 4
              ? "txtInterMedium20"
              : "txtInterMedium20Indigo600"
          }
        >
          <Link to="/recruiter/billing-preferences">Preferences</Link>
        </Text>
        {billingheader === 4 && (
          <Line className="bg-indigo-600 h-[3px] rounded-tl-[10px] rounded-tr-[10px] w-full" />
        )}
      </div>
    </div>
  );
};

export default BillingSubHeaderRecruiter;
