import { Img, Text } from "components";
import {
  AddCancelSection2,
  AddMoreSection,
  EditDeleteIconsSection,
  EditIcon,
  SaveCancleSection,
  ShowAllSection,
} from "pages/JobSeekerDashBoardPages/ProfileProfessional/IconsComponents";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import "./index.css";
const WorkExperienceProfessional = (props) => {
  const { dataOfUser, setWorkExperience, setUserPersonalData, handleDataUpdate } = props;

  const [workDetailsList, setWorkDetailsList] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        const newWorkDetailsList = [];
        const newEditedWorkDetails = [];
        const newWorkExperience = [];

        Object.entries(dataOfUser).forEach(([key, experienceText]) => {
          if (key.includes("Organization Name") && experienceText !== undefined) {
            let newItem;
            if (typeof experienceText === "object") {
              const position = experienceText.position || experienceText;
              const company = experienceText.company;
              const description = experienceText.description;
              const startDate = experienceText.startDate;
              const endDate = experienceText.endDate;

              newItem = {
                position,
                company,
                description,
                startDate,
                endDate,
              };
            } else {
              const position = experienceText;
              const company = "";
              const description = "";
              const startDate = "";
              const endDate = "";

              newItem = {
                position,
                company,
                description,
                startDate,
                endDate,
              };
            }

            newWorkDetailsList.push(newItem);
            newEditedWorkDetails.push(newItem);
            newWorkExperience.push(newItem);
          }
        });

        setWorkDetailsList(newWorkDetailsList);
        setEditedWorkDetails(newEditedWorkDetails);
        setWorkExperience(newWorkExperience);

      } catch (error) {
        const newItem = {
          position: "Not Available",
          company: "Not Available",
        };

        setWorkDetailsList((prev) => [...prev, newItem]);
        setEditedWorkDetails((prev) => [...prev, newItem]);
        setWorkExperience((prev) => [...prev, newItem]);
      }
    }, 5000);

    return () => clearTimeout(timer); // Cleanup function to clear the timer
  }, [dataOfUser]);


  const [editingIndex, setEditingIndex] = useState(-1);
  const [selfEdit, setSelfEdit] = useState(false);
  const [showAllDetails, setShowAllDetails] = useState(false);
  const [editWork, setWorkEdit] = useState(false);
  const [editCompany, setEditedCompany] = useState("");
  const [editPosition, setEditPosition] = useState("");
  const [editdescription, setEditdescription] = useState("");
  const [editStartDate, setStartDate] = useState("");
  const [editEndDate, setEndDate] = useState("");

  const [editedWorkDetails, setEditedWorkDetails] = useState([
    ...workDetailsList,
  ]);
  const [newCompany, setNewCompany] = useState("");
  const [newPosition, setNewPosition] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [newStartDate, setNewstartDate] = useState("");
  const [newEndDate, setNewEndDate] = useState("");
  const [isAddMoreClicked, setIsAddMoreClicked] = useState(false);

  const toggleShowAll = () => {
    setShowAllDetails(!showAllDetails);
    // //console.log(workDetailsList)
  };

  const [workInitial, setWorkInitial] = useState(false);

  const workExpInitially = () => {
    setWorkEdit(false);
    setWorkInitial(!workInitial);
    setShowAllDetails(false);
    setSelfEdit(true);
    setEditingIndex(-1);
    setIsAddMoreClicked(false);
  };

  const editWorkButton = () => {
    setWorkEdit(true);
    setShowAllDetails(true);
    setSelfEdit(true);
    ////console.log("workDetailsList", workDetailsList)
  };

  const editItemButton = (index) => {
    setEditingIndex(index);
    setSelfEdit(false);
    setEditedCompany(workDetailsList[index].company);
    setEditPosition(workDetailsList[index].position);
    setEditdescription(workDetailsList[index].description);
    setStartDate(workDetailsList[index].startDate);
    setEndDate(workDetailsList[index].endDate);
  };

  const saveChanges = () => {
    if (editingIndex !== -1) {
      const updatedWorkDetails = [...editedWorkDetails];
      updatedWorkDetails[editingIndex] = {
        company: editCompany,
        position: editPosition,
        description: editdescription,
        startDate: editStartDate,
        endDate: editEndDate,
      };


      setEditedWorkDetails(updatedWorkDetails);
      setWorkDetailsList(updatedWorkDetails);
      setWorkExperience(updatedWorkDetails);

      if (updatedWorkDetails.length < 4) {
        const dataOfUserCopy = { ...dataOfUser };

        for (let i = 0; i < updatedWorkDetails.length && i < 3; i++) {
          dataOfUserCopy[`Role ${i + 1} with Organization Name`] = updatedWorkDetails[i];
        }

        setUserPersonalData(dataOfUserCopy);
      }

      setEditingIndex(-1);

    }
  };


  const handleAddMoreClick = () => {
    setIsAddMoreClicked(!isAddMoreClicked);
  };

  const newCompany1 = (e) => {
    setNewCompany(e.target.value);
    handleDataUpdate()
  };

  const newPosition1 = (e) => {
    setNewPosition(e.target.value);
    handleDataUpdate()
  };
  const newDescription1 = (e) => {
    setNewDescription(e.target.value);
    handleDataUpdate()
  };
  const newStartDate1 = (e) => {
    setNewstartDate(e.target.value);
    handleDataUpdate()
  };
  const newEndDate1 = (e) => {
    setNewEndDate(e.target.value);
    handleDataUpdate()
  };

  const addNewWorkExperience = () => {
    if (newCompany && newPosition && newEndDate && newEndDate) {
      const newItem = {
        company: newCompany,
        position: newPosition,
        description: newDescription,
        startDate: newStartDate,
        endDate: newEndDate,
      };

      const updatedWorkDetails = [...editedWorkDetails];
      updatedWorkDetails.push(newItem);

      setEditedWorkDetails(updatedWorkDetails);
      setWorkDetailsList(updatedWorkDetails);

      ////console.log("New Work details", updatedWorkDetails);
      setNewCompany("");
      setNewPosition("");
      setNewDescription("");
      setNewstartDate("");
      setNewEndDate("");
      setIsAddMoreClicked(false);

      if (updatedWorkDetails.length < 4) {
        for (let i = 0; i < updatedWorkDetails.length && i < 3; i++) {
          dataOfUser[`Role ${i + 1} with Organization Name`] =
            updatedWorkDetails[i];

          setUserPersonalData(dataOfUser);
        }
      }

      ////console.log("User Data is", dataOfUser);
    }
  };

  const deleteWork = (index) => {
    const updatedWorkDetails = [...editedWorkDetails];
    updatedWorkDetails.splice(index, 1);

    setWorkDetailsList(updatedWorkDetails);
    setWorkExperience(updatedWorkDetails);
    setEditedWorkDetails(updatedWorkDetails);

    if (updatedWorkDetails.length < 4) {
      for (let i = 0; i < 3; i++) {
        if (updatedWorkDetails[i] === undefined) {
          dataOfUser[`Role ${i + 1} with Organization Name`] = undefined;
        }

        dataOfUser[`Role ${i + 1} with Organization Name`] =
          updatedWorkDetails[i];

        setUserPersonalData(dataOfUser);
      }
    }
  };

  const cancleChanges = () => {
    setEditingIndex(-1);
  };
  const cancleAdd = () => {
    setIsAddMoreClicked(false);
  };
  const formatInputDate = (inputDate) => {
    const parts = inputDate.split("-");
    const dateObject = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
    const formattedDate = format(dateObject, "yyyy-MM-dd");
    return formattedDate;
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };
  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  return (
    <div className="bg-white-A700 flex flex-col w-[94%] rounded-[20px]">
      <div className="flex flex-1 flex-row md:gap-10 items-center justify-between p-4  mqtab1:p-[0.9rem]">
        <div className="flex  items-center  flex-row gap-[18px] items-center justify-center ml-[33px]">
          <Img
            className="h-[25px] sm:h-[15px] sm:w-[15px] md:h-auto object-cover w-[25px] mqlap:w-[1.4vw] mqlap:h-[1.4vw] mqtab1:w-[1.6vw] mqtab1:h-[1.6vw]"
            src="images/img_material_symbols_work.svg"
            alt="brainstormskill"
          />
          <Text
            className="mt-0.5 text-black-900 text-lg sm:text-[12px] mqlap:text-[1.05vw] mqtab1:text-[1.15vw]"
            size="txtInterSemiBold18"
          >
            Work experience
          </Text>
        </div>
        <div className="flex flex-row items-center">
          {workInitial && !editWork && <EditIcon onClick={editWorkButton} />}
          <img
            className="icon-park-outline sm:h-[15px]  mqlap:h-[2.6vw]  mqtab1:h-[3vw]"
            onClick={workExpInitially}
            alt="Icon park outline"
            src={`https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-${workInitial ? 'down-1' : 'down-2'}.svg`}
          />
        </div>
      </div>

      <div>
        {workInitial && (
          <div>
            {editedWorkDetails &&
              workDetailsList
                .slice(0, showAllDetails ? workDetailsList.length : 2)
                .map((work, index) => (
                  <div key={index} className="ml-[3px]">
                    <div className="flex flex-row justify-between">
                      {index === editingIndex && !selfEdit ? (
                        <div className="flex flex-row sm:flex sm:flex-col justify-between w-[80%] sm:w-[100%] gap-[9px] it mb-[30px] md:ml-[0] ml-[75px] md:px-5 md:w-full">
                          <div className="w-full sm:ml-[31px] sm:flex sm:flex-col">
                            <input
                              placeholder="Position"
                              className="work-edit-input  sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"

                              value={editPosition} // Correct the value assignment here
                              onChange={(e) => {
                                setEditPosition(e.target.value);
                                handleDataUpdate(); // Call another function
                              }}
                              required
                            ></input>

                            <input
                              placeholder="Company Name"
                              className="work-edit-input  sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                              value={editCompany} // Correct the value assignment here

                              onChange={(e) => {
                                setEditedCompany(e.target.value);
                                handleDataUpdate();
                              }}

                              required
                            ></input>

                            <input
                              placeholder="Description"
                              className="work-edit-input  sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                              value={editdescription} // Correct the value assignment here
                              onChange={(e) => {
                                setEditdescription(e.target.value);
                                handleDataUpdate();
                              }}

                              required
                            ></input>

                            <input
                              type="date"
                              style={{
                                backgroundColor: "#f0ebeb",
                                border: "0px",


                              }}
                              placeholder="Start Date"
                              className="work-edit-input  sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                              value={editStartDate} // Correct the value assignment here
                              onChange={(e) => {
                                handleStartDateChange(e);
                                handleDataUpdate();
                              }}

                              required
                            ></input>

                            <input
                              type="date"
                              style={{
                                backgroundColor: "#f0ebeb",
                                border: "0px",


                              }}
                              placeholder="End Date"
                              className="work-edit-input  sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                              value={editEndDate} // Correct the value assignment here
                              onChange={(e) => {
                                handleEndDateChange(e)
                                handleDataUpdate();
                              }}

                              required
                            ></input>
                          </div>
                          <SaveCancleSection
                            saveChanges={saveChanges}
                            cancleChanges={cancleChanges}
                          />
                        </div>
                      ) : (
                        <div className="flex flex-col sm:gap-[5px] gap-[8px] w-[80%]">
                          {/* Position */}
                          <Text
                            className="pl-[90px]  sm:text-[10px] break-words overflow-hidden mqlap:text-[0.95vw] mqtab1:text-[0.95vw]"
                          >
                            {work.position}
                          </Text>

                          {/* Company */}
                          <Text className="pl-[90px]  sm:text-[10px] overflow-hidden break-words mqlap:text-[0.95vw] mqtab1:text-[0.95vw]">
                            {work.company}
                          </Text>

                          {/* Description */}
                          <Text
                            className="pl-[90px]  sm:text-[10px] break-words overflow-hidden mqlap:text-[0.95vw] mqtab1:text-[0.95vw]"

                          >
                            {work.description}
                          </Text>

                          {/* Start Date */}
                          <Text className="pl-[90px]  sm:text-[10px] mqlap:text-[0.85vw] mqtab1:text-[0.85vw] text-[0.96rem]">
                            {work.startDate}
                          </Text>

                          {/* End Date */}
                          <Text className="pl-[90px]  sm:text-[10px] mqlap:text-[0.85vw] mqtab1:text-[0.85vw] text-[0.96rem]">
                            {work.endDate}
                          </Text>
                        </div>

                      )}
                      {index !== editingIndex && editWork ? (
                        <EditDeleteIconsSection
                          index={index} // Assuming you have index defined somewhere
                          editItemButton={editItemButton}
                          deleteWork={deleteWork}
                        />
                      ) : null}
                    </div>
                    <div className="rectangle" />
                  </div>
                ))}
            {editWork && showAllDetails && workDetailsList.length < 3 ? (
              <AddMoreSection handleAddMoreClick={handleAddMoreClick} />
            ) : (
              <p
                className="sm:text-[10px] pl-[40px] pb-[20px] mqlap:text-[0.9vw] mqtab1:text-[1vw]"
                style={{ color: "#314ca3" }}
              >
                Kindly provide the most recent three experience details{" "}
              </p>
            )}
            {isAddMoreClicked && workDetailsList.length < 3 && (
              <div className="flex flex-row sm:flex sm:flex-col justify-between w-[80%] sm:w-[100%] gap-[9px] it mb-[30px] md:ml-[0] ml-[75px] md:px-5 md:w-full">
                <div className="w-full sm:ml-[31px] sm:flex sm:flex-col">
                  <input
                    placeholder="Position"
                    className="work-edit-input  sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                    value={newPosition} // Correct the value assignment here
                    onChange={newPosition1}
                    required
                  ></input>
                  <input
                    placeholder="Company Name"
                    className="work-edit-input  sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw] mqtab1:text-[1vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw]"
                    value={newCompany} // Correct the value assignment here
                    onChange={newCompany1}
                    required
                  ></input>
                  <textarea
                    onChange={newDescription1}
                    placeholder="Description"
                    className="sm:text-[10px] work-edit-input h-auto  w-[90%] rounded-[8px] mqtab1:text-[1vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw]  "
                    value={newDescription}
                  />
                  <input
                    type="date"
                    style={{ backgroundColor: "#f0ebeb", border: "0px", color: "rgb(101, 101, 112)" }}
                    placeholder="Start Date"
                    className="work-edit-input  sm:h-[29px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw] mqtab1:text-[1vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw]"
                    value={newStartDate} // Correct the value assignment here
                    onChange={newStartDate1}
                    required
                  ></input>
                  <input
                    type="date"
                    style={{ backgroundColor: "#f0ebeb", border: "0px", color: "rgb(101, 101, 112)" }}
                    placeholder="End Date"
                    className="work-edit-input align-center sm:h-[29px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw] mqtab1:text-[1vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw]"
                    value={newEndDate} // Correct the value assignment here
                    onChange={newEndDate1}
                    required
                  ></input>
                </div>
                <AddCancelSection2
                  addNewWorkExperience={addNewWorkExperience}
                  cancelAdd={cancleAdd}
                  page="Work Experience"
                />
              </div>
            )}
            {!showAllDetails && (
              <ShowAllSection
                arrayLength={workDetailsList.length}
                toggleShowAll={toggleShowAll}
                textTag={"experiences"}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkExperienceProfessional;
