import { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import LogoutPopup from "components/LogoutPopup";
import supabase from "components/superBaseConfig";
import "./Navbar.css";
import { Text } from "components";
import { useSelector } from "react-redux";
function NavMobile() {
  const accountType = useSelector(
    (state) => state.profileAccountType.accountType,
  );

  const SidebarData = [
    {
      title: "Dashboard",
      path: "/jobseeker-dashboard",
      icon: "images/dashboard.svg",
      cName: "nav-text",
    },
    {
      title: "Profile",
      path: "/profile-personal",
      icon: "images/profile.svg",
      cName: "nav-text",
    },
    {
      title: "Resume Builder",
      path: "/resumebuilder",
      isPaidReq: true,
      icon: "images/resume_builder.svg",
      cName: "nav-text",
    },
    {
      title: "Cover letters",
      path: "/coverletter",
      isPaidReq: true,
      icon: "images/cv.svg",
      cName: "nav-text",
    },
    {
      title: "Job search",
      path: "/jobsearch",
      isPaidReq: true,
      icon: "images/job_search.svg",
      cName: "nav-text",
    },
    {
      title: "Auto job",
      isPaidReq: true,
      path: "/autojob",
      icon: "images/auto_job.svg",
      cName: "nav-text",
    },
    {
      title: "Interview prep",
      path: "/interview-preparation",
      icon: "images/int_prep.svg",
      isPaidReq: true,
      cName: "nav-text",
    },
    {
      title: "Career advice",
      isPaidReq: true,
      path: "/careeradvice",
      icon: "images/career.svg",
      cName: "nav-text",
    },
    {
      title: "Billing",
      path: "/billing-overview",
      icon: "images/bill.svg",
      cName: "nav-text",
    },
    {
      title: "Notifications",
      path: "",
      icon: "images/img_settings.svg",
      //icon: "images/unread_notification_bell.svg",
      cName: "nav-text",
    },
    {
      title: "Logout",
      path: "",
      icon: "/images/img_logout.png",
      cName: "nav-text",
    },
  ];
  const [isLogoutPopupOpen, setIsLogoutPopupOpen] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [userData, setUserData] = useState(null);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    const storedData = localStorage.getItem(
      `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`,
    );
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setUserData(parsedData);
      if (parsedData && parsedData.user && parsedData.user.user_metadata) {
        setUserName(parsedData.user.user_metadata.full_name);
        setUserEmail(parsedData.user.email);
      }
    }
  }, []);

  const openLogoutPopup = () => {
    setIsLogoutPopupOpen(true);
  };

  const closeLogoutPopup = () => {
    setIsLogoutPopupOpen(false);
  };

  const confirmLogout = () => {
    //console.log("logout successful");
    Cookies.remove("accessToken");
    closeLogoutPopup();
    supabase.auth.signOut();
  };

  const showSidebar = () => setSidebar(!sidebar);

  const imgUrl = localStorage.getItem("imgUrl");

  return (
    <>
      <IconContext.Provider value={{ color: "#000" }}>
        <div className="navbar lg:hidden">
          <Link to="#" className="menu-bars">
            <FaIcons.FaBars
              onClick={showSidebar}
              className="sm:w-[30px] sm:h-[30px]"
            />
          </Link>
        </div>
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items" onClick={showSidebar}>
            <li className="navbar-toggle flex flex-row justify-between">
              <div className="ml-[30px] flex flex-row justify-between items-center">
                <img
                  src={imgUrl}
                  alt=""
                  className="sm:h-[80px] sm:w-[80px]  md:h-[120px] md:w-[120px]  rounded-[50%] "
                />
                <div className="ml-[20px]">
                  <Text
                    className="sm:text-lg text-base  pb-[5px]"
                    size="txtInterRegular16"
                  >
                    {userName}
                  </Text>
                  <Text
                    className="sm:text-sm text-base text-blue_gray-400"
                    size="txtInterRegular16"
                  >
                    {userEmail}
                  </Text>
                </div>
              </div>
              <Link to="#" className="menu-bars ">
                <AiIcons.AiOutlineClose />
              </Link>
            </li>
            {SidebarData.map((item, index) => (
              <li key={index} className={item.cName}>
                {item.title === "Logout" ? (
                  <a href="#" onClick={openLogoutPopup}>
                    <img
                      className="h-[20px] w-[20px] ml-[30px] mr-[10px]"
                      src={item.icon}
                      alt=""
                    />
                    <span className="text-[14px]">{item.title}</span>
                  </a>
                ) : (
                  <>
                    {item.isPaidReq ? (
                      <>
                        {accountType === 1 ? (
                          <Link to={item.path}>
                            <img
                              className="h-[20px] w-[20px] ml-[30px] mr-[10px]"
                              src={item.icon}
                              alt=""
                            />
                            <span className="text-[14px]  ml-[10px]">
                              {item.title}
                            </span>
                          </Link>
                        ) : (
                          <Link to="/billing-overview">
                            <img
                              className="h-[20px] w-[20px] ml-[30px] mr-[10px]"
                              src={item.icon}
                              alt=""
                            />
                            <span className="text-[#cdcdcd] text-[14px]  ml-[10px]">
                              {item.title}
                            </span>
                          </Link>
                        )}
                      </>
                    ) : (
                      <Link to={item.path}>
                        <img
                          className="h-[20px] w-[20px] ml-[30px] mr-[10px]"
                          src={item.icon}
                          alt=""
                        />
                        <span className="text-[14px]  ml-[10px]">
                          {item.title}
                        </span>
                      </Link>
                    )}
                  </>
                )}
              </li>
            ))}
          </ul>
          <LogoutPopup
            goTo="/login"
            isOpen={isLogoutPopupOpen}
            onClose={closeLogoutPopup}
            onConfirmLogout={confirmLogout}
          />
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default NavMobile;
