import { useMemo } from "react";
import PropTypes from "prop-types";

const GroupComponent = ({
  className = "",
  productMarketingAssociate,
  role,
  marketing,
  companyName,
  fullTime,
  applyHref,
  applyTarget,
  propBoxShadow,
  propBoxShadow1,
  propDisplay,
  propWidth,
  propMinWidth,
  propTextDecoration,
  displayedRows
}) => {

  const groupDivStyle = useMemo(() => {
    return {
      boxShadow: propBoxShadow,
    };
  }, [propBoxShadow]);

  const rectangleDivStyle = useMemo(() => {
    return {
      boxShadow: propBoxShadow1,
    };
  }, [propBoxShadow1]);

  const productMarketingAssociateStyle = useMemo(() => {
    return {
      display: propDisplay,
    };
  }, [propDisplay]);

  const jobInfoStyle = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  const marketingStyle = useMemo(() => {
    return {
      minWidth: propMinWidth,
    };
  }, [propMinWidth]);

  const applyStyle = useMemo(() => {
    return {
      textDecoration: propTextDecoration,
    };
  }, [propTextDecoration]);

  return (
    <>
    

    <div
      className={`flex-1 shadow-[0px_29px_33.6px_-16px_rgba(0,_0,_0,_0.09)] rounded-4xs bg-whitesmoke-100 flex flex-col items-start justify-start py-[29px] px-[38px] box-border gap-[7px] min-w-[432px] max-w-full text-left text-[24px] text-black font-poppins mq825:min-w-full ${className}`}
      style={groupDivStyle}
    >
      <div
        className="w-[576px] h-[221px] relative shadow-[0px_29px_33.6px_-16px_rgba(0,_0,_0,_0.09)] rounded-4xs bg-whitesmoke-100 hidden max-w-full"
        style={rectangleDivStyle}
      />
      <div
        className="relative leading-[29px] font-semibold inline-block max-w-full z-[1] mq450:text-lgi mq450:leading-[23px]"
        style={productMarketingAssociateStyle}
      >
        {role}
      </div>
      <div className="flex flex-col items-start justify-start gap-[51px] max-w-full text-[20px] mq450:gap-[25px]">
        <div
          className="w-[217.1px] flex flex-row items-end justify-start gap-[17px]"
          style={jobInfoStyle}
        >
          <div
            className="relative leading-[25px] inline-block min-w-[100px] shrink-0 z-[1] mq450:text-base mq450:leading-[19px]"
            style={marketingStyle}
          >
            {companyName}
          </div>
          <div className="flex-1 rounded-[4.39px] flex flex-row items-start justify-start py-[3px] pr-4 pl-[17px] z-[1] text-smi-5 border-[0.9px] border-solid border-darkslateblue">
            <div className="relative leading-[15px] inline-block min-w-[65px]">
              {fullTime}
            </div>
          </div>
        </div>
        <div className="flex flex-row items-start justify-start gap-[26px] text-xl-2 text-[#ffffff] mq825:flex-wrap">
          <div className="rounded-8xs bg-darkslateblue overflow-hidden flex flex-row items-start justify-start py-3 px-7 gap-[8px] z-[1] hover:bg-cornflowerblue cursor-pointer">
            <div className="flex flex-col items-start justify-start pt-px px-0 pb-0">
              <div className="relative leading-[25px] mq450:text-base mq450:leading-[19px] ">
                Download JD
              </div>
            </div>
            <img
              className="h-[27px] w-[27px] relative overflow-hidden shrink-0 min-h-[27px]"
              alt=""
              src="/icondownload.svg"
            />
          </div>
          <button className="cursor-pointer [border:none] py-3 px-7 bg-darkslateblue rounded-8xs overflow-hidden flex flex-row items-start justify-start gap-[8px] z-[1] hover:bg-cornflowerblue cursor-pointer">
            <div className="flex flex-col items-start justify-start pt-px px-0 pb-0 ">
              <a
                className="relative text-xl-2 leading-[25px] font-poppins text-[#ffffff] text-left inline-block [text-decoration:none] min-w-[58px] mq450:text-base mq450:leading-[19px] "
                href={applyHref}
                target={applyTarget}
                style={applyStyle}
              >
                Apply
              </a>
              
            </div>
            <img
              className="h-[27px] w-[27px] relative overflow-hidden shrink-0 min-h-[27px]"
              alt=""
              src="/iconarrowup.svg"
            />
          </button>
        </div>
      </div>
    </div>
    
    </>
  );
};

GroupComponent.propTypes = {
  className: PropTypes.string,
  productMarketingAssociate: PropTypes.string,
  marketing: PropTypes.string,
  fullTime: PropTypes.string,
  applyHref: PropTypes.string.isRequired,
  applyTarget: PropTypes.string,

  /** Style props */
  propBoxShadow: PropTypes.any,
  propBoxShadow1: PropTypes.any,
  propDisplay: PropTypes.any,
  propWidth: PropTypes.any,
  propMinWidth: PropTypes.any,
  propTextDecoration: PropTypes.any,
};

export default GroupComponent;
