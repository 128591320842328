import {
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import { fetchInterviewPrepData } from "actions/InterviewPrepData";
import { fetchJobSeekerDashboarCounts } from "actions/JobSeekerDashboard";
import { Text, Button } from "components";
import MainLoader from "components/Loader/index.jsx";
import { toPng } from "html-to-image";
import { ProfileHeader } from "pages/JobSeekerDashBoardPages/ProfileHeader/index.jsx";
import { SideBar1 } from "pages/JobSeekerDashBoardPages/SideBar1/index.jsx";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { useEffect, useRef, useState } from "react";
import GaugeChart from "react-gauge-chart";
import { useDispatch, useSelector } from "react-redux";
import profIndicator from "../../../assets/images/interviewReportimg.png";
import logoBase64EncodedValue from "../CareerAdvice/report";
import TextInterviewSection from "./TextInterviewSection";
import "./index.css";
import InterviewDataTable from "./interviewDataTable";
import LiveInstructions from "./liveInterviewSection";
import SubmitTestPopup from "./submitTestPopup";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
var tempData = [];

const InterviewPreparation = () => {
  const jobRole1 = useSelector(
    (state) => state.profilePreferenceData.profilePreferenceData.expected_role_1
  );
  const jobRole2 = useSelector(
    (state) => state.profilePreferenceData.profilePreferenceData.expected_role_2
  );
  const jobRole3 = useSelector(
    (state) => state.profilePreferenceData.profilePreferenceData.expected_role_3
  );

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [advice, setAdvice] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [answersArray, setAnswersArray] = useState([]);
  const [open, setOpen] = useState(false); //  first pop up -> select roles and start interview
  const [fourth, setSt4] = useState(false); // instructions in live interview
  const [Stopen, setSt] = useState(false); //
  const [third, setSt3] = useState(false); // Report pop up
  const [questionsArray, setQuestionsArray] = useState([]);
  const [voiceSelected, setVoiceSelected] = useState(false); // to check if live interview
  const [dataFetching, setFetchstatus] = useState(true);
  const [interviewType, setInterviewType] = useState("");
  const [warningmsg, setWarningMsg] = useState(false);
  const [selectedDuration, setDuration] = useState(20);
  const [timeRemaining, setTimeRemaining] = useState(20 * 60);
  const [questionsAnswersArray, setQuestionsAnswersArray] = useState([{}]);
  const questionAnswersArrayRef = useRef([{}]);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.interviewPrepData.data);
  const isLoading = useSelector((state) => state.interviewPrepData.isLoading);
  const [tableData, setTableData] = useState(data);
  const recommendationsRef = useRef([]);
  const marksRef = useRef([]);
  const [interviewId, setInterviewId] = useState("");
  const [percent, setPercent] = useState(0);
  const [liveInterviewScore, setLiveInterviewScore] = useState("");
  const [jobRoles, setJobRoles] = useState([]);

  useEffect(() => {
    setJobRoles([jobRole1, jobRole2, jobRole3]);
  }, [jobRole1, jobRole2, jobRole3]);

  const handleDuration = (e) => {
    setDuration(e.target.value);
    ////console.log(e.target.value)
  };
  function toDataURL(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = reject;
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    });
  }

  async function convertToImage() {
    const chart = document.getElementById("gauge-chart1");
    const dataUrl = await toPng(chart);
    return dataUrl;
  }

  const handleInterviewMode = (e) => {
    setInterviewType(e.target.value);
    if (e.target.value === "Live Interview") {
      setVoiceSelected(true);
    } else {
      setVoiceSelected(false);
    }
  };

  const handleRoleChangeDropdown = (e) => {
    setSelectedRole(e.target.value);
  };

  const handleOpen = () => {
    setSelectedRole("");
    setInterviewType("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setWarningMsg(false);
  };

  const getNextQuestion = async () => {
    const currentQuestionAnswerPair = {
      question: questionsArray[questionsArray.length - 1],
      answer: answersArray[answersArray.length - 1],
    };
    setQuestionsAnswersArray((prev) => [...prev, currentQuestionAnswerPair]);

    const oldQuestions = questionAnswersArrayRef.current;
    questionAnswersArrayRef.current = [
      ...oldQuestions,
      currentQuestionAnswerPair,
    ];

    ////console.log("Questions Answers Array", questionsAnswersArray);

    const apiUrl = `${process.env.REACT_APP_API_URL}/interview_questions?role=${selectedRole}&duration=${selectedDuration}`;
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        question_answer_pair: questionAnswersArrayRef.current,
      }),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        answersArray[currentQuestion] = "";
        ////console.log("Questions Array", questionsArray);
        ////console.log("Result from interview question", data);
        setQuestionsArray((prevQA) => [...prevQA, data.questions[0]]);
        /* //console.log("Result", data.questions);
        //console.log("Question", data.questions[0]); */
        setCurrentQuestion((prevQuestion) => prevQuestion + 1);
      })

      .catch((error) =>
        console.error("Error fetching interview questions:", error)
      );
  };

  const handleStartOpen4 = () => {
    if (
      selectedRole !== "" &&
      interviewType !== "" &&
      selectedDuration !== ""
    ) {
      setCurrentQuestion(0);
      answersArray[currentQuestion] = "";
      setAnswersArray(answersArray);
      setFetchstatus(true);
      console.log(selectedRole, selectedDuration);
      const apiUrl = `${process.env.REACT_APP_API_URL}/interview_questions?role=${selectedRole}&duration=${selectedDuration}`;
      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question_answer_pair: questionsAnswersArray,
        }),
      };

      fetch(apiUrl, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          ////console.log("Result from interyhytview question", data);
          setQuestionsArray((prev) => [...prev, data.questions[0]]);
          setWarningMsg(false);
          setSt4(true);
          setSt3(false);
          setFetchstatus(false);
          handleClose();
          setInsruction(true);
        })
        .catch((error) =>
          console.error("Error fetching interview questions:", error)
        );
    } else {
      setWarningMsg(true);
    }
  };

  const handleStartClose4 = () => {
    setVoiceSelected(false);
    // setOpen(true);
    setSt4(false);
  };

  const userData = JSON.parse(
    localStorage.getItem(
      `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`
    )
  );
  const user_id = userData.user.id;
  const fullName = userData.user.user_metadata.full_name;

  useEffect(() => {
    setFetchstatus(true);
    data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    let finalData = data.map((item, index) => ({
      sno: index + 1, // Adding index with 1-based numbering
      interviewMode: item["interview_type"],
      Role: item["role"],
      Performance: item["performance"],
      Rating: item["overall_rating"],
      Date: item["date"],
      amount: item["duration"],
      JobID: item["job_id"],
    }));

    setTableData(finalData);

    setFetchstatus(isLoading);
  }, [data]);

  const handleStartClose3 = () => {
    // //console.log("exit")
    setSt4(false);
    setSt3(false);
  };

  const handleStartClose = () => {
    setSt(false);
  };

  const handleStartOpen3 = () => {
    handleStartClose();
    setSt3(true);
    setSt4(true);
  };

  const buildPDF = async (job_id, overall_rating, final_result) => {
    try {
      let questionsAnswers = "";

      const date = new Date().toLocaleDateString();
      const time = new Date().toLocaleTimeString();

      const score = overall_rating;
      setPercent(score / 10);
      await new Promise((resolve) => setTimeout(resolve, 5000));
      const gaugeChartUrl = await convertToImage();

      const dataUrl = await toDataURL(profIndicator);

      for (let i = 1; i < questionsAnswersArray.length; i++) {
        const qa = questionsAnswersArray[i];
        questionsAnswers += `Question ${i}:\n ${qa.question} \n\n Answer: ${qa.answer}\n\n`;
      }

      const rows = [["Question", "Marks"]];
      for (let i = 0; i < questionsAnswersArray.length; i++) {
        const question = questionsArray[i];
        ////console.log("Question", question);
        const marks = marksRef.current[i];
        ////console.log("Marks", marks);
        if (marks !== undefined && question !== undefined) {
          rows.push([question, marks]);
        }
        ////console.log("Rows", rows);
      }

      let questionsAnswersMarksRecommendationsArray = [];

      for (let i = 1; i < questionsAnswersArray.length; i++) {
        const question = questionsAnswersArray[i].question;
        const answer = questionsAnswersArray[i].answer;
        const marks = marksRef.current[i - 1];
        const recommendations = recommendationsRef.current[i - 1];

        if (question === undefined || answer === undefined) {
          continue;
        }

        const renderedContent = `Question ${i}: ${question}\n\nMarks: ${marks}\n\nAnswer: ${answer}\n\nRecommendations: ${recommendations}\n\n\n\n`;

        questionsAnswersMarksRecommendationsArray.push(renderedContent);
      }

      ////console.log(questionsAnswersMarksRecommendationsArray);
      const liveInterviewHumeResults = "";
      if (interviewType === "Live Interview") {
        liveInterviewHumeResults = `Live Interview Score : ${liveInterviewScore}`;
      }

      let fontColor = "";
      const scorePercentage = score * 10;
      if (scorePercentage >= 60) {
        fontColor = "green";
      } else if (scorePercentage >= 30) {
        fontColor = "blue";
      } else {
        fontColor = "red";
      }

      const docData = {
        pageMargins: [40, 80, 40, 40],
        header: [
          {
            text: "\n\n\n\n\n\n\n",
            columns: [
              {
                image: logoBase64EncodedValue,
                width: 50,
                height: 50,
                margin: [30, 5, 0, 0],
                alignment: "left",
              },
              {
                text: "Interview Report",
                color: "#314CA3",
                fontSize: 18,
                bold: true,
                margin: [0, 15, 0, 0],
                alignment: "left",
              },
            ],
            columnGap: 180,
          },
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 10,
                x2: 800,
                y2: 10,
                lineWidth: 3,
              },
            ],
          },
        ],

        content: [
          {
            text: [
              "Full Name: ",
              { text: fullName, style: "bold" },
              "\n\n",
              "Role: ",
              { text: selectedRole, style: "bold" },
              "\n\n",
              "Interview type: ",
              { text: interviewType, style: "bold" },
              "\n\n",
              "Duration: ",
              { text: selectedDuration, style: "bold" },
              " minutes\n\n",
              "Date and Time: ",
              { text: date + " " + time, style: "bold" },
              "\n\n",
            ],
            margin: [0, 20, 0, 0],
          },
          {
            text: "Score Analysis\n",
            style: "bold",
            margin: [0, 20, 0, 5],
          },
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 10,
                x2: 500,
                y2: 10,
                lineWidth: 3,
              },
            ],
          },
          {
            columns: [
              {
                text: [
                  "Total Marks: ",
                  { text: score, style: "bold" },
                ],
              },
              {
                text: [
                  "Interview Status: ",
                  { text: "Completed", color: "green" },
                ],
                alignment: "right",
              },
            ],
            margin: [0, 20, 0, 0],
          },
          {
            text: [
              "You are ",
              { text: score * 10 + " % ", color: fontColor, size: 50 },
              "proficient",
            ],
          },
          { image: gaugeChartUrl, width: 300, alignment: "center" },
          { image: dataUrl, width: 300, alignment: "center" },
          {
            table: {
              headerRows: 1,
              body: rows,
            },
            margin: [0, 20, 0, 0],
          },
          {
            text: questionsAnswersMarksRecommendationsArray,
            pageBreak: "before",
            margin: [0, 40, 0, 0],
          },
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 10,
                x2: 500,
                y2: 10,
                lineWidth: 3,
              },
            ],
          },
          {
            text: "Result\n\n",
            style: "subheader",
            fontSize: 15,
            margin: [0, 20, 0, 0],
          },
          {
            text: final_result,
          },
          {
            text: liveInterviewHumeResults,
          },
        ],
        styles: {
          bold: {
            bold: true,
          },
          green: {
            color: "green",
          },
        },
      };

      // Creating PDF using pdfMake
      const pdfDoc = pdfMake.createPdf(docData);
      pdfDoc.getBlob(async (blob) => {
        await uploadReport(job_id, blob);
      });
    } catch (e) {
      //console.log(e);
    }
  };

  const handleDownload = async (interviewId) => {
    try {
      var storedData = localStorage.getItem(
        `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`
      );
      var parsedData = JSON.parse(storedData);
      var userId = parsedData.user.id;
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/download_interview_report?user_id=${userId}&job_id=${interviewId}`
      );
      const blob = await response.blob();
      //console.log(blob);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "InterviewPreparationReport.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      //console.log(e);
    }
  };

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedRows = tableData.slice(startIndex, endIndex);

  const [answers, setAnswers] = useState({});

  const handleAnswerChange = (e, index) => {
    const { value } = e.target;
    setAnswers((prevState) => ({
      ...prevState,
      [`answer${index}`]: value,
    }));
  };

  // Handler for submitting answers
  const handleGetAdvice = async () => {
    const currentQuestionAnswerPair = {
      question: questionsArray[questionsArray.length - 1],
      answer: answersArray[answersArray.length - 1],
    };
    ////console.log("Current question answer pair", currentQuestionAnswerPair);

    setQuestionsAnswersArray((prev) => [...prev, currentQuestionAnswerPair]);

    const oldQuestions = questionAnswersArrayRef.current;
    questionAnswersArrayRef.current = [
      ...oldQuestions,
      currentQuestionAnswerPair,
    ];

    setFetchstatus(true);
    const questionsAnswersArray = Object.keys(answers).map((key, index) => ({
      question: questionsArray[index],
      answer: answers[key],
    }));

    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/grade_interview_answers`;
      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question_answer_list: questionAnswersArrayRef.current,
          user_id,
          role: selectedRole,
        }),
      };

      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();
      const db_data = data.db_response;
      const job_id = db_data[1][0].job_id; // Accessing job_id from the API response
      const newInterviewId = job_id;
      setInterviewId(newInterviewId);

      const marksURL = `${process.env.REACT_APP_API_URL}/get_marks`;
      const marksOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question_answer_list: questionAnswersArrayRef.current,
          user_id,
          role: selectedRole,
        }),
      };

      const marksResponse = await fetch(marksURL, marksOptions);
      const marksData = await marksResponse.json();
      ////console.log("Marks Data", marksData);

      const recommendationsURL = `${process.env.REACT_APP_API_URL}/get_good_answers`;
      const recommendationsOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question_answer_list: questionAnswersArrayRef.current,
          user_id,
          role: selectedRole,
        }),
      };

      const recommendationsResponse = await fetch(
        recommendationsURL,
        recommendationsOptions
      );
      const recommendationsData = await recommendationsResponse.json();

      setAdvice(data["response"]);
      console.log("response data: ", data["response"]);
      marksRef.current = marksData;
      recommendationsRef.current = recommendationsData;
      setFetchstatus(false);
      await buildPDF(job_id, data.overall_rating, data["response"]);
      setQuestionsAnswersArray([{}]);
      // Ensure that the PDF is available for download
    } catch (error) {
      console.error("Error fetching interview grades:", error);
      setQuestionsAnswersArray([{}]);
      setAdvice("Error fetching, try again later!");
    } finally {
      setSt3(true);
      handleClose();
      handleStartClose4();
      setpenSubmitTest(false);
      dispatch(fetchInterviewPrepData(user_id));
      dispatch(fetchJobSeekerDashboarCounts(user_id));
      setFetchstatus(false);
    }
  };

  const uploadReport = async (job_id, blob) => {
    try {
      var storedData = localStorage.getItem(
        `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`
      );
      var parsedData = JSON.parse(storedData);
      var userId = parsedData.user.id;
      const formData = new FormData();
      formData.append("int_report", blob, "InterviewPreparationReport.pdf");

      const apiUrl = `${process.env.REACT_APP_API_URL}/upload_interview_report?user_id=${userId}&job_id=${job_id}`;
      const requestOptions = {
        method: "POST",
        body: formData,
      };

      const res = await fetch(apiUrl, requestOptions);

      if (!res.ok) {
        const errorText = await res.text();
        console.error("Error fetching data:", res.status, errorText);
        // Handle the error or throw an exception as needed
      } else {
        const data = await res.json();
        dispatch(fetchInterviewPrepData(user_id));
        dispatch(fetchJobSeekerDashboarCounts(user_id));
        //console.log("Data from resume parser", data);
      }
    } catch (error) {
      console.error("Error updating stored resume:", error);
    }
  };

  const [instruction, setInsruction] = useState(false);

  const handleContinue = () => {
    setInsruction(false);
    setTimeRemaining(selectedDuration * 60);
  };

  const backToOptions = () => {
    setOpen(false);
    setSt4(false);
  };

  const [openSubmitTest, setpenSubmitTest] = useState(false);
  const SubmitTest = () => {
    setpenSubmitTest(true);
  };
  const SubmitTestClose = () => {
    setpenSubmitTest(false);
  };

  const handleNextQuestion = () => {
    handleAnswerChange(
      { target: { value: "Answer for question " + (currentQuestion + 1) } },
      currentQuestion
    );
    getNextQuestion();
    setOpen(false);
  };

  return (
    <>
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div style={{ position: "absolute", left: "-9999px" }}>
          <GaugeChart
            id="gauge-chart1"
            animate={false}
            // nrOfLevels={15}
            percent={percent}
            needleColor="#464A4F"
            textColor="#000000"
            colors={["#FE4545", "#15BFEB", "#27BB2D"]}
          />
        </div>
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <SideBar1 clickedTab={"Interview Prep"} />
          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full h-[100vh]">
            <ProfileHeader clickedTab={"Interview Preparation"} />
            <div className="h-[93vh] overflow-y-scroll bg-white-A700 flex flex-col items-center justify-start rounded-[15px] w-[94%] md:w-full">
              <div className=" bg-white-A700 flex flex-col md:gap-10 gap-[682px] h-full inset-[0] items-center justify-start m-auto rounded-[15px] w-full">
                <div className="flex flex-col items-center justify-start p-[30px] sm:px-5 w-full">
                  <div className="flex  flex-row sm:gap-10 items-center justify-between mb-1 w-full">
                    <Text
                      className="text-blue_gray-400 text-xl sm:text-sm mqtab1:text-[1.2vw] mqlap:text-[1.1vw] "
                      size="txtInterMedium20"
                    >
                      Interview Preparation Report
                    </Text>
                    <div>
                      <Button
                        onClick={handleOpen}
                        className="cursor-pointer font-medium  leading-[normal] text-center text-lg  sm:text-sm mqtab1:text-[1.05vw] mqlap:text-[1vw] px-[1.4vw] py-[0.7vw] mqlap:px-[1.6vw] mqtab1:px-[1.6vw] mqlap:rounded-[13px] mqtab1:rounded-[11px]"
                        shape="round"
                      >
                        Start Interview
                      </Button>
                    </div>
                  </div>
                  <div className=" flex flex-col mt-[40px]  inset-[0] items-center justify-center pt-[19px] w-full">
                    {dataFetching ? (
                      <MainLoader />
                    ) : (
                      <InterviewDataTable
                        currentPage={currentPage}
                        displayedRows={displayedRows}
                        tableData={tableData}
                        itemsPerPage={itemsPerPage}
                        handlePageChange={handlePageChange}
                      />
                    )}
                  </div>
                </div>

                <Dialog open={open} maxWidth="xl" disableBackdropClick={true}>
                  {jobRoles.every((each) => each === null) ? (
                    <Card
                      sx={{
                        width: { xs: "80vw", md: "40vw" }, // Responsive width
                        height: { xs: "auto", md: "30vh" }, // Responsive height
                        margin: "auto", // Center horizontally
                        padding: 2, // Padding inside the card
                        backgroundColor: "#f0f8ff", // Light blue background
                        border: "1px solid #ccc", // Border
                        boxShadow: 3, // Box shadow for depth
                        position: "relative", // For positioning the close button
                        textAlign: "center",
                      }}
                    >
                      {/* Close button */}
                      <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                          color: "#999", // Grey color for the icon
                        }}
                      >
                        <CancelIcon />
                      </IconButton>

                      <CardContent className="flex flex-col justify-between h-full">
                        <Typography
                          variant="h6"
                          component="div"
                          gutterBottom
                          sx={{ color: "#333" }} // Darker text for contrast
                        >
                          Important Notice
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#555",
                            fontSize: { xs: "1rem", md: "1.25rem" },
                          }} // Responsive font size
                        >
                          Before starting an interview, please fill in your
                          personal & preference details.
                        </Typography>
                        <Link
                          to="/profile-preferences"
                          className="w-full flex items-center justify-center"
                          style={{ textDecoration: "none", marginTop: 16 }}
                        >
                          <Button className="flex items-center justify-around w-[200px]">
                            <span>Go to Profile</span>
                            <ArrowRight />{" "}
                            {/* Use the correct icon component */}
                          </Button>
                        </Link>
                      </CardContent>
                    </Card>
                  ) : (
                    <div className="flex flex-col  w-[45rem] mqlap:w-[39.5vw] mqtab1:w-[42.8vw] mqtab2:w-[45vw] mqmob1:w-[48vw]">
                      <div className="h-[71px] mqlap:h-[4.1vw] mqtab1:h-[4.25vw] mqtab2:h-[4.1vw] flex justify-start items-center gap-[3px] mqlap:gap-[2px] mqtab1:gap-[2px]">
                        <p className="text-start  font-semibold text-[20px]  text-xl sm:text-sm pl-[30px] mqlap:text-[1.1vw] mqtab1:text-[1.15vw] mqtab2:text-[1.4vw]">
                          Start Interview
                        </p>
                        <Tooltip
                          title={
                            <Typography style={{ marginLeft: 8 }}>
                              {" "}
                              <p className="text-[16px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] mqtab2:text-[1.02vw]">
                                Select your role and type of interview.
                              </p>
                            </Typography>
                          }
                          placement="right"
                          arrow
                          className="relative flex flex-col items-center justify-start md:h-[84px] gap-2  md:w-[250px]   rounded-[15px] transition-colors  hover:bg-gray-100 hover:text-[40px]"
                        >
                          <img
                            className="sm:h-[25px] sm:w-[25px] h-[1.25rem] w-[1.25rem] mqlap:w-[1.1vw] mqlap:h-[1.1rem] mqtab1:w-[1.02vw] mqtab1:mt-[1px] mqtab1:h-[1rem] mqtab2:w-[1.3vw] mqtab2:h-[1.3rem]"
                            src="images/infoIcon.svg"
                            alt="infoIcon"
                          />
                        </Tooltip>
                      </div>
                      <hr
                        style={{
                          height: "1px",
                          backgroundColor: "#e3e4e8",
                          border: "none",
                          width: "100%",
                        }}
                      />

                      <div className="flex-wrap flex flex-row sm:ml-[10px] sm:mr-[10px] ml-[80px] mr-[55px] mqlap:ml-[65px] mqlap:mr-[40px] mqtab1:ml-[65px] mqtab1:mr-[40px] gap-y-[22px] gap-x-[30px]  mqtab1:gap-y-[20px] mqtab1:gap-x-[28px] items-center sm:flex-col mt-[20px] flex-wrap">
                        <div className="w-[45%] sm:w-[90%] flex flex-col gap-[10px]">
                          <p className="sm:text-[10px] font-semibold  mqlap:text-[0.9vw] mqtab1:text-[0.93vw] mqtab2:text-[1.02vw] ">
                            Select Role
                          </p>

                          <select
                            className="flex items-center  w-full h-[2.85rem]  mqlap:h-[2.45vw] mqlap:pt-[0.35vw] mqtab2:rounded-[8.5px] mqtab2:h-[2.5vw] mqtab2:text-[0.9vw] mqtab2:pt-[0.12vw]  mqtab1:h-[2.43vw] mqtab1:pt-[0.25vw] mqtab1:rounded-[10px]  rounded-[12px] border-[#e3e4e8] text-[15.5px] mqlap:text-[0.86vw] mqtab1:text-[0.9vw] "
                            value={selectedRole}
                            onChange={handleRoleChangeDropdown}
                          >
                            <option value="" selected disabled>
                              Select
                            </option>
                            {jobRoles
                              .filter((each) => each !== null)
                              .map((each, index) => (
                                <option key={index} value={each}>
                                  {each}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="w-[45%] sm:w-[90%] flex flex-col gap-[10px]">
                          <p className="sm:text-[10px] font-semibold font-semibold mqlap:text-[0.9vw] mqtab1:text-[0.93vw] mqtab2:text-[1.02vw]">
                            Select interview type
                          </p>
                          <select
                            className="w-full h-[2.85rem]  mqlap:h-[2.45vw] mqlap:pt-[0.35vw] mqtab2:rounded-[8.5px] mqtab2:h-[2.5vw] mqtab2:text-[0.9vw] mqtab2:pt-[0.12vw]  mqtab1:h-[2.43vw] mqtab1:pt-[0.25vw] mqtab1:text-[0.75rem] mqtab1:rounded-[10px] rounded-[12px]  border-[#e3e4e8] text-[15.5px] mqlap:text-[0.86vw] mqtab1:text-[0.9vw]"
                            value={interviewType}
                            onChange={handleInterviewMode}
                          >
                            <option value="" selected disabled>
                              {" "}
                              Select
                            </option>
                            <option>Text Interview</option>
                            <option>Live Interview</option>
                          </select>
                        </div>
                        <div className="w-[45%] sm:w-[90%] flex flex-col gap-[10px]">
                          <p className="sm:text-[10px] font-semibold font-semibold mqlap:text-[0.9vw] mqtab1:text-[0.93vw] mqtab2:text-[1.02vw] ">
                            Select Duration
                          </p>
                          <select
                            className=" w-full h-[2.85rem] mqlap:h-[2.45vw] mqlap:pt-[0.35vw] mqtab2:rounded-[8.5px] mqtab2:h-[2.5vw] mqtab2:text-[0.9vw] mqtab2:pt-[0.12vw] mqtab1:h-[2.43vw] mqtab1:pt-[0.25vw]  mqtab1:rounded-[10px] rounded-[12px] sm:rounded-[8px] sm:text-[10px] border-[#e3e4e8] text-[15.5px] mqlap:text-[0.86vw] mqtab1:text-[0.9vw] "
                            value={selectedDuration}
                            onChange={handleDuration}
                          >
                            <option value="" selected disabled>
                              {" "}
                              Select{" "}
                            </option>
                            <option value={20}>20 minutes(5 Questions)</option>
                            <option value={40}>40 minutes(10 Questions)</option>
                          </select>
                        </div>
                      </div>

                      <div className="flex flex-row justify-end">
                        {warningmsg && (
                          <p
                            style={{
                              color: "red",
                              // paddingLeft: "20px",
                              paddingBottom: "10px",
                            }}
                            className="text-[15px] mqlap:text-[0.8vw] mqtab1:text-[0.85vw] self-end absolute left-[20px]"
                          >
                            *Please enter all required fields
                          </p>
                        )}
                        <div className="flex flex-row gap-[18px] mqlap:gap-[15px] mqtab1:gap-[14.5px] mqtab2:gap-[13px] justify-end sm:justify-center items-center mt-[2.3rem] mb-[1.15rem] mqtab1:mb-[1.05rem] mqtab1:mt-[2.1rem] mqlap:mb-[1.07rem]">
                          <button
                            style={{ borderColor: "blue", color: "blue" }}
                            className=" mqtab1:border-[0.1vw] mqlap:border-[0.1vw]  mqtab2:border-[0.1vw] border-[1.8px] border-soild border-blue flex justify-center items-center h-[2.52rem] w-[7.5rem] mqlap:h-[2.11vw] mqlap:w-[6.8vw] mqtab1:h-[2.15vw] mqtab1:w-[6.9vw] mqtab2:h-[2.35vw] mqtab2:w-[7.4vw] rounded-[13px] sm:text-[14px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] mqtab2:text-[0.95vw] mqlap:rounded-[0.73vw] mqtab1:rounded-[0.73vw] mqtab2:rounded-[9px]"
                            onClick={handleClose}
                          >
                            Cancel
                          </button>
                          <Button
                            className="flex justify-center items-center mr-[30px] h-[2.55rem] w-[7.5rem] mqlap:h-[2.15vw] mqlap:w-[6.9vw] mqtab1:h-[2.2vw] mqtab1:w-[7vw] mqtab2:h-[2.36vw] mqtab2:w-[7.5vw] rounded-[12px] sm:text-[14px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] mqtab2:text-[0.95vw] mqlap:rounded-[0.73vw] mqtab1:rounded-[0.73vw] mqtab2:rounded-[9px]"
                            onClick={handleStartOpen4}
                          >
                            Start
                          </Button>
                        </div>
                      </div>

                      {/* {warningmsg && (
                        <p
                          style={{
                            color: "red",
                            paddingLeft: "20px",
                            paddingBottom: "10px",
                          }}
                          className="text-[15px] mqlap:text-[0.8vw] mqtab1:text-[0.85vw] "
                        >
                          *Please enter all required fields
                        </p>
                      )} */}
                    </div>
                  )}
                </Dialog>

                {voiceSelected ? (
                  <LiveInstructions
                    open={fourth}
                    handleGetAdvice={handleGetAdvice}
                    answersArray={answersArray}
                    questionsArray={questionsArray}
                    noOfQuestions={selectedDuration === 20 ? 5 : 10}
                    handleStartOpen4={handleStartOpen4}
                    handleStartClose3={handleStartClose3}
                    selectedRole={selectedRole}
                    selectedDuration={selectedDuration}
                    questionsAnswersArray={questionsAnswersArray}
                    setQuestionsAnswersArray={setQuestionsAnswersArray}
                    setQuestionsArray={setQuestionsArray}
                    setLiveInterviewScore={setLiveInterviewScore}
                    fullName={fullName}
                    uploadReport={uploadReport}
                    setClose={setSt4}
                    setVoiceSelected={setVoiceSelected}
                    setInstruction={setInsruction}
                  />
                ) : (
                  <>
                    <TextInterviewSection
                      handleGetAdvice={handleGetAdvice}
                      open={fourth}
                      setpenSubmitTest={setpenSubmitTest}
                      instruction={instruction}
                      Typography={Typography}
                      backToOptions={backToOptions}
                      handleContinue={handleContinue}
                      SubmitTestClose={SubmitTestClose}
                      SubmitTest={SubmitTest}
                      handleNextQuestion={handleNextQuestion}
                      openSubmitTest={openSubmitTest}
                      questionsArray={questionsArray}
                      timeRemaining={timeRemaining}
                      setTimeRemaining={setTimeRemaining}
                      currentQuestion={currentQuestion}
                      selectedDuration={selectedDuration}
                      answersArray={answersArray}
                      setAnswersArray={setAnswersArray}
                    />

                    <SubmitTestPopup
                      openSubmitTest={openSubmitTest}
                      SubmitTestClose={SubmitTestClose}
                      handleGetAdvice={handleGetAdvice}
                    />
                  </>
                )}

                <Dialog open={third} disableBackdropClick={true} maxWidth="lg">
                  <div>
                    {/* <div>
                      <Text className="text-center pt-[20px] text-blue_gray-400 text-xl sm:text-sm">
                        Result for you
                      </Text>
                    </div> */}
                    <div style={{borderBottom:"2px solid #d0d0d0"}} className="flex flex-row justify-center mt-[30px]">
                      <div className="flex justify-between items-center w-[90%]">
                        <Text className="text-[gray-400] text-xl sm:text-sm pb-[20px]">
                          Result
                        </Text>
                       
                      </div>
                    </div>
                    <div   className=" flex flex-row justify-center mt-[30px]">
                      <textarea
                        readOnly
                        rows={20}
                        cols={100}
                        style={{border:"2px solid #d0d0d0"}}
                        placeholder={
                          "Error fetching results. Please try again."
                        }
                        maxLength={300}
                        className="rounded-[10px] w-[90%] sm:h-[300px]"
                      >
                        {advice}
                      </textarea>
                    </div>
                    <div className="flex flex-row justify-center mt-[30px] mb-[30px]">
                      <div className="flex gap-[20px] justify-end w-[90%]">
                        <button style={{border:"2px solid #314ca3"}}
                          className="text-[#314ca3] border-[#314ca3] flex h-[50px] w-[150px] sm:h-[30px] sm:w-[90px] sm:text-[12px] rounded-[10px] flex justify-around items-center"
                          onClick={handleStartClose3}
                        >
                          Exit
                        </button>
                        <Button
                          className="flex h-[50px] w-[150px] rounded-[10px] flex justify-around items-center sm:h-[30px] sm:w-[120px] sm:text-[12px]"
                          onClick={() => {
                            handleDownload(interviewId);
                          }}
                        >
                          <Text>Download</Text>{" "}
                          <img
                            className="octicon-download"
                            alt="Octicon download"
                            src="https://cdn.animaapp.com/projects/65245b020103026f6d371b65/releases/6585a2cf79a36e6414abe303/img/octicon-download-16.svg"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InterviewPreparation;
