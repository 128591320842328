
import React from "react";
import { Button, Img, Input, Text } from "components";
import Sidebar1 from "pages/UniversityDashBoardPages/UniversitySidebar";
import TopHeader from "pages/RecruiterDashBoardPages/TopHeader";

  const UniversityFeedback = () => {
  return (
    <>
          <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
            <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
              <Sidebar1 clickedTab={"Feedback"} />

              <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
                <TopHeader header={"Feedback"} />


                <div className="bg-white-A700 flex flex-col items-center justify-start p-7 sm:px-5 rounded-[15px] w-[94%] md:w-full">
                  <div className=" font-poppins md:h-[1103px] h-[1165px] relative w-[94%] md:w-full">
                    



                  {/* <div className="flex flex-1 flex-col font-inter gap-[50px] items-center justify-start md:px-5 w-full">
            
            <div className="bg-white-A700 flex flex-col items-center justify-start p-[34px] sm:px-5 rounded-[15px] w-[95%] md:w-full"> */}

            
            <div className="bg-white-A700 flex flex-col items-start justify-start p-[35px] sm:px-5 rounded-[15px] w-[95%] md:w-full">
              <div className="flex flex-col md:gap-10 gap-[412px] items-start justify-start mb-[9px] md:ml-[0] ml-[43px] w-[55%] md:w-full">
                <div className="sm:h-[261px] h-[389px] md:h-[474px] relative w-full">
                  <div className="absolute bottom-[0] flex flex-col gap-2 items-start justify-start left-[0] w-[92%]">
                    <Text
                      className="text-base text-blue_gray-400"
                      size="txtInterRegular16"
                    >
                      Feedback
                    </Text>
                    <Img
                      className="h-[105px]"
                      src="images/img_frame1000004279.svg"
                      alt="frame1000004279"
                    />
                  </div>
                  <div className="absolute flex flex-col inset-x-[0] items-center justify-end max-w-[809px] mx-auto py-[41px] rounded-[15px] top-[0] w-full">
                    <div className="flex flex-col gap-[22px] items-center justify-start w-full">
                      <div className="flex md:flex-col flex-row gap-[29px] items-center justify-between w-full">
                        <div className="flex md:flex-1 flex-col gap-2 items-start justify-start w-[49%] md:w-full">
                          <Text
                            className="text-base text-blue_gray-400"
                            size="txtInterRegular16"
                          >
                            First Name
                          </Text>
                          <Input
                            name="frame1000004279_One"
                            placeholder="Charlene"
                            className="leading-[normal] p-0 placeholder:text-blue_gray-900 text-[15px] text-left w-full"
                            wrapClassName="border border-gray-300 border-solid w-full"
                          ></Input>
                        </div>
                        <div className="flex md:flex-1 flex-col gap-[9px] items-start justify-start w-[49%] md:w-full">
                          <Text
                            className="text-base text-blue_gray-400"
                            size="txtInterRegular16"
                          >
                            Last Name
                          </Text>
                          <Input
                            name="language"
                            placeholder="Reed "
                            className="leading-[normal] p-0 placeholder:text-blue_gray-900 text-[15px] text-left w-full"
                            wrapClassName="border border-gray-300 border-solid w-full"
                          ></Input>
                        </div>
                      </div>
                      <div className="flex md:flex-col flex-row gap-[29px] items-center justify-between w-full">
                        <div className="flex md:flex-1 flex-col gap-2.5 items-start justify-start w-[49%] md:w-full">
                          <Text
                            className="text-base text-blue_gray-400"
                            size="txtInterRegular16"
                          >
                            Email address*
                          </Text>
                          <Input
                            name="email_One"
                            placeholder="charlenereed@gmail.com "
                            className="leading-[normal] p-0 placeholder:text-blue_gray-900 text-[15px] text-left w-full"
                            wrapClassName="border border-gray-300 border-solid w-full"
                          ></Input>
                        </div>
                        <div className="flex md:flex-1 flex-col gap-[11px] items-start justify-start w-[49%] md:w-full">
                          <Text
                            className="text-base text-blue_gray-400"
                            size="txtInterRegular16"
                          >
                            Phone number
                          </Text>
                          <Input
                            name="mobileNo"
                            placeholder="9876543210"
                            className="leading-[normal] p-0 placeholder:text-blue_gray-900 text-[15px] text-left w-full"
                            wrapClassName="border border-gray-300 border-solid w-full"
                            type="number"
                          ></Input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex sm:flex-col flex-row gap-14 items-center justify-start w-[62%] md:w-full">
                  <Button className="cursor-pointer font-medium h-[50px] leading-[normal] text-center text-xl w-[220px]">
                    Submit
                  </Button>
                  <Button className="cursor-pointer font-medium h-[50px] leading-[normal] text-center text-xl w-[220px]">
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>

                 </div>
                </div>
              </div>
            </div>
       
        </>
  );
};

export default UniversityFeedback;
