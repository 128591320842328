import styled from "styled-components";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import supabase from "components/superBaseConfig";
import { Navigate } from "react-router-dom";
const RecruiterRoboLogo31Icon = styled.img`
  position: absolute;
  top: 8.1px;
  left: 0px;
  width: 60.5px;
  height: 75.1px;
  object-fit: fill;
`;
const RecruiterRoboLogo31Wrapper = styled.div`
  position: relative;
  width: 68.5px;
  height: 82.5px;
  overflow: hidden;
  flex-shrink: 0;
`;
const WebsiteName = styled.div`
  position: relative;
  color: #161616;
  line-height: 135.5%;
  font-weight: 600;
  display: inline-block;
  width: 150.5px;
  height: 24.1px;
  flex-shrink: 0;
`;
const FrameParent = styled.div`
  position: absolute;
  top: 47px;
  left: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const PasswordReset = styled.div`
  position: relative;
  font-size: 14.93px;
  font-weight: 600;
  color: var(--color-gray-100);
  z-index: 0;
`;
const PleaseEnterA = styled.div`
  position: relative;
  color: var(--color-darkslategray-100);
  z-index: 1;
`;
const InputLabelmedium = styled.div`
  position: absolute;
  top: 0%;
  left: calc(50% - 157.75px);
  line-height: 17.33px;
`;
const Component2Child = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 157.45px);
  border-radius: 3.59px;
  background-color: var(--color-white);
  width: 315.5px;
  height: 29.9px;
`;
const InvisibleIcon = styled.img`
  position: absolute;
  top: calc(50% - 5.55px);
  left: calc(50% + 132.25px);
  width: 11.2px;
  height: 11.2px;
  object-fit: cover;
`;
const Div = styled.div`
  position: absolute;
  top: 7px;
  left: 13.5px;
  line-height: 15.54px;
`;
const Component2 = styled.div`
  position: absolute;
  top: 19.6px;
  left: calc(50% - 157.75px);
  width: 315.5px;
  height: 29.9px;
  text-align: center;
  font-size: 9.56px;
  color: var(--color-black);
`;
const Email = styled.div`
  position: relative;
  width: 315.5px;
  height: 49.5px;
  z-index: 2;
  font-size: 10.76px;
  font-family: var(--font-inter);
`;
const Email1 = styled.div`
  position: relative;
  width: 315.5px;
  height: 49.5px;
  z-index: 3;
  font-size: 10.76px;
  font-family: var(--font-inter);
`;
const YourPasswordMust = styled.div`
  position: relative;
  color: #605e5c;
  display: inline-block;
  width: 304.6px;
  z-index: 4;
`;
const SubmitChild = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 157.75px);
  border-radius: 3.59px;
  background-color: var(--color-darkslateblue);
  width: 315.5px;
  height: 29.9px;
`;
const LogIn = styled.div`
  position: absolute;
  top: calc(50% - 7.95px);
  left: calc(50% - 44.35px);
  line-height: 15.54px;
`;
const Submit = styled.div`
  position: relative;
  width: 315.5px;
  height: 29.9px;
  z-index: 5;
  text-align: center;
  font-size: 9.56px;
  color: var(--color-white);
  font-family: var(--font-inter);
`;
const FrameChild = styled.div`
  position: relative;
  border-top: 0.5px solid rgba(184, 184, 184, 0.57);
  box-sizing: border-box;
  width: 305.1px;
  height: 0.5px;
  z-index: 6;
`;
const NeedAdditonalHelp = styled.span``;
const ContactHrPortal = styled.span`
  text-decoration: underline;
  color: var(--color-gray-100);
`;
const NeedAdditonalHelpContainer = styled.div`
  position: absolute;
  margin: 0 !important;
  top: 315px;
  left: calc(50% - 100.35px);
  font-size: 8.4px;
  text-align: center;
  z-index: 7;
  color: #9c9c9c;
`;
const PasswordResetParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  gap: 16.8px;
`;
const ResetPasswordenterNewPassInner = styled.div`
  position: absolute;
  top: calc(50% - 147.5px);
  left: calc(50% - 157.5px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 10.26px;
  color: var(--color-darkslategray-200);
`;
const ResetPasswordenterNewPassRoot = styled.div`
  position: fixed;
  background-color: var(--color-whitesmoke);
  width: 100%;
  height: 100%;
 overflow: scroll;
  text-align: left;
  font-size: 18.39px;
  color: var(--color-gray-300);
  font-family: var(--font-poppins);
`;

const MobResetPasswordenterNewPass = () => {
  const url = window.location.href;
  const urlParams = new URLSearchParams(url.split("#")[1]);
  const accessToken = urlParams.get("access_token");
  let history = useNavigate();

  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

  const onLogo = () => {
    history("/");
  };

  const changePassword = async (event) => {
    event.preventDefault();

    if (new_password !== confirm_password) {
      alert("Passwords do not match");
      return;
    }

    try {
      const { data, error } = await supabase.auth.updateUser({
        password: new_password,
      });
      if (error) {
        throw Error(error.message);
      }
      if (data) {
        alert("Password changed");
        history("/login");
      }
    } catch (e) {
      alert(JSON.stringify(e.message));
    }
  };

  return (
    <ResetPasswordenterNewPassRoot>
      <FrameParent>
        <RecruiterRoboLogo31Wrapper>
          <RecruiterRoboLogo31Icon
            alt=""
            src="/recroid.png"
            onClick={onLogo}
            style={{ cursor: "pointer" }}
          />
        </RecruiterRoboLogo31Wrapper>
        <WebsiteName
          onClick={onLogo}
          style={({ cursor: "pointer" }, { color: "rgb(49 76 163)" })}
        >
          RecRoid
        </WebsiteName>
      </FrameParent>
      <form>
        <ResetPasswordenterNewPassInner>
          <PasswordResetParent>
            <PasswordReset>Password reset</PasswordReset>
            <PleaseEnterA>
              Please enter a new password for your HR Portal account.
            </PleaseEnterA>
            <Email>
              <InputLabelmedium>Enter new password</InputLabelmedium>
              <Component2>
                <Component2Child />
                <input
                  style={{
                    fontSize: "12px",
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    borderRadius: "var(--br-8xs-6)",
                    backgroundColor: "var(--color-white)",
                    border: "0.6px solid var(--color-gainsboro)",
                    boxSizing: "border-box",
                    width: "100%",
                    height: "29.9px",
                  }}
                  type="text"
                  placeholder="Enter New Password"
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                  required
                />
              </Component2>
            </Email>
            <Email1>
              <InputLabelmedium>Confirm new password</InputLabelmedium>
              <Component2>
                <Component2Child />
                <input
                  style={{
                    fontSize: "12px",
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    borderRadius: "var(--br-8xs-6)",
                    backgroundColor: "var(--color-white)",
                    border: "0.6px solid var(--color-gainsboro)",
                    boxSizing: "border-box",
                    width: "100%",
                    height: "29.9px",
                  }}
                  type="text"
                  placeholder="Confirm New Password"
                  required
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
              </Component2>
            </Email1>
            <YourPasswordMust>
              Your password must be at least 8 characters long. Avoid common
              words or patterns.
            </YourPasswordMust>
            <button type="submit" onClick={changePassword}>
              <Submit>
                <SubmitChild />
                <LogIn>Reset my password</LogIn>
              </Submit>
            </button>
            <FrameChild />
            <NeedAdditonalHelpContainer>
              <NeedAdditonalHelp>{`Need additonal help? `}</NeedAdditonalHelp>
              <ContactHrPortal>Contact HR Portal support</ContactHrPortal>
            </NeedAdditonalHelpContainer>
          </PasswordResetParent>
        </ResetPasswordenterNewPassInner>
      </form>
    </ResetPasswordenterNewPassRoot>
  );
};

export default MobResetPasswordenterNewPass;
