export const fetchProfileProfessionalData = (user_id) => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_PROFILE_PROFESSIONAL_DATA_REQUEST" });
    const apiUrl = `${process.env.REACT_APP_API_URL}/get_user_data_for_coverletter`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id }),
      });
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      const myData = data.data;
      dispatch({
        type: "FETCH_PROFILE_PROFESSIONAL_DATA_SUCCESS",
        payload: myData,
      });
    } catch (error) {
      dispatch({
        type: "FETCH_PROFILE_PROFESSIONAL_DATA_FAILURE",
        payload: error,
      });
      console.error("Error fetching user data:", error);
      return {};
    }
  };
};
