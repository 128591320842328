import { Img, Text } from "components";
import {
  AddCancelSection2,
  AddMoreSection,
  EditDeleteIconsSection,
  EditIcon,
  SaveCancleSection,
  ShowAllSection,
} from "pages/JobSeekerDashBoardPages/ProfileProfessional/IconsComponents";
import { useEffect, useState } from "react";
const EducationProfessional = (props) => {
  const { dataOfUser, setRootEducation, setUserPersonalData } = props;
  const [education, setEducation] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [selfEdit, setSelfEdit] = useState(false);
  const [showAllDetails, setShowAllDetails] = useState(false);
  const [editEducation, setEducationEdit] = useState(false);
  const [editDegree, setEditedDegree] = useState("");
  const [editInistition, seteditInistition] = useState("");
  const [editScore, setEditScore] = useState("");
  const [editedEducationDetails, setEditedEducationDetails] = useState([]);
  const [newInstitution, setNewInstitution] = useState("");
  const [newDegree, setNewDegree] = useState("");
  const [newScore, setNewScore] = useState("");
  const [isAddMoreClicked, setIsAddMoreClicked] = useState(false);

useEffect(() => {
  const timer = setTimeout(() => {
    try {
      const newRootEducation = [];
      const newEditedEducationDetails = [];
      const newEducation = [];

      Object.entries(dataOfUser).forEach(([key, experienceText]) => {
        if (
          key.includes("College or University") &&
          experienceText !== undefined &&
          experienceText !== ""
        ) {
          const institution = typeof experienceText === "object" ? experienceText.institution : experienceText;
          const degree = typeof experienceText === "object" ? experienceText.degree : "";
          const score = typeof experienceText === "object" ? experienceText.percentage || "Not Available" : "Not Available";
          
          const newItem = {
            institution,
            degree,
            score,
          };
          
          newRootEducation.push(newItem);
          newEditedEducationDetails.push(newItem);
          newEducation.push(newItem);
        }
      });

      setRootEducation(newRootEducation);
      setEditedEducationDetails(newEditedEducationDetails);
      setEducation(newEducation);

    } catch (error) {
      const newItem = {
        institution: "Not Available",
        degree: "Not Available",
        score: "Not Available",
      };

      setRootEducation([newItem]);
      setEditedEducationDetails([newItem]);
      setEducation([newItem]);
    }
  }, 5000);

  return () => clearTimeout(timer); // Cleanup function to clear the timer
}, [dataOfUser]);

  
  const toggleShowAll = () => {
    setShowAllDetails(!showAllDetails);
  };

  const [educationInitial, setEducationInitial] = useState(false);

  const educationExpInitially = () => {
    setEducationEdit(false);
    setEducationInitial(!educationInitial);
    setShowAllDetails(false);
    setSelfEdit(true);
    setEditingIndex(-1);
    setIsAddMoreClicked(false);
  };

  const editWorkButton = () => {
    setEducationEdit(true);
    setShowAllDetails(true);
    setSelfEdit(true);
  };

  const editItemButton = (index) => {
    setEditingIndex(index);
    setSelfEdit(false);
    setEditedDegree(editedEducationDetails[index].degree);
    seteditInistition(editedEducationDetails[index].institution);
    setEditScore(editedEducationDetails[index].score);
  };

  const saveChanges = () => {
    if (editingIndex !== -1) {
      const updatedEducationDetails = [...editedEducationDetails];
      updatedEducationDetails[editingIndex] = {
        institution: editInistition,
        degree: editDegree,
        score: editScore,
      };
      setEditedEducationDetails(updatedEducationDetails);
      setEducation(updatedEducationDetails);
      setRootEducation(updatedEducationDetails);

      if (updatedEducationDetails.length < 4) {
        for (let i = 0; i < updatedEducationDetails.length && i < 3; i++) {
          dataOfUser[`College or University ${i + 1} with degree`] =
            updatedEducationDetails[i];

          setUserPersonalData(dataOfUser);
        }
      }

      setEditingIndex(-1);

      ////console.log("Saved Education details", updatedEducationDetails);
    }
  };

  const handleAddMoreClick = () => {
    setIsAddMoreClicked(!isAddMoreClicked);
  };

  const newInstitutionInput = (e) => {
    setNewInstitution(e.target.value);
  };

  const newDegreeInput = (e) => {
    setNewDegree(e.target.value);
  };
  const newScoreInput = (e) => {
    setNewScore(e.target.value);
  };

  const addNewEducationExperience = () => {
    if (newInstitution && newDegree) {
      const newItem = {
        institution: newInstitution,
        degree: newDegree,
        score: newScore,
      };

      const updatedEducationDetails = [...editedEducationDetails];
      updatedEducationDetails.push(newItem);

      setEditedEducationDetails(updatedEducationDetails);
      setEducation(updatedEducationDetails);
      ////console.log("New Education details", updatedEducationDetails);
      setNewInstitution("");
      setNewDegree("");
      setNewScore("");
      setIsAddMoreClicked(false);

      if (updatedEducationDetails.length < 4) {
        for (let i = 0; i < updatedEducationDetails.length && i < 3; i++) {
          dataOfUser[`College or University ${i + 1} with degree`] =
            updatedEducationDetails[i];

          setUserPersonalData(dataOfUser);
        }
      }
    }
  };

  const deleteEducation = (index) => {
    const updatedEducationDetails = [...editedEducationDetails];
    updatedEducationDetails.splice(index, 1);

    setEducation(updatedEducationDetails);
    setRootEducation(updatedEducationDetails);
    setEditedEducationDetails(updatedEducationDetails);

    if (updatedEducationDetails.length < 4) {
      for (let i = 0; i < 3; i++) {
        if (updatedEducationDetails[i] === undefined) {
          dataOfUser[`College or University ${i + 1} with degree`] = undefined;
        }
        dataOfUser[`College or University ${i + 1} with degree`] =
          updatedEducationDetails[i];

        setUserPersonalData(dataOfUser);
      }
    }
  };

  const cancelChanges = () => {
    setEditingIndex(-1);
  };

  const cancelAdd = () => {
    setIsAddMoreClicked(false);
  };

  return (
    <div className="bg-white-A700 flex flex-col w-[94%] rounded-[20px]">
      <div className="flex flex-1 flex-row md:gap-10 items-center justify-between p-4">
        <div className="flex  items-center  flex-row gap-[18px] items-start justify-center ml-[33px]">
          <Img
            className="h-[25px] sm:h-[15px] sm:w-[15px] md:h-auto object-cover w-[25px]"
            src="images/edu.svg"
            alt="education-icon"
          />
          <Text
            className="mt-0.5 text-black-900 text-lg sm:text-[12px] mqlap:text-[1.05vw] mqtab1:text-[1.15vw]"
            size="txtInterSemiBold18"
          >
            Education and Qualification
          </Text>
          <Text></Text>
        </div>
        <div className="flex flex-row">
          {educationInitial && !editEducation && (
            <EditIcon onClick={editWorkButton} />
          )}
          <Img
            className="icon-park-outline sm:h-[15px] mqlap:h-[2.6vw]  mqtab1:h-[2.8vw]"
            onClick={educationExpInitially}
            alt="icon-park-outline"
            src={`https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-${
              educationInitial ? "down-1" : "down-2"
            }.svg`}
          />
        </div>
      </div>

      <div>
        {educationInitial && (
          <div >
            {editedEducationDetails &&
              education
                .slice(0, showAllDetails ? education.length : 2)
                .map((education, index) => (
                  <div key={index} className="ml-[3px]">
                    <div className="flex flex-row justify-between">
                      {index === editingIndex && !selfEdit ? (
                        <div className= "flex flex-row sm:flex sm:flex-col justify-between w-[80%] sm:w-[100%] gap-[9px] it mb-[30px] md:ml-[0] ml-[75px] md:px-5 md:w-full">
                          <div className="w-full sm:ml-[31px] sm:flex sm:flex-col">
                            <input
                              placeholder="Institution"
                              className="work-edit-input  sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                              value={editInistition}
                              onChange={(e) =>
                                seteditInistition(e.target.value)
                              }
                              required
                            ></input>

                            <input
                              placeholder="Degree"
                              className="work-edit-input  sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                              value={editDegree}
                              onChange={(e) => setEditedDegree(e.target.value)}
                              required
                            ></input>

                            <input
                              placeholder="Score Percentage"
                              className="work-edit-input  sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                              value={editScore}
                              onChange={(e) => setEditScore(e.target.value)}
                              required
                            ></input>
                          </div>

                          <SaveCancleSection
                            saveChanges={saveChanges}
                            cancleChanges={cancelChanges}
                          />
                        </div>
                      ) : (
                        <div className="w-[80%] flex flex-col gap-[10px] sm:gap-[5px]">
                          <Text className="pl-[90px]  sm:text-[10px] break-words overflow-hidden mqlap:text-[0.95vw] mqtab1:text-[0.95vw]"
                          >
                            {education.institution}
                          </Text>
                          <Text className="pl-[90px]  sm:text-[10px] break-words overflow-hidden mqlap:text-[0.95vw] mqtab1:text-[0.95vw]"
                          >
                            {education.degree}
                          </Text>
                          <Text className="pl-[90px]  sm:text-[10px] break-words overflow-hidden mqlap:text-[0.95vw] mqtab1:text-[0.95vw]"
                          >
                            {education.score}
                          </Text>
                        </div>
                      )}
                      {index !== editingIndex && editEducation ? (
                        <EditDeleteIconsSection
                          index={index} // Assuming you have index defined somewhere
                          editItemButton={editItemButton}
                          deleteWork={deleteEducation}
                        />
                      ) : null}
                    </div>
                    <div className="rectangle" />
                  </div>
                ))}
            {editEducation && showAllDetails && education.length < 3 ? (
              <AddMoreSection handleAddMoreClick={handleAddMoreClick} />
            ) : (
              <p
                className="sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[1vw]"
                style={{
                  color: "#314ca3",
                  paddingLeft: "40px",
                  paddingBottom: "20px",
                }}
              >
                Kindly provide the most recent three education details{" "}
              </p>
            )}
            {isAddMoreClicked && education.length < 3 && (
              <div className="flex flex-row sm:flex sm:flex-col justify-between w-[80%] sm:w-[100%] gap-[9px] it mb-[30px] md:ml-[0] ml-[75px] md:px-5 md:w-full">
                <div className="w-full sm:ml-[31px] sm:flex sm:flex-col">
                  <input
                    placeholder="Institution"
                    className="work-edit-input  sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                    value={newInstitution}
                    onChange={newInstitutionInput}
                    required
                  ></input>
                  <input
                    placeholder="Degree"
                    className="work-edit-input  sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                    value={newDegree}
                    onChange={newDegreeInput}
                    required
                  ></input>
                  <input
                    placeholder="score percentage"
                    className="work-edit-input  sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                    value={newScore}
                    onChange={newScoreInput}
                    required
                  ></input>
                </div>
                <AddCancelSection2
                  addNewWorkExperience={addNewEducationExperience}
                  cancelAdd={cancelAdd}
                  page="Education"
                />
              </div>
            )}
            {!showAllDetails && (
              <ShowAllSection
                arrayLength={education.length}
                toggleShowAll={toggleShowAll}
                textTag={"education"}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EducationProfessional;
