import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { Text, Img } from "components"; // Assuming Text is a custom component you've defined

const InterviewDataTable = ({
  displayedRows,
  currentPage,
  tableData,
  itemsPerPage,
  handlePageChange,
}) => {
  const downloadPDF = async (interviewId) => {
    try {
      var storedData = localStorage.getItem(
        `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`,
      );
      var parsedData = JSON.parse(storedData);
      var userId = parsedData.user.id;
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/download_interview_report?user_id=${userId}&job_id=${interviewId}`,
      );
      if (!response.ok) {
        throw new Error("Failed to download PDF");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "InterviewPreparationReport.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      // Handle error
    }
  };
  if (displayedRows.length === 0) {
    return (
      <div className="overflow-auto mb-[30px] w-full flex justify-center items-center">
        <Text   className="sm:ml-[0] sm:text-[12px] sm:mt-0 my-0.5 text-base text-blue_gray-400 mqtab1:text-[1.15vw] mqlap:text-[1.1vw] mqtab1:p-3"
                  size="txtInterSemiBold16">No interview data available</Text>
      </div>
    );
  }
  return (
    <div className=" mb-[30px] w-full">
      <TableContainer component={Paper} className="w-full">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Text
                  className="sm:ml-[0] sm:mt-0 my-0.5 text-base text-blue_gray-400 cursor-pointer mqlap:text-[0.9vw] mqtab1:text-[0.95vw]"
                  size="txtInterSemiBold16"
                >
                  S. No.
                </Text>
              </TableCell>
              <TableCell>
                <Text
                  className="sm:ml-[0] sm:mt-0 my-0.5 text-base text-blue_gray-400 cursor-pointer mqlap:text-[0.9vw] mqtab1:text-[0.95vw]"
                  size="txtInterSemiBold16"
                >
                  Interview type
                </Text>
              </TableCell>
              <TableCell>
                <Text
                  className="sm:ml-[0] sm:mt-0 my-0.5 text-base text-blue_gray-400 cursor-pointer mqlap:text-[0.9vw] mqtab1:text-[0.95vw]"
                  size="txtInterSemiBold16"
                >
                  Role
                </Text>
              </TableCell>
              <TableCell>
                <Text
                  className="sm:ml-[0] sm:mt-0 my-0.5 text-base text-blue_gray-400 cursor-pointer mqlap:text-[0.9vw] mqtab1:text-[0.95vw]"
                  size="txtInterSemiBold16"
                >
                  Performance
                </Text>
              </TableCell>
              <TableCell>
                <Text
                  className="sm:ml-[0] sm:mt-0 my-0.5 text-base text-blue_gray-400 cursor-pointer mqlap:text-[0.9vw] mqtab1:text-[0.95vw]"
                  size="txtInterSemiBold16"
                >
                  Score
                </Text>
              </TableCell>
              <TableCell>
                <Text
                 className="sm:ml-[0] sm:mt-0 my-0.5 text-base text-blue_gray-400 cursor-pointer mqlap:text-[0.9vw] mqtab1:text-[0.95vw]"
                  size="txtInterSemiBold16"
                >
                  Date
                </Text>
              </TableCell>
              <TableCell>
                <Text
                  className="sm:ml-[0] sm:mt-0 my-0.5 text-base text-blue_gray-400 cursor-pointer mqlap:text-[0.9vw] mqtab1:text-[0.95vw]"
                  size="txtInterSemiBold16"
                >
                  Download Report
                </Text>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedRows.map((row) => (
              <TableRow key={row.sno}>
                <TableCell>
                  <Text className="mqlap:text-[0.8vw] mqtab1:text-[0.85vw]">{row.sno}</Text>
                </TableCell>
                <TableCell>
                  <Text className="mqlap:text-[0.8vw] mqtab1:text-[0.85vw]">{row.interviewMode}</Text>
                </TableCell>
                <TableCell>
                  <Text className="mqlap:text-[0.8vw] mqtab1:text-[0.85vw]">{row.Role}</Text>
                </TableCell>
                <TableCell>
                  <Text className="mqlap:text-[0.8vw] mqtab1:text-[0.85vw]">{row.Performance}</Text>
                </TableCell>
                <TableCell>
                  <Text className="mqlap:text-[0.8vw] mqtab1:text-[0.85vw]">{row.Rating}</Text>
                </TableCell>
                <TableCell>
                  <Text className="mqlap:text-[0.8vw] mqtab1:text-[0.85vw]">{row.Date}</Text>
                </TableCell>
                <TableCell>
                  <Img
                    className="mt-px pl-12 mqlap:h-4 mqtab1:h-[0.9rem]"
                    src="images/img_octicondownload162.svg"
                    alt="octicon:download-16"
                    onClick={() => downloadPDF(row.JobID)} // interviewId
                    style={{ cursor: "pointer" }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="w-full flex flex-row justify-center ">
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          className="flex flex-row justify-center items-center pt-[27px]"
          page={currentPage}
          count={Math.ceil(tableData.length / itemsPerPage)}
          onChange={(event, pageNumber) => handlePageChange(event, pageNumber)
          }
        />
      </div>
    </div>
  );
};

export default InterviewDataTable;
