// BillingOverview.js
import { Text } from "components";
import MainLoader from "components/Loader";
import BillingSubHeader from "pages/JobSeekerDashBoardPages/BillingSubHeader";
import { ProfileHeader } from "pages/JobSeekerDashBoardPages/ProfileHeader";
import { SideBar1 } from "pages/JobSeekerDashBoardPages/SideBar1";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PaidUser from "./paiduser";
import UnPaidUser from "./unPaidUser";

const BillingOverview = () => {
  const accountType = useSelector((state) => state.profileAccountType.accountType);
  const user_id = useSelector((state) => state.profilePersonalData.personalData.id);
  const [currentPlan, setCurrentPlan] = useState("");
  const [planStartedAt, setPlanStartedAt] = useState("");
  const [planExpireAt, setPlanlExpireAt] = useState("");
  const [isLoading, setIsLoading] = useState(true); // State to track loading
  const {subscriptionData} = useSelector(state=>state.subscriptionReducer)
  const dispatch = useDispatch();


  useEffect(() => {
    if (subscriptionData) {
        setCurrentPlan(subscriptionData.current_plan);
        setPlanStartedAt(subscriptionData.trial_started_at);
        setPlanlExpireAt(subscriptionData.trial_expire_at);
        setIsLoading(false);
    }
  }, [subscriptionData,accountType]);





  const notify = () => toast.info("It cannot be changed, it shows if you are on an active paid plan.", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });

  return (
    <>
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <SideBar1 clickedTab={"Billing"} />
          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full h-[100vh]">
            <ProfileHeader clickedTab={"Billing"} />
            <div className="h-[92vh] overflow-scroll bg-white-A700 flex flex-col items-center justify-start p-7 sm:px-5 rounded-[15px] w-[94%] md:w-full">
              <div className="flex flex-col gap-[20px] justify-start mt-2.5 w-[99%] md:w-full">
                <BillingSubHeader billingheader={1} />
                <div className="flex items-center gap-[5px] mt-[40px] mb-[20px]">
                  <img src="images/Vector.png"  className="mqlap:w-[2.1vw] mqtab1:w-[2.1vw]"/>
                  <Text className="text-[20px] font-medium mqlap:text-[1.06vw] mqtab1:text-[1.12vw]">Smart Job Seeker Plan</Text>
                  <div>
                    <div className="checkbox-wrapper-22 ml-[15px]">
                      <label className="switch mqlap:w-[50px] mqlap:h-[33px]" htmlFor="checkbox" >
                        <input
                          onClick={notify}
                          type="checkbox"
                          checked={accountType === 1} // Check if accountType is 1
                          id="checkbox"
                          readOnly={true}
                        />
                        <div className="slider round"></div>
                      </label>
                    </div>
                  </div>
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                    transition={Bounce}
                  />
                </div>
                {accountType === 1 ? (
                  isLoading ? (
                    <MainLoader />
                  ) : (
                    <PaidUser user_id={user_id} currentPlan={currentPlan} planStartedAt={planStartedAt} planExpireAt={planExpireAt} />
                  )
                ) : (
                  <UnPaidUser />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingOverview;
