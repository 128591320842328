const initialState = {
    autoJobData: [],
    loading: true,
    error: null
};

const autoJobData = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_AUTO_JOB_DETAILS_REQUEST":
            return { ...state, loading: true, error: null };
        case "FETCH_AUTO_JOB_DETAILS_SUCCESS":
            return { ...state, loading: false, autoJobData: action.payload };
        case "FETCH_AUTO_JOB_DETAILS_FAILURE":
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
}
export default autoJobData;