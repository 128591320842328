import React from "react";
import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Text ,Button} from "components";

const InstructionPage = ({ backToOptions, handleContinue }) => {
  return (
    <div className="bg-gray-100 h-full">
      <AppBar style={{ backgroundColor: "#314ca3" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Please Read Instructions Carefully
          </Typography>
          <IconButton edge="start" color="inherit" onClick={backToOptions} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className="text-gray-500 gap-[50px] h-[100vh] overflow-scroll flex flex-col justify-center items-center">
        <div style={{ backgroundColor: "#ffffff" }} className="pl-[4.5%] pr-[2%] w-[50vw] h-auto pb-[40px]">
          <Text className="text-xl pb-[40px] pt-[25px]" size="txtInterMedium20">
            Instructions
          </Text>
          <ul className="list-decimal w-full space-y-8">
            <li className="mb-5">
              Prepare to receive tailored career guidance by answering five essential questions regarding your preferences, financial considerations, and aspirations.
            </li>
            <li className="mb-5">
              Maximize the accuracy of your advice by completing your profile thoroughly. This includes detailing your academic background, professional experiences, skills, and any notable projects you've undertaken.
            </li>
            <li className="mb-5">
              Prior to the session, ensure a stable internet connection to facilitate a smooth and uninterrupted experience.
            </li>
            <li className="mb-5">
              At the conclusion of the session, you'll have the option to download a comprehensive report or advice summary for your reference.
            </li>
          </ul>
        </div>
        <Button onClick={handleContinue} className="w-[200px] h-[40px]">
          Start Assessment
        </Button>
      </div>
    </div>
  );
};

export default InstructionPage;
