import { Tooltip, Typography } from "@mui/material";
import { Button, Text } from "components";
import "./index.css";
import DOMPurify from "dompurify";
function formattedDate(originalDate) {
  const dateObject = new Date(originalDate);

  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1; // Months are zero-indexed
  const day = dateObject.getDate();
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();

  // Creating a formatted date string
  const formattedDate = `${day}/${month}/${year} at ${hours}:${minutes}`;
  return formattedDate;
}

const styles = {
  tooltip: {
    maxWidth: "200px",
  },
  tooltipContent: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
};

function truncateText(text, wordLimit) {
  const words = text.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return text;
}

const JobCard = ({ displayedRows ,dataStatus}) => {
  if (displayedRows.length === 0) {
    return (
      <div className="overflow-auto mb-[30px] w-full flex justify-center items-center">
        <Text
          className="sm:ml-[0] sm:text-[12px] sm:mt-0 my-0.5 text-base text-blue_gray-400"
          size="txtInterSemiBold16">
       No Job Feed available.
        </Text>
      </div>
    );
  }

  return (
    <>
      {displayedRows &&
        displayedRows.map((data, index) => {
          const truncatedDescription = truncateText(data.jobDescription, 100);
          return (
            <div
              key={index}
              className="grid grid-cols-4  grow flex flex-col justify-between items-center h-auto bg-gray-100 rounded-[12px]"
            >
              <div className="border-2 grow border-blue-500  w-[95%]  mt-[20px] h-[300px] bg-white-A700 rounded-[12px] mqlap:h-[16.6vw] mqtab1:h-[15.8vw]">
                <div className="flex flex-col gap-[5px] w-[85%]  ml-[15px] mt-[15px] h-auto border-l-4 border-blue-500 pl-2">
                  <Text
                    className=" font-medium mqlap:text-[0.9vw] mqtab1:text-[0.87vw]"
                    size="txtInterBold12"
                  >
                    {data.companyName}
                  </Text>
                  <Text
                    className=" w-full text-[12px] font-semibold overflow-clip mqlap:text-[0.73vw] mqtab1:text-[0.7vw]"
                    size="txtInterBold12"
                  >
                    {data.jobRole}
                  </Text>
                </div>
                <div className="flex items-center ml-[-3.5px] mt-[12px]">
                  <Tooltip
                    title={
                      <Typography
                        style={{ tooltipContent: styles.tooltipContent }}
                        fontSize={14}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(truncatedDescription),
                        }}
                      />
                    }
                    placement="right"
                    arrow
                    classes={{ tooltip: styles.tooltip }}
                    className="flex items-center"
                  >
                    <img src="images/infoBlue.svg" alt="info" className="w-[1.1vw]"/>
                    <Text className="text-[14px] font-bold text-blue_gray-400 pl-[10px] mqlap:text-[0.75vw] mqtab1:text-[0.75vw]">
                      Job Description
                    </Text>
                  </Tooltip>
                </div>

                {/* <div className="text-[14px] pr-[15px] pt-[10px] pl-[20px] pb-[20px] line-clamp-2">
              {data.jobDescription}
            </div> */}
                <div
                  className="text-[#000] text-[14px]  pr-[15px] pt-[10px] pl-[20px] pb-[20px] line-clamp-2 mqlap:text-[0.73vw] mqtab1:text-[0.8vw] mqlap:h-[3.3vw] mqtab1:h-[3.6vw] overflow-hidden"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(data.jobDescription),
                  }}
                />

                <div className=" mqtab1:gap-[5px] gap-[8px] flex flex-col ml-[10px] mb-[10px] mt-[10px]">
                  <div className="flex items-center gap-[7px] ">
                    <img src="images/locationIcon.svg" alt="location" className="w-[1.08vw]" />
                    <Text className="text-[12px] font-semibold text-blue_gray-400 mqlap:text-[0.73vw] mqtab1:text-[0.8vw]">
                      {data.jobLocation}
                    </Text>
                  </div>
                  <div className="flex items-center gap-[7px]">
                    <img src="images/calenderIcon.svg" alt="calendar" className="w-[1.05vw]" />
                    <Text className="text-[13px] font-semibold text-blue_gray-400  mqlap:text-[0.73vw] mqtab1:text-[0.8vw] ">
                      {formattedDate(data.jobPosted)}
                    </Text>
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-end mr-[15px] mt-[20px] mb-[20px] mqtab1:mt-[16px] mqtab1:mb-[16px]">
                <a href={data.jobUrl} target="_blank" rel="noopener noreferrer">
                  <Button className="mqlap:text-[0.9vw]  mqtab1:text-[0.9vw] text-center px-[1.5vw] py-[0.5vw] mqtab1:px-[1.7vw] mqlap:px-[1.6vw] rounded-[14px] mqtab1:rounded-[11px] mqlap:rounded-[11px]">Apply</Button>
                </a>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default JobCard;
