import { Button, Text } from 'components';
import { useState } from 'react';
import Modal from 'react-modal';

const PopupModal = ({ isOpen, onClose }) => {
  
  const [selectedFilters, setSelectedFilters] = useState(1);
  const handleCheckboxChange = (index) => {
    setSelectedFilters(index);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Popup Modal"
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
        content: {
          width: '60%',
          height: '60%',
          margin: 'auto',
          overflow: 'hidden',
        },
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
        <div style={{ padding: '20px', borderBottom: '3px solid grey', width: '100%' }}>
          <div className="flex flex-row justify-between md:gap-10 items-center justify-between w-full md:w-full">
            <div className="flex flex-row h-full w-[100%]">
              <Text className="text-blue_gray-400 text-[28px]" size="txtInterMedium20">
                Filter by
              </Text>
            </div>

            <div className="flex flex-row items-end justify-between w-[80%]">
              <Button
                className="cursor-pointer font-medium  leading-[normal] text-center text-xl w-[220px] h-[50px]"
              //onClick={() => setSelectedFilters([])} // Reset selected filters
              >
                Reset
              </Button>
              <Button
                className="cursor-pointer font-medium  leading-[normal] text-center text-xl text-xl w-[220px] h-[50px] ml-[50px]"
                onClick={onClose} // Close the modal
              >
                Apply
              </Button>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', height: '80%', width: "100%" }}>
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: "200px", justifyContent: 'space-around', borderRight: '3px solid grey' }}>
            {[
              'Designation',
              'Experience in years',
              'College/University',
              'Degree',
              'School',
              'Location',
              'Technical skills',
              'Interpersonal Skills',
            ].map((filter, index) => (
              <Text
                key={index}
                // className={`text-xl ${
                //   selectedFilters.includes(index) ? 'text-red-500' : 'text-gray-500'
                // }`}
                size="txtInterRegular20Gray500"
                onClick={() => handleCheckboxChange(index)}
                style={{
                  cursor: 'pointer', fontSize: "20px",
                  color: selectedFilters === index ? '#314ca3' : 'gray',
                }}
              >
                {filter}
              </Text>
            ))}
          </div>
          <div className='w-[80%] flex flex-col gap-[30px]'>
            <input
              type='search'
              placeholder='Search'
              className='mt-[40px] w-[50%] rounded-[10px] mb-[20px] ml-[50px]'
            />
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: '10px',
                marginTop: '20px',
              }}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
                <div key={index} style={{ marginBottom: "40px" }}>
                  <input
                    type='checkbox'
                    id={`checkbox-${index}`}

                    className='ml-5'
                    style={{ width: '25px', height: '25px' }}
                  />
                  <label
                    //className={`text-xl ${selectedFilters.includes(index) ? 'text-red-500' : 'text-gray-500'}`}
                    size='txtInterRegular20Gray500'
                    htmlFor={`checkbox-${index}`}
                    style={{ fontSize: '20px', marginLeft: '5px' }}
                  >{`Label ${index}`}</label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PopupModal;
