import './index.css';

const PopupBg = ({ isOpen, onClose }) => {
    const heading1Style = {
        fontSize: '24px',
        color: '#000', // Black color
    };

    const heading2Style = {
        fontSize: '20px',
        color: '#000', // Black color
    };

    const textStyle = {
        color: '#666', // Grey color
    };
    return (
        <>
            {isOpen && (

                <div className='popup flex flex-col justify-evenly ' style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.2)', // Adjust the opacity and color as needed
                    },
                    content: {
                        backgroundColor: 'white',
                        width: '450px',
                        height: '200px',
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                    },
                }} >
                    <h1 style={heading1Style}>Background Check</h1>
                    <p style={textStyle}>Congratulations! Albert’s background check has been done.</p>

                    <div>
                        <h2 style={heading2Style}>Github</h2>
                        <p style={textStyle}>About Github The Total Economic Impact™ Of GitHub Enterprise Cloud and Advanced Security, a commissioned study conducted by Forrester Consulting, 2022. Results are for a composite organization based on interviewed customers.</p>
                    </div>

                    <div>
                        <h2 style={heading2Style}>Facebook</h2>
                        <p style={textStyle}>About Github The Total Economic Impact™ Of GitHub Enterprise Cloud and Advanced Security, a commissioned study conducted by Forrester Consulting, 2022. Results are for a composite organization based on interviewed customers.</p>
                    </div>

                    <div>
                        <h2 style={heading2Style}>Twitter</h2>
                        <p style={textStyle}>About Github The Total Economic Impact™ Of GitHub Enterprise Cloud and Advanced Security, a commissioned study conducted by Forrester Consulting, 2022. Results are for a composite organization based on interviewed customers.</p>
                    </div>

                    <div>
                        <h2 style={heading2Style}>Behance</h2>
                        <p style={textStyle}>About Github The Total Economic Impact™ Of GitHub Enterprise Cloud and Advanced Security, a commissioned study conducted by Forrester Consulting, 2022. Results are for a composite organization based on interviewed customers.</p>
                    </div>

                    <div>
                        <h2 style={heading2Style}>Other website</h2>
                        <p style={textStyle}>About Github The Total Economic Impact™ Of GitHub Enterprise Cloud and Advanced Security, a commissioned study conducted by Forrester Consulting, 2022. Results are for a composite organization based on interviewed customers.</p>
                    </div>
                    <div className='border border-red-500 border-solid text-red-500 text-lg text-center' onClick={onClose}>
                        close
                    </div>
                </div>
            )}
        </>
    );
};

export default PopupBg;
