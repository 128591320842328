import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
  Typography
} from "@mui/material";
import { Button, Text } from "components";

const PresubmitPopup = ({ open, close, handleGetAdvice }) => {
  return (
    <Dialog
      maxWidth="sm"
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
    >
       <div className="mqlap:w-[33.2vw] mqtab1:w-[35.2vw]">
       <div>

       <div className="flex flex-row items-center h-[71px] mqlap:h-[3.55vw] mqtab1:h-[3.78vw] mqtab2:h-[45px]">
          <Text
              className="pl-[4.5%] mt-0.5 text-black-900 text-xl sm:text-[12px]   mqlap:text-[1.05vw] mqtab1:text-[1.15vw] mqtab2:text-[1.4vw]"
            size="txtInterSemiBold18"
          >
            Confirm submission of test?
          </Text>
          <Tooltip
            title={
              <Typography fontSize={18}>
                <p className="text-[16px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] mqtab2:text-[1.02vw]">
                  Click submit button to submit test
                  </p>
              </Typography>
            }
            placement="right"
            arrow
            className="relative flex flex-col items-center justify-start md:h-[84px] gap-2 md:w-[250px]   rounded-[15px] transition-colors hover:bg-gray-100 hover:text-[40px]"
          >
            <img src="images/infoIcon.svg" 
            alt="infoIcon" 
            className="mt-[2.7px] mqlap:mt-[2px] mqtab1:mt-[2.65px] mqtab1-[2px] ml-[2.5px] h-[1.2rem] w-[1.25rem] mqlap:w-[1.1vw] mqlap:h-[1.05rem] mqtab1:w-[1.09vw] mqtab1:h-[1.09rem] mqtab2:w-[1.3vw] mqtab2:h-[1.3rem]"
            />
          </Tooltip>
        </div>
        <hr
          style={{ height: "1px", backgroundColor: "grey", border: "none", width: "100%" }}
        />
      </div>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        <p className="pl-[0.5%] text-[1.05rem] mqlap:text-[0.92vw] mqtab1:text-[0.96vw] mqtab2:text-[1.02vw] leading-6 mqlap:leading-[1.45vw] mqtab1:leading-[1.5vw]">
          Review your answers carefully before submitting. Ensure accuracy and completeness for the best outcome.
        </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="mb-[0.57rem] mqtab1:mb-[0.43rem]  mqlap:mb-[0.5rem] mt-[0.2vw] mqtab1:mt-[0.1vw] ">
        <button
          style={{ borderColor: " blue", color: "blue" }}
          className=" mqtab1:border-[0.1vw]  mqlap:border-[0.1vw] border-[1.9px] border-solid flex justify-center items-center h-[2.37rem] w-[7.3rem] mqlap:h-[2vw] mqlap:w-[6.35vw] mqtab1:h-[2.1vw] mqtab1:w-[6.7vw] mqtab2:h-[2.35vw] mqtab2:w-[7.4vw] rounded-[13px] sm:text-[14px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] mqtab2:text-[0.95vw] mqlap:rounded-[0.73vw] mqtab1:rounded-[0.73vw] mqtab2:rounded-[9px]"
          onClick={close}
        >
          Close
        </button>
        <Button
          onClick={handleGetAdvice}
          className="flex justify-center items-center mr-[30px] mqlap:mr-[1.8vw] mqtab1:mr-[1.9vw] h-[2.38rem] w-[7.3rem] mqlap:h-[2.05vw] mqlap:w-[6.5vw] mqtab1:h-[2.15vw] mqtab1:w-[6.7vw] mqtab2:h-[2.36vw] mqtab2:w-[7.4vw] rounded-[12px] sm:text-[14px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] mqtab2:text-[0.95vw] mqlap:rounded-[0.73vw] mqtab1:rounded-[0.73vw] mqtab2:rounded-[9px]"
          autoFocus
        >
          Submit
        </Button>
      </DialogActions>
      </div>

    </Dialog>
  );
};

export default PresubmitPopup;
