import supabase from "components/superBaseConfig";
import Cookies from "js-cookie";
import { useCallback, useState } from "react";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getEnvURL } from "utils/getEnvURL";

const frontEndURL = getEnvURL();
const RecruiterRoboLogo31Icon = styled.img`
  position: absolute;
  top: 8.1px;
  left: 0px;
  width: 35px;
  height: 41px;
  object-fit: cover;
`;
const RecruiterRoboLogo31Wrapper = styled.div`
  position: relative;
  width: 68.5px;
  height: 82.5px;
  overflow: hidden;
  flex-shrink: 0;
`;
const WebsiteName = styled.div`
  position: relative;
  color: #161616;
  line-height: 135.5%;
  font-weight: 600;
  display: inline-block;
  width: 150.5px;
  height: 24.1px;
  flex-shrink: 0;
  top: 7px;
  right: 33px;
  font-size: 9.5px;
`;
const FrameParent = styled.div`
  position: absolute;
  top: 30px;
  left: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const SignInTo = styled.div`
  position: relative;
  font-size: 18.97px;
  line-height: 30.83px;
  font-weight: 600;
  text-align: center;
`;
const InputLabelmedium = styled.div`
  position: absolute;
  top: 0%;
  left: calc(50% - 156.5px);
  line-height: 17.19px;
`;
const GroupChild = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 156.5px);
  border-radius: 4.74px;
  background-color: var(--color-white);
  border: 0.6px solid var(--color-gainsboro);
  box-sizing: border-box;
  width: 313px;
  height: 30.8px;
`;
// const InputPlaceholdermediumon = styled.div`
//   position: absolute;
//   top: 25%;
//   left: calc(50% - 149.4px);
//   line-height: 15.41px;
// `;
const RectangleParent = styled.div`
  position: absolute;
  top: 17.8px;
  left: calc(50% - 156.5px);
  width: 313px;
  height: 30.8px;
  font-size: 9.48px;
  color: var(--color-darkgray-100);
`;
const Email = styled.div`
  position: relative;
  width: 313px;
  height: 48.6px;
`;
const RectangleGroup = styled.div`
  position: absolute;
  top: 17.8px;
  left: calc(50% - 156.5px);
  width: 313px;
  height: 30.8px;
  font-size: 9.48px;
  color: var(--color-silver-100);
`;
const SubmitChild = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 156.5px);
  border-radius: 3.56px;
  background-color: var(--color-darkslateblue);
  width: 313px;
  height: 29.6px;
`;
const LogIn = styled.div`
  position: absolute;
  top: calc(50% - 7.7px);
  left: calc(50% - 16.6px);
  line-height: 15.41px;
`;
const Submit = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 156.5px);
  width: 313px;
  height: 29.6px;
`;
const SignIn = styled.span`
  text-decoration: underline;
`;
const AlreadyHaveAnContainer = styled.div`
  position: absolute;
  top: 43.3px;
  left: 82.4px;
  line-height: 15.41px;
  text-align: left;
  font-family: var(--font-poppins);
  color: var(--color-gray-400);
`;
const SubmitParent = styled.div`
  position: relative;
  width: 313px;
  height: 58.7px;
  text-align: center;
  font-size: 9.48px;
  color: var(--color-white);
`;
const SignInToDhiwiseParent = styled.div`
  position: absolute;
  top: 166px;
  left: calc(50% - 156.5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 14.23px;
  font-size: 10.67px;
  color: var(--color-gray-200);
  font-family: var(--font-inter);
`;
const SignupMobileRoot = styled.div`
  position: fixed;
  background-color: var(--color-whitesmoke);
  width: 100%;
  height: 100vh;
  overflow: scroll;
  text-align: left;
  font-size: 18.39px;
  color: var(--color-gray-300);
  font-family: var(--font-poppins);
`;

const MobSignupMobile = () => {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const changeVisibility = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
  
    try {
      const { data, error } = await supabase.auth.signUp({
        email: email,
        password: password,
        options: {
          redirectTo: `${frontEndURL}/jobseeker-dashboard`,
          data: { 
            firstName: firstName,
            lastName: lastName,
          },
        },
      });
  
      if (error) {
        throw error;
      }
  
      if (data.session) {
        const userId = data.user.id;
        const accessToken = JSON.stringify(data.session.access_token);
  
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/create-session`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: userId,
            }),
          },
        );
  
        const sessionData = await response.json();
        const sessionId = sessionData.sessionId;
  
        Cookies.set("accessToken", accessToken, { expires: 7 });
        Cookies.set("sessionId", sessionId, { expires: 7 });
  
        localStorage.setItem(
          `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`,
          JSON.stringify(data["session"]),
        );
  
      window.location.href = `${frontEndURL}/jobseeker-dashboard`;
      } else {
        alert("Please check your email for verification.");
        navigate("/login");
      }
    } catch (error) {
      if (
        error.error_code === "user_already_exists" ||
        error.toString().includes("User already registered")
      ) {
        alert("User already exists");
        navigate("/login");
      } else {
        alert("Please try again later");
      }
    }
  };

  const insertIntoDB = async () => {
    const fullName = `${firstName} ${lastName}`;
    try {
      await supabase.from("UserProfile").upsert(
        {
          username: fullName,
          password: password,
          email: email,
          linkedin: null,
        },
        { returning: "minimal" },
      );
    } catch (error) {
      alert("Please try again later");
    }
  };

  const OnSignInContainerClick = useCallback(() => {
    navigate("/login");
  }, [navigate]);
  const onLogo = useCallback(() => {
    navigate("/");
  }, [navigate]);
  return (
    <SignupMobileRoot>
      <FrameParent>
        <RecruiterRoboLogo31Wrapper>
          <RecruiterRoboLogo31Icon
            alt=""
            src="/recroid.png"
            onClick={onLogo}
            style={{ cursor: "pointer" }}
          />
        </RecruiterRoboLogo31Wrapper>
        <WebsiteName
          onClick={onLogo}
          style={{ cursor: "pointer", color: "rgb(49 76 163)" }}
        >
          RecRoid
        </WebsiteName>
      </FrameParent>
      <form onSubmit={handleSubmit}>
        <SignInToDhiwiseParent>
          <SignInTo>Sign up to RecRoid</SignInTo>
          <Email>
            <InputLabelmedium>First Name</InputLabelmedium>
            <RectangleParent>
              <GroupChild />
              <input
                style={{
                  fontSize: "10px",
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  borderRadius: "var(--br-8xs-6)",
                  backgroundColor: "var(--color-white)",
                  border: "0.6px solid var(--color-gainsboro)",
                  boxSizing: "border-box",
                  width: "100%",
                  height: "30.8px",
                  color: "black",
                }}
                type="text"
                placeholder="Enter First Name"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                required
              />
            </RectangleParent>
          </Email>
          <Email>
            <InputLabelmedium>Last Name</InputLabelmedium>
            <RectangleParent>
              <GroupChild />
              <input
                style={{
                  fontSize: "10px",
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  borderRadius: "var(--br-8xs-6)",
                  backgroundColor: "var(--color-white)",
                  border: "0.6px solid var(--color-gainsboro)",
                  boxSizing: "border-box",
                  width: "100%",
                  height: "30.8px",
                  color: "black",
                }}
                type="text"
                placeholder="Enter Last Name"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                required
              />
            </RectangleParent>
          </Email>
          <Email>
            <InputLabelmedium>Email Address</InputLabelmedium>
            <RectangleGroup>
              <GroupChild />
              <input
                style={{
                  fontSize: "10px",
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  borderRadius: "var(--br-8xs-6)",
                  backgroundColor: "var(--color-white)",
                  border: "0.6px solid var(--color-gainsboro)",
                  boxSizing: "border-box",
                  width: "100%",
                  height: "30.8px",
                  color: "black",
                }}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="Enter Email Address"
                required
              />
            </RectangleGroup>
          </Email>
          <Email>
            <InputLabelmedium>Enter Password</InputLabelmedium>
            <RectangleGroup>
              <GroupChild />
              <div className="pass">
                <input
                  style={{
                    fontSize: "10px",
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    // borderRadius: "var(--br-8xs-6)",
                    backgroundColor: "var(--color-white)",
                    border: "0.6px solid var(--color-gainsboro)",
                    boxSizing: "border-box",
                    width: "100%",
                    height: "30.8px",
                    // color: "grey",
                    borderRadius: "2px",
                    color: "black",
                  }}
                  type={type}
                  placeholder="Enter Password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  required
                />
                <div
                  style={{ position: "absolute", left: 280, top: 7 }}
                  className="flex  justify-end items-center pl-3 !border-colors !rounded-[12px] "
                  onClick={changeVisibility}
                >
                  <Icon icon={icon} size={16} style={{ color: "grey" }} />
                </div>
              </div>
            </RectangleGroup>
          </Email>
          <Email>
            <InputLabelmedium>Confirm Password</InputLabelmedium>
            <RectangleGroup>
              <GroupChild />
              <input
                style={{
                  fontSize: "10px",
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  // borderRadius: "var(--br-8xs-6)",
                  backgroundColor: "var(--color-white)",
                  border: "0.6px solid var(--color-gainsboro)",
                  boxSizing: "border-box",
                  width: "100%",
                  height: "30.8px",
                  // color: "grey",
                  borderRadius: "2px",
                  color: "black",
                }}
                type="password"
                placeholder="Enter Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </RectangleGroup>
          </Email>
          <SubmitParent>
            <button>
              <Submit style={{ cursor: "pointer" }}>
                <SubmitChild />
                <LogIn>Sign Up</LogIn>
              </Submit>
            </button>

            <AlreadyHaveAnContainer>
              {`Already have an account? `}
              <SignIn
                onClick={OnSignInContainerClick}
                style={{ cursor: "pointer" }}
              >
                Sign In
              </SignIn>
            </AlreadyHaveAnContainer>
          </SubmitParent>
        </SignInToDhiwiseParent>
      </form>
    </SignupMobileRoot>
  );
};

export default MobSignupMobile;
