import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { Button, Line, Text } from "components";
import MainLoader from "components/Loader";
import BillingSubHeader from "pages/JobSeekerDashBoardPages/BillingSubHeader";
import { ProfileHeader } from "pages/JobSeekerDashBoardPages/ProfileHeader";
import { SideBar1 } from "pages/JobSeekerDashBoardPages/SideBar1";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const BillingHistory = () => {
  const { paymentIntent, loading } = useSelector((state) => state.paymentIntentReducer);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [totalItems, setTotalItems] = useState(paymentIntent.length);

  useEffect(() => {
    setTotalItems(paymentIntent.length);
  }, [paymentIntent]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedRows = paymentIntent.slice(startIndex, endIndex);
  //console.log(displayedRows);

  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };
  

  return (
    <>
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <SideBar1 clickedTab={"Billing"} />
          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full h-[100vh]">
            <ProfileHeader clickedTab={"Billing"} />
            <div className="h-[92vh] overflow-scroll bg-white-A700 flex flex-col items-center justify-start p-7 sm:px-5 rounded-[15px] w-[94%] md:w-full">
              <div className="flex flex-col gap-[43px] justify-start  mt-2.5 w-[99%] md:w-full">
                <BillingSubHeader billingheader={3} />
                <div className="flex flex-col items-start justify-start md:ml-[0]  px-[3px] ">
                  <Text
                    className="text-gray-500 text-xl sm:text-sm mqlap:text-[1.05vw] mqtab1:text-[1.09vw]"
                    size="txtInterMedium20"
                  >
                    Showing invoices within the past 12 months
                  </Text>
                </div>
                {loading ? <MainLoader /> :

                ((displayedRows.length === 0)?
                  <div className="overflow-auto mb-[30px] w-full flex justify-center items-center">
        <Text   className="sm:ml-[0] sm:text-[12px] sm:mt-0 my-0.5 text-base text-blue_gray-400 mqlap:text-[1.05vw] mqtab1:text-[1.1vw]"
                  size="txtInterSemiBold16">No Billing History available</Text>
      </div>:
               
                  <TableContainer
                    component={Paper}
                    className="w-[93%] sm:w-[100vw] md:w-full mb-[50px]"
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Text
                              className="text-black-900 text-lg  sm:text-[10px] mqlap:text-[1.03vw] mqtab1:text-[1.05vw]"
                              size="txtInterMedium18"
                            >
                              Invoice
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              className="text-black-900 text-lg  sm:text-[10px] mqlap:text-[1.03vw] mqtab1:text-[1.05vw]"
                              size="txtInterMedium18"
                            >
                              Subscription
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              className=" text-black-900 text-lg sm:text-[10px] mqlap:text-[1.03vw] mqtab1:text-[1.05vw]"
                              size="txtInterMedium18"
                            >
                              Status
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              className="text-black-900 text-lg sm:text-[10px] mqlap:text-[1.03vw] mqtab1:text-[1.05vw]"
                              size="txtInterMedium18"
                            >
                              Amount
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              className="text-black-900 text-lg sm:text-[10px] mqlap:text-[1.03vw] mqtab1:text-[1.05vw]"
                              size="txtInterMedium18"
                            >
                              Created
                            </Text>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {displayedRows.map((invoice, i) => (
                          <TableRow key={invoice.id}>
                            <TableCell>
                              <Text
                                className="text-blue_gray-400 text-lg sm:text-[9px] mqlap:text-[1.03vw] mqtab1:text-[1.05vw]"
                                size="txtInterMedium18Bluegray400"
                              >
                                Invoice #{i + 1}
                              </Text>
                            </TableCell>
                            <TableCell>
                              <Text
                                className="text-blue_gray-400 text-lg sm:text-[9px] mqlap:text-[1.03vw] mqtab1:text-[1.05vw]"
                                size="txtInterMedium18Bluegray400"
                              >
                                {invoice.subscription_type}
                              </Text>
                            </TableCell>
                            <TableCell>
                              {invoice.status ?
                                <Button
                                  className="text-[#45745a] bg-[#b5e2b3] cursor-pointer  p-2  w-auto font-medium leading-[normal] text-center text-lg  sm:text-[9px] mqlap:text-[1.03vw] mqtab1:text-[1.05vw]"
                                >
                                  succeeded
                                </Button> : <Button
                                  className="text-red-500 bg-white border border-solid border-red-500 cursor-pointer p-2 w-auto font-medium leading-normal text-center text-lg sm:text-[9px] mqlap:text-[1.03vw] mqtab1:text-[1.05vw]"
                                  >
                                  Failed
                                </Button>}
                            </TableCell>
                            <TableCell>
                              <Text
                                className="text-black-900 text-lg sm:text-[9px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                                size="txtInterMedium18"
                              >
                                {invoice.amount}
                              </Text>
                            </TableCell>
                            <TableCell>
                              <Text
                                className="text-black-900 text-lg  sm:text-[9px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                                size="txtInterMedium18"
                              >
                                {invoice.date}
                              </Text>
                            </TableCell>
                            {/* <TableCell>
                              <Text
                                className="text-green-600 text-lg cursor-pointer  sm:text-[9px]"
                                size="txtInterMedium18Green600"
                              >
                                View
                              </Text>
                            </TableCell> */}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Line className="bg-blue_gray-100 h-px w-full" />
                  </TableContainer>)}
              </div>
              {
              (displayedRows.length !== 0)&&
              <Pagination
                color="primary"
                variant="outlined"
                shape="rounded"
                page={currentPage}
                count={Math.ceil(totalItems / itemsPerPage)}
                onChange={handlePageChange}
              />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingHistory;
