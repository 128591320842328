import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { legacy_createStore as createStore,applyMiddleware, compose } from "redux";
import { thunk } from "redux-thunk";  // Changed this line
import rootReducer from "./reducers";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./global.css";
import * as Sentry from "@sentry/react";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

Sentry.init({
  dsn: "https://1694e1aa4472802bb041897db870ea5f@o4507616305807360.ingest.us.sentry.io/4507616579682304",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "recroid.com", "https://frontend-dev-ssfw.onrender.com/"],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.REACT_APP_ENVIRONMENT
});

console.log("Environment: ", process.env.REACT_APP_ENVIRONMENT);

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk), sentryReduxEnhancer),
);


const gtmId = process.env.REACT_APP_GTM_ID_DEV;
if (gtmId) {
  const script1 = document.createElement('script');
  script1.async = true;
  script1.src = `https://www.googletagmanager.com/gtag/js?id=${gtmId}`;
  document.head.appendChild(script1);

  const script2 = document.createElement('script');
  script2.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${gtmId}');
  `;
  document.head.appendChild(script2);
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>);

reportWebVitals();
