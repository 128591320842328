import CancelIcon from "@mui/icons-material/Cancel";
import {
  Card,
  CardContent,
  IconButton,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Button, Text } from "components";
import MainLoader from "components/Loader";
import { ArrowRight } from "lucide-react";
import { useState } from "react";
import "./index.css";

import { ProfileHeader } from "pages/JobSeekerDashBoardPages/ProfileHeader";
import { SideBar1 } from "pages/JobSeekerDashBoardPages/SideBar1";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import link_image from "../../../assets/images/external-link.png";
const AutoJob = () => {
  const [filteredJobsData, setFilteredJobsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [jobsData, setJobsData] = useState([]);
  const [dataStatus, setDataStaus] = useState(false)
  const itemsPerPage = 8;
  const [displayedRows, setDisplayedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    platform: "",
    status: "",
    location: "",
  });
  const [sortBy, setSortBy] = useState({
    column: "",
    order: "asc",
  });
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const { autoJobData, loading } = useSelector((state) => state.autoJobData);
  const [isLoading, setIsLoading] = useState(loading);
  const getHrefLink = (job_id, platform) => {
    let link = "";
    switch (platform) {
      case "Careerjet":
        link = `https://www.careerjet.co.in${job_id}`;
        break;
      case "foundit.in":
        link = `https://www.foundit.in/job/${job_id}`;
        break;
      case "Freshersworld":
        link = `${job_id}`;
        break;
      default:
        break;
    }
    return link;
  };

  function convertUTCtoIST(utcDateString) {
    const utcDate = new Date(utcDateString);
    const istOptions = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "long",
      day: "numeric",
      // hour: 'numeric',
      // minute: '2-digit',
      // hour12: true // 12-hour format
    };
    const istDateString = utcDate.toLocaleString("en-IN", istOptions);
    return istDateString;
  }

  const getTimeInTimeZone = (time) => {
    if (time === "NA") return time;
    return convertUTCtoIST(time);
  };

  useEffect(() => {
    if (!jobsData) {
      setDataStaus(true)
    }
    const filteredAndSortedRows = jobsData
      .filter((job) => {
        // Filter by platform, status, and location
        const platformFilter = filters.platform
          ? job.platform === filters.platform
          : true;
        const statusFilter = filters.status
          ? job.status.toString() === filters.status
          : true;
        const locationFilter = filters.location
          ? job.location === filters.location
          : true;
        // Search in job title
        const searchFilter = searchText
          ? job.job_title.toLowerCase().includes(searchText.toLowerCase()) ||
          job.location.toLowerCase().includes(searchText.toLowerCase()) ||
          job.company_name.toLowerCase().includes(searchText.toLowerCase()) ||
          job.platform.toLowerCase().includes(searchText.toLowerCase())
          : true;

        return platformFilter && statusFilter && locationFilter && searchFilter;
      })
      .sort((a, b) => {
        // Sort by column and order
        const aValue = a[sortBy.column];
        const bValue = b[sortBy.column];
        if (aValue < bValue) return sortBy.order === "asc" ? -1 : 1;
        if (aValue > bValue) return sortBy.order === "asc" ? 1 : -1;
        return 0;
      });
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(
      startIndex + itemsPerPage,
      filteredAndSortedRows.length,
    );
    setDisplayedRows(filteredAndSortedRows.slice(startIndex, endIndex));
  }, [currentPage, jobsData, filters, sortBy, searchText]);

  const extractLocations = () => {
    const uniqueLocations = new Set();
    jobsData.forEach((job) => {
      uniqueLocations.add(job.location);
    });
    return Array.from(uniqueLocations);
  };
  const handleOpen = () => {

    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (Array.isArray(autoJobData) && autoJobData.length > 0) {
      setJobsData(autoJobData);
      setIsLoading(false);
    } else {
      // Handle error state when autoJobData is not an array (e.g., error message string)
      setIsLoading(false);
      // Optionally set an error message state to display to the user
    }
  }, [autoJobData]);

  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const filteredData = jobsData.filter((job) => {
      // Filter by platform, status, and location
      const platformFilter = filters.platform
        ? job.platform === filters.platform
        : true;
      const statusFilter = filters.status
        ? job.status.toString() === filters.status
        : true;
      const locationFilter = filters.location
        ? job.location === filters.location
        : true;
      // Search in job title
      const searchFilter = searchText
        ? job.job_title.toLowerCase().includes(searchText.toLowerCase())
        : true;
      return platformFilter && statusFilter && locationFilter && searchFilter;
    });

    setFilteredJobsData(filteredData);
  }, [jobsData, filters, sortBy, searchText]);

  const handleFilterChange = (name, value) => {
    //console.log(name, value)
    //  const { name, value } = event;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    setJobStatusFilter(false);
    setJobLocationFilter(false);
    setJobPlatformFilter(false);
  };

  const handleSortChange = (column) => {
    setSortBy((prevSortBy) => ({
      column,
      order:
        prevSortBy.column === column && prevSortBy.order === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const sortIcon = (column) => {
    if (sortBy.column === column) {
      return sortBy.order === "asc" ? <></> : <></>;
    }
    return null;
  };

  const [jobStatusFilter, setJobStatusFilter] = useState(false);

  const handleJobStatusFilter = () => {
    setJobStatusFilter(!jobStatusFilter);
    setJobLocationFilter(false);
    setJobPlatformFilter(false);
  };

  const [jobLocationFilter, setJobLocationFilter] = useState(false);

  const handleJobLocationFilter = () => {
    setJobLocationFilter(!jobLocationFilter);
    setJobStatusFilter(false);
    setJobPlatformFilter(false);
  };

  const [jobPlatformFilter, setJobPlatformFilter] = useState(false);

  const handleJobPlatformFilter = () => {
    setJobPlatformFilter(!jobPlatformFilter);
    setJobStatusFilter(false);
    setJobLocationFilter(false);
  };
  const [currentPlaceholderIndex, setCurrentPlaceholderIndex] = useState(0);
  const placeholders = [
    'Filter jobs by "Location" ',
    'Filter jobs by "Job Title" ',
    'Filter jobs by "Company" ',
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPlaceholderIndex(
        (prevIndex) => (prevIndex + 1) % placeholders.length,
      );
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  const handleResetFilter = () => {
    setFilters({
      platform: "",
      status: "",
      location: "",
    });
    setSearchText("");
  };
  return (
    <>
      {isLoading && <MainLoader />}
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <SideBar1 clickedTab={"Auto Job"} />
          <div className="flex flex-1 flex-col font-inter gap-[37px] max-h-screen items-center justify-start md:px-5 w-full overflow-hide">
            <ProfileHeader clickedTab={"Auto Job"} />
            <div className="h-[92vh] overflow-y-scroll bg-white-A700 flex flex-col items-center justify-start rounded-[15px] w-[94%] md:w-full">
              <div className="flex flex-col gap-[25px] items-center justify-start  w-full">
              <div className="flex flex-col gap-[21px] items-start p-4 justify-start mb-[55px] w-full md:w-full mqtab1:p-2">
                 
                  <div className="flex  flex-row sm:gap-10 items-center justify-between mb-1 w-full">
                    <div className="flex flex-row items-center  justify-start ">
                      <Text
                        className="pl-[5px] text-blue_gray-400 text-xl mqtab1:text-[1.2vw] mqlap:text-[1.15vw] mqtab1:p-3"
                        size="txtInterMedium20"
                      >
                        Auto Job
                      </Text>

                      <Tooltip
                        title={
                          <Typography fontSize={18}>
                            Job Feed is updated periodically, after entering Job Preferences.
                          </Typography>
                        }
                        placement="right"
                        arrow
                        className="w-[30px] h-[30px] relative flex flex-col items-center justify-start md:h-[84px] gap-2 md:w-[250px]   rounded-[15px] transition-colors hover:bg-gray-100 hover:text-[40px]"
                      >
                        <img
                          src="images/infoIcon.svg"
                          alt="infoIcon"
                          className="pl-[5px]"
                        />
                      </Tooltip>

                    </div>

                    <Dialog open={open} maxWidth="xl" disableBackdropClick={true}>

                      <Card
                        sx={{
                          width: { xs: "80vw", md: "40vw" }, // Responsive width
                          height: { xs: "auto", md: "30vh" }, // Responsive height
                          margin: "auto", // Center horizontally
                          padding: 2, // Padding inside the card
                          backgroundColor: "#f0f8ff", // Light blue background
                          border: "1px solid #ccc", // Border
                          boxShadow: 3, // Box shadow for depth
                          position: "relative", // For positioning the close button
                          textAlign: "center",
                        }}
                      >
                        {/* Close button */}
                        <IconButton
                          aria-label="close"
                          onClick={handleClose}
                          sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            color: "#999", // Grey color for the icon
                          }}
                        >
                          <CancelIcon />
                        </IconButton>

                        <CardContent className="flex flex-col justify-between h-full">
                          <Typography
                            variant="h6"
                            component="div"
                            gutterBottom
                            sx={{ color: "#333" }} // Darker text for contrast
                          >
                            Important Notice
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              color: "#555",
                              fontSize: { xs: "1rem", md: "1.25rem" },
                            }} // Responsive font size
                          >
                            Please update your
                            preference details.
                          </Typography>
                          <Link
                            to="/profile-preferences"
                            className="w-full flex items-center justify-center"
                            style={{ textDecoration: "none", marginTop: 16 }}
                          >
                            <Button className="flex items-center justify-around w-[200px]">
                              <span>Go to Profile</span>
                              <ArrowRight />{" "}
                              {/* Use the correct icon component */}
                            </Button>
                          </Link>
                        </CardContent>
                      </Card>

                    </Dialog>


                    <div>
                      <Button
                        onClick={handleOpen}
                        className="cursor-pointer font-medium  leading-[normal] text-center text-lg  sm:text-sm mqtab1:text-[1.05vw] mqlap:text-[1vw] px-[1.4vw] py-[0.7vw] mqlap:px-[1.6vw] mqtab1:px-[1.6vw] mqlap:rounded-[13px] mqtab1:rounded-[11px]"
                        shape="round"
                      >
                        {!dataStatus ? "Add Preferences" : "Update Preferences"}
                      </Button>
                    </div>
                  </div>
                </div>
                {!jobsData || jobsData.length === 0 ? (
                  <Text
                    className="text-center text-blue_gray-400"
                    size="txtPoppinsSemiBold14"
                  >
                    No Jobs For You
                  </Text>
                ) : (
                  <div className="w-[95%]">
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Text
                                className="sm:ml-[0] sm:mt-0 my-0.5 text-base text-blue_gray-400 cursor-pointer mqlap:text-[0.9vw] mqtab1:text-[0.95vw]"
                                size="txtInterSemiBold16"
                              // onClick={() => handleSortChange("company_name")}
                              >
                                S.no
                              </Text>
                            </TableCell>
                            <TableCell>
                              <Text
                                className="sm:ml-[0] sm:mt-0 my-0.5 text-base text-blue_gray-400 cursor-pointer mqlap:text-[0.9vw] mqtab1:text-[0.95vw]"
                                size="txtInterSemiBold16"
                              // onClick={() => handleSortChange("job_title")}
                              >
                                Job Title
                              </Text>
                            </TableCell>
                            <TableCell>
                              <Text
                                className="sm:ml-[0] sm:mt-0 my-0.5 text-base text-blue_gray-400 cursor-pointer mqlap:text-[0.9vw] mqtab1:text-[0.95vw]"
                                size="txtInterSemiBold16"
                              // onClick={() => handleSortChange("company_name")}
                              >
                                Company
                              </Text>
                            </TableCell>
                            <TableCell>
                              <Text
                                className="sm:ml-[0] sm:mt-0 my-0.5 text-base text-blue_gray-400 cursor-pointer mqlap:text-[0.9vw] mqtab1:text-[0.95vw]"
                                size="txtInterSemiBold16"
                              // onClick={() => handleSortChange("location")}
                              >
                                Location
                              </Text>
                            </TableCell>
                            <TableCell>
                              <div className="flex items-center ">
                                {" "}
                                <Text
                                  className="sm:ml-[0] sm:mt-0 my-0.5 text-base text-blue_gray-400 cursor-pointer mqlap:text-[0.9vw] mqtab1:text-[0.95vw]"
                                  size="txtInterSemiBold16"
                                // onClick={() => handleSortChange("status")}
                                >
                                  Status
                                </Text>
                                <img
                                  className="icon-park-outline sm:h-[15px] h-[25px] pl-[5px]"
                                  onClick={handleJobStatusFilter}
                                  alt="Icon park outline"
                                  src={`https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-${jobStatusFilter ? "down-1" : "down-2"
                                    }.svg`}
                                />
                              </div>

                              {jobStatusFilter && (
                                <div
                                  style={{ backgroundColor: "white" }}
                                  className="absolute mt-2 py-4 w-48 bg-red rounded-md shadow-lg z-50"
                                >
                                  {/* Render your dropdown content here */}
                                  <MenuItem
                                    name="status"
                                    value=""
                                    onClick={() =>
                                      handleFilterChange("status", "")
                                    }
                                  >
                                    All
                                  </MenuItem>
                                  <div className="border-t border-gray-200 my-1"></div>{" "}
                                  {/* Separation line */}
                                  <MenuItem
                                    name="status"
                                    value="200"
                                    className="border-t border-gray-200 my-1 w-full"
                                    onClick={() =>
                                      handleFilterChange("status", "200")
                                    }
                                  >
                                    Applied
                                  </MenuItem>
                                  <div className="border-t border-gray-200 my-1"></div>{" "}
                                  {/* Separation line */}
                                  <MenuItem
                                    name="status"
                                    value="400"
                                    className="border-t border-gray-200 my-1"
                                    onClick={() =>
                                      handleFilterChange("status", "400")
                                    }
                                  >
                                    Failed
                                  </MenuItem>
                                </div>
                              )}
                            </TableCell>

                            <TableCell>
                              <Text
                                className="sm:ml-[0] sm:mt-0 my-0.5 text-base text-blue_gray-400 cursor-pointer mqlap:text-[0.9vw] mqtab1:text-[0.95vw]"
                                size="txtInterSemiBold16"
                                onClick={() => handleSortChange("link")}
                              >
                                Link
                              </Text>
                            </TableCell>
                            <TableCell>
                              <div className="flex items-center ">
                                <Text
                                  className="sm:ml-[0] sm:mt-0 my-0.5 text-base text-blue_gray-400 cursor-pointer mqlap:text-[0.9vw] mqtab1:text-[0.95vw]"
                                  size="txtInterSemiBold16"
                                // onClick={() => handleSortChange("platform")}
                                >
                                  Platform
                                </Text>
                                <img
                                  className="icon-park-outline sm:h-[15px] h-[25px] pl-[5px]"
                                  onClick={handleJobPlatformFilter}
                                  alt="Icon park outline"
                                  src={`https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-${jobPlatformFilter ? "down-1" : "down-2"
                                    }.svg`}
                                />
                              </div>

                              {jobPlatformFilter && (
                                <div className="bg-white absolute mt-2 py-4  bg-red rounded-md w-auto shadow-lg z-50 ">
                                  <MenuItem value="">All</MenuItem>
                                  <div className="border-t border-gray-200 my-1"></div>
                                  <MenuItem
                                    value="Careerjet"
                                    name="platform"
                                    onClick={() =>
                                      handleFilterChange(
                                        "platform",
                                        "Careerjet",
                                      )
                                    }
                                  >
                                    Careerjet
                                  </MenuItem>
                                  <div className="border-t border-gray-200 my-1"></div>

                                  <MenuItem
                                    value="foundit.in"
                                    name="platform"
                                    onClick={() =>
                                      handleFilterChange(
                                        "platform",
                                        "foundit.in",
                                      )
                                    }
                                  >
                                    Foundit.in
                                  </MenuItem>
                                  <div className="border-t border-gray-200 my-1"></div>
                                  <MenuItem
                                    value="Freshersworld"
                                    name="platform"
                                    onClick={() =>
                                      handleFilterChange(
                                        "platform",
                                        "Freshersworld",
                                      )
                                    }
                                  >
                                    Freshersworld
                                  </MenuItem>
                                </div>
                              )}
                            </TableCell>

                            <TableCell>
                              <Text
                                className="sm:ml-[0] sm:mt-0 my-0.5 text-base text-blue_gray-400 cursor-pointer mqlap:text-[0.9vw] mqtab1:text-[0.95vw]"
                                size="txtInterSemiBold16"
                                onClick={() => handleSortChange("matched_at")}
                              >
                                MatchedAt
                              </Text>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {displayedRows && displayedRows.length > 0 ? (
                            displayedRows.map((job, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <Text className="mqlap:text-[0.8vw] mqtab1:text-[0.85vw]">
                                    {index + 1 + (currentPage - 1) * itemsPerPage}
                                  </Text>
                                </TableCell>
                                <TableCell><Text className="mqlap:text-[0.8vw] mqtab1:text-[0.85vw]">{job.job_title}</Text></TableCell>
                                <TableCell><Text className="mqlap:text-[0.8vw] mqtab1:text-[0.85vw]">{job.company_name}</Text></TableCell>
                                <TableCell><Text className="mqlap:text-[0.8vw] mqtab1:text-[0.85vw]">{job.location}</Text></TableCell>
                                <TableCell>
                                  <Text className="mqlap:text-[0.8vw] mqtab1:text-[0.85vw]">
                                    <Button
                                      className="w-[130PX]"
                                      color={
                                        job.status === 200 ? "success" : "error"
                                      }
                                    >
                                      {job.status === 200
                                        ? "Applied"
                                        : job.status === 400
                                          ? "Failed"
                                          : job.status}
                                    </Button>
                                  </Text>
                                </TableCell>
                                <TableCell>
                                  <Text className="mqlap:text-[0.8vw] mqtab1:text-[0.85vw]">
                                    <Link
                                      href={getHrefLink(job.job_id, job.platform)}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <img
                                        src={link_image}
                                        className="w-5 h-5 mqlap:w-4 mqlap:h-4 mqtab1:w-[0.9rem] mqtab1:h-[0.9rem]"
                                        alt="external link"
                                      />
                                    </Link>
                                  </Text>
                                </TableCell>
                                <TableCell><Text className="mqlap:text-[0.8vw] mqtab1:text-[0.85vw]">{job.platform}</Text></TableCell>
                                <TableCell>
                                  <Text className="mqlap:text-[0.8vw] mqtab1:text-[0.85vw]">
                                    {getTimeInTimeZone(
                                      job.matched_at || job.applied_at || "NA",
                                    )}
                                  </Text>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <p className="text-center pt-[20px] pb-[20px]">
                              No data found
                            </p>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Pagination
                      color="primary"
                      variant="outlined"
                      shape="rounded"
                      page={currentPage}
                      count={Math.ceil(filteredJobsData.length / itemsPerPage)}
                      onChange={handlePageChange}
                      className="flex flex-row justify-center items-center pt-[27px] pb-[50px]"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AutoJob;
