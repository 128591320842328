import Cookies from "js-cookie";
import React from "react";
import { Navigate } from "react-router-dom";

const PublicRoute = ({ element, ...rest }) => {
  const accessToken = Cookies.get("accessToken");

  const storedUserData = JSON.parse(
    localStorage.getItem(
      `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`,
    ),
  );
  const userId = storedUserData?.user?.id;

  if (!accessToken || userId === undefined) {
    return React.cloneElement(element, rest);
  }
  window.location.href = "/jobseeker-dashboard";
  return null;
};

export default PublicRoute;
