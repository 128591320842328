import { Button, Img, Line, Text } from "components";
import Sidebar1 from "pages/RecruiterDashBoardPages/RecruiterSidebar";
import TopHeader from "pages/RecruiterDashBoardPages/TopHeader";
import { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import "./index.css";

const CandidatesPage = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const navigate = useNavigate();

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    if (selectedValue === 'Upload Jd') {
      navigate('/upload-jd');
    }
  };
  return (
    <>
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <Sidebar1 clickedTab={"Add Job"} />
          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
            <TopHeader header={"Add Job"} />



            <div
              style={{ overflow: "scroll" }}
              className="bg-white-A700 flex flex-col font-poppins items-center justify-start p-7 sm:px-5 rounded-[15px] w-[94%] md:w-full h-[93vh]"
            >
              <div className="flex md:flex-col flex-row md:gap-10 items-start justify-center w-[82%] md:w-full">
                <div className="flex flex-col gap-[25px] justify-start">
                  <div className="flex flex-col gap-[22px] justify-center items-center md:ml-[0] ml-[177px] w-[58%] md:w-full">
                    <Text
                      className="text-24px md:text-[22px] text-black-900_7f sm:text-xl"
                      size="txtInterMedium24"
                    >
                      Select one option from below to create job post
                    </Text>

                    <select
                      className="font-medium leading-[normal] md:ml-[0] text-left text-20px w-[52%] sm:w-full rounded-[10px]"
                      onChange={handleSelectChange}
                      value={selectedOption}
                    >
                      <option value="Fill Jd">Fill Jd</option>
                      <option value="Upload Jd">Upload Jd</option>
                    </select>
                  </div>
                  <div className="border border-gray-400_66 border-solid flex flex-col justify-start py-[60px] rounded-[15px] w-full">
                    <div className="flex sm:flex-col flex-row gap-[17px] items-start justify-start md:ml-[0] ml-[108px] w-[49%] md:w-full">
                      <Img
                        className="h-6 w-6"
                        src="images/img_arrowleft.svg"
                        alt="arrowleft"
                      />
                      <Text
                        className="text-24px md:text-[22px] text-black-900_7f text-center sm:text-xl"
                        size="txtInterMedium24"
                      >
                        Company role & environment
                      </Text>
                    </div>
                    <Line className="bg-gray-400_91 h-px mt-11 w-full" />
                    <div className="flex flex-col items-center justify-start mb-[17px] md:ml-[0] ml-[122px] mt-[38px] w-[76%] md:w-full">
                      <div className="flex flex-col gap-[11px] items-start justify-start w-full">
                        <Text
                          className="text-24px md:text-[22px] text-blue_gray-400 sm:text-xl"
                          size="txtInterMedium24Bluegray400"
                        >
                          Job title
                        </Text>

                        <input
                          type="text"
                          name="frameFour"
                          placeholder="Project Manager"
                          className="leading-[normal] p-3  text-[15px] text-left w-full border border-gray-300 border-solid rounded-[12px]"
                        />

                      </div>
                      <div className="flex flex-col gap-[9px] items-start justify-start mt-6 w-full">
                        <Text
                          className="text-24px md:text-[22px] text-blue_gray-400 sm:text-xl"
                          size="txtInterMedium24Bluegray400"
                        >
                          Workplace type
                        </Text>

                        <input
                          type="text"
                          name="frameFour_One"
                          placeholder="Hybrid"
                          className="leading-[normal] p-3  text-[15px] text-left w-full border border-gray-300 border-solid rounded-[12px]"

                        />
                      </div>
                      <div className="flex flex-col gap-[11px] items-start justify-start mt-[23px] w-full">
                        <Text
                          className="text-24px md:text-[22px] text-blue_gray-400 sm:text-xl"
                          size="txtInterMedium24Bluegray400"
                        >
                          Job location
                        </Text>

                        <input
                          type="text"
                          name="frameFour_Two"
                          placeholder="Hyderabad"
                          className="leading-[normal] p-3  text-[15px] text-left w-full border border-gray-300 border-solid rounded-[12px]"

                        />
                      </div>
                      <div className="flex flex-col gap-[9px] items-start justify-start mt-6 w-full">
                        <Text
                          className="text-24px md:text-[22px] text-blue_gray-400 sm:text-xl"
                          size="txtInterMedium24Bluegray400"
                        >
                          Employment type
                        </Text>

                        <input
                          type="text"
                          name="frameFour_Three"
                          placeholder="Temporary"
                          className="leading-[normal] p-3  text-[15px] text-left w-full border border-gray-300 border-solid rounded-[12px]"

                        />
                      </div>
                      <div className="flex flex-col items-center justify-start mt-[37px] w-full">
                        <div className="flex flex-col items-start justify-start w-full">
                          <Text
                            className="text-24px md:text-[22px] text-blue_gray-400 sm:text-xl"
                            size="txtInterMedium24Bluegray400"
                          >
                            Job Description
                          </Text>
                          <div className="flex flex-row gap-6 items-start justify-start mt-[7px] w-2/5 md:w-full">
                            <div className="flex flex-row gap-4 items-center justify-between mt-0.5 w-[66%]">
                              <Img
                                className="h-6 md:h-auto object-cover w-6"
                                src="images/img_bold.png"
                                alt="bold"
                              />
                              <Img
                                className="h-6 md:h-auto object-cover w-6"
                                src="images/img_italic.png"
                                alt="italic"
                              />
                              <Img
                                className="h-6 md:h-auto object-cover w-6"
                                src="images/img_underline.png"
                                alt="underline"
                              />
                              <Img
                                className="h-6 md:h-auto object-cover w-6"
                                src="images/img_bulletedlist.png"
                                alt="bulletedlist"
                              />

                            </div>
                            <Button
                              className="cursor-pointer flex items-center justify-center min-w-[95px] rounded-[12px] ml-[85px]"

                              size="xs"
                            >

                              <div className="flex row items-center justify-around w-full checkbox-wrapper-22 w- bg-white-900">
                                <div className="font-medium leading-[normal] text-left text-lg">
                                  AI

                                </div>
                                <label className="switch" htmlForor="checkbox">

                                  <input
                                    // onClick={handleClickOpen}
                                    type="checkbox"
                                    // checked={stateToggle}
                                    className="p-5"
                                    id="checkbox"
                                  />
                                  <div className="slider round"></div>
                                </label>
                              </div>

                            </Button>
                          </div>
                          <label for="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"></label>
                          <textarea id="message" rows="4" className="leading-[normal] p-3  text-[15px] text-left w-full border border-gray-300 border-solid rounded-[12px] h-[200px]"
                            placeholder="Write your job description here...">

                          </textarea>
                        </div>
                      </div>
                      <div className="flex flex-col gap-6 items-start justify-start mt-[27px] w-[99%] md:w-full">
                        <div className="flex flex-col font-inter gap-2 items-start justify-start w-full">
                          <Text
                            className="text-24px md:text-[22px] text-blue_gray-400 sm:text-xl"
                            size="txtInterMedium24Bluegray400"
                          >
                            Add Skills *
                          </Text>
                          <Text
                            className="text-gray-700 text-18px"
                            size="txtInterRegular18"
                          >
                            Add skill keywords to make your job visible to the
                            right candidates (Select up to 10)
                          </Text>
                        </div>
                        <Button
                          className="cursor-pointer font-medium font-poppins h-[47px] leading-[normal] text-center text-24px px w-[124px]"
                          shape="round"
                          color="gray_400_66"
                          variant="outline"
                        >
                          Add skill
                        </Button>
                      </div>
                      <div className="flex flex-col items-center justify-start mt-[55px] md:px-10 sm:px-5 px-[212px] w-full">
                        <Link to='/addjob-jd'>
                          <Button
                            className="cursor-pointer font-semibold h-[67px] leading-[normal] text-24px md:text-[22px] text-center sm:text-xl w-[293px]"
                            shape="round"
                            color="indigo_600"
                            size="md"
                          >
                            Save and continue
                          </Button>
                        </Link>

                      </div>
                    </div>
                  </div>
                </div>
                {/* <Line className="bg-blue-A200 h-[523px] md:h-[5px] mb-[1028px] md:mt-0 mt-[19px] rotate-[180deg] rounded-sm w-[5px] md:w-full" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default CandidatesPage;
