import { Dialog } from "@mui/material";
import { Img, Text } from "components";
import { ProfileHeader } from "pages/JobSeekerDashBoardPages/ProfileHeader";
import { ProfileHeader2 } from "pages/JobSeekerDashBoardPages/ProfileHeader2";
import { SideBar1 } from "pages/JobSeekerDashBoardPages/SideBar1";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Button } from "components";
import { getProfileUploadLinks } from "actions/ProfileUploadLinks";
import AlertModal from "../../../components/AlertModal/index.jsx";


const ProfileUpload = () => {
  const [CVPreviewing, setCVPreviewing] = useState(false);
  const [resumePreviewing, setResumePreviewing] = useState(false);
  const [coverLetterFileName, setCoverLetterFileName] = useState("");
  const [coverLetterTimestamp, setCoverLetterTimestamp] = useState("");
  const [resumePath, setResumePath] = useState("");
  const [resumeTimestamp, setResumeTimestamp] = useState("");
  const [coverletterDots, setCoverLetterDots] = useState(false);
  const [statusResumeDots, setResumeDots] = useState(false);
  const [isResumeUploaded, setIsResumeUploaded] = useState(false);
  const [isCVUploaded, setIsCVUploaded] = useState(false);
  const [resumeUploadTime, setResumeUploadTime] = useState("");
  const [cvUploadTime, setCvUploadTime] = useState("");
  const [resumeLink, setResumeLink] = useState("");
  const [cvLink, setCvLink] = useState("");
  const [open, setOpen] = useState(false);
  const [openResume, setOpenResume] = useState(false);
  const [resumePopup, setResumePopup] = useState(false);
  const [coverletterPopup, setCoverletterPopup] = useState(false);
  const [coverletterMessage, setCoverletterMessage] = useState("");
  const [resumeMessage, setResumeMessage] = useState("");



  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenResume = () => {
    setOpenResume(true);
  };

  const handleCloseResume = () => {
    setOpenResume(false);
  };

  const userData = JSON.parse(
    localStorage.getItem(
      `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`,
    ),
  );
  const user_id = userData.user.id;
  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };
  let items = useSelector((state) => state.profileUploadLinks.links);
  const dispatch = useDispatch();
  //console.log("Items", items);
  useEffect(() => {
    //console.log("Use Effect");
    if (!isEmptyObject(items)) {
      //console.log("Inside If");
      const checkFilesData = {
        is_resume_uploaded: items.status.is_resume_uploaded || false,
        is_cv_uploaded: items.status.is_cv_uploaded || false,
        resume_uploaded_time: items.status.resume_uploaded_time || null,
        cv_uploaded_time: items.status.cv_uploaded_time || null,
      };
      const getFileLinksData = {
        resume_link: items.links.resume_link || "",
        cv_link: items.links.cv_link || "",
      };
      //console.log("Check Files Data", checkFilesData);
      //console.log("Get File Links Data", getFileLinksData);

      if (checkFilesData["is_resume_uploaded"] === true) {
        setIsResumeUploaded(true);
        const time = checkFilesData["resume_uploaded_time"];
        const myDate = new Date(time).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        });
        const formattedTime = myDate;
        setResumeUploadTime(formattedTime);
      }
      if (checkFilesData["is_cv_uploaded"] === true) {
        setIsCVUploaded(true);
        const time = checkFilesData["cv_uploaded_time"];
        const myDate = new Date(time).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        });
        const formattedTime = myDate;
        setCvUploadTime(formattedTime);
      }
      if (
        getFileLinksData["resume_link"] !== "" &&
        getFileLinksData["cv_link"] !== ""
      ) {
        setResumeLink(getFileLinksData["resume_link"]);
        setCvLink(getFileLinksData["cv_link"]);
      }
    }
  }, [items]);

  const getFormattedTimestamp = () => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    return new Date().toLocaleDateString("en-US", options);
  };

  const handleFileChangeCoverLetter = async (event) => {

    handleDialogClose();
    const fileInputCoverLetter = event.target;
    const selectedFileCoverLetter = fileInputCoverLetter.files[0];

    if (selectedFileCoverLetter) {
      const fileSizeCoverLetter = selectedFileCoverLetter.size;
      const maxSizeCoverLetter = 3 * 1024 * 1024;

      if (fileSizeCoverLetter > maxSizeCoverLetter) {
        alert("File size exceeds 3MB. Please choose a smaller file.");
      } else {
        const allowedTypesCoverLetter = [
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];

        if (!allowedTypesCoverLetter.includes(selectedFileCoverLetter.type)) {
          alert("Only PDF and DOC files are allowed.");
        } else {
          setCoverLetterFileName(selectedFileCoverLetter.name);

          const formData = new FormData();
          formData.append("cv_file", selectedFileCoverLetter);
          try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/cv_parser_via_upload?user_id=${user_id}`;
            const requestOptions = {
              method: "POST",
              body: formData,
            };

            const res = await fetch(apiUrl, requestOptions);

            
            if (!res.ok) {
              const errorText = await res.text();
              console.error("Error fetching data:", res.status, errorText);
              // Handle the error or throw an exception as needed
            } else {
              dispatch(getProfileUploadLinks(user_id))
              const data = await res.json();
              //console.log("Data from resume parser", data);
              //handleData(data);
              setIsCVUploaded(true);
              setCvUploadTime(getFormattedTimestamp());
              // Handle the response data as needed

              fetch(
                `${process.env.REACT_APP_API_URL}/get_file_links?user_id=${user_id}`,
                {
                  method: "POST",
                },
              ).then((res) =>
                res.json().then((data) => {
                  //console.log(data);
                  setResumeLink(data["resume_link"]);
                  setCvLink(data["cv_link"]);
                }),
              );
              setCoverletterMessage(
                isCVUploaded
                  ? "Cover letter updated successfully."
                  : "Cover letter uploaded successfully."
              )
              setCoverletterPopup(true);
              setTimeout(() => setCoverletterPopup(false), 3000);
            }
            
          } catch (error) {
            console.error("Error fetching data:", error);
          }

          const reader = new FileReader();
          reader.onloadend = () => {
            const coverLetterData = reader.result;
            localStorage.setItem("coverLetterData", coverLetterData);
            setCoverLetterFileName(selectedFileCoverLetter.name);

            const timestamp = getFormattedTimestamp();
            localStorage.setItem("coverLetterTimestamp", timestamp);
            setCoverLetterTimestamp(timestamp);

            // //console.log('Cover Letter Data:', coverLetterData);
            //console.log("Cover Letter Timestamp:", timestamp);
          };
          reader.readAsDataURL(selectedFileCoverLetter);
        }
      }
    }

    fileInputCoverLetter.value = "";
    // setIsCVUploaded(true);
    // setCvUploadTime(getFormattedTimestamp());
  };

  const coverLetterDots = () => {
    setCoverLetterDots(true);
  };
  const handleDialogClose = () => {
    setCoverLetterDots(false);
  };

  useEffect(() => {
    const storedCoverLetterData = localStorage.getItem("coverLetterData");
    const storedCoverLetterTimestamp = localStorage.getItem(
      "coverLetterTimestamp",
    );

    if (storedCoverLetterData && storedCoverLetterTimestamp) {
      setCoverLetterFileName(storedCoverLetterData);
      setCoverLetterTimestamp(storedCoverLetterTimestamp);
    }
  }, []);

  useEffect(() => {
    const storedResumeData = localStorage.getItem("resumeData");
    if (storedResumeData) {
      setResumePath(storedResumeData);
    }
  }, []);

  const handleFileChangeResume = async (event) => {
    const fileInputResume = event.target;
    const selectedFileResume = fileInputResume.files[0];

    if (selectedFileResume) {
      const fileSizeResume = selectedFileResume.size;
      const maxSizeResume = 3 * 1024 * 1024;

      if (fileSizeResume > maxSizeResume) {
        alert("File size exceeds 3MB. Please choose a smaller file.");
      } else {
        const allowedTypesResume = [
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];

        if (!allowedTypesResume.includes(selectedFileResume.type)) {
          alert("Only PDF and DOC files are allowed.");
        } else {
          setResumePath(selectedFileResume.name);

          ////console.log(user_id);
          const formData = new FormData();
          formData.append("resume_file", selectedFileResume);
          try {
            const apiUrl = `${process.env.REACT_APP_API_URL}/resume_parser_via_upload?user_id=${user_id}`;
            const requestOptions = {
              method: "POST",
              body: formData,
            };

            const res = await fetch(apiUrl, requestOptions);

            if (!res.ok) {
              const errorText = await res.text();
              console.error("Error fetching data:", res.status, errorText);
              // Handle the error or throw an exception as needed
            } else {
              dispatch(getProfileUploadLinks(user_id))
              const data = await res.json();
              //console.log("Data from resume parser", data);
              //handleData(data);
              setIsResumeUploaded(true);
              setResumeUploadTime(getFormattedTimestamp());
              // Handle the response data as needed

              fetch(
                `${process.env.REACT_APP_API_URL}/get_file_links?user_id=${user_id}`,
                {
                  method: "POST",
                },
              ).then((res) =>
                res.json().then((data) => {
                  //console.log(data);
                  setResumeLink(data["resume_link"]);
                  setCvLink(data["cv_link"]);
                  
                }),
              );
              setResumeMessage(
                isResumeUploaded
                  ? "Resume updated successfully."
                  : "Resume uploaded successfully."
              );
              setResumePopup(true)
              setTimeout(() => setResumePopup(false), 3000); 


            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
          const reader = new FileReader();
          reader.onloadend = () => {
            const resumeData = reader.result;
            localStorage.setItem("resumeData", resumeData);
            setResumePath(selectedFileResume.name);

            const timestamp = getFormattedTimestamp();
            localStorage.setItem("resumeTimestamp", timestamp);
            setResumeTimestamp(timestamp);

            //   //console.log('Resume Data:', resumeData);
            //   // //console.log('Resume Timestamp:', timestamp);
          };
          reader.readAsDataURL(selectedFileResume);
        }
      }
    }

    fileInputResume.value = "";
    // setIsResumeUploaded(true);
    // setResumeUploadTime(getFormattedTimestamp());
  };

  const handleResumeDialogClose = () => {
    setResumeDots(false);
  };

  const resumeDots = async () => {
    setResumeDots(true);
  };

  useEffect(() => {
    const storedResumeData = localStorage.getItem("resumeData");
    const storedResumeTimestamp = localStorage.getItem("resumeTimestamp");

    if (storedResumeData && storedResumeTimestamp) {
      setResumePath(storedResumeData);
      setResumeTimestamp(storedResumeTimestamp);
    }
  }, []);

  const handlePreviewClick = () => {
    /* const pdfUrl = localStorage.getItem("coverLetterData");
    window.open(pdfUrl, "_blank"); */
    setCVPreviewing(true);
  };

  const handleResumePreviewClick = () => {
    /* const pdfUrl = localStorage.getItem("resumeData");
    window.open(pdfUrl, "_blank"); */
    setResumePreviewing(true);
  };
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };
  const [popupText, setPopText] = useState("");
  const [actionType, setAction] = useState("");
  const handleAction = (typeofAction) => {
    setAction(typeofAction);
  };
  const editPopup = (text) => {
    openPopup();
    setPopText(text);
  };
  return (
    <>
     {coverletterPopup && <AlertModal msg={coverletterMessage}  />}
     {resumePopup && <AlertModal msg={resumeMessage}   />}

      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <SideBar1 clickedTab={"Profile"} />
          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full h-[100vh] overflow-hidden">
            <ProfileHeader clickedTab={"Profile"} />

            <div className="bg-white-A700 flex flex-col items-center justify-start p-7 sm:px-5 rounded-[15px] w-[94%] md:w-full overflow-y-scroll">
              <div className="flex flex-col gap-[43px] justify-start mb-[199px] mt-2.5 w-[99%] md:w-full">
                <ProfileHeader2 activeTab={"upload"} />
                <div className=" h-auto sm:h-[auto] md:h-[auto] flex flex-col gap-11 items-start justify-start w-full md:w-full ">
                  <div className="flex flex-col items-center justify-center md:ml-[0] ml-[3px] w-auto">
                    <Text
                      className="text-2xl  md:text-[20px] text-blue_gray-900 sm:text-[15px] w-auto text-[1.3rem} mqlap:text-[1.25rem] mqtab1:text-[1.25vw]"
                      size="txtInterMedium24"
                    >
                      Upload your files below
                    </Text>
                  </div>

                  {isCVUploaded === false ? (
                    <div className="border min-w-[20%] border-indigo-50 border-solid flex  flex-col h-auto md:h-auto md:w-[40%] items-center justify-start  sm:px-4  px-[30px] py-12 sm:py-6  rounded w-auto sm:w-[60%] sm:h-auto ">
                      <div className="flex flex-col gap-[46.06px] items-center justify-start w-auto sm:gap-[32px]">
                        <Text
                          className="leading-[21.00px] text-[14.17px] text-blue_gray-800 text-center  sm:text-sm"
                          size="txtInterRegular1417"
                        >
                          <span className="text-blue_gray-800 font-inter font-medium sm:text-[12px] mqlap:text-[13px] mqtab1:text-[0.95vw]">
                            <>
                              Cover Letter
                              <br />
                            </>
                          </span>
                          <span className="text-blue_gray-800 font-inter text-[12.4px] font-normal sm:text-[11px] mqtab1:text-[0.82vw] mqlap:text-[11px]">
                            Be sure to include an updated Cover Letter*
                          </span>
                        </Text>
                        <div className="flex flex-col gap-[21.26px] items-center justify-start w-auto">
                          <label htmlFor="fileInput" className="cursor-pointer">
                            <Img
                              className="h-11 md:h-auto object-cover w-11 mqtab1:h-9 mqtab1:w-9 mqlap:w-10 mqlap:h-10"
                              src="images/img_uploadtocloud.png"
                              alt="uploadtocloud"
                            />
                          </label>
                          <input
                            id="fileInput"
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleFileChangeCoverLetter}
                            accept=".pdf,.doc,.docx"
                            required
                          />
                          <Text
                            className="leading-[19.00px] text-[12.4px] text-blue_gray-400_01 text-center"
                            size="txtInterRegular124"
                          >
                            <label
                              htmlFor="fileInput"
                              className="cursor-pointer"
                            >
                              <span className="text-blue_gray-400_01 font-inter font-normal sm:text-[12px] mqlap:text-[11px] mqtab1:text-[0.82vw]">
                                <>
                                  Upload Cover Letter <br />
                                </>
                              </span>
                              <span className="text-blue_gray-400_01 font-inter text-[10.63px] font-normal sm:text-[10px] mqlap:text-[11px] mqtab1:text-[0.8vw]">
                                DOC, PDF (3MB)
                              </span>
                            </label>
                          </Text>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col items-start justify-start w-full">
                      <div className="flex flex-row sm:gap-10 items-start justify-between w-full sm:text-sm">
                        <Text
                          className="text-black-900 text-lg  sm:text-[12px] mqlap:text-[0.95rem] mqtab1:text-[1vw]"
                          size="txtInterRegular18"
                        >
                          Cover letter
                        </Text>

                        <Popup
                          trigger={
                            <span>
                              <Img
                                className="h-6 w-6"
                                src="images/img_signal.svg"
                                alt="signal"
                              />
                            </span>
                          }
                          position="left end"
                          contentStyle={{
                            width: "200px",
                            // height: "150px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <div
                            style={{
                              // backgroundColor:"red",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "space-around",
                            }}
                          >
                            <Button
                              onClick={handleOpen}
                              className="flex flex-row items-center justify-center w-[130px] mb-[15px] text-center sm:h-[30px] sm:w-[80px] sm:text-sm  text-[18px] rounded-[12px] border border-gray-400_66 border-solid  hover:bg-blue-500 hover:text-white-A700 mqtab1:text-[1.1vw]"
                            >
                              Preview
                            </Button>
                            <Button className="flex flex-row items-center justify-center w-[130px]  text-center sm:h-[30px] sm:w-[80px] sm:text-sm  text-[18px] rounded-[12px] border border-gray-400_66 border-solid  hover:bg-blue-500 hover:text-white-A700 mqtab1:text-[1.1vw]">
                              <label htmlFor="fileInput"> Replace</label>
                              <input
                                id="fileInput"
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFileChangeCoverLetter}
                                required
                              />
                            </Button>
                          </div>
                        </Popup>
                      </div>
                      <Text
                        className="mt-0.5 text-black-900_7f text-lg sm:text-sm mqlap:text-[0.95rem] mqtab1:text-[1vw]"
                        size="txtInterRegular18Black9007f"
                      >
                        Cover letter Uploaded on {cvUploadTime}
                      </Text>
                    </div>
                  )}

                  <Dialog open={open} onClose={handleClose} fullScreen>
                    <div
                      className="start-interview2 "
                      style={{ width: "95%", height: "95%" }}
                    >
                      <div
                        className="group-wrapper flex flex-row"
                        style={{ width: "100%", height: "100%" }}
                      >
                        <iframe
                          className=" h-full w-full"
                          src={cvLink}
                          title="Cover Letter"
                        ></iframe>
                        <div
                          className="cursor-pointer pl-[80px] text-[30px]"
                          onClick={handleClose}
                        >
                          X
                        </div>
                      </div>
                    </div>
                  </Dialog>

                  {isResumeUploaded === false ? (
                    <div className="border border-indigo-50 border-solid flex  flex-col h-auto md:h-auto md:w-[40%] items-center justify-start  sm:px-4  px-[30px] py-12 sm:py-6 min-w-[20%] rounded w-auto sm:w-[60%] sm:h-auto ">
                      <div className="flex flex-col gap-[46.06px] items-center justify-start w-auto sm:gap-[32px]">
                        <Text
                          className="leading-[21.00px] text-[14.17px] text-blue_gray-800 text-center sm:text-[15px]"
                          size="txtInterRegular1417"
                        >
                          <span className="text-blue_gray-800 font-inter font-medium sm:text-[12px] mqlap:text-[13px] mqtab1:text-[0.95vw]">
                            <>
                              Resume
                              <br />
                            </>
                          </span>
                          <span className="text-blue_gray-800 font-inter text-[12.4px] font-normal sm:text-[11px] mqtab1:text-[0.82vw] mqlap:text-[11px]">
                            Be sure to include an updated Resume*
                          </span>
                        </Text>
                        <div className="flex flex-col gap-[21.26px] items-center justify-start w-auto">
                          <label
                            htmlFor="fileInputResume"
                            className="cursor-pointer"
                          >
                            <Img
                              className="h-11 md:h-auto object-cover w-11"
                              src="images/img_uploadtocloud.png"
                              alt="uploadtocloud"
                            />
                          </label>
                          <input
                            id="fileInputResume"
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleFileChangeResume}
                            accept=".pdf,.doc,.docx"
                            required
                          />
                          <Text
                            className="leading-[19.00px] text-[12.4px] text-blue_gray-400_01 text-center"
                            size="txtInterRegular124"
                          >
                            <label
                              htmlFor="fileInputResume"
                              className="cursor-pointer"
                            >
                              <span className="text-blue_gray-400_01 font-inter font-normal sm:text-[12px] mqlap:text-[11px] mqtab1:text-[0.82vw]">
                                <>
                                  Upload Resume <br />
                                </>
                              </span>
                              <span className="text-blue_gray-400_01 font-inter text-[10.63px] font-normal sm:text-[10px] mqlap:text-[11px] mqtab1:text-[0.8vw]">
                                DOC, PDF (3MB)
                              </span>
                            </label>
                          </Text>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col items-start justify-start w-full">
                      <div className="flex flex-row sm:gap-10 items-start justify-between w-full">
                        <Text
                          className="text-black-900 text-lg sm:text-[15px] mqlap:text-[0.95rem] mqtab1:text-[1vw]"
                          size="txtInterRegular18"
                        >
                          Resume
                        </Text>

                        <Popup
                          trigger={
                            <span>
                              <Img
                                className="h-6 w-6"
                                src="images/img_signal.svg"
                                alt="signal"
                              />
                            </span>
                          }
                          position="left center"
                          contentStyle={{
                            width: "200px",
                            // height: "150px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "space-around",
                            }}
                          >
                            <Button
                              onClick={handleOpenResume}
                              className="flex flex-row items-center justify-center w-[130px] mb-[15px] text-center sm:h-[30px] sm:w-[80px] sm:text-sm  text-[18px] rounded-[12px] border border-gray-400_66 border-solid  hover:bg-blue-500 hover:text-white-A700 mqtab1:text-[1.1vw]"
                            >
                              Preview
                            </Button>
                            <Button className="flex flex-row items-center justify-center w-[130px]  text-center sm:h-[30px] sm:w-[80px] sm:text-sm  text-[18px] rounded-[12px] border border-gray-400_66 border-solid  hover:bg-blue-500 hover:text-white-A700 mqtab1:text-[1.1vw]">
                              <label
                                style={{
                                  textAlign: "center",
                                }}
                                htmlFor="fileInput2"
                              >
                                {" "}
                                Replace
                              </label>
                              <input
                                id="fileInput2"
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFileChangeResume}
                                required
                              />
                            </Button>
                          </div>
                        </Popup>
                      </div>
                      <Text
                        className=" text-black-900_7f text-lg sm:text-[15px] mqlap:text-[0.95rem] mqtab1:text-[1vw]"
                        size="txtInterRegular18Black9007f"
                      >
                        Resume is Uploaded on {resumeUploadTime}
                      </Text>
                    </div>
                  )}

                  <Dialog
                    open={openResume}
                    // onClose={handleCloseResume}
                    disableBackdropClick={true}
                    fullScreen
                  >
                    <div
                      className="start-interview2 "
                      style={{ width: "95%", height: "95%" }}
                    >
                      <div
                        className="group-wrapper flex flex-row justify-between"
                        style={{ width: "100%", height: "100%" }}
                      >
                        <iframe
                          className=" h-full w-full"
                          src={resumeLink}
                          title="Cover Letter"
                        ></iframe>
                        <div
                          className="cursor-pointer  text-[30px]"
                          onClick={handleCloseResume}
                        >
                          X
                        </div>
                      </div>
                    </div>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileUpload;
