import { Button, Img, Text } from "components";

const TopHeader = (props) => {
  
  const { header } = props

  return (
    <div className=" bg-white-A700 flex flex-row md:gap-10 items-center justify-between p-[13px] w-full">
      <div className="flex flex-col items-center justify-center ml-11 w-auto">
        <Text
          className="sm:text-2xl md:text-[26px] text-[28px] text-gray-900_01 w-auto"
          size="txtInterSemiBold28"
        >
          {header}
        </Text>
      </div>
      <div className="flex flex-row items-center justify-between mr-5 w-[11%]">
      <Button
          className="flex h-[50px] items-center justify-center my-[5px] w-[50px]"
          shape="circle"
          color="gray_100_01"
          size="sm"
        >
          <Img
            className="h-[25px]"
            src="images/unread_notification_bell.svg"
            alt="002notification"
          />
        </Button>
        <div className="flex flex-col h-[74px] items-center justify-start w-[74px]">
          <Img
            className="h-[74px] md:h-auto rounded-[50%] w-[74px]"
            src="/images/img_ellipse3.png"
            alt="ellipseThree"
          />
        </div>
      </div>
    </div>
  )
}

export default TopHeader;