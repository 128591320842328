import { Button } from "components";
import Container from "components/Container/Container";
import WebsiteFooter from "components/WebsiteFooter/WebsiteFooter";
import { Link } from "react-router-dom";
import "./About.css";
const AboutUs = () => {
  // const navigate = useNavigate();
  // const onLogo = useCallback(() => {
  //   navigate("/");
  // }, [navigate]);

  return (
    <>
      <div className="font-sans overflow-x-clip">
        <div>
          <Container mainhead="About Us" subtext="At RecRoid, we're revolutionizing recruitment by empowering both job seekers and recruiters with a comprehensive solution." />
          {/* <div className="absolute right-0 bottom-[33rem] mqmob1:hidden">
            <img src="/ball1.svg.svg" alt="" className=" h-32 " />
          </div> */}
        </div>
        <div className="flex md:flex-col justify-center gap-[10px]  my-16 px-36 mqmob1:w-[38rem] mqmob1:pl-[4.5rem] mqmob1:pr-[7rem]">
          <div className="flex flex-col justify-center">
            <div className="flex flex-col justify-center items-center w-fit">
              <h1 className=" text-3xl text-[#314CA3]">For Job Seekers</h1>
              <div
                className=" w-36 h-1 bg-gradient-to-r from-[#438FFE] from-0% via-[#BEFF34] via-20% 
              via-[#2512FF] via-41% via-[#FF2F6E] via-59% to-[#FAFF01] to-86%"
              ></div>
            </div>

            <br />
            <div className="">
              <ul className=" list-inside list-disc">
                <li className=" list-disc">
                  Effortless Job Search: Apply to dream jobs with one click on
                  25+ platforms.
                </li>
                <br />
                <li className="">
                  Personalized Job Matching: Get curated opportunities from 50+
                  platforms based on your skills.
                </li>
                <br />
                <li className="">
                  Crafting Success: Create standout resumes and cover letters
                  with our guided tools.
                </li>
                <br />
                <li className="">
                  Confidence in the Interview: Prepare with our interview
                  insights and industry-specific tips.
                </li>
                <br />
                <li className="">
                  Find Your Perfect Career Path: Explore career options with our
                  personalized advisor, including education costs and exams.
                </li>
              </ul>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <img
              src="/undraw_career_progress_ivdb-removebg-preview 1.svg"
              height={400}
              width={750}
              alt="person being confident for Interview"
            />
          </div>
        </div>

        {/*Dividing Line*/}

        <div className="flex justify-center items-center mt-8 my-16 ">
          <div
            className=" w-96 mqmob1:w-69 mqmob1:ml-[1rem] h-1 bg-gradient-to-r from-[#438FFE] from-0% via-[#BEFF34] via-20% 
              via-[#2512FF] via-41% via-[#FF2F6E] via-59% to-[#FAFF01] to-86%"
          ></div>
        </div>

        {/*Recruiters section*/}
        <div className="flex md:flex-col justify-center  px-24 gap-12 mqmob1:w-[38rem] mqmob1:pl-20 ">
          <div className="flex items-center justify-center mqmob1:w-[21.5rem]">
            <img
              src="/recruiters_about_us.svg"
              height={300}
              width={550}
              alt="person being confident for Interview"
            />
          </div>
          <div className="flex flex-col justify-center  ">
            <div className="flex flex-col justify-center items-center w-fit">
              <h1 className=" text-3xl text-[#314CA3]">For Recruiters</h1>
              <div
                className=" w-36 h-1 bg-gradient-to-r from-[#438FFE] from-0% via-[#BEFF34] via-20% 
              via-[#2512FF] via-41% via-[#FF2F6E] via-59% to-[#FAFF01] to-86%"
              ></div>
            </div>

            <br />
            <div className="">
              <ul className=" list-inside list-disc">
                <li className=" list-disc">
                  Streamlined Workflow: Integrate emails and manage candidate
                  profiles centrally.
                </li>
                <br />
                <li className="">
                  Intelligent Candidate Management: Keep detailed candidate
                  records, including resumes and assessments.
                </li>
                <br />
                <li className="">
                  Data-Driven Selection: Automate assessments with real-time
                  results for unbiased shortlisting.
                </li>
                <br />
                <li className="">
                  Simplified Scheduling: Schedule interviews easily, saving time
                  and avoiding manual coordination.
                </li>
                <br />
                <li className="">
                  Transparent Communication: Automate feedback and results,
                  ensuring a professional candidate experience.
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/*Why Recroid section*/}

        <div
          className=" flex justify-center items-center py-10 mt-10 mqmob1:ml-[5rem] mqmob1:w-[28rem] "
        >
          <div>
            <h1 className="text-3xl">
              Why Join the
              <span
                className="text-transparent bg-clip-text bg-gradient-to-r from-[#CB3838] via-[#FEDA20] via-24% 
              via-[#EA286E] via-49% to-[#48C5ED] to-85%"
              >
                Recroid
              </span>{" "}
              Community?
            </h1>

            <ul className=" list-inside list-disc my-12 ">
              <li className="mb-2">
                Access a powerful AI-powered platform designed for success.
              </li>
              <li className="mb-2">
                Connect with a vast network of qualified job seekers and top
                companies.
              </li>
              <li className="mb-2">
                Benefit from personalized career guidance and insightful
                interview preparation.
              </li>
              <li className="mb-2">
                Experience a streamlined and efficient recruitment process..
              </li>
              <li>
                Join a community committed to fostering positive career
                journeys.
              </li>
            </ul>

            <div className="flex sm:flex-col flex-row gap-[3rem]">
              <p className="mt-3">
                <Link to="/signup" className=" text-[#314CA3] font-bold">
                  Sign up
                </Link>{" "}
                today and Experience the Future of Recruitment
              </p>

              <Link to="/signup" className="md:hidden sm:hidden">
                <Button className="p-3 shrink-0">Sign up</Button>
              </Link>
            </div>

          </div>

          <div className=" relative left-[90%] top-10 mqmob1:hidden">
            <img src="/ball3.svg.svg" className="h-24 w-24" alt="" />
          </div>

        </div>
        <div className=" mt-[15rem] mqmob1:mt-[12rem] ">
          < WebsiteFooter />

        </div>

        <div className="absolute z-10 top-[123px] mqmob1:top-[215px] mqtab2:top-[175px] left-0">
          <img src="ball2.svg.svg" alt="Ball" className="ball mqmob1:hidden" />
        </div>


      </div>
    </>
  );
};

export default AboutUs;
