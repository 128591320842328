export const InputField = ({
  label,
  type,
  name,
  value,
  onChange,
  readOnly = false,
  ...props
}) => (
  <div className="flex flex-col w-full">
    <label className="text-gray-600">{label}</label>
    <input
      className="mt-2 p-2 border border-gray-300 rounded"
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      {...props}
    />
  </div>
);

export const SelectField = ({ label, name, value, onChange, options }) => (
  <div className="flex flex-col w-full">
    <label className="text-gray-600">{label}</label>
    <select
      className="mt-2 p-2 border border-gray-300 rounded"
      name={name}
      value={value}
      onChange={onChange}
    >
      <option value="" disabled>
        Select {label}
      </option>
      {options.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </select>
  </div>
);

export const WarnigAlertPopup = ({ nameField }) => (
  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-[#314CA3] shadow-lg p-6 w-2/4 absolute rounded-[15px] flex flex-col justify-center items-center border-[2px] border-solid border-[#000000] p-[29px]">
      <div className="text-[32px] p-[10px] text-[#fff] align-center">
        You cannot change your {nameField}. Contact{" "}
        <a href="mailto:support@culinda.com" className=" underline">
          support@culinda.com
        </a>{" "}
        to change your name.
      </div>
    </div>
  </div>
);
