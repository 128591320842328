import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { useEffect, useState } from "react";
import { Button, Text } from "components";
import TextToSpeech from "./textToSpeech";

const TextInterviewSection = ({handleGetAdvice,
  instruction,
  open,
  Typography,
  backToOptions,
  handleContinue,
  SubmitTest,
  handleNextQuestion,
  questionsArray,
  timeRemaining,
  setTimeRemaining,
  currentQuestion,
  selectedDuration,
  answersArray,
  setAnswersArray,
}) => {
  useEffect(() => {
    // Warn the user if they try to leave the page or reload
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // Most browsers will show a default message
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleAnswerChange = (event) => {
    const newAnswersArray = [...answersArray];
    newAnswersArray[currentQuestion] = event.target.value;
    setAnswersArray(newAnswersArray);
  };

  useEffect(() => {
    if (timeRemaining > 0) {
      const timerInterval = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timerInterval);
            handleGetAdvice();;
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [timeRemaining]);

  const handleSubmitTest = () => {
    SubmitTest();
    setAnswersArray(new Array(questionsArray.length).fill(""));
  };

  const isAnswerEmpty = () => {
    const answer = answersArray[currentQuestion];
    return answer === undefined || answer.trim().length === 0;
  };

  const [speechStatus, setSpeechComplete] = useState(false);
  const handleStartAnswer = () => {
    setSpeechComplete(true);
  };

  const handleNext = () => {
    const nextQuestion = currentQuestion + 1;
    const newAnswersArray = [...answersArray];
    if (newAnswersArray[nextQuestion] === undefined) {
      newAnswersArray[nextQuestion] = "";
    }
    setAnswersArray(newAnswersArray);
    handleNextQuestion();
    setSpeechComplete(false);
  };

  return (
    <Dialog fullScreen open={open} disableBackdropClick={true}>
      {!instruction ? (
        <div className="h-[100%] w-[100%] flex flex-col items-center">
          <AppBar style={{ backgroundColor: "#314ca3" }}>
            <Toolbar className="flex flex-row justify-between items-center">
              <Text className="text-xl sm:text-[14px]">
                Please answer all the five questions
              </Text>
           <p className="text-xl sm:text-[14px] font-medium">
                    Time left:{" "}
                    {String(Math.floor(timeRemaining / 60)).padStart(2, "0")} :{" "}
                    {String(timeRemaining % 60).padStart(2, "0")}
                  </p>
            </Toolbar>
          </AppBar>

          <div
            style={{ backgroundColor: "#e5e8f3" }}
            className="w-[80vw] h-auto flex flex-col self-center mt-[120px] rounded-[14px]"
          >
            <div className="flex justify-end mr-[40px] mt-[20px]">
              <Text>
                Attempted questions: {currentQuestion}/
                {selectedDuration === 20 ? 5 : 10}
              </Text>
            </div>
            <TextToSpeech
              text={questionsArray[currentQuestion]}
              index={currentQuestion}
              handleStartAnswer={handleStartAnswer}
            />
            <div className="w-[95%] mt-[40px] self-center">
              <Text className="pb-[20px]">Your answer:</Text>
              <textarea
                className="w-full rounded-[10px] mt-[10px] sm:text-[14px] text-gray-500 text-md min-h-[200px] border-[#D0D1D4]"
                value={answersArray[currentQuestion]}
                onChange={handleAnswerChange}
              />
            </div>
            <div className="flex justify-end w-[95%] self-center mt-[50px] mb-[50px]">
              {currentQuestion + 1 === (selectedDuration === 20 ? 5 : 10) ? (
                speechStatus ? (
                  <Button
                    className="w-[150px] h-[45px] text-center"
                    onClick={handleSubmitTest}
                  >
                    Submit
                  </Button>
                ) : (
                  <button
                    style={{ border: "solid 2px blue", color: "blue" }}
                    className="h-[45px] flex justify-center items-center w-[150px] sm:h-[30px] sm:w-[70px] text-[18px] rounded-[14px] sm:text-[14px]"
                  >
                    Submit
                  </button>
                )
              ) : speechStatus && !isAnswerEmpty() ? (
                <Button
                  className="w-[150px] h-[45px] text-center"
                  onClick={handleNext}
                >
                  Next
                </Button>
              ) : (
                <button
                  style={{ border: "solid 2px blue", color: "blue" }}
                  className="h-[45px] flex justify-center items-center w-[150px] sm:h-[30px] sm:w-[70px] text-[18px] rounded-[14px] sm:text-[14px]"
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-gray-100 h-full">
          <AppBar
            style={{ backgroundColor: "#314ca3" }}
            sx={{ fontSize: { sm: "12px" } }}
          >
            <Toolbar>
              <Typography
                sx={{ ml: 2, flex: 1 }}
                variant="h6"
                component="div"
                className="sm:text-[1px]"
              >
                <Text className="text-xl sm:text-[14px]">
                  Please Read Instruction Carefully
                </Text>
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={backToOptions}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div className=" overflow-scroll pb-[40px] pt-[50px] text-gray-500 gap-[50px] h-[100%] flex flex-col justify-center items-center">
            <div
              style={{ backgroundColor: "#ffffff" }}
              className="pl-[4.5%] w-[50vw] sm:w-[80vw] h-auto pb-[40px] v-[50vw]"
            >
              <Text
                className="text-xl  pb-[40px] pt-[20px] sm:text-sm"
                size="txtInterMedium20"
              >
                Instructions
              </Text>
              <ul className="list-decimal w-full space-y-5  sm:text-[10px]">
                <li className="mb-5">Duration: {selectedDuration} minutes</li>
                <li className="mb-5">
                  Number of questions: {selectedDuration === 20 ? 5 : 10}
                </li>
                <li className="mb-5">Answer all questions.</li>
                <li className="mb-5">Each question carries equal marks.</li>

                <li className="mb-5">
                  Ensure you have a stable internet connection.
                </li>
                <li className="mb-5">Review your answers before submitting.</li>
                <li className="mb-5">
                  Once submitted, you cannot return to previous questions.
                </li>
              </ul>
            </div>
            <Button onClick={handleContinue} className="w-[200px] h-[40px]">
              Start Assessment
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default TextInterviewSection;
