import React, { useEffect, useRef, useState } from "react";
import classNames from 'classnames'; // Make sure to install this library
import { Text } from "components";
import Pagination from "@mui/material/Pagination";
import { useDispatch } from "react-redux";
import { fetchNotifications } from "actions/Notification";


const OutsideClickHandler = ({ children, onOutsideClick }) => {
    const wrapperRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                onOutsideClick();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onOutsideClick]);

    return <div ref={wrapperRef}>{children}</div>;
};

const NotificationList = ({ notifications, badgeContent, user_id, setUnread, unread, setMenuOpen, onClick }) => {
    const [activeTab, setActiveTab] = useState('unread'); // Simplify tab management
    const dispatch = useDispatch();

    useEffect(() => {
        if (unread) {
            fetch(`${process.env.REACT_APP_API_URL}/mark-all-as-read/${user_id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    if (response.ok) {
                        setUnread(false);
                    } else {
                        throw new Error("Failed to mark all notifications as read");
                    }
                })
                .catch((error) => {
                    console.error("Error marking all notifications as read:", error);
                });
        }
    }, [unread, user_id, setUnread]);

    const filteredNotifications = notifications.filter((notification) =>
        activeTab === 'unread' ? !notification.read : notification.read
    );
    const itemsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedRows = filteredNotifications.slice(startIndex, endIndex);

    // Helper function to format the date
    const formatDate = (dateString) => {
        const dateObject = new Date(dateString);
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return dateObject.toLocaleDateString('en-GB', options);
    };

    // Function to mark all notifications as read
    const handleMarkAllAsRead = () => {
        fetch(`${process.env.REACT_APP_API_URL}/mark-all-as-read/${user_id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.ok) {
                    setUnread(false); // Update state to reflect that there are no unread notifications
                    dispatch(fetchNotifications(user_id));
                    // window.location.reload();
                } else {
                    throw new Error("Failed to mark all notifications as read");
                }
            })
            .catch((error) => {
                console.error("Error marking all notifications as read:", error);
            });
    };

    return (
        <OutsideClickHandler onOutsideClick={() => setMenuOpen(false)}>
            {notifications && notifications.length === 0 ? (
                <div className="z-20 bg-[#f4f6f9] absolute flex justify-start right-[9.5rem] top-[4.385rem] w-[18.5rem] bg-white shadow-lg rounded-[12px]">
                    <div className="flex items-start flex-col h-fit">
                        <p className="text-center text-[16.5px] p-6 pl-8">No notifications</p>
                    </div>
                </div>
            ) : (
                <div className="z-20 bg-[#f4f6f9] absolute right-[2.5rem] top-[5.5rem] p-[22px] w-[35.5rem] bg-white shadow-lg rounded-[12px]" onClick={onClick}>
                    <div className="flex items-start flex-col pb-4 h-fit">
                        <div className="flex justify-between w-full items-center">
                            <h1 className="text-[20px] font-bold">Notifications</h1>
                            <button
                                className="border-[2px] font-bold border-[#314ca3] text-[#314ca3] rounded-[12px] p-[10px]"
                                onClick={badgeContent > 0 ? handleMarkAllAsRead : null} // Conditionally handle click event
                                disabled={badgeContent === 0}
                            >
                                Mark All as read
                            </button>
                        </div>
                        <div className="flex space-x-4 py-2 mt-[2px]">
                            <button
                                className={classNames('text-[16.2px] font-semibold text-black', {
                                    'border-b-solid border-b-2 text-[#314ca3] border-b-blue-700': activeTab === 'unread'
                                })}
                                onClick={() => setActiveTab('unread')}
                            >
                                Unread
                            </button>
                            <button
                                className={classNames('text-[16.2px] text-black', {
                                    'border-b-solid border-b-2 font-semibold text-[#314ca3] border-b-blue-700': activeTab === 'read'
                                })}
                                onClick={() => setActiveTab('read')}
                            >
                                Read
                            </button>
                        </div>
                        {activeTab === 'unread' && filteredNotifications.length === 0 ? (
                            <div className="mt-1 text-left p-1 rounded-lg">
                                <p className="text-[12px] text-[#090909] mb-1 w-full">No unread messages</p>
                            </div>
                        ) : (
                            <ul className="list-disc p-0 w-full">
                                {displayedRows.map((notification, index) => (
                                    <li key={notification.id} className="mt-1 ml-[10px] text-left w-full">
                                        <div className="p-1 rounded-lg">
                                            <p className="text-[14px] text-grey-100 mb-1 text-blue_gray-400 font-semibold w-full">{notification.text}</p>
                                            {index < filteredNotifications.length - 1 ? (
                                                <div className="text-blue_gray-400 text-right border-b-[2px]">
                                                    <text className="w-full text-[12px] h-[2px] mb-4 mt-[8px]">
                                                        {notification.sent_at.length === 0 ? "" : formatDate(notification.sent_at)}
                                                    </text>
                                                </div>
                                            ) : (
                                                <div className="h-[2px] w-[46px] mx-auto mt-2"></div>
                                            )}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                        <div className="flex justify-between w-full pt-[30px]">
                            <Text className="text-blue_gray-400">{displayedRows.length} of {filteredNotifications.length} notifications</Text>
                            <Text className="text-indigo-600">View All</Text>
                        </div>

                    </div>
                </div>
            )}
        </OutsideClickHandler>
    );
};

export default NotificationList;