const initialState = {
    data: [],
    isLoading: true,
    error: null
};

const interviewPrepData = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_INTERVIEW_PREP_DATA_REQUEST":
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case "FETCH_INTERVIEW_PREP_DATA_SUCCESS":
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };
        case "FETCH_INTERVIEW_PREP_DATA_FAILURE":
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

export default interviewPrepData;