
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { Button, Img, Line, Text } from "components";
import Sidebar1 from "pages/RecruiterDashBoardPages/RecruiterSidebar";
import TopHeader from "pages/RecruiterDashBoardPages/TopHeader";
import { useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FaArrowLeft } from "react-icons/fa6";
import { MdOutlineAttachment } from "react-icons/md";
import { Link } from "react-router-dom";
import PopupBg from "components/BackgroundCheckPopup"

const CandidatesJobPage = () => {
    const [viewAllStatus, setViewAllStatus] = useState(true)
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const statusBaseColor = (status) => {
        //console.log(status)
        switch (status) {
            case 'interviewed':
                return 'red-500';
            case 'applied':
                return 'yellow-500';
            case 'offered':
                return 'blue-A200';
            case 'Hired':
                return 'green-600';

            default:
                return 'gray-700'; // Default color or any other color you prefer
        }
    }
    const checkBaseColor = (status) => {
        //console.log(status)
        switch (status) {

            case 'Initiate':
                return 'blue-A200';
            // case 'done':
            // return 'green-600';

            default:
                // return 'gray-700';
                return 'green-600'; // Default color or any other color you prefer
        }
    }


    const invoicesData = [
        {
            "id": "1",
            "name": "John Doe",
            "email": "johndoe@example.com",
            "phnNumber": "1234567890",
            "score": "80",
            "action": "Interview",
            "backgroundCheck": "Initiate",
            "date": "5",
            "progress": "1",
            "status": "applied"
        },
        {
            "id": "2",
            "name": "Jane Smith",
            "email": "janesmith@example.com",
            "phnNumber": "9876543210",
            "score": "90",
            "action": "Feedback",
            "backgroundCheck": "Done",
            "date": "3",
            "progress": "2",
            "status": "screened"
        },
        {
            "id": "3",
            "name": "David Johnson",
            "email": "davidjohnson@example.com",
            "phnNumber": "4567890123",
            "score": "75",
            "action": "Rejected",
            "backgroundCheck": "Done",
            "date": "7",
            "progress": "3",
            "status": "interviewed"
        },
        {
            "id": "4",
            "name": "Emily Brown",
            "email": "emilybrown@example.com",
            "phnNumber": "8901234567",
            "score": "85",
            "action": "Interview",
            "backgroundCheck": "Initiate",
            "date": "2",
            "progress": "4",
            "status": "offered"
        },
        {
            "id": "5",
            "name": "Michael Davis",
            "email": "michaeldavis@example.com",
            "phnNumber": "5678901234",
            "score": "95",
            "action": "feedback",
            "backgroundCheck": "Done",
            "date": "1",
            "progress": "5",
            "status": "hired"
        },
        {
            "id": "6",
            "name": "Sarah Wilson",
            "email": "sarahwilson@example.com",
            "phnNumber": "0123456789",
            "score": "70",
            "action": "rejected",
            "backgroundCheck": "Done",
            "date": "6",
            "progress": "6",
            "status": "applied"
        },
        {
            "id": "7",
            "name": "Christopher Martinez",
            "email": "christophermartinez@example.com",
            "phnNumber": "7890123456",
            "score": "88",
            "action": "interview",
            "backgroundCheck": "Initiate",
            "date": "4",
            "progress": "7",
            "status": "screened"
        },
        {
            "id": "8",
            "name": "Amanda Thompson",
            "email": "amandathompson@example.com",
            "phnNumber": "2345678901",
            "score": "92",
            "action": "feedback",
            "backgroundCheck": "Done",
            "date": "2",
            "progress": "8",
            "status": "interviewed"
        },
        {
            "id": "9",
            "name": "Daniel Clark",
            "email": "danielclark@example.com",
            "phnNumber": "9012345678",
            "score": "78",
            "action": "interview",
            "backgroundCheck": "Initiate",
            "date": "5",
            "progress": "9",
            "status": "offered"
        },
        {
            "id": "10",
            "name": "Olivia Rodriguez",
            "email": "oliviarodriguez@example.com",
            "phnNumber": "3456789012",
            "score": "87",
            "action": "feedback",
            "backgroundCheck": "Done",
            "date": "3",
            "progress": "10",
            "status": "hired"
        }, {
            "id": "11",
            "name": "Sarah Wilson",
            "email": "sarahwilson@example.com",
            "phnNumber": "0123456789",
            "score": "70",
            "action": "rejected",
            "backgroundCheck": "Done",
            "date": "6",
            "progress": "6",
            "status": "applied"
        },
        {
            "id": "12",
            "name": "Christopher Martinez",
            "email": "christophermartinez@example.com",
            "phnNumber": "7890123456",
            "score": "88",
            "action": "interview",
            "backgroundCheck": "Initiate",
            "date": "4",
            "progress": "7",
            "status": "screened"
        },
        {
            "id": "13",
            "name": "Amanda Thompson",
            "email": "amandathompson@example.com",
            "phnNumber": "2345678901",
            "score": "92",
            "action": "feedback",
            "backgroundCheck": "Done",
            "date": "2",
            "progress": "8",
            "status": "interviewed"
        },
        {
            "id": "14",
            "name": "Daniel Clark",
            "email": "danielclark@example.com",
            "phnNumber": "9012345678",
            "score": "78",
            "action": "interview",
            "backgroundCheck": "Initiate",
            "date": "5",
            "progress": "9",
            "status": "offered"
        },
        {
            "id": "15",
            "name": "Olivia Rodriguez",
            "email": "oliviarodriguez@example.com",
            "phnNumber": "3456789012",
            "score": "87",
            "action": "feedback",
            "backgroundCheck": "Done",
            "date": "3",
            "progress": "10",
            "status": "hired"
        }
    ]
    return (
        <>
            <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
                <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
                <Sidebar1  clickedTab={"Jobs"}/>
                    <div className="flex flex-1 flex-col font-inter gap-[50px] items-center justify-start md:px-5 w-full">
                        <div className="bg-white-A700 flex flex-row md:gap-10 items-center justify-between p-[13px] w-full">
                            <TopHeader header={"Jobs"} />
                        </div>
                        <div className="font-poppins md:h-[1103px] h-[1165px] relative w-[95%] md:w-full">
                            <div className=" absolute bg-white-A700 flex flex-col inset-x-[0] justify-start mx-auto py-[23px] rounded-[15px] top-[0] w-full">
                                <div className="flex flex-col items-start justify-start mt-[21px] md:pl-10 pl-14 pr-14 sm:pl-5 w-full">
                                    <div className="flex sm:flex-col flex-row sm:gap-5 items-start justify-start w-full md:w-full gap-20">

                                        <Link to="/jobs" className="flex flex-row gap-6 items-start justify-start">
                                            <FaArrowLeft />
                                            <Text
                                                className="text-2xl md:text-[22px] text-center text-gray-700 sm:text-xl"
                                                size="txtPoppinsSemiBold24"
                                            >
                                                Project Manager
                                            </Text>
                                        </Link>
                                        <Button
                                            style={{
                                                color: "#45745a",
                                                backgroundColor: "#b5e2b3",
                                            }}
                                            className="cursor-pointer font-medium leading-[normal] min-w-[105px] text-center text-lg"
                                            shape="round"

                                            size="sm"
                                        >
                                            Published
                                        </Button>
                                    </div>
                                    <div className="flex flex-col items-start justify-start ml-[40px]">
                                        <Text
                                            className="text-gray-500 text-xl"
                                            size="txtPoppinsRegular20"
                                        >
                                            Full-time
                                        </Text>
                                        <Text
                                            className="mt-0.5 text-gray-500 text-xl"
                                            size="txtPoppinsRegular20"
                                        >
                                            30 May, 2022 to 10 June, 2022
                                        </Text>
                                    </div>


                                    {
                                        viewAllStatus ? <>  <div className="flex flex-row font-inter md:gap-10 items-center justify-between w-full">
                                            <Text className="text-blue-A200 text-xl pl-[40px] pt-[20px]" size="txtInterSemiBold20">
                                                Candidates
                                            </Text>
                                            <div className="text-gray-700 text-xl pr-[70px]" onClick={() => setViewAllStatus(!viewAllStatus)}>
                                                <Text size="txtInterMedium20 ">View all</Text>
                                            </div>
                                        </div>
                                            <div className="md:gap-5 gap-[75px] grid sm:grid-cols-1 mb-[100px] md:grid-cols-2 grid-cols-4 justify-center min-h-[auto] md:ml-[0] ml-[5px] mt-[40px] w-full">
                                                {invoicesData.slice(0, 12).map((item) => (
                                                    <div key={item.id} className="flex flex-1 flex-col items-center justify-start w-full">
                                                        <div className="bg-white-A700 flex flex-col gap-2.5 h-[138px] md:h-auto items-center justify-center outline outline-[1px] outline-blue-A200 px-4 py-2 rounded-[15px] w-[273px]">
                                                            <div className="flex flex-row font-inter items-start justify-between w-[99%]">
                                                                <div className="flex flex-col items-center justify-start w-[64%]">
                                                                    <div className="flex flex-row gap-3 items-start justify-between w-full">
                                                                        <Img
                                                                            className="h-9 md:h-auto rounded-[50%] w-9"
                                                                            src="/images/img_ellipse3.png"
                                                                            alt="ellipseFortyEight"
                                                                        />
                                                                        <div className="flex flex-col items-start justify-start">
                                                                            <Text className="text-gray-700 text-lg" size="txtInterMedium18">
                                                                                {item.name}
                                                                            </Text>
                                                                            <Text className="mt-0.5 text-base text-gray-500" size="txtInterRegular16">
                                                                                {item.date} days ago
                                                                            </Text>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="font-poppins h-9 md:h-[39px] mt-[3px] relative w-9">
                                                                    <div className="!w-9 h-9 m-auto overflow-visible">
                                                                        <CircularProgressbar
                                                                            className="!w-9 h-9 m-auto overflow-visible"
                                                                            value={item.progress}
                                                                            strokeWidth={6}
                                                                            styles={{
                                                                                trail: { strokeWidth: 6, stroke: "#429cff66" },
                                                                                path: {
                                                                                    strokeLinecap: "square",
                                                                                    height: "100%",
                                                                                    transformOrigin: "center",
                                                                                    transform: "rotate(90deg)",
                                                                                    stroke: "#438ffe",
                                                                                },
                                                                            }}
                                                                        ></CircularProgressbar>
                                                                    </div>
                                                                    <Text
                                                                        className="absolute h-max inset-[0] justify-center m-auto text-gray-700 text-xs w-max"
                                                                        size="txtPoppinsMedium12"
                                                                    >
                                                                        {`${item.progress}%`}
                                                                    </Text>
                                                                </div>
                                                            </div>
                                                            <button
                                                                className="cursor-pointer flex items-center justify-center min-w-[139px] border border-blue-A200 border-solid  rounded-[18px]"
                                                            >
                                                                <div className="font-medium font-poppins leading-[normal] text-base text-left text-gray-500 text-xl">
                                                                    Resume
                                                                </div>
                                                                <MdOutlineAttachment className="h-[20px] text-gray-500 text-xl" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div></> : <> <div className="flex flex-row font-inter md:gap-10 items-center justify-between w-full">
                                                <Text className="text-blue-A200 text-xl pl-[40px] pt-[20px]" size="txtInterSemiBold20">
                                                    Candidates
                                                </Text>
                                                <div className="text-gray-700 text-xl pr-[70px]" onClick={() => setViewAllStatus(!viewAllStatus)}>
                                                    <Text size="txtInterMedium20 ">View less</Text>
                                                </div>
                                            </div><TableContainer component={Paper} className="w-[93%] md:w-full">
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Text
                                                                    className="text-black-900 text-lg"
                                                                    size="txtInterMedium18"
                                                                >
                                                                    S.No
                                                                </Text>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Text
                                                                    className="text-black-900 text-lg"
                                                                    size="txtInterMedium18"
                                                                >
                                                                    Name
                                                                </Text>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Text
                                                                    className="text-black-900 text-lg"
                                                                    size="txtInterMedium18"
                                                                >
                                                                    Email
                                                                </Text>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Text
                                                                    className="text-black-900 text-lg"
                                                                    size="txtInterMedium18"
                                                                >
                                                                    Phone number
                                                                </Text>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Text
                                                                    className="text-black-900 text-lg"
                                                                    size="txtInterMedium18"
                                                                >
                                                                    Match
                                                                </Text>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Text
                                                                    className="text-black-900 text-lg"
                                                                    size="txtInterMedium18"
                                                                >
                                                                    Status
                                                                </Text>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Text
                                                                    className="text-black-900 text-lg"
                                                                    size="txtInterMedium18"
                                                                >
                                                                    Action
                                                                </Text>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Text
                                                                    className="text-black-900 text-lg"
                                                                    size="txtInterMedium18"
                                                                >
                                                                    Overall Score
                                                                </Text>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Text
                                                                    className="text-black-900 text-lg"
                                                                    size="txtInterMedium18"
                                                                >
                                                                    Background Check
                                                                </Text>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {invoicesData.map((invoice, i) => (
                                                            <TableRow key={i}>
                                                                <TableCell>
                                                                    <Text
                                                                        className="text-blue_gray-400 text-lg"
                                                                        size="txtInterMedium18Bluegray400"
                                                                    >
                                                                        {i + 1}
                                                                    </Text>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <text

                                                                        className="cursor-pointer  p-2 w-[130px] font-medium leading-[normal] text-center text-lg"
                                                                    >
                                                                        {invoice.name}
                                                                    </text>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Text
                                                                        className="text-black-900 text-lg"
                                                                        size="txtInterMedium18"
                                                                    >
                                                                        {invoice.email}
                                                                    </Text>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Text
                                                                        className="text-black-900 text-lg"
                                                                        size="txtInterMedium18"
                                                                    >
                                                                        {invoice.phnNumber}
                                                                    </Text>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <div className="font-poppins h-9 md:h-[39px] mt-[3px] relative w-9">
                                                                        <div className="!w-9 h-9 m-auto overflow-visible">
                                                                            <CircularProgressbar
                                                                                className="!w-9 h-9 m-auto overflow-visible"
                                                                                value={invoice.progress}
                                                                                strokeWidth={6}
                                                                                styles={{
                                                                                    trail: { strokeWidth: 6, stroke: "#429cff66" },
                                                                                    path: {
                                                                                        strokeLinecap: "square",
                                                                                        height: "100%",
                                                                                        transformOrigin: "center",
                                                                                        transform: "rotate(90deg)",
                                                                                        stroke: "green",
                                                                                    },
                                                                                }}
                                                                            ></CircularProgressbar>
                                                                        </div>
                                                                        <Text
                                                                            className="absolute h-max inset-[0] justify-center m-auto text-green-600 text-xs w-max"
                                                                            size="txtPoppinsMedium12"
                                                                        >
                                                                            {`${invoice.progress}%`}
                                                                        </Text>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Text
                                                                        className={`text-${statusBaseColor(invoice.status)} text-xl`}
                                                                        size="txtInterMedium18"
                                                                    >
                                                                        {invoice.status}
                                                                    </Text>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Text
                                                                        className="text-black-900 text-lg"
                                                                        size="txtInterMedium18"
                                                                    >
                                                                        {invoice.action}
                                                                    </Text>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Text
                                                                        className="text-black-900 text-lg"
                                                                        size="txtInterMedium18"
                                                                    >
                                                                        {invoice.score}
                                                                    </Text>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Text
                                                                        className={`text-${checkBaseColor(invoice.backgroundCheck)} text-xl`}
                                                                        size="txtInterMedium18"
                                                                    >
                                                                        {invoice.backgroundCheck}{invoice.backgroundCheck === "Done" && <span style={{textDecorationLine:"underline",textDecorationThickness:"2px"}} className="pl-[9px] cursor-pointer text-blue-A200 text-xl "  onClick={openPopup}>,View</span>}
                                                                    </Text>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                                <PopupBg isOpen={isPopupOpen} onClose={closePopup} />
                                                <Line className="bg-blue_gray-100 h-px w-full" />
                                            </TableContainer></>

                                    }






                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CandidatesJobPage;
