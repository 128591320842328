import { Img, Text } from "components";
import {
  AddCancelSection2,
  AddMoreSection,
  EditDeleteIconsSection,
  EditIcon,
  SaveCancleSection,
  ShowAllSection,
} from "pages/JobSeekerDashBoardPages/ProfileProfessional/IconsComponents";
import { useEffect, useState } from "react";

const ProfessionalProject = (props) => {
  const { dataOfUser, setProjects, setUserPersonalData,handleDataUpdate } = props;
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [technologies, setTechnologies] = useState("");
  const [projectLink, setProjectLink] = useState("");
  const [editingIndex, setEditingIndex] = useState(-1);
  const [selfEdit, setSelfEdit] = useState(false);
  const [showAllDetails, setShowAllDetails] = useState(false);
  const [editWork, setWorkEdit] = useState(false);

  const [editedWorkDetails, setEditedWorkDetails] = useState([]);

  const [isAddMoreClicked, setIsAddMoreClicked] = useState(false);

  const toggleShowAll = () => {
    setShowAllDetails(!showAllDetails);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        const newProjects = [];
        Object.entries(dataOfUser).forEach(([key, experienceText]) => {
          if (key.includes("Project") && experienceText !== undefined && experienceText !== "") {
            if (typeof experienceText === "string") {
              const allCommaProjects = experienceText.split(",");
              const allLineProjects = experienceText.split("\n");
              const allProjects = [...new Set([...allCommaProjects, ...allLineProjects])]; // Remove duplicates
              allProjects.forEach(projectName => {
                if (projectName.trim() !== "") {
                  const newProject = {
                    projectName: projectName.trim(),
                    projectDescription: undefined,
                    technologiesUsed: undefined,
                    projectLink: undefined,
                  };
                  newProjects.push(newProject);
                }
              });
            } else if (Array.isArray(experienceText)) {
              experienceText.forEach(project => {
                if (typeof project === "string") {
                  const allCommaProjects = project.split(",");
                  const allLineProjects = project.split("\n");
                  const allProjects = [...new Set([...allCommaProjects, ...allLineProjects])]; // Remove duplicates
                  allProjects.forEach(projectName => {
                    if (projectName.trim() !== "") {
                      const newProject = {
                        projectName: projectName.trim(),
                        projectDescription: undefined,
                        technologiesUsed: undefined,
                        projectLink: undefined,
                      };
                      newProjects.push(newProject);
                    }
                  });
                } else {
                  let projectName = undefined,
                    projectDescription = undefined,
                    technologiesUsed = undefined,
                    projectLink = undefined;
                  Object.entries(project).forEach(([key, value]) => {
                    if (key.includes("Name") || key.includes("Project") || key.includes("Title")) {
                      projectName = value;
                    }
                    if (key.includes("Description")) {
                      projectDescription = value;
                    }
                    if (key.includes("Technologies") || key.includes("Tech")) {
                      technologiesUsed = value;
                    }
                    if (key.includes("Link")) {
                      projectLink = value;
                    }
                  });
                  const newItem = {
                    projectName,
                    projectDescription,
                    technologiesUsed,
                    projectLink,
                  };
                  newProjects.push(newItem);
                }
              });
            }
          }
        });
        
        // Update the state with the new projects, ensuring no duplicates
        setProjects(prevProjects => {
          const updatedProjects = [...prevProjects];
          newProjects.forEach(newProject => {
            if (!updatedProjects.some(project => JSON.stringify(project) === JSON.stringify(newProject))) {
              updatedProjects.push(newProject);
            }
          });
          return updatedProjects;
        });
        
        setEditedWorkDetails(prevProjects => {
          const updatedProjects = [...prevProjects];
          newProjects.forEach(newProject => {
            if (!updatedProjects.some(project => JSON.stringify(project) === JSON.stringify(newProject))) {
              updatedProjects.push(newProject);
            }
          });
          return updatedProjects;
        });
  
      } catch (err) {
        const newItem = {
          projectName: "Not Available",
          projectDescription: "Not Available",
          technologiesUsed: "Not Available",
          projectLink: "Not Available",
        };
        setProjects(prevProjects => [...prevProjects, newItem]);
        setEditedWorkDetails(prevProjects => [...prevProjects, newItem]);
      }
    }, 5000);
  
    return () => clearTimeout(timer); // Cleanup function to clear the timer
  
  }, [dataOfUser]);
  

  const [workInitial, setWorkInitial] = useState(false);

  const workExpInitially = () => {
    setWorkEdit(false);
    setWorkInitial(!workInitial);
    setShowAllDetails(false);
    setSelfEdit(true);
    setEditingIndex(-1);
    setIsAddMoreClicked(false);
  };

  const editWorkButton = () => {
    setWorkEdit(true);
    setShowAllDetails(true);
    setSelfEdit(true);
  };

  const editItemButton = (index) => {
    setEditingIndex(index);
    setSelfEdit(false);
    setProjectName(editedWorkDetails[index].projectName);
    setProjectDescription(editedWorkDetails[index].projectDescription);
    setTechnologies(editedWorkDetails[index].technologiesUsed);
    setProjectLink(editedWorkDetails[index].projectLink);
  };

  const saveChanges = () => {
    if (editingIndex !== -1) {
      const updatedWorkDetails = [...editedWorkDetails];
      updatedWorkDetails[editingIndex] = {
        projectName: projectName,
        projectDescription: projectDescription,
        technologiesUsed: technologies,
        projectLink: projectLink,
      };
      
      setEditedWorkDetails(updatedWorkDetails);
      setProjects(updatedWorkDetails);

      dataOfUser["Project Lists"] = [...updatedWorkDetails];
      setUserPersonalData(dataOfUser);

      setEditingIndex(-1);
    }
  };

  const handleAddMoreClick = () => {
    setIsAddMoreClicked(!isAddMoreClicked);
  };

  const [newProjectName, setNewProjectName] = useState(""); 
  const [newProjectDescription, setNewProjectDescription] = useState(""); 
  const [newTechnologies, setNewTechnologies] = useState(""); 
  const [newProjectLink, setNewProjectLink] = useState(""); 

  const addNewWorkExperience = () => {
    if (
      newProjectName &&
      newProjectDescription &&
      newTechnologies &&
      newProjectLink
    ) {
      const newItem = {
        projectName: newProjectName,
        projectDescription: newProjectDescription,
        technologiesUsed: newTechnologies,
        projectLink: newProjectLink,
      };

      const updatedWorkDetails = [...editedWorkDetails];
      updatedWorkDetails.push(newItem);

      setEditedWorkDetails(updatedWorkDetails);
      setProjects(updatedWorkDetails);

      setNewProjectName("");
      setNewProjectDescription("");
      setNewTechnologies("");
      setNewProjectLink("");

      dataOfUser["Project Lists"] = [...updatedWorkDetails];
      setUserPersonalData(dataOfUser);

      setIsAddMoreClicked(false);
    }
  };

  const deleteWork = (index) => {
    const updatedWorkDetails = [...editedWorkDetails];
    updatedWorkDetails.splice(index, 1);

    setEditedWorkDetails(updatedWorkDetails);
    setProjects(updatedWorkDetails);

    dataOfUser["Project Lists"] = [...updatedWorkDetails];
    setUserPersonalData(dataOfUser);
  };

  const cancleChanges = () => {
    setEditingIndex(-1);
  };
  const cancleAdd = () => {
    setIsAddMoreClicked(false);
  };

  return (
    <div className="bg-white-A700 flex flex-col w-[94%] rounded-[20px]">
      <div className="flex flex-1 flex-row md:gap-10 items-center justify-between p-4">
        <div className="flex flex-row gap-[18px] items-start justify-center items-center ml-[33px]">
          <Img
            className=" sm:object-contain sm:h-[15px] sm:w-[15px] h-[25px] md:h-auto object-cover w-[25px]"
            src="images/img_material_symbols_folder.svg"
            alt="brainstormskill"
          />
          <Text
             className="mt-0.5 text-black-900 text-lg sm:text-[12px] mqlap:text-[1.05vw] mqtab1:text-[1.15vw]"
            size="txtInterSemiBold18"
          >
            Projects
          </Text>
        </div>
        <div className="flex flex-row">
          {workInitial && !editWork && <EditIcon onClick={editWorkButton} />}
          <img
            className="icon-park-outline sm:h-[15px] sm:w-[15px] mqlap:h-[2.6vw]  mqtab1:h-[3vw]"
            onClick={workExpInitially}
            alt="Icon park outline"
            src={`https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-${
              workInitial ? "down-1" : "down-2"
            }.svg`}
          />
        </div>
      </div>
      <div>
        {workInitial && (
          <div >
            {editedWorkDetails &&
              editedWorkDetails
                .slice(0, showAllDetails ? editedWorkDetails.length : 2)
                .map((work, index) => (
                  <div key={index} className="ml-[3px]">
                    <div className="flex flex-row justify-between">
                      {index === editingIndex && !selfEdit ? (
                        <div className="flex flex-row sm:flex sm:flex-col justify-between w-[80%] sm:w-[100%] gap-[9px] it mb-[30px] md:ml-[0] ml-[75px] md:px-5 md:w-full">
                          <div className="w-full sm:ml-[31px] sm:flex sm:flex-col">
                            <input
                              placeholder="Project Name"
                              className="work-edit-input  sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                              value={projectName}
                              // value={editPosition} // Correct the value assignment here
                              onChange={(e) => {setProjectName(e.target.value);handleDataUpdate()}}
                              required
                            />
                            <input
                              placeholder="Project Description"
                              className="work-edit-input  sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                              value={projectDescription}
                              onChange={(e) =>
                                {setProjectDescription(e.target.value)
                                ;handleDataUpdate()}}
                              required
                            />

                            <input
                              placeholder="Technologies Used"
                              className="work-edit-input  sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                              value={technologies}
                              onChange={(e) => {setTechnologies(e.target.value);handleDataUpdate()}}
                              required
                            />
                            <input
                              placeholder="Project Link"
                              className="work-edit-input  sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                              value={projectLink} 
                              onChange={(e) => {setProjectLink(e.target.value);handleDataUpdate()}}
                              required
                            />
                          </div>

                          <SaveCancleSection
                            saveChanges={saveChanges}
                            cancleChanges={cancleChanges}
                            page="project"
                            
                          />
                        </div>
                      ) : (
                        <div className="flex flex-col overflow-auto e-[80%] w-[80%] gap-[5px]">
                          <Text className="pl-[90px]  sm:text-[10px] break-words overflow-hidden mqlap:text-[0.95vw] mqtab1:text-[0.95vw]">
                            {work.projectName}
                          </Text>
                           <Text className="pl-[90px]  sm:text-[10px] break-words overflow-hidden mqlap:text-[0.95vw] mqtab1:text-[0.95vw]">
                            {work.projectDescription}
                          </Text>

                           <Text className="pl-[90px]  sm:text-[10px] break-words overflow-hidden mqlap:text-[0.95vw] mqtab1:text-[0.95vw]">
                            {work.technologiesUsed}
                          </Text>
                           <Text className="pl-[90px]  sm:text-[10px] break-words overflow-hidden mqlap:text-[0.95vw] mqtab1:text-[0.95vw]">
                            {work.projectLink}
                          </Text>
                        </div>
                      )}
                      {index !== editingIndex && editWork ? (
                        <EditDeleteIconsSection
                          index={index} 
                          editItemButton={editItemButton}
                          deleteWork={deleteWork}
                        />
                      ) : null}
                    </div>
                    <div className="rectangle" />
                  </div>
                ))}
            {editWork && showAllDetails ? (
              <AddMoreSection handleAddMoreClick={handleAddMoreClick} />
            ) :  (
              <p
                className="sm:text-[10px] pl-[40px] pb-[20px] mqlap:text-[0.9vw] mqtab1:text-[1vw]"
                style={{ color: "#314ca3" }}
              >
                Kindly provide the most recent three projects details{" "}
              </p>
            )}
            {isAddMoreClicked && (
              <div className="flex flex-row sm:flex sm:flex-col justify-between w-[80%] sm:w-[100%] gap-[9px] it mb-[30px] md:ml-[0] ml-[75px] md:px-5 md:w-full">
                <div className="w-full sm:ml-[31px] sm:flex sm:flex-col">
                  <input
                    placeholder="Project Name"
                    className="work-edit-input  sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                    value={newProjectName}
                    onChange={(e) => {setNewProjectName(e.target.value);handleDataUpdate()}}
                    required
                  />
                  <input
                    placeholder="Project Description "
                    className="work-edit-input  sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                    value={newProjectDescription}
                    onChange={(e) => {setNewProjectDescription(e.target.value);handleDataUpdate()}}
                    required
                  />

                  <input
                    placeholder="Technologies Used"
                    className="work-edit-input  sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                    value={newTechnologies}
                    // value={editPosition} // Correct the value assignment here
                    onChange={(e) => {setNewTechnologies(e.target.value);handleDataUpdate()}}
                    required
                  />
                  <input
                    placeholder="Project Link"
                    className="work-edit-input  sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                    value={newProjectLink} // Correct the value assignment here
                    onChange={(e) => {setNewProjectLink(e.target.value);handleDataUpdate()}}
                    required
                  />
                </div>

                <AddCancelSection2
                  addNewWorkExperience={addNewWorkExperience}
                  cancelAdd={cancleAdd}
                  page="Projects"
                />
              </div>
            )}
            {!showAllDetails && (
              <ShowAllSection
                arrayLength={editedWorkDetails.length}
                toggleShowAll={toggleShowAll}
                textTag={"projects"}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfessionalProject;
