// actions/ProfileProfessionalLinks.js

export const fetchProfileProfessionalLinks = (user_id) => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_PROFILE_PROFESSIONAL_LINKS_REQUEST" });

    const url = `${process.env.REACT_APP_API_URL}/read_links`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id }),
      });
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      dispatch({
        type: "FETCH_PROFILE_GETLINKS_SUCCESS",
        payload: data["data"]["data"][0]["links"],
      });
    } catch (error) {
      console.error("Error fetching user data:", error);
      dispatch({ type: "FETCH_PROFILE_GETLINKS_FAILURE", payload: error });
    }
  };
};
