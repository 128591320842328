import { useMemo } from "react";
import PropTypes from "prop-types";

const NamePhoneFields = ({
  className = "",
  fullName,
  propMinWidth,
  propDisplay,
  inputType,
  pattern,
  value,
  onChange,
  name,
}) => {
  const fullNameStyle = useMemo(() => {
    return {
      minWidth: propMinWidth,
      display: propDisplay,
    };
  }, [propMinWidth, propDisplay]);

  const isRequired = name !== "company"; // Only apply 'required' attribute if the field name is not 'company'

  const handleInvalid = (e) => {
    if (name === "phone") {
      e.target.setCustomValidity("Please enter numeric values only.");
    }
  };

  const handleInput = (e) => {
    e.target.setCustomValidity("");
  };

  return (
    <div
      className={`self-stretch h-28 flex flex-col items-start justify-start gap-[17px] text-center text-5xl text-black font-montserrat-alternates mqtab1:h-[6rem] mqtab2:h-[6rem] mqmob1:h-[6rem] ${className}`}
    >
      <div className="flex flex-row items-start justify-start gap-[6px]">
        <div
          className="relative leading-[29px] font-medium inline-block min-w-[128px] mq450:text-lgi mq450:leading-[23px] text-[23.5px]"
          style={fullNameStyle}
        >
          {fullName}
        </div>
        {fullName !== "Current Company (Optional)" && (
          <div className="flex flex-col items-start justify-start pt-[11px] px-0 pb-0">
            <div className="w-[7px] h-[7px] relative rounded-[50%] bg-crimson" />
          </div>
        )}
      </div>
      <input
        className="self-stretch flex-1 relative rounded-3xs bg-whitesmoke border-[1px] border-solid border-darkgray pl-[1.5rem] text-[20px]"
        type={inputType}
        pattern={pattern}
        value={value}
        onChange={onChange}
        name={name}
        required={isRequired} // Conditionally apply 'required' attribute
        onInvalid={handleInvalid}
        onInput={handleInput}
      />
    </div>
  );
};

NamePhoneFields.propTypes = {
  className: PropTypes.string,
  fullName: PropTypes.string,
  propMinWidth: PropTypes.any,
  propDisplay: PropTypes.any,
  inputType: PropTypes.string.isRequired,
  pattern: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default NamePhoneFields;
