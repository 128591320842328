function convertDate(isoDateString) {
  const date = new Date(isoDateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

export const getPaymentIntents = (user_id) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_PAYMENT_INTENTS_REQUEST" });

    const backendUrl = `${process.env.REACT_APP_API_URL}/user_payment_intents`;
    const response = await fetch(backendUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user_id }),
    });
    const data = await response.json();
    //console.log(data);

    if (data == undefined || data == null || data.length == 0) {
      dispatch({ type: "FETCH_PAYMENT_INTENTS_SUCCESS", payload: [] });
      return;
    }

    const invoicesData = data.map((item, index) => ({
      order_id: item.order_id,
      subscription_type: item.subscription_type,
      status: item.payment_status,
      amount: `${item.currency} ${item.amount / 100}`,
      date: convertDate(item.payment_made_at),
    }));
    dispatch({ type: "FETCH_PAYMENT_INTENTS_SUCCESS", payload: invoicesData });
  } catch (error) {
    dispatch({ type: "FETCH_PAYMENT_INTENTS_FAILURE", payload: error });
    throw error;
  }
};
