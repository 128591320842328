const jobRoles = [
    "Software Engineer",
    "Front-end Developer",
    "Back-end Developer",
    "Full Stack Developer",
    "Mobile App Developer (iOS/Android)",
    "DevOps Engineer",
    "System Administrator",
    "Database Administrator (DBA)",
    "Network Engineer",
    "Security Analyst",
    "Quality Assurance (QA) Engineer",
    "Business Analyst",
    "Project Manager",
    "Product Manager",
    "Data Scientist",
    "Machine Learning Engineer",
    "Data Engineer",
    "Cloud Engineer",
    "Site Reliability Engineer (SRE)",
    "UI/UX Designer",
    "Graphic Designer",
    "User Interface (UI) Developer",
    "User Experience (UX) Researcher",
    "Information Security Analyst",
    "Penetration Tester (Ethical Hacker)",
    "IT Support Specialist",
    "Systems Analyst",
    "IT Manager",
    "Scrum Master",
    "Technical Writer",
    "Data Analyst",
    "IT Consultant",
    "Enterprise Architect",
    "Solutions Architect",
    "Data Architect",
    "Network Architect",
    "Cloud Architect",
    "Web Developer",
    "Mobile Application Tester",
    "Database Developer",
    "Big Data Engineer",
    "IoT Developer",
    "Embedded Systems Engineer",
    "Game Developer",
    "Blockchain Developer",
    "Cybersecurity Consultant",
    "IT Auditor",
    "Virtual Reality (VR) Developer",
    "Augmented Reality (AR) Developer",
    "IT Sales Representative",
    "Business Intelligence (BI) Analyst",
    "System Integrator",
    "IT Trainer",
    "Chatbot Developer",
    "Natural Language Processing (NLP) Engineer",
    "AI Ethics Researcher",
    "Digital Marketing Specialist",
    "Content Strategist",
    "DevSecOps Engineer",
    "Robotics Engineer",
    "IT Compliance Officer",
    "Computer Vision Engineer",
    "Software Test Automation Engineer",
    "Customer Support Engineer",
    "Data Warehouse Developer",
    "IT Procurement Specialist",
    "Cloud Solutions Architect",
    "IT Vendor Manager",
    "Change Management Specialist",
    "Data Center Technician",
  ];


  export default jobRoles;