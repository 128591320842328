import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Container from "components/Container/Container";
import FrameComponent from "components/FrameComponent";
import FrameComponent2 from "components/FrameComponent2";
import WebsiteFooter from "components/WebsiteFooter/WebsiteFooter";
import { useState } from "react";

const BookDemo = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showBookingPopup, setShowBookingPopup] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [selectedDateTime, setSelectedDateTime] = useState({
    1: { date: "", time: "" },
    2: { date: "", time: "" },
    3: { date: "", time: "" },
  });

  const handleDateTimeChange = (slot, { date, time }) => {
    setSelectedDateTime((prev) => ({
      ...prev,
      [slot]: {
        ...prev[slot],
        ...(date && { date }),
        ...(time && { time }),
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !email) {
      return;
    }

    const isAnySlotFilled = Object.values(selectedDateTime).some(
      (slot) => slot.date && slot.time
    );

    const backendURL = process.env.REACT_APP_API_URL;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        date1: selectedDateTime[1].date,
        date2: selectedDateTime[2].date,
        date3: selectedDateTime[3].date,
        time1: selectedDateTime[1].time,
        time2: selectedDateTime[2].time,
        time3: selectedDateTime[3].time,
      }),
    };

    const resultFromBackend = await fetch(`${backendURL}/book-demo`, requestOptions);
    const res = await resultFromBackend.json();

    if (isAnySlotFilled) {
      if (res.success) {
        setShowPopup(true);
        setShowErrorPopup(false);
        setTimeout(() => {
          setShowPopup(false);
          window.location.reload(); // Refresh the page
        }, 4000);
      } else {
        setShowErrorPopup(true);
        setShowPopup(false);
        setTimeout(() => {
          setShowErrorPopup(false);
        }, 4000);
      }
    } else {
      setValidationError(true);
      setTimeout(() => {
        setValidationError(false);
      }, 4000);
    }
  };

  return (
    <>
      <Container
        mainhead="Book Demo"
        subtext="Experience Hiring Excellence: Book Your Demo Today!"
      />
      <section className="self-stretch flex flex-row items-start justify-center pt-[120px] pb-[190px] px-[30px] box-border max-w-full mqmob1:px-[64px]">
        <form
          className="m-0 w-[1106px] flex flex-col items-start justify-start gap-[54.5px] max-w-full mqmob1:gap-[27px] mqtab1:w-[850px] mqtab2:w-[600px]  mqmob1:w-[500px]"
          onSubmit={handleSubmit}
        >
          <div className="self-stretch flex flex-row items-start justify-between max-w-full gap-[20px] mqtab2:flex-wrap  mqmob1:flex-col">
            <FrameComponent2 
              fullName="Full Name"
              required={true}
              setValue={setName}
              value={name}
            />
            <FrameComponent2
              fullName="Email Address"
              required={true}
              setValue={setEmail}
              value={email}
            />
          </div>
          <FrameComponent onDateTimeChange={handleDateTimeChange} />
          <div className="self-stretch flex flex-row items-start justify-center py-0 px-5">
            <button
              className="cursor-pointer [border:none] py-2.5 px-[52px] bg-darkslateblue rounded-[105.7px] flex flex-row items-start justify-start whitespace-nowrap hover:bg-cornflowerblue mq450:pl-5 mq450:pr-5 mq450:box-border"
              type="submit"
            >
              <div className="relative text-[26.4px] leading-[53px] font-medium font-poppins text-white text-center text-[#ffff]">
                Book a Demo
              </div>
            </button>
          </div>
          {validationError && (
            <div className="text-red-500 text-center w-full text-[20px]">
              Please select date and time for at least one slot.
            </div>
          )}
        </form>
      </section>
      {showPopup && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-[#3ABA6F] shadow-lg p-6 w-[29%]  mqlap:w-[37%] mqtab1:w-[40%] mqtab2:w-[49%] mqmob1:w-[45%] absolute rounded-[17px] flex flex-col justify-center items-center border-[2px] border-solid border-[#000000] p-[29px] mqmob1:p-[15px]">
            <CheckCircleOutlineIcon
              sx={{ height: "44px", width: "44px", color: "white" }}
            />
            <div className="text-[37px] mqtab1:text-[32px] mqtab2:text-[27px] mqmob1:text-[20px] p-[10px] text-[#fff] align-center">
              Your demo has been booked successfully on{" "}
              {selectedDateTime[1].date ||
                selectedDateTime[2].date ||
                selectedDateTime[3].date}{" "}
              at{" "}
              {selectedDateTime[1].time ||
                selectedDateTime[2].time ||
                selectedDateTime[3].time}
              !
            </div>
          </div>
        </div>
      )}
      {showBookingPopup && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-[#314CA3] shadow-lg p-6 w-1/4 absolute rounded-[15px] flex flex-col justify-center items-center border-[2px] border-solid border-[#000000] p-[29px]">
            <div className="text-[37px] p-[10px] text-[#fff] align-center">
              Booking a demo. Please wait
            </div>
          </div>
        </div>
      )}
      {showErrorPopup && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-[#ED4337] shadow-lg p-6 w-1/4 absolute rounded-[15px] flex flex-col justify-center items-center border-[2px] border-solid border-[#000000] p-[29px]">
            <CancelIcon
              sx={{ height: "44px", width: "44px", color: "white" }}
            />
            <div className="text-[37px] p-[10px] text-[#fff] align-center">
              Please try again later
            </div>
          </div>
        </div>
      )}
      <WebsiteFooter />
    </>
  );
};

export default BookDemo;
