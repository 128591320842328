import styled from "styled-components";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import supabase from "components/superBaseConfig";
const RecruiterRoboLogo31Icon = styled.img`
  position: absolute;
  top: 8.1px;
  left: 0px;
  width: 68.5px;
  height: 67.1px;
  object-fit: cover;
`;
const RecruiterRoboLogo31Wrapper = styled.div`
  position: relative;
  width: 68.5px;
  height: 82.5px;
  overflow: hidden;
  flex-shrink: 0;
`;
const WebsiteName = styled.div`
  position: relative;
  color: #161616;
  line-height: 135.5%;
  font-weight: 600;
  display: inline-block;
  width: 150.5px;
  height: 24.1px;
  flex-shrink: 0;
`;
const FrameParent = styled.div`
  position: absolute;
  top: 48px;
  left: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const SignInTo = styled.div`
  position: relative;
  font-size: 18.97px;
  line-height: 30.83px;
  font-weight: 600;
  text-align: center;
`;
const InputLabelmedium = styled.div`
  position: absolute;
  top: 0%;
  left: calc(50% - 156.5px);
  line-height: 17.19px;
`;
const GroupChild = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 156.5px);
  border-radius: 4.74px;
  background-color: var(--color-white);
  border: 0.6px solid var(--color-gainsboro);
  box-sizing: border-box;
  width: 313px;
  height: 30.8px;
`;
// const InputPlaceholdermediumon = styled.div`
//   position: absolute;
//   top: 25%;
//   left: calc(50% - 149.4px);
//   line-height: 15.41px;
// `;
const RectangleParent = styled.div`
  position: absolute;
  top: 17.8px;
  left: calc(50% - 156.5px);
  width: 313px;
  height: 30.8px;
  font-size: 9.48px;
  color: var(--color-darkgray-100);
`;
const Email = styled.div`
  position: relative;
  width: 313px;
  height: 48.6px;
`;
const RectangleGroup = styled.div`
  position: absolute;
  top: 17.8px;
  left: calc(50% - 156.5px);
  width: 313px;
  height: 30.8px;
  font-size: 9.48px;
  color: var(--color-silver-100);
`;
const SubmitChild = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 156.5px);
  border-radius: 3.56px;
  background-color: var(--color-darkslateblue);
  width: 313px;
  height: 29.6px;
`;
const LogIn = styled.div`
  position: absolute;
  top: calc(50% - 7.7px);
  left: calc(50% - 16.6px);
  line-height: 15.41px;
`;
const Submit = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 156.5px);
  width: 313px;
  height: 29.6px;
`;
const SignIn = styled.span`
  text-decoration: underline;
`;
const AlreadyHaveAnContainer = styled.div`
  position: absolute;
  top: 43.3px;
  left: 82.4px;
  line-height: 15.41px;
  text-align: left;
  font-family: var(--font-poppins);
  color: var(--color-gray-400);
`;
const SubmitParent = styled.div`
  position: relative;
  width: 313px;
  height: 58.7px;
  text-align: center;
  font-size: 9.48px;
  color: var(--color-white);
`;
const SignInToDhiwiseParent = styled.div`
  position: absolute;
  top: 140px;
  left: calc(50% - 156.5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 14.23px;
  font-size: 10.67px;
  color: var(--color-gray-200);
  font-family: var(--font-inter);
`;
const SignupMobileRoot = styled.div`
  position: fixed;
  background-color: var(--color-whitesmoke);
  width: 100%;
  height: 100vh;
  overflow: hidden;
  text-align: left;
  font-size: 18.39px;
  color: var(--color-gray-300);
  font-family: var(--font-poppins);
`;

const RecruiterMobSignupMobile = () => {
  const navigate = useNavigate();

  const [fullName, setfullName] = useState("");
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [numberofEmployees, setNumberofEmployees] = useState(null)
  const [contactNumber, setContactNumber] = useState("")
  const OnSignInContainerClick = useCallback(() => {
    navigate("/recruiter-login");
  }, [navigate]);

  const onLogo = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const handleSubmit = async (e) => {
    //alert("Entered");
    e.preventDefault();

    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    try {
      const {  error } = await supabase.auth.signUp({
        email: email,
        password: password,
        options: {
          data: { full_name: fullName },
        },
      });
      if (error) {
        throw error;
      }
      alert("Check your email for confirmation!");
    } catch (error) {
      alert("Please try again");
    }

    await insertIntoDB();
    //alert("Inserted into DB");
  };

  const insertIntoDB = async () => {
    try {
      await supabase.from("UserProfile").upsert(
        {
          username: fullName,
          password: password,
          email: email,
          linkedin: null,
        },
        { returning: "minimal" },
      );
    } catch (error) {
      alert("Please try again later");
    }
  };

  // const OnSignInContainerClick = useCallback(() => {
  //   navigate("/recruiter-login");
  // }, [navigate]);
  // const onLogo = useCallback(() => {
  //   navigate("/");
  // }, [navigate]);
  return (
    <SignupMobileRoot>
      <FrameParent>
        <RecruiterRoboLogo31Wrapper>
          <RecruiterRoboLogo31Icon
            alt=""
            src="/recruiter-robo-logo-3-1@2x.png"
            onClick={onLogo}
            style={{ cursor: "pointer" }}
          />
        </RecruiterRoboLogo31Wrapper>
        <WebsiteName onClick={onLogo} style={{cursor: "pointer", color: "rgb(90, 198, 200)" }}>
          Recruiter Robo
        </WebsiteName>
      </FrameParent>
      <SignInToDhiwiseParent>
        <SignInTo>Sign up to Robo Recruiter mob</SignInTo>
      



        <Email>
          <InputLabelmedium>Company name</InputLabelmedium>
          <RectangleParent>
            <GroupChild />
            <input
               style={{
                fontSize: "12px",
                position: "absolute",
                top: "0px",
                left: "calc(50% - 151.7px)",
                borderRadius: "var(--br-8xs-6)",
                backgroundColor: "var(--color-white)",
                border: "0.6px solid var(--color-gainsboro)",
                boxSizing: "border-box",
                width: "100%",
                height: "29.9px",
              }}
              onChange={(e) => setfullName(e.target.value)}
              type="text"
              value={fullName}
              placeholder="Enter Full name"
            />
          </RectangleParent>
        </Email>
        <Email>
          <InputLabelmedium>Work Email address</InputLabelmedium>
          <RectangleGroup>
            <GroupChild />
            <input
               style={{
                fontSize: "12px",
                position: "absolute",
                top: "0px",
                left: "calc(50% - 151.7px)",
                borderRadius: "var(--br-8xs-6)",
                backgroundColor: "var(--color-white)",
                border: "0.6px solid var(--color-gainsboro)",
                boxSizing: "border-box",
                width: "100%",
                height: "29.9px",
              }}
              onChange={(e) => setemail(e.target.value)}
              type="email"
              value={email}
              placeholder="Enter Email address"
            />
          </RectangleGroup>
        </Email>
        <Email>
          <InputLabelmedium>Number of employees</InputLabelmedium>
          <RectangleGroup>
            <GroupChild />
            <input
               style={{
                fontSize: "12px",
                position: "absolute",
                top: "0px",
                left: "calc(50% - 151.7px)",
                borderRadius: "var(--br-8xs-6)",
                backgroundColor: "var(--color-white)",
                border: "0.6px solid var(--color-gainsboro)",
                boxSizing: "border-box",
                width: "100%",
                height: "29.9px",
              }}
              onChange={(e) => setNumberofEmployees(e.target.value)}
              type="number"
              min={0}
              value={numberofEmployees}
              placeholder="Enter number of employees"
            />
          </RectangleGroup>
        </Email>
        <Email>
          <InputLabelmedium>Company Contact Number</InputLabelmedium>
          <RectangleGroup>
            <GroupChild />
            <input
             style={{
              fontSize: "12px",
              position: "absolute",
              top: "0px",
              left: "calc(50% - 151.7px)",
              borderRadius: "var(--br-8xs-6)",
              backgroundColor: "var(--color-white)",
              border: "0.6px solid var(--color-gainsboro)",
              boxSizing: "border-box",
              width: "100%",
              height: "29.9px",
            }}
              onChange={(e) => setContactNumber(e.target.value)}
              type="tel"
              value={contactNumber}
              placeholder="Enter Company Contact Number"
            />
          </RectangleGroup>
        </Email>
        <Email>
          <InputLabelmedium>Enter password</InputLabelmedium>
          <RectangleGroup>
            <GroupChild />
            <input
             style={{
              fontSize: "12px",
              position: "absolute",
              top: "0px",
              left: "calc(50% - 151.7px)",
              borderRadius: "var(--br-8xs-6)",
              backgroundColor: "var(--color-white)",
              border: "0.6px solid var(--color-gainsboro)",
              boxSizing: "border-box",
              width: "100%",
              height: "29.9px",
            }}
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </RectangleGroup>
        </Email>
        <Email>
          <InputLabelmedium>Confirm password</InputLabelmedium>
          <RectangleGroup>
            <GroupChild />
            <input
              style={{
                fontSize: "12px",
                position: "absolute",
                top: "0px",
                left: "calc(50% - 151.7px)",
                borderRadius: "var(--br-8xs-6)",
                backgroundColor: "var(--color-white)",
                border: "0.6px solid var(--color-gainsboro)",
                boxSizing: "border-box",
                width: "100%",
                height: "29.9px",
              }}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
              value={confirmPassword}
              placeholder="Enter Confirm password"
            />
          </RectangleGroup>
        </Email>







        <SubmitParent>
          <Submit style={{ cursor: "pointer" }} onClick={handleSubmit}>
            <SubmitChild />
            <LogIn>Sign up</LogIn>
          </Submit>
          <AlreadyHaveAnContainer>
            {`Already have an account? `}
            <SignIn
              onClick={OnSignInContainerClick}
              style={{ cursor: "pointer" }}
            >
              Sign in
            </SignIn>
          </AlreadyHaveAnContainer>
        </SubmitParent>
      </SignInToDhiwiseParent>
    </SignupMobileRoot>
  );
};

export default RecruiterMobSignupMobile;
