export const fetchNotifications = (user_id) => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/get-notifications/${user_id}`);
            if (!response.ok) {
                return;
            }
            const data = await response.json() || [];
            dispatch({ type: "ADD_NOTIFICATIONS", payload: data });
        } catch (error) {
            console.error("Error fetching notifications:", error.message);
            throw error;
        }
    };
};
