import { Img, Text } from "components";
import Sidebar1 from "pages/RecruiterDashBoardPages/RecruiterSidebar";
import TopHeader from "pages/RecruiterDashBoardPages/TopHeader";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import './index.css';

const UploadJD = () => {
  const navigate = useNavigate();
  const [files, setFiles] = useState(null);
  const inputRef = useRef();

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setFiles(event.dataTransfer.files)
  };
  // const handleChange = (event) => {
  //   setFiles(event.dataTransfer.files)
  // };
  const handleFileUpload = () => {
    navigate("/addjob-jd");
  };

  return (
    <>
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <Sidebar1 />
          <div className="flex flex-1 flex-col font-inter gap-[50px] items-center justify-start md:px-5 w-full">
            <div className="bg-white-A700 flex flex-row md:gap-10 items-center justify-between p-[13px] w-full">
              <TopHeader header={"Add Job"} />
            </div>
            <div className="bg-white-A700 flex flex-col font-inter items-start justify-start mx-auto p-[35px] sm:px-5 w-full">
              <div className="flex md:flex-col flex-row md:gap-10 gap-24 items-start justify-start mb-[195px] md:ml-[0] ml-[43px] md:px-5 w-[89%] md:w-full">
                <div className="flex flex-col items-start justify-start w-[33%] md:w-full">
                  <div className="flex flex-row font-inter gap-4 items-start justify-start w-[38%] md:w-full">
                    <Img
                      className="common-pointer h-6 w-6"
                      src="images/img_arrowleft.svg"
                      alt="arrowleft"
                      onClick={() => navigate(-1)}
                    />
                    <Text
                      className="text-2xl md:text-[22px] text-black-900_7f sm:text-xl"
                      size="txtInterMedium24"
                    >
                      File Upload
                    </Text>
                  </div>
                  <div
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    className="bg-gray-400_14 flex flex-col font-inter gap-8 items-center justify-center mt-[109px] outline-[0.5px] outline-dashed outline-gray-500 p-[111px] md:px-10 sm:px-5 w-full">
                    <Img
                      className="h-12 mt-[61px] w-12"
                      src="images/uploadicon.svg"
                      alt="twitter"
                    />
                    <Text
                      className="mb-[46px] text-2xl md:text-[22px] text-gray-700 sm:text-xl"
                      size="txtInterRegular24"
                    >
                      Drag files to upload
                    </Text>
                  </div>
                  <label
                    htmlFor="actual-btn"
                    className="cursor-pointer font-medium font-poppins h-[50px] leading-[normal] md:ml-[0] ml-[129px] mt-9 text-center text-xl w-[220px] p-2.5 bg-blue-A200 rounded-[15px] text-white-A700"
                  >
                    Choose Folder
                    <input
                      type="file"
                      id="actual-btn"
                      ref={inputRef}
                      hidden
                      onChange={handleFileUpload}
                    />
                  </label>
                </div>
                <div className="flex flex-col gap-[47px] items-start justify-start md:mt-0 mt-[140px] w-[61%] md:w-full">
                  <Text
                    className="ml-1 md:ml-[0] text-[24px] md:text-[22px] text-black-900_7f sm:text-xl"
                    size="txtInterMedium24"
                  >
                    Uploading
                  </Text>
                  <div className="flex md:flex-col flex-row gap-[30px] items-start justify-between w-full">
                    <Img
                      className="h-12 md:h-auto object-cover w-12"
                      src="images/img_happyfile.png"
                      alt="happyfile"
                    />
                    <div className="flex flex-col items-start justify-start w-full">
                      <Text
                        className="text-gray-700 text-[20px] mb-4"
                        size="txtInterRegular20"
                      >
                        {files?.length > 0 ? files?.[0]?.name : "Project manager JD.doc"} 32MB
                      </Text>
                      <progress className="flex w-full" id="file" max="100" value="70">70%</progress>
                      <div className="h-2 mt-[13px] overflow-hidden relative w-full">
                        <div className="w-full h-full absolute bg-gray_400_30 rounded-[4px]"></div>
                        <div
                          className="h-full absolute bg-blue_A200  rounded-[4px]"
                          style={{ width: "43%" }}
                        ></div>
                      </div>
                      <div className="flex flex-row sm:gap-10 items-start justify-between mt-[15px] w-full">
                        <Text
                          className="text-gray-500 text-xl"
                          size="txtInterRegular20Gray500"
                        >
                          37% completed
                        </Text>
                        <Text
                          className="text-gray-500 text-xl"
                          size="txtInterRegular20Gray500"
                        >
                          90KB/sec
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadJD;
