export const fetchProfilePreferenceData = (userId) => async (dispatch) => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}/get_preferences`;
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user_id: userId }),
    };

    const userResponse = await fetch(apiUrl, requestOptions);

    if (userResponse.ok) {
      const userData = await userResponse.json();
      const userDetails = userData.data;

      dispatch({
        type: "FETCH_PROFILE_PREFERENCE_DATA_SUCCESS",
        payload: userDetails,
      });
    } else {
      dispatch({
        type: "FETCH_PROFILE_PREFERENCE_DATA_FAILURE",
        payload: "Failed to fetch preference data",
      });
      throw new Error("Failed to fetch preference data");
    }
  } catch (error) {
    dispatch({ type: "FETCH_PROFILE_PREFERENCE_DATA_FAILURE", payload: error });
    throw error;
  }
};
