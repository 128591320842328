
import {
  Dialog,
} from "@mui/material";
import { Img, List, SelectBox, Text } from "components";
import TopHeader from "pages/RecruiterDashBoardPages/TopHeader";
import Sidebar1 from "pages/UniversityDashBoardPages/UniversitySidebar";
import { useState } from "react";

const dropdownOptionsList = [
  { label: "Option1", value: "option1" },
  { label: "Option2", value: "option2" },
  { label: "Option3", value: "option3" },
];
const dropdownOneOptionsList = [
  { label: "Option1", value: "option1" },
  { label: "Option2", value: "option2" },
  { label: "Option3", value: "option3" },
];



const UniversityDashboard = () => {

  // const [reportLoad, setreportLoad] = useState(true);
  const [third, setst3] = useState(false);
  const [Stopen, setst] = useState(false);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen1 = () => {
    // setreportLoad(true);
    setst3(false);
    handleClose();
    setst(true);
  };

  const handleClose1 = () => {
    setst(false);
  };

  const handleOpen2 = () => {
    handleClose1();
    setst3(true);
    // setreportLoad(true);

  };

  // const handleClose2 = () => {
  //   setst3(false);
  // };


  return (
    <>
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <Sidebar1 clickedTab={"Dashboard"} />

          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
            <TopHeader header={"Dashboard"} />


            <div className="bg-white-A700 flex flex-col items-center justify-start p-7 sm:px-5 rounded-[15px] w-[94%] md:w-full">
              <div className=" font-poppins md:h-[1103px] h-[1165px] relative w-[94%] md:w-full">




                {/* <div className="flex flex-1 flex-col font-inter gap-[50px] items-center justify-start md:px-5 w-full">
            
            <div className="bg-white-A700 flex flex-col items-center justify-start p-[34px] sm:px-5 rounded-[15px] w-[95%] md:w-full"> */}
                <div className="flex flex-col items-start justify-start mb-[13px] w-[98%] md:w-full">
                  <div className="flex sm:flex-col flex-row sm:gap-10 gap-[68px] items-center justify-start ml-1 md:ml-[0] w-[36%] md:w-full">
                    <SelectBox

                      className="bg-white-A700 leading-[normal] pl-7 pr-[35px] sm:px-5 py-3.5 rounded-[15px] shadow-md shadow-black-700 text-base text-gray-700 text-left w-[44%] sm:w-full"
                      placeholderClassName="text-gray-700"
                      indicator={
                        <Img
                          className="h-3 mr-[0]"
                          src="images/img_arrowdown2.svg"
                          alt="arrow_down"
                        />
                      }
                      isMulti={false}
                      name="dropdown"
                      options={dropdownOptionsList}
                      isSearchable={false}
                      placeholder="Department"
                    />
                    <SelectBox
                      className="bg-white-A700 leading-[normal] pl-7 pr-[35px] sm:px-5 py-3.5 rounded-[15px] shadow-md shadow-black-700 text-base text-gray-700 text-left w-[44%] sm:w-full"
                      placeholderClassName="text-gray-700"
                      indicator={
                        <Img
                          className="h-3 mr-[0]"
                          src="images/img_arrowdown2.svg"
                          alt="arrow_down"
                        />
                      }
                      isMulti={false}
                      name="dropdown_One"
                      options={dropdownOneOptionsList}
                      isSearchable={false}
                      placeholder="Batch"
                    />
                  </div>
                  <div className="flex md:flex-col flex-row font-poppins md:gap-10 items-center justify-between mt-[43px] w-full">
                    <div className="border border-gray-400_7f border-solid flex flex-col items-start justify-start p-[11.67px] rounded-[10px] w-[205px]">
                      <div className="flex flex-col gap-3.5 items-start justify-start w-[84%]">
                        <div className="flex flex-row items-start justify-between w-full">
                          <Text
                            className="sm:text-[25.19px] md:text-[27.19px] text-[29.19px] text-center text-indigo-600 w-auto"
                            size="txtPoppinsSemiBold2919"
                          >
                            1500
                          </Text>
                          <Img
                            className="h-[29px] md:h-auto object-cover rounded-[5px] w-[29px]"
                            src="images/img_linechart copy.png"
                            alt="linechart"
                          />
                        </div>
                        <Text
                          className="text-[14.59px] text-blue_gray-400 text-center w-auto"
                          size="txtPoppinsMedium1459"
                        >
                          Total Students
                        </Text>
                      </div>
                    </div>
                    <div className="border border-gray-400_7f border-solid flex flex-col items-start justify-start p-[11.76px] rounded-[11px] w-[206px]">
                      <div className="flex flex-col gap-3.5 items-start justify-start w-[79%]">
                        <div className="flex flex-row items-start justify-between w-full">
                          <Text
                            className="sm:text-[25.19px] md:text-[27.19px] text-[29.19px] text-center text-indigo-600 w-auto"
                            size="txtPoppinsSemiBold2919"
                          >
                            1414
                          </Text>
                          <Img
                            className="h-[29px] md:h-auto object-cover rounded-[5px] w-[29px]"
                            src="images/img_linechart_29x29.png"
                            alt="linechart_One"
                          />
                        </div>
                        <Text
                          className="text-[14.59px] text-blue_gray-400 text-center w-auto"
                          size="txtPoppinsMedium1459"
                        >
                          Students Placed
                        </Text>
                      </div>
                    </div>
                    <div className="border border-gray-400_7f border-solid flex flex-col items-start justify-start p-[11.67px] rounded-[10px] w-[205px]">
                      <div className="flex flex-col gap-4 items-start justify-start w-[78%]">
                        <div className="flex flex-row items-start justify-between w-full">
                          <Text
                            className="sm:text-[25.19px] md:text-[27.19px] text-[29.19px] text-center text-indigo-600 w-auto"
                            size="txtPoppinsSemiBold2919"
                          >
                            54
                          </Text>
                          <Img
                            className="h-[29px] md:h-auto object-cover rounded-[5px] w-[29px]"
                            src="images/img_linechart copy.png"
                            alt="linechart_Two"
                          />
                        </div>
                        <Text
                          className="text-[14.59px] text-blue_gray-400 text-center w-auto"
                          size="txtPoppinsMedium1459"
                        >
                          Opened Jobs
                        </Text>
                      </div>
                    </div>
                    <div className="border border-gray-400_7f border-solid flex flex-col items-start justify-start p-[11.67px] rounded-[10px] w-[205px]">
                      <div className="flex flex-col gap-3.5 items-start justify-start w-4/5">
                        <div className="flex flex-row items-start justify-between w-full">
                          <Text
                            className="sm:text-[25.19px] md:text-[27.19px] text-[29.19px] text-center text-indigo-600 w-auto"
                            size="txtPoppinsSemiBold2919"
                          >
                            80%
                          </Text>
                          <Img
                            className="h-[29px] md:h-auto object-cover rounded-[5px] w-[29px]"
                            src="images/img_linechart copy.png"
                            alt="linechart_Three"
                          />
                        </div>
                        <Text
                          className="text-[14.59px] text-blue_gray-400 text-center w-auto"
                          size="txtPoppinsMedium1459"
                        >
                          Placement Rate
                        </Text>
                      </div>
                    </div>
                    <div className="border border-gray-400_7f border-solid flex flex-col items-start justify-start p-[11.76px] rounded-[11px] w-[206px]">
                      <div className="flex flex-col gap-[15px] items-start justify-start w-full">
                        <div className="flex flex-row items-start justify-between w-full">
                          <Text
                            className="sm:text-[25.41px] md:text-[27.41px] text-[29.41px] text-center text-indigo-600 w-auto"
                            size="txtPoppinsSemiBold2941"
                          >
                            5.5LPA
                          </Text>
                          <Img
                            className="h-[29px] md:h-auto object-cover rounded-[5px] w-[29px]"
                            src="images/img_linechart_29x29.png"
                            alt="linechart_Four"
                          />
                        </div>
                        <Text
                          className="text-[14.71px] text-blue_gray-400 text-center w-auto"
                          size="txtPoppinsMedium1471"
                        >
                          Average Package
                        </Text>
                      </div>
                    </div>
                    <div className="border border-gray-400_7f border-solid flex flex-col items-start justify-start p-[11.67px] rounded-[10px] w-[205px]">
                      <div className="flex flex-col gap-4 items-start justify-start w-[92%]">
                        <div className="flex flex-row gap-[72px] items-start justify-start w-[82%] md:w-full">
                          <Text
                            className="sm:text-[25.19px] md:text-[27.19px] text-[29.19px] text-center text-indigo-600 w-auto"
                            size="txtPoppinsSemiBold2919"
                          >
                            23
                          </Text>
                          <Img
                            className="h-[29px] md:h-auto object-cover rounded-[5px] w-[29px]"
                            src="images/img_linechart copy.png"
                            alt="linechart_Five"
                          />
                        </div>

                        <text

                          className="text-[14.59px] text-blue_gray-400 text-center w-auto"
                          size="txtPoppinsMedium1459"
                        >
                          Upcoming Companies
                        </text>
                      </div>
                    </div>
                  </div>
                  <List
                    className="sm:flex-col flex-row font-inter md:gap-10 gap-[86px] grid md:grid-cols-1 grid-cols-2 ml-1 md:ml-[0] mt-11 w-[65%]"
                    orientation="horizontal"
                  >
                    <div className="flex flex-col items-center justify-start pl-0.5 w-full">
                      <div className="flex flex-col justify-start w-full">
                        <Text
                          className="md:ml-[0] ml-[181px] text-[12.92px] text-blue_gray-400"
                          size="txtInterBold1292"
                        >
                          Students Placed
                        </Text>
                        <div className="flex sm:flex-col flex-row gap-3 items-start justify-between mt-4 w-full">
                          <Text
                            className="sm:mt-0 mt-[41px] rotate-[90deg] text-[12.92px] text-blue_gray-400"
                            size="txtInterRegular1292"
                          >
                            Total Number of Students
                          </Text>
                          <div className="md:h-64 h-[258px] relative w-[95%] sm:w-full">
                            <div className="absolute flex flex-col h-full inset-[0] items-end justify-center m-auto pb-[5px] pl-[5px] w-[98%]">
                              <div className="flex flex-col items-center justify-start w-[93%] md:w-full">
                                <div className="flex flex-row gap-[13px] items-center justify-between w-full">
                                  <div className="flex flex-col gap-[38px] items-start justify-start">
                                    <Text
                                      className="h-4 text-[12.92px] text-blue_gray-400 text-right"
                                      size="txtInterRegular1292"
                                    >
                                      40
                                    </Text>
                                    <Text
                                      className="h-4 text-[12.92px] text-blue_gray-400 text-right"
                                      size="txtInterRegular1292"
                                    >
                                      30
                                    </Text>
                                    <Text
                                      className="text-[12.92px] text-blue_gray-400 text-right"
                                      size="txtInterRegular1292"
                                    >
                                      20
                                    </Text>
                                    <Text
                                      className="ml-0.5 md:ml-[0] text-[12.92px] text-blue_gray-400 text-right"
                                      size="txtInterRegular1292"
                                    >
                                      10
                                    </Text>
                                    <Text
                                      className="ml-2 md:ml-[0] text-[12.92px] text-blue_gray-400 text-right"
                                      size="txtInterRegular1292"
                                    >
                                      0
                                    </Text>
                                  </div>
                                  <div
                                    className="bg-cover bg-no-repeat flex flex-col h-[219px] items-center justify-start w-[92%]"
                                    style={{
                                      backgroundImage:
                                        "url('images/img_group3.svg')",
                                    }}
                                  >
                                    <div
                                      className="bg-cover bg-no-repeat flex flex-col h-[219px] items-start justify-end pt-1.5 px-1.5 w-full"
                                      style={{
                                        backgroundImage:
                                          "url('images/img_group4.svg')",
                                      }}
                                    >
                                      <Img
                                        className="h-[174px] mt-[38px]"
                                        src="images/img_dataseries.svg"
                                        alt="dataseries"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="flex flex-row items-center justify-center w-[71%] md:w-full">
                                  <Text
                                    className="text-[12.92px] text-blue_gray-400 text-center"
                                    size="txtInterRegular1292"
                                  >
                                    2020
                                  </Text>
                                  <Text
                                    className="ml-10 text-[12.92px] text-blue_gray-400 text-center"
                                    size="txtInterRegular1292"
                                  >
                                    2021
                                  </Text>
                                  <Text
                                    className="ml-[41px] text-[12.92px] text-blue_gray-400 text-center"
                                    size="txtInterRegular1292"
                                  >
                                    2022
                                  </Text>
                                  <Text
                                    className="ml-[39px] text-[12.92px] text-blue_gray-400 text-center"
                                    size="txtInterRegular1292"
                                  >
                                    2023
                                  </Text>
                                </div>
                              </div>
                            </div>
                            <Text
                              className="absolute left-[0] rotate-[90deg] text-[12.92px] text-blue_gray-400 top-[26%]"
                              size="txtInterRegular1292"
                            >
                              Students Placed
                            </Text>
                          </div>
                        </div>
                        <Text
                          className="md:ml-[0] ml-[244px] mt-2.5 text-[12.92px] text-blue_gray-400"
                          size="txtInterRegular1292"
                        >
                          Year
                        </Text>
                      </div>
                    </div>
                    <div className="flex flex-col items-end justify-start sm:pl-5 pl-[22px] w-full">
                      <div className="flex flex-col justify-start w-full">
                        <Text
                          className="md:ml-[0] ml-[161px] text-[12.94px] text-blue_gray-400"
                          size="txtInterBold1294"
                        >
                          CTC
                        </Text>
                        <div className="flex sm:flex-col flex-row sm:gap-5 items-start justify-evenly mt-4 w-full">
                          <Text
                            className="sm:mt-0 mt-[82px] rotate-[90deg] text-[12.94px] text-blue_gray-400"
                            size="txtInterRegular1294"
                          >
                            CTC in LPA
                          </Text>
                          <div className="flex sm:flex-1 flex-col items-end justify-start pb-[5px] pl-[5px] w-[97%] sm:w-full">
                            <div className="flex flex-col items-center justify-start w-[93%] md:w-full">
                              <div className="flex flex-row gap-[13px] items-center justify-between w-full">
                                <div className="flex flex-col gap-[39px] items-start justify-start">
                                  <Text
                                    className="h-4 text-[12.94px] text-blue_gray-400 text-right"
                                    size="txtInterRegular1294"
                                  >
                                    40
                                  </Text>
                                  <Text
                                    className="h-4 text-[12.94px] text-blue_gray-400 text-right"
                                    size="txtInterRegular1294"
                                  >
                                    30
                                  </Text>
                                  <Text
                                    className="text-[12.94px] text-blue_gray-400 text-right"
                                    size="txtInterRegular1294"
                                  >
                                    20
                                  </Text>
                                  <Text
                                    className="ml-0.5 md:ml-[0] text-[12.94px] text-blue_gray-400 text-right"
                                    size="txtInterRegular1294"
                                  >
                                    10
                                  </Text>
                                  <Text
                                    className="ml-2 md:ml-[0] text-[12.94px] text-blue_gray-400 text-right"
                                    size="txtInterRegular1294"
                                  >
                                    0
                                  </Text>
                                </div>
                                <div className="h-[219px] relative w-[92%]">
                                  <Img
                                    className="h-[219px] m-auto"
                                    src="images/img_group3.svg"
                                    alt="verticalgridlin"
                                  />
                                  <div
                                    className="absolute bg-cover bg-no-repeat flex flex-col h-full inset-[0] items-start justify-center m-auto pt-1.5 px-1.5 w-full"
                                    style={{
                                      backgroundImage:
                                        "url('images/img_group4.svg')",
                                    }}
                                  >
                                    <Img
                                      className="h-[164px] mt-12"
                                      src="images/img_dataseries_indigo_600.svg"
                                      alt="dataseries"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="flex flex-row items-center justify-center w-[71%] md:w-full">
                                <Text
                                  className="text-[12.94px] text-blue_gray-400 text-center"
                                  size="txtInterRegular1294"
                                >
                                  2020
                                </Text>
                                <Text
                                  className="ml-10 text-[12.94px] text-blue_gray-400 text-center"
                                  size="txtInterRegular1294"
                                >
                                  2021
                                </Text>
                                <Text
                                  className="ml-[41px] text-[12.94px] text-blue_gray-400 text-center"
                                  size="txtInterRegular1294"
                                >
                                  2022
                                </Text>
                                <Text
                                  className="ml-[39px] text-[12.94px] text-blue_gray-400 text-center"
                                  size="txtInterRegular1294"
                                >
                                  2023
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                        <Text
                          className="ml-56 md:ml-[0] mt-2.5 text-[12.94px] text-blue_gray-400"
                          size="txtInterRegular1294"
                        >
                          Year
                        </Text>
                      </div>
                    </div>
                  </List>
                  <div className="flex md:flex-col flex-row font-inter md:gap-5 items-center justify-start ml-1 md:ml-[0] mt-[70px] w-[98%] md:w-full">
                    <div className="bg-white-A700 border border-gray-400_7f border-solid flex flex-col items-center justify-start p-[18px] rounded-[15px] w-[29%] md:w-full">
                      <div className="flex flex-col items-center justify-start mb-[13px] w-[89%] md:w-full">
                        <button
                          onClick={handleOpen}
                          className="text-base text-blue_gray-400"
                          size="txtInterSemiBold16"
                        >
                          {" "}
                          Upcoming Companies
                        </button>
                        <Dialog open={open} disableBackdropClick={true}
                          //  onClose={handleClose} 
                          maxWidth="lg">
                          <div className="start-interview2">
                            <div className="group-wrapper">
                              <div className="group">
                                <div className="overlap">


                                  <p className="text-wrapper">
                                    Enter Upcoming Companies Details.
                                  </p>
                                </div>


                              </div>
                            </div>
                          </div>
                        </Dialog>
                        <div className="flex flex-row items-start justify-between mt-[19px] w-full">
                          <Img
                            className="h-[46px] md:h-auto object-cover"
                            src="images/img_image9 copy.png"
                            alt="imageNine"
                          />
                          <Img
                            className="h-[46px] md:h-auto object-cover"
                            src="images/img_image12 copy.png"
                            alt="imageTwelve"
                          />
                          <Img
                            className="h-[50px] md:h-auto object-cover"
                            src="images/img_image10 copy.png"
                            alt="imageTen"
                          />
                        </div>
                        <div className="flex flex-row items-center justify-between mt-10 w-[84%] md:w-full">
                          <Img
                            className="h-9 md:h-auto object-cover"
                            src="images/img_image11 copy.png"
                            alt="imageEleven"
                          />
                          <Img
                            className="h-[30px] md:h-auto object-cover"
                            src="images/img_image8 copy.png"
                            alt="imageEight"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="bg-white-A700 border border-gray-400_7f border-solid flex flex-col items-center justify-start md:ml-[0] ml-[99px] p-[18px] rounded-[15px] w-[29%] md:w-full">
                      <div className="flex flex-col gap-[23px] justify-start mb-[3px] w-[96%] md:w-full">
                        <button
                          onClick={handleOpen1}
                          className="md:ml-[0] ml-[54px] text-base text-blue_gray-400"
                          size="txtInterSemiBold16"
                        >
                          Top Recruiting Companies
                        </button>
                        <Dialog open={Stopen}
                          // onClose={handleClose1} 
                          disableBackdropClick={true}
                          maxWidth="lg">
                          <div className="start-interview2">
                            <div className="group-wrapper">
                              <div className="group">
                                <div className="overlap">


                                  <p className="text-wrapper">
                                    Enter Top Recruiting Companies Details.
                                  </p>
                                </div>


                              </div>
                            </div>
                          </div>
                        </Dialog>
                        <div className="flex flex-col gap-[25px] items-center justify-start w-full">
                          <div className="flex flex-row gap-5 items-center justify-between w-[97%] md:w-full">
                            <Img
                              className="h-[42px] md:h-auto object-cover"
                              src="images/img_image2 copy 3.png"
                              alt="imageTwo"
                            />
                            <Img
                              className="h-8 md:h-auto object-cover"
                              src="images/img_image3 copy 3.png"
                              alt="imageThree"
                            />
                            <Img
                              className="h-[41px] md:h-auto object-cover"
                              src="images/img_image4 copy 3.png"
                              alt="imageFour"
                            />
                          </div>
                          <div className="flex flex-row gap-5 items-center justify-between w-full">
                            <Img
                              className="h-[67px] md:h-auto object-cover"
                              src="images/img_image5 copy 3.png"
                              alt="imageFive"
                            />
                            <Img
                              className="h-[41px] md:h-auto object-cover w-[42px]"
                              src="images/img_image6 copy 3.png"
                              alt="imageSix"
                            />
                            <Img
                              className="h-8 md:h-auto object-cover"
                              src="images/img_image7 copy.png"
                              alt="imageSeven"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white-A700 border border-gray-400_7f border-solid flex flex-col items-start justify-start md:ml-[0] ml-[122px] p-[17px] rounded-[15px] w-[29%] md:w-full">
                      <div className="flex flex-col gap-[29px] justify-start mb-[3px] ml-3.5 md:ml-[0] w-[88%] md:w-full">
                        <button
                          onClick={handleOpen2}
                          className="md:ml-[0] ml-[89px] text-base text-blue_gray-400"
                          size="txtInterSemiBold16"
                        >
                          Recent Placements
                        </button>
                        <Dialog open={third}
                          //  onClose={handleClose2} 
                          disableBackdropClick={true}
                          maxWidth="lg">
                          <div className="start-interview2">
                            <div className="group-wrapper">
                              <div className="group">
                                <div className="overlap">


                                  <p className="text-wrapper">
                                    Enter Recent Placements Details.
                                  </p>
                                </div>


                              </div>
                            </div>
                          </div>
                        </Dialog>
                        <List
                          className="sm:flex-col flex-row gap-5 grid grid-cols-3 justify-center w-full"
                          orientation="horizontal"
                        >
                          <div className="bg-white-A700 flex flex-1 flex-col gap-[9px] items-center justify-start p-[11px] rounded-[11px] shadow-bs1 w-full">
                            <Img
                              className="h-[63px] md:h-auto object-cover w-[77%]"
                              src="images/img_image17.png"
                              alt="imageSeventeen"
                            />
                            <div className="flex flex-col gap-2 items-center justify-start w-[98%] md:w-full">
                              <Text
                                className="text-[7.5px] text-blue_gray-400 text-center"
                                size="txtInterRegular75"
                              >
                                <>
                                  Rahul Upadhay
                                  <br /> Package - 10 LPA
                                </>
                              </Text>
                              <Img
                                className="h-2 md:h-auto object-cover w-full"
                                src="images/img_image20.png"
                                alt="imageTwenty"
                              />
                            </div>
                          </div>
                          <div className="bg-white-A700 flex flex-1 flex-col gap-2 items-center justify-end p-1.5 rounded-[11px] shadow-bs1 w-full">
                            <div className="flex flex-col gap-[9px] items-center justify-start mt-1 w-[79%] md:w-full">
                              <Img
                                className="h-[63px] md:h-auto object-cover w-[86%] sm:w-full"
                                src="images/img_image19.png"
                                alt="imageNineteen"
                              />
                              <Text
                                className="text-[7.5px] text-blue_gray-400 text-center"
                                size="txtInterRegular75"
                              >
                                <>
                                  Priyanka Tiwari
                                  <br /> Package - 5 LPA
                                </>
                              </Text>
                            </div>
                            <Img
                              className="h-[13px] md:h-auto object-cover w-[95%]"
                              src="images/img_image21.png"
                              alt="imageTwentyOne"
                            />
                          </div>
                          <div className="bg-white-A700 flex flex-1 flex-col items-center justify-center p-[3px] rounded-[11px] shadow-bs1 w-full">
                            <div className="flex flex-col gap-[9px] items-center justify-start mt-2 w-[73%] md:w-full">
                              <Img
                                className="h-[63px] md:h-auto object-cover w-[86%] sm:w-full"
                                src="images/img_image18.png"
                                alt="imageEighteen"
                              />
                              <Text
                                className="text-[7.5px] text-blue_gray-400 text-center"
                                size="txtInterRegular75"
                              >
                                <>
                                  Rekha Pandey
                                  <br /> Package - 3 LPA
                                </>
                              </Text>
                            </div>
                            <Img
                              className="h-[21px] md:h-auto object-cover w-[99%]"
                              src="images/img_image22.png"
                              alt="imageTwentyTwo"
                            />
                          </div>
                        </List>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




        </div>
      </div>
      {/* </div>
            </div> */}




    </>


  );
};

export default UniversityDashboard;
