import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "../ErrorMessage";

const Switch = ({
  value,
  className,
  errors = [],
  onChange,
}) => {
  const [checked, setChecked] = React.useState(value);

  const handleChange = (event) => {
    const newValue = event.target.checked;
    setChecked(newValue);
    onChange?.(newValue);
  };

  return (
    <div className={`${className}`}>
      <label className="switch">
        <input
          type="checkbox"
          checked={checked}
          onChange={handleChange}
        />
        <span className="slider round"></span>
      </label>
      <ErrorMessage errors={errors} />
    </div>
  );
};

Switch.propTypes = {
  value: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

Switch.defaultProps = {
  value: false,
  className: "",
  onChange: () => {},
};

export { Switch };