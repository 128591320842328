import SocialButtons from "pages/LandingPage/Desktop/socialbuttons";
import PropTypes from "prop-types";
import "./WebsiteFooter.css";

const WebsiteFooter = () => {
  return (

    <div className="website-footer_all mt-[120px]">
      <div
        className="content-container12_all"
        data-scroll-to="contentContainer_all"
      >
        <div className="all-rights-reserved-parent_all">
          <div className="all-rights-reserved_all">
            <div className="all-rights-reserved1_all">
              2024. All rights reserved.
            </div>
          </div>
          <div className="products-parent_all">
            <div className="products1_all">
              <div className="heading-67_all" >Products</div>
             <a href="/book-demo" className="no-underline text-[#454545]">
               <div className="item-link3_all mt-[8px] "  >Book Demo</div>
              </a>
            </div>
            <div className="heading-6-products-parent_all">
              <div className="heading-68_all" >Company</div>
              <a href="/about-us">
                <div className="item-link7_all mt-[8px]">About</div>
              </a>
              <a href="/policies">
                <div className="item-link7_all">Policy</div>
              </a>
              <a href="/contact-us"
                className="no-underline text-[#454545]"
              >
                <div className="item-link7_all">Contact Us</div>
              </a>
              <a href="/careers">
                <div className="item-link7_all">Careers</div>
              </a>
              <a href="/terms-of-use">
                <div className="item-link7_all">Terms Of Use</div>
              </a>
            </div>
          </div>
          <div className="follow-us_all">
            <div className="follow_all mqmob1:hidden">Follow:</div>
            <div className="list8_all">
              <SocialButtons />
            </div>
          </div>
          <div className="logo-container-parent_all">
            <div className="cursor-pointer logo-container_all">
              <img
                className="recruiter-robo-logo-3-2-icon_all"
                alt=""
                src="/recroid.png"
              />
            </div>
            <div className="website-info-content_all">
              <div className="recruiter-robo-is-container_all">
                <p className="is-a-dedicated_all">
                  Recroid is a dedicated platform for all
                  recruitment needs of Recruiters as well as job
                  seekers. It uses state of the art AI Models, various
                  Integrations & RPA to fulfill the requirements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="green-ball-image-container1_all">
        <img className="ball3svg-icon_all" alt="" src="/ball3svg3@2x.png" />
      </div> */}
    </div>

  );
};

WebsiteFooter.propTypes = {
  className: PropTypes.string,
};

export default WebsiteFooter;
