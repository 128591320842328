const initialState={
    personalData: {},
    isLoading: false,
    error: null
};

const profilePersonalData = (state = initialState, action) => {
    // //console.log(action.payload);
    switch (action.type) {
        case "FETCH_PROFILE_PERSONAL_DATA_REQUEST":
            return {
                ...state,
                isLoading: true,
                error: null
            };
        case "FETCH_PROFILE_PERSONAL_DATA_SUCCESS":
            return {
                ...state,
                personalData: action.payload,
                isLoading: false,
                error: null
            };
        case "FETCH_PROFILE_PERSONAL_DATA_FAILURE":
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        default:
            return state;
    }
}

export default profilePersonalData;