import { SideBar1 } from "pages/JobSeekerDashBoardPages/SideBar1";
import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Button, Img, Line, List, Text } from "components";

import { ProfileHeader } from "pages/JobSeekerDashBoardPages/ProfileHeader";
import getData from "pages/JobSeekerDashBoardPages/CoveLetterDetails/getData";
import { jsPDF } from "jspdf";
import { useSelector } from "react-redux";

const ResumeDowload = () => {
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [current_role, setCurrentRole] = useState("");
  const [email_address, setEmailAddress] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [countries, setSelectedCountry] = useState("India");
  const [state, setSelectedState] = useState("");
  const [formError, setFormError] = useState(false);
  const [dataFetch, setDataFetch] = useState(false);

  const [awards, setAwards] = useState([]);
  const [courses, setCourses] = useState([]);
  const [projects, setProjects] = useState([]);
  const [publications, setPublications] = useState([]);
  const [workExperience, setWorkExperience] = useState([]);
  const [education, setEducation] = useState([]);
  const [technicalSkills, setTechnicalSkills] = useState([]);
  const [interests, setInterests] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [declarations, setDeclarations] = useState([]);
  const [references, setReferences] = useState([]);
  const [languages, setLanguages] = useState([]);
  const { personalData, isLoading } = useSelector(
    (state) => state.profilePersonalData
  );
  const userData = JSON.parse(
    localStorage.getItem(
      `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`,
    ),
  );
  const user_id = userData.user.id;

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const apiUrl = `${process.env.REACT_APP_API_URL}/get_personal_data`;
  //       const requestOptions = {
  //         method: "POST",
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ user_id: user_id }),
  //       };

  //       const userResponse = await fetch(apiUrl, requestOptions);

  //       if (userResponse.ok) {
  //         const userData = await userResponse.json();

  //         const userDetails = userData.data[1][0];
  //         setDataFetch(true);
  //         if (userDetails) {
  //           setFirstName(userDetails.first_name || "");
  //           setLastName(userDetails.last_name || "");
  //           setCurrentRole(userDetails.current_role_ || "");
  //           setEmailAddress(userDetails.email_address || "");
  //           setPhoneNumber(userDetails.phone_number || "");
  //           setSelectedCountry(userDetails.country || "India");
  //           setSelectedState(userDetails.state || "");
  //           setFormError(false);
  //         } else {
  //           // Handle the case where the expected data structure is not found
  //           console.error("Unexpected data structure in API response");
  //           setFormError(true);
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error fetching user data:", error);
  //       // Handle other errors, setFormError(true) or show an error message
  //       setFormError(true);
  //     }
  //   };

  //   fetchData();
  // }, [user_id]);


  useEffect(()=>{
    if(personalData){
      setDataFetch(true);
      setFirstName(personalData.first_name);
      setLastName(personalData.last_name);
      setCurrentRole(personalData.current_role);
      setEmailAddress(personalData.email_address);
      setPhoneNumber(personalData.phone_number);
      setSelectedCountry(personalData.country);
      setSelectedState(personalData.state);
      setFormError(false);
    }else{
      setFormError(true);
    }
  },[personalData])

  useEffect(() => {
    const fetchData = async () => {
      const data = await getData();

      // Updated the way data is accessed due to changes in getData.js output structure
      setAwards(data.awards || []);
      setCourses(data.courses || []);
      setProjects(data.projects || []);
      setPublications(data.publications || []);
      setWorkExperience(data.work_experience || []);
      setEducation(data.education || []);
      setTechnicalSkills(data.technical_skills || []);
      setCertificates(data.certificates || []);
      setDeclarations(data.declarations || []);
      setReferences(data.references || []);
      setInterests(data.interests || []);
      setLanguages(data.interpersonal_skills || []); // Assuming you want languages from interpersonal_skills
    };

    fetchData();
  }, []);

  const sample_data = {
    name: first_name + " " + last_name,
    job_title: current_role,
    email: email_address,
    link: "www.google.com",
    phone: phone_number,
    personal_profile: "A brief personal profile goes here.",
    address: state + ", " + countries,
    key_skills: technicalSkills,
    awards: awards,
    declarations: declarations,
    references: references,
    publications: publications,
    certificates: certificates,
    interest: interests,
    courses: courses,
    project: projects,
    language: languages,
    work_experience: workExperience,
    education: education,
    organizations: workExperience,
  };

  const handleDownload = async () => {
    try {
      //var doc = new jsPDF();
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: [8, 11],
      });

      var elementHTML = document.querySelector("#content");

      // Change the color of all content inside the element
      elementHTML.style.color = "black";

      await doc.html(elementHTML, {
        callback: function (doc) {
          // Save the PDF
          doc.save("sample-document.pdf");
        },
        x: 0,
        y: 0,
        width: 8, //target width in the PDF document
        windowWidth: 600, //window width in CSS pixels
      });
      // doc.save('save.pdf');
    } catch (e) {
      //console.log(e);
    }
  };

  return (
    <>
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <SideBar1 clickedTab={"Resume Builder"} />
          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
            <ProfileHeader clickedTab={"Resume Builder"} />

            <div className="bg-white-A700 flex flex-col items-center justify-start rounded-[15px] w-[94%] md:w-full">
              <div className="flex flex-col h-[100px] md:h-auto items-center justify-start max-w-[1078px] pl-[77px] pr-[82px] md:px-10 sm:px-5 py-[30px] w-full">
                <div className="flex md:flex-col flex-row md:gap-10 gap-[70px] items-start sm:items-center sm:justify-center justify-start w-auto md:w-full">
                  <div className="flex flex-row gap-5 items-center justify-start w-1/4 md:w-full sm:items-center sm:justify-center">
                    <Text
                      size="txtInterSemiBold25"
                      className="sm:text-[12px] bg2 flex h-[50px] sm:h-[25px]  sm:w-[25px] items-center justify-center rounded-[50%] md:text-[23px] text-[25px] text-center text-white-A700 tracking-[0.50px] w-[50px]"
                    >
                      3
                    </Text>

                    <Text
                      className="text2 text-blue-A200 text-xl tracking-[0.50px] sm:text-[12px]"
                      size="txtInterMedium20"
                    >
                      Download your Resume
                    </Text>
                  </div>
                </div>
              </div>
              <div className="flex md:flex-col flex-row font-poppins md:gap-[49px] items-start justify-between mt-[17px] w-[90%]">
                <div
                  id="content"
                  className="bg-white-A700 border-2 border-blue_gray-100 border-solid flex flex-col gap-26 items-center justify-start p-29 sm:px-5 rounded-15 shadow-md"
                >
                  <div className="flex flex-col items-center justify-start w-[56%] md:w-full">
                    <div className="flex flex-col items-center justify-start w-full pt-[50px] ">
                      <div className="flex flex-col items-center justify-start">
                        <Text
                          className="text-2xl md:text-[22px] text-black-900 sm:text-xl"
                          size="txtPoppinsSemiBold24"
                        >
                          {first_name + " " + last_name}
                        </Text>
                      </div>
                      <div className="flex flex-col items-center justify-start">
                        <Text
                          className="text-black-900_7f text-lg"
                          size="txtPoppinsMedium18"
                        >
                          {current_role}
                        </Text>
                      </div>
                      <div className="flex sm:flex-col flex-row gap-5 items-center justify-between mt-[3px] w-full">
                        <div className="flex sm:flex-1 sm:flex-col flex-row gap-5 items-center justify-between w-[73%] sm:w-full">
                          <div className="flex flex-row gap-2 items-center justify-start pt-0.5 w-[62%] sm:w-full">
                            <Img
                              className="h-4"
                              src="images/img_mail.svg"
                              alt="mail"
                            />
                            <Text
                              className="text-black-900 text-lg"
                              size="txtPoppinsRegular18"
                            >
                              {email_address}
                            </Text>
                          </div>
                          <div className="flex flex-row gap-2 items-center justify-start w-[34%] sm:w-full">
                            <Img
                              className="h-5 w-5"
                              src="images/img_call.svg"
                              alt="call"
                            />
                            <Text
                              className="text-black-900 text-lg"
                              size="txtPoppinsRegular18"
                            >
                              {phone_number}
                            </Text>
                          </div>
                        </div>
                        <div className="flex sm:flex-1 flex-row gap-1.5 items-start justify-start w-[24%] sm:w-full">
                          <Img
                            className="h-[23px] mt-1 w-[23px]"
                            src="images/img_ionlocationoutline.svg"
                            alt="ionlocationoutl"
                          />
                          <Text
                            className="text-black-900 text-lg"
                            size="txtPoppinsRegular18"
                          >
                            {state + ", " + countries}
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-start mb-[130px] w-[79%] md:w-full">
                    <div className="flex flex-col items-start justify-start w-full">
                      <Text
                        className="text-black-900 text-xl"
                        size="txtPoppinsMedium20"
                      >
                        Resume Objective
                      </Text>
                      <Line className="bg-black-900 h-0.5 mt-[7px] w-full" />
                      <Text
                        className="text-[15px] text-black-900 w-full"
                        size="txtInterRegular15"
                      >
                        Seeking an entry-level position to begin my career in a
                        high-level professional environment. Secure a
                        responsible career opportunity to fully utilize my
                        training and skills, while making a significant
                        contribution to the success of the company. A highly
                        organized and hard-working individual looking for a
                        responsible position to gain practical experience. To
                        make use of my interpersonal skills to achieve goals of
                        a company that focuses on customer satisfaction and
                        customer experience.
                      </Text>
                    </div>
                    <div className="flex flex-col items-center justify-start mt-[41px] w-full">
                      <div className="flex flex-col items-start justify-start w-full">
                        <div className="font-poppins h-[30px] ml-0.5 md:ml-[0] relative w-full">
                          <Text
                            className="absolute h-full inset-y-[0] left-[0] my-auto text-black-900 text-xl"
                            size="txtPoppinsMedium20"
                          >
                            Work Experience
                          </Text>
                          <Line className="absolute bg-black-900 bottom-[0] h-0.5 inset-x-[0] mx-auto w-full" />
                        </div>
                        <div className="flex flex-row font-inter sm:gap-10 items-start justify-between mt-1 w-full">
                          <Text
                            className="text-[15px] text-black-900"
                            size="txtInterMedium15"
                          >
                            Software Developer
                          </Text>
                          <Text
                            className="text-[15px] text-black-900"
                            size="txtInterLight15"
                          >
                            Oct 2020 - Dec 2022
                          </Text>
                        </div>
                        <Text
                          className="ml-0.5 md:ml-[0] mt-1 text-[15px] text-black-900"
                          size="txtInterRegular15"
                        >
                          Amazon Full-time
                        </Text>
                        <div className="flex flex-row font-inter sm:gap-10 items-start justify-between mt-1.5 w-full">
                          <Text
                            className="text-[15px] text-black-900"
                            size="txtInterMedium15"
                          >
                            Software Developer
                          </Text>
                          <Text
                            className="text-[15px] text-black-900"
                            size="txtInterLight15"
                          >
                            Oct 2020 - Dec 2022
                          </Text>
                        </div>
                        <Text
                          className="mt-1.5 text-[15px] text-black-900"
                          size="txtInterRegular15"
                        >
                          Apple Full-time
                        </Text>
                      </div>
                    </div>
                    <div className="flex flex-col items-start justify-start mt-5 w-full">
                      <div className="font-poppins h-[30px] ml-0.5 md:ml-[0] relative w-full">
                        <Text
                          className="absolute h-full inset-y-[0] left-[0] my-auto text-black-900 text-xl"
                          size="txtPoppinsMedium20"
                        >
                          Education and qualification
                        </Text>
                        <Line className="absolute bg-black-900 bottom-[0] h-0.5 inset-x-[0] mx-auto w-full" />
                      </div>
                      <Text
                        className="mt-[3px] text-[15px] text-black-900"
                        size="txtInterMedium15"
                      >
                        BITS Pilani{" "}
                      </Text>
                      <Text
                        className="ml-0.5 md:ml-[0] mt-[7px] text-[15px] text-black-900"
                        size="txtInterRegular15"
                      >
                        Doctor of Philosophy{" "}
                      </Text>
                      <Text
                        className="mt-1 text-[15px] text-black-900"
                        size="txtInterMedium15"
                      >
                        BIT MERSA
                      </Text>
                      <Text
                        className="mt-[7px] text-[15px] text-black-900"
                        size="txtInterRegular15"
                      >
                        Masters Degree
                      </Text>
                    </div>
                    <List
                      className="flex flex-col gap-5 items-center mt-[19px] w-full"
                      orientation="vertical"
                    >
                      <div className="flex flex-1 flex-col items-start justify-start w-full">
                        <Text
                          className="text-black-900 text-xl"
                          size="txtPoppinsMedium20"
                        >
                          Skills
                        </Text>
                        <Line className="bg-black-900 h-0.5 ml-0.5 md:ml-[0] w-full" />
                        <Text
                          className="mt-[9px] text-[15px] text-black-900"
                          size="txtInterMedium15"
                        >
                          Curriculum design
                        </Text>
                        <Text
                          className="mt-4 text-[15px] text-black-900"
                          size="txtInterMedium15"
                        >
                          E-learning
                        </Text>
                      </div>
                      <div className="flex flex-1 flex-col items-start justify-start w-full">
                        <Text
                          className="text-black-900 text-xl"
                          size="txtPoppinsMedium20"
                        >
                          Interests
                        </Text>
                        <Line className="bg-black-900 h-0.5 ml-0.5 md:ml-[0] w-full" />
                        <Text
                          className="mt-[9px] text-[15px] text-black-900"
                          size="txtInterMedium15"
                        >
                          Coding
                        </Text>
                        <Text
                          className="mt-[17px] text-[15px] text-black-900"
                          size="txtInterMedium15"
                        >
                          Football
                        </Text>
                      </div>
                      <div className="flex flex-1 flex-col items-start justify-start w-full">
                        <Text
                          className="text-black-900 text-xl"
                          size="txtPoppinsMedium20"
                        >
                          Language
                        </Text>
                        <Line className="bg-black-900 h-0.5 ml-0.5 md:ml-[0] w-full" />
                        <Text
                          className="mt-[9px] text-[15px] text-black-900"
                          size="txtInterMedium15"
                        >
                          English
                        </Text>
                        <Text
                          className="mt-[17px] text-[15px] text-black-900"
                          size="txtInterMedium15"
                        >
                          Hindi
                        </Text>
                      </div>
                      <div className="flex flex-1 flex-col items-start justify-start w-full">
                        <Text
                          className="text-black-900 text-xl"
                          size="txtPoppinsMedium20"
                        >
                          Certificate
                        </Text>
                        <Line className="bg-black-900 h-0.5 ml-0.5 md:ml-[0] w-full" />
                        <Text
                          className="mt-[9px] text-[15px] text-black-900"
                          size="txtInterMedium15"
                        >
                          Python for beginners
                        </Text>
                        <Text
                          className="mt-[17px] text-[15px] text-black-900"
                          size="txtInterMedium15"
                        >
                          Machine learning beginner to advance
                        </Text>
                      </div>
                    </List>
                  </div>
                </div>
                <Line className="bg-blue-A200 h-[523px] md:h-[5px] hidden mb-[623px] md:mt-0 mt-[73px] rotate-[180deg] rounded-sm w-[5px] md:w-full" />
              </div>

              <div className="flex md:flex-row flex-row font-inter md:gap-10 gap-[190px] items-start justify-between mt-[82px] w-[90%] mb-5">
                <Link to="/resume-details">
                  <div className=" sm:h-[35px] sm:w-[120px] bg-indigo-600 flex md:flex-1 flex-row gap-3.5 items-center justify-center p-[8px] rounded-[15px] text-left w-[200px] md:w-full">
                    <Img
                      className="h-4 sm:h-[15px] sm:w-[15px]"
                      src="images/img_line3.svg"
                      alt="lineThree"
                    />

                    <Text
                      className="my-1 text-center text-lg text-white-A700 sm:text-[12px]"
                      size="txtInterMedium18WhiteA700"
                    >
                      Previous
                    </Text>
                  </div>
                </Link>
                {/* <Link to="/resumebuilder">
                  <div className="bg-indigo-600 flex md:flex-1 flex-row items-center justify-center p-[10px] rounded-[15px] w-[230px] md:w-full">
                    <Text
                      className="mb-0.5 text-center text-lg text-white-A700"
                      size="txtInterMedium18WhiteA700"
                    >
                      Change Template
                    </Text>

                    <Img
                      className="pl-2"
                      src="images\img_materialsymbolsborderall2 copy.svg"
                      alt="change template"
                    />
                  </div>
                </Link> */}
                <Button
                  className="sm:h-[35px] sm:w-[120px] !p-[12px] bg-indigo-600 cursor-pointer flex items-center justify-center  rounded-[10px] w-[200px]"
                  rightIcon={
                    <Img
                      className="mt-px pl-3 "
                      src="images/img_octicondownload16.svg"
                      alt="octicon:download-16"
                    />
                  }
                  color="indigo_600"
                  size="sm"
                >
                  <div
                    onClick={handleDownload}
                    className="flex-row font-medium leading-[normal] text-center text-lg hidden md:flex sm:text-[12px]"
                  >
                    Download
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ResumeDowload;
