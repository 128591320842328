import React, { Component } from 'react';

class ToggleButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggledOn: false,
    };
  }

  handleToggle = () => {
    this.setState((prevState) => ({
      isToggledOn: !prevState.isToggledOn,
    }));
  };

  render() {

    return (
      <div>
        <button className="toggle-button" onClick={this.handleToggle}>
          {this.state.isToggledOn?<img alt='' src="yearly-icon-111.svg" />:<img alt='' src="monthly-icon-111.svg"/> }
        </button>
      </div>
    );
  }
}

export default ToggleButton;
