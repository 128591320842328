import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";

export default function AlertModal(props) {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: isSmallScreen ? "80%" : 487,
    // height: isSmallScreen ? "20%" : 280,
    border: "1px solid #000",
    boxShadow: 24,
    p: isSmallScreen ? 3 : 6,
    // backgroundColor:
    //   props.msg === "error" || props.msg == "profile-error"
    //     ? "#F8D7D7"
    //     : props.msg === "loading"
    //     ? "#424242"
    //     : props.msg === "success"
    //     ? "#3ABA6F"
    //     : "#3ABA6F",
    backgroundColor:
    props.msg ==="error" || props.msg == "profile-error"
      ? "#F8D7D7"
      : props.msg ==="loading"
      ? "#424242"
      : props.msg ==="success"
      ? "#3ABA6F"
      : null,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "15px",
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className="w-[31rem] h-[17.5rem] mqlap:w-[27.5vw] mqlap:h-[15.7vw] mqtab1:w-[27.7vw] mqtab1:h-[15.7vw] flex justify-center"
        >
          {props.msg === "error" && (
            <div className="h-[44px] w-[44px] mqtab1:w-[2.5vw] mqtab1:h-[2.5vw] mqlap:h-[2.5vw] mqlap:w-[2.5vw]">
            <ErrorOutlineIcon
               sx={{ height: "44px", width: "44px", color: "#AA2A2A" }}
            />
            </div>
          )}
          {props.msg === "profile-error" && (
            <div className="h-[44px] w-[44px] mqtab1:w-[2.5vw] mqtab1:h-[2.5vw] mqlap:h-[2.5vw] mqlap:w-[2.5vw]">
            <ErrorOutlineIcon
              sx={{ height: "100%", width: "100%", color: "#AA2A2A" }}
            />
            </div>
          )}
          {props.msg === "loading" && (
            <div className="h-[44px] w-[44px] mqtab1:w-[2.5vw] mqtab1:h-[2.5vw] mqlap:h-[2.5vw] mqlap:w-[2.5vw]">
            <CircularProgress 
            sx={{ color: "white" }} />
            </div>
          )}
          {props.msg === "success" && (
            <div className="h-[44px] w-[44px] mqtab1:w-[2.5vw] mqtab1:h-[2.5vw] mqlap:h-[2.5vw] mqlap:w-[2.5vw]">
            <CheckCircleOutlineIcon
              sx={{ height: "100%", width: "100%", color: "white" }}
            />
          </div>
          )}
          {props.msg !== "error" &&
            props.msg !== "profile-error" &&
            props.msg !== "loading" &&
            props.msg !== "success" && (
              <div className="h-[44px] w-[44px] mqtab1:w-[2.5vw] mqtab1:h-[2.5vw] mqlap:h-[2.5vw] mqlap:w-[2.5vw]">
                <CheckCircleOutlineIcon
                  sx={{ height: "100%", width: "100%", color: "white" }}
                />
              </div>
            )}

          <Typography
            id="modal-modal-description"
            sx={{
              mt: isSmallScreen ? 1 : 2,
              // fontSize: isSmallScreen ? "14px" : "32px",
              textAlign: "center",
              color:
                props.msg === "error" || props.msg == "profile-error"
                  ? "#AA2A2A"
                  : props.msg === "loading"
                  ? "#FFFFFF"
                  : props.msg === "success"
                  ? "white"
                  : "white",
            }}
          >
            <p className="text-[33px] mqlap:text-[1.85vw] mqtab1:text-[1.85vw] leading-[1.3] tracking-[0.00938em]">
              {props.msg === "error" &&
                "The email or password entered is incorrect. Please try again."}
              {props.msg === "loading" &&
                "Please wait while your data is being fetched"}
              {props.msg === "success" &&
                `Your data has been saved successfully`}
              {props.msg === "profile-error" &&
                "Please fill all the required fields"}
              {props.msg &&
                !["error", "loading", "success", "profile-error"].includes(
                  props.msg
                ) &&
                props.msg}
            </p>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
