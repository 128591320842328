import React, { useState } from "react";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Pagination
} from "@mui/material";
import { Text, Img } from "components";

const AdviceTable = ({ careerData, handleDownload }) => {
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedRows = careerData.slice(startIndex, endIndex);

    const downloadAdvice = (record) => {
        handleDownload(record);
    };

    if (displayedRows.length === 0) {
        return (
          <div className="overflow-auto mb-[30px] w-full flex justify-center items-center">
            <Text   className="sm:ml-[0] sm:text-[12px] sm:mt-0 my-0.5 text-base text-blue_gray-400"
                      size="txtInterSemiBold16">No career advice data available</Text>
          </div>
        );
      }

    return (
        <div className="mb-[30px] w-full">
            <TableContainer component={Paper} className="w-full">
                <Table>
                    <TableHead>
                        <TableRow>
                            {["S. No.", "Role", "Goal", "Country", "Advice Type", "Date", "Download report"].map((header, index) => (
                                <TableCell key={index}>
                                    <Text
                                        className="sm:ml-[0] sm:mt-0 my-0.5 sm:text-[12px] text-base text-blue_gray-400 mqlap:text-[0.9vw] mqtab1:text-[0.95vw]"
                                        size="txtInterSemiBold16"
                                    >
                                        {header}
                                    </Text>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedRows.map((row, index) => (
                            <TableRow
                                className="sm:text-[10px]"
                                key={index + 1 + (currentPage - 1) * itemsPerPage}
                            >
                                <TableCell>
                                    <Text className="mqlap:text-[0.8vw] mqtab1:text-[0.85vw]">
                                        {index + 1 + (currentPage - 1) * itemsPerPage}
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="mqlap:text-[0.8vw] mqtab1:text-[0.85vw]">
                                        {row.role}
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="mqlap:text-[0.8vw] mqtab1:text-[0.85vw]">
                                        {row.goal}
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="mqlap:text-[0.8vw] mqtab1:text-[0.85vw]">
                                        {row.country}
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="mqlap:text-[0.8vw] mqtab1:text-[0.85vw]">
                                        {row.advice_type}
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text className="mqlap:text-[0.8vw] mqtab1:text-[0.85vw]">
                                        {row.date}
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Img
                                        className="mt-px pl-12 mqlap:h-[1.2rem] mqtab1:h-[1.1rem]"
                                        src="images/img_octicondownload162.svg"
                                        alt="Download icon"
                                        onClick={() => downloadAdvice(row.record)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="flex flex-row justify-center mt-[50px]">
                <Pagination
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    page={currentPage}
                    count={Math.ceil(careerData.length / itemsPerPage)}
                    onChange={handlePageChange}
                />
            </div>
        </div>
    );
};

export default AdviceTable;
