import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
  Typography
} from "@mui/material";
import { Button, Text } from "components";
const LogoutPopup = ({ goTo, isOpen, onClose, onConfirmLogout, mainHeadText, subText, hoverText, cancelButton, yesButton }) => {
  return (
    <Dialog
      maxWidth="2xl"
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
    >
      <div className="w-[31rem] mqlap:w-[27.5vw] mqtab1:w-[29.5vw]">
      <div>
        <div className="flex flex-row items-center h-[64px] mqlap:h-[3.52vw] mqtab1:h-[3.75vw] mqtab2:h-[45px]">
          <Text
            className="pl-[3.5%] mt-0.5 text-black-900 text-xl sm:text-[12px] mqlap:text-[1.06vw] mqtab1:text-[1.12vw] mqtab2:text-[1.4vw]"
            size="txtInterSemiBold18"
          >
            {mainHeadText}
          </Text>
          <Tooltip
            title={
              <Typography>
                <p className="text-[16px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] mqtab2:text-[1.02vw]">
                {hoverText}
                </p>
              </Typography>
            }
            placement="right"
            arrow
            className="relative flex flex-col items-center justify-end gap-2 rounded-[15px] transition-colors hover:bg-gray-100 hover:text-[40px]"
          >
            <img src="images/infoIcon.svg" alt="infoIcon" className="ml-[1.5px]  mt-[2.2px] mqlap:mt-[2.5px] mqtab1:mt-[2.7px] w-[1.3rem] h-[1.13rem] mqlap:w-[1.15vw] mqlap:h-[1.02vw] mqtab1:w-[1.09vw] mqtab1:h-[1.02vw] mqtab2:w-[1.3vw] mqtab2:h-[1.3rem]" />
          </Tooltip>
        </div>
        <hr
          style={{ height: "1px", backgroundColor: "grey", border: "none", width: "100%" }}
        />
      </div>
      <DialogContent className="mqtab1:py-[18px] mqlap:py-[18px]">
        <DialogContentText id="alert-dialog-description" >
        <Text  className="text-[17px] mqlap:text-[0.93vw] mqtab1:text-[0.96vw] mqtab2:text-[1.02vw]"> {subText}</Text>
          
        </DialogContentText>
      </DialogContent>
      <DialogActions className="mb-[8px]">
        <button
          style={{ borderColor: "blue", color: "blue" }}
          className="flex justify-center items-center mqtab1:border-[0.1vw]  mqlap:border-[0.1vw] border-[1.9px] border-solid h-[1.95rem] w-[5.45rem] text-[15.2px] rounded-[11px] mqlap:rounded-[10px] mqlap:h-[1.82vw] mqlap:w-[5.1vw] mqlap:text-[0.88vw] mqtab1:rounded-[9px] mqtab1:text-[0.93vw] mqtab1:h-[1.9vw] mqtab1:w-[5.7vw]"
          onClick={onClose}
        >
          {cancelButton} 
        </button>
        <Button
          onClick={onConfirmLogout}
          className="flex justify-center items-center h-[1.95rem] w-[5.45rem] text-[15px] rounded-[9.9px] mqlap:rounded-[9.5px] mqlap:h-[1.82vw] mqlap:w-[5.1vw] mqlap:text-[0.85vw] mqtab1:rounded-[9px] mqtab1:text-[0.9vw]  mqtab1:h-[1.97vw] mqtab1:w-[5.7vw]"
          autoFocus
        >
          {yesButton}
        </Button>
      </DialogActions>
      </div>
    </Dialog>
  );
};

export default LogoutPopup;
