async function getUserData(user_id) {
  const apiUrl = `${process.env.REACT_APP_API_URL}/get_user_data_for_coverletter`;
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user_id }),
    });
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const data = await response.json();
    return data.data || {};
  } catch (error) {
    console.error("Error fetching user data:", error);
    return {};
  }
}

async function getLinks(user_id) {
  const url = `${process.env.REACT_APP_API_URL}/read_links`;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user_id }),
    });
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const data = await response.json();
    return data.data?.data?.[0]?.links || [];
  } catch (error) {
    console.error("Error fetching user data:", error);
    return [];
  }
}

function handleListData(data, key) {
  if (!data[key] || !data[key][0] || !data[key][0][key]) return [];
  return data[key][0][key].flatMap((item) => (item ? item.split(",") : []));
}

function handleProjectsData(projectsData) {
  if (
    !projectsData ||
    projectsData.length === 0 ||
    !projectsData[0]?.project_name
  )
    return [];

  const allProjects = [];
  for (let i = 0; i < projectsData[0].project_name.length; i++) {
    allProjects.push({
      projectName: projectsData[0].project_name[i],
      projectDescription: projectsData[0].description[i],
      projectLink: projectsData[0].reference[i],
      technologiesUsed: projectsData[0].skills_technology[i],
    });
  }
  return allProjects;
}

function handleWorkExperienceData(workExperienceData) {
  if (
    !workExperienceData ||
    workExperienceData.length === 0 ||
    !workExperienceData[0]?.org_company_name
  )
    return [];

  const allWorkExperience = [];
  for (let i = 0; i < workExperienceData[0].org_company_name.length; i++) {
    allWorkExperience.push({
      company: workExperienceData[0].org_company_name[i],
      position: workExperienceData[0].role[i],
      description: workExperienceData[0].description[i],
      startDate: workExperienceData[0].start_date[i],
      endDate: workExperienceData[0].end_date[i],
    });
  }
  return allWorkExperience;
}

function handleEducationData(educationData) {
  if (
    !educationData ||
    educationData.length === 0 ||
    !educationData[0]?.college_uni_name
  )
    return [];

  const allEducation = [];
  for (let i = 0; i < educationData[0].college_uni_name.length; i++) {
    allEducation.push({
      institution: educationData[0].college_uni_name[i],
      degree: educationData[0].degree[i],
      percentage: educationData[0].percent[i],
    });
  }
  return allEducation;
}

function handleReferencesData(data) {
  if (!data.references || !data.references[0] || !data.references[0].reference)
    return [];
  return data.references[0].reference.flatMap((item) =>
    item ? item.split(",") : [],
  );
}

function handleDeclarationsData(data) {
  if (
    !data.declarations ||
    !data.declarations[0] ||
    !data.declarations[0].declaration
  )
    return [];
  return data.declarations[0].declaration.flatMap((item) =>
    item ? item.split(",") : [],
  );
}

async function getData() {
  const userData = JSON.parse(
    localStorage.getItem(
      `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`,
    ),
  );
  if (!userData || !userData.user) {
    console.error("User data not found in local storage.");
    return {};
  }

  const user_id = userData.user.id;
  const data = await getUserData(user_id);
  const links = await getLinks(user_id);

  // Consolidated data handling
  const output = {
    awards: handleListData(data, "awards"),
    courses: handleListData(data, "courses"),
    projects: handleProjectsData(data.projects || []),
    publications: handleListData(data, "publications"),
    work_experience: handleWorkExperienceData(data.work_experience || []),
    education: handleEducationData(data.education || []),
    technical_skills: handleListData(data, "technical_skills"),
    certificates: handleListData(data, "certificates"),
    references: handleReferencesData(data),
    interests: handleListData(data, "interests"),
    declarations: handleDeclarationsData(data),
    languages: handleListData(data, "languages"),
    interpersonal_skills: handleListData(data, "interpersonal_skills"),
    links: links,
    resumeObjective: data.resume_objective || "",
  };

  return output;
}

export default getData;
