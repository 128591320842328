
import { Img, Input, Line, Text } from "components";
import TopHeader from "pages/RecruiterDashBoardPages/TopHeader";
import Sidebar1 from "pages/UniversityDashBoardPages/UniversitySidebar";

const UniversityCommunication = () => {
  return (
    <>
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <Sidebar1 clickedTab={"Communication"} />

          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
            <TopHeader header={"Communication"} />


            <div className="bg-white-A700 flex flex-col items-center justify-start p-7 sm:px-5 rounded-[15px] w-[94%] md:w-full">
              <div className=" font-poppins md:h-[1103px] h-[1165px] relative w-[94%] md:w-full">




                {/* <div className="flex flex-1 flex-col font-inter gap-[50px] items-center justify-start md:px-5 w-full">
            
            <div className="bg-white-A700 flex flex-col items-center justify-start p-[34px] sm:px-5 rounded-[15px] w-[95%] md:w-full"> */}


                <div className="flex md:flex-col flex-row gap-4 items-start justify-between w-full">
                  <div className="flex md:flex-1 flex-col gap-9 items-center justify-start md:mt-0 mt-7 w-[29%] md:w-full">
                    <Input
                      name="searchbar"
                      placeholder="Search..."
                      className="font-medium p-0 placeholder:text-indigo-600 sm:px-5 text-[19px] text-indigo-600 text-left tracking-[-0.21px] w-full"
                      wrapClassName="border border-gray-400_7f border-solid flex px-[25px] py-[7px] rounded-[14px] w-full"
                      prefix={
                        <Img
                          className="mt-auto mb-1.5 h-[38px] mr-[35px]"
                          src="images/img_rewind.svg"
                          alt="rewind"
                        />
                      }
                      suffix={
                        <Img
                          className="mt-0.5 mb-auto h-[42px] ml-[35px]"
                          src="images/img_carbonaddfilled.svg"
                          alt="carbon:add-filled"
                        />
                      }
                    ></Input>
                    <div className="bg-gray-100 border border-gray-400_7f border-solid flex flex-col items-start justify-start p-[21px] sm:px-5 rounded-[15px] w-full">
                      <div className="flex flex-row gap-[18px] items-center justify-start md:ml-[0] ml-[5px] w-[93%] md:w-full">
                        <div className="flex flex-col h-[74px] items-center justify-start w-[74px]">
                          <Img
                            className="h-[74px] md:h-auto rounded-[50%] w-[74px]"
                            src="images/img_ellipse3.png"
                            alt="ellipseThree_One"
                          />
                        </div>
                        <div className="flex flex-col gap-3.5 items-start justify-start w-3/4">
                          <div className="flex flex-row items-center justify-between w-full">
                            <Text
                              className="text-base text-blue_gray-400"
                              size="txtInterBold16"
                            >
                              EC Batch 2023
                            </Text>
                            <Text
                              className="text-base text-blue_gray-400"
                              size="txtInterRegular16"
                            >
                              11:44PM
                            </Text>
                          </div>
                          <Text
                            className="text-gray-800 text-xs"
                            size="txtInterLight12"
                          >
                            Lorem ipsum dolor sit amet consectetur ...
                          </Text>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white-A700 border border-gray-400_7f border-solid flex flex-col items-start justify-start p-[21px] sm:px-5 rounded-[15px] w-full">
                      <div className="flex flex-row gap-[18px] items-center justify-start md:ml-[0] ml-[5px] w-[93%] md:w-full">
                        <div className="flex flex-col h-[74px] items-center justify-start w-[74px]">
                          <Img
                            className="h-[74px] md:h-auto rounded-[50%] w-[74px]"
                            src="images/img_ellipse3.png"
                            alt="ellipseThree_Two"
                          />
                        </div>
                        <div className="flex flex-col gap-3.5 items-start justify-start w-3/4">
                          <div className="flex flex-row items-center justify-between w-full">
                            <Text
                              className="text-base text-blue_gray-400"
                              size="txtInterBold16"
                            >
                              EC Batch 2023
                            </Text>
                            <Text
                              className="text-base text-blue_gray-400"
                              size="txtInterRegular16"
                            >
                              11:44PM
                            </Text>
                          </div>
                          <Text
                            className="text-gray-800 text-xs"
                            size="txtInterLight12"
                          >
                            Lorem ipsum dolor sit amet consectetur ...
                          </Text>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white-A700 border border-gray-400_7f border-solid flex flex-col items-start justify-start p-[21px] sm:px-5 rounded-[15px] w-full">
                      <div className="flex flex-row gap-[18px] items-center justify-start md:ml-[0] ml-[5px] w-[93%] md:w-full">
                        <div className="flex flex-col h-[74px] items-center justify-start w-[74px]">
                          <Img
                            className="h-[74px] md:h-auto rounded-[50%] w-[74px]"
                            src="images/img_ellipse3.png"
                            alt="ellipseThree_Three"
                          />
                        </div>
                        <div className="flex flex-col gap-3.5 items-start justify-start w-3/4">
                          <div className="flex flex-row items-center justify-between w-full">
                            <Text
                              className="text-base text-blue_gray-400"
                              size="txtInterBold16"
                            >
                              EC Batch 2023
                            </Text>
                            <Text
                              className="text-base text-blue_gray-400"
                              size="txtInterRegular16"
                            >
                              11:44PM
                            </Text>
                          </div>
                          <Text
                            className="text-gray-800 text-xs"
                            size="txtInterLight12"
                          >
                            Lorem ipsum dolor sit amet consectetur ...
                          </Text>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white-A700 border border-gray-400_7f border-solid flex flex-col items-start justify-start p-[21px] sm:px-5 rounded-[15px] w-full">
                      <div className="flex flex-row gap-[18px] items-center justify-start md:ml-[0] ml-[5px] w-[93%] md:w-full">
                        <div className="flex flex-col h-[74px] items-center justify-start w-[74px]">
                          <Img
                            className="h-[74px] md:h-auto rounded-[50%] w-[74px]"
                            src="images/img_ellipse3.png"
                            alt="ellipseThree_Four"
                          />
                        </div>
                        <div className="flex flex-col gap-3.5 items-start justify-start w-3/4">
                          <div className="flex flex-row items-center justify-between w-full">
                            <Text
                              className="text-base text-blue_gray-400"
                              size="txtInterBold16"
                            >
                              EC Batch 2023
                            </Text>
                            <Text
                              className="text-base text-blue_gray-400"
                              size="txtInterRegular16"
                            >
                              11:44PM
                            </Text>
                          </div>
                          <Text
                            className="text-gray-800 text-xs"
                            size="txtInterLight12"
                          >
                            Lorem ipsum dolor sit amet consectetur ...
                          </Text>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white-A700 border border-gray-400_7f border-solid flex flex-col items-start justify-start p-[21px] sm:px-5 rounded-[15px] w-full">
                      <div className="flex flex-row gap-[18px] items-center justify-start md:ml-[0] ml-[5px] w-[93%] md:w-full">
                        <div className="flex flex-col h-[74px] items-center justify-start w-[74px]">
                          <Img
                            className="h-[74px] md:h-auto rounded-[50%] w-[74px]"
                            src="images/img_ellipse3.png"
                            alt="ellipseThree_Five"
                          />
                        </div>
                        <div className="flex flex-col gap-3.5 items-start justify-start w-3/4">
                          <div className="flex flex-row items-center justify-between w-full">
                            <Text
                              className="text-base text-blue_gray-400"
                              size="txtInterBold16"
                            >
                              EC Batch 2023
                            </Text>
                            <Text
                              className="text-base text-blue_gray-400"
                              size="txtInterRegular16"
                            >
                              11:44PM
                            </Text>
                          </div>
                          <Text
                            className="text-gray-800 text-xs"
                            size="txtInterLight12"
                          >
                            Lorem ipsum dolor sit amet consectetur ...
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border border-gray-400_7f border-solid flex md:flex-1 flex-col items-center justify-start pb-[47px] rounded-[15px] w-[71%] md:w-full">
                    <div className="flex flex-col items-center justify-start w-full">
                      <Img
                        className="h-[74px] rounded-bl-[15px] rounded-br-[15px]"
                        src="images/img_topcontainer.svg"
                        alt="topcontainer"
                      />
                      <div className="flex md:flex-col flex-row md:gap-10 items-start justify-between mt-14 w-[97%] md:w-full">
                        <div className="flex md:flex-col flex-row gap-5 items-center justify-start p-[21px] sm:px-5 rounded-[15px]">
                          <div className="flex flex-col h-[74px] items-center justify-start md:ml-[0] ml-[5px] w-[74px]">
                            <Img
                              className="h-[74px] md:h-auto rounded-[50%] w-[74px]"
                              src="images/img_ellipse3.png"
                              alt="ellipseThree_Six"
                            />
                          </div>
                          <div className="bg-gray-100 flex flex-col items-start justify-start md:mt-0 my-[5px] p-[9px] rounded-[15px]">
                            <Text
                              className="my-2 text-gray-800 text-xs w-[72%] sm:w-full"
                              size="txtInterLight12"
                            >
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Maxime mollitia, molestiae quas vel sint
                              commodi repudiandae{" "}
                            </Text>
                          </div>
                        </div>
                        <Line className="bg-blue-A200 h-[200px] md:h-[5px] md:mt-0 mt-[46px] rotate-[180deg] rounded-sm w-[5px] md:w-full" />
                      </div>
                      <Input
                        name="textbox"
                        placeholder="Get your career advice ..."
                        className="leading-[normal] p-0 placeholder:text-blue_gray-400 sm:pl-5 text-[15px] text-blue_gray-400 text-left w-full"
                        wrapClassName="border border-gray-300 border-solid flex mt-[458px] pl-[21px] pr-[17px] py-3 rounded-[15px] w-[67%]"
                        suffix={
                          <Img
                            className="h-6 ml-[35px] my-auto"
                            src="images/img_save.svg"
                            alt="save"
                          />
                        }
                      ></Input>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

    </>
  );
};

export default UniversityCommunication;
