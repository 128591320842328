import axios from "axios";
import { useSelector } from "react-redux";

export const fetchCareerAdviceData = (user_id) => {
  return async (dispatch) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/get_career_advice_data`;
      const requestData = { user_id };

      const response = await axios.post(apiUrl, requestData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const myData = response.data.data[1];
      myData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      // Dispatch action to update Redux store with fetched data
      dispatch({ type: "FETCH_CAREER_ADVICE_SUCCESS", payload: myData });
    } catch (error) {
      console.error("Error fetching career advice data:", error);
      throw new Error(error.message);
    }
  };
};
