import React, { useState, useEffect } from "react";
import { SideBar1 } from "pages/JobSeekerDashBoardPages/SideBar1";
import { Button, List, Text } from "components";
import BillingSubHeader from "pages/JobSeekerDashBoardPages/BillingSubHeader";
import { ProfileHeader } from "pages/JobSeekerDashBoardPages/ProfileHeader";
import countryStates from "components/CountriesStates/index.jsx";
import { BeatLoader } from "react-spinners";
import { useSelector } from "react-redux";

const BillingPreferences = () => {
  const user_id = useSelector((state) => state.profilePersonalData?.personalData?.id);
  const [formData, setFormData] = useState({
    user_id: "",
    billingCompanyName: "",
    billingEmail: "",
    billingCountry: "",
    billingAddress1: "",
    billingAddress2: "",
    billingPincode: "",
    state: "",
    phoneNumber: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isExisting, setIsExisting] = useState(false); // Track if billing data exists

  // Fetch states list based on selected country
  const statesList = countryStates.find((country) => country.country === formData.billingCountry);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.billingCompanyName) newErrors.billingCompanyName = "Full name is required";
    if (!formData.billingEmail) newErrors.billingEmail = "Email address is required";
    else if (!/\S+@\S+\.\S+/.test(formData.billingEmail)) newErrors.billingEmail = "Invalid email address";
    if (!formData.phoneNumber) newErrors.phoneNumber = "Phone number is required";
    else if (!/^\d{10}$/.test(formData.phoneNumber)) newErrors.phoneNumber = "Invalid phone number";
    if (!formData.billingAddress1) newErrors.billingAddress1 = "Address is required";
    if (!formData.billingCountry) newErrors.billingCountry = "Country is required";
    if (!formData.state) newErrors.state = "State is required";
    if (!formData.billingPincode) newErrors.billingPincode = "Pincode is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    const fetchBillingPreferences = async () => {
      if (!user_id) return; // Exit if user_id is not available
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/billing-preference`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ user_id }),
        });
        if (response.ok) {
          const data = await response.json();
          if (data.length > 0) {
            setFormData(data[0]);
            setIsExisting(true); // Set to true if data exists
          }
        } else {
          console.error("Failed to fetch billing preferences.");
        }
      } catch (error) {
        console.error("Error fetching billing preferences:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBillingPreferences();
  }, [user_id]);

  const saveBillingData = async () => {
    if (!validateForm()) return;

    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/save-billing-preference`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...formData, user_id }),
      });

      if (response.ok) {
        alert("Billing information saved successfully!");
      } else {
        alert("Failed to save billing information.");
      }
    } catch (error) {
      console.error("Error saving billing information:", error);
      alert("An error occurred while saving billing information.");
    } finally {
      setLoading(false);
    }
  };

  const updateBillingData = async () => {
    if (!validateForm()) return;

    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/update-billing-preference`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...formData,
          user_id: user_id,
          billingPincode: String(formData.billingPincode), // Ensure pincode is sent as string
          phoneNumber: String(formData.phoneNumber), // Ensure phone number is sent as string
        }),
      });

      if (response.ok) {
        alert("Billing information updated successfully!");
      } else {
        const errorData = await response.json();
        alert(`Failed to update billing information: ${errorData.detail}`);
      }
    } catch (error) {
      console.error("Error updating billing information:", error);
      alert("An error occurred while updating billing information.");
    } finally {
      setLoading(false);
    }
  };

  const handleSaveOrUpdate = () => {
    if (isExisting) {
      updateBillingData();
    } else {
      saveBillingData();
    }
  };

  return (
    <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
      <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
        <SideBar1 clickedTab={"Billing"} />
        <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full h-[100vh]">
          <ProfileHeader clickedTab={"Billing"} />
          <div className="sm:h-auto h-[92vh] overflow-scroll bg-white-A700 flex flex-col items-center justify-start p-7 sm:px-5 rounded-[15px] w-[94%] md:w-full">
            <div className="flex flex-col gap-[43px] justify-start mt-2.5 w-[99%] md:w-full">
              <BillingSubHeader billingheader={4} />
              <div className="flex flex-col gap-10 items-start justify-start mb-[75px] ml-1.5 md:ml-[0] w-full">
                <Text className="text-gray-500 text-xl w-[88%] sm:w-full sm:text-sm mqlap:text-[1.02vw] mqtab1:text-[1.06vw] mqlap:leading-[1.48rem] mqtab1:leading-[1.48rem]" size="txtInterMedium20">
                  Changes to these preferences will apply to future invoices only. If you need a past invoice reissued, please contact at Support@recroid.com
                </Text>
                <div className="flex flex-col gap-[41px] items-start justify-start w-full md:w-full">
                  <div className="flex flex-col sm:gap-[20px] gap-8 mqlap:gap-7 mqtab1:gap-5 items-start justify-start w-full md:w-full">
                    <List className="sm:flex-col flex-row grid md:grid-cols-1 sm:gap-[20px] grid-cols-2 justify-start w-full md:w-full" orientation="horizontal">
                      <div className="mqtab2:mt-6 flex flex-col items-start w-full">
                        <Text className="text-base text-black-900 sm:text-sm  mqlap:text-[0.94vw] mqtab1:text-[1vw]" size="txtInterMedium16Black900">
                          Full name
                        </Text>
                        <Text className="mt-1.5 text-blue_gray-400 mqtab1:mt-[0.1rem] mqlap:mt-[0.25rem] text-sm w-[85%] sm:w-full sm:text-[12px] mqlap:text-[0.84vw] mqtab1:text-[0.86vw]" size="txtInterRegular14">
                          Your full name will appear on invoices.
                        </Text>
                        <input
                          value={formData.billingCompanyName}
                          onChange={handleChange}
                          name="billingCompanyName"
                          placeholder="Enter your full name"
                          className="sm:text-[10px] placeholder-gray-300 leading-[normal] p-3    text-[15px] text-left border border-gray-300 border-solid mt-4 w-[79%] rounded-[12px] mt-1 mqlap:text-[0.9vw] mqtab1:text-[0.9vw]  mqtab1:mt-[0.7rem] mqlap:mt-[0.6rem] p-3 mqtab1:p-[0.6rem]"
                        />
                        {errors.billingCompanyName && (
                          <span className="text-red-500 text-sm">{errors.billingCompanyName}</span>
                        )}
                      </div>
                      <div className="mqtab2:mt-6 flex flex-col items-start justify-between w-full">
                        <Text className="text-base text-black-900 sm:text-sm  mqlap:text-[0.94vw] mqtab1:text-[1vw]" size="txtInterMedium16Black900">
                          Email address
                        </Text>
                        <Text className="mt-1.5 text-blue_gray-400 mqtab1:mt-[0.1rem] mqlap:mt-[0.25rem] text-sm w-[85%] sm:w-full sm:text-[12px] mqlap:text-[0.84vw] mqtab1:text-[0.86vw]" size="txtInterRegular14">
                          Your email address will appear on invoices.
                        </Text>
                        <input
                          value={formData.billingEmail}
                          onChange={handleChange}
                          name="billingEmail"
                          placeholder="Enter your email address"
                          className="sm:text-[10px] placeholder-gray-300 leading-[normal] p-3    text-[15px] text-left border border-gray-300 border-solid mt-4 w-[79%] rounded-[12px] mt-1 mqlap:text-[0.9vw] mqtab1:text-[0.9vw]  mqtab1:mt-[0.7rem] mqlap:mt-[0.6rem] p-3 mqtab1:p-[0.6rem]"
                        />
                        {errors.billingEmail && (
                          <span className="text-red-500 text-sm">{errors.billingEmail}</span>
                        )}
                      </div>
                    </List>
                    <List className="sm:flex-col flex-row grid md:grid-cols-1 sm:gap-[20px] grid-cols-2 justify-start w-full md:w-full" orientation="horizontal">
                      <div className="mqtab2:mt-6 flex flex-col items-start justify-between w-full">
                        <Text className="text-base text-black-900 sm:text-sm  mqlap:text-[0.94vw] mqtab1:text-[1vw]" size="txtInterMedium16Black900">
                          Country
                        </Text>
                        <Text className="mt-1.5 text-blue_gray-400 mqtab1:mt-[0.1rem] mqlap:mt-[0.25rem] text-sm w-[85%] sm:w-full sm:text-[12px] mqlap:text-[0.84vw] mqtab1:text-[0.86vw]" size="txtInterRegular14">
                          Select the country where you want to receive invoices.
                        </Text>
                        <select
                          value={formData.billingCountry}
                          onChange={handleChange}
                          name="billingCountry"
                          className="ui dropdown p-3 border border-gray-300 border-solid mt-4 w-[79%] rounded-[12px] mt-1 mqlap:text-[0.9vw] mqtab1:text-[0.9vw]  mqtab1:mt-[0.4rem] mqlap:mt-[0.5rem] p-3 mqtab1:p-[0.6rem]"
                        >
                          <option value="">Select your country</option>
                          {countryStates.map((country) => (
                            <option key={country.country} value={country.country}>
                              {country.country}
                            </option>
                          ))}
                        </select>
                        {errors.billingCountry && (
                          <span className="text-red-500 text-sm">{errors.billingCountry}</span>
                        )}
                      </div>
                      <div className="mqtab2:mt-6 flex flex-col items-start justify-between w-full">
                        <Text className="text-base text-black-900 sm:text-sm  mqlap:text-[0.94vw] mqtab1:text-[1vw]" size="txtInterMedium16Black900">
                          State
                        </Text>
                        <Text className="mt-1.5 text-blue_gray-400 mqtab1:mt-[0.1rem] mqlap:mt-[0.25rem] text-sm w-[85%] sm:w-full sm:text-[12px] mqlap:text-[0.84vw] mqtab1:text-[0.86vw]" size="txtInterRegular14">
                          Select the state where you want to receive invoices.
                        </Text>
                        <select
                          value={formData.state}
                          onChange={handleChange}
                          name="state"
                          className="p-3 border border-gray-300 border-solid mt-4 w-[79%] rounded-[12px] mt-1 mqlap:text-[0.9vw] mqtab1:text-[0.9vw]  mqtab1:mt-[0.4rem] mqlap:mt-[0.5rem] p-3 mqtab1:p-[0.6rem]"
                          disabled={!formData.billingCountry}
                        >
                          <option value="">Select your state</option>
                          {statesList?.states?.map((state) => (
                            <option key={state} value={state}>
                              {state}
                            </option>
                          ))}
                        </select>
                        {errors.state && (
                          <span className="text-red-500 text-sm">{errors.state}</span>
                        )}
                      </div>
                    </List>
                    <List className="sm:flex-col flex-row grid md:grid-cols-1 sm:gap-[20px] grid-cols-2 justify-start w-full md:w-full" orientation="horizontal">
                      <div className="mqtab2:mt-6 flex flex-col items-start justify-between w-full">
                        <Text className="text-base text-black-900 sm:text-sm  mqlap:text-[0.94vw] mqtab1:text-[1vw]" size="txtInterMedium16Black900">
                          Address line 1
                        </Text>
                        <Text className="mt-1.5 text-blue_gray-400 mqtab1:mt-[0.1rem] mqlap:mt-[0.25rem] text-sm w-[85%] sm:w-full sm:text-[12px] mqlap:text-[0.84vw] mqtab1:text-[0.86vw] ]" size="txtInterRegular14">
                          Your address will appear on invoices.
                        </Text>
                        <input
                          value={formData.billingAddress1}
                          onChange={handleChange}
                          name="billingAddress1"
                          placeholder="Enter your address line 1"
                          className="sm:text-[10px] placeholder-gray-300 leading-[normal] p-3    text-[15px] text-left border border-gray-300 border-solid mt-4 w-[79%] rounded-[12px] mt-1 mqlap:text-[0.9vw] mqtab1:text-[0.9vw]  mqtab1:mt-[0.7rem] mqlap:mt-[0.6rem] p-3 mqtab1:p-[0.6rem]"
                        />
                        {errors.billingAddress1 && (
                          <span className="text-red-500 text-sm">{errors.billingAddress1}</span>
                        )}
                      </div>
                      <div className="mqtab2:mt-6 flex flex-col items-start justify-between w-full">
                        <Text className="text-base text-black-900 sm:text-sm  mqlap:text-[0.94vw] mqtab1:text-[1vw]" size="txtInterMedium16Black900">
                          Address line 2 (optional)
                        </Text>
                        <Text className="mt-1.5 text-blue_gray-400 mqtab1:mt-[0.1rem] mqlap:mt-[0.25rem] text-sm w-[85%] sm:w-full sm:text-[12px] mqlap:text-[0.84vw] mqtab1:text-[0.86vw]" size="txtInterRegular14">
                          Additional address details.
                        </Text>
                        <input
                          value={formData.billingAddress2}
                          onChange={handleChange}
                          name="billingAddress2"
                          placeholder="Enter your address line 2"
                          className="sm:text-[10px] placeholder-gray-300 leading-[normal] p-3    text-[15px] text-left border border-gray-300 border-solid mt-4 w-[79%] rounded-[12px] mt-1 mqlap:text-[0.9vw] mqtab1:text-[0.9vw]  mqtab1:mt-[0.7rem] mqlap:mt-[0.6rem] p-3 mqtab1:p-[0.6rem]"
                        />
                      </div>
                    </List>
                    <List className="sm:flex-col flex-row grid md:grid-cols-1 sm:gap-[20px] grid-cols-2 justify-start w-full md:w-full" orientation="horizontal">
                      <div className="mqtab2:mt-6 flex flex-col items-start justify-between w-full">
                        <Text className="text-base text-black-900 sm:text-sm  mqlap:text-[0.94vw] mqtab1:text-[1vw]" size="txtInterMedium16Black900">
                          Pincode
                        </Text>
                        <Text className="mt-1.5 text-blue_gray-400 mqtab1:mt-[0.1rem] mqlap:mt-[0.25rem] text-sm w-[85%] sm:w-full sm:text-[12px] mqlap:text-[0.84vw] mqtab1:text-[0.86vw]" size="txtInterRegular14">
                          Enter the pincode for your address.
                        </Text>
                        <input
                          value={formData.billingPincode}
                          onChange={handleChange}
                          name="billingPincode"
                          placeholder="Enter your pincode"
                          className="sm:text-[10px] placeholder-gray-300 leading-[normal] p-3    text-[15px] text-left border border-gray-300 border-solid mt-4 w-[79%] rounded-[12px] mt-1 mqlap:text-[0.9vw] mqtab1:text-[0.9vw]  mqtab1:mt-[0.7rem] mqlap:mt-[0.6rem] p-3 mqtab1:p-[0.6rem]"
                        />
                        {errors.billingPincode && (
                          <span className="text-red-500 text-sm">{errors.billingPincode}</span>
                        )}
                      </div>
                      <div className="mqtab2:mt-6 flex flex-col items-start justify-between w-full">
                        <Text className="text-base text-black-900 sm:text-sm mqlap:text-[0.94vw] mqtab1:text-[1vw]" size="txtInterMedium16Black900">
                          Phone number
                        </Text>
                        <Text className="mt-1.5 text-blue_gray-400 mqtab1:mt-[0.1rem] mqlap:mt-[0.25rem] text-sm w-[85%] sm:w-full sm:text-[12px] mqlap:text-[0.84vw] mqtab1:text-[0.86vw]" size="txtInterRegular14">
                          Your phone number will appear on invoices.
                        </Text>
                        <input
                          value={formData.phoneNumber}
                          onChange={handleChange}
                          name="phoneNumber"
                          placeholder="Enter your phone number"
                          className="sm:text-[10px] placeholder-gray-300 leading-[normal] p-3    text-[15px] text-left border border-gray-300 border-solid mt-4 w-[79%] rounded-[12px] mt-1mqlap:text-[0.9vw] mqtab1:text-[0.9vw]  mqtab1:mt-[0.7rem] mqlap:mt-[0.6rem] p-3 mqtab1:p-[0.6rem]"
                        />
                        {errors.phoneNumber && (
                          <span className="text-red-500 text-sm">{errors.phoneNumber}</span>
                        )}
                      </div>
                    </List>
                  </div>
                  <div className="flex justify-end w-full mt-4">
                    <Button
                      className=" text-center disabled:opacity-50 sm:text-[12px]  px-[3.4rem] py-[0.9rem] text-[1.2rem]  mqlap:px-[2.8vw] mqlap:rounded-[12px] mqtab1:rounded-[12px]   mqlap:py-[0.7vw] mqlap:text-[1vw] mqtab1:px-[2.6vw]  mqtab1:py-[0.7vw]  mqtab1:text-[1vw] bg-gray-100 text-indigo-600  border-indigo-600 cursor-pointer font-medium  leading-[normal] md:ml-0 text-center text-lg  flex flex-row justify-center items-center "
                      onClick={handleSaveOrUpdate}
                      disabled={loading}
                    >
                      {loading ? <BeatLoader size={8} color="#fff" /> : isExisting ? "Update" : "Save"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  );
};

export default BillingPreferences;
