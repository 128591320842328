import { Text } from "components";
import Container from "components/Container/Container";
import FormWrapper from "components/FormWrapper/FormWrapper";
import WebsiteFooter from "components/WebsiteFooter/WebsiteFooter";
import { Mail, Phone } from "lucide-react";
import { MapPin } from "lucide-react";
const ContactUs = () => {
  return (
    // <div className="w-full h-[1920px] relative bg-white overflow-hidden flex flex-col items-end justify-start pt-0 px-0 pb-[697px] box-border gap-[89px] leading-[normal] tracking-[normal] mq450:gap-[22px] mq825:gap-[44px] mq1125:h-auto">
    <div className="w-auto overflow-hidden">
      <Container
        mainhead="Contact Us"
        subtext="Connect with Us for Your Next Great Hire/Job"
      />
      <section className="self-stretch h-auto gap-[3rem]  flex flex-row mqmob1:flex mqmob1:flex-col   mqtab2:flex mqtab2:flex-col items-start justify-center pt-[9rem] pb-[7rem] px-[7rem] mqlap:px-[5.5rem] mqtab2:px-[8rem] mqtab1:px-[8rem] mqmob1:px-[3rem]  box-border max-w-full w-auto shrink-0 mq450:pb-[60px] mq450:box-border  mq1125:box-border">
        <FormWrapper />

        <div className=" w-1/4 mqtab1:w-[30%]  mqmob1:w-[100%] mqtab2:w-[100%] shadow-xl w-full flex flex-col  justify-center items-center  gap-[3rem] gap-[15.5px] mqtab1:gap-[4rem] text-md p-[3rem] mqtab2:px-[4rem] h-auto">
          <div className="flex flex-col gap-[1.7rem]">
            <div className="flex flex-row gap-[20px]">
              <Mail color="#314ca3" size={24} />
              <Text className="text-[20px] mqtab1:text-[18px] mqtab2:text-[18px] mqmob1:text-[18px]">
                support@culinda.com
              </Text>
            </div>
            <div className="flex flex-row gap-[20px]">
              <Phone color="#314ca3" size={24} />
              <Text className="text-[20px] mqtab1:text-[18px] mqtab2:text-[18px] mqmob1:text-[18px] ">
                +1 (213) 221-0120
              </Text>
            </div>
            <div className="flex flex-row gap-[20px]">
              <MapPin color="#314ca3"/>
              <address className="text-[18px] mqtab1:text-[16px] mqtab2:text-[16px] mqmob1:text-[14px]">
                <div>
                  <strong className="text-[22px] mqtab1:text-[20px] mqtab2:text-[20px] mqmob1:text-[18px]">
                    India Office:
                  </strong>
                  <br />
                  DEV2PROD SOFT SOLUTIONS PRIVATE LIMITED
                  <br />
                  7-149, Road No 2,
                  <br />
                  Vaishali Nagar, Champapet,
                  <br />
                  Hyderabad - 500079
                  <br />
                  India
                </div>
              </address>
            </div>
            <div className="flex flex-row gap-[20px]">
              <MapPin color="#314ca3" size={24} />
              <address className="text-[18px] mqtab1:text-[16px] mqtab2:text-[16px] mqmob1:text-[14px]">
                <div>
                  <strong className="text-[22px] mqtab1:text-[20px] mqtab2:text-[20px] mqmob1:text-[18px]">
                    USA Office:
                  </strong>
                  <br />
                  Irvine, California
                  <br />
                  United States
                  <br />
                  {/* Email: <a href="mailto:support@culinda.com">support@culinda.com</a><br />
                   Phone: <a href="tel:+12132210120">+1 (213) 221-0120</a> */}
                </div>
              </address>
            </div>
          </div>
          <div className="mt-[20px] mb-[20px]  h-auto self-stretch w-auto flex justify-center items-center ">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3808.358282343854!2d78.5152055216608!3d17.346484499121793!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb987ce20b3287%3A0x8b7d2aaac688f60d!2sRoad%20No.%202%2C%20Nagarjuna%20Colony%2C%20Vaishali%20Nagar%2C%20Champapet%2C%20Hyderabad%2C%20Telangana%20500079!5e0!3m2!1sen!2sin!4v1718596083119!5m2!1sen!2sin"
              // width="400"
              // height="400"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="India Office Location"
              className="w-[400px] h-[400px] mqlap:h-[390px] mqlap:w-[400px] mqtab1:w-[120%] mqtab1:h-[300px] mqtab2:w-fit mqtab2:h-[300px] mqmob1:w-auto mqmob1:auto  w-auto h-auto"
            ></iframe>
          </div>
        </div>
      </section>
      <div className=" mt-[12rem] mqmob1:mt-[12rem]">
        <WebsiteFooter />
      </div>
    </div>
    // </div>
  );
};

export default ContactUs;
