const initialState = {
    profileProfessionalData: [],
    isLoading: false,
    error: null
};

const profileProfessionalReducer= (state = initialState, action) =>{
    switch (action.type) {
        case "FETCH_PROFILE_PROFESSIONAL_DATA_REQUEST":
            return {
                ...state,
                isLoadingProfile: true,
            };
        case "FETCH_PROFILE_PROFESSIONAL_DATA_SUCCESS":
            return {
                ...state,
                isLoadingProfile: false,
                profileProfessionalData: action.payload,
            };
        case "FETCH_PROFILE_PROFESSIONAL_DATA_FAILURE":
            return {
                ...state,
                isLoadingProfile: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default profileProfessionalReducer;