
import React from "react";
import { createColumnHelper } from "@tanstack/react-table";

import { Button, Img, Line, Text, Table } from "components";


import Sidebar1 from "pages/UniversityDashBoardPages/UniversitySidebar";
import TopHeader from "pages/RecruiterDashBoardPages/TopHeader";

  const UniversityStudentManagement = () => {

    const tableDataUse = React.useRef([
      {
        sno: "1.",
        rowname: "Albert@gmail.com",
        phonenumber: "9876543210",
        course: "B.tech",
        gpa: "7.8",
        placementstatusOne: "Placed",
      },
      {
        sno: "2.",
        rowname: "Albert@gmail.com",
        phonenumber: "9876543210",
        course: "B.tech",
        gpa: "7.8",
        placementstatusOne: "Placed",
      },
      {
        sno: "3.",
        rowname: "Albert@gmail.com",
        phonenumber: "9876543210",
        course: "B.tech",
        gpa: "7.8",
        placementstatusOne: "Placed",
      },
      {
        sno: "4.",
        rowname: "Albert@gmail.com",
        phonenumber: "9876543210",
        course: "B.tech",
        gpa: "7.8",
        placementstatusOne: "Placed",
      },
      {
        sno: "5.",
        rowname: "Albert@gmail.com",
        phonenumber: "9876543210",
        course: "B.tech",
        gpa: "7.8",
        placementstatusOne: "Placed",
      },
      {
        sno: "6.",
        rowname: "Albert@gmail.com",
        phonenumber: "9876543210",
        course: "B.tech",
        gpa: "7.8",
        placementstatusOne: "Placed",
      },
      {
        sno: "7.",
        rowname: "Albert@gmail.com",
        phonenumber: "9876543210",
        course: "B.tech",
        gpa: "7.8",
        placementstatusOne: "Placed",
      },
      {
        sno: "8.",
        rowname: "Albert@gmail.com",
        phonenumber: "9876543210",
        course: "B.tech",
        gpa: "7.8",
        placementstatusOne: "Placed",
      },
    ]);
    const tableColum = React.useMemo(() => {
      const tableColumnHelp = createColumnHelper();
      return [
        tableColumnHelp.accessor("sno", {
          cell: (info) => (
            <Text
              className="pb-[13px] pt-[34px] sm:px-5 px-[35px] text-gray-700 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[186px] sm:px-5 px-[35px] text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              S. No
            </Text>
          ),
        }),
        tableColumnHelp.accessor("rowname", {
          cell: (info) => (
            <div className="flex flex-row gap-[98px] items-start justify-center pr-2.5 py-2.5">
              <Text
                className="mt-6 text-gray-700 text-lg"
                size="txtInterMedium18"
              >
                Albert
              </Text>
              <Text
                className="mr-[103px] mt-[26px] text-gray-700 text-lg"
                size="txtInterMedium18"
              >
                {info?.getValue()}
              </Text>
            </div>
          ),
          header: (info) => (
            <div className="flex flex-row gap-[147px] items-center justify-center min-w-[425px]">
              <Text
                className="text-blue_gray-400 text-lg"
                size="txtInterSemiBold18Bluegray400"
              >
                Name
              </Text>
              <Text
                className="text-blue_gray-400 text-lg"
                size="txtInterSemiBold18Bluegray400"
              >
                Email
              </Text>
            </div>
          ),
        }),
        tableColumnHelp.accessor("phonenumber", {
          cell: (info) => (
            <Text
              className="pb-3 pl-[9px] pt-[35px] text-gray-700 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[256px] text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              Phone Number
            </Text>
          ),
        }),
        tableColumnHelp.accessor("course", {
          cell: (info) => (
            <Text
              className="pb-3 pl-[3px] pt-[35px] text-gray-700 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[219px] text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              Course
            </Text>
          ),
        }),
        tableColumnHelp.accessor("gpa", {
          cell: (info) => (
            <Text
              className="pb-3 pl-1.5 pt-[35px] text-gray-700 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[139px] text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              GPA
            </Text>
          ),
        }),
        tableColumnHelp.accessor("placementstatusOne", {
          cell: (info) => (
            <Text
              className="pb-3 pt-[35px] text-gray-700 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[317px] text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              Placement Status
            </Text>
          ),
        }),
      ];
    }, []);
  

    return (
    <>
          <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
            <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
              <Sidebar1 clickedTab={"Student Management"} />

              <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
                <TopHeader header={"Student Management"} />


                <div className="bg-white-A700 flex flex-col items-center justify-start p-7 sm:px-5 rounded-[15px] w-[94%] md:w-full">
                  <div className=" font-poppins md:h-[1103px] h-[1165px] relative w-[94%] md:w-full">
                    



                  {/* <div className="flex flex-1 flex-col font-inter gap-[50px] items-center justify-start md:px-5 w-full">
            
            <div className="bg-white-A700 flex flex-col items-center justify-start p-[34px] sm:px-5 rounded-[15px] w-[95%] md:w-full"> */}


              <div className="flex flex-col items-center justify-start mt-[7px] w-full">
                <div className="flex flex-col gap-7 items-start justify-start w-[91%] md:w-full">
                  <div className="flex flex-row gap-[82px] items-start justify-start ml-0.5 md:ml-[0] w-[238px]">
                    <div className="flex flex-col gap-2 items-start justify-start w-auto">
                      <Text
                        className="text-indigo-600 text-xl w-auto"
                        size="txtInterMedium20"
                      >
                        Students
                      </Text>
                      <Line className="bg-indigo-600 h-[3px] rounded-tl-[10px] rounded-tr-[10px] w-[97%]" />
                    </div>
                    <Text
                      className="text-blue_gray-400 text-xl w-auto"
                      size="txtInterMedium20Bluegray400"
                    >
                      Upload
                    </Text>
                  </div>
                  <div className="flex flex-row md:gap-10 items-end justify-between w-full">
                    <div className="flex flex-col gap-[9px] items-center justify-start mt-3.5 w-[6%]">
                      <Text
                        className="text-blue-A200 text-lg"
                        size="txtInterSemiBold18"
                      >
                        All 37
                      </Text>
                      <Line className="bg-blue-A200 h-1 w-full" />
                    </div>
                    {/* <div className="bg-blue-A200 flex flex-row gap-[38px] h-[50px] md:h-auto items-center justify-start md:px-10 sm:px-5 px-[46px] py-2.5 rounded-[15px] w-[220px]">
                      <Img
                        className="h-6 w-6"
                        src="images/img_materialsymbol_white_a700.svg"
                        alt="materialsymbol_One"
                      />
                      <Text
                        className="text-white-A700 text-xl w-auto"
                        size="txtInterMedium20WhiteA700"
                      >
                        Filter
                      </Text>
                    </div> */}

                      <div className="bg-blue-500 flex flex-row gap-[38px] h-[50px] md:h-auto items-center justify-start md:px-10 sm:px-5 px-[40px] py-2.5 rounded-[15px] w-[220px]">
                       <img
                         className="h-6 w-6 mr-2"
                          src="images/img_materialsymbol_white_a700.svg"
                          alt="Filter Icon"
                       />
                         <span className="text-white-A700 text-xl w-auto">Filter</span>
                       </div>


                  </div>
                </div>
                <div className="overflow-auto mt-[38px] w-full">
                  <Table
                    columns={tableColum}
                    data={tableDataUse.current}
                    rowClass={""}
                    headerClass=""
                  />
                </div>
                <div className="flex sm:flex-col flex-row font-poppins md:gap-10 items-start justify-between mt-[65px] w-[92%] md:w-full">
                  <div className="flex flex-col items-center justify-start">
                    <Text
                      className="text-black-900 text-lg"
                      size="txtPoppinsMedium18"
                    >
                      <span className="text-blue_gray-900 font-poppins text-left font-medium">
                        Showing 1-8{" "}
                      </span>
                      <span className="text-gray-500 font-poppins text-left font-medium">
                        of 35
                      </span>
                    </Text>
                  </div>
                  <div className="flex flex-row font-inter items-center justify-between sm:mt-0 mt-0.5 w-[24%] sm:w-full">
                    <div className="flex flex-row items-start justify-evenly">
                      <Img
                        className="h-6 w-6"
                        src="images/img_arrowleft.svg"
                        alt="arrowleft"
                      />
                      <Text
                        className="text-[15px] text-indigo-600"
                        size="txtInterMedium15"
                      >
                        Previous
                      </Text>
                    </div>
                    <Button className="cursor-pointer font-medium h-10 leading-[normal] text-[15px] text-center w-10">
                      1
                    </Button>
                    <Text
                      className="text-[15px] text-indigo-600"
                      size="txtInterMedium15"
                    >
                      2
                    </Text>
                    <Text
                      className="text-[15px] text-indigo-600"
                      size="txtInterMedium15"
                    >
                      3
                    </Text>
                    <Text
                      className="text-[15px] text-indigo-600"
                      size="txtInterMedium15"
                    >
                      4
                    </Text>
                    <div className="flex flex-row items-center justify-evenly">
                      <Text
                        className="text-[15px] text-indigo-600"
                        size="txtInterMedium15"
                      >
                        Next
                      </Text>
                      <Img
                        className="h-6 w-6"
                        src="images/img_arrowright.svg"
                        alt="arrowright"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


                 </div>
                </div>
              </div>
           
        </>
  );
};

export default UniversityStudentManagement;
