
import { Img, Input, List, Text } from "components";
import React from "react";

import TopHeader from "pages/RecruiterDashBoardPages/TopHeader";
import Sidebar1 from "pages/UniversityDashBoardPages/UniversitySidebar";

import HelpAndSupportFaqaccordion from "components/HelpAndSupportFaqaccordion";


const UniversityHelpAndSupport = () => {

  const helpAndSupportFaqaccordionPropList = [
    {},
    { questiontext: "How can I update my account information?" },
    { questiontext: "What features does [Product/Service] offer?" },
    { questiontext: "How do I cancel or modify my subscription?" },
  ];

  return (
    <>
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <Sidebar1 clickedTab={"Help and Support"} />

          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
            <TopHeader header={"Help And Support"} />


            <div className="bg-white-A700 flex flex-col items-center justify-start p-7 sm:px-5 rounded-[15px] w-[94%] md:w-full">
              <div className=" font-poppins md:h-[1103px] h-[1165px] relative w-[94%] md:w-full">




                {/* <div className="flex flex-1 flex-col font-inter gap-[50px] items-center justify-start md:px-5 w-full">
            
            <div className="bg-white-A700 flex flex-col items-center justify-start p-[34px] sm:px-5 rounded-[15px] w-[95%] md:w-full"> */}


                <div className="bg-white-A700 flex flex-col items-center justify-start p-[59px] md:px-10 sm:px-5 rounded-[15px] w-[95%] md:w-full">
                  <Text
                    className="sm:text-4xl md:text-[38px] text-[40px] text-blue_gray-400 tracking-[-0.44px]"
                    size="txtInterSemiBold40"
                  >
                    Hello, How can we help you?
                  </Text>
                  <Input
                    name="searchbar"
                    placeholder="Ask a question..."
                    className="font-medium p-0 placeholder:text-indigo-600 sm:px-5 text-[19px] text-indigo-600 text-left tracking-[-0.21px] w-full"
                    wrapClassName="border border-gray-400_7f border-solid flex mt-[26px] pl-[25px] pr-[35px] py-2 rounded-[14px] w-[53%]"
                    prefix={
                      <Img
                        className="mt-auto mb-[5px] h-[38px] mr-[35px]"
                        src="images/img_rewind.svg"
                        alt="rewind"
                      />
                    }
                    suffix={
                      <Img
                        className="mt-px mb-auto h-[42px] ml-[35px]"
                        src="images/img_carbonaddfilled.svg"
                        alt="carbon:add-filled"
                      />
                    }
                  ></Input>
                  <Text
                    className="mt-7 text-blue_gray-400 text-xl tracking-[-0.22px]"
                    size="txtInterSemiBold20"
                  >
                    Frequently Asked Questions
                  </Text>
                  <List
                    className="flex flex-col gap-[43px] items-center mb-[113px] mt-[26px] w-auto"
                    orientation="vertical"
                  >
                    {helpAndSupportFaqaccordionPropList.map((props, index) => (
                      <React.Fragment key={`HelpAndSupportFaqaccordion${index}`}>
                        <HelpAndSupportFaqaccordion
                          className="bg-white-A700 border border-blue_gray-100 border-solid flex flex-col h-[88px] md:h-auto items-start justify-start rounded-lg shadow-bs w-[570px] sm:w-full"
                          {...props}
                        />
                      </React.Fragment>
                    ))}
                  </List>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default UniversityHelpAndSupport;
