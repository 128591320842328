import React from "react";

import { Img, Text } from "components";

const HelpAndSupportFaqaccordion = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="flex flex-row gap-2 h-full items-center justify-between p-[30px] sm:px-5 rounded-lg w-full">
          <Text className="text-gray-800 text-xs w-auto" size="txtInterBold12">
            {props?.questiontext}
          </Text>
          <Img
            className="h-[13px] w-6"
            src="images/img_arrowdown.svg"
            alt="arrowdown"
          />
        </div>
      </div>
    </>
  );
};

HelpAndSupportFaqaccordion.defaultProps = {
  questiontext: "How do I log in if I forgot my password?",
};

export default HelpAndSupportFaqaccordion;
