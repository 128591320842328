import {
  AddCancelSection2,
  AddMoreSection,
  EditDeleteIconsSection,
  SaveCancleSection,
  ToggleArrow
} from "pages/JobSeekerDashBoardPages/ProfileProfessional/IconsComponents";
import { useEffect, useState } from "react";
const ProfessionalSocialLinks = (props) => {
  const { dataOfUser, setLinks, setUserPersonalData, handleDataUpdate } = props;
  const [socialMediaData, setSocialMediaData] = useState([
    // Add more objects as needed
  ]);

  useEffect(() => {
    setTimeout(() => {
      Object.entries(dataOfUser).forEach(([key, text]) => {
        if (
          typeof text === "string" &&
          text !== undefined &&
          (text.includes("https://") || text.includes("http://"))
        ) {
          ////console.log("Key is", key);
          ////console.log("Text is", text);
          const newLink = { title: key, url: dataOfUser[key] };
          setSocialMediaData((prev) => {
            return [...prev, newLink];
          });
          setLinks((prev) => {
            return [...prev, newLink];
          });
        }
      });
    }, 5000);
  }, []);

  const [websiteName, setWebsiteName] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  const [newwebsiteName, setnewWebsiteName] = useState("");
  const [newwebsiteLink, setnewWebsiteLink] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editedIndex, setEditedIndex] = useState(null);
  const [isAddMoreClicked, setIsAddMoreClicked] = useState(false);

  const deleteSocialMediaLink = (index) => {
    const updatedData = [...socialMediaData];
    updatedData.splice(index, 1);
    setSocialMediaData(updatedData);
    setLinks(updatedData);

    delete dataOfUser[socialMediaData[index].title];
    setUserPersonalData(dataOfUser);
    // //console.log("Deleted link");
    setTimeout(() => {
      //  //console.log(dataOfUser);
    }, 500);
  };

  const editSocialMediaLink = (index) => {
    const { title, url } = socialMediaData[index];
    setIsAddMoreClicked(false);
    setWebsiteName(title);
    setWebsiteLink(url);
    setEditedIndex(index);
    setIsEditing(true);
    dataOfUser[title] = url;
    setUserPersonalData(dataOfUser);
  };

  const cancelEdit = () => {
    setIsEditing(false);
    setEditedIndex(null);
    setWebsiteName("");
    setWebsiteLink("");
  };

  const saveEditedLink = () => {
    const updatedData = [...socialMediaData];
    const oldTitle = updatedData[editedIndex].title;
    const oldUrl = updatedData[editedIndex].url;
    delete dataOfUser[oldTitle];

    updatedData[editedIndex] = { title: websiteName, url: websiteLink };
    setSocialMediaData(updatedData);
    setLinks(updatedData);
    setIsEditing(false);
    setEditedIndex(null);
    setWebsiteName("");
    setWebsiteLink("");
    setIsAddMoreClicked(false);
    for (let i = 0; i < updatedData.length; i++) {
      dataOfUser[updatedData[i].title] = updatedData[i].url;
    }
    setUserPersonalData(dataOfUser);
    //   //console.log("Edited link");

    setTimeout(() => {
      // //console.log(dataOfUser);
    }, 500);
  };

  const handleAddMoreClick = () => {
    setIsAddMoreClicked(true);
    setIsEditing(false);
  };

  const addNewSocialMediaLink = () => {
    if (newwebsiteName !== "" && newwebsiteLink !== "") {
      const updatedData = [
        ...socialMediaData,
        { title: newwebsiteName, url: newwebsiteLink },
      ];
      setSocialMediaData(updatedData);
      setLinks(updatedData);

      setIsAddMoreClicked(false);
      setnewWebsiteName("");
      setnewWebsiteLink("");
      for (let i = 0; i < updatedData.length; i++) {
        dataOfUser[updatedData[i].title] = updatedData[i].url;
      }
      setUserPersonalData(dataOfUser);
      // //console.log("Added new link");
      setTimeout(() => {
        //console.log(dataOfUser);
      }, 500);
    }
  };

  const cancleAdd = () => {
    setIsAddMoreClicked(false);
    setWebsiteName("");
    setWebsiteLink("");
  };

  const [editedWorkDetails, setEditedWorkDetails] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [selfEdit, setSelfEdit] = useState(false);
  const [editWork, setWorkEdit] = useState(false);

  const toggleDeclaration = () => {
    setWorkEdit(!editWork);
    setSelfEdit(true);
    setEditingIndex(-1);
    setIsAddMoreClicked(false);
  };

  return (
    <div className="bg-white-A700 flex flex-col w-[94%] rounded-[20px] mqlap:text-[1.05vw] mqtab1:text-[1.15vw]">
      <ToggleArrow
        toggleDeclaration={toggleDeclaration}
        imgPath="images/img_vector_black_900.svg"
        sectionHeading="Social Links"
        editWork={editWork}
      />
      {editWork && (
        <>
          <div className="ml-[93px] sm:ml-[67px] w-full">
            {socialMediaData.map((item, index) => (
              <div className="flex flex-row ml-[-15px] sm:ml-[0px] justify-between" key={index}>
                {isEditing && editedIndex === index ? (
                 <div className="flex flex-row justify-between w-[80%] gap-[9px] sm:ml-[31px]  items-center mb-[3px] md:ml-[0]  md:px-5 md:w-full">
                      <div class="w-full sm:ml-[31px] sm:flex sm:flex-col flex flex-col ">
                      <input
                        placeholder="Social Media Name"
                        className="work-edit-input  sm:text-[10px] sm:h-[20px] w-[90%] rounded-[8px] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                        value={websiteName}
                        onChange={(e) => { setWebsiteName(e.target.value); handleDataUpdate() }}
                        required
                      ></input>
                      <input
                        placeholder="Social Media Link"
                          className="work-edit-input  sm:text-[10px] sm:h-[20px] w-[90%] rounded-[8px] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                        value={websiteLink}
                        onChange={(e) => { setWebsiteLink(e.target.value); handleDataUpdate() }}
                        required
                      ></input>
                    </div>
                    <SaveCancleSection
                      saveChanges={saveEditedLink}
                      cancleChanges={cancelEdit}
                    />

                  </div>
                ) : (
                  <div className="flex flex-col  text-black-900 text-lg   w-[100%]">
                    <div className="flex flex-row  w-[94%] items-center justify-between">
                      <div className="break-words overflow-hidden  sm:text-[10px] w-full pr-4 ">
                        <span className="break-words block mqlap:text-[0.95vw] mqtab1:text-[0.95vw] ">{item.title}</span>
                        <span className="break-words block mqlap:text-[0.95vw] mqtab1:text-[0.95vw]">{item.url}</span>
                      </div>
                      <EditDeleteIconsSection
                        index={index} // Assuming you have index defined somewhere
                        editItemButton={editSocialMediaLink}
                        deleteWork={deleteSocialMediaLink}
                      />
                    </div>
                    <div
                      style={{ marginTop: "5px", marginLeft: "0px" }}
                      className="rectangle"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>

          {!isAddMoreClicked && editWork && (
            <AddMoreSection handleAddMoreClick={handleAddMoreClick} />
          )}

          {isAddMoreClicked && editWork && (
            <div className="flex flex-row justify-between w-[80%] items-end gap-[9px] sm:ml-[31px] mb-[3px] md:ml-[0] ml-[75px] md:px-5 md:w-full">
              <div class="w-full sm:ml-[31px] sm:flex sm:flex-col flex flex-col ">
                <input
                  placeholder="Social Media Name"
                  className="work-edit-input  sm:text-[10px] sm:h-[20px] w-[90%] rounded-[8px] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                  value={newwebsiteName}
                  onChange={(e) => { setnewWebsiteName(e.target.value); handleDataUpdate() }}
                  required
                ></input>
                <input
                  placeholder="Social Media Link"
                  className="work-edit-input  sm:text-[10px] sm:h-[20px] w-[90%] rounded-[8px] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                  value={newwebsiteLink}
                  onChange={(e) => { setnewWebsiteLink(e.target.value); handleDataUpdate() }}
                  required
                ></input>
              </div>
              <AddCancelSection2
                // handleDataUpdate={handleDataUpdate}
                addNewWorkExperience={addNewSocialMediaLink}
                cancelAdd={cancleAdd}
                page="Social Links"
              />


            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProfessionalSocialLinks;
