import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
  Typography
} from "@mui/material";
import { Button, Img, Text } from "components";
export const EditIcon = ({ onClick }) => (
  <div>
    <img
      className="sm:h-[15px] bxs-edit mqlap:h-[2.7vw] mqlap:w-[1.85vw]  mqtab1:h-[2.8vw] mqtab1:w-[2.1vw]"
      alt="Bxs edit"
      src="https://c.animaapp.com/OxnFMjQi/img/bxs-edit-1.svg"
      onClick={onClick}
    />
  </div>
);
export const DownArrow = () => (
  <img
    className="icon-park-outline  sm:h-[15px] "
    // onClick={workExpInitially}
    alt="Icon park outline"
    src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-1.svg"
  />
);
export const UpArrow = () => (
  <img
    className="icon-park-outline sm:h-[15px] "
    // onClick={workExpInitially}
    alt="Icon park outline"
    src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-2.svg"
  />
);

export const AddCancelSection = ({
  newPosition,
  setNewPosition,
  addNewWorkExperience,
  cancelAdd,
  page,handleDataUpdate
}) => {
  return (
    <div className="flex flex-row sm:flex sm:flex-col justify-between w-[80%] sm:w-[100%] gap-[9px] it mb-[30px] md:ml-[0] ml-[75px] md:px-5 md:w-full">
      <div class="w-full sm:ml-[31px] sm:flex sm:flex-col">
      <input
        placeholder={page}
        className="work-edit-input  sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
        value={newPosition}
        onChange={(e) =>{setNewPosition(e.target.value);handleDataUpdate()}}
        required
      /></div>
     
      <div className="flex flex-row items-end justify-end gap-5 sm:gap-4 relative  ">
                  <Button
                    className="w-[8.4rem] h-[50px] mqlap:h-[2.6rem] mqlap:w-[7.5rem] mqtab1:h-[2.2rem] mqtab1:w-[6.3rem] flex justify-center items-center mb-3 sm:rounded-[5px]  sm:w-[65px] sm:h-[22px] sm:h-[20px] sm:text-[10px] text-[1.1rem] mqlap:text-[1vw] mqtab1:text-[1.02vw] mqlap:rounded-[12px] mqtab1:rounded-[10px]"
                    onClick={ addNewWorkExperience}
                  >
                    Add
                  </Button>
                  <Button
                    className="w-[8.4rem] h-[50px] mqlap:h-[2.6rem] mqlap:w-[7.5rem] mqtab1:h-[2.2rem] mqtab1:w-[6.3rem] flex justify-center items-center mb-3 sm:rounded-[5px]  sm:w-[65px] sm:h-[22px] sm:h-[20px] sm:text-[10px] text-[1.1rem] mqlap:text-[1vw] mqtab1:text-[1.02vw] mqlap:rounded-[12px] mqtab1:rounded-[10px]"
                    onClick={cancelAdd}
                  >
                    Cancel
                  </Button>
                </div>     
    </div>
  );
};

export const AddCancelSection2 = ({
  addNewWorkExperience,
  cancelAdd,
  page
}) => (
<div className="flex flex-row items-end justify-end gap-5 sm:gap-4 relative  ">
                  <Button
                    className="w-[8.4rem] h-[50px] mqlap:h-[2.6rem] mqlap:w-[7.5rem] mqtab1:h-[2.2rem] mqtab1:w-[6.3rem] flex justify-center items-center mb-3 sm:rounded-[5px]  sm:w-[65px] sm:h-[22px] sm:h-[20px] sm:text-[10px] text-[1.1rem] mqlap:text-[1vw] mqtab1:text-[1.02vw] mqlap:rounded-[12px] mqtab1:rounded-[10px]"
                    onClick={ addNewWorkExperience}
                  >
                    Add
                  </Button>
                  <Button
                    className="w-[8.4rem] h-[50px] mqlap:h-[2.6rem] mqlap:w-[7.5rem] mqtab1:h-[2.2rem] mqtab1:w-[6.3rem] flex justify-center items-center mb-3 sm:rounded-[5px]  sm:w-[65px] sm:h-[22px] sm:h-[20px] sm:text-[10px] text-[1.1rem] mqlap:text-[1vw] mqtab1:text-[1.02vw] mqlap:rounded-[12px] mqtab1:rounded-[10px]"
                    onClick={cancelAdd}
                  >
                    Cancel
                  </Button>
                </div>
);

export const SaveCancleSection = ({ saveChanges, cancleChanges, page }) => (
  <div className="flex flex-row items-end justify-end gap-5 sm:gap-4 relative sm:relative  sm:top-[3px]">
    <Button
    className="w-[8.4rem] h-[50px]  mb-3 mqlap:h-[2.6rem] mqlap:w-[7.5rem] mqtab1:h-[2.2rem] mqtab1:w-[6.3rem] flex justify-center items-center mb-3 sm:rounded-[5px]  sm:w-[65px] sm:h-[22px] sm:h-[20px] sm:text-[10px] text-[1.1rem] mqlap:text-[1vw] mqtab1:text-[1.02vw] mqlap:rounded-[12px] mqtab1:rounded-[10px]"

      onClick={saveChanges}
    >
      Save
    </Button>
    <Button
      className="w-[8.4rem] h-[50px]  mb-3 mqlap:h-[2.6rem] mqlap:w-[7.5rem] mqtab1:h-[2.2rem] mqtab1:w-[6.3rem] flex justify-center items-center mb-3 sm:rounded-[5px]  sm:w-[65px] sm:h-[22px] sm:h-[20px] sm:text-[10px] text-[1.1rem] mqlap:text-[1vw] mqtab1:text-[1.02vw] mqlap:rounded-[12px] mqtab1:rounded-[10px] sm:relative  sm:right-[8px]"
      onClick={cancleChanges}
    >
      Cancel
    </Button>
  </div>
);

export const ToggleArrow = ({ toggleDeclaration, editWork,imgPath,sectionHeading }) => (
  <div className="flex flex-1 flex-row md:gap-10 items-center justify-between p-4">
  <div className="flex flex-row gap-[18px] items-center justify-center ml-[33px]">
          <Img
            className=" sm:object-contain  sm:h-[15px] sm:w-[15px]  h-[25px] md:h-auto object-cover w-[25px] mqlap:w-[1.4vw] mqlap:h-[1.4vw] mqtab1:w-[1.6vw] mqtab1:h-[1.6vw]"
            src={imgPath}
            alt="brainstormskill"
          />
          <Text
             className="mt-0.5 text-black-900 text-lg sm:text-[12px] mqlap:text-[1.05vw] mqtab1:text-[1.15vw]"
            size="txtInterSemiBold18"
          >
            {sectionHeading}
          </Text>
        </div>
  <div className="flex flex-row">
    <img
      className="icon-park-outline sm:h-[15px] sm:w-[15px] mqlap:h-[2.6vw]  mqtab1:h-[3vw] "
      onClick={toggleDeclaration}
      alt="Icon park outline"
      src={
        editWork
          ? "https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-1.svg"
          : "https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-2.svg"
      }
    />
  </div>
  </div>
);

// export const EditDeleteIconsSection = ({
//   index,
//   editItemButton,
//   deleteWork,
// }) => (
//   <div className="flex flex-row w-[15%] justify-end mr-[4%]">
//     <img
//       className="bxs-edit sm:h-[15px] sm:w-[15px]"
//       onClick={() => editItemButton(index)}
//       alt="Bxs edit"
//       src="https://c.animaapp.com/OxnFMjQi/img/bxs-edit-1.svg"
//     />
//     <Img
//       className="bxs-edit sm:h-[15px] sm:w-[15px]"
//       onClick={() => deleteWork(index)}
//       src="images\img_delete1.svg"
//     />
//   </div>
// );
export const EditDeleteIconsSection = ({
  index,
  editItemButton,
  deleteWork,
}) => {
  const [deletePopup, setDeletePopup] = useState(false);

  const handleDeleteButton = () => {
    setDeletePopup(true);
  };

  return (

      <div className="flex flex-row w-[15%] justify-end mr-[4%]">
        <div  className="flex flex-row justify-center items-end mr-[4%]">
        <img
          className="sm:h-[15px] bxs-edit mqlap:h-[1.85vw] mqlap:w-[1.85vw]  mqtab1:h-[2.1vw] mqtab1:w-[2.1vw]"

          onClick={() => editItemButton(index)}
          alt="Edit"
          src="https://c.animaapp.com/OxnFMjQi/img/bxs-edit-1.svg"
        />
        <img
          className="bxs-delete sm:h-[15px] mqlap:h-[1.6vw] mqlap:w-[1.6vw]  mqtab1:h-[1.8vw] mqtab1:w-[1.8vw]"
          onClick={handleDeleteButton}
          alt="Delete"
          src="images/img_delete1.svg"
        />
        </div>
       
     
      <Dialog
        maxWidth="xxl"
        open={deletePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick={true}
      >
        <div className="mt-[20px]">
          <div className="flex flex-row items-center mb-[20px]">
            <Typography
              className="pl-[3.5%] mt-0.5 text-black-900 text-xl sm:text-[12px]"
              variant="h6"
            >
              Sure you want to delete?
            </Typography>
            <Tooltip
              title={
                <Typography fontSize={18}>
                  Click Yes button to delete.
                </Typography>
              }
              placement="right"
              arrow
              className="w-[40px] h-[40px] relative flex flex-col items-center justify-start gap-2  rounded-[15px] transition-colors hover:bg-gray-100"
            >
              <img src="images/infoIcon.svg" alt="Info Icon" className="pl-[5px]" />
            </Tooltip>
          </div>
          <hr style={{ height: "1px", backgroundColor: "grey", border: "none", width: "100%" }} />
        </div>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Please verify this before proceeding with the deletion, as it will be permanent.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            style={{ border: "solid 2px blue", color: "blue" }}
            className="h-[50px] w-[100px] sm:h-[30px] sm:w-[80px] text-[18px] rounded-[14px]"
            onClick={() => setDeletePopup(false)}
          >
            No
          </button>
          <Button
            onClick={() => {
              deleteWork(index);
              setDeletePopup(false);
            }}
            className="h-[50px] w-[100px]"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      </div>
  );
};

export const AddMoreSection = ({ handleAddMoreClick }) => (
  <div
    className="flex flex-row gap-[9px] items-center justify-start mb-[3px] md:ml-[0] sm:ml-[35px] ml-[87px] md:px-5 w-[20%] md:w-full"
    onClick={handleAddMoreClick}
  >
    <Img
      className="h-5 w-5 mb-4 sm:h-[13px] sm:w-[13px] sm:relative sm:top-[2px] mqlap:w-[1vw] mqlap:h-[1vw] mqtab1:w-[1.1vw] mqtab1:h-[1.1vw]"
      src="images/img_plus.svg"
      alt="plus"
    />
    <Text
      className="text-[15px] text-black-900 pb-3 w-[100%] sm:text-[11px] mqlap:text-[0.9vw] mqtab1:text-[0.95vw]"
      size="txtInterRegular15"
    >
      Add more
    </Text>
  </div>
);

export const ShowAllSection = ({ arrayLength, toggleShowAll,textTag }) => (
  <div
    className="flex flex-row items-center justify-center mt-5 mb-5"
    onClick={toggleShowAll}
  >
    <Text className="text-wrapper-3 sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[1vw]">
      show all {arrayLength} {textTag}
    </Text>
    <Img
      className="rightarrow1 h-[25px] sm:h-[15px] sm:w-[15px] md:h-auto object-cover w-[30px] p-1 ml-3"
      src="https://c.animaapp.com/OxnFMjQi/img/line-3-1.svg"
      alt="brainstormskill"
    />
  </div>
);
