import { useCallback } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const RecruiterRoboLogo31Icon = styled.img`
  position: absolute;
  top: 11.5px;
  left: 0px;
  width: 97.2px;
  height: 95.1px;
  object-fit: cover;
`;
const RecruiterRoboLogo31Wrapper = styled.div`
  position: relative;
  width: 97.2px;
  height: 117px;
  overflow: hidden;
  flex-shrink: 0;
`;
const WebsiteName = styled.div`
  position: relative;
  line-height: 135.5%;
  font-weight: 600;
  display: inline-block;
  width: 213.3px;
  height: 34.2px;
  flex-shrink: 0;
  color: #161616;
`;
const FrameParent = styled.div`
  position: absolute;
  top: 50px;
  left: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const BoyWithLaptopInstallingSec = styled.img`
  position: absolute;
  top: 360px;
  left: calc(50% - 653px);
  width: 532px;
  height: 399px;
  object-fit: cover;
`;
const ForgotPassword = styled.div`
  position: relative;
  font-size: var(--font-size-13xl);
  font-weight: 600;
  color: var(--color-gray-100);
`;
const EnterTheEmail = styled.div`
  position: relative;
  display: inline-block;
  width: 676px;
`;
const InputLabelmedium = styled.div`
  position: absolute;
  top: 0%;
  left: calc(50% - 338.15px);
  line-height: 37.15px;
`;
const GroupChild = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 338.15px);
  border-radius: 10.25px;
  background-color: var(--color-white);
  border: 1.3px solid var(--color-gainsboro);
  box-sizing: border-box;
  width: 676.3px;
  height: 66.6px;
`;
const InputPlaceholdermediumon = styled.div`
  position: absolute;
  top: 25.08%;
  left: calc(50% - 322.75px);
  line-height: 33.3px;
`;
const RectangleParent = styled.div`
  position: absolute;
  top: 38.4px;
  left: calc(50% - 338.15px);
  width: 676.3px;
  height: 66.6px;
  font-size: 20.49px;
  color: var(--color-darkgray-100);
`;
const Email = styled.div`
  position: relative;
  width: 676.3px;
  height: 105px;
  font-size: 23.06px;
  color: var(--color-darkslategray-200);
  font-family: var(--font-inter);
`;
const SubmitChild = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 338.15px);
  border-radius: 7.69px;
  background-color: var(--color-darkslateblue);
  width: 676.3px;
  height: 64px;
`;
const LogIn = styled.div`
  position: absolute;
  top: calc(50% - 17px);
  left: calc(50% - 34.15px);
  line-height: 33.3px;
`;
const Submit = styled.div`
  position: relative;
  width: 676.3px;
  height: 64px;
  cursor: pointer;
  text-align: center;
  font-size: 20.49px;
  color: var(--color-white);
  font-family: var(--font-inter);
`;
const ForgotPasswordParent = styled.div`
  position: absolute;
  top: 265px;
  left: calc(50% - 24px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 42px;
  font-size: var(--font-size-3xl);
  color: var(--color-darkslategray-100);
`;
const ResetPasswordemailAddressRoot = styled.div`
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  height: 100vh;
  overflow: hidden;
  text-align: left;
  font-size: 26.06px;
  color: var(--color-gray-300);
  font-family: var(--font-poppins);
`;

const UniversityDesktopResetPasswordemailAddress = () => {
  const navigate = useNavigate();

  const onSubmitContainerClick = useCallback(() => {
    navigate("/newpassword");
  }, [navigate]);

  const onLogo = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <ResetPasswordemailAddressRoot>
      <FrameParent>
        <RecruiterRoboLogo31Wrapper>
          <RecruiterRoboLogo31Icon
            alt=""
            src="/recruiter-robo-logo-3-1@2x.png" onClick={onLogo} style={{cursor: "pointer"}}
          />
        </RecruiterRoboLogo31Wrapper>
        <WebsiteName onClick={onLogo} style={{cursor: "pointer", color: "rgb(90, 198, 200)"}}>Recruiter Robo</WebsiteName>
      </FrameParent>
      <BoyWithLaptopInstallingSec
        alt=""
        src="/boy-with-laptop-installing-security-passwords-shield-and-lock-on-his-pc-and-phone@2x.png"
      />
      <ForgotPasswordParent>
        <ForgotPassword>Forgot Password?</ForgotPassword>
        <EnterTheEmail>
          Enter the email address you used when you joined and we’ll send you
          link to reset your pasword.
        </EnterTheEmail>
        <EnterTheEmail>
          For security reasons we do NOT store your password. So rest assured
          that we will never send your password via email.
        </EnterTheEmail>
        <Email>
          <InputLabelmedium>Email</InputLabelmedium>
          <RectangleParent>
            <GroupChild />
            <InputPlaceholdermediumon>Enter Email</InputPlaceholdermediumon>
          </RectangleParent>
        </Email>
        <Submit onClick={onSubmitContainerClick} style={{cursor: "pointer"}}>
          <SubmitChild />
          <LogIn>Submit</LogIn>
        </Submit>
      </ForgotPasswordParent>
    </ResetPasswordemailAddressRoot>
  );
};

export default UniversityDesktopResetPasswordemailAddress;
