import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { Img, Line, Text } from "components";
import PopupModal from "components/Filterpopup";
import Sidebar1 from "pages/RecruiterDashBoardPages/RecruiterSidebar";
import TopHeader from "pages/RecruiterDashBoardPages/TopHeader";
import { useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import { RadioGroup } from "components";

import { DateRange } from "react-date-range";

const JobsPage = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const handleStart = () => {
    //console.log("Start button clicked");
    closePopup();
  };

  const handleCancel = () => {
    //console.log("Cancel button clicked");
    closePopup();
  };

  const invoicesData = [
    {
      "S.No": "1",
      jobroles: "Software Engineer",
      status: "Uploaded",
      totalcandidates: "50",
      jobposted: "2022-01-01",
    },
    {
      "S.No": "2",
      jobroles: "Data Analyst",
      status: "Job Match",
      totalcandidates: "20",
      jobposted: "2021-12-15",
    },
    {
      "S.No": "3",
      jobroles: "Product Manager",
      status: "Cancelled",
      totalcandidates: "35",
      jobposted: "2022-01-10",
    },
    {
      "S.No": "4",
      jobroles: "Sales Representative",
      status: "On-Hold",
      totalcandidates: "45",
      jobposted: "2021-12-20",
    },
    {
      "S.No": "5",
      jobroles: "Graphic Designer",
      status: "Closed",
      totalcandidates: "15",
      jobposted: "2021-12-05",
    },
    {
      "S.No": "6",
      jobroles: "Marketing Specialist",
      status: "Job Match",
      totalcandidates: "30",
      jobposted: "2022-01-05",
    },
    {
      "S.No": "7",
      jobroles: "Financial Analyst",
      status: "Cancelled",
      totalcandidates: "25",
      jobposted: "2021-12-10",
    },
    {
      "S.No": "8",
      jobroles: "HR Manager",
      status: "On-Hold",
      totalcandidates: "40",
      jobposted: "2022-01-15",
    },
    {
      "S.No": "9",
      jobroles: "Operations Coordinator",
      status: "Uploaded",
      totalcandidates: "55",
      jobposted: "2021-12-25",
    },
    {
      "S.No": "10",
      jobroles: "Customer Service Representative",
      status: "Closed",
      totalcandidates: "10",
      jobposted: "2021-12-01",
    },
  ];

  const [isModalOpen, setModalOpen] = useState(false);
  // const [isPopupVisible, setPopupVisible] = useState(false);
  const [actionType, setAction] = useState("");

  // const handleEditApplyClick = () => {
  //   // Toggle the visibility of the popup
  //   setPopupVisible(!isPopupVisible);
  // };
  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleAction = (typeofAction) => {
    setAction(typeofAction);
  };

  const getColorBasedOnStatus = (status) => {
    switch (status) {
      case "Cancelled":
        return "red-500";
      case "On-Hold":
        return "yellow-500";
      case "Job Match":
        return "blue-A200";
      case "Uploaded":
        return "green-600";

      default:
        return "gray-700"; // Default color or any other color you prefer
    }
  };

  const [popupText, setPopText] = useState("");
  const navigate = useNavigate();
  const viewCandidates = (nameOfRole) => {
    navigate("/jobs/cadidates");
    //console.log(nameOfRole);
  };

  const editPopup = (text) => {
    openPopup();
    setPopText(text);
  };

  // var tempData = [];

  // const [tableData, setTableData] = useState(tempData);









  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage=10;

  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  // const displayedRows = invoicesData.slice(startIndex, endIndex);

  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleButtonClick = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleCloseButtonClick = () => {
    setShowDatePicker(false);
  };

  const [showDatePicker, setShowDatePicker] = useState(false);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  return (
    <>
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <Sidebar1 clickedTab={"Jobs"} />

          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
            <TopHeader header={"Jobs"} />
            <div
              style={{ overflow: "scroll" }}
              className="bg-white-A700 flex flex-col font-poppins items-center justify-start rounded-[15px] w-[94%] md:w-full h-[93vh]"
            >

              <div className="flex flex-col gap-[43px] justify-start mb-[199px] mt-2.5 w-[99%] md:w-full">
                <div className="flex flex-row md:gap-10 items-end justify-between w-[88%] md:w-full">
                  <div className="flex flex-col gap-[9px] items-center justify-start mt-[17px] w-[6%]">
                    <Text
                      className="text-blue-A200 text-lg"
                      size="txtInterSemiBold18"
                    >
                      All 37
                    </Text>
                    <Line className="bg-blue-A200 h-1 w-full" />
                  </div>
                  <div
                    onClick={openModal}
                    className="bg-blue-A200 flex flex-row gap-[38px] h-[50px] md:h-auto items-center justify-start mb-0.5 md:px-10 sm:px-5 px-[46px] py-2.5 rounded-[15px] w-[220px]"
                  >
                    <Img
                      className="h-6 w-6"
                      src="images/img_materialsymbol_white_700.svg"
                      alt="materialsymbol_One"
                    />

                    <Text
                      className="text-white-A700 text-xl w-auto"
                      size="txtInterMedium20"
                    >
                      Filter
                    </Text>
                  </div>
                </div>

                <TableContainer component={Paper} className="w-[93%] md:w-full">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Text
                            className="text-black-900 text-lg"
                            size="txtInterMedium18"
                          >
                            S.No
                          </Text>
                        </TableCell>
                        <TableCell>
                          <Text
                            className="pl-[30px] text-black-900 text-lg"
                            size="txtInterMedium18"
                          >
                            Job Roles
                          </Text>
                        </TableCell>
                        <TableCell>
                          <Text
                            className="text-black-900 text-lg"
                            size="txtInterMedium18"
                          >
                            Status
                          </Text>
                        </TableCell>
                        <TableCell>
                          <Text
                            className="text-black-900 text-lg"
                            size="txtInterMedium18"
                          >
                            Total Candidates
                          </Text>
                        </TableCell>
                        <TableCell>Job Posted</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoicesData.map((invoice) => (
                        <TableRow key={invoice.id}>
                          <TableCell>
                            <Text
                              className="text-blue_gray-400 text-lg"
                              size="txtInterMedium18Bluegray400"
                            >
                              {invoice["S.No"]}
                            </Text>
                          </TableCell>

                          <TableCell
                            onClick={() => viewCandidates(invoice.jobroles)}
                          >
                            <Text
                              className="text-blue_gray-400 text-lg  cursor-pointer"
                              size="txtInterMedium18Bluegray400"
                            >
                              {invoice.jobroles}
                            </Text>
                          </TableCell>

                          <TableCell>
                            <button
                              style={{
                                //color: `text-${getColorBasedOnStatus(invoice.status)}`,

                                backgroundColor: "#ffffff",
                              }}
                              className={`text-[20px] rounded-[12px] border border-${getColorBasedOnStatus(
                                invoice.status,
                              )} border-solid text-lg cursor-pointer p-2 w-[130px] font-medium leading-[normal] text-center text-${getColorBasedOnStatus(
                                invoice.status,
                              )}`}
                            >
                              {invoice.status}
                            </button>
                          </TableCell>

                          <TableCell>
                            <Text
                              className="text-black-900 text-lg"
                              size="txtInterMedium18"
                            >
                              {invoice.totalcandidates}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              className="text-black-900 text-lg"
                              size="txtInterMedium18"
                            >
                              {invoice.jobposted}
                            </Text>
                          </TableCell>
                          <Popup
                            trigger={
                              <TableCell>
                                <Text>
                                  <span
                                    onClick={() => handleAction("Apply")}
                                    className="text-blue-A200 text-lg cursor-pointer"
                                    size="txtInterMedium18Green600"
                                  >
                                    Apply{" "}
                                  </span>{" "}
                                  /{" "}
                                  <span
                                    className="text-blue-A200 text-lg cursor-pointer"
                                    size="txtInterMedium18Green600"
                                    onClick={() => handleAction("Edit")}
                                  >
                                    {" "}
                                    Edit{" "}
                                  </span>
                                </Text>
                              </TableCell>
                            }
                            position="left center"
                            contentStyle={
                              actionType === "Apply"
                                ? {
                                  width: "380px",
                                  height: "190px",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                }
                                : {
                                  width: "216px",
                                  height: "185px",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                }
                            }
                          >
                            {actionType === "Apply" ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                }}
                              >
                                <RadioGroup
                                  selectedValue="Lifetime"
                                  className="flex gap-[60px] items-start justify-start w-auto"
                                  name="timeperiod"
                                >
                                  <button
                                    className="text-[20px] rounded-[12px] border border-gray-400_66 border-solid text-blue_gray-400 text-lg hover:bg-blue-500 hover:text-white-A700"
                                    style={{
                                      width: "279px",
                                      height: "70px",
                                      marginBottom: "15px",
                                      padding: "10px",
                                    }}
                                    checked={false}
                                    name="timeperiod"
                                    label="Custom"
                                    id="Custom"
                                    onClick={handleButtonClick}
                                  >
                                    Apply with resume received on custom dates
                                  </button>


                                  {showDatePicker && (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        position: "absolute",
                                        left: "300px",
                                        top: "90px",
                                        zIndex: 900,
                                        backgroundColor: "#ede8e8",
                                        padding: "10px",
                                      }}
                                    >
                                      <DateRange
                                        editableDateInputs={true}
                                        onChange={(item) => setState([item.selection])}
                                        moveRangeOnFirstSelection={false}
                                        ranges={state}
                                      />

                                      <button
                                        style={{
                                          marginTop: "10px",
                                          backgroundColor: "#5298fe",
                                          color: "white",
                                          height: "30px",
                                        }}
                                        onClick={handleCloseButtonClick}
                                      >
                                        Close
                                      </button>
                                    </div>
                                  )}
                                </RadioGroup>

                                <button
                                  className="text-[20px] rounded-[12px] border border-gray-400_66 border-solid text-blue_gray-400 text-lg  hover:bg-blue-500 hover:text-white-A700"
                                  style={{
                                    width: "279px",
                                    height: "70px",
                                    padding: "10px",
                                  }}
                                >
                                  Apply with active resumes
                                </button>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                }}
                              >
                                <button
                                  onClick={() => editPopup("cancel")}
                                  className="text-[20px] rounded-[12px] border border-red-500 border-solid text-red-500 text-lg hover:bg-red-500 hover:text-white-A700"
                                  style={{
                                    width: "150px",
                                    height: "32px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  Cancelled
                                </button>
                                <button
                                  onClick={() => editPopup("On-Hold")}
                                  className="text-[20px] rounded-[12px] border border-yellow-500 border-solid text-yellow-500 text-lg hover:bg-yellow-500 hover:text-white-A700 "
                                  style={{
                                    width: "150px",
                                    height: "32px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  On-Hold
                                </button>
                                <button
                                  onClick={() => editPopup("Job Match")}
                                  className=" text-[20px] rounded-[12px] border border-text-blue-A200 border-solid text-blue-A200 text-lg hover:bg-blue-A200 hover:text-white-A700 text-center"
                                  style={{ width: "150px", height: "32px" }}
                                >
                                  Job Match
                                </button>
                              </div>
                            )}
                          </Popup>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Line className="bg-blue_gray-100 h-px w-full" />
                </TableContainer>

                <div className="w-full flex flex-row justify-center mt-[50px]">
                  <Pagination
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    page={currentPage}
                    count={Math.ceil(invoicesData.length / itemsPerPage)}
                    onChange={handlePageChange}
                  />
                </div>
                <PopupModal isOpen={isModalOpen} onClose={closeModal} />
                <Modal
                  isOpen={isPopupOpen}
                  onRequestClose={closePopup}
                  contentLabel="Popup Modal"
                  style={{
                    overlay: {
                      backgroundColor: "rgba(0, 0, 0, 0.2)", // Adjust the opacity and color as needed
                    },
                    content: {
                      backgroundColor: "white",
                      width: "986px",
                      height: "221px",
                      margin: "auto",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                    },
                  }}
                >
                  <div>
                    <h1
                      className="text-center text-[22px] rounded-[12px] text-blue_gray-400 text-lg hover:bg-blue-500 hover:text-white-A700"
                      style={{ color: "605E5C" }}
                    >
                      Are you sure want to set job post {popupText}
                    </h1>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <button
                      onClick={handleStart}
                      className="bg-blue-A200"
                      style={{
                        color: "white",
                        padding: "12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        width: "220px", // Set a specific width for both buttons
                        fontSize: "14px", // Set font size for the buttons
                      }}
                    >
                      Confirm
                    </button>
                    <button
                      onClick={handleCancel}
                      style={{
                        border: "2px solid grey",
                        padding: "12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        width: "220px", // Set a specific width for both buttons
                        fontSize: "14px", // Set font size for the buttons
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobsPage;
