import Dialog from '@mui/material/Dialog';
import DialogContentText from "@mui/material/DialogContentText";
import { data } from "autoprefixer";
import axios from "axios";
import { Button, Img, Text } from "components";
import FallbackTemplate from "components/CoverletterTemplates/FallbackTemplate";
import jsPDF from "jspdf";
import { ProfileHeader } from "pages/JobSeekerDashBoardPages/ProfileHeader";
import { SideBar1 } from "pages/JobSeekerDashBoardPages/SideBar1";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import getData from "../CoveLetterDetails/getData";

// const label = { inputProps: { "aria-label": "Switch demo" } };

const CoverletterDowload = () => {
  const [open, setOpen] = useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleBothActions = () => {
    handleDownload();
    handleClickOpen();
  };

  const [coverletterContent, setCoverletterContent] = useState("");
  const [data1, setdata] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [companyaddress, setCompanyaddress] = useState("");
  const [hiringManagerName, setHiringManagerName] = useState("");
  const [role, setRole] = useState("");
  const [CoverLetterToRender, setCoverLetterToRender] = useState(null);
  const [CoverLetterName, setCoverLetterName] = useState("");

  const [awards, setAwards] = useState("");
  const [courses, setCourses] = useState("");
  const [projects, setProjects] = useState("");
  const [publications, setPublications] = useState("");
  const [workExperience, setWorkExperience] = useState("");
  const [education, setEducation] = useState("");
  const [technicalSkills, setTechnicalSkills] = useState("");
  const [certificates, setCertificates] = useState("");
  const [educationDetails, setEducationDetails] = useState("");
  const [projectsDetails, setProjectsDetails] = useState("");
  const [workExperienceDetails, setWorkExperienceDetails] = useState("");

  const [inputValue, setInputValue] = useState("");
  const [showWarning, setShowWarning] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const imageId = queryParams.get("imageId");

  const commonProps = {
    companyname,
    companyaddress,
    role,
    hiringManagerName,
    coverletterContent: data1,
  };
  useEffect(() => {
    const loadComponent = async () => {
      try {
        const importedComponent = await import(
          `components/CoverletterTemplates/template${imageId}`
        );
        setCoverLetterToRender(() => importedComponent.default);
        setCoverLetterName(imageId);
        // //console.log(`pages/CoverLetterTemplates/template${imageId}`)
      } catch (error) {
        console.error("Error importing component:", error);
        setCoverLetterToRender(null);
      }
    };

    if (CoverLetterName === "") {
      loadComponent();
    }

    const fetchData = async () => {
      const data = await getData();
      //console.log("Data from function", data);
      // //console.log("-------------", data["awards"]);
      //console.log("------------", data["work_experience"].toString());
      // make everything as a string and then pass it to the backend
      setAwards(data["awards"].toString());
      setCourses(data["courses"].toString());
      setProjects(data["projects"].toString());
      setPublications(data["publications"].toString());
      setWorkExperience(data["work_experience"].toString());
      setEducation(data["education"].toString());
      setTechnicalSkills(data["technical_skills"].toString());
      setCertificates(data["certificates"].toString());
    };
    fetchData(data);
  }, []);

  const fetchData = async () => {
    try {
      const data = await getData();
      //console.log("Data from function", data);

      // Convert arrays to JSON strings
      setAwards(JSON.stringify(data["awards"]));
      setCourses(JSON.stringify(data["courses"]));
      setProjects(JSON.stringify(data["projects"]));
      setPublications(JSON.stringify(data["publications"]));
      setWorkExperience(JSON.stringify(data["work_experience"]));
      setEducation(JSON.stringify(data["education"]));

      // Extract education, projects, and workExperience data separately
      const educationData = data["education"].map(
        (edu) => `${edu.institution} ${edu.degree} ${edu.percentage}`,
      );
      const projectsData = data["projects"].map(
        (project) =>
          `${project.projectName} ${project.projectDescription} ${project.technologiesUsed} ${project.projectLink}`,
      );
      const workExperienceData = data["work_experience"].map(
        (work) => `${work.company} ${work.position} ${work.description}`,
      );

      // Join the extracted data and set in the state
      const educationDetails = educationData.join("\n");
      const projectsDetails = projectsData.join("\n");
      const workExperienceDetails = workExperienceData.join("\n");

      // Set the state variables
      setEducationDetails(educationDetails);
      setProjectsDetails(projectsDetails);
      setWorkExperienceDetails(workExperienceDetails);

      // Create the prompt using the stringified data
      const prompt = {
        prompt: `You are a talented AI Coverletter generator, and your task is to create a professional cover letter for a job seeker within 200 words. Exclude salutations, addresses, personal details and closing part(Yours Sincerely, Regards etc.) in the response. Only give the main paragraphs of the letter. Use the provided information below to craft the paragraphs.\n\nCompany: ${companyname}\nRecruiting Company Address: ${companyaddress}\nRole: ${role}\nHiring Manager Name: ${hiringManagerName}\n Awards Won by Candidate: ${awards}\nCourses completed by Candidate: ${courses}\nProject Made by Candidate: ${projectsDetails}\nHiring Manager Name: ${publications}\nWork Experiences of the Candidate: ${workExperienceDetails}\nEducation attained by the Candidate: ${educationDetails}\nTechnical Skills of the Candidate: ${technicalSkills}\nCertificates Won: ${certificates}`,
      };

      //console.log(prompt);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/get_response`,
        prompt,
      );
      setdata(response.data);
      //console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const contentCreate = () => {
    // //console.log("coverletterContent");
    //console.log(coverletterContent);
    setCoverletterContent(!coverletterContent);
    if (!coverletterContent) {
      fetchData();
    }
  };

  const generatePDF = () => {
    const report = new jsPDF("portrait", "pt", "a4");
    report.html(document.querySelector("#report")).then(() => {
      report.save("report.pdf");
    });
  };

  const handleChange = (e) => {
    setdata(e.target.value);
  };

  const generateCV = (e) => {
    e.preventDefault();
    const userData = {
      name: "",
      contact_number: "",
      email_id: "",
      linkedin_url: "",
      github_url: "",
      personal_website: "",
      date: "",
      jobtitle: "",
      cname: "",
      hmname: "",
      caddress: "",
      skills: "",
      experience: "",
      has_resume: "",
      resume_link: "",
      education: "",
      role: "",
      content: "",
      cname: data1.cname,
      hmname: data1.hmname,
      caddress: data1.caddress,
      role: data1.role,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/generate_cv`, userData)
      .then((response) => {
        //console.log(response.status, response.data.token);
      });
  };

  const handleDownload = async () => {
    try {
      var storedData = localStorage.getItem(
        `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`,
      );
      var parsedData = JSON.parse(storedData);
      var userId = parsedData.user.id;
      // //console.log(userId);

      // const apiUrl =
      //   "http://127.0.0.1:8000/update_download_counter/cover_letter";

      const apiUrl = `${process.env.REACT_APP_API_URL}/update_download_counter/cover_letter`;
      const postOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: userId }),
      };

      fetch(apiUrl, postOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        // .then((data) => {
        //   //console.log(data);
        // })
        .catch((error) => {
          console.error("Error:", error);
        });

      //var doc = new jsPDF();
      const doc = new jsPDF({
        orientation: "potrait",
        unit: "in",
        format: "[11.42,8]",
      });
      var elementHTML = document.querySelector(".cover-letter");

      // Change the color of all content inside the element
      // elementHTML.style.color = "black";
      elementHTML.style.padding = "0";
      elementHTML.style.margin = "0";

      await doc.html(elementHTML, {
        callback: function (doc) {
          // Save the PDF
          doc.save("CoverLetter.pdf");
        },
        x: 0,
        y: 0,
        width: 8, //target width in the PDF document
        windowWidth: 590, //window width in CSS pixels
      });

      // doc.save('save.pdf');
    } catch (e) {
      //console.log(e);
    }
  };
  const handleCompanyName = (event) => {
    const newValue1 = event.target.value;
    setCompanyname(newValue1);
    // Limit the input value to a maximum of 30 characters
    if (newValue1.length > 40) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };

  const handleCompanyAddress = (event) => {
    const newValue2 = event.target.value;
    setCompanyaddress(newValue2);
    // Limit the input value to a maximum of 30 characters
    if (newValue2.length > 40) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };

  const handleHiringManagerName = (event) => {
    const newValue3 = event.target.value;
    setHiringManagerName(newValue3);
    // Limit the input value to a maximum of 30 characters
    if (newValue3.length > 40) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };

  const handleRole = (event) => {
    const newValue4 = event.target.value;
    setRole(newValue4);
    // Limit the input value to a maximum of 30 characters
    if (newValue4.length > 40) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };

  const CoverLetterPrepare = () => {
    return (
      <div className="bg-white-A700 border border-blue_gray-100 border-solid flex flex-col items-start justify-start  mt-[47px] p-2.5 shadow-bs w-[595px] sm:w-full">
        {CoverLetterName !== "" ? (
          <CoverLetterToRender {...commonProps} />
        ) : (
          <FallbackTemplate />
        )}
      </div>
    );
  };

  return (
    <>
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <SideBar1 clickedTab={"Cover Letters"} />
          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
            <ProfileHeader clickedTab={"Cover Letter"} />

            <div className="h-[93vh] overflow-scroll bg-white-A700 flex flex-col items-center justify-start rounded-[15px] w-[94%] md:w-full">
              <div className="flex flex-col h-[100px] md:h-auto items-center justify-start max-w-[1078px] pl-[77px] pr-[82px] md:px-10 sm:px-5 py-[30px] w-full">
                <div className="flex md:flex-col flex-row md:gap-10 gap-[70px] items-start justify-start w-auto md:w-full">
                  <div className=" sm:hidden  flex flex-row gap-1 items-center justify-start w-1/4 md:w-full">
                    <Text
                      size="txtInterSemiBold25"
                      className="bg2 flex h-[50px] items-center justify-center rounded-[50%] sm:text-[21px] md:text-[23px] text-[25px] text-center text-white-A700 tracking-[0.50px] w-[50px]"
                    >
                      1
                    </Text>
                    <Text
                      className="text2 text-blue_gray-100 text-xl tracking-[0.50px]"
                      size="txtInterMedium20Bluegray100"
                    >
                      Choose template
                    </Text>
                  </div>

                  <div className=" sm:hidden flex flex-row gap-[19px] items-center justify-center sm:pr-5 pr-[27px] w-[29%] md:w-full">
                    <Text
                      size="txtInterSemiBold25"
                      className="bg2  sm:text-sm  sm:h-[30px] sm:w-[30px] flex h-[50px] items-center justify-center rounded-[50%] sm:text-[21px] md:text-[23px] text-[25px] text-center text-white-A700 tracking-[0.50px] w-[50px]"
                    >
                      2
                    </Text>

                    <Text
                      className=" sm:text-sm  text2 text-blue_gray-100 text-xl tracking-[0.50px]"
                      size="txtInterMedium20Bluegray100"
                    >
                      Enter your details
                    </Text>
                  </div>
                  <div className="flex flex-row gap-5 items-center justify-center pr-5 w-[33%] md:w-full">
                    <Text
                      size="txtInterSemiBold25"
                      className="bg2 sm:text-sm  sm:h-[30px] sm:w-[30px]  sm:text-sm  flex h-[50px] items-center justify-center rounded-[50%] sm:text-[21px] md:text-[23px] text-[25px] text-center text-white-A700 tracking-[0.50px] w-[50px]"
                    >
                      3
                    </Text>
                    <Text
                      className=" sm:text-sm  text2 text-blue_gray-100 text-xl tracking-[0.50px]"
                      size="txtInterMedium20Bluegray100"
                    >
                      Download cover letter
                    </Text>
                  </div>
                </div>
              </div>

              <div className="flex md:flex-col flex-row items-start justify-between w-full">
                <div className="flex md:flex-col flex-row items-center justify-evenly  w-full">
                  <div className="flex flex-col items-start justify-end p-[9px] w-[50%] md:w-full">
                    <Link to="/coverletter">
                      <div className="flex md:flex-1 flex-row gap-[11px] items-center justify-start pr-0.5 pt-0.5 w-[200px] md:w-full">
                        <Img
                          className="h-5  sm:h-[20px] sm:w-[20px] "
                          src="images/img_materialsymbolsborderall2.svg"
                          alt="materialsymbols"
                        />
                        <Text
                          className="leading-[normal] ml-3 md:ml-[0]  text-base text-black-900_7f text-left sm:text-sm "
                          size="txtPoppinsSemiBold24"
                        >
                          Change Template
                        </Text>
                      </div>
                    </Link>

                    <div>
                      <CoverLetterPrepare />
                    </div>
                    <Button
                      className="cursor-pointer flex items-center justify-center mb-1 sm:w-[100px] min-w-[190px] md:ml-[0] ml-[392px] mt-[38px]"
                      rightIcon={
                        <Img
                          className="mt-px ml-4  sm:h-[20px] sm:w-[20px] "
                          src="images/img_octicondownload16.svg"
                          alt="octicon:download-16"
                        />
                      }
                    >
                      <div
                        onClick={handleBothActions}
                        className="font-medium leading-[normal] text-center text-lg  sm:text-sm"
                      >
                        Download
                      </div>
                    </Button>

                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <div className="index">
                        <div style={{ width: "400px", height: "150px" }}>
                          <div>
                            <div>
                              <div
                                className="overlap-group-wrapper"
                                onClick={handleClose}
                              >
                                <div className="overlap-group">
                                  <div className="save">No</div>
                                </div>
                              </div>
                              <button
                                style={{ paddingLeft: "100px" }}
                                className="overlap-wrapper"
                                onClick={handleClose}
                              >
                                <div className="save-wrapper">
                                  <div className="text-wrapper">Yes</div>
                                </div>
                              </button>
                              <DialogContentText
                                id="alert-dialog-description"
                                className="are-you-sure-to-turn"
                              >
                                Your Download should start soon <br />
                                Do you want to replace the uploaded <br />
                                Cover letter on the RecRoid?
                              </DialogContentText>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dialog>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CoverletterDowload;
