import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import NamePhoneFields from "components/NamePhoneFields/NamePhoneFields";
import PropTypes from "prop-types";
import { useState } from "react";

const FormWrapper = ({ className = "" }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    phone: "",
    emailAddress: "",
    company: "",
    requestReason: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const nameParts = formData.fullName.trim().split(" ");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/contact-us`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      setShowPopup(true);

      // Clear form data to reset the form
      setFormData({
        fullName: "",
        phone: "",
        emailAddress: "",
        company: "",
        requestReason: "",
        message: "",
      });

      // Hide popup message after 5 seconds
      setTimeout(() => {
        setShowPopup(false);
      }, 4000);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  return (
    <div className="w-[100%] mqtab1:w-[80%] mqtab2:w-[100%] mqmob1:w-[100%]">
      <form
        className={`w-[100%] shadow-xl p-[3.5rem] mqtab1:pb-[1.4rem] flex flex-col items-start justify-start gap-[73px] max-w-full text-center text-[24px] text-black font-montserrat-alternates mqmob1:gap-[70px] mq1125:gap-[36px] mqtab1:w-[1326px] mqmob1:p[2rem] ${className}`}
        onSubmit={handleSubmit}
      >
        <div className="self-stretch flex flex-col items-start justify-start gap-[17px] max-w-full mqmob1:gap-[8px]">
          <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[35px] max-w-full mqmob1:gap-[30px]">
            <div className="flex-1 flex flex-col items-start justify-start gap-[44px] min-w-[326px] max-w-full mqmob1:gap-[30px]">
              <NamePhoneFields
                fullName="Full Name"
                inputType="text"
                value={formData.fullName}
                onChange={handleChange}
                name="fullName"
                error={errors.fullName}
              />
              <NamePhoneFields
                fullName="Phone"
                propMinWidth="81px"
                propDisplay="inline-block"
                inputType="tel"
                pattern="\d*"
                value={formData.phone}
                onChange={handleChange}
                name="phone"
                error={errors.phone}
              />
            </div>
            <div className="flex-1 flex flex-col items-start justify-start gap-[44px] min-w-[326px] max-w-full mqmob1:gap-[30px]">
              <NamePhoneFields
                fullName="Email Address"
                propMinWidth="unset"
                propDisplay="unset"
                inputType="email"
                value={formData.emailAddress}
                onChange={handleChange}
                name="emailAddress"
                error={errors.emailAddress}
              />
              <NamePhoneFields
                fullName="Current Company (Optional)"
                propMinWidth="unset"
                propDisplay="unset"
                inputType="text"
                value={formData.company}
                onChange={handleChange}
                name="company"
              />
            </div>
          </div>
          <div className="flex-1 flex flex-col items-start justify-start gap-[44px] w-full mqmob1:gap-[30px]">
            <div
              className={`self-stretch h-28 flex flex-col items-start justify-start gap-[17px] text-center text-5xl text-black font-montserrat-alternates mqtab1:h-[6rem] mqtab2:h-[6rem] mqmob1:h-[6rem] ${className}`}
            >
              <div className="flex flex-row items-start justify-start">
                <div className="text-[23.5px] w-[120px]">
                  Reason
                </div>
                <div className="flex flex-col items-start justify-start ml-[10px] pt-[11px] px-0 pb-0">
                  <div className="w-[7px] h-[7px] relative rounded-[50%] bg-crimson" />
                </div>
              </div>
              <select
                name="requestReason" // Added name attribute
                className="h-[55px] border-[1px] border-solid border-darkgray w-full rounded-3xs"
                value={formData.requestReason} // Controlled component
                onChange={handleChange} // Handler to update state
              >
                <option value="" disabled>Select a reason</option>
                <option value="data-deletion">Data Deletion Request</option>
                <option value="career">Career/Jobs</option>
                <option value="complaint">Complaint</option>
                <option value="feedback">Feedback</option>
                <option value="demo">Demo</option>
                <option value="business">Business</option>
                <option value="others">Others</option>
              </select>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[21px] max-w-full text-xl-6 mt-[38px] mqmob1:mt[20px]">
            <div className="flex flex-row items-start justify-start gap-[6px]">
              <div className="relative leading-[29px] font-medium mq450:text-lgi mq450:leading-[23px] text-[23.5px]">
                Your Message
              </div>
              <div className="flex flex-col items-start justify-start pt-[11px] px-0 pb-0">
                <div className="w-[7px] h-[7px] relative rounded-[50%] bg-crimson" />
              </div>
            </div>
            <textarea
              className="self-stretch h-[147px] relative rounded-3xs bg-whitesmoke box-border border-[1px] border-solid border-darkgray p-[2rem] pt-[1.5rem] text-[20px] font-thin leading-[1.8rem] tracking-normal"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
            <div className="relative leading-[30px] mt-[10px] font-medium inline-block max-w-full mq450:text-base mq450:leading-[20px]">
              <span className="mqmob1:text-[18px]">
                {`By submitting your information, you agree to our `}
              </span>
              <a href="/policies">
                <span className="[text-decoration:underline] text-darkslateblue mqmob1:text-[18px]">
                  Privacy Policy
                </span>
              </a>
              <span className="mqmob1:text-[18px]"> and</span>
              <a href="/terms-of-use">
                <span className="[text-decoration:underline] text-darkslateblue mqmob1:text-[18px]">
                  {" "}
                  Terms of Use.
                </span>
              </a>
            </div>
          </div>
        </div>
        <div
          className="flex flex-row items-start justify-center py-0 px-5 box-border max-w-full mqmob1:mt-[2rem]"
          style={{ width: "inherit" }}
        >
          <button
            className="cursor-pointer [border:none] py-2 pr-[50px] pl-[50px] bg-darkslateblue shadow-[0px_23px_29.6px_-33px_#314ca3] rounded-86xl-7 flex flex-row items-start justify-start hover:bg-cornflowerblue"
            type="submit"
          >
            <div className="relative text-[25.4px] leading-[53px] font-medium font-poppins text-white text-center inline-block min-w-[96px] z-[1] mq450:text-[21px] mq450:leading-[42px] mqmob1:text-[21px] mqmob1:leading-[42px] text-[#ffff]">
              Submit
            </div>
          </button>
        </div>
        {showPopup && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-[#3ABA6F] shadow-lg p-6 w-1/4 absolute rounded-[15px] flex flex-col justify-center items-center border-[2px] border-solid border-[#000000] p-[29px] mqlap:w-[37%] mqtab1:w-[34%] mqtab2:w-[43%] mqtab2:p-[20px] mqmob1:w-[40%] mqmob1:p-[15px]">
              <CheckCircleOutlineIcon
                sx={{ height: "44px", width: "44px", color: "white" }}
              />
              <div className="text-[37px] p-[10px] text-[#fff] align-center mqtab1:text-[32px] mqtab2:text-[27px] mqmob1:text-[20px]">
                Your message has been sent successfully!
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

FormWrapper.propTypes = {
  className: PropTypes.string,
};

export default FormWrapper;
