import PropTypes from "prop-types";
import { useMemo, useState } from "react";

const FrameComponent5 = ({
  className = "",
  selectTime,
  uiwdate,
  propHeight,
  propWidth,
  onChange,
}) => {
  const [minTime, setMinTime] = useState(new Date().toTimeString().slice(0, 5));

  const uiwdateIconStyle = useMemo(() => {
    return {
      height: propHeight,
      width: propWidth,
    };
  }, [propHeight, propWidth]);

  const handleTimeChange = (e) => {
    const time = e.target.value;
    setMinTime(time); // Update the minTime state with the selected time
    onChange(time); // Call onChange with the selected time
  };

  return (
    <div
      className={`flex-1 flex flex-col items-start justify-start gap-[17px] min-w-[326px] max-w-full text-center text-[24px] text-black font-montserrat-alternates ${className}`}
    >
      <div className="flex flex-row items-start justify-start gap-[6px]">
        <div className="relative leading-[29px] font-medium z-[1] mq450:text-lgi mq450:leading-[23px]">
          {selectTime}
        </div>
        <div className="flex flex-col items-start justify-start pt-[11px] px-0 pb-0">
          <div className="w-[7px] h-[7px] relative rounded-[50%] bg-crimson z-[1]" />
        </div>
      </div>
      <input
        min={minTime}
        className="h-[70px] mqtab1:h-[3.5rem] mqtab2:h-[3.2rem] mqmob1:h-[3.2rem] w-[501px] relative rounded-3xs bg-whitesmoke box-border max-w-full border-[1px] border-solid border-darkgray text-[19px] pl-[1.3rem]"
        type="time"
        onChange={handleTimeChange} // Ensure onChange handler is set correctly
      />
    </div>
  );
};

FrameComponent5.propTypes = {
  className: PropTypes.string,
  selectTime: PropTypes.string,
  uiwdate: PropTypes.string,
  propHeight: PropTypes.any,
  propWidth: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

export default FrameComponent5;
