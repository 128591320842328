const initialState = {
    careerData: [], // Initial state for career data
    loading: false, // Loading state for data fetching
    error: null, // Error state for handling fetch errors
  };
  
  const careerReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_CAREER_ADVICE_REQUEST':
        return {
          ...state,
          loading: true,
          error: null,
        };
      case 'FETCH_CAREER_ADVICE_SUCCESS':
        return {
          ...state,
          careerData: action.payload,
          loading: false,
          error: null,
        };
      case 'FETCH_CAREER_ADVICE_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default careerReducer;
  