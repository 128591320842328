const initialState={
    jobSearchCount: null,
  liveInterviewCount: null,
  textInterviewCount: null,
  careerAdviceCount: null,
  autoJobCount: null,
  coverLetterCount: null,
  resumeBuilderCount: null,
  loading: true,
  error: null,
};
const jobSeekerDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_JOB_SEEKER_DASHBOARD_COUNTS_REQUEST':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'FETCH_JOB_SEEKER_DASHBOARD_COUNTS_SUCCESS':
            return {
                ...state,
                jobSearchCount: action.payload.job_search_counts,
                liveInterviewCount: action.payload.live_interview_counts,
                textInterviewCount: action.payload.text_interview_counts,
                careerAdviceCount: action.payload.career_advice_counts,
                autoJobCount: action.payload.autojob_cnt,
                coverLetterCount: action.payload.cover_letter_cnt,
                resumeBuilderCount: action.payload.resume_cnt,
                loading: false,
                error: null,
            };
        case 'FETCH_JOB_SEEKER_DASHBOARD_COUNTS_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
export default jobSeekerDashboardReducer;