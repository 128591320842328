import { Img, Text } from "components";
import { ProfileHeader } from "pages/JobSeekerDashBoardPages/ProfileHeader";
import { SideBar1 } from "pages/JobSeekerDashBoardPages/SideBar1";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./index.css";

const ResumeBuilder = () => {
  const typesOfResumeBuilders = [
    {
      id: 1,
      typesOfResume: "All Templates",
      selectImageUrl: "images/img_materialsymbolsborderall.svg",
      unselectImageUrl: "images/img_materialsymbolsborderall2.svg",
    },
    {
      id: 2,
      typesOfResume: "Simple",
      selectImageUrl: "images/img_phfiresimplefill.svg",
      unselectImageUrl: "images/img_phfiresimplefill2.svg",
    },
    {
      id: 3,
      typesOfResume: "Creative",
      selectImageUrl: "images/img_mdiunicornvariant.svg",
      unselectImageUrl: "images/img_mdiunicornvariant2.svg",
    },
    {
      id: 4,
      typesOfResume: "Professional",
      selectImageUrl: "images/img_basilbagsolid.svg",
      unselectImageUrl: "images/img_basilbagsolid2.svg",
    },
    {
      id: 5,
      typesOfResume: "Modern",
      selectImageUrl: "images/img_bistars.svg",
      unselectImageUrl: "images/img_bistars2.svg",
    },
  ];

  const resumeTemplatesList = [
    {
      id: 1,
      categeryType: "Creative",
      imageUrl: "resumeBuilder/Resume1.png",
    },
    {
      id: 2,
      categeryType: "Creative",
      imageUrl: "resumeBuilder/Resume2.png",
    },
    {
      id: 3,
      categeryType: "Creative",
      imageUrl: "resumeBuilder/Resume3.png",
    },
    {
      id: 4,
      categeryType: "Simple",
      imageUrl: "resumeBuilder/Resume4.png",
    },
    {
      id: 5,
      categeryType: "Simple",
      imageUrl: "resumeBuilder/Resume5.png",
    },
    {
      id: 6,
      categeryType: "Simple",
      imageUrl: "resumeBuilder/Resume6.png",
    },
    {
      id: 7,
      categeryType: "Modern",
      imageUrl: "resumeBuilder/Resume7.png",
    },
    {
      id: 8,
      categeryType: "Modern",
      imageUrl: "resumeBuilder/Resume8.png",
    },
    {
      id: 9,
      categeryType: "Modern",
      imageUrl: "resumeBuilder/Resume9.png",
    },
    {
      id: 10,
      categeryType: "Professional",
      imageUrl: "resumeBuilder/Resume10.png",
    },
    {
      id: 11,
      categeryType: "Professional",
      imageUrl: "resumeBuilder/Resume11.png",
    },
    {
      id: 12,
      categeryType: "Professional",
      imageUrl: "resumeBuilder/Resume12.png",
    },
    {
      id: 13,
      categeryType: "Professional",
      imageUrl: "resumeBuilder/Resume13.png",
    },
    {
      id: 14,
      categeryType: "Professional",
      imageUrl: "resumeBuilder/Resume14.png",
    },
    {
      id: 15,
      categeryType: "Professional",
      imageUrl: "resumeBuilder/Resume15.png",
    },
    {
      id: 16,
      categeryType: "Professional",
      imageUrl: "resumeBuilder/Resume16.png",
    },
    {
      id: 17,
      categeryType: "Professional",
      imageUrl: "resumeBuilder/Resume17.png",
    },
    {
      id: 18,
      categeryType: "Professional",
      imageUrl: "resumeBuilder/Resume18.png",
    },
    {
      id: 19,
      categeryType: "Professional",
      imageUrl: "resumeBuilder/Resume19.png",
    },
    {
      id: 20,
      categeryType: "Professional",
      imageUrl: "resumeBuilder/Resume20.png",
    },
  ];

  const [selectedTemplates, setSelectedTemplates] = useState([
    ...resumeTemplatesList,
  ]);
  const [savedTemplate, updatetemplate] = useState("All Templates");

  const resumeType = (type) => {
    updatetemplate(type);

    if (type === "All Templates") {
      setSelectedTemplates([...resumeTemplatesList]);
    } else {
      const filteredTemplates = resumeTemplatesList.filter(
        (each) => each.categeryType === type,
      );
      setSelectedTemplates([...filteredTemplates]);
    }
  };

 
  return (
    <>
      <div className="bg-3 bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full h-[100vh]">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <SideBar1 clickedTab={"Resume Builder"} />
          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
            <ProfileHeader clickedTab={"Resume Builder"} />

            <div className="h-[93vh] main-container bg-white-A700 flex flex-col items-center justify-start rounded-[15px] w-[94%] md:w-full ">
              <div className="flex  flex-col h-[100px] md:h-auto items-center justify-start max-w-[1078px] pl-[77px] pr-[82px] md:px-10 sm:px-5 py-[30px] w-full">
                <div className="flex sm:flex-col flex-row md:gap-10 gap-[70px] items-center justify-center w-auto md:w-full mqtab1:gap-[32px] mqlap:gap-[43px]">
                  <div className="flex flex-row gap-5 items-center justify-start sm:justify-center  w-1/4 md:w-full">
                    <Text
                      size="txtInterSemiBold25"
                      className="bg2 flex sm:h-[30px] sm:w-[30px]  h-[50px] items-center justify-center rounded-[50%] sm:text-[16px] md:text-[23px] text-[25px] text-center text-white-A700 tracking-[0.50px] w-[65px] mqlap:text-[21px] mqlap:h-[46px] mqlap:w-[54px] mqtab1:text-[18px] mqtab1:h-[39.5px] mqtab1:w-[47px]"
                    >
                      1
                    </Text>
                    <Text
                      className="text2 text-blue-A200 text-xl tracking-[0.50px] sm:text-[16px] mqtab1:text-[1.18vw] mqlap:text-[1.15vw] mqtab1:leading-[1.4rem]  mqlap:leading-[1.4rem]  mqlap:leading-[1.5rem]"
                      size="txtInterMedium20"
                    >
                      Choose template
                    </Text>
                  </div>
                  <div className="sm:hidden flex flex-row gap-[19px] items-center justify-center sm:pr-5 w-1/3 md:w-full">
                    <Text
                      size="txtInterSemiBold25"
                      className="bg3  flex h-[50px] items-center justify-center rounded-[50%] sm:text-[21px] md:text-[23px] text-[25px] text-center text-white-A700 tracking-[0.50px] w-[52px] mqlap:text-[21px] mqlap:h-[46px] mqlap:w-[47px] mqtab1:text-[18px] mqtab1:h-[40px] mqtab1:w-[43px]"
                    >
                      2
                    </Text>

                    <Text
                      className="text3 text-blue_gray-100 text-xl tracking-[0.50px] mqtab1:text-[1.18vw] mqlap:text-[1.15vw] mqtab1:leading-[1.4rem]  mqlap:leading-[1.5rem]"
                      size="txtInterMedium20Bluegray100"
                    >
                      Enter your details
                    </Text>
                  </div>
                  <div className="sm:hidden flex flex-row gap-5 items-center justify-end w-1/3 md:w-full">
                    <Text
                      size="txtInterSemiBold25"
                      className="bg3 flex h-[50px] items-center justify-center rounded-[50%] sm:text-[21px] md:text-[23px] text-[25px] text-center text-white-A700 tracking-[0.50px] w-[60px] mqlap:text-[21px] mqlap:h-[46px] mqlap:w-[52px] mqtab1:text-[18px] mqtab1:h-[40px] mqtab1:w-[46px]"
                    >
                      3
                    </Text>
                    <Text
                      className="text3 text-blue_gray-100 text-xl tracking-[0.50px] mqtab1:text-[1.18vw] mqlap:text-[1.15vw] mqtab1:leading-[1.4rem] mqlap:leading-[1.5rem]"
                      size="txtInterMedium20Bluegray100"
                    >
                      Download your Resume
                    </Text>
                  </div>
                </div>
              </div>
              <div className="flex flex-col font-poppins items-center justify-start w-full">
                <div className="flex flex-col items-center justify-start w-full">
                  <div className="mt-[40px] flex flex-col items-center justify-start p-[11px] w-full">
                    <div className="flex  flex-row md:gap-10 sm:gap-[30px] gap-[60px] items-start justify-center  mb-[15px] mt-[5px] w-full sm:overflow-x-auto   sm:justify-start scrollbar-hidden-personal mqlap:gap[45px] mqtab1:gap-[45px]">
                      {typesOfResumeBuilders.map((each) => (
                        <div
                          key={each.id}
                          onClick={() => resumeType(each.typesOfResume)}
                          className="sm:w-[400px] cursor-pointer flex md:flex-1 flex-row gap-2.5 items-center justify-start pr-0.5 pt-0.5  md:w-full"
                        >
                          <Img
                            className="h-5 mqtab1:h-4  mqlap:h-[1.2rem]"
                            src={
                              savedTemplate === each.typesOfResume
                                ? each.selectImageUrl
                                : each.unselectImageUrl
                            }
                            alt="materialsymbols"
                          />
                          <Text
                            className={`text-2xl md:text-[22px] mqtab1:text-[1.3vw] mqlap:text-[1.3vw] ${
                              savedTemplate === each.typesOfResume
                                ? "text-black-900"
                                : "text-black-900_7f"
                            } ${
                              each.typesOfResume === "All Templates"
                                ? " sm:w-[122px]"
                                : ""
                            }  sm:text-sm`}
                            size={
                              savedTemplate === each.typesOfResume
                                ? "txtPoppinsSemiBold24"
                                : "txtPoppinsSemiBold24Black9007f"
                            }
                          >
                            {each.typesOfResume}
                          </Text>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="flex flex-col items-center justify-start w-[97%] pb-4">
                    <div className="flex flex-col items-center justify-start w-full">
                      <div className="gap-2 mb-[20px] md:gap-5 grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 justify-center min-h-[auto] w-full">
                        {selectedTemplates.map((each) => (
                          <div className="p-3 bg-white-A700 border-[0.5px] border-[#6d69697f] border-solid flex flex-1 flex-col items-center justify-center rounded-[12px] w-full">
                            <Link to={"/resume-details?imageId=" + each.id}>
                              <div className="test sm:h-full mt-3   object-cover w-auto h-[80%] md:w-full">
                                <Img src={each.imageUrl} alt="imageOne" />
                              </div>
                            </Link>
                            <Text
                              className="mt-[32px] text-center text-base text-gray-900_02"
                              size="txtInterRegular16"
                            >
                              {each.templateText}
                            </Text>
                            {/* <div className="flex flex-row items-center justify-between mb-[18px] mt-6 w-[90%] md:w-full">
                              <div className="cursor-pointer flex flex-row gap-[5px] items-center justify-center p-1.5 w-[24%]">
                                <Img
                                  className="h-3.5 w-3.5"
                                  src={
                                    each.isLiked
                                      ? "images/img_redheart.svg"
                                      : "images/img_favorite.svg"
                                  }
                                  alt="favorite"
                                  onClick={() => likeButton(each.id)}
                                />
                                <Text
                                  className="text-gray-900_02 text-sm"
                                  size="txtInterRegular14"
                                >
                                  {each.likes}
                                </Text>
                              </div>
                              <div className="flex flex-row items-center justify-center p-1.5 w-[28%]">
                                <Text
                                  className="ml-[3px] text-gray-900_02 text-sm"
                                  size="txtInterRegular14"
                                >
                                  Share
                                </Text>
                                <Img
                                  className="h-3.5 mr-1 w-3.5"
                                  src="images/img_reply.svg"
                                  alt="reply"
                                />
                              </div>
                            </div> */}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResumeBuilder;
