import react from "react";

const FallbackTemplate = () => {

  return (
    <div className="h-[681px] md:h-auto object-cover w-[480px]">
      {/* <h1>Fallback Template</h1> */}
    </div>
  );
};

export default FallbackTemplate;
