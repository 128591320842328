import { Dialog, Tooltip } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import { getProfileUploadLinks } from "actions/ProfileUploadLinks";
import { data } from "autoprefixer";
import axios from "axios";
import { Button, Img, Text } from "components";
import FallbackTemplate from "components/CoverletterTemplates/FallbackTemplate";
import jsPDF from "jspdf";
import mammoth from "mammoth";
import { ProfileHeader } from "pages/JobSeekerDashBoardPages/ProfileHeader";
import { SideBar1 } from "pages/JobSeekerDashBoardPages/SideBar1";
import { PDFDocument } from "pdf-lib";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import BeatLoader from "react-spinners/ClipLoader";
import { TextArea } from "semantic-ui-react";
import getData from "./getData";
import "./index.css";

const CoverLetterDetails = () => {
  const [open, setOpen] = useState(false);
  const [coverletterContent, setCoverletterContent] = useState("");
  const [data1, setdata] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [companyaddress, setCompanyaddress] = useState("");
  const [hiringManagerName, setHiringManagerName] = useState("");
  const [role, setRole] = useState("");
  const [CoverLetterToRender, setCoverLetterToRender] = useState(null);
  const [CoverLetterName, setCoverLetterName] = useState("");
  const [awards, setAwards] = useState("");
  const [pdfFile, setPdfFile] = useState(null);
  const [courses, setCourses] = useState("");
  const [projects, setProjects] = useState("");
  const [publications, setPublications] = useState("");
  const [workExperience, setWorkExperience] = useState("");
  const [education, setEducation] = useState("");
  const [technicalSkills, setTechnicalSkills] = useState("");
  const [certificates, setCertificates] = useState("");
  const [educationDetails, setEducationDetails] = useState("");
  const [projectsDetails, setProjectsDetails] = useState("");
  const [workExperienceDetails, setWorkExperienceDetails] = useState("");
  // const [inputValue, setInputValue] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [fileText, setFileText] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const imageId = queryParams.get("imageId");
  const dispatch = useDispatch();
  const commonProps = {
    companyname,
    companyaddress,
    role,
    hiringManagerName,
    coverletterContent: data1,
  };
  useEffect(() => {
    const loadComponent = async () => {
      try {
        const importedComponent = await import(
          `components/CoverletterTemplates/template${imageId}`
        );
        setCoverLetterToRender(() => importedComponent.default);
        setCoverLetterName(imageId);
        // //console.log(`pages/CoverLetterTemplates/template${imageId}`)
      } catch (error) {
        console.error("Error importing component:", error);
        setCoverLetterToRender(null);
      }
    };

    if (CoverLetterName === "") {
      loadComponent();
    }

    const fetchData = async () => {
      const data = await getData();

      setAwards(data["awards"].toString());
      setCourses(data["courses"].toString());
      setProjects(data["projects"].toString());
      setPublications(data["publications"].toString());
      setWorkExperience(data["work_experience"].toString());
      setEducation(data["education"].toString());
      setTechnicalSkills(data["technical_skills"].toString());
      setCertificates(data["certificates"].toString());
    };
    fetchData(data);
  }, []);

  const updateStoredResume = async (pdfFile) => {
    try {
      var storedData = localStorage.getItem(
        `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`,
      );
      var parsedData = JSON.parse(storedData);
      var userId = parsedData.user.id;

      const formData = new FormData();
      formData.append("cv_file", pdfFile);

      const apiUrl = `${process.env.REACT_APP_API_URL}/cv_parser_via_upload?user_id=${userId}`;
      const requestOptions = {
        method: "POST",
        body: formData,
      };

      const res = await fetch(apiUrl, requestOptions);

      if (!res.ok) {
        const errorText = await res.text();
        console.error("Error fetching data:", res.status, errorText);
        // Handle the error or throw an exception as needed
      } else {
        const data = await res.json();
        //console.log("Data from resume parser", data);
        dispatch(getProfileUploadLinks(userId));
      }
    } catch (error) {
      console.error("Error updating stored resume:", error);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType === "application/pdf") {
        const text = await parsePdf(file);
        setFileText(text);
      } else if (
        fileType === "application/msword" ||
        fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        const text = await parseDoc(file);
        setFileText(text);
        //console.log(fileText);
      } else {
        alert("Please upload a PDF or DOC file.");
      }
    }
  };

  const parsePdf = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);
    const textArray = await Promise.all(
      pdfDoc
        .getPages()
        .map(async (page) =>
          page
            .getTextContent()
            .then((content) => content.items.map((item) => item.str).join(" ")),
        ),
    );
    return textArray.join(" ");
  };

  const parseDoc = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const result = await mammoth.extractRawText({ arrayBuffer });
    return result.value;
  };

  const fetchData = async () => {
    try {
      const data = await getData();
      //console.log("Data from function", data);

      // Convert arrays to JSON strings
      setAwards(JSON.stringify(data["awards"]));
      setCourses(JSON.stringify(data["courses"]));
      setProjects(JSON.stringify(data["projects"]));
      setPublications(JSON.stringify(data["publications"]));
      setWorkExperience(JSON.stringify(data["work_experience"]));
      setEducation(JSON.stringify(data["education"]));

      // Extract education, projects, and workExperience data separately
      const educationData = data["education"].map(
        (edu) => `${edu.institution} ${edu.degree} ${edu.percentage}`,
      );
      const projectsData = data["projects"].map(
        (project) =>
          `${project.projectName} ${project.projectDescription} ${project.technologiesUsed} ${project.projectLink}`,
      );
      const workExperienceData = data["work_experience"].map(
        (work) => `${work.company} ${work.position} ${work.description}`,
      );

      // Join the extracted data and set in the state
      const educationDetails = educationData.join("\n");
      const projectsDetails = projectsData.join("\n");
      const workExperienceDetails = workExperienceData.join("\n");

      // Set the state variables
      setEducationDetails(educationDetails);
      setProjectsDetails(projectsDetails);
      setWorkExperienceDetails(workExperienceDetails);
      setdata("");
      // Create the prompt using the stringified data
      const prompt = {
        prompt: `You are a talented AI Coverletter generator, and your task is to create a professional cover letter for a job seeker within 200 words for the undergiven job description. Exclude salutations, addresses, personal details and closing part(Yours Sincerely, Regards etc.) in the response. Only give the main paragraphs of the letter. Use the provided information below to craft the paragraphs.\n\nCompany: ${companyname}\nRecruiting Company Address: ${companyaddress}\nRole: ${role}\nHiring Manager Name: ${hiringManagerName}\n Awards Won by Candidate: ${awards}\nCourses completed by Candidate: ${courses}\nProject Made by Candidate: ${projectsDetails}\nHiring Manager Name: ${publications}\nWork Experiences of the Candidate: ${workExperienceDetails}\nEducation attained by the Candidate: ${educationDetails}\nTechnical Skills of the Candidate: ${technicalSkills}\nCertificates Won: ${certificates}. 
        
        Job Description : ${fileText}`,
      };

      //console.log(prompt);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/get_response`,
        prompt,
      );
      setdata(response.data);
      //console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const contentCreate = () => {
    // //console.log("coverletterContent");
    //console.log(coverletterContent);
    setCoverletterContent(!coverletterContent);
    if (!coverletterContent) {
      fetchData();
    }
  };

  // const generatePDF = () => {
  //   const report = new jsPDF("portrait", "pt", "a4");
  //   report.html(document.querySelector("#report")).then(() => {
  //     report.save("report.pdf");
  //   });
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setdata(e.target.value);
  };

  // const generateCV = (e) => {
  //   e.preventDefault();
  //   const userData = {
  //     name: "",
  //     contact_number: "",
  //     email_id: "",
  //     linkedin_url: "",
  //     github_url: "",
  //     personal_website: "",
  //     date: "",
  //     jobtitle: "",
  //     cname: "",
  //     hmname: "",
  //     caddress: "",
  //     skills: "",
  //     experience: "",
  //     has_resume: "",
  //     resume_link: "",
  //     education: "",
  //     role: "",
  //     content: "",
  //     cname: data1.cname,
  //     hmname: data1.hmname,
  //     caddress: data1.caddress,
  //     role: data1.role,
  //   };
  //   axios
  //     .post(`${process.env.REACT_APP_API_URL}/generate_cv`, userData)
  //     .then((response) => {
  //       //console.log(response.status, response.data.token);
  //     });
  // };

  const handleDownload = async () => {
    try {
      var storedData = localStorage.getItem(
        `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`,
      );
      var parsedData = JSON.parse(storedData);
      var userId = parsedData.user.id;

      const apiUrl = `${process.env.REACT_APP_API_URL}/update_download_counter/cover_letter`;
      const postOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: userId }),
      };
      fetch(apiUrl, postOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          // //console.log(data); // remove this line
        })
        .catch((error) => {
          console.error("Error:", error);
        });

      const doc = new jsPDF({
        orientation: "p",
        unit: "in",
        format: [11.42, 8],
      });
      var elementHTML;
      console.log("imageid: ", imageId);
      if (imageId === "1") {
        elementHTML = document.querySelector(".content_2");
      } else {
        elementHTML = document.querySelector(".cover-letter");
      }

      // Change the color of all content inside the element
      elementHTML.style.padding = "0";
      elementHTML.style.margin = "0";
      // elementHTML.style.color = "black";

      const pdfBlob = await new Promise((resolve) => {
        doc.html(elementHTML, {
          callback: function (doc) {
            // Resolve with the Blob representing the PDF
            resolve(doc.output("blob"));
          },
          x: 0,
          y: 0,
          width: 8, //target width in the PDF document
          windowWidth: 590, //window width in CSS pixels
        });
      });
      // Save the PDF
      doc.save("Cover_letter.pdf");

      // Create a File object from the Blob
      const pdfFile = new File([pdfBlob], "Cover_letter.pdf", {
        type: "application/pdf",
      });

      // Trigger download

      setPdfFile(pdfFile);
      // Return the File object
      return pdfFile;
    } catch (e) {
      //console.log(e);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleBothActions = async () => {
    handleClickOpen();
    await handleDownload();
  };

  const handleYesOptionClick = async () => {
    try {
      handleClose(); // Close the dialog window
      if (pdfFile) {
        await updateStoredResume(pdfFile); // Call updateStoredResume to update the stored resume
      } else {
        console.error("No PDF file available to update stored resume");
      }
    } catch (error) {
      console.error("Error handling yes option click:", error);
    }
  };
  const handleCompanyName = (event) => {
    const newValue1 = event.target.value;
    setCompanyname(newValue1);
    // Limit the input value to a maximum of 30 characters
    if (newValue1.length > 40) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };

  const handleCompanyAddress = (event) => {
    const newValue2 = event.target.value;
    setCompanyaddress(newValue2);
    // Limit the input value to a maximum of 30 characters
    if (newValue2.length > 40) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };

  const handleHiringManagerName = (event) => {
    const newValue3 = event.target.value;
    setHiringManagerName(newValue3);
    // Limit the input value to a maximum of 30 characters
    if (newValue3.length > 40) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };

  const handleRole = (event) => {
    const newValue4 = event.target.value;
    setRole(newValue4);
    // Limit the input value to a maximum of 30 characters
    if (newValue4.length > 40) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };

  const CoverLetterPrepare = () => {
    return (
      <div
        className="bg-white-A700 relative  flex flex-col items-center justify-center  mt-[47px] p-2.5 shadow-bs w-full sm:w-full"
        id="matter"
      >
        <div className="border border-blue_gray-100 border-solid w-[100%]">
        {CoverLetterName !== "" ? (
          <CoverLetterToRender {...commonProps}   />
        ) : (
          <FallbackTemplate />
        )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <SideBar1 clickedTab={"Cover Letters"} />
          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
            <ProfileHeader clickedTab={"Cover Letter"} />

            <div className="h-[93vh] overflow-scroll bg-white-A700 flex flex-col items-center justify-start rounded-[15px] w-[94%] md:w-full">
              <div className="flex flex-col h-[100px] md:h-auto items-center justify-start max-w-[1078px] pl-[77px] pr-[82px] md:px-10 sm:px-5 py-[30px] w-full">
                <div className="flex md:flex-col flex-row md:gap-10 gap-[85px] mqlap:gap-[50px] mqtab1:gap-[45px] items-start justify-start w-auto md:w-full">
                  <div className=" sm:hidden  flex flex-row gap-5 items-center justify-start w-[20%] md:w-full">
                    <Text
                      size="txtInterSemiBold25"
                      className="bg2 flex h-[46px] items-center justify-center rounded-[50%] sm:text-[21px] md:text-[23px] text-[25px] text-center text-white-A700 tracking-[0.50px] w-[75px] mqlap:text-[21px] mqlap:h-[41px] mqlap:w-[55px] mqtab1:text-[18px] mqtab1:h-[38px] mqtab1:w-[55px]"
                    >
                      1
                    </Text>
                    <Text
                      className="text2 text-blue_gray-100 text-xl tracking-[0.50px] mqtab1:text-[1.18vw] mqlap:text-[1.15vw] mqtab1:leading-[1.35rem] mqlap:leading-[1.5rem]"
                      size="txtInterMedium20Bluegray100"
                    >
                      Choose template
                    </Text>
                  </div>

                  <div className=" flex flex-row gap-5 items-center justify-center sm:pr-5 pr-[27px] w-[25%] md:w-full">
                    <Text
                      size="txtInterSemiBold25"
                      className="bg2  sm:text-sm  sm:h-[30px] sm:w-[30px] flex h-[46px] items-center justify-center rounded-[50%] sm:text-[21px] md:text-[23px] text-[25px] text-center text-white-A700 tracking-[0.50px] w-[70px] mqlap:text-[21px] mqlap:h-[41px] mqlap:w-[52px] mqtab1:text-[18px] mqtab1:h-[38px] mqtab1:w-[50px]"
                    >
                      2
                    </Text>

                    <Text
                      className=" sm:text-sm  text2 text-blue_gray-100 text-xl tracking-[0.50px] mqtab1:text-[1.18vw] mqlap:text-[1.15vw] mqtab1:leading-[1.35rem] mqlap:leading-[1.5rem]"
                      size="txtInterMedium20Bluegray100"
                    >
                      Enter your details
                    </Text>
                  </div>
                  <div className=" sm:hidden flex flex-row gap-5 items-center justify-center pr-5 w-[25%] md:w-full">
                    <Text
                      size="txtInterSemiBold25"
                      className="bg2  sm:text-sm  flex h-[46px] items-center justify-center rounded-[50%] sm:text-[21px] md:text-[23px] text-[25px] text-center text-white-A700 tracking-[0.50px] w-[75px] mqlap:text-[21px] mqlap:h-[41px] mqlap:w-[60px] mqtab1:text-[18px] mqtab1:h-[38px] mqtab1:w-[58px]"
                    >
                      3
                    </Text>
                    <Text
                      className=" sm:text-sm  text2 text-blue_gray-100 text-xl tracking-[0.50px] mqtab1:text-[1.18vw] mqlap:text-[1.15vw] mqtab1:leading-[1.35rem] mqlap:leading-[1.5rem]"
                      size="txtInterMedium20Bluegray100"
                    >
                      Download cover letter
                    </Text>
                  </div>
                </div>
              </div>

              <div className="flex md:flex-col flex-row items-start justify-center w-[94%] gap-[37px] ">
                {/* <div className="flex md:flex-col flex-row items-center justify-evenly  w-full"> */}
                  <div
                    id="content"
                    className="bg-white-A700 flex flex-col items-start justify-end pr-[13px] py-[13px]  w-[50%] md:w-50% sm:w-[95%]"
                  >
                    <div className="flex flex-col font-poppins sm:gap-5 gap-8 items-start justify-start mt-[26px] pb-[0px]  pl-[0px] w-full">
                      <div className="flex flex-col items-start justify-start ml-1.5 md:ml-[0]">
                        <Text
                          className="text-2xl md:text-[22px] text-black-900_7f sm:text-sm mqlap:text-[1.32rem] mqtab1:text-[1.17rem]"
                          size="txtPoppinsSemiBold24"
                        >
                          Details
                        </Text>
                      </div>
                      <div className=" flex sm:flex-col flex-row font-inter  items-start justify-start ml-1.5 md:ml-[0] w-full sm:w-full gap-[15px]">
                        <div className="flex flex-col gap-[19px] items-center justify-start w-1/2 sm:w-full">
                          <div className="flex flex-col sm:gap-1.5 gap-[13px] items-start justify-start w-full">
                            <Text
                              className="text-base text-blue_gray-400  sm:text-[12px] mqlap:text-[0.9rem] mqtab1:text-[0.85rem]"
                              size="txtInterRegular16"
                            >
                              Company Name
                            </Text>
                            <input
                              required
                              value={companyname}
                              onChange={handleCompanyName}
                              name="Amazon"
                              placeholder="Amazon"
                              className="leading-[normal] placeholder-gray-300  border border-gray-300  rounded-[12px]  text-[15px] mqlap:text-[13.6px] mqtab1:text-[12.7px] text-left w-full  p-3  sm:text-[10px] mqlap:py-[0.68rem] mqtab1:py-[0.58rem] mqtab1:rounded-[10px]"
                              wrapClassName="w-full border border-gray-300 border-solid w-full "
                              maxLength={41} // Set the maximum length
                            />
                          </div>
                          <div className="flex flex-col sm:gap-1.5 gap-3.5 items-start justify-start w-full">
                            <Text
                              className="text-base text-blue_gray-400 sm:text-[12px] mqlap:text-[0.9rem] mqtab1:text-[0.85rem]"
                              size="txtInterRegular16"
                            >
                              Company Address
                            </Text>
                            <input
                              required
                              value={companyaddress}
                              onChange={handleCompanyAddress}
                              maxLength={41}
                              wrapClassName="border border-gray-300 border-solid w-full"
                              placeholder="Chicago USA"
                              className="placeholder-gray-300 leading-[normal]  border border-gray-300  rounded-[12px]  text-[15px] mqlap:text-[13.6px] mqtab1:text-[12.7px] text-left w-full  p-3 sm:text-[10px] mqlap:py-[0.68rem] mqtab1:py-[0.58rem] mqtab1:rounded-[10px] "
                              size="txtInterRegular15"
                            />
                          </div>

                          {showWarning && (
                            <p style={{ color: "red" }}>
                              Character limit exceeded!
                            </p>
                          )}

                          <div className="flex flex-col sm:gap-1.5 gap-3.5 items-start justify-start w-full sm:[95%] mt-[30px] sm:mb-[8px] sm:mt-[10px]">
                            <label
                              className="text-base text-blue_gray-400 sm:text-[12px] mqlap:text-[13.7px] mqtab1:text-[13px]"
                              htmlFor="fileInput"
                            >
                              Upload Job Description
                            </label>
                            <input
                              required
                              type="file"
                              accept=".pdf, .doc, .docx"
                              style={{ color: "grey" }}
                              className="placeholder-gray-300 leading-[normal] rounded-[12px] text-[15px] text-left w-full pl-3 sm:text-[10px] mqlap:text-[13px] mqtab1:text-[12px]"
                              id="fileInput"
                              onChange={handleFileChange}
                            />
                          </div>
                        </div>

                        <div className="flex flex-col sm:gap-2.5  gap-[19px] items-center justify-start w-1/2 sm:w-full">
                          <div className="flex flex-col sm:gap-1.5  gap-[13px] items-start justify-start w-full">
                            <Text
                              className="text-base text-blue_gray-400 sm:text-[12px] mqlap:text-[0.9rem] mqtab1:text-[0.85rem]"
                              size="txtInterRegular16"
                            >
                              Hiring manager name
                            </Text>
                            <input
                              required
                              value={hiringManagerName}
                              onChange={handleHiringManagerName}
                              maxLength={41}
                              name="albert"
                              placeholder="Albert"
                              className="placeholder-gray-300 leading-[normal]  border border-gray-300  rounded-[12px]  text-[15px] mqlap:text-[13.6px] mqtab1:text-[12.7px] text-left w-full  p-3 sm:text-[10px] mqlap:py-[0.68rem] mqtab1:py-[0.58rem] mqtab1:rounded-[10px]"
                              wrapClassName="w-full border border-gray-300 border-solid w-full"
                            />
                          </div>
                          <div className="flex flex-col gap-3.5 items-start justify-start w-full">
                            <Text
                              className="text-base text-blue_gray-400 sm:text-[12px] mqlap:text-[0.9rem] mqtab1:text-[0.85rem]"
                              size="txtInterRegular16"
                            >
                              Role
                            </Text>
                            <input
                              required
                              value={role}
                              onChange={handleRole}
                              maxLength={41}
                              wrapClassName="border border-gray-300 border-solid w-full"
                              placeholder="UX/UI Designer"
                              className="placeholder-gray-300 leading-[normal]  border border-gray-300  rounded-[12px]  text-[15px] mqlap:text-[13.6px] mqtab1:text-[12.7px] text-left w-full  p-3 sm:text-[10px] mqlap:py-[0.68rem] mqtab1:py-[0.58rem] mqtab1:rounded-[10px]"
                              size="txtInterRegular15"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-start mt-[45px] pb-4  w-full">
                      <div className="flex flex-col gap-3 items-center justify-start w-[98%] md:w-full">
                        <div className="flex flex-col gap-9 items-center justify-start w-full md:w-full">
                          <div className="flex flex-row font-poppins items-center justify-between w-full">
                            <Text
                              className="text-2xl md:text-[22px] text-black-900_7f sm:text-sm mqlap:text-[1.32rem] mqtab1:text-[1.17rem]"
                              size="txtPoppinsSemiBold24"
                            >
                              Content
                            </Text>
                            <div className="bg-gray-100 flex flex-row gap-[21px] items-center justify-center rounded-[12px] w-auto p-2 h-[100%]">
                              <div>
                                <Text
                                  className=" text-2xl md:text-[22px] text-black-900_7f sm:text-sm mqlap:text-[1.32rem] mqtab1:text-[1.17rem]"
                                  size="txtPoppinsSemiBold24"
                                >
                                  AI
                                </Text>
                              </div>

                              <div className="checkbox-wrapper-22 flex flex-row items-center justify-center">
                                <label className="switch" htmlForor="checkbox">
                                  <input
                                    onClick={(e) => {
                                      contentCreate();
                                      if (!e.target.checked) {
                                        setdata("");
                                      }
                                    }}
                                    type="checkbox"
                                    id="checkbox"
                                  />
                                  <div className="slider round"></div>
                                </label>
                              </div>

                              <div className="info-button">
                                <Img
                                  className=" h-5 mr-[10px] w-5"
                                  src="images/img_phinfobold.svg"
                                  alt="phinfobold"
                                />
                                <div className="info-content sm:text-[8px]">
                                  Use AI to write cover letter for you
                                </div>
                              </div>
                            </div>
                          </div>
                          <Text
                            className="text-base text-blue_gray-400 w-full sm:text-[12px] mqlap:text-[0.89rem] mqtab1:text-[0.84rem]"
                            size="txtInterRegular16"
                          >
                            3-4 paragraphs explaining why you’re the perfect
                            candidate for a specific job.
                          </Text>
                        </div>

                        {coverletterContent ? (
                          data1 === "" ? (
                            <div className="p-3 border border-blue_gray-100 border-solid flex flex-col items-center justify-center rounded-[12px] w-full h-[500px]">
                              <BeatLoader color="hsla(168, 67%, 53%, 1)" />
                            </div>
                          ) : (
                            <TextArea
                              onChange={handleChange}
                              className="sm:text-[12px] p-3 border border-blue_gray-100 border-solid flex flex-col items-center justify-start rounded-[12px] w-full h-[500px]"
                            >
                              {data1}
                            </TextArea>
                          )
                        ) : (
                          <textarea
                            placeholder="write a content for you're cover letter..."
                            className="sm:text-[12px] p-3 border border-blue_gray-100 border-solid flex flex-col items-center justify-start rounded-[12px] w-full h-[500px] mqlap:text-[0.89rem] mqtab1:text-[0.84rem]"
                            onChange={handleChange}
                          >
                            { }
                          </textarea>
                        )}
                      </div>
                    </div>
                    <div className="hidden md:flex flex flex-row w-full items-center justify-between sm:mb-[50px]">
                      <Link to="/coverletter" className="">
                        <Button className="flex flex-row items-center justify-center sm:text-[12px] sm:w-[100px] sm:h-[30px]">
                          Back
                        </Button>
                      </Link>
                      <Link to="/coverletter-download" className="">
                        <Button className="flex flex-row items-center justify-center sm:text-[12px] sm:w-[100px] sm:h-[30px]">
                          Generate
                        </Button>
                      </Link>
                    </div>
                  </div>

                  <div className="sm:hidden flex flex-col items-start justify-end p-[9px] w-[50%] md:w-full pt-[39px]">
                    <Link to="/coverletter">
                      <div className="flex md:flex-1 flex-row gap-[11px] items-center justify-start pr-0.5 pt-0.5 w-[200px] md:w-full">
                        <Img
                          className="h-5"
                          src="images/img_materialsymbolsborderall2.svg"
                          alt="materialsymbols"
                        />
                        <Text
                          className="leading-[normal] ml-3 md:ml-[0]  text-base text-black-900_7f text-left"
                          size="txtPoppinsSemiBold24"
                        >
                          Change Template
                        </Text>
                      </div>
                    </Link>

                    <div className="w-[100%] ">
                      <CoverLetterPrepare />
                    </div>

                    <Button
                    className="cursor-pointer flex items-center self-end justify-center mb-1  w-[11.6rem] mqtab1:w-[9.5rem] mqlap:w-[10.5rem] md:ml-[0] ml-[302px] mt-[50px] p-[7px] mqlap:p-[5.5px] mqtab1:py-[4.5px] mqtab1:px-[1.5px]"
                      rightIcon={
                        <Img
                          className="mt-px ml-4"
                          src="images/img_octicondownload16.svg"
                          alt="octicon:download-16"
                        />
                      }
                    >
                      <div
                        onClick={handleBothActions}
                        className="font-medium leading-[normal] text-center text-lg mqlap:text-[1vw] mqtab1:text-[1.05vw]"
                        >

                      
                        Download
                      </div>
                    </Button>

                    <Dialog
                      maxWidth="sm"
                      open={open}
                      onClose={handleClose}
                      disableBackdropClick={true}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                    <div className="mqlap:w-[33.2vw] mqtab1:w-[35.3vw]">

                      <div>
                      <div className="flex flex-row items-center h-[68px] mqlap:h-[3.55vw] mqtab1:h-[3.76vw] mqtab2:h-[45px]">
                          <Text
                            className="pl-[4.5%] mt-0.5 text-black-900 text-xl sm:text-[12px]   mqlap:text-[1.05vw] mqtab1:text-[1.15vw] mqtab2:text-[1.4vw]"
                            size="txtInterSemiBold18"
                          >
                            Download Coverletter
                          </Text>
                          <Tooltip
                            title={
                              <Typography>
                                 <p className="text-[16px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] mqtab2:text-[1.02vw]">
                                  Your download will start shortly.
                                </p>
                              </Typography>
                            }
                            placement="right"
                            arrow
                            className="relative flex flex-col items-center justify-start md:h-[84px] gap-2 md:w-[250px]   rounded-[15px] transition-colors hover:bg-gray-100 hover:text-[40px]"
                          >
                            <img
                              src="images/infoIcon.svg"
                              alt="infoIcon"
                              className="mt-[1.3px] mqlap:mt-[1px] mqtab1:mt-[2px] ml-[3px] h-[1.25rem] w-[1.25rem] mqlap:w-[1.1vw] mqlap:h-[1.1rem] mqtab1:w-[1.1vw] mqtab1:h-[1rem] mqtab2:w-[1.3vw] mqtab2:h-[1.3rem]"
                            />
                          </Tooltip>
                        </div>
                        <hr
                          style={{
                            height: "0.5px",
                            backgroundColor: "grey",
                            border: "none",
                            width: "100%",
                          }}
                        />
                      </div>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        <p className="pl-[0.5%] text-[1.05rem] mqlap:text-[0.92vw] mqtab1:text-[0.96vw] mqtab2:text-[1.02vw] leading-6 mqlap:leading-[1.45vw] mqtab1:leading-[1.5vw]">
                          Would you like to replace the currently uploaded
                          Coverletter on RecRoid with the new one?
                          </p>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions className="mb-[0.57rem] mqtab1:mb-[0.43rem]  mqlap:mb-[0.5rem] mt-[0.2vw] mqtab1:mt-[0.1vw] ">
                        <button
                          style={{ borderColor: "blue", color: "blue" }}
                          className=" mqtab1:border-[0.1vw]  mqlap:border-[0.1vw] border-[1.9px] border-solid flex justify-center items-center h-[2.2rem] w-[6.55rem] mqlap:h-[1.9vw] mqlap:w-[5.8vw] mqtab1:h-[2vw] mqtab1:w-[6vw] mqtab2:h-[2.35vw] mqtab2:w-[7.4vw] rounded-[13px] sm:text-[14px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] mqtab2:text-[0.95vw] mqlap:rounded-[0.73vw] mqtab1:rounded-[0.73vw] mqtab2:rounded-[9px]"
                          onClick={handleClose}
                        >
                          No
                        </button>
                        <Button
                          onClick={handleYesOptionClick}
                          className="flex justify-center items-center mr-[30px] mqlap:mr-[1.8vw] mqtab1:mr-[1.9vw] h-[2.25rem] w-[6.55rem] mqlap:h-[2vw] mqlap:w-[5.8vw] mqtab1:h-[2.05vw] mqtab1:w-[6vw] mqtab2:h-[2.36vw] mqtab2:w-[7.4vw] rounded-[11px] sm:text-[14px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] mqtab2:text-[0.95vw] mqlap:rounded-[0.73vw] mqtab1:rounded-[0.73vw] mqtab2:rounded-[9px]"
                          autoFocus
                        >
                          Yes
                        </Button>
                      </DialogActions>
                      </div>
                    </Dialog>
                  </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoverLetterDetails;