import { useState, useMemo } from "react";
import PropTypes from "prop-types";

const FrameComponent2 = ({
  className = "",
  fullName,
  required,
  propDisplay,
  propMinWidth,
  setValue,
  ...props
}) => {
  const [touched, setTouched] = useState(false);

  const fullNameStyle = useMemo(() => {
    return {
      display: propDisplay,
      minWidth: propMinWidth,
    };
  }, [propDisplay, propMinWidth]);

  const handleBlur = () => {
    setTouched(true);
  };

  return (
    <div
      className={`h-28 w-[501px] flex flex-col items-start justify-start gap-[17px] max-w-full text-center text-[24px] text-black font-montserrat-alternates mqtab1:h-[6rem] mqtab2:h-[6rem] mqmob1:h-[6.1rem] ${className}`}
    >
      <div className="flex flex-row items-start justify-start gap-[6px]">
        <div
          className="relative leading-[29px] font-medium inline-block min-w-[128px] mq450:text-lgi mq450:leading-[23px]"
          style={fullNameStyle}
        >
          {fullName}
        </div>
        {required && (
          <div className="flex flex-col items-start justify-start pt-[11px] px-0 pb-0">
            <div className="w-[7px] h-[7px] relative rounded-[50%] bg-crimson" />
          </div>
        )}
      </div>
      <input
        className="self-stretch flex-1 relative rounded-3xs bg-whitesmoke border-[1px] border-solid border-darkgray text-[19.5px] pl-[1.3rem]"
        type={fullName ? "text" : "email"}
        required={required}
        onBlur={handleBlur}
        onChange={(e) => setValue(e.target.value)}
        {...props}
      />
      {/* {required && touched && !props.value && (
        <span className="text-red-500 text-sm mt-1">Please fill this field</span>
      )} */}
    </div>
  );
};

FrameComponent2.propTypes = {
  className: PropTypes.string,
  fullName: PropTypes.string,
  required: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  propDisplay: PropTypes.any,
  propMinWidth: PropTypes.any,
};

export default FrameComponent2;
