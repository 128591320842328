import AlertModal from "components/AlertModal";
import supabase from "components/superBaseConfig";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getEnvURL } from "utils/getEnvURL";

const frontEndURL = getEnvURL();
const RecruiterRoboLogo31Icon = styled.img`
  position: absolute;
  top: 11.5px;
  left: 0px;
  width: 97.2px;
  height: 95.1px;
  object-fit: cover;
`;
const RecruiterRoboLogo31Wrapper = styled.div`
  position: relative;
  width: 97.2px;
  height: 117px;
  overflow: hidden;
  flex-shrink: 0;
`;
const WebsiteName = styled.div`
  position: relative;
  line-height: 135.5%;
  font-weight: 600;
  display: inline-block;
  width: 213.3px;
  height: 34.2px;
  flex-shrink: 0;
  color: #161616;
`;
const FrameParent = styled.div`
  position: absolute;
  top: 50px;
  left: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const SignInTo = styled.div`
  position: relative;
  font-size: 38.44px;
  line-height: 62.47px;
  font-weight: 600;
  text-align: center;
  z-index: 0;
`;
const InputLabelmedium = styled.div`
  position: absolute;
  top: 0%;
  left: calc(50% - 317.15px);
  line-height: 34.84px;
`;

const RectangleParent = styled.div`
  position: absolute;
  top: 36px;
  left: calc(50% - 317.15px);
  width: 634.3px;
  height: 62.5px;
  font-size: 19.22px;
  color: var(--color-darkgray-100);
`;
const Email = styled.div`
  position: relative;
  width: 634.3px;
  height: 98.5px;
  z-index: 1;
`;
const RectangleGroup = styled.div`
  position: absolute;
  top: 36px;
  left: calc(50% - 317.15px);
  width: 634.3px;
  height: 62.5px;
  font-size: 19.22px;
`;
const Email1 = styled.div`
  position: absolute;
  margin: 0 !important;
  height: 51.95%;
  top: 110.23%;
  bottom: -62.18%;
  left: calc(50% - 317.15px);
  width: 634.3px;
  z-index: 2;
`;
const SignInToDhiwiseParent = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 28.14px;
`;
const InputLabelsmall = styled.div`
  position: relative;
  font-size: 21.62px;
  line-height: 34.84px;
  text-align: right;
  cursor: pointer;
`;
const SubmitChild = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 317.15px);
  border-radius: 7.21px;
  background-color: var(--color-darkslateblue);
  width: 634.3px;
  height: 60.1px;
`;
const LogIn = styled.div`
  position: absolute;
  top: 14.4px;
  left: calc(50% - 27.65px);
  line-height: 31.23px;
`;
const Submit = styled.div`
  position: relative;
  width: 634.3px;
  height: 60.1px;
  color: var(--color-white);
`;
const OrContinueWith1 = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 72.05px);
  line-height: 31.23px;
`;
const OrContinueWithChild = styled.div`
  position: absolute;
  top: 15px;
  left: calc(50% + 91.85px);
  background-color: var(--color-silver-100);
  border-top: 1.2px solid var(--color-silver-100);
  box-sizing: border-box;
  width: 216.2px;
  height: 1.2px;
`;
const OrContinueWithItem = styled.div`
  position: absolute;
  top: 15px;
  left: calc(50% - 308.15px);
  background-color: var(--color-silver-100);
  border-top: 1.2px solid var(--color-silver-100);
  box-sizing: border-box;
  width: 217.4px;
  height: 1.2px;
`;
const OrContinueWith = styled.div`
  position: relative;
  width: 615.1px;
  height: 32px;
  text-align: left;
  color: var(--color-silver-100);
`;
const LoginChild = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 317.15px);
  border-radius: 7.21px;
  border: 1.2px solid var(--color-gray-400);
  box-sizing: border-box;
  width: 634.3px;
  height: 60.1px;
`;
const LoginParent = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 317.15px);
  width: 634.3px;
  height: 60.1px;
`;
const Google1Icon = styled.img`
  position: relative;
  width: 28.8px;
  height: 28.8px;
  overflow: hidden;
  flex-shrink: 0;
`;
const SignInWith = styled.div`
  position: relative;
  line-height: 31.23px;
`;
const Google1Parent = styled.div`
  position: absolute;
  top: 14.4px;
  left: calc(50% - 104.85px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7.21px;
`;
const Facebook1Parent = styled.div`
  position: absolute;
  top: 14.4px;
  left: calc(50% - 104.25px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7.21px;
`;
const LoginGroup = styled.div`
  position: absolute;
  top: 79.3px;
  left: calc(50% - 317.15px);
  width: 634.3px;
  height: 60.1px;
`;
const Linkedin11Parent = styled.div`
  position: absolute;
  top: 14.4px;
  left: calc(50% - 104.25px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7.21px;
`;
const LoginContainer = styled.div`
  position: absolute;
  top: 158.6px;
  left: calc(50% - 317.15px);
  width: 634.3px;
  height: 60.1px;
`;
const GroupParent = styled.div`
  position: relative;
  width: 634.3px;
  height: 218.6px;
`;
const InputLabelsmallParent = styled.div`
  position: absolute;
  top: 317.1px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 28.14px;
  text-align: center;
  font-size: 19.22px;
  color: var(--color-gray-400);
`;
const SignUp = styled.span`
  text-decoration: underline;
`;
const DontHaveAnContainer = styled.div`
  position: absolute;
  top: 778.4px;
  left: 122.9px;
  font-size: 24.44px;
  line-height: 135.5%;
  font-family: var(--font-poppins);
  color: var(--color-black);
  cursor: pointer;
`;
const FrameGroup = styled.div`
  position: absolute;
  top: 205.7px;
  left: calc(50% - 317px);
  width: 634.3px;
  height: 811.3px;
  font-size: 21.62px;
  color: var(--color-darkslategray-200);
  font-family: var(--font-inter);
`;
const RecruiterRoboSignInlogInRoot = styled.div`
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  height: 1080px;
  overflow: hidden;
  text-align: left;
  font-size: 26.06px;
  color: var(--color-gray-300);
  font-family: var(--font-poppins);
`;
const StyledInput = styled.input`
  position: absolute;
  top: 0px;
  left: calc(50% - 317.15px);
  border-radius: 9.61px;
  background-color: var(--color-white);
  border: 1.2px solid var(--color-gainsboro);
  box-sizing: border-box;
  width: 634.3px;
  height: 62.5px;
  padding: 10px; /* Add padding if needed */
  font-size: 16px; /* Adjust font size as per your design */
  /* Add more styles as needed */
`;



const UniversityTabSignInlogIn = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onInputLabelSmallTextClick = useCallback(() => {
    navigate("/university-forgotpassword");
  }, [navigate]);

  const onDontHaveAnClick = useCallback(() => {
    navigate("/university-signup");
  }, [navigate]);

  const onLogo = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onGmailLogin = useCallback(async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        queryParams: {
          access_type: "offline",
          prompt: "consent",
        },
        redirectTo: `${frontEndURL}/jobseeker-dashboard`,
      },
    });

    if (error) {
      throw error; // Handle the error appropriately
    }
  }, []);

  const onLinkedinLogin = useCallback(() => {
    window.location.replace("https://www.linkedin.com/oauth/v2/authorization");
  }, []);

  const onFacebookLogin = useCallback(() => {
    window.location.replace("https://www.facebook.com/login/");
  }, []);

  const [error, setError] = useState(false);
  const [loadingVal, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const { error } = await supabase.auth.signInWithPassword({
          email: email,
          password: password,
        });
        if (!error) {
          setLoading(false);
          navigate("/University-dashboard");
        } else {
          setLoading(false);
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 3000);
        }
      } catch (error) {
        setLoading(false);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
      }
    },
    [navigate, email, password],
  );
  const onLogin = useCallback(
    (e) => {
      setLoading(true);
      handleSubmit(e);
    },
    [handleSubmit],
  );

  return (
    <RecruiterRoboSignInlogInRoot>
      {loadingVal && <AlertModal msg="loading" />}
      {error && <AlertModal msg="error" />}
      <FrameParent>
        <RecruiterRoboLogo31Wrapper>
          <RecruiterRoboLogo31Icon
            alt=""
            src="/recruiter-robo-logo-3-1@2x.png"
            onClick={onLogo}
            style={{ cursor: "pointer" }}
          />
        </RecruiterRoboLogo31Wrapper>
        <WebsiteName onClick={onLogo} style={{ cursor: "pointer" }}>
          Recruiter Robo
        </WebsiteName>
      </FrameParent>
      <FrameGroup>
        <SignInToDhiwiseParent>
          <SignInTo>Sign in to Recruiter Robo</SignInTo>
          <Email>
            <InputLabelmedium>Email</InputLabelmedium>
            <RectangleParent>
              <StyledInput
                type="email"
                onChange={(event) => setEmail(event.target.value)}
                placeholder="Enter Email"
                value={email}
              />
            </RectangleParent>
          </Email>
          <Email1>
            <InputLabelmedium>Password</InputLabelmedium>
            <RectangleGroup>
              <StyledInput
                type="email"
                onChange={(event) => setEmail(event.target.value)}
                placeholder="Enter Email"
                value={email}
              />
              <StyledInput
                type="password"
                placeholder="Enter Password"
                value={password}
                onChange={(event) => {setPassword(event.target.value);}}
              />
            </RectangleGroup>
          </Email1>
        </SignInToDhiwiseParent>
        <InputLabelsmallParent>
          <InputLabelsmall
            onClick={onInputLabelSmallTextClick}
            style={{ cursor: "pointer" }}
          >
            Forgot Password?
          </InputLabelsmall>
          <Submit onClick={onLogin} style={{ cursor: "pointer" }}>
            <SubmitChild />
            <LogIn>Log in</LogIn>
          </Submit>
          <OrContinueWith>
            <OrContinueWith1>Or continue with</OrContinueWith1>
            <OrContinueWithChild />
            <OrContinueWithItem />
          </OrContinueWith>
          <GroupParent>
            <LoginParent style={{ cursor: "pointer" }}>
              <LoginParent>
                <LoginChild />
              </LoginParent>
              <Google1Parent
                onClick={onGmailLogin}
                style={{ cursor: "pointer" }}
              >
                <Google1Icon alt="" src="/google-1.svg" />
                <SignInWith>Sign in with Google</SignInWith>
              </Google1Parent>
            </LoginParent>
            <LoginGroup>
              <LoginParent style={{ cursor: "pointer" }}>
                <LoginChild />
              </LoginParent>
              <Facebook1Parent
                onClick={onFacebookLogin}
                style={{ cursor: "pointer" }}
              >
                <Google1Icon alt="" src="/facebook-1.svg" />
                <SignInWith>Sign in with Facebook</SignInWith>
              </Facebook1Parent>
            </LoginGroup>
            <LoginContainer>
              <LoginParent style={{ cursor: "pointer" }}>
                <LoginChild />
              </LoginParent>
              <Linkedin11Parent
                onClick={onLinkedinLogin}
                style={{ cursor: "pointer" }}
              >
                <Google1Icon alt="" src="/linkedin-1-1.svg" />
                <SignInWith>Sign in with Linkedin</SignInWith>
              </Linkedin11Parent>
            </LoginContainer>
          </GroupParent>
        </InputLabelsmallParent>
        <DontHaveAnContainer onClick={onDontHaveAnClick}>
          {`Don’t have an account? `}
          <SignUp>Sign up</SignUp>
        </DontHaveAnContainer>
      </FrameGroup>
    </RecruiterRoboSignInlogInRoot>
  );
};

export default UniversityTabSignInlogIn;
