import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Button, Text } from "components";
import { MicOff, VideoOff } from 'lucide-react';
import { useState } from "react";
import { Bounce, ToastContainer, toast } from 'react-toastify';

const LiveInterviewInstruction = ({ startAssesment, openPermissionPopup }) => {
    const [timerOn, setTimerOn] = useState(false);
    const [mainLive, setMainLive] = useState(false);
    const [cameraPermission, setCameraPermission] = useState(false);
    const [microphonePermission, setMicrophonePermission] = useState(false);
    const [permissionBlocked, setPermissionBlocked] = useState(false);

    const checkPermissions = async () => {
        try {
            const camera = await navigator.mediaDevices.getUserMedia({ video: true });
            const microphone = await navigator.mediaDevices.getUserMedia({ audio: true });

            camera.getTracks().forEach(track => track.stop());
            microphone.getTracks().forEach(track => track.stop());

            setCameraPermission(true);
            setMicrophonePermission(true);
            startAssessment();
        } catch (error) {
            console.error("Error accessing microphone or camera:", error);
            toast.error("Couldn't access your camera/mic. Check the permissions and try again.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            setPermissionBlocked(true);
        }
    };

    const startAssessment = () => {
        startAssesment()
        setTimerOn(true);
        setMainLive(true);
    };

    const closePermissionPopup = () => {
        setPermissionBlocked(false);
        setCameraPermission(false);
        setMicrophonePermission(false);
    };

    return (
        <Dialog open={openPermissionPopup} onClose={closePermissionPopup} maxWidth="xl">
            <div className="flex flex-col items-center p-5 h-auto">
                <ToastContainer />

                {!cameraPermission && !microphonePermission ? (
                    !permissionBlocked ? (
                        <div className="text-center w-full flex flex-col justify-center items-center">
                            <Text className="text-[#314ca3] text-xl font-bold mb-4">
                                Allow Camera Access
                            </Text>
                            <Typography className="text-[#314ca3] text-lg sm:text-sm mb-6">
                                To proceed with the exam, camera and microphone access is required.
                            </Typography>
                            <div >
                                <img
                                    src="https://res.cloudinary.com/learning-platform/image/fetch/dpr_auto,w_auto,f_auto,q_auto/https%3A%2F%2Fmedia-content.ccbp.in%2Fwebsite%2Fstatic-images%2Fwindows_allow_camera_permissions.gif"
                                    alt="Instructions to allow camera permissions"
                                    className="h-[300px]"
                                />
                            </div>
                            <Button
                                className="flex h-[35px] w-[180px] sm:h-[30px] sm:w-[90px] sm:text-[12px] text-[14px] rounded-[10px] items-center justify-center"
                                onClick={checkPermissions}
                            >
                                Request Permission
                            </Button>
                        </div>
                    ) : (
                        <div className="text-center w-full flex flex-col justify-center items-center">
                            <div className="flex gap-4 justify-center mt-4 mb-6">
                                <VideoOff color="gray" size={36} />
                                <MicOff color="gray" size={36} />
                            </div>
                            <Text className="text-[#314ca3] text-md sm:text-sm mb-4">
                                It seems your browser is blocking access to the camera/mic. Please follow the steps below to allow access.
                            </Text>
                            <div className="text-left font-medium w-[60%] mx-auto mb-6 flex flex-col gap-[10px]">
                                <div className="flex items-start gap-4 mb-4">
                                    <div className="w-10 h-10 rounded-full bg-gray-100 flex justify-center items-center text-lg font-bold">
                                        1
                                    </div>
                                    <div className="flex flex-col gap-[15px]">
                                        <Text className="text-sm">Click the Camera/Mic icon in the URL bar.</Text>
                                        <img className="h-[25px] w-[150px]" src="images/permission_popup.png" />

                                    </div>

                                </div>
                                <div className="flex items-start gap-4 mb-4">
                                    <div className="w-10 h-10 rounded-full bg-gray-100 flex justify-center items-center text-lg font-bold">
                                        2
                                    </div>
                                    <div className="flex flex-col gap-[15px]">
                                        <Text className="text-sm">Select "Always allow https://www.recroid.com access your Camera/Mic".</Text>
                                        <img className="h-[120px] w-[200px]" src="images/permission_popup2.png" />

                                    </div>
                                </div>
                                <div className="flex items-start gap-4 mb-4">
                                    <div className="w-10 h-10 rounded-full bg-gray-100 flex justify-center items-center text-lg font-bold">
                                        3
                                    </div>
                                    <Text className="text-sm">Finally, click "I Have Allowed Access" button below.</Text>
                                </div>
                            </div>
                            <Button
                                className="flex h-[35px] w-[180px] sm:h-[30px] sm:w-[90px] sm:text-[12px] text-[14px] rounded-[10px] items-center justify-center"
                                onClick={checkPermissions}
                            >
                                I Have Allowed Access
                            </Button>
                        </div>
                    )
                ) : (
                    <div className="text-center">
                        <Text className="text-[#314ca3] text-2xl font-bold mb-4">
                            You are all set!
                        </Text>
                        <Typography className="text-[#314ca3] text-xl sm:text-sm mb-6">
                            Your camera and microphone permissions are granted. You can now start your exam.
                        </Typography>
                        <Button
                            className="flex h-[50px] w-[250px] sm:h-[30px] sm:w-[90px] sm:text-[12px] text-[18px] rounded-[10px] items-center justify-center"
                            onClick={startAssessment}
                        >
                            Start Assessment
                        </Button>
                    </div>
                )}
            </div>
        </Dialog>
    );
};

export default LiveInterviewInstruction;
