export const fetchInterviewPrepData =  (user_id) => {
    return async (dispatch) => {
      dispatch({ type: "FETCH_INTERVIEW_PREP_DATA_REQUEST" });
      try {
        const backendUrl = `${process.env.REACT_APP_API_URL}/get_interview_prep_data`; // Update the backend URL
        const response = await fetch(backendUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_id }),
        });
        
        const data = await response.json();
        const myData = data.data[1];
        // console.log(myData);
        dispatch({ type: "FETCH_INTERVIEW_PREP_DATA_SUCCESS", payload: myData });
      } catch (error) {
        dispatch({ type: "FETCH_INTERVIEW_PREP_DATA_FAILURE", payload: error.message });
        console.error("Error fetching interview prep data:", error.message);
        throw error;
      }
    };
  }
