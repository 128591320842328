import CancelIcon from "@mui/icons-material/Cancel";
import { Tooltip } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { fetchProfilePreferenceData } from "actions/ProfilePreference";
import axios from "axios";
import { Button, Text } from "components";
import { useNavigate } from "react-router-dom";
import AlertModal from "components/AlertModal";
import countryStates from "components/CountriesStates";
import jobRoles from "components/JobRoles";
import { ProfileHeader } from "pages/JobSeekerDashBoardPages/ProfileHeader";
import { ProfileHeader2 } from "pages/JobSeekerDashBoardPages/ProfileHeader2";
import { SideBar1 } from "pages/JobSeekerDashBoardPages/SideBar1";
import { useEffect, useState } from "react";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { is } from "date-fns/locale";
import { Salad } from "lucide-react";

const preferences = [
  {
    website: "None",
    key: "none",
    disabled: false,
  },
  {
    website: "Freshersworld",
    key: "freshersworld",
    disabled: false,
  },
  {
    website: "Michael Page",
    key: "michaelpage",
    disabled: false,
  },
  {
    website: "CareerJet",
    key: "careerjet",
    disabled: false,
  },
  {
    website: "Foundit.in",
    key: "foundit",
    disabled: false,
  },
  {
    website: "Shine",
    key: "shine",
    disabled: false,
  },
];

const ProfilePreference = () => {
  const backendUrl = process.env.REACT_APP_API_URL;
  const [editable, setEdit] = useState(false);
  const navigate = useNavigate();
  const accountType = useSelector(
    (state) => state.profileAccountType.accountType
  );

  const checkAutoJobPref = (jobPref, username, password) => {
    if (jobPref === "none") return true;
    if (username === "" || password === "") {
      alert(
        "Fill in the username and password for the selected job search engine."
      );
      return false;
    }
    return true;
  };
  const [countries, setSelectedCountry] = useState("");

  const [formError, setFormError] = useState(false);
  const [salaryError, setSalaryError] = useState(false);

  const validateForm = () => {
    /* 
    THIS IS WORKING
    |
    v

    if (
      !work_location_1 ||
      !work_location_2 ||
      !work_location_3 ||
      !work_location_4 ||
      !expected_role_1 ||
      !expected_role_2 ||
      !expected_role_3 ||
      !expected_salary
    ) {
      setFormError(true);
      setTimeout(() => {
        setFormError(false);
      }, 2000);
      return false;
    }
    setFormError(false);
    return true; */

    // will work if atleast one location is set

    /*    console.log(
      work_location_1,
      work_location_2,
      work_location_3,
      work_location_4,
      expected_role_1,
      expected_role_2,
      expected_role_3
    ); */

    if (
      work_location_1 === "Select a country" ||
      work_location_2 === "Select state"
    ) {
      setFormError(true);
      setTimeout(() => {
        setFormError(false);
      }, 2000);
      return false;
    }

    if (expected_role_1 === "Select role") {
      setFormError(true);
      setTimeout(() => {
        setFormError(false);
      }, 2000);
      return false;
    }

    if (
      !work_location_1 &&
      !work_location_2 &&
      !work_location_3 &&
      !work_location_4
    ) {
      setFormError(true);
      setTimeout(() => {
        setFormError(false);
      }, 2000);
      return false;
    }

    if (!work_location_1 && !work_location_2) {
      setFormError(true);
      setTimeout(() => {
        setFormError(false);
      }, 2000);
      return false;
    }

    // will work if atleast one role is set

    if (!expected_role_1 && !expected_role_2 && !expected_role_3) {
      setFormError(true);
      setTimeout(() => {
        setFormError(false);
      }, 2000);
      return false;
    }

    if (!expected_role_1) {
      setFormError(true);
      setTimeout(() => {
        setFormError(false);
      }, 2000);
      return false;
    }

    if (!expected_salary) {
      setFormError(true);
      setTimeout(() => {
        setFormError(false);
      }, 2000);
      return false;
    }

    setFormError(false);
    return true;
  };
  const userData = JSON.parse(
    localStorage.getItem(
      `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`
    )
  );
  const user_id = userData.user.id;

  const [work_location_1, setLoc1] = useState("");
  const [work_location_2, setLoc2] = useState("");
  const [work_location_3, setLoc3] = useState("");
  const [work_location_4, setLoc4] = useState("");

  // State for Expected Role
  const [expected_role_1, setExpectedRole1] = useState("");
  const [expected_role_2, setExpectedRole2] = useState("");
  const [expected_role_3, setExpectedRole3] = useState("");

  // State for Expected Salary
  const [expected_salary, setExpectedSalary] = useState("");

  const [saveStatePrep, setSaveStatePrep] = useState(true);

  // State for Job Search Engine Preferences
  const [job_search_engine_preference_1, setPreference1] = useState("none");
  const [search_name_user_1, setUsername1] = useState();
  const [search_password_user_1, setPassword1] = useState();

  const [job_search_engine_preference_2, setPreference2] = useState("none");
  const [search_name_user_2, setUsername2] = useState();
  const [search_password_user_2, setPassword2] = useState();

  const [job_search_engine_preference_3, setPreference3] = useState("none");
  const [search_name_user_3, setUsername3] = useState();
  const [search_password_user_3, setPassword3] = useState();

  const [job_search_engine_preference_4, setPreference4] = useState("none");
  const [search_name_user_4, setUsername4] = useState();
  const [search_password_user_4, setPassword4] = useState();

  const [dataFetch, setDataFetch] = useState(false);
  const [success, setSuccess] = useState(false);

  const [type1, setType1] = useState("password");
  const [icon1, setIcon1] = useState(eyeOff);
  //const [formError, setFormError] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state) => state.profilePreferenceData.profilePreferenceData
  );
  const changeVisibility1 = () => {
    if (type1 === "password") {
      setIcon1(eye);
      setType1("text");
    } else {
      setIcon1(eyeOff);
      setType1("password");
    }
  };
  const [stateToggle, setToggleState] = useState(false);
  const [type2, setType2] = useState("password");
  const [icon2, setIcon2] = useState(eyeOff);
  const changeVisibility2 = () => {
    if (type2 === "password") {
      setIcon2(eye);
      setType2("text");
    } else {
      setIcon2(eyeOff);
      setType2("password");
    }
  };

  const [type3, setType3] = useState("password");
  const [icon3, setIcon3] = useState(eyeOff);
  const changeVisibility3 = () => {
    if (type3 === "password") {
      setIcon3(eye);
      setType3("text");
    } else {
      setIcon3(eyeOff);
      setType3("password");
    }
  };

  const [type4, setType4] = useState("password");
  const [icon4, setIcon4] = useState(eyeOff);
  const changeVisibility4 = () => {
    if (type4 === "password") {
      setIcon4(eye);
      setType4("text");
    } else {
      setIcon4(eyeOff);
      setType4("password");
    }
  };

  useEffect(() => {
    let isMounted = true; // Flag to track whether the component is mounted

    const fetchToggleStatus = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/get_autojob_toggle_status`,
          { user_id: user_id }
        );

        if (isMounted) {
          setToggleStatus(response.data.autojob_toggle_val); // Update state only if component is still mounted
        }
      } catch (error) {
        console.error("Error fetching toggle status:", error);
      }
    };

    fetchToggleStatus();

    // Cleanup function to cancel async task if component is unmounted
    return () => {
      isMounted = false; // Component is unmounting, update flag
    };
  }, [user_id]); // Add user_id to the dependency array

  // };
  const userPreferncesData = async (event) => {
    event.preventDefault();

    if (
      validateForm() &&
      checkAutoJobPref(
        job_search_engine_preference_1,
        search_name_user_1,
        search_password_user_1
      ) &&
      checkAutoJobPref(
        job_search_engine_preference_2,
        search_name_user_2,
        search_password_user_2
      ) &&
      checkAutoJobPref(
        job_search_engine_preference_3,
        search_name_user_3,
        search_password_user_3
      ) &&
      checkAutoJobPref(
        job_search_engine_preference_4,
        search_name_user_4,
        search_password_user_4
      )
    ) {
      const user_id = userData.user.id;
      const formData = {
        user_id,
        work_location_1:
          work_location_1 !== "Select a country" ? work_location_1 : "",
        work_location_2:
          //work_location_2 !== "Select state" ? work_location_2 : "",
          work_location_1 === "Select a country"
            ? ""
            : work_location_2 !== "Select state"
              ? work_location_2
              : "",
        work_location_3:
          work_location_3 !== "Select a country" ? work_location_3 : "",
        work_location_4:
          //work_location_4 !== "Select state" ? work_location_4 : "",
          work_location_3 === "Select a country"
            ? ""
            : work_location_4 !== "Select state"
              ? work_location_4
              : "",

        expected_role_1:
          expected_role_1 !== "Select role" ? expected_role_1 : "Select role",
        expected_role_2:
          expected_role_2 !== "Select role" ? expected_role_2 : "",
        expected_role_3:
          expected_role_3 !== "Select role" ? expected_role_3 : "",

        expected_salary,

        job_search_engine_preference_1,
        search_name_user_1,
        search_password_user_1,

        job_search_engine_preference_2,
        search_name_user_2,
        search_password_user_2,

        job_search_engine_preference_3,
        search_name_user_3,
        search_password_user_3,

        job_search_engine_preference_4,
        search_name_user_4,
        search_password_user_4,
      };

      //console.log(formData);

      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/save_preferences`;
        const requestOptions = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
          //console: ////console.log("data", formData)
        };

        const res = await fetch(apiUrl, requestOptions);
        const data = await res.json();
        setSuccess(true);
        setSaveStatePrep(true);
        dispatch(fetchProfilePreferenceData(user_id));
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      } catch (error) {
        //console.error("Error fetching data:", error);
      }
    }
  };

  const handleToggleChange = (e) => {
    if (accountType !== 1) {
      e.preventDefault()
      navigate("/billing-overview")
    }
    else {
      setOpen(true);
    }
  };

  const handleClose = async (confirm) => {
    setOpen(false);
    if (confirm) {
      try {
        const userId = JSON.parse(
          localStorage.getItem(
            `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`
          )
        ).user.id;
        const newToggleStatus = !toggleStatus;
        await axios.post(
          `${process.env.REACT_APP_API_URL}/get_autojob_toggle_status_change`,
          { user_id: userId, newToggleStatus }
        );
        setToggleStatus(newToggleStatus);
      } catch (error) {
        console.error("Error updating toggle status:", error);
      }
    }
  };

  useEffect(() => {
    // Check if userDetails is available
    if (userDetails) {
      // Check if work_location_1 is undefined and set the edit state accordingly
      if (userDetails.work_location_1 === undefined) {
        setEdit(false);
      } else {
        // Populate the location states
        setLoc1(userDetails.work_location_1 || "Select a country");
        setLoc2(userDetails.work_location_2 || "Select state");
        setLoc3(userDetails.work_location_3 || "Select a country");
        setLoc4(userDetails.work_location_4 || "Select state");
        setExpectedRole1(userDetails.expected_role_1 || "Select role");
        setExpectedRole2(userDetails.expected_role_2 || "Select role");
        setExpectedRole3(userDetails.expected_role_3 || "Select role");
        setExpectedSalary(userDetails.expected_salary || "");

        // Check if autojobportals is available and set the corresponding states
        if (userDetails.autojobportals) {
          setPreference1(userDetails?.autojobportals[0]?.website || "none");
          setUsername1(userDetails?.autojobportals[0]?.email || "");
          setPassword1(userDetails?.autojobportals[0]?.password || "");

          setPreference2(userDetails?.autojobportals[1]?.website || "none");
          setUsername2(userDetails?.autojobportals[1]?.email || "");
          setPassword2(userDetails?.autojobportals[1]?.password || "");

          setPreference3(userDetails?.autojobportals[2]?.website || "none");
          setUsername3(userDetails?.autojobportals[2]?.email || "");
          setPassword3(userDetails?.autojobportals[2]?.password || "");

          setPreference4(userDetails?.autojobportals[3]?.website || "none");
          setUsername4(userDetails?.autojobportals[3]?.email || "");
          setPassword4(userDetails?.autojobportals[3]?.password || "");

          setFormError(false);
          setDataFetch(true);
        }
      }
    } else {
      setEdit(false);
      // If userDetails is not available or has an unexpected structure
      //setFormError(true);
    }
  }, [userDetails]); // Depend on userDetails to re-run effect when it changes

  useEffect(() => {
    setSaveStatePrep(true);
  }, []);

  const statesList = countryStates.find(
    (country) => country.country === work_location_1
  );

  const statesList2 = countryStates.find(
    (country) => country.country === work_location_3
  );
  const handleInputChange = (e, setStateFunction) => {
    if (e.target.value === "None") setStateFunction(null);
    else setStateFunction(e.target.value);
    setSaveStatePrep(false);
  };

  const handleInputChangeJobPref = (
    e,
    setStateFunction,
    setUsernameFunction,
    setPasswordFunction
  ) => {
    const { value } = e.target;

    if (value === "None") {
      // If preference is set to "None", reset username and password for all preferences
      setStateFunction("");
      setUsernameFunction("");
      setPasswordFunction("");
    } else {
      const index = preferences.findIndex((pref) => pref.key === value);
      preferences[index].disabled = true;
      setStateFunction(value);
    }
    setSaveStatePrep(false);
  };
  const [toggleStatus, setToggleStatus] = useState(false);
  return (
    <>
      {success && <AlertModal msg="success" />}
      {formError && <AlertModal msg="profile-error" />}
      {salaryError && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-[#ED4337] shadow-lg p-6 w-1/4 absolute rounded-[15px] flex flex-col justify-center items-center border-[2px] border-solid border-[#000000] p-[29px]">
            <CancelIcon
              sx={{ height: "44px", width: "44px", color: "white" }}
            />
            <div className="text-[30px] p-[10px] text-[#fff] align-center">
              Please enter your salary using numbers only
            </div>
          </div>
        </div>
      )}
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <SideBar1 clickedTab={"Profile"} />
          <div className="flex flex-1 flex-col font-inter sm:gap-[10px] gap-[37px] items-center justify-start md:px-5 w-full h-[100vh]">
            <ProfileHeader clickedTab={"Profile"} />
            <form
              style={{ overflow: "scroll" }}
              onSubmit={userPreferncesData}
              className="bg-white-A700 flex flex-col items-center justify-start p-7 sm:px-5 rounded-[15px] w-[94%] md:w-full h-[100vh]"
            >
              <div className="flex flex-col gap-[43px] justify-start  mt-2.5 w-[99%] md:w-full">
                <ProfileHeader2 activeTab={"preferences"} />

                <div className=" flex flex-col items-center justify-start sm:gap-[5px] md:ml-[0] sm:mt-[5px] mt-[33px] w-full md:w-full">
                  <div className="flex flex-col sm:gap-[10px] gap-[19px] items-start justify-start w-full">
                    <div className="flex flex-col items-center justify-start">
                      <Text
                        style={{ color: "#6f7074" }}
                        className="font-semibold text-base sm:text-sm text-blue_gray-400  mqlap:text-[0.91rem] mqtab1:text-[1.02vw]  "
                      // size="txtInterRegular16"
                      >
                        Work location *
                      </Text>
                    </div>
                    <div className="flex flex-row items-center sm:gap-[10px] justify-between w-full sm:flex-col">
                      {/* <div style={{ backgroundColor: "red" }} className="flex sm:flex-col  sm:gap-[12px] gap-[21px] flex-row sm:gap-10 items-center justify-between w-full flex-wrap"> */}

                      <div className="rounded-[12px] flex flex-col items-center w-[47%] gap-[20px] pt-[20px] pb-[20px] p-[10px] bg-gray-100">
                        <select
                          className="pref1 mqlap:text-[0.85rem] mqtab1:text-[0.89vw] leading-[normal] sm:h-[30px]  h-[45px] mqtab1:h-[2.9vw] mqtab1:py-0  sm:w-[75vw] sm:text-[10px]   text-[15px] text-left !border-colors !rounded-[12px] border border-solid sm:flex-1 mb-0.5  w-[98%] sm:w-full p-3  mqlap:text-[0.85rem] mqtab1:text-[0.89vw]"
                          value={work_location_1}
                          onChange={(e) => handleInputChange(e, setLoc1)}
                          disabled={editable}
                          required
                        >
                          <option value="Select a country" disabled>
                            Select a country
                          </option>
                          {countryStates
                            .sort((a, b) => a.country.localeCompare(b.country))
                            .map((each) => (
                              <option key={each.country} value={each.country}>
                                {each.country}
                              </option>
                            ))}
                        </select>
                        <select
                          className="sm:h-[30px]  h-[45px] mqtab1:h-[2.9vw] mqtab1:py-0  sm:w-[75vw] sm:text-[10px] pref1 mqlap:text-[0.85rem] mqtab1:text-[0.89vw] leading-[normal]   text-[15px] text-left !border-colors !rounded-[12px] border border-solid sm:flex-1 mb-0.5 w-[98%]  sm:w-full p-3"
                          value={work_location_2}
                          onChange={(e) => handleInputChange(e, setLoc2)}
                          disabled={editable}
                          required
                        >
                          <option value="Select state" disabled>
                            Select State
                          </option>
                          {statesList &&
                            statesList.states
                              .sort((a, b) => a.localeCompare(b))
                              .map((state) => (
                                <option key={state} value={state}>
                                  {state}
                                </option>
                              ))}
                        </select>
                      </div>
                      <div className="rounded-[12px]  pb-[20px] pt-[20px] flex flex-col items-center w-[47%] gap-[20px] p-[10px] bg-gray-100">
                        <select
                          className="sm:h-[30px]  h-[45px] mqtab1:h-[2.9vw] mqtab1:py-0  sm:w-[75vw] sm:text-[10px] pref1 mqlap:text-[0.85rem] mqtab1:text-[0.89vw] leading-[normal]   text-[15px] text-left !border-colors !rounded-[12px] border border-solid sm:flex-1 mb-0.5  w-[98%] sm:w-full p-3"
                          value={work_location_3}
                          onChange={(e) => handleInputChange(e, setLoc3)}
                          name="locCounter_Two"
                          disabled={editable}
                        >
                          <option value="" selected>
                            Select a country{" "}
                          </option>
                          {countryStates
                            .sort((a, b) => a.country.localeCompare(b.country))
                            .map((each) => (
                              <option key={each.country} value={each.country}>
                                {each.country}
                              </option>
                            ))}
                        </select>

                        <select
                          className="sm:h-[30px]  h-[45px] mqtab1:h-[2.9vw] mqtab1:py-0  sm:w-[75vw] sm:text-[10px] pref1 mqlap:text-[0.85rem] mqtab1:text-[0.89vw] leading-[normal]   text-[15px] text-left !border-colors !rounded-[12px] border border-solid sm:flex-1 mb-0.5  w-[98%] sm:w-full p-3"
                          value={work_location_4}
                          onChange={(e) => handleInputChange(e, setLoc4)}
                          disabled={editable}
                        >
                          <option value="" selected>
                            Select State{" "}
                          </option>
                          {statesList2 &&
                            statesList2.states
                              .sort((a, b) => a.localeCompare(b))
                              .map((state) => (
                                <option key={state} value={state}>
                                  {state}
                                </option>
                              ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col items-start justify-start sm:mt-[10px] mt-[35px] w-full">
                    <div className="flex flex-col gap-4 items-start justify-start w-full">
                      <div className="flex flex-col items-center justify-start">
                        <Text
                          style={{ color: "#6f7074" }}
                          className="font-semibold sm:text-sm text-base text-blue_gray-400  mqlap:text-[0.91rem] mqtab1:text-[1.02vw]"
                        >
                          Expected Role *
                        </Text>
                      </div>
                      <div className="flex sm:flex-col sm:gap-[12px] gap-[21px] flex-row sm:gap-10 items-center justify-between w-full flex-wrap">
                        <select
                          className="sm:h-[30px]  h-[45px] mqtab1:h-[2.9vw] mqtab1:py-0  sm:w-[75vw] sm:text-[10px] pref1 mqlap:text-[0.85rem] mqtab1:text-[0.89vw] !border-colors !rounded-[12px] border border-solid sm:flex-1  w-[46%] sm:w-full"
                          value={expected_role_1}
                          onChange={(e) =>
                            handleInputChange(e, setExpectedRole1)
                          }
                          disabled={editable}
                          required
                        >
                          <option value="Select role" selected disabled>
                            Select role
                          </option>
                          {jobRoles
                            .sort((a, b) => a.localeCompare(b))
                            .map((each) => (
                              <option key={each} value={each}>
                                {each}
                              </option>
                            ))}
                        </select>
                        <select
                          required
                          className="sm:h-[30px]  h-[45px] mqtab1:h-[2.9vw] mqtab1:py-0  sm:w-[75vw] sm:text-[10px] pref1 mqlap:text-[0.85rem] mqtab1:text-[0.89vw] !border-colors !rounded-[12px] border border-solid sm:flex-1  w-[46%] sm:w-full"
                          value={expected_role_2}
                          disabled={editable}
                          onChange={(e) =>
                            handleInputChange(e, setExpectedRole2)
                          }
                        >
                          <option value="" selected>
                            Select role
                          </option>
                          {jobRoles
                            .sort((a, b) => a.localeCompare(b))
                            .map((each) => (
                              <option key={each} value={each}>
                                {each}
                              </option>
                            ))}
                        </select>
                        <select
                          required
                          className="sm:h-[30px]  h-[45px] mqtab1:h-[2.9vw] mqtab1:py-0  sm:w-[75vw] sm:text-[10px] pref1 mqlap:text-[0.85rem] mqtab1:text-[0.89vw] !border-colors !rounded-[12px] border border-solid sm:flex-1  w-[46%] sm:w-full"
                          value={expected_role_3}
                          disabled={editable}
                          onChange={(e) =>
                            handleInputChange(e, setExpectedRole3)
                          }
                        >
                          <option value="" selected>
                            Select role
                          </option>
                          {jobRoles
                            .sort((a, b) => a.localeCompare(b))
                            .map((each) => (
                              <option key={each} value={each}>
                                {each}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-2 items-start justify-start sm:w-full  sm:mt-[10px]  mt-[25px] w-full">
                    <div className="flex flex-col items-center justify-start ">
                      <Text
                        style={{ color: "#6f7074" }}
                        className="font-semibold sm:text-[12px] text-base text-blue_gray-400  mqlap:text-[0.91rem] mqtab1:text-[1.02vw]"
                      >
                        Expected salary *
                      </Text>
                    </div>
                    <div className="flex sm:flex-col sm:gap-[12px] gap-[21px] flex-row sm:gap-10 items-center justify-between w-full flex-wrap">
                      <input
                        required
                        style={{ borderColor: "#E3E4E8" }}
                        min={0}
                        name="textbox"
                        type="text"
                        placeholder="Enter expected salary"
                        className="sm:h-[30px] placeholder-gray-300 h-[45px] mqtab1:h-[2.9vw] mqtab1:py-0  sm:w-[75vw] sm:text-[10px] mqlap:text-[0.85rem] mqtab1:text-[0.89vw] leading-[normal]   text-[15px] text-left !border-colors !rounded-[12px] border border-solid sm:flex-1 w-[46%] sm:w-full p-3"
                        value={expected_salary}
                        onChange={(e) => {
                          const value = e.target.value;
                          const isNumber = /^[0-9]*$/;
                          if (isNumber.test(value)) {
                            handleInputChange(e, setExpectedSalary);
                          } else {
                            setSalaryError(true);
                            setTimeout(() => {
                              setSalaryError(false);
                            }, 1500);
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col items-start   justify-start sm:mt-[10px] mt-[31px] pb-[5px] pr-[5px] w-full">
                    <div className="flex flex-col items-start justify-start w-full">
                      <Tooltip
                        title={
                          <Typography fontSize={16}>
                            Enter Profile details of preferred platforms to
                            allow RecRoid to apply to jobs on your behalf.
                          </Typography>
                        }
                        arrow
                        placement="right"
                        className="flex flex-row items-center justify-start gap-[10px]"
                      >
                        <div>
                          {" "}
                          <Text
                            style={{ color: "#6f7074" }}
                            className="font-semibold  sm:text-sm text-base text-blue_gray-400  mqlap:text-[0.91rem] mqtab1:text-[1.02vw]"
                          // size="txtInterRegular16"
                          >
                            Auto Job search engine
                          </Text>
                          <img src="images/infoIcon.svg" alt="info icon" />
                        </div>
                      </Tooltip>
                      <div className="flex flex-col items-start justify-end p-[23px] sm:px-5 w-full">
                        <div className="flex flex-row gap-[15px] items-center justify-start ml-0.5 md:ml-[0] mt-3.5 w-auto md:w-full">
                          <div>
                            <div className="checkbox-wrapper-22 ">
                              <label
                                className="switch mqtab1:w-[4.5vw] mqtab1:h-[2.5vw]"
                                htmlForor="checkbox"
                              >
                                <input
                                  required
                                  onChange={handleToggleChange}
                                  type="checkbox"
                                  checked={toggleStatus}
                                  id="checkbox"
                                  className="mqlap:h-[1vw] mqtab1:h-[1vw]"
                                />
                                <div className="slider round"></div>
                              </label>
                            </div>
                          </div>

                          <div className="flex flex-col items-center justify-center w-auto">
                            <Text className="text-base text-blue_gray-400  mqlap:text-[0.91rem] mqtab1:text-[1.02vw] w-auto">
                              Enable or disable Auto job apply
                            </Text>
                          </div>
                        </div>

                        <Dialog
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          maxWidth="xxl"
                        >
                          <div className="flex flex-col items-center justify-center min-w-[400px] w-auto min-h-[200px]  h-auto">
                            <Text
                              className="text-base  w-auto pb-[50px]"
                              size="txtInterRegular16"
                              id="alert-dialog-description"
                            >
                              Are you sure to turn {toggleStatus ? "off" : "on"}{" "}
                              the Auto Job Application?
                            </Text>
                            <div className="flex flex-row items-center justify-around w-full">
                              <Button
                                className="rounded-[12px]"
                                style={{
                                  backgroundColor: "#fff",
                                  color: "#314ca3",
                                  border: "3px solid #314ca3",
                                  width: "100px",
                                  height: "35px",
                                }}
                                onClick={() =>
                                  handleClose(stateToggle ? true : false)
                                }
                              >
                                No
                              </Button>

                              <Button
                                className="w-[100px]"
                                onClick={() =>
                                  handleClose(stateToggle ? false : true)
                                }
                              >
                                Yes
                              </Button>
                            </div>
                          </div>
                        </Dialog>
                      </div>
                      <div className="flex-wrap flex md:flex-row  flex-row md:gap-5   items-center justify-between flex-wrap mt-2.5 w-full">
                        <div className="rounded-[12px]  pb-[20px] pt-[20px] flex flex-col items-center w-[47%] gap-[20px] p-[10px] bg-gray-100">
                          {" "}
                          <select
                            // //disabled={!toggleStatus}
                            required
                            className="sm:h-[30px]  h-[45px] mqtab1:h-[2.9vw] mqtab1:py-0  sm:w-[75vw] sm:text-[10px] pref1 mqlap:text-[0.85rem] mqtab1:text-[0.89vw] leading-[normal]   border border-solid !border-colors !rounded-[12px] text-[15px] 
                             text-left w-full"
                            value={job_search_engine_preference_1}
                            onChange={(e) =>
                              handleInputChangeJobPref(
                                e,
                                setPreference1,
                                setUsername1,
                                setPassword1
                              )
                            }
                          >
                            {preferences.map((preference, index) => (
                              <option
                                key={index}
                                value={preference.key}
                                disabled={preference.disabled}
                              >
                                {preference.website}
                              </option>
                            ))}
                          </select>
                          <input
                            // //disabled={!toggleStatus}
                            value={search_name_user_1}
                            onChange={(e) => handleInputChange(e, setUsername1)}
                            required={job_search_engine_preference_1 !== "none"}
                            name="preferenceCounter_One"
                            placeholder="Username 1"
                            className="sm:h-[30px]  placeholder-gray-300 h-[45px] mqtab1:h-[2.9vw] mqtab1:py-0  sm:w-[75vw]  md:w-[75vw] sm:text-[10px] mqlap:text-[0.85rem] mqtab1:text-[0.89vw] leading-[normal]   text-[15px] text-left !border-colors !rounded-[12px] border border-solid p-4 w-full border-[#e3e4e8]"
                          />
                          <div className=" bg-white-A700 password4 w-full passboarder2 pass d-flex flex-row sm:w-[75vw] border-[#e3e4e8]">
                            <div className=" w-[95%] sm:w-[90%]">
                              <input
                                //disabled={!toggleStatus}
                                required={
                                  job_search_engine_preference_1 !== "none"
                                }
                                style={{ borderColor: "transparent" }}
                                type={type1}
                                value={search_password_user_1}
                                onChange={(e) =>
                                  handleInputChange(e, setPassword1)
                                }
                                name="preferenceCounter_Nine"
                                placeholder="Password 1"
                                className="sm:h-[30px]  h-[45px] placeholder-gray-300 mqtab1:h-[2.9vw] mqtab1:py-0  sm:w-[75vw]  md:w-[75vw] sm:text-[10px] mqlap:text-[0.85rem] mqtab1:text-[0.89vw] leading-[normal] p-3     text-[15px] text-left !border-colors !rounded-[12px] w-full  "
                              />
                            </div>
                            <div className="!border-colors !rounded-[12px]  ">
                              <span
                                className="flex  justify-end items-center mr-3 sm:mr-5 sm:ml-3"
                                onClick={changeVisibility1}
                              >
                                <Icon icon={icon1} size={15} />
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* {!dataFetch && <MainLoader />} */}
                        <div className="rounded-[12px]  pb-[20px] pt-[20px] flex flex-col items-center w-[47%] gap-[20px] p-[10px] bg-gray-100 ">
                          {" "}
                          <select
                            //disabled={!toggleStatus}
                            required
                            className="sm:h-[30px]  h-[45px] mqtab1:h-[2.9vw] mqtab1:py-0  sm:w-[75vw] sm:text-[10px] pref1 mqlap:text-[0.85rem] mqtab1:text-[0.89vw] leading-[normal]   !border border-solid !border-colors !rounded-[12px] text-[15px]  text-left w-full"
                            value={job_search_engine_preference_2}
                            onChange={(e) =>
                              handleInputChangeJobPref(
                                e,
                                setPreference2,
                                setUsername2,
                                setPassword2
                              )
                            }
                          >
                            {preferences.map((preference, index) => (
                              <option
                                key={index}
                                value={preference.key}
                                disabled={preference.disabled}
                              >
                                {preference.website}
                              </option>
                            ))}
                          </select>
                          <input
                            //disabled={!toggleStatus}
                            required={job_search_engine_preference_2 !== "none"}
                            value={search_name_user_2}
                            onChange={(e) => handleInputChange(e, setUsername2)}
                            name="preferenceCounter_Three"
                            placeholder="Username 2"
                            className="p-4 sm:h-[30px] placeholder-gray-300 h-[45px] mqtab1:h-[2.9vw] mqtab1:py-0  sm:w-[75vw] md:w-[75vw] sm:text-[10px] mqlap:text-[0.85rem] mqtab1:text-[0.89vw] leading-[normal]   text-[15px] text-left !border-colors !rounded-[12px] border border-solid border-[#e3e4e8] w-full"
                            size="sm"
                          />
                          <div className=" bg-white-A700 password4 w-full passboarder2 pass d-flex flex-row !border-colors !rounded-[12px] sm:w-[75vw] border-[#e3e4e8]">
                            <div className=" w-[95%] sm:w-[89%]">
                              <input
                                //disabled={!toggleStatus}
                                required={
                                  job_search_engine_preference_2 !== "none"
                                }
                                value={search_password_user_2}
                                onChange={(e) =>
                                  handleInputChange(e, setPassword2)
                                }
                                style={{ borderColor: "transparent" }}
                                type={type2}
                                name="preferenceCounter_Four"
                                placeholder="Password 2"
                                className="sm:h-[30px] placeholder-gray-300  h-[45px] mqtab1:h-[2.9vw] mqtab1:py-0  sm:w-[75vw]  md:w-[75vw] sm:text-[10px] mqlap:text-[0.85rem] mqtab1:text-[0.89vw] leading-[normal] p-3    text-[15px] text-left !border-colors !rounded-[12px] w-full"
                              />
                            </div>
                            <div className="!border-colors !rounded-[12px] border-[#e3e4e8] ">
                              <span
                                className="flex  justify-end items-center mr-3 sm:mr-5 sm:ml-3"
                                onClick={changeVisibility2}
                              >
                                <Icon icon={icon2} size={15} />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="rounded-[12px]  pb-[20px] pt-[20px] flex flex-col items-center w-[47%] gap-[20px] p-[10px] bg-gray-100  mt-[40px]">
                          {" "}
                          <select
                            //disabled={!toggleStatus}
                            required
                            className="sm:h-[30px]  h-[45px] mqtab1:h-[2.9vw] mqtab1:py-0  sm:w-[75vw] sm:text-[10px] pref1 mqlap:text-[0.85rem] mqtab1:text-[0.89vw] leading-[normal]   border border-solid !border-colors !rounded-[12px] text-[15px]  text-left w-full"
                            value={job_search_engine_preference_3}
                            onChange={(e) =>
                              handleInputChangeJobPref(
                                e,
                                setPreference3,
                                setUsername3,
                                setPassword3
                              )
                            }
                          >
                            {preferences.map((preference, index) => (
                              <option
                                key={index}
                                value={preference.key}
                                disabled={preference.disabled}
                              >
                                {preference.website}
                              </option>
                            ))}
                          </select>
                          <input
                            //disabled={!toggleStatus}
                            required
                            value={search_name_user_3}
                            onChange={(e) => handleInputChange(e, setUsername3)}
                            name="preferenceCounter_Five"
                            placeholder="Username 3"
                            className="p-4 sm:h-[30px] placeholder-gray-300 h-[45px] mqtab1:h-[2.9vw] mqtab1:py-0  sm:w-[75vw] sm:text-[10px] mqlap:text-[0.85rem] mqtab1:text-[0.89vw] leading-[normal]    text-[15px] text-left !border-colors !rounded-[12px] border border-solid border-[#e3e4e8] w-full"
                            size="sm"
                          />
                          <div className=" bg-white-A700 password4 passboarder2 pass d-flex flex-row  w-full sm:w-[75vw] border-[#e3e4e8]">
                            <div className=" w-[95%] sm:w-[89%]">
                              <input
                                //disabled={!toggleStatus}
                                required
                                value={search_password_user_3}
                                onChange={(e) =>
                                  handleInputChange(e, setPassword3)
                                }
                                style={{ borderColor: "transparent" }}
                                type={type3}
                                name="preferenceCounter_Six"
                                placeholder="Password 3"
                                className="sm:h-[30px]  placeholder-gray-300 h-[45px] mqtab1:h-[2.9vw] mqtab1:py-0  sm:w-[75vw]  md:w-[75vw] sm:text-[10px] mqlap:text-[0.85rem] mqtab1:text-[0.89vw] leading-[normal] p-3    text-[15px] text-left !border-colors !rounded-[12px] w-full"
                              />
                            </div>
                            <div className="!border-colors !rounded-[12px] border-[#e3e4e8] ">
                              <span
                                className="flex  justify-end items-center mr-3 sm:mr-5 sm:ml-3"
                                onClick={changeVisibility3}
                              >
                                <Icon icon={icon3} size={15} />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="rounded-[12px]  pb-[20px] pt-[20px] flex flex-col items-center w-[47%] gap-[20px] p-[10px] bg-gray-100 mt-[40px]">
                          <select
                            //disabled={!toggleStatus}
                            required
                            className="sm:h-[30px]  h-[45px] mqtab1:h-[2.9vw] mqtab1:py-0  sm:w-[75vw] sm:text-[10px] pref1 mqlap:text-[0.85rem] mqtab1:text-[0.89vw] leading-[normal]   border border-solid !border-colors !rounded-[12px] text-[15px] text-left w-full"
                            value={job_search_engine_preference_4}
                            onChange={(e) =>
                              handleInputChangeJobPref(
                                e,
                                setPreference4,
                                setUsername4,
                                setPassword4
                              )
                            }
                          >
                            {preferences.map((preference, index) => (
                              <option
                                key={index}
                                value={preference.key}
                                disabled={preference.disabled}
                              >
                                {preference.website}
                              </option>
                            ))}
                          </select>
                          <input
                            //disabled={!toggleStatus}
                            required={job_search_engine_preference_4 !== "none"}
                            value={search_name_user_4}
                            onChange={(e) => handleInputChange(e, setUsername4)}
                            name="preferenceCounter_Seven"
                            placeholder="Username 4"
                            className="p-4 md:p-[0px] sm:h-[30px] placeholder-gray-300 h-[45px] mqtab1:h-[2.9vw] mqtab1:py-0   md:w-[75vw] sm:w-[75vw] sm:text-[10px] mqlap:text-[0.85rem] mqtab1:text-[0.89vw] leading-[normal]   text-[15px] text-left !border-colors !rounded-[12px] border border-solid border-[#e3e4e8]  w-full"
                            size="sm"
                          />

                          <div className=" bg-white-A700 password4 passboarder2 pass d-flex flex-row  w-full  sm:w-[75vw] border-[#e3e4e8]">
                            <div className=" w-[95%] sm:w-[89%]">
                              <input
                                //disabled={!toggleStatus}
                                required={
                                  job_search_engine_preference_4 !== "none"
                                }
                                value={search_password_user_4}
                                onChange={(e) =>
                                  handleInputChange(e, setPassword4)
                                }
                                style={{ borderColor: "transparent" }}
                                type={type4}
                                name="preferenceCounter_Eight"
                                placeholder="Password 4"
                                className="sm:h-[30px]  placeholder-gray-300 h-[45px] mqtab1:h-[2.9vw] mqtab1:py-0  sm:w-[75vw]  md:w-[75vw] sm:text-[10px] mqlap:text-[0.85rem] mqtab1:text-[0.89vw] leading-[normal] p-3    text-[15px] text-left !border-colors !rounded-[12px] w-full"
                              />
                            </div>
                            <div className="!border-colors !rounded-[12px] ">
                              <span
                                className="flex  justify-end items-center mr-3 sm:mr-5 sm:ml-3"
                                onClick={changeVisibility4}
                              >
                                <Icon icon={icon4} size={15} />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row justify-end">
                  {saveStatePrep ? (
                    <Button
                      style={{ border: "2px solid #314ca3" }}
                      className=" sm:text-[12px]  px-[3.4rem] py-[0.9rem] text-[1.2rem]  mqlap:px-[2.85vw] mqlap:rounded-[12px] mqtab1:rounded-[12px]   mqlap:py-[0.7vw] mqlap:text-[1vw] mqtab1:px-[2.9vw]  mqtab1:py-[0.7vw]  mqtab1:text-[1vw] bg-gray-100 text-indigo-600  border-indigo-600 cursor-pointer font-medium  leading-[normal] md:ml-0 text-center text-lg  flex flex-row justify-center items-center "
                      size="md"
                      type="submit"
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      onClick={userPreferncesData}
                      type="submit"
                      className=" sm:text-[12px]  px-[3.4rem] py-[0.9rem] text-[1.2rem]  mqlap:px-[2.85vw] mqlap:rounded-[12px] mqtab1:rounded-[12px]   mqlap:py-[0.7vw] mqlap:text-[1vw] mqtab1:px-[2.9vw]  mqtab1:py-[0.7vw]  mqtab1:text-[1vw] bg-gray-100 text-indigo-600  border-indigo-600 cursor-pointer font-medium  leading-[normal] md:ml-0 text-center text-lg  flex flex-row justify-center items-center "
                      size="md"
                    >
                      Save
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePreference;
