import styled from "styled-components";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import supabase from "components/superBaseConfig";

const RecruiterRoboLogo31Icon = styled.img`
  position: absolute;
  top: 11.5px;
  left: 0px;
  width: 80.2px;
  height: 90.1px;
  object-fit: fill;
`;
const RecruiterRoboLogo31Wrapper = styled.div`
  position: relative;
  width: 97.2px;
  height: 117px;
  overflow: hidden;
  flex-shrink: 0;
`;
const WebsiteName = styled.div`
  position: relative;
  line-height: 135.5%;
  font-weight: 600;
  display: inline-block;
  width: 213.3px;
  height: 34.2px;
  flex-shrink: 0;
  color: #161616;
`;
const FrameParent = styled.div`
  position: absolute;
  top: 50px;
  left: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const PasswordReset = styled.div`
  position: relative;
  font-size: var(--font-size-13xl);
  font-weight: 600;
  color: var(--color-gray-100);
  z-index: 0;
`;
const PleaseEnterA = styled.div`
  position: relative;
  color: var(--color-darkslategray-100);
  z-index: 1;
`;
const InputLabelmedium = styled.div`
  position: absolute;
  top: 0%;
  left: calc(50% - 338.15px);
  line-height: 37.15px;
`;
const Component2Child = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 338.15px);
  border-radius: 7.69px;
  background-color: var(--color-white);
  width: 676.3px;
  height: 64px;
`;
const InvisibleIcon = styled.img`
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% + 282.85px);
  width: 24px;
  height: 24px;
  object-fit: cover;
`;
const Div = styled.div`
  position: absolute;
  top: 15px;
  left: 31px;
  line-height: 33.3px;
`;
const Component2 = styled.div`
  position: absolute;
  top: 42px;
  left: calc(50% - 338.15px);
  width: 676.3px;
  height: 64px;
  text-align: center;
  font-size: 20.49px;
  color: var(--color-black);
`;
const Email = styled.div`
  position: relative;
  width: 676.3px;
  height: 106px;
  z-index: 2;
  font-size: 23.06px;
  font-family: var(--font-inter);
`;
const Email1 = styled.div`
  position: relative;
  width: 676.3px;
  height: 106px;
  z-index: 3;
  font-size: 23.06px;
  font-family: var(--font-inter);
`;
const YourPasswordMust = styled.div`
  position: relative;
  color: #605e5c;
  display: inline-block;
  width: 653px;
  z-index: 4;
`;
const SubmitChild = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 338.15px);
  border-radius: 7.69px;
  background-color: var(--color-darkslateblue);
  width: 676.3px;
  height: 64px;
`;
const LogIn = styled.div`
  position: absolute;
  top: calc(50% - 17px);
  left: calc(50% - 95.15px);
  line-height: 33.3px;
`;
const Submit = styled.div`
  position: relative;
  width: 676.3px;
  height: 64px;
  z-index: 5;
  text-align: center;
  font-size: 20.49px;
  color: var(--color-white);
  font-family: var(--font-inter);
`;
const FrameChild = styled.div`
  position: relative;
  border-top: 1px solid rgba(184, 184, 184, 0.57);
  box-sizing: border-box;
  width: 654px;
  height: 1px;
  z-index: 6;
`;
const NeedAdditonalHelp = styled.span``;
const ContactHrPortal = styled.span`
  text-decoration: underline;
  color: var(--color-gray-100);
`;
const NeedAdditonalHelpContainer = styled.div`
  position: absolute;
  margin: 0 !important;
  top: 675.1px;
  left: calc(50% - 215.15px);
  font-size: 18px;
  text-align: center;
  z-index: 7;
  color: #9c9c9c;
`;
const PasswordResetParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  gap: 36px;
`;
const ResetPasswordenterNewPassInner = styled.div`
  position: absolute;
  top: calc(50% - 320px);
  left: calc(50% - 340px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--font-size-3xl);
  color: var(--color-darkslategray-200);
`;
const ResetPasswordenterNewPassRoot = styled.div`
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  height: 1080px;
 overflow: scroll;
  text-align: left;
  font-size: 26.06px;
  color: var(--color-gray-300);
  font-family: var(--font-poppins);
`;

const TabResetPasswordenterNewPass = () => {
  const url = window.location.href;
  const urlParams = new URLSearchParams(url.split("#")[1]);
  const accessToken = urlParams.get("access_token");
  let history = useNavigate();

  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

  const onLogo = () => {
    history("/");
  };

  const changePassword = async (event) => {
    event.preventDefault();

    if (new_password !== confirm_password) {
      alert("Passwords do not match");
      return;
    }

    try {
      const { data, error } = await supabase.auth.updateUser({
        password: new_password,
      });
      if (error) {
        throw Error(error.message);
      }
      if (data) {
        alert("Password changed");
        history("/login");
      }
    } catch (e) {
      alert(JSON.stringify(e.message));
    }
  };

  return (
    <ResetPasswordenterNewPassRoot>
      <FrameParent>
        <RecruiterRoboLogo31Wrapper>
          <RecruiterRoboLogo31Icon
            alt=""
            src="/recroid.png"
            onClick={onLogo}
            style={{ cursor: "pointer" }}
          />
        </RecruiterRoboLogo31Wrapper>
        <WebsiteName
          onClick={onLogo}
          style={({ cursor: "pointer" }, { color: "rgb(49 76 163)" })}
        >
          RecRoid
        </WebsiteName>
      </FrameParent>
      <form>
        <ResetPasswordenterNewPassInner>
          <PasswordResetParent>
            <PasswordReset>Password reset</PasswordReset>
            <PleaseEnterA>
              Please enter a new password for your HR Portal account.
            </PleaseEnterA>
            <Email>
              <InputLabelmedium>Enter new password</InputLabelmedium>
              <Component2>
                <Component2Child />
                <input
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    borderRadius: "9.61px",
                    backgroundColor: "var(--color-white)",
                    border: "1.2px solid var(--color-gainsboro)",
                    boxSizing: "border-box",
                    width: "100%",
                    height: "62.5px",
                  }}
                  type="password"
                  placeholder="Enter new password"
                  required
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Component2>
            </Email>
            <Email1>
              <InputLabelmedium>Confirm new password</InputLabelmedium>
              <Component2>
                <Component2Child />
                <input
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    borderRadius: "9.61px",
                    backgroundColor: "var(--color-white)",
                    border: "1.2px solid var(--color-gainsboro)",
                    boxSizing: "border-box",
                    width: "100%",
                    height: "62.5px",
                  }}
                  type="password"
                  placeholder="Confirm new password"
                  required
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Component2>
            </Email1>
            <YourPasswordMust>
              Your password must be at least 8 characters long. Avoid common
              words or patterns.
            </YourPasswordMust>

            <button type="submit" onClick={changePassword}>
              <Submit>
                <SubmitChild />
                <LogIn>Reset my password</LogIn>
              </Submit>
            </button>

            <FrameChild />
            <NeedAdditonalHelpContainer>
              <NeedAdditonalHelp>{`Need additonal help? `}</NeedAdditonalHelp>
              <ContactHrPortal>Contact HR Portal support</ContactHrPortal>
            </NeedAdditonalHelpContainer>
          </PasswordResetParent>
        </ResetPasswordenterNewPassInner>
      </form>
    </ResetPasswordenterNewPassRoot>
  );
};

export default TabResetPasswordenterNewPass;
