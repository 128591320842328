const initialState = {
    profilePicture: "images/defaultprofilepicture.svg",
    loading: false,
    error: null,
  };
  
  const profilePictureReducer = (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_PROFILE_PICTURE_REQUEST":
        return {
          ...state,
          loading: true,
          error: null,
        };
      case "FETCH_PROFILE_PICTURE_SUCCESS":
        return {
          ...state,
          loading: false,
          profilePicture: action.payload,
        };
      case "FETCH_PROFILE_PICTURE_FAILURE":
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default profilePictureReducer;
  