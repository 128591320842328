import React from 'react';
import Container from "components/Container/Container";
import WebsiteFooter from 'components/WebsiteFooter/WebsiteFooter';


const TermsAndConditions = () => {
  return (
    <><Container mainhead="Terms Of Use"/> 
    <div className='flex flex-col gap-[30px] px-[60px] py-[80px] mb-[7rem]  mqmob1:px-[45px] '>
    {/* <div className='p-[35px] pb-[80px]'> */}
      {/* <div className="p-6 leading-relaxed">
         <h1 className="text-4xl text-indigo-800 font-bold  mb-3 mt-2 ">Terms Of Use For RecRoid</h1>
      </div> */}
       <div>
      <p className=" text-[19px] leading-relaxed mb-[10px]"  ><strong>Effective Date:</strong> 1st June 2024</p>
      <p className=" text-[18px] leading-relaxed  ">Welcome to RecRoid! These terms and conditions outline the rules and regulations for the use of RecRoid's Website and Services, located at {" "}
      <a href="https://www.recroid.com/" className=" text-[#006A94]" target="_blank">
            recroid.com
          </a>{" "}
      </p>
      </div>
      <div className='flex flex-col gap-[35px] mqtab1:gap-[40px] mqtab2:gap-[40px] mqmob1:gap-[37px]'>
      <div>
      <h2 className="text-2xl text-indigo-800 font-bold   mb-3 mt-2 ">1. Introduction</h2>
      <p className=" text-[18px] leading-relaxed  ">By accessing this website and using our services, we assume you accept these terms and conditions in full. Do not continue to use RecRoid's website or services if you do not accept all of the terms and conditions stated on this page.</p>
      </div>

      <div>
      <h2 className="text-2xl text-indigo-800 font-bold  mb-3 mt-2 ">2. Modifications to Terms</h2>
      <p className=" text-[18px] leading-relaxed  ">RecRoid reserves the right to revise these terms at any time. Any changes will be effective immediately upon posting on our website. Your continued use of the Service following the posting of changes will mean you accept and agree to the changes.</p>
      </div>

      <div>
      <h2 className="text-2xl text-indigo-800 font-bold  mb-3 mt-2 ">3. Description of Service</h2>
      <p className=" text-[18px] leading-relaxed ">RecRoid is an AI-powered recruitment platform designed to streamline the job search and recruitment process for both job seekers and recruiters.</p>
      <p className=" text-[19px] mb-[10px] mt-[20px]"><strong>For Job Seekers:</strong></p>
      <ul className=" text-[18px] mb-[10px] list-disc pl-[20px]">
        <li className='mb-[7px]'>Effortless job search across 25+ platforms</li>
        <li className='mb-[7px]'>Personalized job matching from 50+ platforms</li>
        <li className='mb-[7px]'>Tools for crafting resumes and cover letters</li>
        <li className='mb-[7px]'>Interview preparation insights and tips</li>
        <li className='mb-[7px]'>Career path exploration with personalized advice</li>
      </ul>
      <p className=" text-[19px] mb-[10px] mt-[25px]"><strong>For Recruiters:</strong></p>
      <ul className=" text-[18px] leading-relaxed list-disc pl-[20px]">
        <li className='mb-[7px]'>Integrated email and candidate profile management</li>
        <li className='mb-[7px]'>Intelligent candidate management with detailed records</li>
        <li className='mb-[7px]'>Automated assessments for unbiased shortlisting</li>
        <li className='mb-[7px]'>Simplified interview scheduling</li>
        <li className='' >Automated feedback and result communication</li>
      </ul>
      </div>

      <div>
      <h2 className="text-2xl text-indigo-800 font-bold  mb-3 mt-2 ">4. Eligibility</h2>
      <p className=" text-[18px] leading-relaxed  " >You must be at least 18 years old to use our Service. By using the Service, you represent and warrant that you meet the eligibility requirements.</p>
      </div>

      <div>
      <h2 className="text-2xl text-indigo-800 font-bold  mb-3 mt-2 ">5. Account Registration</h2>
      <p className=" text-[18px] leading-relaxed  ">To access certain features of the Service, you must register for an account. You are responsible for maintaining the confidentiality of your account information and password and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.</p>
      </div>

      <div>
      <h2 className="text-2xl text-indigo-800 font-bold  mb-3 mt-2 ">6. Subscription and Payment</h2>
      <p className=" text-[18px] leading-relaxed ">RecRoid offers various subscription plans. Payments are processed on a monthly or annual basis, and you agree to pay the fees associated with your chosen plan. All payments are non-refundable, except as required by law. RecRoid reserves the right to change subscription plans and fees at any time, with prior notice.</p>
      </div>

      <div>
      <h2 className="text-2xl text-indigo-800 font-bold  mb-3 mt-2 ">7. No Refund Policy</h2>
      <p className=" text-[18px] leading-relaxed mb-[10px]">Thank you for choosing Recroid. Please read our No Refund Policy carefully before making a purchase. By purchasing any of our services, you agree to the terms below.</p>
      <ul className=" text-[18px] leading-relaxed list-disc pl-[20px]">
        
        <li className='mb-[7px] font-bold mt-[12px] text-[19px] '>General Policy</li>
        <p className=" text-[18px] leading-relaxed ">All sales are final. Purchases are non-refundable and non-transferable.</p>
        
        <li className='mb-[7px] font-bold mt-[10px] text-[19px] mt-[10px]'>Service Description</li>
        <p className=" text-[18px] leading-relaxed ">Our services include auto job applications, job listings, resume and cover letter creation, interview preparation, career advising, email integration, centralized databases, automated assessments, profile shortlisting, interview scheduling, and result communication.</p>
        
        <li className='mb-[7px] font-bold mt-[10px] text-[19px] mt-[10px]'> No Refunds </li>
        <p className=" text-[18px] leading-relaxed ">Refunds are not provided under any circumstances, including changes of mind, dissatisfaction, or technical issues not caused by Recroid.</p>
        
        <li className='mb-[7px] font-bold mt-[10px] text-[19px] mt-[10px]'>Modifications </li>
        <p className=" text-[18px] leading-relaxed ">Recroid may update this policy at any time. Changes are effective immediately upon posting on our website.</p>
        
        <li className='mb-[7px] font-bold mt-[10px] text-[19px] mt-[10px]'> Contact Us </li>
        <p className=" text-[18px] leading-relaxed "> For questions or concerns, please contact us at:  <a href="mailto:support@culinda.com" className="text-[#006A94]">support@culinda.com</a> </p>
        <p  className='leading-relaxed '>By purchasing our services, you agree to this No Refund Policy.</p>
      </ul>


      </div>

      <div>
      <h2 className="text-2xl text-indigo-800 font-bold  mb-3 mt-2 ">8. User Responsibilities</h2>
      <p className=" text-[18px] leading-relaxed ">You agree to use the Service only for lawful purposes and in accordance with these Terms. You must not misuse the Service by knowingly introducing viruses, trojans, worms, logic bombs, or other material that is malicious or technologically harmful.</p>
      </div>

      <div>
      <h2 className="text-2xl text-indigo-800 font-bold  mb-3 mt-2 ">9. Intellectual Property</h2>
      <p className=" text-[18px] leading-relaxed ">All content, trademarks, and data on this website, including but not limited to software, databases, text, graphics, icons, hyperlinks, private information, designs, and agreements, are the property of RecRoid and are protected from infringement by local and international legislation and treaties. You are granted a limited license only for purposes of viewing the material contained on this website.</p>
      </div>

      <div>
      <h2 className="text-2xl text-indigo-800 font-bold  mb-3 mt-2 ">10. Confidentiality</h2>
      <p className=" text-[18px] leading-relaxed ">Both parties agree to maintain the confidentiality of any confidential information shared during the use of the Service. Confidential information includes any data or information that is proprietary to either party and not generally known to the public.</p>
      </div>

      <div>
      <h2 className="text-2xl text-indigo-800 font-bold  mb-3 mt-2 ">11. Data Privacy</h2>
      <p className=" text-[18px] leading-relaxed ">Your privacy is important to us. Please review our Privacy Policy, which governs the use of personal information on our website and our data collection practices. By using the Service, you consent to the collection and use of your data as outlined in the Privacy Policy.</p>
      </div>

      <div>
      <h2 className="text-2xl text-indigo-800 font-bold  mb-3 mt-2 ">12. Limitation of Liability</h2>
      <p className=" text-[18px] leading-relaxed ">RecRoid will not be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.</p>
      </div>

      <div>
      <h2 className="text-2xl text-indigo-800 font-bold  mb-3 mt-2 ">13. Indemnification</h2>
      <p className=" text-[18px] leading-relaxed  ">You agree to indemnify, defend, and hold harmless RecRoid, its officers, directors, employees, and agents, from any claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) made by any third party due to or arising out of your use of the Service, your violation of these Terms, or your violation of any rights of another.</p>
      </div>

      <div>
      <h2 className="text-2xl text-indigo-800 font-bold  mb-3 mt-2 ">14. Termination</h2>
      <p className=" text-[18px] leading-relaxed ">RecRoid may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under its sole discretion, for any reason whatsoever, including without limitation a breach of these Terms. Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.</p>
      </div>

      <div>
      <h2 className="text-2xl text-indigo-800 font-bold  mb-3 mt-2 ">15. Governing Law and Dispute Resolution</h2>
      <p className=" text-[18px] leading-relaxed ">These Terms are governed by and construed in accordance with the laws of India. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of India.</p>
      </div>

      <div>
      <h2 className="text-2xl text-indigo-800 font-bold  mb-3 mt-2 ">16. Miscellaneous</h2>
      <p className=" text-[18px] leading-relaxed mb-[5px] mqtab1:mb-[10px] mqtab2:mb-[10px] mqmob1:mb-[13px]"><strong>Severability:</strong> If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will continue in full force and effect.</p>
      <p className=" text-[18px] leading-relaxed mb-[5px] mqtab1:mb-[10px] mqtab2:mb-[10px] mqmob1:mb-[13px]"><strong>Waiver:</strong> No waiver of any term shall be deemed a further or continuing waiver of such term or any other term.</p>
      <p className=" text-[18px] leading-relaxed mb-[5px] mqtab1:mb-[10px] mqtab2:mb-[10px] mqmob1:mb-[13px]"><strong>Entire Agreement:</strong> These Terms constitute the entire agreement between you and RecRoid regarding the use of the Service.</p>
      </div>

      <div>
      <h2 className="text-2xl text-indigo-800 font-bold  mb-3 mt-2 ">17. Contact Information</h2>
      <p className=" text-[18px] leading-relaxed mb-[5px]  ">If you have any questions about these Terms, please contact us at:</p>
      <p className=" text-[18px] leading-relaxed mb-[5px] ">Company Name: RecRoid</p>
      <p className=" text-[18px] leading-relaxed mb-[5px] ">Address: India, USA</p>
      <p className=" text-[18px] leading-relaxed mb-[5px] ">Email: support@recroid.com</p>
      <p className=" text-[18px] leading-relaxed mb-[5px] ">Phone: +1(213)221-0120</p>
      </div>
      </div>
    </div>
    <div className=" mt-[15rem] mqmob1:mt-[12rem]">
         < WebsiteFooter />
    </div>
    </>
  );
};

export default TermsAndConditions;
