import { Img, Text } from "components";
import { ProfileHeader } from "pages/JobSeekerDashBoardPages/ProfileHeader";
import { SideBar1 } from "pages/JobSeekerDashBoardPages/SideBar1";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./index.css";

const CoverLetter = () => {
  const typesOfCoverLetters = [
    {
      id: 1,
      typeOfLetter: "All Templates",
      selectImageUrl: "images/img_materialsymbolsborderall.svg",
      unselectImageUrl: "images/img_materialsymbolsborderall2.svg",
    },
    {
      id: 2,
      typeOfLetter: "Simple",
      selectImageUrl: "images/img_phfiresimplefill.svg",
      unselectImageUrl: "images/img_phfiresimplefill2.svg",
    },
    {
      id: 3,
      typeOfLetter: "Creative",
      selectImageUrl: "images/img_mdiunicornvariant.svg",
      unselectImageUrl: "images/img_mdiunicornvariant2.svg",
    },
    {
      id: 4,
      typeOfLetter: "Professional",
      selectImageUrl: "images/img_basilbagsolid.svg",
      unselectImageUrl: "images/img_basilbagsolid2.svg",
    },
    {
      id: 5,
      typeOfLetter: "Modern",
      selectImageUrl: "images/img_bistars.svg",
      unselectImageUrl: "images/img_bistars2.svg",
    },
  ];
  const coverLetterTemplatesList = [
    { id: 1, categeryType: "Creative", imageUrl: "images/img_image7.png" },
    { id: 2, categeryType: "Creative", imageUrl: "images/img_image8.png" },
    { id: 3, categeryType: "Creative", imageUrl: "templates/template3.jpg" },
    // { id: 4, categeryType: "Simple", imageUrl: "images/img_image10.png" },
    {
      id: 5,
      categeryType: "Simple",
      imageUrl: "templates/MicrosoftTeams-image.png",
    },
    { id: 6, categeryType: "Simple", imageUrl: "templates/template6.png" },
    { id: 7, categeryType: "Modern", imageUrl: "templates/template7.png" },
    { id: 8, categeryType: "Modern", imageUrl: "templates/template8.png" },
    { id: 9, categeryType: "Modern", imageUrl: "templates/template9.png" },
    {
      id: 10,
      categeryType: "Professional",
      imageUrl: "templates/template10.png",
    },
    {
      id: 11,
      categeryType: "Professional",
      imageUrl: "templates/template11.png",
    },
    {
      id: 12,
      categeryType: "Professional",
      imageUrl: "templates/template12.png",
    },
    {
      id: 13,
      categeryType: "Professional",
      imageUrl: "templates/template13.png",
    },
    {
      id: 14,
      categeryType: "Professional",
      imageUrl: "templates/template14.png",
    },
  ];

  const [savedTemplate, updatetemplate] = useState("All Templates");
  const [selectedCoverLetterTemplates, setselectedCoverLetterTemplates] =
    useState([...coverLetterTemplatesList]);

  const coverLetterType = (type) => {
    updatetemplate(type);

    if (type === "All Templates") {
      setselectedCoverLetterTemplates([...coverLetterTemplatesList]);
    } else {
      const filteredTemplates = coverLetterTemplatesList.filter(
        (each) => each.categeryType === type,
      );
      setselectedCoverLetterTemplates([...filteredTemplates]);
    }
  };

  return (
    <>
      <div className="bg-3 bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full h-[100vh]">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <SideBar1 clickedTab={"Cover Letters"} />
          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full ">
            <ProfileHeader clickedTab={"Cover Letter"} />
            <div className="h-[93vh] main-container bg-white-A700 flex flex-col items-center justify-start rounded-[15px] w-[94%] md:w-full sm:mb-[200px]">
              <div className="flex flex-col h-[100px] md:h-auto items-center justify-start max-w-[1078px] pl-[77px] pr-[82px] md:px-10 sm:px-5 py-[30px] w-full">
                <div className="flex sm:flex-col flex-row md:gap-10 gap-[70px] items-start justify-start sm:justify-center w-auto md:w-full sm:flex-col sm:gap-[10px] mqtab1:gap-[32px] mqlap:gap-[43px]">
                  <div className="flex flex-row gap-5 items-center justify-start w-[20%] md:w-full">
                    <Text
                      size="txtInterSemiBold25"
                      className="sm:text-[16px] bg2 flex sm:h-[30px] sm:w-[30px] h-[50px] items-center justify-center rounded-[50%] sm:text-[21px] md:text-[23px] text-[25px] text-center text-white-A700 tracking-[0.50px] w-[75px] sm:h-[30px] sm:w-[30px] sm:text-[21px] mqlap:text-[21px] mqlap:h-[46px] mqlap:w-[75px] mqtab1:text-[18px] mqtab1:h-[40px] mqtab1:w-[65px]"
                    >
                      1
                    </Text>
                    <Text
                      className="text2 text-blue-A200 text-xl tracking-[0.50px] sm:text-[14px] mqtab1:text-[1.18vw] mqlap:text-[1.15vw] mqtab1:leading-[1.4rem]  mqlap:leading-[1.4rem]  mqlap:leading-[1.5rem]"
                      size="txtInterMedium20"
                    >
                      Choose template
                    </Text>
                  </div>
                  <div className=" flex flex-row gap-[19px] items-center justify-center w-1/3 ">
                    <Text
                      size="txtInterSemiBold25"
                      className="bg3 flex h-[50px] items-center justify-center rounded-[50%] sm:text-[21px] md:text-[23px] text-[25px] text-center text-white-A700 tracking-[0.50px] w-[50px] sm:h-[30px] sm:w-[30px] sm:text-[21px] mqlap:text-[21px] mqlap:h-[46px] mqlap:w-[47px] mqtab1:text-[18px] mqtab1:h-[40px] mqtab1:w-[41px]"
                    >
                      2
                    </Text>
                    <Text
                     className="text3 text-blue_gray-100 text-xl tracking-[0.50px] mqtab1:text-[1.18vw] mqlap:text-[1.15vw] mqtab1:leading-[1.4rem]  mqlap:leading-[1.4rem]  mqlap:leading-[1.5rem]"
                     size="txtInterMedium20Bluegray100"
                    >
                      Enter your details
                    </Text>
                  </div>
                  <div className=" flex flex-row gap-5 items-center justify-center w-1/4">
                    <Text
                      size="txtInterSemiBold25"
                      className="bg3  flex h-[50px] items-center justify-center rounded-[50%] sm:text-[21px] md:text-[23px] text-[25px] text-center text-white-A700 tracking-[0.50px] w-[75px] sm:h-[30px] sm:w-[30px] sm:text-[21px] mqlap:text-[21px] mqlap:h-[46px] mqlap:w-[75px] mqtab1:text-[18px] mqtab1:h-[40px] mqtab1:w-[65px]"
                    >
                      3
                    </Text>
                    <Text
                     className="text3 text-blue_gray-100 text-xl tracking-[0.50px] mqtab1:text-[1.18vw] mqlap:text-[1.15vw] mqtab1:leading-[1.4rem]  mqlap:leading-[1.4rem]  mqlap:leading-[1.5rem]"
                     size="txtInterMedium20Bluegray100"
                    >
                      Download cover letter
                    </Text>
                  </div>
                </div>
              </div>
              <div className="flex flex-col font-poppins items-center justify-start w-full">
                <div className="flex flex-col items-center justify-start w-full">
                  <div className="mt-[40px] flex flex-col items-center justify-start p-[11px] w-full">
                    <div className="sm:overflow-x-auto   sm:justify-start scrollbar-hidden-personal flex  flex-row md:gap-10 gap-[60px] items-start justify-center max-w-[1019px] mb-[15px] mt-[5px] w-full mqlap:gap[45px] mqtab1:gap-[45px]">
                      {typesOfCoverLetters.map((each) => (
                        <div
                          key={each.id}
                          onClick={() => coverLetterType(each.typeOfLetter)}
                          className="cursor-pointer flex md:flex-1 flex-row gap-2.5 items-center justify-start pr-0.5 pt-0.5  md:w-full"
                        >
                          <Img
                            className="h-5 mqtab1:h-4  mqlap:h-[1.2rem]"
                            src={
                              savedTemplate === each.typeOfLetter
                                ? each.selectImageUrl
                                : each.unselectImageUrl
                            }
                            alt="materialsymbols"
                          />
                          <Text
                            className={`text-2xl   sm:text-sm md:text-[22px] mqtab1:text-[1.3vw] mqlap:text-[1.3vw] ${
                              each.typeOfLetter == "All Templates"
                                ? "sm:w-[122px]"
                                : "w-auto"
                            } ${
                              savedTemplate === each.typeOfLetter
                                ? "text-black-900"
                                : "text-black-900_7f"
                            } sm:text-sm`}
                            size={
                              savedTemplate === each.typeOfLetter
                                ? "txtPoppinsSemiBold24"
                                : "txtPoppinsSemiBold24Black9007f"
                            }
                          >
                            {each.typeOfLetter}
                          </Text>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-start w-[97%] pb-4">
                    <div className="flex flex-col items-center justify-start w-full">
                      <div className="gap-2 mb-[20px] md:gap-5 grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 justify-center min-h-[auto] w-full">
                        {selectedCoverLetterTemplates.map((each) => (
                          <div
                            key={each.id}
                            className=" bg-white-A700 border-[0.5px] border-[#6d69697f] border-solid flex flex-1 flex-col items-center justify-start rounded-[12px] w-full h-full mb-[30px]"
                          >
                            <div className="test sm:h-full   md:w-full ">
                              <Link
                                to={"/coverletter-details?imageId=" + each.id}
                              >
                                <Img src={each.imageUrl} alt="imageOne" />
                              </Link>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoverLetter;
