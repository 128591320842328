import { Link } from "react-router-dom";
import { Text, Line } from "components";

const BillingSubHeader = ({ billingheader }) => {
  const headerItems = [
    { id: 1, label: "Overview", link: "/billing-overview" },
    // { id: 2, label: "Payment methods", link: "/billing-payment" },
    { id: 3, label: "Billing history", link: "/billing-history" },
    { id: 4, label: "Preferences", link: "/billing-preferences" },
  ];

  return (
    <div className="flex flex-row sm:gap-10 items-start justify-between pr-3 sm:full w-[60%] md:w-full overflow-x-auto scrollbar-hidden-personal ">
      {headerItems.map(({ id, label, link }) => (
        <div key={id} className="flex flex-col gap-2   items-center justify-start  ">
          <Text
            className={`text-xl sm:text-sm mqlap:text-[1.15vw] mqtab1:text-[1.15vw] ${
              billingheader === id ? "text-indigo-600" : "text-blue_gray-400"
            }`}
            size={billingheader === id ? "txtInterMedium20" : "txtInterMedium20Indigo600"}
          >
            <Link to={link}>{label}</Link>
          </Text>
          {billingheader === id && (
            <Line className="bg-indigo-600 h-[3px] rounded-tl-[10px] rounded-tr-[10px] w-full" />
          )}
        </div>
      ))}
    </div>
  );
};

export default BillingSubHeader;
