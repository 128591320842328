import {Button,Text} from "components";
import "./index.css";
import SettingsSubHeader from "pages/RecruiterDashBoardPages/SettingsSubHeader";
import Sidebar1 from "pages/RecruiterDashBoardPages/RecruiterSidebar";
import TopHeader from "pages/RecruiterDashBoardPages/TopHeader";
const ProjectManager = () => {
  return (
    <>
    <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
            <Sidebar1 clickedTab={"Billing"} />

            <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
                <TopHeader header={"Add Job"} />
                <div className="bg-white-A700 flex flex-col items-center justify-start p-7 sm:px-5 rounded-[15px] w-[94%] md:w-full">
                    <div className="flex flex-col gap-[43px] justify-start mb-[199px] mt-2.5 w-[99%] md:w-full">
                        <SettingsSubHeader billingheader={2} />


                        <div className="flex flex-col items-start justify-start md:ml-[0] ml-[29px] mt-10 w-[98%] md:w-full">
                            <div className="flex flex-row items-start justify-between w-[33%] md:w-full">
                                <Text
                                    className="mt-[7px] text-blue_gray-400 text-xl "
                                    size="txtInterMedium20"
                                >
                                    Role
                                </Text>
                                <select style={{ placeContentcolor: "red" }} className="leading-[normal] text-base text-left w-[52%] sm:w-full rounded-[10px]">
                                    <option defaultValue={1}> HR Manager </option>
                                    <option> frontend Developer </option>
                                    <option> UI/UX Developer  </option>
                                    <option> Backend Developer </option>
                                    <option> ML Engineer</option>
                                </select>

                            </div>
                            <div className="flex sm:flex-col flex-row sm:gap-10 gap-[83px] items-start justify-between mt-[50px] w-[33%] md:w-full">
                                <Text
                                    className="sm:mt-0 mt-[7px] text-blue_gray-400 text-xl"
                                    size="txtInterMedium20"
                                >
                                    Written Exam
                                </Text>
                                <select className="leading-[normal] text-base text-left w-[52%] sm:w-full rounded-[10px]">
                                    <option> 0 </option>
                                    <option> 1 </option>
                                    <option> 2 </option>
                                    <option> 3 </option>
                                </select>
                            </div>
                            <div className="md:gap-10 gap-[221px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 items-center justify-between md:ml-[0] ml-[210px] mt-[49px] w-[84%] md:w-full">
                                <Button className="cursor-pointer font-medium h-[50px] leading-[normal] text-center text-xl w-full">
                                    Exam 1
                                </Button>
                                <Button
                                    className="cursor-pointer font-medium h-[50px] leading-[normal] text-center text-xl w-full"
                                    variant="outline"
                                >
                                    Exam 2
                                </Button>
                                <Button
                                    className="cursor-pointer font-medium h-[50px] leading-[normal] text-center text-xl w-full"
                                    variant="outline"
                                >
                                    Exam 3
                                </Button>
                            </div>
                            <div className="bg-white-A700 border border-gray-400_7f border-solid flex flex-col items-center justify-start md:ml-[0] ml-[210px] mt-[51px] p-[22px] sm:px-5 rounded-[15px] w-[84%] md:w-full">
                                <div className="flex flex-col items-start justify-start my-[9px] w-full">
                                    <div className="flex flex-row gap-14 items-start justify-start ml-0.5 md:ml-[0] w-[34%] md:w-full">
                                        <Text
                                            className="mt-[7px] text-blue_gray-400 text-xl tracking-[-0.40px]"
                                            size="txtInterMedium20"
                                        >
                                            Platform
                                        </Text>
                                        <select className="leading-[normal] text-base text-left w-[60%] sm:w-full rounded-[10px]">
                                            <option> 1 </option>
                                            <option> 1 </option>
                                            <option> 1 </option>
                                            <option> 1 </option><option> 1 </option>
                                        </select>
                                    </div>
                                    <div className="flex flex-row gap-[89px] items-start justify-start mt-[30px] w-[34%] md:w-full">
                                        <Text
                                            className="mt-1 text-blue_gray-400 text-xl tracking-[-0.40px]"
                                            size="txtInterMedium20"
                                        >
                                            Skills
                                        </Text>
                                        <select className="leading-[normal] text-base text-left w-[60%] sm:w-full rounded-[10px]">
                                            <option> 1 </option>
                                            <option> 1 </option>
                                            <option> 1 </option>
                                            <option> 1 </option><option> 1 </option>
                                        </select>
                                    </div>
                                    <div className="flex flex-row gap-[41px] items-center justify-start mt-[30px] w-[34%] md:w-full">
                                        <Text
                                            className="text-blue_gray-400 text-xl tracking-[-0.40px]"
                                            size="txtInterMedium20"
                                        >
                                            Start Date
                                        </Text>
                                        <input type="date" className="leading-[normal] text-base text-left w-[60%] sm:w-full rounded-[10px]" />
                                    
                                    </div>
                                    <div className="flex flex-row gap-[57px] items-start justify-start mt-[39px] w-[35%] md:w-full">
                                        <Text
                                            className="mt-[7px] text-blue_gray-400 text-xl tracking-[-0.40px]"
                                            size="txtInterMedium20"
                                        >
                                            End Date
                                        </Text>
                                        <input type="date" className="leading-[normal] text-base text-left w-[60%] sm:w-full rounded-[10px]" />
                                    </div>
                                    <div className="flex flex-row gap-8 items-center justify-start mt-[42px] w-[34%] md:w-full">
                                        <Text
                                            className="text-blue_gray-400 text-xl tracking-[-0.40px]"
                                            size="txtInterMedium20"
                                        >
                                            Threshhold
                                        </Text>
                                        <select className="leading-[normal] text-base text-left w-[60%] sm:w-full rounded-[10px]">
                                            <option> 1 </option>
                                            <option> 1 </option>
                                            <option> 1 </option>
                                            <option> 1 </option><option> 1 </option>
                                        </select>
                                    </div>
                                    <div className="flex flex-col gap-[13px] items-start justify-start mt-[29px] w-full">
                                        <Text
                                            className="text-blue_gray-400 text-xl tracking-[-0.40px]"
                                            size="txtInterMedium20"
                                        >
                                            Create custom mail
                                        </Text>

                                        <textarea
                                            placeholder=" Type your message here..."
                                            className="bg-white-A700 border border-gray-400_7f border-solid flex flex-col items-start justify-start p-5 rounded-lg w-full"
                                            size="txtInterRegular18"
                                        />


                                    </div>
                                </div>
                            </div>
                            <div className="flex sm:flex-col flex-row sm:gap-10 gap-[75px] items-center justify-start mt-[57px] w-[33%] md:w-full">
                                <Text
                                    className="text-blue_gray-400 text-xl"
                                    size="txtInterMedium20"
                                >
                                    Live Interview
                                </Text>
                                <select className="leading-[normal] text-base text-left w-[60%] sm:w-full rounded-[10px]">
                                    <option> 1 </option>
                                    <option> 1 </option>
                                    <option> 1 </option>
                                    <option> 1 </option><option> 1 </option>
                                </select>
                            </div>
                            {/* <div  className="flex sm:flex-col flex-row sm:gap-10 items-center justify-between md:ml-[0] ml-[210px] mt-[53px] w-[55%] md:w-full"> */}

                            <div className="md:gap-10 gap-[221px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 items-center justify-between md:ml-[0] ml-[210px] mt-[49px] w-[84%] md:w-full">
                                <Button className="cursor-pointer font-medium h-[50px] leading-[normal] text-center text-xl w-full">
                                    Live Interview 1
                                </Button>
                                <Button
                                    className="cursor-pointer font-medium h-[50px] leading-[normal] text-center text-xl w-full"
                                    variant="outline"
                                >
                                    Live Interview 2
                                </Button>
                                {/* <Button
                                    className="cursor-pointer font-medium h-[50px] leading-[normal] text-center text-xl w-full"
                                    variant="outline"
                                >
                                    Exam 3
                                </Button> */}
                                {/* </div> */}


                                {/* <Input
                                    name="frame953"
                                    placeholder="Live Interview 1"
                                    className="font-medium leading-[normal] md:h-auto p-0 placeholder:text-white-A700 sm:h-auto text-left text-xl w-full"
                                    wrapClassName="sm:w-full"
                                ></Input>
                                <Input
                                    name="frame954"
                                    placeholder="Live Interview 2"
                                    className="font-medium leading-[normal] md:h-auto p-0 placeholder:text-blue-A200 sm:h-auto text-left text-xl w-full"
                                    wrapClassName="sm:w-full"
                                    variant="outline"
                                ></Input> */}
                            </div>
                            <div className="flex sm:flex-col flex-row gap-11 items-center justify-start mt-[45px] w-[33%] md:w-full">
                                <Text
                                    className="text-blue_gray-400 text-xl tracking-[-0.40px]"
                                    size="txtInterMedium20"
                                >
                                    Interviewer Name
                                </Text>
                                <select className="leading-[normal] text-base text-left w-[60%] sm:w-full rounded-[10px]">
                                    <option> 1 </option>
                                    <option> 1 </option>
                                    <option> 1 </option>
                                    <option> 1 </option><option> 1 </option>
                                </select>
                            </div>
                            <div className="flex sm:flex-col flex-row gap-7 items-center justify-start mt-10 w-[33%] md:w-full">
                                <Text
                                    className="text-blue_gray-400 text-xl"
                                    size="txtInterMedium20"
                                >
                                    Background Check
                                </Text>
                                <select className="leading-[normal] text-base text-left w-[60%] sm:w-full rounded-[10px]">
                                    <option> 1 </option>
                                    <option> 1 </option>
                                    <option> 1 </option>
                                    <option> 1 </option><option> 1 </option>
                                </select>
                            </div>
                        </div>
                        <div className="flex sm:flex-col flex-row gap-14 items-center justify-end md:ml-[0] ml-[845px] mt-[29px] w-[37%] md:w-full">
                            <Button
                                className="cursor-pointer font-medium h-[50px] leading-[normal] text-center text-xl w-[220px]"
                                color="indigo_600"
                            >
                                Save
                            </Button>
                            <Button
                                className="cursor-pointer font-medium h-[50px] leading-[normal] text-center text-xl w-[220px]"
                                color="indigo_600"
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</>
  );
};

export default ProjectManager;
