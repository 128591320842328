import Container from "components/Container/Container";
import React from "react";
import WebsiteFooter from "components/WebsiteFooter/WebsiteFooter";
const Policies = () => {
  return (
    <><Container mainhead="Privacy Policy"/> 
    <div className=" leading-relaxed flex flex-col gap-[35px] px-[60px] pt-[80px] mqmob1:px-[45px] ">
      {/* <h1 className="text-4xl text-indigo-800 font-bold mb-6">
        Privacy Policy for RecRoid
      </h1> */}
      <div>
        <p className=" text-[18px] leading-relaxed">
          RecRoid ("us", "we" or "Company") respect the privacy of our users
          (each, "you" or "User") and are committed to protect the privacy of
          Users who access, use or engage with our website at{" "}
          <a href="https://www.recroid.com/" className=" text-[#006A94]" target="_blank">
            recroid.com
          </a>{" "}
          (the "Services").
        </p>
        <br />
        <p className=" text-[18px] leading-relaxed">
          At RecRoid, accessible from{" "}
          <a href="https://www.recroid.com/" className=" text-[#006A94]" target="_blank">
            recroid.com
          </a>{" "}
          , one of our main priorities is the privacy of our visitors. The
          Company has prepared this Privacy Policy to outline our practices with
          respect to collecting, using, and disclosing your information when you
          use the Services.
        </p>
        <br />
        <p className=" text-[18px] leading-relaxed">
          We encourage you to read the Privacy Policy carefully and use it to
          make informed decisions. By using the Services, you agree to the terms
          of this Privacy Policy and your continued use of the Services
          constitutes your ongoing agreement to the Privacy Policy.
        </p>
      </div>
      <div className="flex flex-col gap-[35px] mqtab1:gap-[40px] mqtab2:gap-[40px] mqmob1:gap-[37px]">
      <div> 
        <h2 className="text-[1.75rem] text-indigo-800 font-bold my-6">
          What type of information we collect
        </h2>
        <p className=" text-[18px] leading-relaxed">
          We may collect two types of data and information from our Users.
        </p>{" "}
        <br />
        <p className=" text-[18px] leading-relaxed">
          The first type of information is un-identified and non-identifiable
          information pertaining to you, which may be made available or gathered
          via your use of the Services ("Non-personal Information"). We are not
          aware of the identity from which the Non-personal Information is
          collected. Non-personal Information which is collected may include
          your aggregated usage information and technical information
          transmitted by your device, including certain software and hardware
          information about your device (e.g. the device you use, the type of
          browser and operating system your device uses, language preference,
          access time and the website's domain name from which you linked to the
          Services, etc.), in order to enhance the functionality of the
          Services. We may also collect information about your activity on the
          Services (e.g., clicks, actions, mouse movements, dashboard browsing,
          data inserted, typing, etc.).
        </p>{" "}
        <br />
        <p className=" text-[18px] leading-relaxed mb-[15px]">
          The second type of information is individually identifiable
          information, namely information that identifies an individual or may
          with reasonable effort identify an individual ("Personal
          Information"). Such information may include:
        </p>
        <ul>
          <li className=" list-disc list-inside leading-relaxed mb-[10px] ">
            Voluntarily Provided Information: We may collect information which
            you provide us voluntarily. For instance, when you fill in any
            "contact us" or similar form on the Services, communicate with us
            via email or share additional information about yourself through
            your use of the Services. We may also collect the feedback,
            suggestions, complaints, and reports which you send to us.
          </li>
          <li className=" list-disc list-inside leading-relaxed ">
            Device Information: We may collect Personal Information from your
            device. Such information may include geolocation data, Session
            Recordings, internal IP address, unique identifiers (e.g., browser
            user agent, browser plugins used, organizational user associated
            with the browser), as well as other information which relates to
            your activity through the Services.
          </li>
        </ul>
        <br />
        <p className=" text-[18px] leading-relaxed">
          For avoidance of doubt, if we combine Personal Information with
          Non-personal Information, the combined information will be treated as
          Personal Information as long as it remains combined.
        </p>
      </div>

      <div>
        <h2 className="text-[1.75rem] text-indigo-800 font-bold my-6">
          Log Files
        </h2>
        <p className=" text-[18px] leading-relaxed">
          RecRoid follows a standard procedure of using log files. These files
          log visitors when they visit websites. All hosting companies do this
          and a part of hosting services' analytics. The information collected
          by log files include internet protocol (IP) addresses, browser type,
          Internet Service Provider (ISP), date and time stamp, referring/exit
          pages, and possibly the number of clicks. These are not linked to any
          information that is personally identifiable. The purpose of the
          information is for analysing trends, administering the site, tracking
          users' movement on the website, and gathering demographic information.
        </p>
      </div>

      <div>
        <h2 className="text-[1.75rem] text-indigo-800 font-bold my-6">
          Cookies and Web Beacons
        </h2>
        <p className=" text-[18px] leading-relaxed mb-[15px]">
          Like any other website, RecRoid uses "cookies" uses to store
          information including visitors' preferences, and the pages on the
          website that the visitor accessed or visited. The information is used
          to optimize the users' experience by customizing our web page content
          based on visitors' browser type and/or other information. We may use
          the following types of cookies:
        </p>
        <ul>
          <li className=" list-disc list-inside leading-relaxed mb-[10px]">
            <b>Session Cookies</b> which are stored only temporarily during a
            browsing session to allow normal use of the system and are deleted
            from your device when the browser is closed.
          </li>
          <li className=" list-disc list-inside leading-relaxed mb-[10px]">
            <b>Persistent Cookies</b> which are saved on your device for a fixed
            period and are not deleted when the browser is closed. Such cookies
            are used where we need to identify who you are for repeated visits
            (for instance, to allow us to store your preferences for the next
            sign-in).
          </li>
          <li className="list-disc list-inside">
            <b>Third-Party Cookie</b> which are set by other online services
            that run content on the page you view on your browser.
          </li>
        </ul>
        <br />
        <p className=" text-[18px] leading-relaxed">
          You may remove the cookies by following the instructions of your
          device preferences. However, if you choose to disable cookies
          (including first-party cookies), some features of the Services may not
          operate properly, and your online experience may be limited. You can
          opt out of Google's use of cookies or device identifiers by visiting
          Google's Ads Settings at{" "}
          <a
            href="http://www.google.com/settings/ads"
            className=" text-[#006A94]"
          >
            http://www.google.com/settings/ads .
          </a>
        </p>
      </div>

      <div className="">
        <h2 className="text-[1.75rem] text-indigo-800 font-bold my-6">
          Third-Party Privacy Policies
        </h2>
        <p className=" text-[18px] leading-relaxed">
          RecRoid's Privacy Policy does not apply to other advertisers or
          websites. Thus, we are advising you to consult the respective Privacy
          Policies of these third-party ad servers for more detailed
          information. It may include their practices and instructions about how
          to opt-out of certain options.
        </p>
      </div>

      <div>
        <h2 className="text-[1.75rem] text-indigo-800 font-bold my-6">
          How we use the information
        </h2>
        <p className=" text-[18px] leading-relaxed mb-[15px]">
          We use and share Personal Information in the manners described in this
          Privacy Policy. In addition to the purposes listed above, the
          information we collect, which may include your Personal Information,
          is used for the following purposes:
        </p>
        <ul>
          <li className=" list-disc list-inside leading-relaxed mb-[5px]">
            Communicate with you and to keep you informed of our latest updates.
          </li>
          <li className=" list-disc list-inside leading-relaxed mb-[5px]">
            Respond to any queries or requests you submit through the Services.
          </li>
          <li className=" list-disc list-inside leading-relaxed mb-[5px]">
            Perform research or to conduct analytics to improve and customize
            the Services to your needs and interests.
          </li>
          <li className=" list-disc list-inside leading-relaxed mb-[5px]">
            Investigate and resolve disputes in connection with your use of the
            Services.
          </li>
          <li className=" list-disc list-inside leading-relaxed mb-[5px]">
            Detect and prevent fraudulent and illegal activity or any other type
            of activity that may jeopardize or negatively affect the integrity
            of the Services; and
          </li>
          <li className=" list-disc list-inside leading-relaxed mb-[5px]">
            As required by law, regulation, or other governmental authority, or
            to comply with a subpoena or similar legal process or respond to a
            government request.
          </li>
        </ul>
      </div>
      <div>
        <h2 className="text-[1.75rem] text-indigo-800 font-bold my-6">
          With whom we share the information and for what purpose
        </h2>
        <p className=" text-[18px] leading-relaxed">
          We do not rent, sell, or share your Personal Information with third
          parties except as described in this Privacy Policy.
        </p>
        <br />
        <p className=" text-[18px] leading-relaxed">
          We may share Personal Information with the following recipients: (i)
          our subsidiaries; (ii) affiliated companies; (iii) subcontractors and
          other third-party service providers (e.g., Google, AWS); (iv) auditors
          or advisers of our business processes; and (v) any potential
          purchasers or investors in the Company.
        </p>
        <br />
        <p className=" text-[18px] leading-relaxed">
          In addition to the purposes listed in this Privacy Policy, we may
          share Personal Information with our recipients for any of the
          following purposes: (i) storing or processing Personal Information on
          our behalf (e.g. cloud computing service providers); (ii) processing
          such information to assist us with our business operations; and (iii)
          performing research, technical diagnostics, personalization and
          analytics.
        </p>{" "}
        <br />
        <p className=" text-[18px] leading-relaxed">
          We may also disclose Personal Information, or any information you
          submitted via the Services if we have a good faith belief that
          disclosure of such information is helpful or reasonably necessary to:
          (i) comply with any applicable law, regulation, legal process or
          governmental request; (ii) enforce our policies, including
          investigations of potential violations thereof; (iii) investigate,
          detect, prevent, or take action regarding illegal activities or other
          wrongdoing, suspected fraud or security issues; (iv) to establish or
          exercise our rights to defend against legal claims; (v) prevent harm
          to the rights, property or safety of us, our affiliates, our Users,
          yourself or any third-party; (vi) for the purpose of collaborating
          with law enforcement agencies; and (vii) in case we find it necessary
          in order to enforce intellectual property or other legal rights.
        </p>
      </div>
      <div>
        <h2 className="text-[1.75rem] text-indigo-800 font-bold my-6">
          For how long we retain the information
        </h2>
        <p className=" text-[18px] leading-relaxed">
          We retain the information we collect from you for as long as needed to
          provide our Services and to comply with our legal obligations, resolve
          disputes and enforce our agreements (unless we are instructed
          otherwise). We may rectify, replenish, or remove incomplete or
          inaccurate information, at any time and at our own discretion. 
        </p>
      </div>
      <div>
        <h2 className="text-[1.75rem] text-indigo-800 font-bold my-6">
          How we protect your information
        </h2>
        <p className=" text-[18px] leading-relaxed">
          We take great care in implementing and maintaining the security of the
          Services and safeguarding information that relates to you. We employ
          industry standard procedures and policies to ensure the safety of your
          information and prevent unauthorized use of any such information.
          Although we take reasonable steps to safeguard such information, we
          cannot be responsible for the acts of those who gain unauthorized
          access or abuse our Services, and we make no warranty, express,
          implied, or otherwise, that we will prevent such access.
        </p>
      </div>
      <div>
        <h2 className="text-[1.75rem] text-indigo-800 font-bold my-6">
          Corporate transaction
        </h2>
        <p className=" text-[18px] leading-relaxed">
          We may share information, including Personal Information, in the event
          of a corporate transaction (e.g., sale of a substantial part of our
          business, merger, consolidation or asset sale of an asset or transfer
          in the operation thereof) of the Company. In the event of the above,
          the acquiring company or transferee will assume the rights and
          obligations as described in this Privacy Policy.
        </p>
      </div>
      <div className="">
        <h2 className="text-[1.75rem] text-indigo-800 font-bold my-6">
          Children's Information
        </h2>
        <p className=" text-[18px] leading-relaxed">
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </p>{" "}
        <br />
        <p className=" text-[18px] leading-relaxed">
          RecRoid does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our website, we strongly
          encourage you to contact us immediately and we will do our best
          efforts to promptly remove such information from our records.
        </p>
      </div>
      <div>
        <h2 className="text-[1.75rem] text-indigo-800 font-bold my-6">
          Updates or amendments to the Privacy Policy
        </h2>
        <p className=" text-[18px] leading-relaxed">
          We may revise this Privacy Policy from time to time, in our sole
          discretion, and the most current version will always be posted on our
          dashboard or our website (as reflected in the "Last Revised" heading).
          In the event of a material change to the Privacy Policy, we will
          notify you through the Services or via email. We encourage you to
          review this Privacy Policy regularly for any changes.
        </p>{" "}
        <br />
        <p className=" text-[18px] leading-relaxed">
          Your continued use of the Services, following the notification of such
          amendments, constitutes your acknowledgement and consent of such
          amendments to the Privacy Policy and your agreement to be bound by the
          terms of such amendments.
        </p>
      </div>
      <div className="">
        <h2 className="text-[1.75rem] text-indigo-800 font-bold my-6">
          Online Privacy Policy Only
        </h2>
        <p className=" text-[18px] leading-relaxed">
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that they
          shared and/or collect in RecRoid This policy is not applicable to any
          information collected offline or via channels other than this website.
        </p>
      </div>
      <div>
        <h2 className="text-[1.75rem] text-indigo-800 font-bold my-6">
          Consent
        </h2>
        <p className=" text-[18px] leading-relaxed">
          By using our website, you hereby consent to our Privacy Policy and
          agree to its Terms and Conditions.
        </p>
      </div>
       </div>
    </div>

    <div className=" mt-[18rem] mqmob1:mt-[12rem] ">
         < WebsiteFooter />
    </div>

    
    </>
  );
};

export default Policies;
