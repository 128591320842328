import { Text } from "components";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SocialButtons from "../Desktop/socialbuttons";
import ToggleButton from "../Desktop/toggle";
import "./LandingPageLaptop1220.css";
import LCarousel from "./company_animation/App";
const LandingPageLaptop1220 = () => {
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const navigate = useNavigate();

  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [locationError, setLocationError] = useState(null);
  const [isInIndia, setIsInIndia] = useState(false);

  const isInIndiaCheck = (latitude, longitude) => {
    return (
      latitude >= 8.067 &&
      latitude <= 37.1 &&
      longitude >= 68.117 &&
      longitude <= 97.417
    );
  };

  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
            const inIndia = isInIndiaCheck(latitude, longitude);
            setIsInIndia(inIndia);
          },
          (error) => {
            setLocationError(error.message);
          }
        );
      } else {
        setLocationError("Geolocation is not supported by this browser.");
      }
    };

    getLocation();
  }, []);

  const onJobSeekerContainerClick = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  // const onLogoFrameContainerClick = useCallback(() => {
  //   const anchor = document.querySelector(
  //     "[data-scroll-to='openingPageContainer']",
  //   );
  //   if (anchor) {
  //     anchor.scrollIntoView({ block: "start", behavior: "smooth" });
  //   }
  // }, []);

  // const onWebsiteNameTextClick = useCallback(() => {
  //   const anchor = document.querySelector(
  //     "[data-scroll-to='openingPageContainer']",
  //   );
  //   if (anchor) {
  //     anchor.scrollIntoView({ block: "start", behavior: "smooth" });
  //   }
  // }, []);

  const onProductTagContainerClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='productJobSeekersContainer']",
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const handleJobSeekersdemo = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='JobSeekersdemo']",
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const handlerecruiterdemo = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='recruiterdemo']",
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);


  const onPricingTagContainerClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='pricingContainer']",
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  // const onAboutUsTagContainerClick = useCallback(() => {
  //   const anchor = document.querySelector(
  //     "[data-scroll-to='contentContainer']",
  //   );
  //   if (anchor) {
  //     anchor.scrollIntoView({ block: "start", behavior: "smooth" });
  //   }
  // }, []);

  const onButtonContainerClick = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  // const onButtonContainer2Click = useCallback(() => {
  //   navigate("/login");
  // }, [navigate]);

  const [revstate, SetrevState] = useState(0);

  const reviewname = ["Srinivas", "Shrashti", "Somesh", "Jatin"];

  const reviewcompany = [
    "HR Manger, Culinda Inc.",
    "Data Scientist, Culinda Inc.",
    "ML Engg. Culinda Inc.",
    "ML Intern, Magix DB",
  ];

  const reviewcontent = [
    "We use Smart Recruiter for hiring for all positions in our company.️ Smart recruiter has cut down our cost for recruiting team. We don't need a recruiting team to schedule interviews and follow ups. We are able to hire people 10X faster.",
    "RecRoid revolutionized our hiring process, cutting costs and accelerating recruitment by 10x. No need for a dedicated recruiting team; it's all automated and streamlined.",
    "RecRoid is a game-changer. With its advanced automation, we've not only cut recruitment costs significantly but also managed to hire top-tier talent in record time. No more manual scheduling or follow-ups; the platform handles it all seamlessly. It's a win-win for our organization.",
    "RecRoid is a must-have for efficient hiring. Our hiring speed improved by an impressive 10x, and we've seen a remarkable reduction in hiring costs. Our hiring process is not just faster but also more cost-effective, making it an indispensable tool for any organization.",
  ];

  const [currentSvg, setCurrentSvg] = useState(1);

  const toggleSVG = () => {
    setCurrentSvg((prev) => (prev < 10 ? prev + 1 : 1));
  };

  useEffect(() => {
    const interval = setInterval(toggleSVG, 2000); // Change SVG every 2 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="landing-page-laptop1220">
      {/* <div className="website-header1"> */}
      <div className="header">
        <div className=" text-xl font-medium right-fields flex flex-row justify-between w-[400px]">
          <div
            className="overlay w-[26px] h-[25px]   absolute top-[-8px] left-[-57px] z-20 transform rotate-[-78deg]"
            style={{ backgroundColor: "white" }}
          ></div>
          <img
            src="/comingSoon4.png"
            alt=""
            className=" transform rotate-0 w-[115px] h-[39px]  absolute  top-0 left-[-53px] z-10"
          />
          <Link to="/recruiter-login" className="company">
            <div
              className="job-seekers landingpage  hover:bg-gray-50 relative rounded-[8px]  "
              style={{
                border: "2px solid #314ca3",
                color: "#314ca3",
                padding: 12,
                left: -37,
                paddingLeft: 60,
              }}
            >
              Employer
            </div>
          </Link>
          <div
            className="job-seeker rounded-[12px]"
            onClick={onButtonContainerClick}
            style={{ border: "2px solid #314ca3", padding: 14 }}
          >
            <div
              style={{ color: "#314ca3" }}
              className="job-seekers landingpage "
            >
              Job seekers
            </div>
          </div>
        </div>

        <div
          // style={{backgroundColor:"red"}}
          //  style={{ fontSize: "larger", fontWeight: "500" }}
          className="left-fields text-xl font-medium"
        >
          <div className="product-tag" onClick={onProductTagContainerClick}>
            <div style={{ color: "#314ca3" }} className="product landingpage">
              Products
            </div>
          </div>
          <div className="pricing-tag" onClick={onPricingTagContainerClick}>
            <div style={{ color: "#314ca3" }} className="pricing landingpage">
              Pricing
            </div>
          </div>
          <a href="/about-us">
            <div className="about-us-tag">
              <div style={{ color: "#314ca3" }} className="product landingpage">
                About us
              </div>
            </div>
          </a>
        </div>

        <div className="website-name-frame-parent1">
          <div className="website-name-frame1">
            <div style={{ color: "rgb(49 76 163)" }} className="website-name1">
              RecRoid
            </div>
          </div>
          <div className="logo-frame">
            <img
              className="recruiter-robo-logo-3-1-icon1"
              alt=""
              src="/recroid.png"
            />
          </div>
        </div>
      </div>
      {/* </div> */}
      <div className="website-main1">
        <div className="whyus1">
          <div className="content-container13">
            <div className="subheading-container-group">
              {/* <div
                style={{ fontSize: "larger", fontWeight: "500" }}
                className="right-fields flex flex-row justify-between w-[450px]"
              >
                <div className="job-seeker" onClick={onJobSeekerContainerClick}>
                  <div className="job-seekers landingpage">
                    Job seekers Laptop
                  </div>
                </div>
                <Link to="/recruiter-login" className="company">
                  <div className="cursor-pointer company1 landingpage">
                    Company
                  </div>
                </Link>
                <Link
                  to="/university-login"
                  className="cursor-pointer company1"
                >
                  <div className="company3 landingpage">University</div>
                </Link>
              </div> */}
              <div className="content-rows-container1">
                <div className="job-seeker-column1">
                  <div className="text-container12">
                    <b className="heading-69">Access to undisclosed Jobs</b>
                    <div className="our-all-the1">
                      Our all the clients vacancy undisclosed in the market is
                      available to you.
                    </div>
                  </div>
                  <div className="text-container13">
                    <b className="heading-610">Everything to get you hired</b>
                    <div className="everything-you-require1">
                      Everything you require to get you hire is here at one
                      platform
                    </div>
                  </div>
                </div>
                <div className="recruiter-column1">
                  <div className="text-container14">
                    <b className="heading-611">Simple Fixed Pricing</b>
                    <div className="no-waiting-for1">{`No waiting for quotes from the company. `}</div>
                  </div>
                  <div className="text-container15">
                    <b className="heading-612">
                      Candidates Database at no cost
                    </b>
                    <div className="in-house-candidates1">
                      {" "}
                      In house Candidates database aviable at no extra cost
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="heading-container4">
              <div className="yellow-text1" />
              <b className="heading-212">Everything at One Place</b>
            </div>
            {/* <div className="yellow-ball-image-container1"> */}
            {/* </div> */}
          </div>
          <div className="App">
            <img src="images/yellow_ball.svg" alt="Ball" className="yball" />
          </div>
        </div>
        <div className="resumetemplates2">
          <div className="content-container14">
            <div className="resumetemplates3">
              <div className="resume-2-wrapper">
                <div className="resume-21">
                  <img
                    className="image-2-icon1"
                    alt=""
                    src="/image-21@2x.png"
                  />
                </div>
              </div>
              <div className="resume-3-wrapper">
                <div className="resume-31">
                  <img
                    className="image-3-icon1"
                    alt=""
                    src="/image-31@2x.png"
                  />
                </div>
              </div>
            </div>
            <Link to="/all-resumetemplates">
              <div className="moretemplates1">
                <div className="text-container16">
                  <div className="take-me-to3">More Templates</div>
                </div>
                <img
                  className="arrow-container-icon3"
                  alt=""
                  src="/arrow-container.svg"
                />
              </div>
            </Link>
          </div>
          <div className="title-conatiner2">
            <b className="heading-213">Resume Templates</b>
          </div>
          <div className="ball-container4">
            <img className="ball1svg-icon2" alt="" src="/ball1svg2@2x.png" />
          </div>
        </div>
        <div
          className="autoapply1"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="heading-container5">
            <div className="text-container17">
              <b className="auto-apply2">Auto Apply</b>
            </div>
            <div className="yellow-badge2">
              <b className="heading-213">partners</b>
            </div>
          </div>
          <LCarousel />
          {/* <div className="rolling-companies1"> */}
          {/* </div> */}
        </div>
        <div data-scroll-to="JobSeekersdemo" className="smartjobseekersdemo1">
          <div className="video-container2">
            <img
              className="video-recruiter-demo-icon2"
              alt=""
              src="/video-recruiter-demo2@2x.png"
            />
          </div>
          <div className="yellow-ball-image-container2">
            <img className="ball2svg-icon4" alt="" src="/ball2svg4@2x.png" />
          </div>
          <div className="green-ball-image-container2">
            <img className="ball3svg-icon4" alt="" src="/ball3svg4@2x.png" />
          </div>
          <div className="heading-container6">
            <b className="heading-214">Smart Job Seekers Demo</b>
          </div>
          <div className="button-container2" onClick={onButtonContainerClick}>
            <div className="button-text-container2">
              <div className="take-me-to4">To Smart Job Seeker</div>
            </div>
            <img
              className="arrow-container-icon4"
              alt=""
              src="/arrow-container1.svg"
            />
          </div>
        </div>
        <div data-scroll-to="recruiterdemo" className="recruiterdemo1">
          <div className="image-background-container1">
            <img
              className="bg-ractangle-shapepng-icon2"
              alt=""
              src="/bgractangleshapepng2@2x.png"
            />
            <div className="content-container15">
              <div className="video-container3">
                <img
                  className="video-recruiter-demo-icon3"
                  alt=""
                  src="/video-recruiter-demo3@2x.png"
                />
              </div>
              <div className="content-container16">
                <b className="heading-215">Smart Recruiter Demo</b>
                <div className="watch-this-demo1">
                  Watch this demo to see how easily the Smart Recruiter
                  integrates with you Mail account and starts automating you
                  hiring pipeline.
                </div>
                <div
                  className="button-container3"
                  // onClick={onJobSeekerContainerClick}
                  // style={{ cursor: "pointer" }}
                >
                  <div className="button-text-container3">
                    <div className="take-me-to5">Coming Soon</div>
                  </div>
                  <img
                    className="arrow-container-icon5"
                    alt=""
                    src="/arrow-container1.svg"
                  />
                </div>
              </div>
              <div className="ball-container5">
                <img
                  className="ball3svg-icon4"
                  alt=""
                  src="/ball3svg4@2x.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="happy-customers1">
          <div className="customers-stats-container1">
            <div className="content-container17">
              <div className="countries-container1">
                <b className="heading-216">200</b>
                <div className="countries-worldwide1">Countries Worldwide</div>
              </div>
              <div className="users-container1">
                <div className="heading-217">
                  <b className="k2">156K</b>
                </div>
                <div className="registered-user1">Registered User</div>
              </div>
              <div className="companies-container1">
                <div className="heading-218">
                  <b className="k3">23K</b>
                </div>
                <div className="small-big1">{`Small & Big Companies`}</div>
              </div>
            </div>
          </div>
          <div className="rectangle-image-container1">
            <img
              className="bg-ractangle-shapepng-icon3"
              alt=""
              src="/bgractangleshapepng3@2x.png"
            />
            <div className="ball-container6">
              <img className="ball2svg-icon4" alt="" src="/ball2svg5@2x.png" />
            </div>
            <div className="content-container18">
              <div className="divrow1">
                <div className="divhappy-customer-left1">
                  <div className="arrow-left-blacksvg1" />
                  <div className="divcustomers-name1">
                    <div className="heading-613">{reviewname[revstate]}</div>
                    <b className="heading-510">{reviewcompany[revstate]}</b>
                    <div className="div1">{revstate + 1}/4</div>
                    <img
                      className="arrow-right-blacksvg-icon1"
                      alt=""
                      src="/arrowrightblacksvg.svg"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        revstate === 3 ? "" : SetrevState(revstate + 1)
                      }
                    />
                    <img
                      className="arrow-left-blacksvg-icon1"
                      alt=""
                      src="/arrowleftblacksvg.svg"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        revstate === 0 ? "" : SetrevState(revstate - 1)
                      }
                    />
                  </div>
                  <div className="p4">
                    <div className="we-use-smart1">
                      {reviewcontent[revstate]}
                    </div>
                  </div>
                  <div className="divhappy-customers-title1">
                    <b className="heading-219">Happy customers</b>
                    <div className="heading-511">
                      Worldwide 30,000+ customer
                    </div>
                  </div>
                </div>
                <div className="divhappy-customer-right1">
                  <div className="emojisvg1">
                    <img
                      className="emojisvg-icon1"
                      alt=""
                      src="/emojisvg.svg"
                    />
                  </div>
                  <div className="divslick-list1">
                    <div className="divslick-track1">
                      <div className="divslider-img4">
                        <div className="pseudo5" />
                        <img
                          className="happy-customers1png-icon1"
                          alt=""
                          src="/happycustomers1png@2x.png"
                        />
                      </div>
                      <div className="divslider-img5">
                        <div className="divhalfclip1">
                          <div className="divhalfcircle1" />
                        </div>
                        <div className="pseudo5" />
                        <img
                          className="happy-customers1png-icon1"
                          alt=""
                          src="/happycustomers2png@2x.png"
                        />
                      </div>
                      <div className="divslider-img6">
                        <div className="pseudo5" />
                        <img
                          className="happy-customers1png-icon1"
                          alt=""
                          src="/happycustomers3png@2x.png"
                        />
                      </div>
                      <div className="divslider-img7">
                        <div className="pseudo5" />
                        <img
                          className="happy-customers1png-icon1"
                          alt=""
                          src="/happycustomers4png@2x.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pricing3" data-scroll-to="pricingContainer">
          <div className="price-card-container1">
            <div className="jobseeker-price-card1">
              <div className="button3" onClick={onButtonContainerClick}>
                <b className="start-for-free">
                  {isToggled ? "Purchase Now" : "Start for free"}
                </b>
              </div>
              <div className="list9">
                <div className="item-unlimited11">
                  Unlimited Resumes Builders
                </div>
                <div className="item-unlimited12">
                  Unlimited Resume Enhancements
                </div>
                <div className="item-unlimited13">Unlimited Cover Letters</div>
                <div className="item-unlimited14">
                  Unlimited automation templates
                </div>
                <div className="item-2001">
                  200 min of Interview Preparation
                </div>
                <div className="item-unlimited15">Unlimited Job Searches</div>
                <div className="item-unlimited16">Unlimited Auto Job Apply</div>
                <div className="item-unlimited17">
                  Access to undisclosed Jobs
                </div>
              </div>
              <div className="icons2">
                <div className="compare-price-shapesvg2">
                  <img
                    className="compare-price-shapesvg-icon2"
                    alt=""
                    src="/comparepriceshapesvg.svg"
                  />
                </div>
                <div className="crownsvg1">
                  <img className="crownsvg-icon1" alt="" src="/crownsvg.svg" />
                </div>
              </div>
              <div className="heading-220">
                <b className="strong-59">
                  {isInIndia ? (
                    isToggled ? (
                      // <>
                      //   ₹ 7655  per year
                      //   <br />
                      //   (₹ 510 per month)
                      // </>
                      <div>
                        <Text>  ₹ 7655  per year</Text>

                        <Text className="font-medium text-lg">  (₹ 510 per month)</Text>

                      </div>
                    ) : (
                      "₹ 799 per month"
                    )
                  ) : isToggled ? (
                    // <>
                    //   $ 96 per year
                    //   <br />
                    //   ($ 8 per month)
                    // </>
                    <div>
                      <Text>  $ 96 per year</Text>

                      <Text className="font-medium text-lg"> ($ 8 per month)</Text>

                    </div>
                  ) : (
                    "$ 9.99 per month"
                  )}
                </b>

                {/* <b className="strong-191">19</b>
                <div className="per-month2-1">Per month</div>
                <div className="superscript2">$</div> */}
              </div>
              <b className="heading-512">For Job Seekers</b>
            </div>
            <div className="recruiter-price-card1">
              <div className="button4">
                <b className="start-for-free">
                 Coming Soon
                </b>
              </div>
              <div className="list10">
                <div className="item-email1">Unlimited Resume Processing</div>
                <div className="item-unlimited18">
                  Unlimited Auto Interview Schedules
                </div>
                <div className="item-unlimited19">
                  Free Centralised Databases
                </div>
                <div className="item-unlimited20">
                  Access to 5000 free candidates profiles
                </div>
                <div className="item-great1">Great Customer Support</div>
                <div className="item-unlimited21">
                  Unlimited job Uploads and Shortlisting
                </div>
              </div>
              <div className="icons3">
                <div className="compare-price-shapesvg3">
                  <img
                    className="compare-price-shapesvg-icon2"
                    alt=""
                    src="/comparepriceshapesvg.svg"
                  />
                </div>
                <div className="crown-2svg1">
                  <img
                    className="crown-2svg-icon1"
                    alt=""
                    src="/crown2svg.svg"
                  />
                </div>
              </div>
              <div className="heading-221">
                {/* <div className="heading-29"> */}
                <b className="strong-59">
                  {isInIndia ? (
                    isToggled ? (
                      // <>
                      //   ₹ 47280 per year
                      //   <br />
                      //   (₹ 3940 per month)
                      // </>
                      <div>
                        <Text> ₹ 47280 per year</Text>

                        <Text className="font-medium text-lg">(₹ 3940 per month)</Text>

                      </div>
                    ) : (
                      "₹ 4920 per month"
                    )
                  ) : isToggled ? (
                    <div>
                      <Text>    $ 566 per year</Text>

                      <Text className="font-medium text-lg">  ($ 47.20 per month)</Text>

                    </div>
                    // <>
                    //   $ 566 per year
                    //   <br />
                    //   ($ 47.20 per month)
                    // </>
                  ) : (
                    "$ 59 per month"
                  )}
                </b>
                {/* <div className="superscript">$</div> */}
                {/* </div> */}
                {/* <b className="strong-191">59</b>
                <div className="superscript2">$</div>
                <div className="per-month2">Per month</div> */}
              </div>
              <b className="heading-513">For Recruiters</b>
            </div>
            <div className="university-price-card1">
              <div className="button4">
                <b className="start-for-free">
                 Coming Soon
                </b>
              </div>
              <div className="list10">
                <div className="item-email1">Unlimited Resume Processing</div>
                <div className="item-unlimited18">
                  Unlimited Auto Interview Schedules
                </div>
                <div className="item-unlimited19">
                  Free Centralised Databases
                </div>
                <div className="item-unlimited20">
                  Access to 5000 free candidates profiles
                </div>
                <div className="item-great1">Great Customer Support</div>
                <div className="item-unlimited21">
                  Unlimited job Uploads and Shortlisting
                </div>
              </div>
              <div className="icons3">
                <div className="compare-price-shapesvg3">
                  <img
                    className="compare-price-shapesvg-icon2"
                    alt=""
                    src="/comparepriceshapesvg.svg"
                  />
                </div>
                <div className="crown-2svg1">
                  <img
                    className="crown-2svg-icon1"
                    alt=""
                    src="/crown2svg.svg"
                  />
                </div>
              </div>
              <div className="heading-221">
                {/* <div className="heading-29"> */}
                <b className="strong-59">
                  {isInIndia ? (
                    isToggled ? (

                      // <>
                      //   ₹ 47280 per year
                      //   <br />
                      //   (₹ 3940 per month)
                      // </>
                      <div>

                        <Text>   ₹ 47280 per year</Text>

                        <Text className="font-medium text-lg">   (₹ 3940 per month)</Text>

                      </div>
                    ) : (
                      "₹ 4920 per month"
                    )
                  ) : isToggled ? (
                    // <>
                    //   $ 566 per year
                    //   <br />
                    //   ($ 47.20 per month)
                    // </>
                    <div>

                      <Text>$ 566 per year</Text>

                      <Text className="font-medium text-lg">  ($ 47.20 per month)</Text>

                    </div>
                  ) : (
                    "$ 59 per month"
                  )}
                </b>
                {/* <div className="superscript">$</div> */}
                {/* </div> */}
                {/* <b className="strong-191">59</b>
                <div className="superscript2">$</div>
                <div className="per-month2">Per month</div> */}
              </div>
              <b className="heading-513">For University</b>
            </div>
          </div>
          <div className="ball1svg-parent">
            <img className="ball1svg-icon3" alt="" src="/ball1svg3@2x.png" />
            <div className="heading-container7">
              <div className="top-heading-container1">
                <b className="pricing-table1">Pricing Table</b>
              </div>
              <div className="heading1-container1">
                <b className="giving-you-good1">Giving you good value</b>
              </div>
              <div className="heading2-container1">
                <b className="with1">{`with `}</b>
              </div>
              <div className="yellow-badge3">
                <b className="best-service1">best service</b>
              </div>
            </div>
            <div className="yearly-payment-tab1">
              <div className="toggle-btn" style={{ fontSize: "30px" }}>
                <span>Monthly</span>
                <div onClick={handleToggle}>
                  <ToggleButton />
                </div>
                <span>
                  Yearly
                  <span style={{ color: "#00CEC9" }}>(20% Off)</span>
                </span>
              </div>
            </div>
            <div className="card-offers1">
              <div className="list11">
                <div className="item21">
                  <img className="img-icon15" alt="" src="/img.svg" />
                  <div className="no-cards-required1">No cards required</div>
                </div>
                <div className="item22">
                  <img className="img-icon15" alt="" src="/img.svg" />
                  <div className="no-cards-required1">7 day free trial</div>
                </div>
                <div className="item23">
                  <img className="img-icon15" alt="" src="/img.svg" />
                  <div className="no-cards-required1">
                    Get started in 2 minutes
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="product-recruiter1">
          <div className="content-container19">
            <div className="title-container1">
              <b className="product3">PRODUCTS</b>
              <b className="heading-222">For Recruiters</b>
            </div>
            <img className="ball2svg-icon6" alt="" src="/ball2svg6@2x.png" />
            <div className="product-recruiters-content1">
              <div className="integrate-emails-row1">
                <div className="image-container12">
                  <img
                    className="integrate-emails-container-icon1"
                    alt=""
                    src="/integrate-emails-container1@2x.png"
                  />
                </div>
                <div className="divpowerfull-template-number6">
                  <b className="b6">1</b>
                </div>
                <div className="integrate-emails-content-conta1">
                  <b className="heading-46">Integrate Emails</b>
                  <div className="sub-content1">
                    <div className="integrate-outlook-g1">
                      {" "}
                      Integrate Outlook, G suite or any other professional email
                      box where you receive candidates profiles.
                    </div>
                  </div>
                  <div className="list12">
                    <div className="item24">
                      <img className="img-icon15" alt="" src="/img1.svg" />
                      <div className="no-cards-required1">
                        All major professionals email Integration Available
                      </div>
                    </div>
                    <div className="item25">
                      <img className="img-icon15" alt="" src="/img1.svg" />
                      <div className="no-cards-required1">
                        Automated Profile identification and merging to database
                      </div>
                    </div>
                    <div className="item26">
                      <img className="img-icon15" alt="" src="/img1.svg" />
                      <div className="no-cards-required1">
                        Real Time Profile processing
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="centralised-database1">
                <div className="content-container20">
                  <div className="heading-614">
                    <b className="heading-47">Centralized Database</b>
                  </div>
                  <div className="subcontent1">
                    <div className="maintain-candidates-resumes1">
                      Maintain candidates resumes, cover letter, their
                      performances on assignments and interviews..
                    </div>
                  </div>
                  <div className="ordered-list2">
                    <div className="item27">
                      <div className="monitor-iconsvg4">
                        <img
                          className="crown-2svg-icon1"
                          alt=""
                          src="/monitor-iconsvg.svg"
                        />
                      </div>
                      <div className="all-candidates-profiles1">
                        All Candidates Profiles at one place
                      </div>
                    </div>
                    <div className="item28">
                      <div className="monitor-iconsvg4">
                        <img
                          className="crown-2svg-icon1"
                          alt=""
                          src="/message-iconsvg.svg"
                        />
                      </div>
                      <div className="all-candidates-profiles1">
                        On boarding of HRs and Interviewers
                      </div>
                    </div>
                    <div className="item29">
                      <div className="monitor-iconsvg4">
                        <img
                          className="crown-2svg-icon1"
                          alt=""
                          src="/document-iconsvg.svg"
                        />
                      </div>
                      <div className="centralized-job-vacancies1">
                        Centralized Job Vacancies
                      </div>
                    </div>
                  </div>
                </div>
                <div className="divpowerfull-template-number7">
                  <b className="b7">2</b>
                </div>
                <div className="image-container13">
                  <img
                    className="centralised-database-image-icon1"
                    alt=""
                    src="/centralised-database-image@2x.png"
                  />
                </div>
              </div>
              <div className="integrate-emails-row1">
                <div className="image-container14">
                  <img
                    className="sends-assesment-image-icon1"
                    alt=""
                    src="/sends-assesment-image1@2x.png"
                  />
                </div>
                <div className="divpowerfull-template-number8">
                  <b className="b8">3</b>
                </div>
                <div className="content-container21">
                  <b className="heading-48">Sends automated Assessments</b>
                  <div className="p5">
                    <div className="once-the-profile1">
                      Once the profile is shortlisted the smart Recruiter send
                      an assessment to the candidate for the 1st round of test.
                    </div>
                  </div>
                  <div className="list13">
                    <div className="item24">
                      <img className="img-icon15" alt="" src="/img1.svg" />
                      <div className="no-cards-required1">
                        Assessments on Leetcode, Hacker Rank
                      </div>
                    </div>
                    <div className="item31">
                      <img className="img-icon15" alt="" src="/img1.svg" />
                      <div className="no-cards-required1">
                        Plugins for custom exams
                      </div>
                    </div>
                    <div className="item26">
                      <img className="img-icon15" alt="" src="/img1.svg" />
                      <div className="no-cards-required1">
                        Real time results
                      </div>
                    </div>
                  </div>
                  <div className="ball2svg1" />
                </div>
              </div>
              <div className="centralised-database1">
                <div className="content-container20">
                  <b className="heading-49">Shortlist Profiles</b>
                  <div className="p6">
                    <div className="profiles-are-shortlisted2">
                      Profiles are shortlisted based on the performance of the
                      candidate on the assessment. Flexibility to set the exam
                      cutoff
                    </div>
                  </div>
                  <div className="list14">
                    <div className="item24">
                      <img className="img-icon15" alt="" src="/img.svg" />
                      <div className="no-cards-required1">
                        Profiles are shortlisted by the smart recruiter
                      </div>
                    </div>
                    <div className="item34">
                      <img className="img-icon15" alt="" src="/img1.svg" />
                      <div className="no-cards-required1">
                        No human interaction required
                      </div>
                    </div>
                    <div className="item26">
                      <img className="img-icon15" alt="" src="/img1.svg" />
                      <div className="no-cards-required1">No biases</div>
                    </div>
                  </div>
                </div>
                <div className="divpowerfull-template-number9">
                  <b className="b9">4</b>
                </div>
                <div className="image-container15">
                  <img
                    className="shortlist-profiles-image-icon1"
                    alt=""
                    src="/shortlist-profiles-image@2x.png"
                  />
                </div>
              </div>
              <div className="integrate-emails-row1">
                <div className="image-container16">
                  <img
                    className="schedule-interview-image-icon1"
                    alt=""
                    src="/schedule-interview-image1@2x.png"
                  />
                </div>
                <div className="divpowerfull-template-number10">
                  <b className="b10">5</b>
                </div>
                <div className="content-container23">
                  <b className="heading-410">Schedule Interviews</b>
                  <div className="heading-615">
                    <div className="interviewrs-profiles-can1">
                      Interviewrs profiles can be added and in tervieww
                      interviews are scheduled through mails automatically,
                      after matching the avaialble slots of the interviewer and
                      the candidate
                    </div>
                  </div>
                  <div className="ordered-list3">
                    <div className="item36">
                      <div className="monitor-iconsvg4">
                        <img
                          className="crown-2svg-icon1"
                          alt=""
                          src="/monitor-iconsvg.svg"
                        />
                      </div>
                      <div className="auto-interview-slot1">
                        Auto Interview Slot matching
                      </div>
                    </div>
                    <div className="item28">
                      <div className="monitor-iconsvg4">
                        <img
                          className="crown-2svg-icon1"
                          alt=""
                          src="/message-iconsvg.svg"
                        />
                      </div>
                      <div className="auto-emails-for1">{`Auto Emails  for scheduling interviews `}</div>
                    </div>
                    <div className="item29">
                      <div className="monitor-iconsvg4">
                        <img
                          className="crown-2svg-icon1"
                          alt=""
                          src="/document-iconsvg.svg"
                        />
                      </div>
                      <div className="reminders-before-interviews1">
                        Reminders before interviews
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="centralised-database1">
                <div className="content-container24">
                  <b className="heading-411">Send Out Results</b>
                  <div className="p7">
                    <div className="after-the-feedback1">{`After the feedback from the interviewer the feedback is mailed to the candidates. `}</div>
                  </div>
                  <div className="list15">
                    <div className="item24">
                      <img className="img-icon15" alt="" src="/img1.svg" />
                      <div className="no-cards-required1">{`Keeps your acceptance & rejection communication crisp`}</div>
                    </div>
                    <div className="item31">
                      <img className="img-icon15" alt="" src="/img1.svg" />
                      <div className="no-cards-required1">
                        Good company repo
                      </div>
                    </div>
                    <div className="item26">
                      <img className="img-icon15" alt="" src="/img1.svg" />
                      <div className="no-cards-required1">
                        10X Faster hiring
                      </div>
                    </div>
                  </div>
                </div>
                <div className="divpowerfull-template-number11">
                  <b className="b11">6</b>
                </div>
                <div className="image-container17">
                  <img
                    className="send-results-image-icon1"
                    alt=""
                    src="/send-results-image@2x.png"
                  />
                </div>
              </div>
            </div>
            <div className="divdividing-line1" />
          </div>
        </div>
        <div
          //  style={{backgroundColor:"red"}}
          className="product-job-seekers1"
          data-scroll-to="productJobSeekersContainer"
        >
          <div className="job-seeker-product-second-row-group">
            <div className="job-seeker-product-second-row1">
              <div className="interview-preparation1">
                <div className="text-container19">
                  <b className="heading-514">Interview Preparation</b>
                  <div className="prepare-for-live1">{`Prepare for live interviews for jobs and academics and get a detailed analysis on your performance of you content, language & body language.`}</div>
                </div>
                <div className="image-container18">
                  <img
                    className="interview-prep-image-icon1"
                    alt=""
                    src="/interview-prep-image1@2x.png"
                  />
                </div>
              </div>
              <div className="career-advise1">
                <div className="text-container20">
                  <b className="heading-515">Career Advise</b>
                  <div className="get-career-advise1">{`Get career advise with jobs, research & colleges lists along with salary, stipend and expenses from our highly curated database with an AI powered counselor.`}</div>
                </div>
                <div className="image-container19">
                  <img
                    className="career-advise-image-icon1"
                    alt=""
                    src="/career-advise-image1@2x.png"
                  />
                </div>
              </div>
              <div className="auto-apply3">
                <div className="text-conatiner2">
                  <b className="heading-516">Auto Job Apply</b>
                  <div className="relax-while-our1">
                    Relax, while our bots apply for relevant jobs for you to our
                    20+ auto jobs partners
                  </div>
                </div>
                <div className="image-container20">
                  <img
                    className="autojob-apply-image-icon1"
                    alt=""
                    src="/autojob-apply-image1@2x.png"
                  />
                </div>
              </div>
            </div>
            <div className="job-seeker-product-first-row1">
              <div className="build-and-enhance-resume1">
                <div className="text-container21">
                  <b className="heading-517">{`Build & Enahnce Resume`}</b>
                  <div className="prepare-for-live1">
                    Create a personalized resume on a professional templates.
                    Build from scratch or various sources. Enahnce Resume using
                    AI
                  </div>
                </div>
                <div className="image-container21">
                  <img
                    className="resume-builder-image-icon1"
                    alt=""
                    src="/resume-builder-image1@2x.png"
                  />
                </div>
              </div>
              <div className="build-cover-letters1">
                <div className="text-container22">
                  <b className="heading-518">Build Cover Letter</b>
                  <div className="craft-compelling-cover1">
                    Craft compelling cover letter with our AI powered technology
                  </div>
                </div>
                <div className="image-container22">
                  <img
                    className="cover-letter-image-icon1"
                    alt=""
                    src="/cover-letter-image1@2x.png"
                  />
                </div>
              </div>
              <div className="job-search1">
                <div className="text-conatiner3">
                  <b className="heading-519">Job Search</b>
                  <div className="prepare-for-live1">
                    Look for jobs on 50 plus platforms and apply directly. Get
                    access to undisclosed jobs in market too.
                  </div>
                </div>
                <div className="image-container23">
                  <img
                    className="job-search-image-icon1"
                    alt=""
                    src="/job-search-image1@2x.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="title-conatiner3">
            <b className="product3">PRODUCTS</b>
            <b className="heading-223">For Job Seekers</b>
          </div>
          <div className="App">
            <img src="images/ball3.svg" alt="Ball" className="ball" />
          </div>
        </div>
        <div className="opening-page1" data-scroll-to="openingPageContainer">
          <div className="image-container-21">
            <img
              className="opening-page-gif-icon1"
              alt=""
              src="Opening_page_gif.gif"
            />
          </div>
          <div className="text-container-group">
            <div className="text-container23">
              <b className="democratizing-recruitment1">
                Democratizing Recruitment
              </b>
            </div>
            <div className="image-container-1">
              <div>
                {currentSvg === 1 && (
                  <div className="svg-container">
                    <img
                      className="animated-svg"
                      src="images/text_resume_builder.svg"
                      alt="resume"
                    />
                  </div>
                )}

                {currentSvg === 2 && (
                  <div className="svg-container">
                    <img
                      className="animated-svg"
                      src="images/text_cover_letter.svg"
                      alt="resume"
                    />
                  </div>
                )}

                {currentSvg === 3 && (
                  <div className="svg-container">
                    <img
                      className="animated-svg"
                      src="images/text_job_search.svg"
                      alt="resume"
                    />
                  </div>
                )}

                {currentSvg === 4 && (
                  <div className="svg-container">
                    <img
                      className="animated-svg"
                      src="images/text_interview_prep.svg"
                      alt="resume"
                    />
                  </div>
                )}
                {currentSvg === 5 && (
                  <div className="svg-container">
                    <img
                      className="animated-svg"
                      src="images/text_career_advisor.svg"
                      alt="resume"
                    />
                  </div>
                )}
                {currentSvg === 6 && (
                  <div className="svg-container">
                    <img
                      className="animated-svg"
                      src="images/text_auto_job_apply.svg"
                      alt="resume"
                    />
                  </div>
                )}
                {currentSvg === 7 && (
                  <div className="svg-container">
                    <img
                      className="animated-svg"
                      src="images/text_free_candidates_db.svg"
                      alt="resume"
                    />
                  </div>
                )}
                {currentSvg === 8 && (
                  <div className="svg-container">
                    <img
                      className="animated-svg"
                      src="images/text_automated_assessments.svg"
                      alt="resume"
                    />
                  </div>
                )}
                {currentSvg === 9 && (
                  <div className="svg-container">
                    <img
                      className="animated-svg"
                      src="images/text_auto_profile_shortlisting.svg"
                      alt="resume"
                    />
                  </div>
                )}
                {currentSvg === 10 && (
                  <div className="svg-container">
                    <img
                      className="animated-svg"
                      src="images/text_auto_interview_scheduler.svg"
                      alt="resume"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="website-footer1">
        <div className="content-container25" data-scroll-to="contentContainer">
          <div className="all-rights-reserved2">
            <div className="all-rights-reserved3">
              2024. All rights reserved.
            </div>
            <div className="list16">
              {/* <div className="item-link12">Terms and conditions</div>
              <div className="item-link13">Cookies</div>
              <div className="item-link14">Privacy policy</div> */}
            </div>
          </div>
          <div className="products-group">
            <div className="products3">
              <div className="heading-616">Products</div>
              <div className="item-link15 cursor-pointer " onClick={handleJobSeekersdemo}>Smart Recruiter</div>
              <div className="item-link15 cursor-pointer " onClick={handlerecruiterdemo}>Smart JobSeeker</div>
              <a href="/book-demo" className="no-underline text-[#454545]">
                <div className="item-link15">Book a Demo</div>
              </a>
            </div>
            <div className="heading-6-products-group">
              <div className="heading-617">Company</div>
              <a href="/about-us">
                <div className="item-link19">About</div>
              </a>
              <a href="/policies">
                <div className="item-link19">Policy</div>
              </a>
              <a href="/contact-us" className="no-underline text-[#454545]">
                <div className="item-link19 no">Contact Us</div>
              </a>
              <a href="/careers">
                <div className="item-link19">Careers</div>
              </a>
              <a href="/terms-of-use">
                <div className="item-link19">Terms Of Use</div>
              </a>
            </div>
          </div>
          <div className="follow-us1">
            <div className="follow1">Follow:</div>
            <SocialButtons />
          </div>
          <div className="logo-container-group">
            <div className="logo-container1">
              <img
                className="recruiter-robo-logo-3-2-icon1"
                alt=""
                src="/recroid.png"
              />
            </div>
            <div className="website-info-content1">
              <div className="recruiter-robo-is">{`RecRoid is a dedicated platform for all recruitment needs of Recruiters as well as job seekers. It uses state of the art AI Models, various Integrations & RPA to fulfill the requirements.`}</div>
            </div>
          </div>
        </div>
        <div className="green-ball-image-container3">
          <img className="ball3svg-icon4" alt="" src="/ball3svg1@2x.png" />
        </div>
      </div>
    </div>
  );
};

export default LandingPageLaptop1220;