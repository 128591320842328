import { Text } from "components";
import {
  AddCancelSection,
  AddMoreSection,
  EditDeleteIconsSection,
  SaveCancleSection,
  ToggleArrow,
} from "pages/JobSeekerDashBoardPages/ProfileProfessional/IconsComponents";
import { useEffect, useState } from "react";
const ProfessionalIntrests = (props) => {
  const { dataOfUser, interests, setInterests, setUserPersonalData,handleDataUpdate } = props;
  const [editedWorkDetails, setEditedWorkDetails] = useState([]);

  const [editingIndex, setEditingIndex] = useState(-1);
  const [selfEdit, setSelfEdit] = useState(false);
  const [editWork, setWorkEdit] = useState(false);
  const [editPosition, setEditPosition] = useState("");
  const [newPosition, setNewPosition] = useState("");
  const [isAddMoreClicked, setIsAddMoreClicked] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      Object.entries(dataOfUser).forEach(([key, text]) => {
        if (key.includes("Interests and hobbies") && text !== undefined) {
          if (typeof text === "object") {
            setEditedWorkDetails(text);
            setInterests(text);
          } else {
            const textArray = text.split(",");
            setEditedWorkDetails(textArray);
            setInterests(textArray);
          }
        }
      });
    }, 5000);
  
    return () => clearTimeout(timer); // Cleanup function to clear the timer
  
  }, [dataOfUser]);
  

  const toggleDeclaration = () => {
    setWorkEdit(!editWork);
    setSelfEdit(true);
    setEditingIndex(-1);
    setIsAddMoreClicked(false);
  };

  const editItemButton = (index) => {
    setEditingIndex(index);
    setSelfEdit(false);
    setEditPosition(editedWorkDetails[index]);
  };

  const saveChanges = () => {
    if (editingIndex !== -1) {
      const updatedWorkDetails = [...editedWorkDetails];
      updatedWorkDetails[editingIndex] = editPosition;
      setEditingIndex(-1);
      setEditedWorkDetails(updatedWorkDetails);

      ////console.log("Updated work details are", updatedWorkDetails);

      setInterests(editedWorkDetails);

      dataOfUser["Interests and hobbies"] = [...updatedWorkDetails];
      setUserPersonalData(dataOfUser);
    }
  };

  const handleAddMoreClick = () => {
    setIsAddMoreClicked(!isAddMoreClicked);
  };

  const addNewWorkExperience = () => {
    if (newPosition) {
      const newItem = newPosition;
      setNewPosition("");
      //setIsAddMoreClicked(false);

      const updatedWorkDetails = [...editedWorkDetails, newItem];
      setEditedWorkDetails(updatedWorkDetails);
      setInterests(updatedWorkDetails);

      ////console.log("New item is", newItem);
      ////console.log("New work details are", updatedWorkDetails);

      dataOfUser["Interests and hobbies"] = [...updatedWorkDetails];
      setUserPersonalData(dataOfUser);
    }
  };

  const deleteWork = (index) => {
    const updatedWorkDetails = [...editedWorkDetails];
    updatedWorkDetails.splice(index, 1);
    setEditedWorkDetails(updatedWorkDetails);
    setIsAddMoreClicked(true);
    setInterests(updatedWorkDetails);

    dataOfUser["Interests and hobbies"] = [...updatedWorkDetails];
    setUserPersonalData(dataOfUser);
  };

  const cancleChanges = () => {
    setEditingIndex(-1);
  };

  const cancleAdd = () => {
    setIsAddMoreClicked(false);
  };

  return (
    <div className="bg-white-A700 flex flex-col w-[94%] rounded-[20px] mqlap:text-[1.05vw] mqtab1:text-[1.15vw]">
      <ToggleArrow
        toggleDeclaration={toggleDeclaration}
        imgPath="images/interests.svg"
        sectionHeading="Interests"
        editWork={editWork}
      />
      <div>
        {editedWorkDetails.length > 0 && (
          <div className="ml-[3px]">
            {editedWorkDetails.map((work, index) => (
              <div key={index}>
                <div className="flex flex-row justify-between items-center">
                  {index === editingIndex && !selfEdit ? (
                    <div className="flex flex-row justify-between w-[80%] gap-[9px] sm:ml-[31px] items-center mb-[3px] md:ml-[0] ml-[75px] md:px-5 md:w-full">
                      <div class="w-full sm:ml-[31px] sm:flex sm:flex-col">
                      <input
                        placeholder="Interest"
                        className="work-edit-input sm:h-[20px] sm:text-[10px] rounded-[8px] w-[90%] mqlap:h-[2.8vw] mqtab1:h-[2.8vw]  mqtab1:text-[0.9vw] mqlap:pl-[1.2vw] mqtab1:pl-[1.25vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                        value={editPosition}
                        onChange={(e) => setEditPosition(e.target.value)}
                        required
                      ></input>
                      </div>
                      <SaveCancleSection
                        saveChanges={saveChanges}
                        cancleChanges={cancleChanges}
                      />
                    </div>
                  ) : (
                    <div className="w-[80%]">
                      {editWork ? (
                        <Text className="pl-[90px]  sm:text-[10px] break-words overflow-hidden mqlap:text-[0.95vw] mqtab1:text-[0.95vw]">
                          {work}
                        </Text>
                      ) : null}
                    </div>
                  )}
                  {index !== editingIndex &&
                  editWork &&
                  editedWorkDetails.length >= 1 ? (
                    <EditDeleteIconsSection
                      index={index} // Assuming you have index defined somewhere
                      editItemButton={editItemButton}
                      deleteWork={deleteWork}
                    />
                  ) : null}
                </div>
                {editWork && <div className="rectangle" />}
              </div>
            ))}
          </div>
        )}
        {!isAddMoreClicked && editWork && (
          <AddMoreSection handleAddMoreClick={handleAddMoreClick} />
        )}
        {isAddMoreClicked && editWork && (
          <AddCancelSection
          handleDataUpdate={handleDataUpdate}
            newPosition={newPosition}
            setNewPosition={setNewPosition}
            addNewWorkExperience={addNewWorkExperience}
            cancelAdd={cancleAdd}
            page="Interests"
          />
        )}
      </div>
    </div>
  );
};

export default ProfessionalIntrests;
