import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import { Line, Text } from "components";
import supabase from "components/superBaseConfig";
import Sidebar1 from "pages/RecruiterDashBoardPages/RecruiterSidebar";
import SettingsSubHeader from "pages/RecruiterDashBoardPages/SettingsSubHeader";
import TopHeader from "pages/RecruiterDashBoardPages/TopHeader";
import { useEffect, useState, useMemo } from "react";
import LoadingSpin from "react-loading-spin";

const SettingsTeam = () => {
  const invoicesData = useMemo(() => [
    {
      "S.No": "1",
      Designation: "Manager",
      email: "manager@example.com",
      name: "John Doe",
      "role ": "Manager",
    },
    {
      "S.No": "2",
      Designation: "Engineer",
      email: "engineer@example.com",
      name: "Jane Smith",
      "role ": "Engineer",
    },
    {
      "S.No": "3",
      Designation: "Analyst",
      email: "analyst@example.com",
      name: "Mike Johnson",
      "role ": "Analyst",
    },
    {
      "S.No": "4",
      Designation: "Designer",
      email: "designer@example.com",
      name: "Emily Brown",
      "role ": "Designer",
    },
    {
      "S.No": "5",
      Designation: "Developer",
      email: "developer@example.com",
      name: "Chris Davis",
      "role ": "Developer",
    },
    {
      "S.No": "6",
      Designation: "Tester",
      email: "tester@example.com",
      name: "Sarah Wilson",
      "role ": "Tester",
    },
    {
      "S.No": "7",
      Designation: "Administrator",
      email: "admin@example.com",
      name: "Alex Thompson",
      "role ": "Administrator",
    },
    {
      "S.No": "8",
      Designation: "Supervisor",
      email: "supervisor@example.com",
      name: "Jessica Martinez",
      "role ": "Supervisor",
    },
    {
      "S.No": "9",
      Designation: "Consultant",
      email: "consultant@example.com",
      name: "David Wilson",
      "role ": "Consultant",
    },
    {
      "S.No": "10",
      Designation: "Coordinator",
      email: "coordinator@example.com",
      name: "Amy White",
      "role ": "Coordinator",
    },
  ], []);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(invoicesData.length);

  useEffect(() => {
    setTotalItems(invoicesData.length);
  }, [invoicesData]);

  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  // const displayedRows = invoicesData.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const userData = {
    user_email: userEmail,
    date_joined: "2024/02/01",
  };

  const handleStart = async () => {
    //console.log(userData);
    setLoading(true);
    if (userData.user_email.includes("@gmail.com")) {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/google`, userData)
        .then((data) => {
          //console.log(data);
        })
        .catch((error) => console.error(error));
    } else {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/microsoft`,
        userData,
      );

      if (response.data.access_token) {
        // If an access token is found in the response
        // const accessToken = response.data.access_token;

        // Call the /token_generation/microsoft/messagelist endpoint
        // const messagelistResponse = await axios.post(
        //   `${process.env.REACT_APP_API_URL}/token_generation/microsoft/messagelist`,
        //   {
        //     access_token: accessToken,
        //     date_joined: userData.date_joined,
        //     flow: {},
        //     user_email: userData.user_email,
        //   },
        // );

        // Handle the response as needed
        //console.log("Message list response:", messagelistResponse.data);
      } else if (response.data.flow) {
        const { flow } = response.data;
        const flowMessage = flow.message;

        // Create a new div element to hold the iframe
        const iframeContainer = document.createElement("div");
        iframeContainer.id = "iframe-alert";
        iframeContainer.style.position = "fixed";
        iframeContainer.style.top = "50%";
        iframeContainer.style.left = "50%";
        iframeContainer.style.transform = "translate(-50%, -50%)";
        iframeContainer.style.width = "400px";
        iframeContainer.style.padding = "20px";
        iframeContainer.style.backgroundColor = "#fff";
        iframeContainer.style.border = "1px solid #ccc";
        iframeContainer.style.borderRadius = "8px";
        iframeContainer.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
        iframeContainer.style.zIndex = "10000";

        // Create the iframe element
        const iframe = document.createElement("iframe");
        iframe.style.width = "100%";
        iframe.style.height = "auto";
        iframe.style.border = "none";

        // Append the iframe to the container
        iframeContainer.appendChild(iframe);
        document.body.appendChild(iframeContainer);

        // Set the iframe content
        const iframeDoc =
          iframe.contentDocument || iframe.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(`
        <html>
        <head>
        <style>
            body {
                font-family: Arial, sans-serif;
                padding: 10px;
                margin: 0;
            }
            .content {
                display: flex; /* Use flexbox layout */
                flex-direction: column; /* Arrange children in a column */
                justify-content: center; /* Center children horizontally */
                align-items: center; /* Center children vertically */
                height: 100vh; /* Set the height of the container to the full height of the viewport */
                text-align: center; /* Center the text of children */
            }
            button {
                padding: 8px 16px;
                margin: 10px;
                background-color: #5298fe;
                color: white;
                border: none;
                cursor: pointer;
                border-radius: 4px;
            }
            button:hover {
                background-color: #4177ce;
            }
        </style>
        </head>
        <body>
            <div class="content">
                <p id="flowMessage">${flowMessage}</p>
                <button id="cancelBtn">Cancel</button>
            </div>
        </body>
        </html>
        `);
        iframeDoc.close();

        // Attach event listeners to buttons within the iframe
        const cancelBtn = iframeDoc.getElementById("cancelBtn");

        const removeIframe = () => {
          setLoading(false);
          document.body.removeChild(iframeContainer);
          // return;
        };

        cancelBtn.addEventListener("click", removeIframe);

        const tokenCheckResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/token_received/microsoft`,
          {
            flow,
            date_joined: userData.date_joined,
            access_token: "",
            user_email: userData.user_email,
          },
        );
        //console.log(tokenCheckResponse);
        if (tokenCheckResponse.data) {
          // If the backend response is true (success), proceed with the same actions as before
          const paragraphElement = iframeDoc.getElementById("flowMessage");
          paragraphElement.innerHTML = "Downloading attachments ...";
          cancelBtn.remove();

          const combinedData = {
            flow,
            date_joined: userData.date_joined,
            access_token: "",
            user_email: userData.user_email,
          };

          // Send the combined data to another backend URL
          await axios.post(
            `${process.env.REACT_APP_API_URL}/token_generation/microsoft/messagelist`,
            combinedData,
          );
          // Remove the iframe and close the popup
          removeIframe();
        } else {
          //console.log("Token check failed.");
        }
      } else {
        //console.log("here");
      }
    }
    //console.log("Start button clicked");
    setLoading(false);
  };

  useEffect(() => {
    async function getUserData() {
      await supabase.auth.getUser().then((value) => {
        //console.log(value);
        setUserEmail(value.data.user.email);
      });
    }
    getUserData();
    // Open the popup immediately when the component mounts
  }, []); // Empty dependency array ensures that the effect runs only once on mount

  return (
    <>
      {" "}
      {loading && <LoadingSpin />}
      {!loading && (
        <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
          <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
            <Sidebar1 clickedTab={"Settings"} />

            <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
              <TopHeader header={"Settings"} />

              <div
                style={{ overflow: "scroll" }}
                className="bg-white-A700 flex flex-col font-poppins items-center justify-start p-7 sm:px-5 rounded-[15px] w-[94%] md:w-full h-[93vh]"
              >
                <div className="flex flex-col gap-[43px] justify-start mb-[199px] mt-2.5 w-[99%] md:w-full">
                  <SettingsSubHeader billingheader={1} />

                  <div style={{ marginBottom: "10px" }}>
                    <button
                      onClick={handleStart}
                      style={{
                        backgroundColor: "green", // A softer shade of green
                        color: "white",
                        padding: "8px", // Slightly reduced padding
                        borderRadius: "4px", // Reduced border radius
                        cursor: "pointer",
                        width: "100px",
                        fontSize: "12px", // Smaller font size
                      }}
                    >
                      Sync Mails
                    </button>
                  </div>

                  <TableContainer
                    component={Paper}
                    className="w-[93%] md:w-full"
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Text
                              className="text-base text-blue_gray-400 text-lg"
                              size="txtInterSemiBold16"
                            >
                              S.No
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              className="text-base text-blue_gray-400 text-lg"
                              size="txtInterSemiBold16"
                            >
                              Designation
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              className="text-base text-blue_gray-400 text-lg"
                              size="txtInterSemiBold16"
                            >
                              Email
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              className="text-base text-blue_gray-400 text-lg"
                              size="txtInterSemiBold16"
                            >
                              Name
                            </Text>
                          </TableCell>
                          <TableCell>
                            {" "}
                            <Text
                              className="text-base text-blue_gray-400 text-lg"
                              size="txtInterSemiBold16"
                            >
                              Role
                            </Text>{" "}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {invoicesData.map((invoice) => (
                          <TableRow key={invoice.id}>
                            <TableCell>
                              <Text
                                className="text-gray-650 text-lg"
                                size="txtInterMedium5"
                              >
                                {invoice["S.No"]}
                              </Text>
                            </TableCell>
                            <TableCell>
                              <Text
                                className="text-gray-650 text-lg"
                                size="txtInterMedium5"
                              >
                                {invoice.Designation}
                              </Text>
                            </TableCell>
                            <TableCell>
                              <Text
                                className="text-gray-650 text-lg"
                                size="txtInterMedium5"
                              >
                                {invoice.email}
                              </Text>
                            </TableCell>
                            <TableCell>
                              <Text
                                className="text-gray-650 text-lg"
                                size="txtInterMedium5"
                              >
                                {invoice.name}
                              </Text>
                            </TableCell>
                            <TableCell>
                              <Text
                                className="text-gray-650 text-lg"
                                size="txtInterMedium5"
                              >
                                {invoice["role "]}
                              </Text>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Line className="bg-blue_gray-100 h-px w-full" />
                  </TableContainer>
                  <div className="w-full flex flex-row justify-center mt-[50px]">
                    <Pagination
                      color="primary"
                      variant="outlined"
                      shape="rounded"
                      page={currentPage}
                      count={Math.ceil(totalItems / itemsPerPage)}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SettingsTeam;
