
import Sidebar1 from "pages/RecruiterDashBoardPages/RecruiterSidebar";
import TopHeader from "pages/RecruiterDashBoardPages/TopHeader";

import { Img, Line, Text } from "components";

const analytics = () => {
  

  return (
    <>
        <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <Sidebar1 clickedTab={"Analytics"} />
          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
              <TopHeader header={"Analytics"} />
              <div
              style={{ overflow: "scroll" }}
              className="bg-white-A700 flex flex-col font-poppins items-center justify-start rounded-[15px] w-[94%] md:w-full h-[93vh]"
            >
            
          
          {/* <div className="flex flex-1 flex-col font-inter gap-[49px] items-center justify-start md:px-5 w-full"> */}

            <div className="font-poppins h-[938px] relative w-[95%] md:w-full">
              <div className="absolute h-[937px] md:h-[938px] inset-[0] justify-center m-auto w-full">
                {/* <div className="bg-white-A700 h-[938px] m-auto w-full"></div> */}
                <div className="absolute bg-white-A700 bottom-[1%] flex flex-col gap-[26px] items-center justify-start p-5 right-[0] w-[43%]">
                  <Text
                    className="text-blue_gray-400 text-center text-sm"
                    size="txtPoppinsMedium14"
                  >
                    Candidates applied for different position
                  </Text>
                  <div className="flex sm:flex-col flex-row gap-10 items-center justify-start mb-[47px] w-[84%] md:w-full">
                    <div
                      className="bg-cover bg-no-repeat flex flex-col h-[319px] items-center justify-end p-14 md:px-10 sm:px-5 w-[319px]"
                      style={{ backgroundImage: "url('images/img_graph.svg')" }}
                    >
                      <div className="flex flex-row items-start justify-start mb-[11px] mt-[26px] w-[98%] md:w-full">
                        <Text
                          className="mt-[37px] text-white-A700 text-xs"
                          size="txtPoppinsMedium12"
                        >
                          25%
                        </Text>
                        <div className="flex flex-col md:gap-10 gap-[120px] justify-start ml-[30px] w-[65%]">
                          <Text
                            className="md:ml-[0] ml-[93px] text-white-A700 text-xs"
                            size="txtPoppinsMedium12"
                          >
                            40%
                          </Text>
                          <div className="flex flex-row items-start justify-start w-full">
                            <Text
                              className="mt-[13px] text-white-A700 text-xs"
                              size="txtPoppinsMedium12"
                            >
                              15%
                            </Text>
                            <Text
                              className="ml-[62px] mt-[13px] text-white-A700 text-xs"
                              size="txtPoppinsMedium12"
                            >
                              10%
                            </Text>
                            <Text
                              className="mb-[13px] ml-[13px] text-white-A700 text-xs"
                              size="txtPoppinsMedium12"
                            >
                              5%
                            </Text>
                          </div>
                        </div>
                        <Text
                          className="ml-0.5 mt-[117px] text-white-A700 text-xs"
                          size="txtPoppinsMedium12"
                        >
                          5%
                        </Text>
                      </div>
                    </div>
                    <div className="flex flex-col items-start justify-start w-[31%] sm:w-full">
                      <div className="flex flex-row gap-2 items-start justify-start w-[54%] md:w-full">
                        <div className="bg-teal-300 h-3 my-1 rounded-[50%] w-3"></div>
                        <Text
                          className="text-blue_gray-400 text-center text-sm"
                          size="txtPoppinsRegular14"
                        >
                          QA tester
                        </Text>
                      </div>
                      <div className="flex flex-row gap-2 items-center justify-start mt-[11px] w-[77%] md:w-full">
                        <div className="bg-pink-900 h-3 my-1 rounded-[50%] w-3"></div>
                        <Text
                          className="text-blue_gray-400 text-sm"
                          size="txtPoppinsRegular14"
                        >
                          UI/UX Designer
                        </Text>
                      </div>
                      <div className="flex flex-row gap-2 items-start justify-start mt-[13px] w-full">
                        <div className="bg-pink-300 h-3 mb-1.5 mt-0.5 rounded-[50%] w-3"></div>
                        <Text
                          className="text-blue_gray-400 text-sm"
                          size="txtPoppinsRegular14"
                        >
                          Frontend developer
                        </Text>
                      </div>
                      <div className="flex flex-row gap-2 items-start justify-start mt-3 w-[99%] md:w-full">
                        <div className="bg-lime-700 h-3 mb-1.5 mt-0.5 rounded-[50%] w-3"></div>
                        <Text
                          className="text-blue_gray-400 text-sm"
                          size="txtPoppinsRegular14"
                        >
                          Backend developer
                        </Text>
                      </div>
                      <div className="flex flex-row gap-2 items-center justify-start mt-2.5 w-[74%] md:w-full">
                        <div className="bg-yellow-900 h-3 my-1 rounded-[50%] w-3"></div>
                        <Text
                          className="text-blue_gray-400 text-sm"
                          size="txtPoppinsRegular14"
                        >
                          Data scientist
                        </Text>
                      </div>
                      <div className="flex flex-row gap-2 items-center justify-start mt-3 w-[62%] md:w-full">
                        <div className="bg-teal-900 h-3 my-1 rounded-[50%] w-3"></div>
                        <Text
                          className="text-blue_gray-400 text-sm"
                          size="txtPoppinsRegular14"
                        >
                          Consultant
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="absolute bg-white-A700 bottom-[1%] flex flex-col items-center justify-start left-[5%] p-1.5 w-[43%]">
                  <div className="flex flex-col items-center justify-start w-[98%] md:w-full">
                    <Text
                      className="text-blue_gray-400 text-center text-sm"
                      size="txtPoppinsMedium14"
                    >
                      Candidates by Degree
                    </Text>
                    <div className="flex md:flex-col flex-row gap-[11px] items-center justify-between mt-[5px] w-full">
                      <Text
                        className="rotate-[90deg] text-blue_gray-400 text-center text-sm"
                        size="txtPoppinsMedium14"
                      >
                        Degrees
                      </Text>
                      <div className="sm:h-[2758px] md:h-[360px] h-[377px] relative w-[96%] md:w-full">
                        <div className="absolute sm:h-[2758px] md:h-[360px] h-[361px] inset-x-[0] mx-auto top-[0] w-[98%] sm:w-full">
                          <div className="absolute bottom-[7%] flex flex-col items-center justify-start right-[0] w-[86%]">
                            <div className="flex flex-col md:gap-10 gap-[68px] items-center justify-start w-full">
                              <div className="bg-gray-200 h-[87px] w-full"></div>
                              <div className="bg-gray-200 flex flex-col items-start justify-end p-[31px] sm:px-5 w-full">
                                <Text
                                  className="ml-32 md:ml-[0] mt-0.5 text-blue_gray-400 text-center text-sm"
                                  size="txtPoppinsSemiBold14Bluegray400"
                                >
                                  600
                                </Text>
                              </div>
                            </div>
                          </div>
                          <div className="absolute flex flex-col h-full inset-y-[0] items-center justify-start left-[0] my-auto w-[16%]">
                            <div className="flex flex-row gap-[5px] items-start justify-between w-full">
                              <div className="flex flex-col gap-[59px] items-end justify-start mt-[49px] w-[71%]">
                                <div className="flex flex-col md:gap-10 gap-[60px] items-center justify-start">
                                  <Text
                                    className="text-blue_gray-400 text-center text-xs"
                                    size="txtPoppinsMedium12Bluegray400"
                                  >
                                    Doctoral
                                  </Text>
                                  <Text
                                    className="text-blue_gray-400 text-center text-xs"
                                    size="txtPoppinsMedium12Bluegray400"
                                  >
                                    Master’s
                                  </Text>
                                </div>
                                <div className="flex flex-col items-start justify-start w-full">
                                  <Text
                                    className="text-blue_gray-400 text-center text-xs"
                                    size="txtPoppinsMedium12Bluegray400"
                                  >
                                    Bachelor’s
                                  </Text>
                                  <Text
                                    className="mt-[60px] text-blue_gray-400 text-center text-xs"
                                    size="txtPoppinsMedium12Bluegray400"
                                  >
                                    Associate
                                  </Text>
                                  <Text
                                    className="md:ml-[0] ml-[47px] mt-[41px] text-blue_gray-400 text-center text-xs"
                                    size="txtPoppinsMedium12Bluegray400"
                                  >
                                    0
                                  </Text>
                                </div>
                              </div>
                              <div className="flex flex-col items-center justify-start mb-[7px] w-[23%]">
                                <div className="h-[352px] relative w-full">
                                  <Line className="absolute bg-gray-500_60 h-[352px] inset-y-[0] my-auto right-[0] w-px" />
                                  <Line className="absolute bg-gray-500_60 h-px inset-x-[0] mx-auto rotate-[180deg] top-[17%] w-full" />
                                  <Line className="absolute bg-gray-500_60 h-px inset-x-[0] mx-auto rotate-[180deg] top-[0] w-full" />
                                  <Line className="absolute bg-gray-500_60 h-px inset-x-[0] mx-auto rotate-[180deg] top-[39%] w-full" />
                                  <Line className="absolute bg-gray-500_60 bottom-[39%] h-px inset-x-[0] mx-auto rotate-[180deg] w-full" />
                                  <Line className="absolute bg-gray-500_60 bottom-[16%] h-px inset-x-[0] mx-auto rotate-[180deg] w-full" />
                                </div>
                                <Line className="bg-gray-500_60 h-px rotate-[180deg] w-full" />
                              </div>
                            </div>
                          </div>
                          <div className="absolute flex sm:flex-col flex-row gap-[49px] h-max inset-[0] items-center justify-center m-auto w-[52%]">
                            <Line className="bg-gray-500_60 h-[352px] sm:h-px sm:w-full w-px" />
                            <Line className="bg-gray-500_60 h-[352px] sm:h-px sm:w-full w-px" />
                            <Line className="bg-gray-500_60 h-[352px] sm:h-px sm:w-full w-px" />
                            <Line className="bg-gray-500_60 h-[352px] sm:h-px sm:w-full w-px" />
                            <Line className="bg-gray-500_60 h-[352px] sm:h-px sm:w-full w-px" />
                            <Line className="bg-gray-500_60 h-[352px] sm:h-px sm:w-full w-px" />
                            <Line className="bg-gray-500_60 h-[352px] sm:h-px sm:w-full w-px" />
                          </div>
                        </div>
                        <Line className="absolute bg-gray-500_60 h-[352px] right-[19%] top-[0] w-px" />
                        <Line className="absolute bg-gray-500_60 h-[352px] right-[10%] top-[0] w-px" />
                        <Line className="absolute bg-gray-500_60 h-[352px] right-[2%] top-[0] w-px" />
                        <div className="absolute flex flex-col gap-[23px] items-start justify-start left-[15%] top-[9%] w-[62%]">
                          <div className="flex flex-row gap-2 items-center justify-start w-[31%] md:w-full">
                            <div className="bg-blue-800 h-[54px] w-[70%]"></div>
                            <Text
                              className="text-blue_gray-400 text-center text-sm"
                              size="txtPoppinsSemiBold14Bluegray400"
                            >
                              290
                            </Text>
                          </div>
                          <div className="flex flex-row gap-[9px] items-center justify-start w-[85%] md:w-full">
                            <div className="bg-blue-800 h-[54px] w-[88%]"></div>
                            <Text
                              className="text-blue_gray-400 text-center text-sm"
                              size="txtPoppinsSemiBold14Bluegray400"
                            >
                              1,100
                            </Text>
                          </div>
                          <div className="flex flex-row gap-2 items-center justify-between w-full">
                            <div className="bg-blue-800 h-[54px] w-[89%]"></div>
                            <Text
                              className="text-blue_gray-400 text-center text-sm"
                              size="txtPoppinsSemiBold14Bluegray400"
                            >
                              1,250
                            </Text>
                          </div>
                          <div className="bg-blue-800 h-[54px] w-[41%]"></div>
                        </div>
                        <Text
                          className="absolute bottom-[0] left-[21%] text-blue_gray-400 text-center text-xs"
                          size="txtPoppinsMedium12Bluegray400"
                        >
                          200
                        </Text>
                        <Text
                          className="absolute bottom-[0] left-[29%] text-blue_gray-400 text-center text-xs"
                          size="txtPoppinsMedium12Bluegray400"
                        >
                          400
                        </Text>
                        <Text
                          className="absolute bottom-[0] left-[38%] text-blue_gray-400 text-center text-xs"
                          size="txtPoppinsMedium12Bluegray400"
                        >
                          600
                        </Text>
                        <Text
                          className="absolute bottom-[0] left-[46%] text-blue_gray-400 text-center text-xs"
                          size="txtPoppinsMedium12Bluegray400"
                        >
                          800
                        </Text>
                        <Text
                          className="absolute bottom-[0] right-[42%] text-blue_gray-400 text-center text-xs"
                          size="txtPoppinsMedium12Bluegray400"
                        >
                          1000
                        </Text>
                        <Text
                          className="absolute bottom-[0] right-[33%] text-blue_gray-400 text-center text-xs"
                          size="txtPoppinsMedium12Bluegray400"
                        >
                          1200
                        </Text>
                        <Text
                          className="absolute bottom-[0] right-1/4 text-blue_gray-400 text-center text-xs"
                          size="txtPoppinsMedium12Bluegray400"
                        >
                          1400
                        </Text>
                        <Text
                          className="absolute bottom-[0] right-[17%] text-blue_gray-400 text-center text-xs"
                          size="txtPoppinsMedium12Bluegray400"
                        >
                          1600
                        </Text>
                        <Text
                          className="absolute bottom-[0] right-[8%] text-blue_gray-400 text-center text-xs"
                          size="txtPoppinsMedium12Bluegray400"
                        >
                          1800
                        </Text>
                        <Text
                          className="absolute bottom-[0] right-[0] text-blue_gray-400 text-center text-xs"
                          size="txtPoppinsMedium12Bluegray400"
                        >
                          2000
                        </Text>
                      </div>
                    </div>
                    <Text
                      className="mt-[15px] text-blue_gray-400 text-center text-sm"
                      size="txtPoppinsMedium14"
                    >
                      Candidates
                    </Text>
                  </div>
                </div>
                <div className="absolute bg-white-A700 flex flex-col items-center justify-end p-[19px] right-[0] top-[0] w-[43%]">
                  <div className="flex md:flex-col flex-row md:gap-10 items-start justify-between w-[99%] md:w-full">
                    <Text
                      className="md:mt-0 mt-[179px] rotate-[90deg] text-blue_gray-400 text-center text-xs"
                      size="txtPoppinsMedium12Bluegray400"
                    >
                      Match %
                    </Text>
                    <div className="flex flex-col items-end justify-start md:mt-0 mt-0.5 pl-1.5">
                      <div className="flex flex-row gap-[11px] items-center justify-between w-[99%] md:w-full">
                        <div className="flex flex-col gap-[49px] justify-start w-1/2">
                          <Text
                            className="mr-[5px] text-blue_gray-400 text-center text-xs"
                            size="txtPoppinsMedium12Bluegray400"
                          >
                            100%
                          </Text>
                          <div className="flex flex-col items-start justify-start md:ml-[0] ml-[5px]">
                            <Text
                              className="text-blue_gray-400 text-center text-xs"
                              size="txtPoppinsMedium12Bluegray400"
                            >
                              80%
                            </Text>
                            <Text
                              className="mt-[49px] text-blue_gray-400 text-center text-xs"
                              size="txtPoppinsMedium12Bluegray400"
                            >
                              60%
                            </Text>
                            <Text
                              className="mt-[50px] text-blue_gray-400 text-center text-xs"
                              size="txtPoppinsMedium12Bluegray400"
                            >
                              40%
                            </Text>
                            <Text
                              className="mt-[49px] text-blue_gray-400 text-center text-xs"
                              size="txtPoppinsMedium12Bluegray400"
                            >
                              20%
                            </Text>
                            <Text
                              className="md:ml-[0] ml-[19px] mt-10 text-blue_gray-400 text-center text-xs"
                              size="txtPoppinsMedium12Bluegray400"
                            >
                              0
                            </Text>
                          </div>
                        </div>
                        <div className="flex flex-col items-start justify-start w-[32%]">
                          <div className="h-[331px] relative w-full">
                            <Line className="absolute bg-blue_gray-400 h-[331px] inset-y-[0] my-auto right-[0] w-px" />
                            <Line className="absolute bg-blue_gray-400 h-px left-[0] rotate-[180deg] top-[0] w-full" />
                            <Line className="absolute bg-blue_gray-400 h-px right-[0] rotate-[180deg] top-[21%] w-full" />
                            <Line className="absolute bg-blue_gray-400 h-px right-[0] rotate-[180deg] top-[41%] w-full" />
                            <Line className="absolute bg-blue_gray-400 bottom-[38%] h-px right-[0] rotate-[180deg] w-full" />
                            <Line className="absolute bg-blue_gray-400 bottom-[18%] h-px right-[0] rotate-[180deg] w-full" />
                          </div>
                          <Line className="bg-blue_gray-400 h-px rotate-[180deg] w-full" />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col justify-start">
                      <div className="flex flex-row font-inter items-end justify-end md:ml-[0] ml-[371px] w-[24%] md:w-full">
                        <Text
                          className="my-[5px] text-blue_gray-400 text-center text-sm"
                          size="txtInterMedium14"
                        >
                          Sort by YOE
                        </Text>
                        <Img
                          className="h-7 md:h-auto ml-0.5 object-cover w-7"
                          src="images/img_sortdown.png"
                          alt="sortdown"
                        />
                      </div>
                      <Img
                        className="h-[225px] md:ml-[0] ml-[26px] mt-[90px]"
                        src="images/img_bars.svg"
                        alt="bars"
                      />
                      <div className="flex flex-col font-poppins items-center justify-start mr-[52px] mt-[11px] w-[90%] md:w-full">
                        <div className="flex flex-col gap-1.5 items-center justify-start w-full">
                          <div className="h-4 md:h-[15px] relative w-[98%] sm:w-full">
                            <Line className="absolute bg-blue_gray-400 h-px inset-x-[0] mx-auto top-[0] w-full" />
                            <Line className="absolute bg-blue_gray-400 bottom-[0] h-[15px] left-[0] w-px" />
                            <div className="absolute flex flex-row inset-x-[0] items-center justify-between mx-auto top-[0] w-[67%]">
                              <Line className="bg-blue_gray-400 h-[15px] w-px" />
                              <Line className="bg-blue_gray-400 h-[15px] w-px" />
                              <Line className="bg-blue_gray-400 h-[15px] w-px" />
                              <Line className="bg-blue_gray-400 h-[15px] w-px" />
                              <Line className="bg-blue_gray-400 h-[15px] w-px" />
                            </div>
                            <Line className="absolute bg-blue_gray-400 h-[15px] right-[0] top-[0] w-px" />
                          </div>
                          <div className="flex flex-row items-start justify-between w-full">
                            <Text
                              className="text-blue_gray-400 text-center text-xs"
                              size="txtPoppinsMedium12Bluegray400"
                            >
                              0
                            </Text>
                            <Text
                              className="text-blue_gray-400 text-center text-xs"
                              size="txtPoppinsMedium12Bluegray400"
                            >
                              2
                            </Text>
                            <Text
                              className="text-blue_gray-400 text-center text-xs"
                              size="txtPoppinsMedium12Bluegray400"
                            >
                              4
                            </Text>
                            <Text
                              className="text-blue_gray-400 text-center text-xs"
                              size="txtPoppinsMedium12Bluegray400"
                            >
                              6
                            </Text>
                            <Text
                              className="text-blue_gray-400 text-center text-xs"
                              size="txtPoppinsMedium12Bluegray400"
                            >
                              8
                            </Text>
                            <Text
                              className="text-blue_gray-400 text-center text-xs"
                              size="txtPoppinsMedium12Bluegray400"
                            >
                              10
                            </Text>
                            <Text
                              className="text-blue_gray-400 text-center text-xs"
                              size="txtPoppinsMedium12Bluegray400"
                            >
                              12
                            </Text>
                          </div>
                        </div>
                      </div>
                      <Text
                        className="md:ml-[0] ml-[101px] text-blue_gray-400 text-center text-xs"
                        size="txtPoppinsMedium12Bluegray400"
                      >
                        Years of experience (YOE)
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
              <div className="absolute bg-white-A700 flex flex-col font-inter items-center justify-start left-[5%] p-[13px] top-[0] w-[43%]">
                <div className="flex md:flex-col flex-row gap-[18px] items-start justify-between my-[11px] w-full">
                  <div className="flex md:flex-1 flex-col gap-[51px] items-start justify-start w-[55%] md:w-full">
                    <div className="flex flex-row gap-4 items-center justify-start md:ml-[0] ml-[7px] w-[61%] md:w-full">
                      <div className="flex flex-row items-center justify-evenly w-[52%]">
                        <Text
                          className="text-blue_gray-400 text-center text-sm"
                          size="txtInterMedium14"
                        >
                          Candidates
                        </Text>
                        <Img
                          className="h-7 md:h-auto object-cover w-7"
                          src="images/img_sortdown.png"
                          alt="sortdown_One"
                        />
                      </div>
                      <div className="flex flex-row items-end justify-evenly w-[41%]">
                        <Text
                          className="my-[5px] text-blue_gray-400 text-center text-sm"
                          size="txtInterMedium14"
                        >
                          Country
                        </Text>
                        <Img
                          className="h-7 md:h-auto object-cover w-7"
                          src="images/img_sortdown.png"
                          alt="sortdown_Two"
                        />
                      </div>
                    </div>
                    <Img
                      className="h-[247px] md:h-auto object-cover w-full"
                      src="images/img_screenshot20230324.png"
                      alt="screenshot20230"
                    />
                    <div className="flex flex-row items-end justify-start md:ml-[0] ml-[7px] w-[32%] md:w-full">
                      <Text
                        className="my-[5px] text-blue_gray-400 text-center text-sm"
                        size="txtInterMedium14"
                      >
                        Last 7 days
                      </Text>
                      <Img
                        className="h-7 md:h-auto ml-0.5 object-cover w-7"
                        src="images/img_sortdown.png"
                        alt="sortdown_Three"
                      />
                    </div>
                  </div>
                  <div className="flex md:flex-1 flex-col gap-[7px] items-center justify-start md:mt-0 mt-[62px] w-[43%] md:w-full">
                    <div className="flex flex-row items-center justify-between w-[94%] md:w-full">
                      <Text
                        className="text-center text-gray-500 text-sm"
                        size="txtInterMedium14Gray500"
                      >
                        COUNTRY
                      </Text>
                      <Text
                        className="text-center text-gray-500 text-sm"
                        size="txtInterMedium14Gray500"
                      >
                        CANDIDATES
                      </Text>
                    </div>
                    <div className="flex flex-col gap-[7px] items-center justify-start w-full">
                      <Line className="bg-gray-500 h-px w-full" />
                      <div className="flex flex-row items-center justify-between w-[95%] md:w-full">
                        <Text
                          className="text-blue_gray-400 text-center text-sm"
                          size="txtInterMedium14"
                        >
                          United States
                        </Text>
                        <Text
                          className="text-blue_gray-400 text-right text-sm"
                          size="txtInterMedium14"
                        >
                          43
                        </Text>
                      </div>
                      <Line className="bg-gray-500 h-px w-full" />
                      <div className="flex flex-row items-center justify-between w-[95%] md:w-full">
                        <Text
                          className="text-blue_gray-400 text-sm"
                          size="txtInterMedium14"
                        >
                          India
                        </Text>
                        <Text
                          className="text-blue_gray-400 text-right text-sm"
                          size="txtInterMedium14"
                        >
                          413
                        </Text>
                      </div>
                      <Line className="bg-gray-500 h-px w-full" />
                      <div className="flex flex-row items-center justify-between w-[95%] md:w-full">
                        <Text
                          className="text-blue_gray-400 text-sm"
                          size="txtInterMedium14"
                        >
                          Canada
                        </Text>
                        <Text
                          className="text-blue_gray-400 text-right text-sm"
                          size="txtInterMedium14"
                        >
                          143
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default analytics;
