import React, { useEffect, useRef, useState } from "react";
import supabase from "components/superBaseConfig";
import { format } from "date-fns";

const apiUrl = `${process.env.REACT_APP_API_URL}/post_interviewprep_assets`;

const WebcamRecorder = ({
  handleStartClose,
  onVideoLinkChange,
  onVideoDataChange,
  webCamRecording,
}) => {
  const [mediaRecorderVideo, setMediaRecorderVideo] = useState(null);
  const [recording, setRecording] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const userVideo = useRef({});
  const hasMounted = useRef(false);
  const areFilesReady = useRef(true);

  const stopRecording = () => {
    if (userVideo.current && userVideo.current.srcObject) {
      userVideo.current.srcObject.getTracks().forEach((track) => track.stop());
      setRecording(false);
    } else {
      console.error(
        "userVideo.current or its srcObject is null, cannot stop recording.",
      );
    }
  };

  useEffect(() => {
    if (!webCamRecording) stopRecording();
  }, [webCamRecording]);

  useEffect(() => {
    if (!hasMounted.current) {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((stream) => {
          userVideo.current.srcObject = stream;
          setRecording(true);
          hasMounted.current = true;
        });
    }
  }, []);

  return (
    <div className="flex items-center flex-col w-full h-full justify-start">
      <div className="w-full h-[30%] flex flex-col items-end"></div>
      <div className="w-full flex flex-col items-center">
        {!videoFile && (
          <div className="flex justify-center w-full h-[100%]">
            <video
              playsInline
              className="bg-[#F4F6F9] p-[5px] w-[100%]"
              muted
              ref={userVideo}
              autoPlay
            />
            <div
              className={`${
                recording
                  ? "p-1 m-2 rounded-full w-3 h-3 bg-red-400 animate-pulse"
                  : ""
              }`}
            ></div>
          </div>
        )}
        {videoFile && (
          <>
            <div>
              <video controls width="400">
                <source
                  src={URL.createObjectURL(videoFile)}
                  type="video/webm"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default WebcamRecorder;