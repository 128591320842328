import supabase from "components/superBaseConfig";
import Cookies from "js-cookie";
import { useCallback, useState } from "react";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getEnvURL } from "utils/getEnvURL";

const frontEndURL = getEnvURL();

const RecruiterRoboLogo31Icon = styled.img`
  position: absolute;
  top: 11.5px;
  left: 0px;
  width: 79px;
  height: 91px;
  object-fit: cover;
`;
const RecruiterRoboLogo31Wrapper = styled.div`
  position: relative;
  width: 97.2px;
  height: 117px;
  overflow: hidden;
  flex-shrink: 0;
`;
const WebsiteName = styled.div`
  position: relative;
  line-height: 135.5%;
  font-weight: 600;
  display: inline-block;
  width: 213.3px;
  height: 34.2px;
  flex-shrink: 0;
  color: #161616;
  top: 34px;
  right: 18px;
  font-size: 20.5px;
`;
const FrameParent = styled.div`
  position: absolute;
  top: 59px;
  left: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
// const TopNav = styled.div`
//   position: absolute;
//   top: 54px;
//   left: 71px;
//   width: 310.4px;
//   height: 117px;
// `;
const SignInTo = styled.div`
  position: relative;
  font-size: 37.94px;
  line-height: 61.65px;
  font-weight: 600;
  text-align: center;
`;
const InputLabelmedium = styled.div`
  position: absolute;
  top: 0%;
  left: calc(50% - 313px);
  line-height: 34.38px;
`;
const GroupChild = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 313px);
  border-radius: 9.48px;
  background-color: var(--color-white);
  border: 1.2px solid var(--color-gainsboro);
  box-sizing: border-box;
  width: 626px;
  height: 61.7px;
`;

const RectangleParent = styled.div`
  position: absolute;
  top: 35.6px;
  left: calc(50% - 313px);
  width: 626px;
  height: 61.7px;
  font-size: 18.97px;
  color: var(--color-darkgray-100);
`;
const Email = styled.div`
  position: relative;
  width: 626px;
  height: 97.2px;
`;
const RectangleGroup = styled.div`
  position: absolute;
  top: 35.6px;
  left: calc(50% - 313px);
  width: 626px;
  height: 61.7px;
  font-size: 18.97px;
  color: var(--color-silver-100);
`;
const SubmitChild = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 313px);
  border-radius: 7.11px;
  background-color: var(--color-darkslateblue);
  width: 626px;
  height: 59.3px;
`;
const LogIn = styled.div`
  position: absolute;
  top: calc(50% - 15.45px);
  left: calc(50% - 33.2px);
  line-height: 30.83px;
`;
const Submit = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 313px);
  width: 626px;
  height: 59.3px;
`;
const SignIn = styled.span`
  text-decoration: underline;
`;
const AlreadyHaveAnContainer = styled.div`
  position: absolute;
  font-family: var(--font-poppins);
  color: var(--color-gray-400);
  top: 86.5px;
  left: 164.8px;
  line-height: 30.83px;
  text-align: left;
`;
const SubmitParent = styled.div`
  position: relative;
  width: 626px;
  height: 117.4px;
  text-align: center;
  font-size: 18.97px;
  color: var(--color-white);
`;
const SignInToDhiwiseParent = styled.div`
  position: absolute;
  top: 215px;
  left: calc(50% - 313px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 28.45px;
  font-size: 21.34px;
  color: var(--color-gray-200);
  font-family: var(--font-inter);
`;
const SignupTabletRoot = styled.div`
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  height: 100vh;
  overflow: scroll;
  text-align: left;
  font-size: 26.06px;
  color: var(--color-gray-300);
  font-family: var(--font-poppins);
`;

const SignupTablet = () => {
  const navigate = useNavigate();

  const OnSignInContainerClick = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  // const [type1, setType1] = useState("password");
  // const [icon1, setIcon1] = useState(eyeOff);
  const changeVisibility = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
  
    try {
      const { data, error } = await supabase.auth.signUp({
        email: email,
        password: password,
        options: {
          redirectTo: `${frontEndURL}/jobseeker-dashboard`,
          data: { 
            firstName: firstName,
            lastName: lastName,
          },
        },
      });
  
      if (error) {
        throw error;
      }
  
      if (data.session) {
        const userId = data.user.id;
        const accessToken = JSON.stringify(data.session.access_token);
  
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/create-session`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: userId,
            }),
          },
        );
  
        const sessionData = await response.json();
        const sessionId = sessionData.sessionId;
  
        Cookies.set("accessToken", accessToken, { expires: 7 });
        Cookies.set("sessionId", sessionId, { expires: 7 });
  
        localStorage.setItem(
          `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`,
          JSON.stringify(data["session"]),
        );
  
      window.location.href = `${frontEndURL}/jobseeker-dashboard`;
      } else {
        alert("Please check your email for verification.");
        navigate("/login");
      }
    } catch (error) {
      if (
        error.error_code === "user_already_exists" ||
        error.toString().includes("User already registered")
      ) {
        alert("User already exists");
        navigate("/login");
      } else {
        alert("Please try again later");
      }
    }
  };

  const onLogo = useCallback(() => {
    navigate("/");
  }, [navigate]);
  return (
    <SignupTabletRoot>
      {/* <TopNav> */}
      <FrameParent>
        <RecruiterRoboLogo31Wrapper>
          <RecruiterRoboLogo31Icon
            alt=""
            src="/recroid.png"
            onClick={onLogo}
            style={{ cursor: "pointer" }}
          />
        </RecruiterRoboLogo31Wrapper>
        <WebsiteName
          onClick={onLogo}
          style={{ cursor: "pointer", color: "rgb(49 76 163)" }}
        >
          RecRoid
        </WebsiteName>
      </FrameParent>
      {/* </TopNav> */}
      <form onSubmit={handleSubmit}>
        <SignInToDhiwiseParent>
          <SignInTo>Sign up to RecRoid</SignInTo>
          <Email>
            <InputLabelmedium>First Name</InputLabelmedium>
            <RectangleParent>
              <GroupChild />
              <input
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  borderRadius: "9.61px",
                  backgroundColor: "var(--color-white)",
                  border: "1.2px solid var(--color-gainsboro)",
                  boxSizing: "border-box",
                  width: "100%",
                  height: "62.5px",
                  color: "black",
                }}
                type="text"
                placeholder="Enter First Name"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                required
              />
            </RectangleParent>
          </Email>
          <Email>
            <InputLabelmedium>Last Name</InputLabelmedium>
            <RectangleParent>
              <GroupChild />
              <input
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  borderRadius: "9.61px",
                  backgroundColor: "var(--color-white)",
                  border: "1.2px solid var(--color-gainsboro)",
                  boxSizing: "border-box",
                  width: "100%",
                  height: "62.5px",
                  color: "black",
                }}
                type="text"
                placeholder="Enter Last Name"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                required
              />
            </RectangleParent>
          </Email>
          <Email>
            <InputLabelmedium>Email Address</InputLabelmedium>
            <RectangleGroup>
              <GroupChild />
              <input
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  borderRadius: "9.61px",
                  backgroundColor: "var(--color-white)",
                  border: "1.2px solid var(--color-gainsboro)",
                  boxSizing: "border-box",
                  width: "100%",
                  height: "62.5px",
                  color: "black",
                }}
                type="email"
                placeholder="Enter Email Address"
                value={email}
                onChange={(e) => setemail(e.target.value)}
                required
              />
            </RectangleGroup>
          </Email>
          <Email>
            <InputLabelmedium>Enter Password</InputLabelmedium>
            <RectangleGroup>
              <GroupChild />
              <div className="pass">
                <input
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    borderRadius: "9.61px",
                    backgroundColor: "var(--color-white)",
                    border: "1.2px solid var(--color-gainsboro)",
                    boxSizing: "border-box",
                    width: "100%",
                    height: "62.5px",
                    color: "black",
                  }}
                  type={type}
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <div
                  style={{ position: "absolute", left: 580, top: 16 }}
                  className="flex  justify-end items-center pl-3 !border-colors !rounded-[12px] "
                  onClick={changeVisibility}
                >
                  <Icon icon={icon} size={23} style={{ color: "grey" }} />
                </div>
              </div>
            </RectangleGroup>
          </Email>
          <Email>
            <InputLabelmedium>Confirm Password</InputLabelmedium>
            <RectangleGroup>
              <GroupChild />
              <input
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  borderRadius: "9.61px",
                  backgroundColor: "var(--color-white)",
                  border: "1.2px solid var(--color-gainsboro)",
                  boxSizing: "border-box",
                  width: "100%",
                  height: "62.5px",
                  color: "black",
                }}
                type="password"
                placeholder="Enter Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </RectangleGroup>
          </Email>
          <SubmitParent>
            <button type="submit">
              <Submit style={{ cursor: "pointer" }}>
                <SubmitChild />
                <LogIn>Sign Up</LogIn>
              </Submit>
            </button>

            <AlreadyHaveAnContainer>
              {`Already have an account? `}
              <SignIn
                onClick={OnSignInContainerClick}
                style={{ cursor: "pointer" }}
              >
                Sign In
              </SignIn>
            </AlreadyHaveAnContainer>
          </SubmitParent>
        </SignInToDhiwiseParent>
      </form>
    </SignupTabletRoot>
  );
};

export default SignupTablet;
