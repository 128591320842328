import FrameComponent1 from "./FrameComponent1";
import FrameComponent5 from "./FrameComponent5";

import PropTypes from "prop-types";
const FrameComponent = ({ className = "" , onDateTimeChange}) => {

  const handleDateChange = (slot, date) => {
    onDateTimeChange(slot, { date });
  };

  const handleTimeChange = (slot, time) => {
    onDateTimeChange(slot, { time });
  };
  
  return (
    <div
      className={`self-stretch flex flex-col items-start justify-start gap-[44px] max-w-full text-center text-[24px] text-black font-montserrat-alternates mq825:gap-[22px] ${className}`}
    >
       {[1, 2, 3].map((slot) => (
      <div className="self-stretch rounded-lg box-border flex flex-col items-start justify-start pt-[29px] pb-[25px] pr-10 pl-[41px] gap-[34px] max-w-full border-[1px] border-solid border-[#EAEAEB] mq825:gap-[17px]">
        <div className="w-[1106px] h-[233px] relative rounded-lg box-border hidden max-w-full border-[1px] border-solid border-[#EAEAEB]" />
        <div className="w-[143px] relative leading-[29px] font-semibold inline-block z-[1] mq450:text-lgi mq450:leading-[23px]">
          Book Slot {slot}
        </div>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-center gap-[21px] max-w-full">
            <FrameComponent1
              selectDate="Select Date"
              uiwdate="/uiwdate.svg"
              onChange={(date) => handleDateChange(slot, date)}
            />
            <FrameComponent5
              selectTime="Select Time"
              uiwdate="/mingcutetimeline.svg"
              propHeight="30px"
              propWidth="30px"
              onChange={(time) => handleTimeChange(slot, time)}
            />
          </div>
      </div>
      ))}
      {/* <div className="self-stretch rounded-lg box-border flex flex-col items-start justify-start pt-[29px] px-[39px] pb-[25px] gap-[34px] max-w-full border-[1px] border-solid border-[#EAEAEB] mq825:gap-[17px]">
        <div className="w-[1106px] h-[233px] relative rounded-lg box-border hidden max-w-full border-[1px] border-solid border-gainsboro-100" />
        <div className="relative leading-[29px] font-semibold z-[1] mq450:text-lgi mq450:leading-[23px]">
          Book Slot 2
        </div>
        <div className="self-stretch flex flex-row items-start justify-start py-0 pr-px pl-0.5 box-border max-w-full">
          <div className="flex-1 flex flex-row flex-wrap items-start justify-center gap-[21px] max-w-full">
            <FrameComponent1
              selectDate="Select Date "
              uiwdate="/uiwdate.svg"
              propHeight="26px"
              propWidth="26px"
            />
            <FrameComponent5
              selectTime="Select Time "
              uiwdate="/uiwdate-2.svg"
              propHeight="26px"
              propWidth="26px"
            />
          </div>
        </div>
      </div>
      <div className="self-stretch rounded-lg box-border flex flex-col items-start justify-start pt-[29px] px-[39px] pb-[25px] gap-[34px] max-w-full border-[1px] border-solid border-[#EAEAEB] mq825:gap-[17px]">
        <div className="w-[1106px] h-[233px] relative rounded-lg box-border hidden max-w-full border-[1px] border-solid border-gainsboro-100" />
        <div className="relative leading-[29px] font-semibold z-[1] mq450:text-lgi mq450:leading-[23px]">
          Book Slot 3
        </div>
        <div className="self-stretch flex flex-row items-start justify-start py-0 pr-px pl-0.5 box-border max-w-full">
          <div className="flex-1 flex flex-row flex-wrap items-start justify-center gap-[21px] max-w-full">
            <FrameComponent1
              selectDate="Select Date "
              uiwdate="/uiwdate.svg"
              propHeight="26px"
              propWidth="26px"
            />
            <FrameComponent5
              selectTime="Select Time "
              uiwdate="/uiwdate-2.svg"
              propHeight="26px"
              propWidth="26px"
            />
          </div>
        </div>
      </div> */}
      
    </div>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
  onDateTimeChange: PropTypes.func.isRequired,
};

export default FrameComponent;