import axios from "axios";

export const fetchJobSearchData = (userId) => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_JOB_SEARCH_REQUEST" });

    try {
      const userData = {
        user_id: userId,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/job_search`,
        userData,
      );

      const sortedData = response.data.sort((a, b) => {
        return new Date(b.jobPosted) - new Date(a.jobPosted);
      });

      dispatch({ type: "FETCH_JOB_SEARCH_SUCCESS", payload: sortedData });
    } catch (error) {
      dispatch({ type: "FETCH_JOB_SEARCH_FAILURE", payload: error.message });
      console.error("Error fetching job search data:", error.message);
      throw error;
    }
  };
};
