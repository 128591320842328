const initialState = {
    notifications: [],
};
const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_NOTIFICATIONS':
            return { notifications: action.payload }
        default:
            return state;
    }
}
export default notificationsReducer;