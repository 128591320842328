import React from "react";
import Sidebar1 from "pages/UniversityDashBoardPages/UniversitySidebar";
import TopHeader from "pages/RecruiterDashBoardPages/TopHeader";
import { createColumnHelper } from "@tanstack/react-table";
import { Button, Img, Line, PlacementRecordsTable , PlacementPastRecordsTable ,  Text } from "components";
import { useState } from "react";
// import PastPlacement from "pages/UniversityPastPlacement"
// import CurrentPlacement from 
  const UniversityPlacementRecords = () => {

    const tableData = React.useRef([
      {
        sno: "1.",
        companyname: "Concentrix",
        placementcoordiOne: "David Willy",
        interviewdate: "9876543210",
        applicationdeadOne: "20-12-2023",
        placementpositiOne: "Software Developer",
        placementstatusOne: "Placed",
      },
      {
        sno: "2.",
        companyname: "Amazon",
        placementcoordiOne: "David Willy",
        interviewdate: "9876543210",
        applicationdeadOne: "20-12-2023",
        placementpositiOne: "Software Developer",
        placementstatusOne: "Placed",
      },
      {
        sno: "3.",
        companyname: "Apple",
        placementcoordiOne: "David Willy",
        interviewdate: "9876543210",
        applicationdeadOne: "20-12-2023",
        placementpositiOne: "Software Developer",
        placementstatusOne: "Placed",
      },
      {
        sno: "4.",
        companyname: "Meta",
        placementcoordiOne: "David Willy",
        interviewdate: "9876543210",
        applicationdeadOne: "20-12-2023",
        placementpositiOne: "Software Developer",
        placementstatusOne: "Placed",
      },
      {
        sno: "5.",
        companyname: "Microsoft",
        placementcoordiOne: "David Willy",
        interviewdate: "9876543210",
        applicationdeadOne: "20-12-2023",
        placementpositiOne: "Software Developer",
        placementstatusOne: "Placed",
      },
      {
        sno: "6.",
        companyname: "Google",
        placementcoordiOne: "David Willy",
        interviewdate: "9876543210",
        applicationdeadOne: "20-12-2023",
        placementpositiOne: "Software Developer",
        placementstatusOne: "Placed",
      },
      {
        sno: "7.",
        companyname: "Netflix",
        placementcoordiOne: "David Willy",
        interviewdate: "9876543210",
        applicationdeadOne: "20-12-2023",
        placementpositiOne: "Software Developer",
        placementstatusOne: "Placed",
      },
      {
        sno: "8.",
        companyname: "Wipro",
        placementcoordiOne: "David Willy",
        interviewdate: "9876543210",
        applicationdeadOne: "20-12-2023",
        placementpositiOne: "Software Developer",
        placementstatusOne: "Placed",
      },
    ]);
    const tableColumns = React.useMemo(() => {
      const tableColumnHelper = createColumnHelper();
      return [
        tableColumnHelper.accessor("sno", {
          cell: (info) => (
            <Text
              className="pb-[13px] pt-[34px] sm:px-5 px-[35px] text-gray-800 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[158px] sm:px-5 px-[34px] text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              S. No
            </Text>
          ),
        }),
        tableColumnHelper.accessor("companyname", {
          cell: (info) => (
            <Text
              className="pb-3 pl-3.5 pt-[35px] text-gray-800 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[196px] pt-[3px] text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              Company Name
            </Text>
          ),
        }),
        tableColumnHelper.accessor("placementcoordiOne", {
          cell: (info) => (
            <Text
              className="pb-[11px] sm:pl-5 pl-[35px] pt-[35px] text-gray-800 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[257px] text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              Placement Coordinator
            </Text>
          ),
        }),
        tableColumnHelper.accessor("interviewdate", {
          cell: (info) => (
            <Text
              className="pb-3 pl-[9px] pt-[35px] text-gray-800 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[224px] text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              Interview Date
            </Text>
          ),
        }),
        tableColumnHelper.accessor("applicationdeadOne", {
          cell: (info) => (
            <Text
              className="pb-3 pl-[17px] pt-[35px] text-gray-800 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[257px] text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              Application Deadline
            </Text>
          ),
        }),
        tableColumnHelper.accessor("placementpositiOne", {
          cell: (info) => (
            <Text
              className="pb-[11px] pt-[35px] text-gray-800 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[231px] pl-1 text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              Placement Position
            </Text>
          ),
        }),
        tableColumnHelper.accessor("placementstatusOne", {
          cell: (info) => (
            <Text
              className="pb-3 pl-[19px] pt-[35px] text-gray-800 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[219px] text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              Placement Status
            </Text>
          ),
        }),
      ];
    }, []);


    const tableDataPast = React.useRef([
      {
        sno: "1.",
        companyname: "Concentrix",
        placementcoordiOne: "David Willy",
        interviewdate: "9876543210",
        applicationdeadOne: "20-12-2023",
        placementpositiOne: "Software Developer",
        placementstatusOne: "Placed",
      },
      {
        sno: "2.",
        companyname: "Amazon",
        placementcoordiOne: "David Willy",
        interviewdate: "9876543210",
        applicationdeadOne: "20-12-2023",
        placementpositiOne: "Software Developer",
        placementstatusOne: "Placed",
      },
      {
        sno: "3.",
        companyname: "Apple",
        placementcoordiOne: "David Willy",
        interviewdate: "9876543210",
        applicationdeadOne: "20-12-2023",
        placementpositiOne: "Software Developer",
        placementstatusOne: "Placed",
      },
      {
        sno: "4.",
        companyname: "Meta",
        placementcoordiOne: "David Willy",
        interviewdate: "9876543210",
        applicationdeadOne: "20-12-2023",
        placementpositiOne: "Software Developer",
        placementstatusOne: "Placed",
      },
      {
        sno: "5.",
        companyname: "Microsoft",
        placementcoordiOne: "David Willy",
        interviewdate: "9876543210",
        applicationdeadOne: "20-12-2023",
        placementpositiOne: "Software Developer",
        placementstatusOne: "Placed",
      },
      {
        sno: "6.",
        companyname: "Google",
        placementcoordiOne: "David Willy",
        interviewdate: "9876543210",
        applicationdeadOne: "20-12-2023",
        placementpositiOne: "Software Developer",
        placementstatusOne: "Placed",
      },
      {
        sno: "7.",
        companyname: "Netflix",
        placementcoordiOne: "David Willy",
        interviewdate: "9876543210",
        applicationdeadOne: "20-12-2023",
        placementpositiOne: "Software Developer",
        placementstatusOne: "Placed",
      },
      {
        sno: "8.",
        companyname: "Wipro",
        placementcoordiOne: "David Willy",
        interviewdate: "9876543210",
        applicationdeadOne: "20-12-2023",
        placementpositiOne: "Software Developer",
        placementstatusOne: "Placed",
      },
    ]);
    const tableColumnsPast = React.useMemo(() => {
      const tableColumnHelperPast = createColumnHelper();
      return [
        tableColumnHelperPast.accessor("sno", {
          cell: (info) => (
            <Text
              className="pb-[13px] pt-[34px] sm:px-5 px-[35px] text-gray-800 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[158px] sm:px-5 px-[34px] text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              S. No
            </Text>
          ),
        }),
        tableColumnHelperPast.accessor("companyname", {
          cell: (info) => (
            <Text
              className="pb-3 pl-3.5 pt-[35px] text-gray-800 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[196px] pt-[3px] text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              Company Name
            </Text>
          ),
        }),
        tableColumnHelperPast.accessor("placementcoordiOne", {
          cell: (info) => (
            <Text
              className="pb-[11px] sm:pl-5 pl-[35px] pt-[35px] text-gray-800 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[257px] text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              Placement Coordinator
            </Text>
          ),
        }),
        tableColumnHelperPast.accessor("interviewdate", {
          cell: (info) => (
            <Text
              className="pb-3 pl-[9px] pt-[35px] text-gray-800 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[224px] text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              Interview Date
            </Text>
          ),
        }),
        tableColumnHelperPast.accessor("applicationdeadOne", {
          cell: (info) => (
            <Text
              className="pb-3 pl-[17px] pt-[35px] text-gray-800 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[257px] text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              Application Deadline
            </Text>
          ),
        }),
        tableColumnHelperPast.accessor("placementpositiOne", {
          cell: (info) => (
            <Text
              className="pb-[11px] pt-[35px] text-gray-800 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[231px] pl-1 text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              Placement Position
            </Text>
          ),
        }),
        tableColumnHelperPast.accessor("placementstatusOne", {
          cell: (info) => (
            <Text
              className="pb-3 pl-[19px] pt-[35px] text-gray-800 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[219px] text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              Placement Status
            </Text>
          ),
        }),
      ];
    }, []);

    const [currentPage, setCurrentPage] = useState('current'); 
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };

  return (
    <>
          <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
            <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
              <Sidebar1 clickedTab={"Placement Records"} />

              <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
                <TopHeader header={"Placement Records"} />


                <div className="bg-white-A700 flex flex-col items-center justify-start p-7 sm:px-5 rounded-[15px] w-[94%] md:w-full">
                  <div className=" font-poppins md:h-[1103px] h-[1165px] relative w-[94%] md:w-full">
              <div className="flex flex-col items-center justify-start w-full">
                <div className="flex flex-col gap-9 items-start justify-start w-[91%] md:w-full">
                  <div className="flex sm:flex-col flex-row sm:gap-10 gap-[82px] items-start justify-start w-[432px] sm:w-full">
                    <div className="flex flex-col gap-2 items-start justify-start w-auto cursor-pointer" onClick={() => handlePageChange('current')}> 
                      <Text
                        style={{ color: currentPage === 'current' ? '#4F46E5' : '#6B7280' }}
                        className="text-indigo-600 text-xl w-auto"
                        size="txtInterMedium20"
                      >
                        Current Placements
                      </Text>
                      {currentPage === 'current' && <Line className="bg-indigo-600 h-[3px] rounded-tl-[10px] rounded-tr-[10px] w-full" />}
                    </div>
                    <div className="flex flex-col gap-2 items-start justify-start w-auto cursor-pointer" onClick={() => handlePageChange('previous')}>
                    <Text
                      style={{ color: currentPage === 'previous' ? '#4F46E5' : '#6B7280' }}
                      className="text-blue_gray-400 text-xl w-auto"
                      size="txtInterMedium20Bluegray400"
                    >
                      Past Placements
                    </Text>
                    {currentPage === 'previous' && <Line className="bg-indigo-600 h-[3px] rounded-tl-[10px] rounded-tr-[10px] w-full" />}
                    </div> 
                    {/* {/* {currentPage === 'current' && <PastPlacement />} */}
                    {/* {currentPage === 'previous' && <PastPlacement />}                    / */}
                  </div>
                  <div className="flex flex-row md:gap-10 items-end justify-between w-full">
                    <div className="flex flex-col gap-[9px] items-center justify-start mt-3.5 w-[6%]">
                      <Text
                        className="text-blue-A200 text-lg"
                        size="txtInterSemiBold18"
                      >
                        All 37
                      </Text>
                      <Line className="bg-blue-A200 h-1 w-full" />
                    </div>
                    <div className="bg-blue-500 flex flex-row gap-[38px] h-[50px] md:h-auto items-center justify-start md:px-10 sm:px-5 px-[46px] py-2.5 rounded-[15px] w-[220px]">
                      <Img
                        className="h-6 w-6"
                        src="images/img_materialsymbol_white_a700.svg"
                        alt="materialsymbol_One"
                      />
                      <Text
                        className="text-white-A700 text-xl w-auto"
                        size="txtInterMedium20WhiteA700"
                      >
                        Filter
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="overflow-auto mt-[65px] w-full">
                  {/* <PlacementRecordsTable
                    columns={tableColumns}
                    data={tableData.current}
                    rowClass={""}
                    headerClass=""
                  /> */}
                  {currentPage === 'current' ? (
                  <PlacementRecordsTable
                    columns={tableColumns}
                    data={tableData.current}
                    rowClass={""}
                    headerClass=""
                  />
                   ) : null}
                   {currentPage==='previous'?(
                    <PlacementPastRecordsTable
                    columns={tableColumnsPast}
                    data={tableDataPast.current}
                    rowClass={""}
                    headerClass=""
                  />
                   ): null}
                  
                </div>
                <div className="flex sm:flex-col flex-row font-poppins md:gap-10 items-start justify-between mt-[38px] w-[92%] md:w-full">
                  <div className="flex flex-col items-center justify-start">
                    <Text
                      className="text-black-900 text-lg"
                      size="txtPoppinsMedium18"
                    >
                      <span className="text-blue_gray-900 font-poppins text-left font-medium">
                        Showing 1-8{" "}
                      </span>
                      <span className="text-gray-500 font-poppins text-left font-medium">
                        of 35
                      </span>
                    </Text>
                  </div>
                  <div className="flex flex-row font-inter items-center justify-between sm:mt-0 mt-0.5 w-[24%] sm:w-full">
                    <div className="flex flex-row items-start justify-evenly">
                      <Img
                        className="h-6 w-6"
                        src="images/img_arrowleft.svg"
                        alt="arrowleft"
                      />
                      <Text
                        className="text-[15px] text-indigo-600"
                        size="txtInterMedium15"
                      >
                        Previous
                      </Text>
                    </div>
                    <Button className="cursor-pointer font-medium h-10 leading-[normal] text-[15px] text-center w-10">
                      1
                    </Button>
                    <Text
                      className="text-[15px] text-indigo-600"
                      size="txtInterMedium15"
                    >
                      2
                    </Text>
                    <Text
                      className="text-[15px] text-indigo-600"
                      size="txtInterMedium15"
                    >
                      3
                    </Text>
                    <Text
                      className="text-[15px] text-indigo-600"
                      size="txtInterMedium15"
                    >
                      4
                    </Text>
                    <div className="flex flex-row items-center justify-evenly">
                      <Text
                        className="text-[15px] text-indigo-600"
                        size="txtInterMedium15"
                      >
                        Next
                      </Text>
                      <Img
                        className="h-6 w-6"
                        src="images/img_arrowright.svg"
                        alt="arrowright"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

                 </div>
                </div>
              </div>
            {/* </div>
          </div> */}
        </>
  );
};

export default UniversityPlacementRecords;
