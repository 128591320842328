import GroupComponent from "./GroupComponent";
import { Pagination } from "@mui/material";

import PropTypes from "prop-types";
import { useState} from "react";
import { useSelector } from "react-redux";


const SearchContainer = ({ className = "" }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 4;
  const loading = useSelector((state) => state.jobSearchReducer.loading);
  const [searchKeyword, setSearchKeyword] = useState("");
  
  const jobSearchData = useSelector((state) => state.jobSearchReducer.jobData);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;


  const [isOpen, setIsOpen] = useState(false);
  const [isOpenType, setIsOpenType] = useState(false);

  const [selectedValue, setSelectedValue] = useState('Department');
  const [selectedValue2, setSelectedValue2] = useState('Type');

  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filteredJobSearchData = jobSearchData
    ? jobSearchData.filter((job) =>
        job.companyName.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        job.jobRole.toLowerCase().includes(searchKeyword.toLowerCase())
      )
    : [];

  const displayedRows = filteredJobSearchData.slice(startIndex, endIndex);


   // Options for the dropdown
   const options = ['HR', 'Design', 'App', 'Web', 'DevOps', 'Social media', 'CRM', 'Product'];
   const options2 = ['Fulltime', 'Intership', 'Parttime', 'Contract'];


   // Toggle the dropdown list
   const toggleDropdown = () => {
     setIsOpen(!isOpen);

   };
   const toggleDropdown2 = () => {
    setIsOpenType(!isOpenType);

  };
 
   // Handle selecting an option
   const handleSelect = (option) => {
     setSelectedValue(option);
     setIsOpen(false);
   };

   const handleSelect2 = (option) => {
    setSelectedValue2(option);
    setIsOpenType(false);
  };

  return (
    <div
      className={`w-[1215px] flex flex-col items-start justify-center gap-[64px] max-w-full text-left text-[24px] text-black font-poppins mq825:gap-[32px] mq450:gap-[16px] mqtab1:w-[64rem] ${className}`}
    >
      <div className="self-stretch flex flex-col items-end justify-start gap-[51px] max-w-full mq825:gap-[25px]">
        <div className="self-stretch rounded-4xs bg-whitesmoke-300 flex flex-col items-start justify-start pt-5 px-[23px] pb-[23px] box-border gap-[27px] max-w-full">
          <div className="w-[1210px] h-[150px] relative rounded-4xs bg-whitesmoke-300 hidden max-w-full" />
          <div className="self-stretch flex flex-row items-start justify-between max-w-full gap-[20px] mq1125:flex-wrap">
            <div className="w-[857px] flex flex-row items-start justify-start gap-[30px] max-w-full mq825:flex-wrap">
              <div className="flex flex-col items-start justify-start pt-[10.5px] px-0 pb-0">
                <div className="relative leading-[29px] z-[1] mq450:text-lgi mq450:leading-[23px]">{`Search Here : `}</div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start py-0 pr-[25px] pl-0 box-border min-w-[327px] max-w-full">
                <div className="self-stretch rounded-2xs bg-whitesmoke-200 box-border flex flex-row items-start justify-start h-[2.9rem] max-w-full z-[1] border-[1px] border-solid border-lightgray">
                  <div className="h-[50px]  relative rounded-2xs bg-whitesmoke-200 box-border hidden max-w-full border-[1px] border-solid border-lightgray" />
                  <input
                    className="w-full border-none outline-none font-medium font-poppins text-mid-8 bg-[transparent] h-[100%] relative text-black text-left inline-block p-[1.5rem] z-[2] boxShadow-none focus:outline-none focus:ring-0 focus:border-transparent"
                    placeholder="Add keywords"
                    type="search"
                    value={searchKeyword}
                    onChange={(e) => setSearchKeyword(e.target.value)}
                  />
                </div>
              </div>
              {/* <div className="relative leading-[29px] z-[1] mq450:text-lgi mq450:leading-[23px]">
                Filtered by :
              </div> */}
            </div>
            {/* <div className="flex flex-col items-start justify-start pt-[5px] px-0 pb-0 text-base-3">
              <a className="[text-decoration:underline] relative leading-[20px] text-[inherit] inline-block min-w-[44px] z-[1]">
                Clear
              </a>
            </div> */}
          </div>
          
          <div className="flex flex-row items-start justify-start py-0 px-px box-border max-w-full">
            <div className="flex flex-row items-start justify-start gap-[37px] max-w-full mq450:flex-wrap mq450:gap-[18px]">
           <div>
              <div className="flex flex-row items-start justify-start gap-[13px] relative">
                <div className="relative leading-[29px] inline-block min-w-[58px] z-[1] mq450:text-lgi mq450:leading-[23px]">
              {selectedValue2}
              {/* Type */}

                </div>
                <img
                  className="h-[30px] w-[30px] relative overflow-hidden shrink-0 min-h-[30px] z-[1]"
                  alt=""
                  src="/icondropdown.svg"
                  onClick={toggleDropdown2}
                />
                 {/* Dropdown List */}
               {isOpenType && (
                 <div className="absolute top-[12.5px] left-[84px] mt-2 w-[150px] bg-[#ffff] border border-gray-300 rounded-md shadow-lg z-[10]  text-[14px] w-[205px]">
                     <div className="flex flex-wrap  rounded p-[14px] text-center gap-[3px]">
                       {options2.map((type) => (
                 <div
                key={type}
                className="flex px-2 py-1 m-1 hover:bg-cornflowerblue cursor-pointer rounded bg-darkslateblue text-[#ffff] w-fit"
                onClick={() => handleSelect2(type)}
                 >
                        {type}
                 </div>
                 ))}
            </div>
                 </div>
               )}
              </div>
              
               </div>
              <div>
              <div className="flex flex-row items-start justify-start gap-[13px] relative">
                <div className="relative leading-[29px] z-[1] mq450:text-lgi mq450:leading-[23px] ">
                {selectedValue}
                {/* Departmenyt */}

                </div>
                <img
                  className="h-[30px] w-[30px] relative overflow-hidden shrink-0 min-h-[30px] z-[1]"
                  alt=""
                  src="/icondropdown.svg"
                  onClick={toggleDropdown}
                />
                {/* Dropdown List */}
               {isOpen && (
                 <div className="absolute  mt-2 w-[150px] bg-[#ffff] border border-gray-300 rounded-md shadow-lg z-[10]  text-[14px] w-[205px] top-[12.5px] left-[171px]">
                     <div className="flex flex-wrap  rounded p-[14px] text-center gap-[3px]">
            {options.map((option) => (
              <div
                key={option}
                className="flex px-2 py-1 m-1 hover:bg-cornflowerblue cursor-pointer rounded bg-darkslateblue text-[#ffff] w-fit"
                onClick={() => handleSelect(option)}
              >
                {option}
              </div>
            ))}
          </div>
                 </div>
               )}
              </div>
               
    </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[63px] max-w-full mq825:gap-[31px] mq450:gap-[16px]">
        {displayedRows.length > 0 ? (
            displayedRows.map((data, index) => (
              <GroupComponent
                key={index}
                productMarketingAssociate="Product Marketing Associate"
                marketing="Marketing"
                companyName={data.companyName}
                fullTime="Full - Time"
                role={data.jobRole}
                applyHref={data.jobUrl}
                applyTarget="_blank"
              />
            ))
          ) : (
            !loading && <div>No results found.</div>
          )}
          {/* <GroupComponent
            productMarketingAssociate="Backend Developer"
            marketing="Web"
            fullTime="Full - Time"
            propBoxShadow="unset"
            propBoxShadow1="unset"
            propDisplay="unset"
            propWidth="163.1px"
            propMinWidth="46px"
            propTextDecoration="unset"
          /> */}
        </div>
      </div>
      {/* <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[63px] max-w-full mq825:gap-[31px] mq450:gap-[16px]">
        <GroupComponent
          productMarketingAssociate="Product Design Intern"
          marketing="Design"
          fullTime="Internship"
          propBoxShadow="unset"
          propBoxShadow1="unset"
          propDisplay="unset"
          propWidth="183.1px"
          propMinWidth="69px"
          propTextDecoration="unset"
        />
        <GroupComponent
          productMarketingAssociate="Social Media Marketing Intern"
          marketing="Marketing"
          fullTime="Internship"
          propBoxShadow="unset"
          propBoxShadow1="unset"
          propDisplay="inline-block"
          propWidth="214.1px"
          propMinWidth="100px"
          propTextDecoration="unset"
        />
      </div> */}
       <div className="mb-[70px] flex flex-col inset-x-[0] items-center justify-center mx-auto w-full">
                  <div className="flex flex-row items-center justify-end mt-[10px] w-[100%] md:w-full">
                    <div className="flex flex-row items-start justify-evenly w-full">
                    {!loading && filteredJobSearchData.length > 0 && (
                        <Pagination
                          color="primary"
                          variant="outlined"
                          shape="rounded"
                          currentPage={currentPage}
                          count={Math.ceil(filteredJobSearchData.length / itemsPerPage)}
                          onChange={(event, pageNumber) => handlePageChange(event, pageNumber)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                
    </div>
  );
};

SearchContainer.propTypes = {
  className: PropTypes.string,
};

export default SearchContainer;
