
import React from "react";

import Sidebar1 from "pages/UniversityDashBoardPages/UniversitySidebar";
import TopHeader from "pages/RecruiterDashBoardPages/TopHeader";

import { createColumnHelper } from "@tanstack/react-table";

import { Button, Img, Line, InterviewSchedulingTable, Text } from "components";

  const UniversityInterviewingScheduling = () => {

    const tableData = React.useRef([
      {
        sno: "1.",
        companyname: "Concentrix",
        placementcoordiOne: "David Willy",
        interviewdate: "22 Dec 2023",
        applicationdeadOne: "20-12-2023",
        placementpositiOne: "Software Developer",
        placementstatusOne: "In progress",
      },
      {
        sno: "2.",
        companyname: "Amazon",
        placementcoordiOne: "David Willy",
        interviewdate: "22 Dec 2023",
        applicationdeadOne: "20-12-2023",
        placementpositiOne: "Software Developer",
        placementstatusOne: "Placed",
      },
      {
        sno: "3.",
        companyname: "Apple",
        placementcoordiOne: "David Willy",
        interviewdate: "22 Dec 2023",
        applicationdeadOne: "20-12-2023",
        placementpositiOne: "Software Developer",
        placementstatusOne: "Placed",
      },
      {
        sno: "4.",
        companyname: "Meta",
        placementcoordiOne: "David Willy",
        interviewdate: "22 Dec 2023",
        applicationdeadOne: "20-12-2023",
        placementpositiOne: "Software Developer",
        placementstatusOne: "Placed",
      },
      {
        sno: "5.",
        companyname: "Microsoft",
        placementcoordiOne: "David Willy",
        interviewdate: "22 Dec 2023",
        applicationdeadOne: "20-12-2023",
        placementpositiOne: "Software Developer",
        placementstatusOne: "Placed",
      },
      {
        sno: "6.",
        companyname: "Google",
        placementcoordiOne: "David Willy",
        interviewdate: "22 Dec 2023",
        applicationdeadOne: "20-12-2023",
        placementpositiOne: "Software Developer",
        placementstatusOne: "Placed",
      },
      {
        sno: "7.",
        companyname: "Netflix",
        placementcoordiOne: "David Willy",
        interviewdate: "22 Dec 2023",
        applicationdeadOne: "20-12-2023",
        placementpositiOne: "Software Developer",
        placementstatusOne: "Placed",
      },
      {
        sno: "8.",
        companyname: "Wipro",
        placementcoordiOne: "David Willy",
        interviewdate: "22 Dec 2023",
        applicationdeadOne: "20-12-2023",
        placementpositiOne: "Software Developer",
        placementstatusOne: "Placed",
      },
    ]);
    const tableColumns = React.useMemo(() => {
      const tableColumnHelper = createColumnHelper();
      return [
        tableColumnHelper.accessor("sno", {
          cell: (info) => (
            <Text
              className="pb-[13px] sm:pl-5 pl-[35px] pt-[34px] text-gray-800 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[158px] sm:pl-5 pl-6 text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              S. No
            </Text>
          ),
        }),
        tableColumnHelper.accessor("companyname", {
          cell: (info) => (
            <Text
              className="pb-3 pl-3.5 pt-[35px] text-gray-800 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[196px] pt-[3px] text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              Company Name
            </Text>
          ),
        }),
        tableColumnHelper.accessor("placementcoordiOne", {
          cell: (info) => (
            <Text
              className="pb-[11px] sm:pl-5 pl-[35px] pt-[35px] text-gray-800 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[229px] text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              Placement Coordinator
            </Text>
          ),
        }),
        tableColumnHelper.accessor("interviewdate", {
          cell: (info) => (
            <div className="flex flex-row gap-[7px] items-start justify-start p-1.5">
              <Img
                className="h-6 mb-[5px] mt-[29px] w-6"
                src="images/img_carboncalendar.svg"
                alt="carboncalendar"
              />
              <Text
                className="mt-[30px] text-gray-800 text-lg"
                size="txtInterMedium18"
              >
                {info?.getValue()}
              </Text>
            </div>
          ),
          header: (info) => (
            <Text
              className="min-w-[252px] sm:pl-5 pl-7 text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              Interview Date
            </Text>
          ),
        }),
        tableColumnHelper.accessor("applicationdeadOne", {
          cell: (info) => (
            <Text
              className="pb-3 pl-[17px] pt-[35px] text-gray-800 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[250px] text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              Application Deadline
            </Text>
          ),
        }),
        tableColumnHelper.accessor("placementpositiOne", {
          cell: (info) => (
            <Text
              className="pb-[11px] pl-[7px] pt-[35px] text-gray-800 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[238px] text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              Placement Position
            </Text>
          ),
        }),
        tableColumnHelper.accessor("placementstatusOne", {
          cell: (info) => (
            <Text
              className="pb-2.5 pl-[19px] pt-[35px] text-gray-800 text-lg"
              size="txtInterMedium18"
            >
              {info?.getValue()}
            </Text>
          ),
          header: (info) => (
            <Text
              className="min-w-[219px] text-blue_gray-400 text-lg"
              size="txtInterSemiBold18Bluegray400"
            >
              Placement Status
            </Text>
          ),
        }),
      ];
    }, []);

  return (
    <>
          <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
            <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
              <Sidebar1 clickedTab={"Interviewing Scheduling"} />

              <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
                <TopHeader header={"Interviewing Scheduling"} />


                <div className="bg-white-A700 flex flex-col items-center justify-start p-7 sm:px-5 rounded-[15px] w-[94%] md:w-full">
                  <div className=" font-poppins md:h-[1103px] h-[1165px] relative w-[94%] md:w-full">
                    



                  {/* <div className="flex flex-1 flex-col font-inter gap-[50px] items-center justify-start md:px-5 w-full">
            
            <div className="bg-white-A700 flex flex-col items-center justify-start p-[34px] sm:px-5 rounded-[15px] w-[95%] md:w-full"> */}


{/* <div className="flex flex-1 flex-col font-inter gap-[35px] items-center justify-start md:px-5 w-full">
            
            <div className="bg-white-A700 flex flex-col items-center justify-start py-5 rounded-[15px] w-[95%] md:w-full"> */}
              <div className="flex flex-col items-center justify-start mb-[15px] w-full">
                <div className="flex flex-col items-center justify-start pb-[5px] pr-[5px] w-[95%] md:w-full">
                  <div className="flex flex-row md:gap-10 items-center justify-between mb-[77px] w-full">
                    <div className="flex flex-col gap-[9px] items-center justify-start w-[6%]">
                      <Text
                        className="text-blue-A200 text-lg"
                        size="txtInterSemiBold18"
                      >
                        All 37
                      </Text>
                      <Line className="bg-blue-A200 h-1 w-full" />
                    </div>
                    <div className="flex flex-col font-poppins items-center justify-start w-[9%]">
                      <div className="bg-white-A700 border border-gray-700 border-solid flex flex-row gap-4 items-center justify-start px-3 py-2.5 rounded w-auto">
                        <Img
                          className="h-6 w-6"
                          src="images/img_materialsymbol_gray_700.svg"
                          alt="materialsymbol_One"
                        />
                        <Text
                          className="text-gray-700 text-xl w-auto"
                          size="txtPoppinsMedium20"
                        >
                          Filters
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col md:gap-10 gap-[132px] items-center justify-start w-full">
                  <div className="overflow-auto w-full">
                    <InterviewSchedulingTable
                      columns={tableColumns}
                      data={tableData.current}
                      rowClass={""}
                      headerClass=""
                    />
                  </div>
                  <div className="flex sm:flex-col flex-row font-poppins md:gap-10 items-start justify-between w-[93%] md:w-full">
                    <div className="flex flex-col items-center justify-start">
                      <Text
                        className="text-black-900 text-lg"
                        size="txtPoppinsMedium18"
                      >
                        <span className="text-blue_gray-900 font-poppins text-left font-medium">
                          Showing 1-8{" "}
                        </span>
                        <span className="text-gray-500 font-poppins text-left font-medium">
                          of 35
                        </span>
                      </Text>
                    </div>
                    <div className="flex flex-row font-inter items-center justify-between sm:mt-0 mt-0.5 w-[23%] sm:w-full">
                      <div className="flex flex-row items-start justify-evenly">
                        <Img
                          className="h-6 w-6"
                          src="images/img_arrowleft.svg"
                          alt="arrowleft"
                        />
                        <Text
                          className="text-[15px] text-indigo-600"
                          size="txtInterMedium15"
                        >
                          Previous
                        </Text>
                      </div>
                      <Button className="cursor-pointer font-medium h-10 leading-[normal] text-[15px] text-center w-10">
                        1
                      </Button>
                      <Text
                        className="text-[15px] text-indigo-600"
                        size="txtInterMedium15"
                      >
                        2
                      </Text>
                      <Text
                        className="text-[15px] text-indigo-600"
                        size="txtInterMedium15"
                      >
                        3
                      </Text>
                      <Text
                        className="text-[15px] text-indigo-600"
                        size="txtInterMedium15"
                      >
                        4
                      </Text>
                      <div className="flex flex-row items-center justify-evenly">
                        <Text
                          className="text-[15px] text-indigo-600"
                          size="txtInterMedium15"
                        >
                          Next
                        </Text>
                        <Img
                          className="h-6 w-6"
                          src="images/img_arrowright.svg"
                          alt="arrowright"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


                 </div>
                </div>
              </div>
            {/* </div>
          </div> */}
        </>
  );
};

export default UniversityInterviewingScheduling;
