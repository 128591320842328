// reducers/profileGetlinksReducer.js

const initialState = {
    profileGetlinksData: {}, // Initialize as an empty object
    isLoadingProfile: false,
    error: null,
  };
  
  const profileGetlinksReducer= (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_PROFILE_GETLINKS_REQUEST":
        return {
          ...state,
          isLoadingProfile: true,
        };
      case "FETCH_PROFILE_GETLINKS_SUCCESS":
        return {
          ...state,
          isLoadingProfile: false,
          profileGetlinksData: action.payload, // Set the entire payload object
        };
      case "FETCH_PROFILE_GETLINKS_FAILURE":
        return {
          ...state,
          isLoadingProfile: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };

    export default profileGetlinksReducer;

  