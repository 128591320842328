import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Button, Text } from "components";
import countryStates from 'components/CountriesStates/index.jsx';
import { InputField, SelectField, } from 'components/InputField/index.jsx';
import MainLoader from "components/Loader";
import { ProfileHeader } from "pages/JobSeekerDashBoardPages/ProfileHeader";
import { SideBar1 } from "pages/JobSeekerDashBoardPages/SideBar1";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PaymentMethods from "./paymentMethods";
import {useSelector} from "react-redux"
const JobseekersCheckout = () => {
  const [loader, setLoader] = useState(false)
  const [moreOffers, setMoreOffers] = useState(false)
  const [paymentPage, setPaymnetpage] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [fetchStatus, setFetchStatus] = useState(false);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [validCode, setValidCode] = useState(null);
  const [expiredCode, setExpiredCode] = useState(null);
  const [trialUserCode, setTrialUserCode] = useState(false);
  const [codeStatus, setCodeStatus] = useState(null);
  const navigate = useNavigate();


  const { personalData, isLoading } = useSelector(
    (state) => state.profilePersonalData,
  );
  const user_id = personalData.id;
  const subscriptionType = window.location.search.split("=")[1];

  const handlePaymentMethodSelect = (method) => {
    setSelectedPaymentMethod(method);
  };

  const handleApplyCouponCode = async (couponCode) => {
    console.log(couponCode)
    setLoader(true)
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/check-coupon-code`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({ couponCode, user_id }),
      },
    );
    let data = await response.json();
    //console.log(data);
    setLoader(false)
    if (data.isTrialUser) {
      setCodeStatus(false);
      setTrialUserCode(true);
      setDiscountPercent(100);
    } else {
      setCodeStatus(true);
      if (data.isValid) {
        setValidCode(true);
        if (data.isExpired) {
          setExpiredCode(true);
          setDiscountPercent(0);
        } else {
          setDiscountPercent(data.discountPercent);
        }
      } else {
        setValidCode(false);
        setDiscountPercent(0);
      }
    }
  };

  const handleTrialUser = async () => {
    setFetchStatus(true);
    try {
      let response = await fetch(
        `${process.env.REACT_APP_API_URL}/start-trial`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({ user_id }),
        },
      );
      if (!response.ok) {
        throw new Error("Failed to update subscription");
      }
      navigate("/jobseeker-dashboard");
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setFetchStatus(false);
    }
  };


  const handleCheckout = (subscriptionType) => {
    setFetchStatus(true);
    let data = {
      user_id,
      subscriptionType,
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/create-checkout-session-jobSeekers`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data),
      },
    )
      .then((response) => {
        //console.log(response);
        if (!response.ok) {
          throw new Error("Error creating checkout session");
        }
        setFetchStatus(false);
        return response.json();
      })
      .then((data) => {
        //console.log(data);
        window.location.href = data.url;
      })
      .catch((error) => {
        console.error("Error:", error);
        setFetchStatus(false);
      });
  };

  const handleBuyButtonClick = async (subscriptionType) => {
    if (!selectedPaymentMethod) {
      alert("Please select a payment method.");
      return;
    }

    // Setting fetch status to true before initiating the fetch
    setFetchStatus(true);
    let data = {
      user_id,
      subscriptionType,
    };
    if (validCode) {
      data = {
        ...data,
        discountPercent,
      };
    }
    try {
      // Making the POST request to create a checkout session
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/create-checkout-session-jobSeekersRazorpay`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        },
      );

      if (!response.ok) {
        throw new Error("Error creating checkout session");
      }

      const responseData = await response.json();

      // Configuring options for Razorpay payment
      const options = {
        key: process.env.REACT_APP_RAZORPAY_API_KEY,
        amount: responseData.amount,
        currency: responseData.currency,
        order_id: responseData.id,
        name: "Recroid",
        image: "//recroid.png",
        handler: async function (response) {
          const body = {
            user_id: user_id,
            subscriptionType: subscriptionType,
            currency: responseData.currency,
            amount: responseData.amount,
            ...response,
          };
          const validateRes = await fetch(
            `${process.env.REACT_APP_API_URL}/verify-payment-jobSeekers`,
            {
              method: "POST",
              body: JSON.stringify(body),
              headers: {
                "Content-Type": "application/json",
              },
            },
          );
          const jsonRes = await validateRes.json();
          window.location.href = "/billing-overview";
        },
        prefill: {
          method: selectedPaymentMethod,
          name: responseData.customer_name,
          email: responseData.customer_email,
          // contact: String(responseData.customer_phone),
        },
        description: user_id,
        theme: {
          color: "#3399cc",
        },
        modal: {
          ondismiss: function () {
            alert("Payment failed");
          },
        },
      };

      //console.log(options);

      // Initiating the payment using Razorpay
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // Setting fetch status to false after completing the fetch
      setFetchStatus(false);
    }
  };
  const handleProceed = async () => {
    setPaymnetpage(true);

  };

  const handleRemoveCoupon = () => {
    setDiscountPercent(0);
    setCouponCode("");
    setValidCode(null);
    setExpiredCode(null);
    setTrialUserCode(false);
    setCodeStatus(null);
    setMoreOffers(false)
  }



  const [billingAddress, setBillingAddress] = useState({
    billingAddress1: "",
    billingAddress2: "",
    billingCompanyName: "",
    billingCountry: "India",
    billingEmail: "",
    billingPincode: "",
    phoneNumber: "",
    state: ""
  });
  const [editMode, setEditMode] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [hasBillingData, setHasBillingData] = useState(false); // Track if billing data exists

  useEffect(() => {
    if (personalData) {
      setBillingAddressFromPersonalData(personalData);
      fetchBillingPreference(personalData.id);
    }
  }, [personalData]);
  
  const setBillingAddressFromPersonalData = (personalData) => {
    setBillingAddress((prev) => ({
      ...prev,
      billingCompanyName: `${personalData.first_name} ${personalData.last_name}`.trim() || "",
      billingCountry: personalData.country || "",
      billingEmail: personalData.email_address || "",
      phoneNumber: personalData.phone_number || "",
      state: personalData.state || ""
    }));
    setEditMode(true);
    setHasBillingData(true);
  };

  const fetchBillingPreference = async (user_id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/billing-preference`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_id }),
      });
      if (response.ok) {
        const data = await response.json();
        if (data && data.length > 0) {
          setBillingAddress((prev) => ({
            ...prev,
            billingAddress1: data[0].billingAddress1 || "",
            billingAddress2: data[0].billingAddress2 || "",
            billingPincode: data[0].billingPincode || ""
          }));
          setEditMode(false);
          setHasBillingData(true); // Data exists
        } else {
          setEditMode(true); // Show form in edit mode if no data found
          setHasBillingData(false); // No data exists
        }
      } else {
        console.error("Failed to fetch billing address");
      }
    } catch (error) {
      console.error("Error fetching billing address:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBillingAddress({ ...billingAddress, [name]: value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      try {
        const url = `${process.env.REACT_APP_API_URL}/${hasBillingData ? 'update-billing-preference' : 'save-billing-preference'}`;
        const method = hasBillingData ? "PUT" : "POST";
        const response = await fetch(url, {
          method: method,
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ user_id: personalData.id, ...billingAddress }),
        });
        if (response.ok) {
          console.log("Billing address saved successfully");
          setEditMode(false);
        } else {
          console.error("Failed to save billing address");
        }
      } catch (error) {
        console.error("Error saving billing address:", error);
      }
    } else {
      setFormErrors(errors);
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!billingAddress.billingCompanyName.trim()) {
      errors.billingCompanyName = "Billing Company Name is required";
    }
    if (!billingAddress.billingAddress1.trim()) {
      errors.billingAddress1 = "Billing Address 1 is required";
    }
    // Add more validations as needed
    return errors;
  };

  
  const shouldShowEditForm = () => {
    // Create a new object excluding billingAddress2
    const { billingAddress2, ...rest } = billingAddress;
    // Check if any field in the remaining billingAddress is empty
    return Object.values(rest).some(value => !value);
  };
  const statesList = countryStates.find(country => country.country === billingAddress.countries)?.states || [];


  return (
    <>
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start ">
        <div className="flex md:flex-col flex-row gap-2 items-start justify-between w-full">
          <SideBar1 clickedTab={"Billing"} />
          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
            <ProfileHeader clickedTab={"Billing"} />
            <div className="h-[85vh] overflow-scroll bg-white-A700 flex flex-col items-center justify-start p-7 sm:px-5 rounded-[15px] w-[94%] md:w-full">
              <div className="flex flex-col gap-[20px] justify-start mt-2.5 w-[99%] md:w-full">
                <div className="flex flex-col items-start px-5">
                  <div className="flex gap-5 text-2xl font-medium text-neutral-800">
                    <Link to="/billing-overview">
                      <KeyboardBackspaceIcon />
                    </Link>
                    <div>For Job Seekers</div>
                  </div>
                  <div className="flex flex-col w-full mt-[50px] "></div>
                  <div className="flex shadow-2xl flex-col self-stretch mt-14 w-full max-md:pl-5 max-md:mt-10 max-md:max-w-full">
                    <div className="flex">
                      {loader && <MainLoader />}
                      {paymentPage ? (
                        <PaymentMethods paymentPage={paymentPage} />
                      ) : (
                        <div className="shadow-2xl m-[40px]  p-[30px]  w-full flex justify-start h-auto items-center">
                        <div className="w-[100%] h-[100%] flex flex-col items-center ">
                          <div className="font-semibold flex  justify-start mt-[40px] w-[90%] text-xl  text-gray-900 max-md:ml-2.5">
                            <Text>Plan Details</Text>
                          </div>
                          <div
                            style={{ color: "#414c5f" }}
                            className="flex  justify-between w-[90%] text-2xl mt-[30px] font-medium max-md:ml-2.5"
                          >
                            <Text className="text-lg ">
                              Selected plan ({subscriptionType} subscription)
                            </Text>
                            <div className="flex gap-[10px]">
                              <Text
                                style={{ color: "#7c8ba2" }}
                                className="text-lg font-bold line-through"
                              >
                                {subscriptionType === "yearly" ? "9588" : ""}
                              </Text>
                              <Text className="text-lg text-gray-900 font-bold ">
                                {subscriptionType === "yearly"
                                  ? "₹ 7655.00*"
                                  : "₹ 799.00*"}
                              </Text>
                            </div>
                          </div>

                          <div className="ml-[80px] flex flex-col mt-[40px] w-full self-center w-[90%]">
                            <div className="text-2xl font-medium  ml-2">
                              Benefits
                            </div>
                            <ul
                              className="mt-6  text-md text-black max-md:mt-4 max-md:max-w-full"
                              style={{
                                listStyleType: "disc",
                                paddingLeft: "1.5em",
                              }}
                            >
                              <li>Comprehensive Resume Building</li>
                              <li>Cover Letter Creation</li>
                              <li>Curated Job Listings</li>
                              <li>Automated Job Applications</li>
                              <li>Interview Preparation</li>
                              <li>Career Advice</li>
                          
                            </ul>
                          </div>
                          <div className="flex justify-start items-center  w-[90%] mt-[100px]">
                            <Link to="/billing-overview">
                              <Text className="text-indigo-600 text-lg font-semibold">
                                Change Plan
                              </Text>
                            </Link>
                            {/* <Text   className="text-indigo-600 text-2xl font-semibold ml-[80px]">Cancel Plan</Text> */}
                          </div>
                        </div>
                      </div>
                      )}

                      <div className="w-full flex  flex-col justify-start items-center mb-[50px]">
                        <div className="font-semibold flex justify-center mb-[40px] mt-[40px] w-[90%] text-xl text-gray-900 max-md:ml-2.5">
                          <Text>Price Details</Text>
                        </div>

                        <div className="shadow-2xl w-[60%] h-auto flex flex-col items-center">

                          <div
                            style={{ color: "#414c5f" }}
                            className="text-lg flex justify-between w-[90%] mt-[30px] font-medium max-md:ml-2.5"
                          >
                            <Text>Total</Text>
                            <Text>
                              ₹ {subscriptionType === "yearly" ? (799.00 * 12).toFixed(2) : 799.00.toFixed(2)}
                            </Text>
                          </div>

                          {subscriptionType === "yearly" && (
                            <div

                              className="text-[#008000] text-lg flex justify-between w-[90%] mt-[30px] font-medium max-md:ml-2.5"
                            >
                              <Text>Discount (20% OFF)</Text>
                              <Text>
                                -₹ {(799.00 * 12 - 7655.00).toFixed(2)} {/* Calculated discount amount */}
                              </Text>
                            </div>
                          )}


                          {trialUserCode ? <div
                            className="text-[#008000]  flex justify-between border-b-4 border-gray-500 h-[60px] w-[90%] text-lg mt-[30px] font-medium max-md:ml-2.5"
                          >
                            <Text>Coupon Discount</Text>
                            <Text>
                              - ₹ {subscriptionType === "yearly" ? (7655.00).toFixed(2) : 799.00.toFixed(2)}
                            </Text>

                          </div> : <div
                            className="text-[#314ca3] flex justify-between border-b-4 border-gray-500  w-[90%] text-lg mt-[30px] font-medium max-md:ml-2.5"
                          >

                          </div>}

                          <div className="font-bold flex justify-between mb-[40px] mt-[40px] w-[90%] text-lg font-medium text-gray-900 max-md:ml-2.5">
                            <Text>Total Payable Amount</Text>
                            <Text>
                              ₹ {trialUserCode ? 0.00 : (subscriptionType === "yearly" ? (7655.00 * (1 - discountPercent / 100)).toFixed(2) : (799.00 * (1 - discountPercent / 100)).toFixed(2))}
                            </Text>
                          </div>
                        </div>


                        <div className="w-[60%] mt-[50px] max-md:w-full">
                          {
                            !paymentPage  &&
                            <Button className="w-full  h-[60px]  font-semibold" onClick={handleProceed}                              >
                              Proceed
                            </Button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {fetchStatus && <MainLoader />}
    </>
  );
};

export default JobseekersCheckout;