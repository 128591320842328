import { Dialog } from "@mui/material";
import { Tooltip } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import { getProfileUploadLinks } from "actions/ProfileUploadLinks";
import { Button, Img, Line, Switch, Text } from "components";
import FallbackTemplate from "components/CoverletterTemplates/FallbackTemplate";
import MainLoader from "components/Loader";
import { jsPDF } from "jspdf";
import getData from "pages/JobSeekerDashBoardPages/CoveLetterDetails/getData";
import { ProfileHeader } from "pages/JobSeekerDashBoardPages/ProfileHeader";
import { SideBar1 } from "pages/JobSeekerDashBoardPages/SideBar1";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import BeatLoader from "react-spinners/ClipLoader";
import { TextArea } from "semantic-ui-react";
import "./index.css";
const ResumeDetails = () => {
  const [ResumeTemplateName, setResumeTemplateName] = useState("");
  const [ResumeTemplateToRender, setResumeTemplateToRender] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [awards, setAwards] = useState([]);
  const [courses, setCourses] = useState([]);
  const [projects, setProjects] = useState([]);
  const [publications, setPublications] = useState([]);
  const [workExperience, setWorkExperience] = useState([]);
  const [education, setEducation] = useState([]);
  const [technicalSkills, setTechnicalSkills] = useState([]);
  const [interests, setInterests] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [declarations, setDeclarations] = useState([]);
  const [references, setReferences] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [links, setLinks] = useState([]);

  const [interpersonalSkills, setInterpersonalSkills] = useState([]);
  const [resumeObjective, setResumeObjective] = useState([]);

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [dob, setDateOfBirth] = useState("");
  const [current_role, setCurrentRole] = useState("");
  const [email_address, setEmailAddress] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [countries, setSelectedCountry] = useState("India");
  const [state, setSelectedState] = useState("");
  const [formError, setFormError] = useState(false);
  const [dataFetch, setDataFetch] = useState(false);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const imageId = queryParams.get("imageId");

  const [resumeObjText, setResumeObjText] = useState("");
  // const [finalObjective, setFinalObjective] = useState("");
  const [objSaved, setObjStatus] = useState(false);
  const dispatch = useDispatch();
  const { personalData } = useSelector((state) => state.profilePersonalData);
  // const validateForm = () => {
  //   if (
  //     !first_name ||
  //     !last_name ||
  //     !dob ||
  //     !current_role ||
  //     !email_address ||
  //     !phone_number ||
  //     !countries ||
  //     !state
  //   ) {
  //     setFormError(true);
  //     return false;
  //   }
  //   setFormError(false);
  //   return true;
  // };

  const userData = JSON.parse(
    localStorage.getItem(
      `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`
    )
  );
  const user_id = userData.user.id;
  useEffect(() => {
    const loadComponent = async () => {
      try {
        const importedComponent = await import(
          `pages/JobSeekerDashBoardPages/ResumeTemplates/template${imageId}`
        );
        setResumeTemplateToRender(() => importedComponent.default);
        setResumeTemplateName(imageId);
        // //console.log(`pages/CoverLetterTemplates/template${imageId}`)
      } catch (error) {
        console.error("Error importing component:", error);
        setResumeTemplateToRender(null);
      }
    };

    if (ResumeTemplateName === "") {
      loadComponent();
    }

    //   const fetchData = async () => {
    //     try {
    //       const apiUrl = `${process.env.REACT_APP_API_URL}/get_personal_data`;
    //       const requestOptions = {
    //         method: "POST",
    //         headers: {
    //           Accept: "application/json",
    //           "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({ user_id: user_id }),
    //       };

    //       const userResponse = await fetch(apiUrl, requestOptions);

    //       if (userResponse.ok) {
    //         const userData = await userResponse.json();

    //         const userDetails = userData.data[1][0];
    //         setDataFetch(true);
    //         if (userDetails) {
    //           setFirstName(userDetails.first_name || "");
    //           setLastName(userDetails.last_name || "");
    //           setDateOfBirth(userDetails.date_of_birth || "");
    //           setCurrentRole(userDetails.current_role_ || "");
    //           setEmailAddress(userDetails.email_address || "");
    //           setPhoneNumber(userDetails.phone_number || "");
    //           setSelectedCountry(userDetails.country || "India");
    //           setSelectedState(userDetails.state || "");
    //           setFormError(false);
    //         } else {
    //           console.error("Unexpected data structure in API response");
    //           setFormError(true);
    //         }
    //       }
    //     } catch (error) {
    //       console.error("Error fetching user data:", error);
    //       setFormError(true);
    //     }
    //   };

    //   fetchData();
  }, [user_id, ResumeTemplateName, imageId]);

  useEffect(() => {
    if (personalData) {
      setDataFetch(true);
      setFirstName(personalData.first_name || "");
      setLastName(personalData.last_name || "");
      setDateOfBirth(personalData.date_of_birth || "");
      setCurrentRole(personalData.current_role_ || "");
      setEmailAddress(personalData.email_address || "");
      setPhoneNumber(personalData.phone_number || "");
      setSelectedCountry(personalData.country || "India");
      setSelectedState(personalData.state || "");
      setFormError(false);
    }
  }, [personalData]);

  // const personalInfo = {
  //   firstName: first_name,
  //   lastName: last_name,
  //   dateOfBirth: dob,
  //   currentRole: current_role,
  //   emailAddress: email_address,
  //   phoneNumber: phone_number,
  //   country: countries,
  //   state: state,
  // };
  const updateStoredResume = async (pdfFile) => {
    try {
      var storedData = localStorage.getItem(
        `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`
      );
      var parsedData = JSON.parse(storedData);
      var userId = parsedData.user.id;

      const formData = new FormData();
      formData.append("resume_file", pdfFile);

      const apiUrl = `${process.env.REACT_APP_API_URL}/resume_parser_via_upload?user_id=${userId}`;
      const requestOptions = {
        method: "POST",
        body: formData,
      };

      const res = await fetch(apiUrl, requestOptions);

      if (!res.ok) {
        const errorText = await res.text();
        console.error("Error fetching data:", res.status, errorText);
        // Handle the error or throw an exception as needed
      } else {
        // const data = await res.json();
        //console.log("Data from resume parser", data);
        dispatch(getProfileUploadLinks(userId));
      }
    } catch (error) {
      console.error("Error updating stored resume:", error);
    }
  };

  const [resumeObject, setResumeObject] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      // setIsLoading(true);
      try {
        const data = await getData();
        // Updated the way data is accessed due to changes in getData.js output structure

        // Filter out empty work experiences
        setWorkExperience((data.work_experience || []).filter(exp => exp.position && exp.company));

        // Filter out empty education entries
        setEducation((data.education || []).filter(ed => ed.degree && ed.institution));
    
        // Filter out empty projects
        setProjects((data.projects || []).filter(proj => proj.title && proj.description));
    

        setAwards(data.awards || []);
        setCourses(data.courses || []);
        setPublications(data.publications || []);
        setTechnicalSkills(data.technical_skills || []);
        setCertificates(data.certificates || []);
        setDeclarations(data.declarations || []);
        setReferences(data.references || []);
        setInterests(data.interests || []);
        setLanguages(data.languages || []);

        setLinks(Object.values(data.links || []));
        //console.log(123, data.links || []);
        setInterpersonalSkills(data.interpersonal_skills || []);
        setResumeObjective(data.resumeObjective[0] || []);
        setResumeObjText(data.resumeObjective[0].resume_objective || []);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false); // Clear loading state even on error
      }
    };

    fetchData();
  }, [resumeObject]);

  const [iscareerObjectEdit, setcareerObjectState] = useState(true);

  const saveObjective = async () => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: user_id,
          resume_objective: aiContent ? ObjectiveResponse : resumeObjText,
        }),
      };
      const apiUrl = `${process.env.REACT_APP_API_URL}/save_resume_objective`;
      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();
      const objective = data.data[1][0].resume_objective;
      //console.log(objective);
      setResumeObjective(objective);
      setResumeObject(objective);
      if (!response.ok) {
        throw new Error("Failed to save resume objective");
      }
    } catch (error) {
      console.error("Error saving resume objective:", error);
    }
  };

  // ------------------------------------------------------------------------

  const [projectDetails, setprojectDetails] = useState(false);
  const [projectstatus, setProjectStatus] = useState(false);
  const [intereststatus, setInterestStatus] = useState(false);
  const [linkstatus, setLinkStatus] = useState(false);
  const [languagestatus, setLanguageStatus] = useState(false);
  const [organisationstatus, setOrganisationStatus] = useState(false);
  const [publicationstatus, setPublicationStatus] = useState(false);
  const [coursestatus, setCourseStatus] = useState(false);
  const [certificatestatus, setCertificateStatus] = useState(false);
  const [referencestatus, setReferenceStatus] = useState(false);
  const [declarationstatus, setDeclarationStatus] = useState(false);
  const [awardstatus, setAwardStatus] = useState(false);

  const [workDetails, setWorkDetails] = useState(false);
  const workExperienceArrow = () => {
    setWorkDetails(!workDetails);
    setWorkAllDetails(true);
  };

  const [educationDetails, seteducationDetails] = useState(false);
  const educationArrow = () => {
    seteducationDetails(!educationDetails);
  };
  const [skillsDetails, setskillsDetails] = useState(false);
  const skillsArrow = () => {
    setskillsDetails(!skillsDetails);
  };

  const [interestDetails, setinterestDetails] = useState(false);
  const intrestArrow = () => {
    setinterestDetails(!interestDetails);
  };
  const handleInterests = () => {
    if (!intereststatus) {
      setInterestStatus(true);
    } else {
      setInterestStatus(false);
    }
  };

  const [linkDetails, setlinkDetails] = useState(false);
  const linkArrow = () => {
    setlinkDetails(!linkDetails);
  };
  const handleLinks = () => {
    if (!linkstatus) {
      setLinkStatus(true);
    } else {
      setLinkStatus(false);
    }
  };

  const [launguageDetails, setlaunguageDetails] = useState(false);
  const launguageArrow = () => {
    setlaunguageDetails(!launguageDetails);
  };
  const handleLanguages = () => {
    if (!languagestatus) {
      setLanguageStatus(true);
    } else {
      setLanguageStatus(false);
    }
  };

  const [certificateDetails, setcertificateDetails] = useState(false);
  const certificateArrow = () => {
    setcertificateDetails(!certificateDetails);
  };

  const handleCertificates = () => {
    if (!certificatestatus) {
      setCertificateStatus(true);
    } else {
      setCertificateStatus(false);
    }
  };

  const projectArrow = () => {
    setprojectDetails(!projectDetails);
  };

  const handleProjects = () => {
    if (!projectstatus) {
      setProjectStatus(true);
    } else {
      setProjectStatus(false);
    }
  };

  const [courseDetails, setcourseDetails] = useState(false);
  const courseArrow = () => {
    setcourseDetails(!courseDetails);
  };

  const handleCourses = () => {
    if (!coursestatus) {
      setCourseStatus(true);
    } else {
      setCourseStatus(false);
    }
  };

  const [awardsDetails, setawardsDetails] = useState(false);
  const awardsArrow = () => {
    setawardsDetails(!awardsDetails);
  };
  const handleAwards = () => {
    if (!awardstatus) {
      setAwardStatus(true);
    } else {
      setAwardStatus(false);
    }
  };

  const [organisationDetails, setorganisationDetails] = useState(false);
  const organisationArrow = () => {
    setorganisationDetails(!organisationDetails);
  };

  const handleOrganisations = () => {
    if (!organisationstatus) {
      setOrganisationStatus(true);
    } else {
      setOrganisationStatus(false);
    }
  };

  const [publicationDetails, setpublicationDetails] = useState(false);
  const publicationArrow = () => {
    setpublicationDetails(!publicationDetails);
  };

  const handlePublications = () => {
    if (!publicationstatus) {
      setPublicationStatus(true);
    } else {
      setPublicationStatus(false);
    }
  };

  const [referenceDetails, setreferenceDetails] = useState(false);
  const referenceArrow = () => {
    setreferenceDetails(!referenceDetails);
  };

  const handleReferences = () => {
    if (!referencestatus) {
      setReferenceStatus(true);
    } else {
      setReferenceStatus(false);
    }
  };

  const [declarationDetails, setdeclarationDetails] = useState(false);
  const declarationArrow = () => {
    setdeclarationDetails(!declarationDetails);
  };

  // const handleDeclarations = () => {
  //   if (!declarationstatus) {
  //     setDeclarationStatus(true);
  //   } else {
  //     setDeclarationStatus(false);
  //   }
  // };
  // ------------------------------------------------------------------------/
  // if (localStorage.getItem("imgUrl") === null) {
  //   localStorage.setItem("imgUrl", "images/img_ellipse28.png");
  // }
  // const [imgUrl, setImgUrl] = useState(
  //   localStorage.getItem("imgUrl") || "images/img_ellipse28.png",
  // );

  const [objective, setObjective] = useState(false);

  const ObjectiveClick = () => {
    setObjective(!objective);
  };

  const [workAllDetails, setWorkAllDetails] = useState(false);
  // const workAll = () => {
  //   setWorkAllDetails(!workAllDetails);
  // };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [tipsopen, settipsOpen] = useState(false);

  const tipshandleOpen = () => {
    settipsOpen(true);
  };
  const tipshandleClose = () => {
    settipsOpen(false);
  };
  // const [loaded, setLoaded] = useState(false);
  const [ObjectiveResponse, setObjResponse] = useState("");
  // const [response, setResponse] = useState(null);
  const [aiContent, setAiContent] = useState(false);
  // const [content, setContent] = useState("unclicked");
  const [loading, setLoading] = useState(false); // New loading state

  const organizations = workExperience.map((experience) => experience.company);

  const sample_data = {
    name: first_name + " " + last_name,
    job_title: current_role,
    email: email_address,
    link: links,
    phone: phone_number,
    personal_profile: "",
    address: state + ", " + countries,
    key_skills: technicalSkills,
    awards: awards,
    declarations: declarations,
    references: references,
    publications: publications,
    certificates: certificates,
    interest: interests,
    courses: courses,
    project: projects,
    language: languages,
    work_experience: workExperience,
    education: education,
    organizations: organizations,
    interpersonal_skills: interpersonalSkills,
    resume_objective: resumeObjective,
  };

  const [resumeBody, setResumeBody] = useState(sample_data);

  const objectiveCreate = async () => {
    const newAiContent = !aiContent;
    setAiContent(newAiContent);

    if (newAiContent) {
      // Only make the API call if aiContent is being set to true
      // Set loading to true before the API call
      setLoading(true);

      // Create a copy of sample_data with an empty resume_objective
      const modifiedSampleData = {
        ...sample_data,
        resume_objective: "",
      };

      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/get_response`;
        const requestOptions = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            prompt: `I am providing you my resume below :-${JSON.stringify(
              modifiedSampleData
            )}Please write a professional Resume Objective for me, which reflects my work mentioned in my resume and emphasizes on my passion for my skills. Please don't add any extra information. `,
          }),
        };

        const res = await fetch(apiUrl, requestOptions);
        const data = await res.json();

        setObjResponse(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        // Set loading to false after the API call completes
        setLoading(false);
      }
    }
  };

  const careerObjectEdit = () => {
    setObjStatus(!objSaved);
    setcareerObjectState(!iscareerObjectEdit);
  };
  useEffect(() => {
    const updateResumeBody = () => {
      const updatedData = { ...sample_data };

      if (!projectstatus) {
        updatedData.project = "";
      } else {
        updatedData.project = projects;
      }

      if (!intereststatus) {
        updatedData.interest = "";
      } else {
        updatedData.interest = interests;
      }
      if (!linkstatus) {
        updatedData.link = "";
      } else {
        updatedData.link = links;
      }

      if (!certificatestatus) {
        updatedData.certificates = "";
      } else {
        updatedData.certificates = certificates;
      }

      if (!awardstatus) {
        updatedData.awards = "";
      } else {
        updatedData.awards = awards;
      }

      if (!declarationstatus) {
        updatedData.declarations = "";
      } else {
        updatedData.declarations = declarations;
      }

      if (!referencestatus) {
        updatedData.references = "";
      } else {
        updatedData.references = references;
      }

      if (!publicationstatus) {
        updatedData.publications = "";
      } else {
        updatedData.publications = publications;
      }

      if (!coursestatus) {
        updatedData.courses = "";
      } else {
        updatedData.courses = courses;
      }

      if (!languagestatus) {
        updatedData.language = "";
      } else {
        updatedData.language = languages;
      }

      if (!organisationstatus) {
        updatedData.organizations = "";
      } else {
        updatedData.organizations = organizations;
      }

      if (JSON.stringify(updatedData) !== JSON.stringify(resumeBody)) {
        setResumeBody(updatedData);
      }
    };

    updateResumeBody();
  }, [
    projectstatus,
    resumeBody,
    sample_data,
    projects,
    intereststatus,
    interests,
    linkstatus,
    links,
    certificatestatus,
    certificates,
    awardstatus,
    awards,
    declarationstatus,
    declarations,
    referencestatus,
    references,
    publicationstatus,
    publications,
    coursestatus,
    courses,
    languagestatus,
    languages,
    organisationstatus,
    organizations,
  ]);

  const handleDownload = async () => {
    try {
      var storedData = localStorage.getItem(
        `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`
      );
      var parsedData = JSON.parse(storedData);
      var userId = parsedData.user.id;

      const apiUrl = `${process.env.REACT_APP_API_URL}/update_download_counter/resume`;
      const postOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: userId }),
      };
      fetch(apiUrl, postOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          // //console.log(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });

      const doc = new jsPDF({
        orientation: "p",
        unit: "in",
        format: [11.42, 8],
      });
      var elementHTML = document.querySelector("#resume");

      // Change the color of all content inside the element
      elementHTML.style.padding = "0";
      elementHTML.style.margin = "0";
      elementHTML.style.color = "black";

      const pdfBlob = await new Promise((resolve) => {
        doc.html(elementHTML, {
          callback: function (doc) {
            // Resolve with the Blob representing the PDF
            resolve(doc.output("blob"));
          },
          x: 0,
          y: 0,
          width: 8, //target width in the PDF document
          windowWidth: 590, //window width in CSS pixels
        });
      });
      // Save the PDF
      doc.save("Resume.pdf");

      // Create a File object from the Blob
      const pdfFile = new File([pdfBlob], "Resume.pdf", {
        type: "application/pdf",
      });

      // Trigger download

      setPdfFile(pdfFile);
      // Return the File object
      return pdfFile;
    } catch (e) {
      //console.log(e);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleBothActions = async () => {
    handleClickOpen();
    await handleDownload();
  };

  const handleYesOptionClick = async () => {
    try {
      handleClose(); // Close the dialog window
      if (pdfFile) {
        await updateStoredResume(pdfFile); // Call updateStoredResume to update the stored resume
      } else {
        console.error("No PDF file available to update stored resume");
      }
    } catch (error) {
      console.error("Error handling yes option click:", error);
    }
  };

  const LoadingIndicator = () => {
    return <MainLoader />;
  };

  return (
    <>
      {isLoading && <LoadingIndicator />}

      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <SideBar1 clickedTab={"Resume Builder"} />
          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
            <ProfileHeader clickedTab={"Resume Builder"} />

            <div className="bg-white-A700 flex flex-col items-center justify-start rounded-[15px] w-[94%] md:w-full">
              <div className="flex flex-col md:h-auto items-center justify-start max-w-[1078px] pl-[77px] pr-[82px] md:px-10 sm:px-5 py-[35px] mqlap:py-[31px] mqtab1:py-[20px] w-full">
                <div className="flex flex-row md:gap-10 gap-[80px] mqlap:gap-[65px] mqtab1:gap-[56px] items-start justify-start w-auto md:w-full">
                  <div className="sm:hidden  flex flex-row gap-5 items-center justify-start w-1/4 md:w-full">
                    <Text
                      size="txtInterSemiBold25"
                      className="sm:hidden bg2 flex h-[50px] items-center justify-center rounded-[50%] sm:text-[21px] md:text-[23px] text-[25px] text-center text-white-A700 tracking-[0.50px] w-[58px] mqlap:text-[21px] mqlap:h-[46px] mqlap:w-[49px] mqtab1:text-[18px] mqtab1:h-[40px] mqtab1:w-[41px]"
                    >
                      1
                    </Text>

                    <Text
                      className="sm:hidden  text2 text-blue-A200 text-xl tracking-[0.50px] mqtab1:text-[1.18vw] mqlap:text-[1.15vw] mqtab1:leading-[1.4rem] mqlap:leading-[1.5rem]"
                      size="txtInterMedium20"
                    >
                      Choose template
                    </Text>
                  </div>
                  <div className="flex flex-row gap-5 items-center justify-center w-1/4 sm:w-[100%] md:w-full">
                    <Text
                      size="txtInterSemiBold25"
                      className="bg2 flex sm:h-[30px] sm:w-[30px]   h-[50px] sm:text-[12px] items-center justify-center rounded-[50%] sm:text-[21px] md:text-[23px] text-[25px] text-center text-white-A700 tracking-[0.50px] w-[60px] mqlap:text-[21px] mqlap:h-[46px] mqlap:w-[50px] mqtab1:text-[18px] mqtab1:h-[40px] mqtab1:w-[43px]"
                    >
                      2
                    </Text>

                    <Text
                      className="text2 text-blue-A200 text-xl tracking-[0.50px] sm:text-[12px] mqtab1:text-[1.18vw] mqlap:text-[1.15vw] mqtab1:leading-[1.4rem] mqlap:leading-[1.5rem]   "
                      size="txtInterMedium20"
                    >
                      {" "}
                      Enter your details
                    </Text>
                  </div>
                  <div className="sm:hidden  flex flex-row gap-5 items-center justify-start w-1/4 md:w-full">
                    <Text
                      size="txtInterSemiBold25"
                      className="sm:hidden  bg2 flex h-[50px] items-center justify-center rounded-[50%] sm:text-[21px] md:text-[23px] text-[25px] text-center text-white-A700 tracking-[0.50px]  w-[80px] mqlap:text-[21px] mqlap:h-[46px] mqlap:w-[65px] mqtab1:text-[18px] mqtab1:h-[40px] mqtab1:w-[56px]"
                    >
                      3
                    </Text>
                    <Text
                      className="sm:hidden  text2 text-blue_gray-100 text-xl tracking-[0.50px] mqtab1:text-[1.18vw] mqlap:text-[1.15vw] mqtab1:leading-[1.4rem] mqlap:leading-[1.5rem]  "
                      size="txtInterMedium20Bluegray100"
                    >
                      Download your Resume
                    </Text>
                  </div>
                </div>
              </div>
            </div>
            <div className=" w-[94%] flex sm:flex-col flex-row gap-[37px]  h-[80vh] sm:h-auto mqtab2:flex mqmob1:flex mqtab2:flex-col mqmob1:flex-col mqtab2:w-[100%] mqmob1:w-[100%] mqtab2:h-auto mqmob1:h-auto mqtab1:gap-[20px] mqlap:gap-[30px]">
              <div className="overflow-scroll sm:w-[100%] flex flex-col gap-[37px] h-[80vh] sm:h-auto  sm:overflow-visible detail-left mqtab2:w-[100%] mqmob1:w-[46%] mqtab2:h-auto mqmob1:h-auto">
                <div
                  id="content"
                  className="bg-white-A700 flex flex-col items-start justify-start mt-[10px] rounded-[15px] sm:w-[100%]  shadow-bs w-[94%]"
                >
                  <div className="flex flex-col items-start justify-start  w-full ">
                    <div className="flex flex-col items-center justify-start w-full">
                      <div className="flex flex-col gap-5 items-center justify-start w-full p-4 pt-3.5 pb-4.5  mqtab1:pb-3.5">
                        <div className="flex  flex-row md:gap-5 items-center justify-between w-full md:w-full">
                          <div className=" flex flex-row gap-[18px] sm:ml-[20px] items-center justify-center md:mt-0 mt-[7px] w-auto ml-[33px] ">
                            <Img
                              className="h-[25px] md:h-auto object-cover w-[27px] sm:w-[15px]  sm:h-[15px]"
                              src="images/img_maleuser.png"
                              alt="maleuser"
                            />
                            <Text
                              className="text-black-900 text-lg w-auto sm:text-[12px] mqlap:text-[1.05vw] mqtab1:text-[1.1vw] "
                              size="txtInterSemiBold18"
                            >
                              Resume objective
                            </Text>
                          </div>
                          {objective === true ? (
                            <div className="flex flex-row  items-center justify-around gap-5">
                              <div className="font-medium leading-[normal] text-left text-lg sm:text-[12px] mqlap:text-[1.05vw] mqtab1:text-[1.1vw]">
                                AI
                              </div>
                              <div>
                                <div className="sm:w-[50px] sm:h-[25px] checkbox-wrapper-22 ">
                                  <label
                                    className="switch sm:w-[50px] sm:h-[25px] "
                                    htmlForor="checkbox"
                                  >
                                    <input
                                      onClick={objectiveCreate}
                                      type="checkbox"
                                      //checked={stateToggle}
                                      id="checkbox"
                                    />
                                    <div className="slider round"></div>
                                  </label>
                                </div>
                              </div>
                              <Img
                                className="h-9 mqtab1:h-[1.7rem]  mqlap:h-[1.9rem] md:ml-[0] sm:w-[15px]  sm:h-[15px]"
                                src="images/img_edit.svg"
                                alt="edit"
                                onClick={careerObjectEdit}
                              />
                              <img
                                className="icon-park-outline  sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                                onClick={ObjectiveClick}
                                alt="Icon park outline"
                                src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-1.svg"
                              />
                            </div>
                          ) : (
                            <img
                              className="icon-park-outline  sm:w-[15px] sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                              onClick={ObjectiveClick}
                              alt="Icon park outline"
                              src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-2.svg "
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <Line className="bg-blue_gray-100  w-full" />

                    {objective && (
                      <div className="w-full flex flex-col items-center justify-center mt-[17px]">
                        <div
                          className="flex flex-col items-start justify-start  w-[95%]"
                          style={{ height: "fit-content" }}
                        >
                          <Text
                            className="text-[18px] text-blue_gray-900_01 pb-3 sm:text-[12px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]"
                            size="txtInterRegular16Bluegray90001"
                          >
                            Description
                          </Text>
                        </div>
                        <div className="border border-blue_gray-100 border-solid flex flex-col gap-[13px] items-start justify-start rounded-[12px] w-[95%] md:w-full">
                          {loading ? (
                            <div className="p-3 border border-blue_gray-100 border-solid flex flex-col items-center justify-center rounded-[12px] w-full">
                              <BeatLoader color="hsla(150, 67%, 53%, 1)" />
                            </div>
                          ) : aiContent ? (
                            ObjectiveResponse === undefined ? (
                              <div className="flex flex-row justify-center w-[100%] pb-[40px] mqlap:text-[0.84vw] mqtab1:text-[0.9vw]  text-[1.02rem] leading-[1.3vw]  mqlap:leading-[1.55vw] mqtab1:leading-[1.6vw] pt-[12px]">
                                <BeatLoader color="hsla(168, 67%, 53%, 1)" />
                              </div>
                            ) : iscareerObjectEdit ? (
                              <Text
                                className="mb-[17px] md:ml-[0] ml-[23px] text-black-900 text-lg w-[95%] sm:w-full h-auto sm:text-[10px] mqlap:text-[0.84vw] mqtab1:text-[0.9vw]  text-[1.02rem] leading-[1.3vw]  mqlap:leading-[1.55vw] mqtab1:leading-[1.6vw] pt-[12px]"
                                size="txtInterRegular18"
                              >
                                {ObjectiveResponse}
                              </Text>
                            ) : (
                              <TextArea
                                className="mb-[17px] md:ml-[0] ml-[23px] text-black-900 text-lg w-[95%] sm:w-full h-auto sm:text-[10px] mqlap:text-[0.84vw] mqtab1:text-[0.9vw]  text-[1.02rem] leading-[1.3vw]  mqlap:leading-[1.55vw] mqtab1:leading-[1.6vw] pt-[12px]"
                                size="txtInterRegular18"
                                readOnly={careerObjectEdit ? false : true}
                                onChange={(e) => setObjResponse(e.target.value)}
                              >
                                {ObjectiveResponse}
                              </TextArea>
                            )
                          ) : iscareerObjectEdit ? (
                            <Text
                              className="mb-[17px] md:ml-[0] ml-[23px] text-black-900 text-lg w-[95%] sm:w-full h-auto sm:text-[10px] mqlap:text-[0.84vw] mqtab1:text-[0.9vw]  text-[1.02rem] leading-[1.3vw]  mqlap:leading-[1.55vw] mqtab1:leading-[1.6vw] pt-[12px]"
                              size="txtInterRegular18"
                            >
                              {resumeObjText}
                            </Text>
                          ) : (
                            <TextArea
                              placeholder="Describe your career aspirations or select the AI tab for assistance."
                              onChange={(e) => {
                                setResumeObjText(e.target.value);
                              }}
                              className="border-0 mb-[17px] md:ml-[0] ml-[13px] text-black-900 text-lg w-[95%] sm:w-full h-auto sm:text-[10px] mqlap:text-[0.84vw] mqtab1:text-[0.9vw]  text-[1.02rem] leading-[1.3vw]  mqlap:leading-[1.55vw] mqtab1:leading-[1.6vw] pt-[12px]"
                              size="txtInterRegular18"
                            >
                              {resumeObjText}
                            </TextArea>
                          )}
                        </div>

                        <div className="flex flex-row sm:gap-10 items-center justify-between sm:justify-around md:ml-[0] w-[95%] md:w-full">
                          <div
                            onClick={tipshandleOpen}
                            className="bg-yellow-500 flex flex-row gap-[15px] items-end justify-start my-[11px] px-2 py-1 rounded-lg w-auto"
                          >
                            <Img
                              className="h-5 md:h-auto object-cover w-5 "
                              src="images/img_idea.png"
                              alt="idea"
                            />
                            <Text
                              className="text-base text-black-900 w-auto sm:text-[10px] mqlap:text-[0.84vw] mqtab1:text-[0.85vw]"
                              size="txtInterMedium16"
                            >
                              Tips
                            </Text>
                          </div>

                          <Dialog
                            open={tipsopen}
                            onClose={tipshandleClose}
                            maxWidth="lg"
                          >
                            <div className="tips-overlay sm:w-[80vw]">
                              <div className="tips sm:text-[12px]">Tips</div>
                              <div className="sm:text-[10px] your-profile-is-container">
                                <ul className="your-profile-is">{`1. Your profile is always placed at the top of your resume. `}</ul>
                              </div>
                              <div className="sm:text-[10px] describe-yourself-in-container sm:text-[10px]">
                                <ul className="your-profile-is">
                                  <li className="describe-yourself-in">{`2. Describe yourself in short and strong terms with both the `}</li>
                                </ul>
                                <p className="vacancy-and-job">{` vacancy and job title in mind. Do not use one liners, `}</p>
                                <p className="vacancy-and-job">
                                  {" "}
                                  but try to be as concise as possible.
                                </p>
                              </div>
                              <div className="sm:text-[10px] create-a-good-container">
                                <ul className="your-profile-is">
                                  <li className="describe-yourself-in">{`3. Create a good profile by mentioning each of the following `}</li>
                                </ul>
                                <p className="vacancy-and-job">
                                  {" "}
                                  at the very least; achievements, qualities,
                                  ambitions, goals
                                </p>
                                <p className="vacancy-and-job">
                                  {" "}
                                  and what are you looking for.
                                </p>
                              </div>
                              <div className="sm:text-[10px] create-a-good-container2">
                                <ul className="your-profile-is">
                                  <li className="describe-yourself-in2">{`4. Use AI to write efficient Resume objective by turning on`}</li>
                                </ul>
                                <p className="vacancy-and-job">
                                  {" "}
                                  the AI Toggle
                                </p>
                              </div>
                            </div>
                          </Dialog>

                          <Button
                            onClick={saveObjective}
                            className="cursor-pointer font-medium leading-[normal] w-[7.3rem] text-center text-lg sm:text-[12px] sm:w-[100px] mqtab1:text-[0.9vw] mqlap:text-[0.95vw] mqtab1:w-[6.05rem] mqlap:w-[6.8rem] mqtab1:rounded-[12px]"
                            shape="round"
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className=" bg-white-A700 flex flex-col w-[94%] sm:w-[100%]  rounded-[20px] mqtab1:rounded-[15px]">
                  <div className=" flex flex-1 flex-row md:gap-10 items-center justify-between p-4 mqtab1:py-[0.8rem]    mqlap:py-[0.9rem]">
                    <div className="flex flex-row gap-[18px] items-center justify-center ml-[33px] sm:ml-[10px]">
                      <Img
                        className="sm:w-[15px]  sm:h-[15px] h-[25px] md:h-auto object-cover w-[25px]"
                        src="images/img_business.png"
                        alt="brainstormskill"
                      />
                      <Text
                        className="mt-0.5 text-black-900 text-lg sm:text-[12px] mqlap:text-[1vw] mqtab1:text-[1.08vw]"
                        size="txtInterSemiBold18"
                      >
                        Work experience
                      </Text>
                    </div>
                    {workDetails ? (
                      <div className="flex flex-row ">
                        <img
                          className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                          onClick={workExperienceArrow}
                          alt="Icon park outline"
                          src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-1.svg"
                        />
                      </div>
                    ) : (
                      <img
                        className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                        onClick={workExperienceArrow}
                        alt="Icon park outline"
                        src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-2.svg"
                      />
                    )}
                  </div>
                  {workDetails && (
                    <div className="w-[95%]">
                      {sample_data.work_experience.length > 0 ? (
                        sample_data.work_experience.map((experience, index) => (
                          <div key={index} className="gap-[5px] flex flex-col">
                            <div className="pl-[90px] sm:pl-[55px] text-wrapper-2 sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]">
                              {experience.company}
                            </div>
                            <div className="pl-[90px] sm:pl-[55px] sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]">
                              {experience.position}
                            </div>
                            <div className="pl-[90px] sm:pl-[55px] sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]">
                              {experience.startDate} - {experience.endDate}
                            </div>
                            <div className="pl-[90px] pr-[10px] sm:pl-[55px] sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]">
                              {experience.description}
                            </div>
                            <div className="rectangle" />
                          </div>
                        ))
                      ) : (
                        <div className="sm:pl-[55px] pl-[90px] text-red-600  sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]">
                          No work experience found.
                        </div>
                      )}
                      <div className="flex flex-row items-center justify-center mt-5 sm:justify-start sm:ml-[55px] mb-5 ">
                        <div className="text-wrapper-3 sm:pl-[55px] sm:text-[10px] mqlap:text-[0.85vw] mqtab1:text-[0.9vw]">
                          show all {sample_data.work_experience.length}{" "}
                          experiences
                        </div>
                        <Img
                          className="sm:w-[15px]  sm:h-[15px] rightarrow1 h-[25px] md:h-auto object-cover w-[30px] p-1 ml-3"
                          src="https://c.animaapp.com/OxnFMjQi/img/line-3-1.svg"
                          alt="brainstormskill"
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className=" bg-white-A700 flex flex-col w-[94%]  sm:w-[100%] rounded-[20px] mqtab1:rounded-[15px]">
                  <div className=" flex flex-1 flex-row md:gap-10 items-center justify-between p-4 mqtab1:py-[0.8rem] mqlap:py-[0.9rem]">
                    <div className="flex flex-row gap-[18px] items-center justify-center  ml-[33px] sm:ml-[10px]">
                      <Img
                        className="sm:w-[15px]  sm:h-[15px] h-[25px] md:h-auto object-cover w-[25px]"
                        src="images/img_education.png"
                        alt="brainstormskill"
                      />
                      <Text
                        className="mt-0.5 text-black-900 text-lg sm:text-[12px] mqlap:text-[1.05vw] mqtab1:text-[1.1vw]"
                        size="txtInterSemiBold18"
                      >
                        Education and qualifications
                      </Text>
                    </div>
                    {educationDetails ? (
                      <div className="flex flex-row ">
                        <img
                          className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                          onClick={educationArrow}
                          alt="Icon park outline"
                          src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-1.svg"
                        />
                      </div>
                    ) : (
                      <img
                        className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                        onClick={educationArrow}
                        alt="Icon park outline"
                        src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-2.svg"
                      />
                    )}
                  </div>

                  {educationDetails && (
                    <div className="w-[95%]">
                      {sample_data.education.map((edu, index) => (
                        <div key={index}>
                          <Text className="sm:pl-[55px] pl-[90px] text-wrapper-2 pr-[10px] sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]">
                            {edu.institution}
                          </Text>
                          <Text className="sm:pl-[55px] pl-[90px] pr-[10px] sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]   ">
                            {edu.degree}
                          </Text>
                          <Text className="sm:pl-[55px] pl-[90px] pr-[10px] sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]">
                            {edu.percentage}
                          </Text>
                          <div className="rectangle" />
                        </div>
                      ))}
                      <div className="flex flex-row items-center justify-center mt-5 sm:justify-start sm:ml-[55px] mb-5">
                        <Text className="text-wrapper-3  sm:text-[10px] mqlap:text-[0.85vw] mqtab1:text-[0.9vw]">
                          show all {sample_data.education.length} education
                        </Text>
                        <Img
                          className="rightarrow1 h-[25px] md:h-auto object-cover w-[30px] p-1 ml-3 sm:w-[15px]  sm:h-[15px]"
                          src="https://c.animaapp.com/OxnFMjQi/img/line-3-1.svg"
                          alt="brainstormskill"
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="bg-white-A700 flex flex-col w-[94%]   sm:w-[100%] rounded-[20px] mqtab1:rounded-[15px]">
                  <div className="flex flex-1 flex-row md:gap-10 items-center justify-between p-4 mqtab1:py-[0.8rem]    mqlap:py-[0.9rem]">
                    <div className="flex flex-row gap-[18px] items-center justify-center sm:ml-[10px] ml-[33px]">
                      <img
                        className="h-[25px] md:h-auto object-cover w-[25px] sm:w-[15px]  sm:h-[15px]"
                        src="images/img_brainstormskill.png"
                        alt="brainstormskill"
                      />
                      <Text
                        className="mt-0.5 text-black-900 text-lg  sm:text-[12px]  mqlap:text-[1.05vw] mqtab1:text-[1.1vw] "
                        size="txtInterSemiBold18"
                      >
                        Skills
                      </Text>
                    </div>
                    <div className="flex flex-row">
                      <img
                        className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                        onClick={skillsArrow}
                        alt="Icon park outline"
                        src={
                          skillsDetails
                            ? "https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-1.svg"
                            : "https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-2.svg"
                        }
                      />
                    </div>
                  </div>

                  {skillsDetails && (
                    <div className="w-[95%]">
                      {sample_data.key_skills.map((skill, index) => (
                        <div key={index}>
                          <Text className="sm:pl-[55px] sm:pl-[55px] pl-[90px] text-wrapper-2  sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]">
                            {skill}
                          </Text>
                          <div className="rectangle" />
                        </div>
                      ))}
                      <div className="flex flex-row items-center justify-center mt-5 sm:justify-start sm:ml-[55px] mb-5 ">
                        <Text className="text-wrapper-3  sm:text-[10px] mqlap:text-[0.85vw] mqtab1:text-[0.9vw]">
                          show all {sample_data.key_skills.length} technical
                          skills
                        </Text>
                        <Img
                          className="rightarrow1 h-[25px] md:h-auto object-cover w-[30px] p-1 ml-3 sm:w-[15px]  sm:h-[15px]"
                          src="https://c.animaapp.com/OxnFMjQi/img/line-3-1.svg"
                          alt="brainstormskill"
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className=" bg-white-A700 flex flex-col w-[94%]  sm:w-[100%] rounded-[20px] mqtab1:rounded-[15px]">
                  <div className=" flex flex-1 flex-row md:gap-10 items-center justify-between p-4 mqtab1:py-[0.8rem]    mqlap:py-[0.9rem]">
                    <div className="flex flex-row gap-[18px] items-center justify-center sm:ml-[10px] ml-[33px]">
                      <Img
                        className="h-[25px] md:h-auto object-cover w-[25px] sm:w-[15px]  sm:h-[15px]"
                        src="images/img_petcommandssummon.png"
                        alt="brainstormskill"
                      />
                      <Text
                        className="mt-0.5 text-black-900 text-lg  sm:text-[12px]  mqlap:text-[1.05vw] mqtab1:text-[1.1vw] "
                        size="txtInterSemiBold18"
                      >
                        Interests
                      </Text>
                    </div>
                    {interestDetails ? (
                      <div className="flex flex-row ">
                        <div
                          className="flex items-center"
                          onClick={handleInterests}
                        >
                          <Switch
                            onColor="#349765"
                            offColor="#888EA2"
                            onHandleColor="#ffffff"
                            offHandleColor="#ffffff"
                            value={false}
                            className="w-[19%]"
                          />
                        </div>
                        <img
                          className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                          onClick={intrestArrow}
                          alt="Icon park outline"
                          src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-1.svg"
                        />
                      </div>
                    ) : (
                      <img
                        className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                        onClick={intrestArrow}
                        alt="Icon park outline"
                        src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-2.svg"
                      />
                    )}
                  </div>
                  {interestDetails && (
                    <div className="w-[95%]">
                      {sample_data.interest.map((interest, index) => (
                        <div key={index}>
                          <Text className="sm:pl-[55px] pl-[90px] text-wrapper-2  sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]">
                            {interest}
                          </Text>
                          <div className="rectangle" />
                        </div>
                      ))}
                      <div className="flex flex-row items-center justify-center mt-5 sm:justify-start sm:ml-[55px] mb-5 mqlap:text-[0.85vw] mqtab1:text-[0.9vw]">
                        <Text className="text-wrapper-3  sm:text-[10px] mqlap:text-[0.85vw] mqtab1:text-[0.9vw]">
                          show all {sample_data.interest.length} interests
                        </Text>
                        <Img
                          className="rightarrow1 h-[25px] md:h-auto object-cover w-[30px] p-1 ml-3 sm:w-[15px]  sm:h-[15px]"
                          src="https://c.animaapp.com/OxnFMjQi/img/line-3-1.svg"
                          alt="brainstormskill"
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className=" bg-white-A700 flex flex-col w-[94%]  sm:w-[100%] rounded-[20px] mqtab1:rounded-[15px]">
                  <div className=" flex flex-1 flex-row md:gap-10 items-center justify-between p-4 mqtab1:py-[0.8rem]    mqlap:py-[0.9rem]">
                    <div className="flex flex-row gap-[18px] items-center justify-center sm:ml-[10px] ml-[33px]">
                      <Img
                        className="h-[25px] md:h-auto object-cover w-[25px] sm:w-[15px]  sm:h-[15px]"
                        src="images/img_education.png"
                        alt="brainstormskill"
                      />
                      <Text
                        className="mt-0.5 text-black-900 text-lg  sm:text-[12px]  mqlap:text-[1.05vw] mqtab1:text-[1.1vw] "
                        size="txtInterSemiBold18"
                      >
                        languages
                      </Text>
                    </div>
                    {launguageDetails ? (
                      <div className="flex flex-row ">
                        <div
                          className="flex items-center"
                          onClick={handleLanguages}
                        >
                          <Switch
                            onColor="#349765"
                            offColor="#888EA2"
                            onHandleColor="#ffffff"
                            offHandleColor="#ffffff"
                            value={false}
                            className="w-[19%]"
                          />
                        </div>

                        <img
                          className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                          onClick={launguageArrow}
                          alt="Icon park outline"
                          src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-1.svg"
                        />
                      </div>
                    ) : (
                      <img
                        className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                        onClick={launguageArrow}
                        alt="Icon park outline"
                        src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-2.svg"
                      />
                    )}
                  </div>

                  {launguageDetails && (
                    <div className="w-[95%]">
                      {sample_data.language.map((lang, index) => (
                        <div key={index}>
                          <Text className="pl-[90px] sm:pl-[55px] text-wrapper-2  sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]">
                            {lang}
                          </Text>
                          <div className="rectangle" />
                        </div>
                      ))}
                      <div className="flex flex-row items-center justify-center mt-5 sm:justify-start sm:ml-[55px] mb-5 mqlap:text-[0.85vw] mqtab1:text-[0.9vw]">
                        <Text className="text-wrapper-3  sm:text-[10px] mqlap:text-[0.85vw] mqtab1:text-[0.9vw]">
                          show all {sample_data.language.length} languages
                        </Text>
                        <Img
                          className="rightarrow1 h-[25px] md:h-auto object-cover w-[30px] p-1 ml-3 sm:w-[15px]  sm:h-[15px]"
                          src="https://c.animaapp.com/OxnFMjQi/img/line-3-1.svg"
                          alt="brainstormskill"
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className=" bg-white-A700 flex flex-col w-[94%]  sm:w-[100%] rounded-[20px] mqtab1:rounded-[15px]">
                  <div className=" flex flex-1 flex-row md:gap-10 items-center justify-between p-4 mqtab1:py-[0.8rem]    mqlap:py-[0.9rem]">
                    <div className="flex flex-row gap-[18px] items-center justify-center sm:ml-[10px] ml-[33px]">
                      <Img
                        className="h-[25px] md:h-auto object-cover w-[25px] sm:w-[15px]  sm:h-[15px]"
                        src="images/img_brainstormskill.png"
                        alt="brainstormskill"
                      />
                      <Text
                        className="mt-0.5 text-black-900 text-lg  sm:text-[12px]  mqlap:text-[1.05vw] mqtab1:text-[1.1vw] "
                        size="txtInterSemiBold18"
                      >
                        Certificate
                      </Text>
                    </div>
                    {certificateDetails ? (
                      <div className="flex flex-row ">
                        <div
                          className="flex items-center"
                          onClick={handleCertificates}
                        >
                          <Switch
                            onColor="#349765"
                            offColor="#888EA2"
                            onHandleColor="#ffffff"
                            offHandleColor="#ffffff"
                            value={false}
                            className="w-[19%]"
                          />
                        </div>

                        <img
                          className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                          onClick={certificateArrow}
                          alt="Icon park outline"
                          src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-1.svg"
                        />
                      </div>
                    ) : (
                      <img
                        className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                        onClick={certificateArrow}
                        alt="Icon park outline"
                        src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-2.svg"
                      />
                    )}
                  </div>

                  {certificateDetails && (
                    <div className="w-[95%]">
                      {sample_data.certificates.map((certificate, index) => (
                        <div key={index}>
                          <Text className="pl-[90px] sm:pl-[55px] text-wrapper-2  sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]">
                            {certificate}
                          </Text>
                          <div className="rectangle" />
                        </div>
                      ))}
                      <div className="flex flex-row items-center justify-center mt-5 sm:justify-start sm:ml-[55px] mb-5 mqlap:text-[0.85vw] mqtab1:text-[0.9vw]">
                        <Text className="text-wrapper-3 sm:text-[10px] mqlap:text-[0.85vw] mqtab1:text-[0.9vw]">
                          show all {sample_data.certificates.length}{" "}
                          certificates
                        </Text>
                        <Img
                          className="rightarrow1 h-[25px] md:h-auto object-cover w-[30px] p-1 ml-3 sm:w-[15px]  sm:h-[15px]"
                          src="https://c.animaapp.com/OxnFMjQi/img/line-3-1.svg"
                          alt="brainstormskill"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className=" bg-white-A700 flex flex-col w-[94%]  sm:w-[100%] rounded-[20px] mqtab1:rounded-[15px]">
                  <div className=" flex flex-1 flex-row md:gap-10 items-center justify-between p-4 mqtab1:py-[0.8rem]    mqlap:py-[0.9rem]">
                    <div className="flex flex-row gap-[18px] items-center justify-center sm:ml-[10px] ml-[33px]">
                      <Img
                        className="h-[25px] md:h-auto object-cover w-[25px] sm:w-[15px]  sm:h-[15px]"
                        src="images/img_business.png"
                        alt="brainstormskill"
                      />
                      <Text
                        className="mt-0.5 text-black-900 text-lg  sm:text-[12px]  mqlap:text-[1.05vw] mqtab1:text-[1.1vw] "
                        size="txtInterSemiBold18"
                      >
                        Project
                      </Text>
                    </div>
                    {projectDetails ? (
                      <div className="flex flex-row ">
                        <div
                          className="flex items-center"
                          onClick={handleProjects}
                        >
                          <Switch
                            onColor="#349765"
                            offColor="#888EA2"
                            onHandleColor="#ffffff"
                            offHandleColor="#ffffff"
                            value={false}
                            className="w-[19%]"
                          />
                        </div>
                        <img
                          className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                          onClick={projectArrow}
                          alt="Icon park outline"
                          src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-1.svg"
                        />
                      </div>
                    ) : (
                      <img
                        className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                        onClick={projectArrow}
                        alt="Icon park outline"
                        src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-2.svg"
                      />
                    )}
                  </div>
                  {projectDetails && (
                    <div>
                      <div className="w-[95%]">
                        {sample_data.project.map((project, index) => (
                          <div key={index} className="gap-[5px] flex flex-col">
                            <div className="pl-[90px] sm:pl-[55px] sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] ">
                              {project.projectName}
                            </div>
                            {/* <div className="pl-[90px]  pr-[10px] sm:pl-[55px] text-wrapper-2  sm:text-[10px]">
                            {project.projectDescription}
                          </div> */}
                            <div className="pl-[90px] sm:pl-[55px] sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] ">
                              {project.projectDescription}
                            </div>
                            {/* <div className="pl-[90px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] font-semi-bold">Technology Used </div> */}
                            {/* {project.technologiesUsed.map((tech, index)=>
                          
                          <div className="pl-[90px] sm:pl-[55px] sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] ">
                            {tech}
                          </div>
                          )} */}

                            <div className="pl-[90px] sm:pl-[55px] sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] ">
                              {project.technologiesUsed}
                            </div>
                            <div className="rectangle " />
                          </div>
                        ))}
                        <div className="flex flex-row items-center justify-center mt-5 sm:justify-start sm:ml-[55px] mb-5">
                          <Text className="text-wrapper-3  sm:text-[10px] mqlap:text-[0.85vw] mqtab1:text-[0.9vw]">
                            show all {sample_data.project.length} projects
                          </Text>
                          <Img
                            className="rightarrow1 h-[25px] md:h-auto object-cover w-[30px] p-1 ml-3 sm:w-[15px]  sm:h-[15px]"
                            src="https://c.animaapp.com/OxnFMjQi/img/line-3-1.svg"
                            alt="brainstormskill"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className=" bg-white-A700 flex flex-col w-[94%]  sm:w-[100%] rounded-[20px] mqtab1:rounded-[15px]">
                  <div className=" flex flex-1 flex-row md:gap-10 items-center justify-between p-4 mqtab1:py-[0.8rem]    mqlap:py-[0.9rem]">
                    <div className="flex flex-row gap-[18px] items-center justify-center sm:ml-[10px] ml-[33px]">
                      <Img
                        className="h-[25px] md:h-auto object-cover w-[25px] sm:w-[15px]  sm:h-[15px]"
                        src="images/img_education.png"
                        alt="brainstormskill"
                      />
                      <Text
                        className="mt-0.5 text-black-900 text-lg  sm:text-[12px]  mqlap:text-[1.05vw] mqtab1:text-[1.1vw] "
                        size="txtInterSemiBold18"
                      >
                        Courses
                      </Text>
                    </div>
                    {courseDetails ? (
                      <div className="flex flex-row ">
                        <div
                          className="flex items-center"
                          onClick={handleCourses}
                        >
                          <Switch
                            onColor="#349765"
                            offColor="#888EA2"
                            onHandleColor="#ffffff"
                            offHandleColor="#ffffff"
                            value={false}
                            className="w-[19%]"
                          />
                        </div>

                        <img
                          className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                          onClick={courseArrow}
                          alt="Icon park outline"
                          src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-1.svg"
                        />
                      </div>
                    ) : (
                      <img
                        className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                        onClick={courseArrow}
                        alt="Icon park outline"
                        src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-2.svg"
                      />
                    )}
                  </div>
                  {courseDetails && (
                    <div className="w-[95%]">
                      {sample_data.courses.map((course, index) => (
                        <div key={index}>
                          <Text className="pl-[90px] sm:pl-[55px] text-wrapper-2  sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]">
                            {course}
                          </Text>
                          <div className="rectangle" />
                        </div>
                      ))}
                      <div className="flex flex-row items-center justify-center mt-5 sm:justify-start sm:ml-[55px] mb-5 mqlap:text-[0.85vw] mqtab1:text-[0.9vw]">
                        <Text className="text-wrapper-3  sm:text-[10px] mqlap:text-[0.85vw] mqtab1:text-[0.9vw]">
                          show all {sample_data.courses.length} courses
                        </Text>
                        <Img
                          className="rightarrow1 h-[25px] md:h-auto object-cover w-[30px] p-1 ml-3 sm:w-[15px]  sm:h-[15px]"
                          src="https://c.animaapp.com/OxnFMjQi/img/line-3-1.svg"
                          alt="brainstormskill"
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className=" bg-white-A700 flex flex-col w-[94%]  sm:w-[100%] rounded-[20px] mqtab1:rounded-[15px]">
                  <div className=" flex flex-1 flex-row md:gap-10 items-center justify-between p-4 mqtab1:py-[0.8rem]    mqlap:py-[0.9rem]">
                    <div className="flex flex-row gap-[18px] items-center justify-center sm:ml-[10px] ml-[33px]">
                      <Img
                        className="h-[25px] md:h-auto object-cover w-[25px] sm:w-[15px]  sm:h-[15px]"
                        src="images/img_brainstormskill.png"
                        alt="brainstormskill"
                      />
                      <Text
                        className="mt-0.5 text-black-900 text-lg  sm:text-[12px]  mqlap:text-[1.05vw] mqtab1:text-[1.1vw] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] "
                        size="txtInterSemiBold18"
                      >
                        Awards
                      </Text>
                    </div>
                    {awardsDetails ? (
                      <div className="flex flex-row ">
                        <div
                          className="flex items-center"
                          onClick={handleAwards}
                        >
                          <Switch
                            onColor="#349765"
                            offColor="#888EA2"
                            onHandleColor="#ffffff"
                            offHandleColor="#ffffff"
                            value={false}
                            className="w-[19%]"
                          />
                        </div>

                        <img
                          className="icon-park-outlin sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw]"
                          onClick={awardsArrow}
                          alt="Icon park outline"
                          src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-1.svg"
                        />
                      </div>
                    ) : (
                      <img
                        className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw]  "
                        onClick={awardsArrow}
                        alt="Icon park outline"
                        src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-2.svg"
                      />
                    )}
                  </div>

                  {awardsDetails && sample_data.awards && (
                    <div className="w-[95%]">
                      {sample_data.awards.map((award, index) => (
                        <div key={index}>
                          <Text className="pl-[90px] sm:pl-[55px] text-wrapper-2  sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]">
                            {award}
                          </Text>
                          <div className="rectangle" />
                        </div>
                      ))}
                      <div className="flex flex-row items-center justify-center mt-5 sm:justify-start sm:ml-[55px] mb-5 mqlap:text-[0.85vw] mqtab1:text-[0.9vw]">
                        <Text className="text-wrapper-3  sm:text-[10px] mqlap:text-[0.85vw] mqtab1:text-[0.9vw]">
                          show all {sample_data.awards.length} awards
                        </Text>
                        <Img
                          className="rightarrow1 h-[25px] md:h-auto object-cover w-[30px] p-1 ml-3 sm:w-[15px]  sm:h-[15px]"
                          src="https://c.animaapp.com/OxnFMjQi/img/line-3-1.svg"
                          alt="brainstormskill"
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className=" bg-white-A700 flex flex-col w-[94%]  sm:w-[100%] rounded-[20px] mqtab1:rounded-[15px]">
                  <div className=" flex flex-1 flex-row md:gap-10 items-center justify-between p-4 mqtab1:py-[0.8rem]    mqlap:py-[0.9rem]">
                    <div className="flex flex-row gap-[18px] items-center justify-center sm:ml-[10px] ml-[33px]">
                      <Img
                        className="h-[25px] md:h-auto object-cover w-[25px] sm:w-[15px]  sm:h-[15px]"
                        src="images/img_petcommandssummon.png"
                        alt="brainstormskill"
                      />
                      <Text
                        className="mt-0.5 text-black-900 text-lg  sm:text-[12px]  mqlap:text-[1.05vw] mqtab1:text-[1.1vw] "
                        size="txtInterSemiBold18"
                      >
                        Organisation
                      </Text>
                    </div>
                    {organisationDetails ? (
                      <div className="flex flex-row ">
                        <div
                          className="flex items-center"
                          onClick={handleOrganisations}
                        >
                          <Switch
                            onColor="#349765"
                            offColor="#888EA2"
                            onHandleColor="#ffffff"
                            offHandleColor="#ffffff"
                            value={false}
                            className="w-[19%]"
                          />
                        </div>
                        <img
                          className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                          onClick={organisationArrow}
                          alt="Icon park outline"
                          src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-1.svg"
                        />
                      </div>
                    ) : (
                      <img
                        className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                        onClick={organisationArrow}
                        alt="Icon park outline"
                        src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-2.svg"
                      />
                    )}
                  </div>
                  {organisationDetails && (
                    <div className="w-[95%]">
                      {sample_data.organizations.map((organization, index) => (
                        <div key={index}>
                          <React.Fragment>
                            <Text className="pl-[90px] sm:pl-[55px] text-wrapper-2  sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]">
                              {organization}
                            </Text>
                            <div className="rectangle" />
                          </React.Fragment>
                        </div>
                      ))}
                      <div className="flex flex-row items-center justify-center mt-5 sm:justify-start sm:ml-[55px] mb-5">
                        <Text className="text-wrapper-3  sm:text-[10px] mqlap:text-[0.85vw] mqtab1:text-[0.9vw]">
                          show all {sample_data.organizations.length}{" "}
                          organizations
                        </Text>
                        <Img
                          className="rightarrow1 h-[25px] md:h-auto object-cover w-[30px] p-1 ml-3 sm:w-[15px]  sm:h-[15px]"
                          src="https://c.animaapp.com/OxnFMjQi/img/line-3-1.svg"
                          alt="brainstormskill"
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className=" bg-white-A700 flex flex-col w-[94%]  sm:w-[100%] rounded-[20px] mqtab1:rounded-[15px]">
                  <div className=" flex flex-1 flex-row md:gap-10 items-center justify-between p-4 mqtab1:py-[0.8rem]    mqlap:py-[0.9rem]">
                    <div className="flex flex-row gap-[18px] items-center justify-center sm:ml-[10px] ml-[33px]">
                      <Img
                        className="h-[25px] md:h-auto object-cover w-[25px] sm:w-[15px]  sm:h-[15px]"
                        src="images/img_education.png"
                        alt="brainstormskill"
                      />
                      <Text
                        className="mt-0.5 text-black-900 text-lg  sm:text-[12px]  mqlap:text-[1.05vw] mqtab1:text-[1.1vw] "
                        size="txtInterSemiBold18"
                      >
                        Publication
                      </Text>
                    </div>
                    {publicationDetails ? (
                      <div className="flex flex-row ">
                        <div
                          className="flex items-center"
                          onClick={handlePublications}
                        >
                          <Switch
                            onColor="#349765"
                            offColor="#888EA2"
                            onHandleColor="#ffffff"
                            offHandleColor="#ffffff"
                            value={false}
                            className="w-[19%]"
                          />
                        </div>

                        <img
                          className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                          onClick={publicationArrow}
                          alt="Icon park outline"
                          src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-1.svg"
                        />
                      </div>
                    ) : (
                      <img
                        className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                        onClick={publicationArrow}
                        alt="Icon park outline"
                        src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-2.svg"
                      />
                    )}
                  </div>

                  {publicationDetails && (
                    <div className="w-[95%]">
                      {sample_data.publications.map((publication, index) => (
                        <div key={index}>
                          <Text className="pl-[90px] sm:pl-[55px] text-wrapper-2  sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]">
                            {publication}
                          </Text>
                          <div className="rectangle" />
                        </div>
                      ))}
                      <div className="flex flex-row items-center justify-center mt-5 sm:justify-start sm:ml-[55px] mb-5">
                        <Text className="text-wrapper-3  sm:text-[10px] mqlap:text-[0.85vw] mqtab1:text-[0.9vw]">
                          show all {sample_data.publications.length}{" "}
                          publications
                        </Text>
                        <Img
                          className="rightarrow1 h-[25px] md:h-auto object-cover w-[30px] p-1 ml-3 sm:w-[15px]  sm:h-[15px]"
                          src="https://c.animaapp.com/OxnFMjQi/img/line-3-1.svg"
                          alt="brainstormskill"
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className=" bg-white-A700 flex flex-col w-[94%]  sm:w-[100%] rounded-[20px] mqtab1:rounded-[15px]">
                  <div className=" flex flex-1 flex-row md:gap-10 items-center justify-between p-4 mqtab1:py-[0.8rem]    mqlap:py-[0.9rem]">
                    <div className="flex flex-row gap-[18px] items-center justify-center sm:ml-[10px] ml-[33px]">
                      <Img
                        className="h-[25px] md:h-auto object-cover w-[25px] sm:w-[15px]  sm:h-[15px]"
                        src="images/img_brainstormskill.png"
                        alt="brainstormskill"
                      />
                      <Text
                        className="mt-0.5 text-black-900 text-lg  sm:text-[12px]  mqlap:text-[1.05vw] mqtab1:text-[1.1vw] "
                        size="txtInterSemiBold18"
                      >
                        Social Links
                      </Text>
                    </div>
                    {linkDetails ? (
                      <div className="flex flex-row ">
                        <div
                          className="flex items-center"
                          onClick={handleLinks}
                        >
                          <Switch
                            onColor="#349765"
                            offColor="#888EA2"
                            onHandleColor="#ffffff"
                            offHandleColor="#ffffff"
                            value={false}
                            className="w-[19%]"
                          />
                        </div>

                        <img
                          className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                          onClick={linkArrow}
                          alt="Icon park outline"
                          src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-1.svg"
                        />
                      </div>
                    ) : (
                      <img
                        className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                        onClick={linkArrow}
                        alt="Icon park outline"
                        src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-2.svg"
                      />
                    )}
                  </div>
                  {linkDetails && sample_data.link && (
                    <div className="w-[95%]">
                      {sample_data.link.map((link, index) => (
                        <div key={index}>
                          <Text className="pl-[90px] sm:pl-[55px] text-wrapper-2  sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]">
                            {link}
                          </Text>
                          <div className="rectangle" />
                        </div>
                      ))}
                      <div className="flex flex-row items-center justify-center sm:justify-start sm:ml-[55px] mt-5 mb-5  mqlap:text-[0.85vw] mqtab1:text-[0.9vw] ">
                        <Text className="text-wrapper-3 sm:text-[10px] mqlap:text-[0.85vw] mqtab1:text-[0.9vw]">
                          show all {sample_data.link.length} Social links
                        </Text>
                        <Img
                          className="rightarrow1 h-[25px] md:h-auto object-cover w-[30px] p-1 ml-3 sm:w-[15px]  sm:h-[15px]"
                          src="https://c.animaapp.com/OxnFMjQi/img/line-3-1.svg"
                          alt="brainstormskill"
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className=" bg-white-A700 flex flex-col w-[94%]  sm:w-[100%] rounded-[20px] mqtab1:rounded-[15px]">
                  <div className=" flex flex-1 flex-row md:gap-10 items-center justify-between p-4 mqtab1:py-[0.8rem]    mqlap:py-[0.9rem]">
                    <div className="flex flex-row gap-[18px] items-center justify-center sm:ml-[10px] ml-[33px]">
                      <Img
                        className="h-[25px] md:h-auto object-cover w-[25px] sm:w-[15px]  sm:h-[15px]"
                        src="images/img_brainstormskill.png"
                        alt="brainstormskill"
                      />
                      <Text
                        className="mt-0.5 text-black-900 text-lg  sm:text-[12px]  mqlap:text-[1.05vw] mqtab1:text-[1.1vw] "
                        size="txtInterSemiBold18"
                      >
                        Reference
                      </Text>
                    </div>
                    {referenceDetails ? (
                      <div className="flex flex-row ">
                        <div
                          className="flex items-center"
                          onClick={handleReferences}
                        >
                          <Switch
                            onColor="#349765"
                            offColor="#888EA2"
                            onHandleColor="#ffffff"
                            offHandleColor="#ffffff"
                            value={false}
                            className="w-[19%]"
                          />
                        </div>

                        <img
                          className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                          onClick={referenceArrow}
                          alt="Icon park outline"
                          src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-1.svg"
                        />
                      </div>
                    ) : (
                      <img
                        className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                        onClick={referenceArrow}
                        alt="Icon park outline"
                        src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-2.svg"
                      />
                    )}
                  </div>
                  {referenceDetails && sample_data.references && (
                    <div className="w-[95%]">
                      {sample_data.references.map((reference, index) => (
                        <div key={index}>
                          <Text className="pl-[90px] sm:pl-[55px] text-wrapper-2  sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]">
                            {reference}
                          </Text>
                          <div className="rectangle" />
                        </div>
                      ))}
                      <div className="flex flex-row items-center justify-center mt-5 sm:justify-start sm:ml-[55px] mb-5 mqlap:text-[0.85vw] mqtab1:text-[0.9vw]">
                        <Text className="text-wrapper-3  sm:text-[10px] mqlap:text-[0.85vw] mqtab1:text-[0.9vw]">
                          show all {sample_data.references.length} references
                        </Text>
                        <Img
                          className="rightarrow1 h-[25px] md:h-auto object-cover w-[30px] p-1 ml-3 sm:w-[15px]  sm:h-[15px]"
                          src="https://c.animaapp.com/OxnFMjQi/img/line-3-1.svg"
                          alt="brainstormskill"
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className=" bg-white-A700 flex flex-col w-[94%]  sm:w-[100%] rounded-[20px] mqtab1:rounded-[15px] mb-[40px]">
                  <div className=" flex flex-1 flex-row md:gap-10 items-center justify-between p-4 mqtab1:py-[0.8rem]    mqlap:py-[0.9rem]">
                    <div className="flex flex-row gap-[18px] items-center justify-center ml-[33px] sm:ml-[10px]">
                      <Img
                        className="h-[25px] md:h-auto object-cover w-[25px] sm:w-[15px]  sm:h-[15px]"
                        src="images/img_brainstormskill.png"
                        alt="brainstormskill"
                      />
                      <Text
                        className="mt-0.5 text-black-900 text-lg  sm:text-[12px]  mqlap:text-[1.05vw] mqtab1:text-[1.1vw] "
                        size="txtInterSemiBold18"
                      >
                        Declaration
                      </Text>
                    </div>
                    {declarationDetails ? (
                      <div className="flex flex-row ">
                        <div className="flex items-center">
                          <Switch
                            onColor="#349765"
                            offColor="#888EA2"
                            onHandleColor="#ffffff"
                            offHandleColor="#ffffff"
                            value={false}
                            className="w-[19%]"
                          />
                        </div>

                        <img
                          className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                          onClick={declarationArrow}
                          alt="Icon park outline"
                          src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-1.svg"
                        />
                      </div>
                    ) : (
                      <img
                        className="icon-park-outline sm:w-[15px]  sm:h-[15px] mqlap:w-[2.5vw] mqtab1:w-[2.6vw] "
                        onClick={declarationArrow}
                        alt="Icon park outline"
                        src="https://c.animaapp.com/OxnFMjQi/img/icon-park-outline-down-2.svg"
                      />
                    )}
                  </div>

                  {declarationDetails && (
                    <div className="w-[95%]">
                      {sample_data.declarations.map((declaration, index) => (
                        <div key={index}>
                          <Text className="pl-[90px] sm:pl-[55px] text-wrapper-2  sm:text-[10px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw]">
                            {declaration}
                          </Text>
                          <div className="rectangle" />
                        </div>
                      ))}
                      <div className="flex flex-row items-center justify-center sm:justify-start sx`m:ml-[55px] mt-5 mb-5">
                        <Text className="text-wrapper-3  sm:text-[10px] mqlap:text-[0.85vw] mqtab1:text-[0.9vw]">
                          show all {sample_data.declarations.length}{" "}
                          declarations
                        </Text>
                        <Img
                          className="rightarrow1 h-[25px] md:h-auto object-cover w-[30px] p-1 ml-3 sm:w-[15px]  sm:h-[15px]"
                          src="https://c.animaapp.com/OxnFMjQi/img/line-3-1.svg"
                          alt="brainstormskill"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="hidden md:flex flex flex-row w-full items-center justify-between sm:mb-[50px]">
                  <Link to="/resumebuilder" className="">
                    <Button className="flex flex-row items-center justify-center sm:text-[12px] sm:w-[100px] sm:h-[30px]">
                      Back
                    </Button>
                  </Link>
                  <Link to="/resume-download" className="">
                    <Button className="flex flex-row items-center justify-center sm:text-[12px] sm:w-[100px] sm:h-[30px]">
                      Generate
                    </Button>
                  </Link>
                </div>
              </div>
              <div className=" overflow-scroll  h-full  sm:w-[100%] detail-right mqtab1:w-[54%] mqmob1:w-[100%] mqtab2:h-auto mqmob1:h-auto  ">
                <div className="flex flex-col items-start justify-end p-[9px] sm:p-[0px] w-[100%] md:w-full  gap-[30px]">
                  <Link to="/resumebuilder">
                    <div className=" flex md:flex-1 flex-row gap-[5px] items-center justify-start pr-0.5 pt-0.5 w-[100%] sm:w-[100%] md:w-full mqtab2:gap-[10px] mqmob1:gap-[10px]">
                      <Img
                        className="h-5 sm:w-[15px]  sm:h-[15px] mqlap:h-[1.2vw] mqtab1:h-[1.3vw]"
                        src="images/img_materialsymbolsborderall2.svg"
                        alt="materialsymbols"
                      />
                      <Text
                        className=" sm:text-[10px] leading-[normal] ml-3 md:ml-[0]  text-base text-black-900_7f text-left mqlap:text-[1vw] mqtab1:text-[1.05vw]"
                        size="txtPoppinsSemiBold24"
                      >
                        Change Template
                      </Text>
                    </div>
                  </Link>

                  <div
                    id="resume"
                    className=" sm:pl-[0px] flex flex-row items-center justify-center md:ml-[0] sm:mt-[20px] sm:pl-[0px] sm:pt-[0px] mt-[20px] p-2.5 sm:p-0 sm:self-center shadow-bs w-[100%] sm:w-[100vw] sm:h-[75vh]"
                  >
                    {ResumeTemplateName !== "" ? (
                      <ResumeTemplateToRender
                        data={resumeBody}
                        intereststatus={intereststatus}
                        projectstatus={projectstatus}
                        linkstatus={linkstatus}
                        languagestatus={languagestatus}
                        organisationstatus={organisationstatus}
                        publicationstatus={publicationstatus}
                        coursestatus={coursestatus}
                        certificatestatus={certificatestatus}
                        referencestatus={referencestatus}
                        declarationstatus={declarationstatus}
                        awardstatus={awardstatus}
                      />
                    ) : (
                      <FallbackTemplate />
                    )}
                  </div>
                  <Button
                    onClick={handleBothActions}
                    className="cursor-pointer flex items-center self-end justify-center mb-1  w-[11.6rem] mqtab1:w-[9.5rem] mqlap:w-[10.5rem] md:ml-[0] ml-[302px] mt-[50px] p-[7px] mqlap:p-[5.5px] mqtab1:py-[4.5px] mqtab1:px-[1.5px]"
                    rightIcon={
                      <Img
                        className="mt-px ml-4 sm:w-[15px]  sm:h-[15px]"
                        src="images/img_octicondownload16.svg"
                        alt="octicon:download-16"
                      />
                    }
                  >
                    <div className="font-medium leading-[normal] text-center text-lg mqlap:text-[1vw] mqtab1:text-[1.05vw]">
                      Download
                    </div>
                  </Button>

                  {/* <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title
                    aria-describedby="alert-dialog-description"
                  >
                    <div className="index">
                      <div className="w-[400px] h-[150px]">
                        <div>
                          <div>
                            <div
                              className="overlap-group-wrapper"
                              onClick={handleClose}
                            >
                              <div className="overlap-group">
                                <div className="save">No</div>
                              </div>
                            </div>
                            <button
                              className="overlap-wrapper pl-[100px]"
                              onClick={handleYesOptionClick}
                            >
                              <div className="save-wrapper">
                                <div className="text-wrapper">Yes</div>
                              </div>
                            </button>
                            <DialogContentText
                              id="alert-dialog-description"
                              className="are-you-sure-to-turn"
                            >
                              Your Download should start soon <br />
                              Do you want to replace the uploaded <br />
                              Resume on the RecRoid?
                            </DialogContentText>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog> */}

                  <Dialog
                    maxWidth="sm"
                    open={open}
                    onClose={handleClose}
                    disableBackdropClick={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <div className="mqlap:w-[33.2vw] mqtab1:w-[35.2vw]">
                      <div>
                        <div className="flex flex-row items-center h-[68px] mqlap:h-[3.55vw] mqtab1:h-[3.76vw] mqtab2:h-[45px]">
                          <Text
                            className="pl-[4.5%] mt-0.5 text-black-900 text-xl sm:text-[12px]   mqlap:text-[1.05vw] mqtab1:text-[1.15vw] mqtab2:text-[1.4vw]"
                            size="txtInterSemiBold18"
                          >
                            Download Resume
                          </Text>
                          <Tooltip
                            title={
                              <Typography>
                                <p className="text-[16px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] mqtab2:text-[1.02vw]">
                                  Your download will start shortly.
                                </p>
                              </Typography>
                            }
                            placement="right"
                            arrow
                            className="relative flex flex-col items-center justify-start md:h-[84px] gap-2 md:w-[250px]   rounded-[15px] transition-colors hover:bg-gray-100 hover:text-[40px]"
                          >
                            <img
                              src="images/infoIcon.svg"
                              alt="infoIcon"
                              className="mt-[1.3px] mqlap:mt-[1px] mqtab1:mt-[2px] ml-[3px] h-[1.25rem] w-[1.25rem] mqlap:w-[1.1vw] mqlap:h-[1.1rem] mqtab1:w-[1.1vw] mqtab1:h-[1rem] mqtab2:w-[1.3vw] mqtab2:h-[1.3rem]"
                            />
                          </Tooltip>
                        </div>
                        <hr
                          style={{
                            height: "0.5px",
                            backgroundColor: "grey",
                            border: "none",
                            width: "100%",
                          }}
                        />
                      </div>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          <p className="pl-[0.5%] text-[1.05rem] mqlap:text-[0.92vw] mqtab1:text-[0.96vw] mqtab2:text-[1.02vw] leading-6 mqlap:leading-[1.45vw] mqtab1:leading-[1.5vw]">
                            Would you like to replace the currently uploaded
                            resume on RecRoid with the new one?
                          </p>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions className="mb-[0.57rem] mqtab1:mb-[0.43rem]  mqlap:mb-[0.5rem] mt-[0.2vw] mqtab1:mt-[0.1vw] ">
                        <button
                          style={{ borderColor: " blue", color: "blue" }}
                          className=" mqtab1:border-[0.1vw]  mqlap:border-[0.1vw] border-[1.9px] border-solid flex justify-center items-center h-[2.2rem] w-[6.55rem] mqlap:h-[1.9vw] mqlap:w-[5.8vw] mqtab1:h-[2vw] mqtab1:w-[6vw] mqtab2:h-[2.35vw] mqtab2:w-[7.4vw] rounded-[13px] sm:text-[14px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] mqtab2:text-[0.95vw] mqlap:rounded-[0.73vw] mqtab1:rounded-[0.73vw] mqtab2:rounded-[9px]"
                          // className="h-[50px] w-[100px] sm:h-[30px] sm:w-[80px] text-[18px] rounded-[14px]"
                          onClick={handleClose}
                        >
                          No
                        </button>
                        <Button
                          onClick={handleYesOptionClick}
                          className="flex justify-center items-center mr-[30px] mqlap:mr-[1.8vw] mqtab1:mr-[1.9vw] h-[2.25rem] w-[6.55rem] mqlap:h-[2vw] mqlap:w-[5.8vw] mqtab1:h-[2.05vw] mqtab1:w-[6vw] mqtab2:h-[2.36vw] mqtab2:w-[7.4vw] rounded-[11px] sm:text-[14px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] mqtab2:text-[0.95vw] mqlap:rounded-[0.73vw] mqtab1:rounded-[0.73vw] mqtab2:rounded-[9px]"
                          // className="h-[50px] w-[100px] sm:h-[30px] sm:w-[90px] sm:text-[12px] rounded-[10px]"
                          autoFocus
                        >
                          Yes
                        </Button>
                      </DialogActions>
                    </div>
                  </Dialog>

                  {/* <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xxl"
                  >
                    <div className="flex flex-col items-center justify-center min-w-[400px] w-auto min-h-[200px]  h-auto">
                      <Text
                        className="text-base font-medium  w-auto pb-[50px]"
                        // size="txtInterRegular16"
                        id="alert-dialog-description"
                      >
                        Your Download should start soon <br />
                        Do you want to replace the uploaded <br />
                        Cover letter on the RecRoid?
                      </Text>
                      <div className="flex flex-row items-center justify-around w-full">
                        <Button
                          className="flex flex-row items-center justify-center  rounded-[12px]"
                          style={{
                            backgroundColor: "#fff",
                            color: "#314ca3",
                            border: "3px solid #314ca3",
                            width: "100px",
                            height: "35px",
                          }}
                          onClick={handleClose}
                        >
                          No
                        </Button>

                        <Button
                          className="w-[100px]"
                          onClick={handleYesOptionClick}
                        >
                          Yes
                        </Button>
                      </div>
                    </div>
                  </Dialog> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResumeDetails;
