import { css } from "@emotion/css";
import HamburgerMenu from "components/HamburgerMenu";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SocialButtons from "../Desktop/socialbuttons";
import ToggleButton from "../Desktop/toggle";
import TabCarousel from "./company_animation/App";
import "./index.css";
const MinWidth820AndMaxWidth12 = () => {
  const [isToggled, setIsToggled] = useState(false);
  const [currentSvg, setCurrentSvg] = useState(1);

  const toggleSVG = () => {
    setCurrentSvg((prev) => (prev < 10 ? prev + 1 : 1));
  };

  useEffect(() => {
    const interval = setInterval(toggleSVG, 2000);

    return () => clearInterval(interval);
  }, []);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [locationError, setLocationError] = useState(null);
  const [isInIndia, setIsInIndia] = useState(false);

  const isInIndiaCheck = (latitude, longitude) => {
    return (
      latitude >= 8.067 &&
      latitude <= 37.1 &&
      longitude >= 68.117 &&
      longitude <= 97.417
    );
  };

  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
            const inIndia = isInIndiaCheck(latitude, longitude);
            setIsInIndia(inIndia);
          },
          (error) => {
            setLocationError(error.message);
          }
        );
      } else {
        setLocationError("Geolocation is not supported by this browser.");
      }
    };

    getLocation();
  }, []); 

  const navigate = useNavigate();

  const onJobSeekerContainerClick = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  const onInterviewPrepContainerClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='careerAdviseContainer']",
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "nearest", behavior: "smooth" });
    }
  }, []);

  const onCareerAdviseContainerClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='autoJobApply']");
    if (anchor) {
      anchor.scrollIntoView({ block: "nearest", behavior: "smooth" });
    }
  }, []);

  const onAutoJobApplyContainerClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='Build&EnhanceResume']",
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "nearest", behavior: "smooth" });
    }
  }, []);

  const onJobSearchContainerClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='BuildCoverLetter']",
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "nearest", behavior: "smooth" });
    }
  }, []);

  const onBuildCoverLetterClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='Build&EnhanceResume']",
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "nearest", behavior: "smooth" });
    }
  }, []);

  const onCentralisedDbContainerClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='shortlistedProfilesContainer']",
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "nearest", behavior: "smooth" });
    }
  }, []);

  const onShortlistedProfilesContainerClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='sendOutResultContainer']",
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "nearest", behavior: "smooth" });
    }
  }, []);

  const onSendOutResultContainerClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='sendIntegrateEmailscontainer']",
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "nearest", behavior: "smooth" });
    }
  }, []);

  const onScheduleInterviewContainerClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='sendAutomatedAssessmentcontainer']",
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "nearest", behavior: "smooth" });
    }
  }, []);

  const onSendAutomatedContainerClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='sendIntegrateEmailscontainer']",
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "nearest", behavior: "smooth" });
    }
  }, []);

  const handleJobSeekersdemo = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='JobSeekersdemo']",
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const handlerecruiterdemo = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='recruiterdemo']",
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const [revstate, SetrevState] = useState(0);

  const reviewname = ["Srinivas", "Shrashti", "Somesh", "Jatin"];

  const reviewcompany = [
    "HR Manger, Culinda Inc.",
    "Data Scientist, Culinda Inc.",
    "ML Engg. Culinda Inc.",
    "ML Intern, Magix DB",
  ];

  const reviewcontent = [
    "We use Smart Recruiter for hiring for all positions in our company.️ Smart recruiter has cut down our cost for recruiting team. We don't need a recruiting team to schedule interviews and follow ups. We are able to hire people 10X faster.",
    "RecRoid revolutionized our hiring process, cutting costs and accelerating recruitment by 10x. No need for a dedicated recruiting team; it's all automated and streamlined.",
    "RecRoid is a game-changer. With its advanced automation, we've not only cut recruitment costs significantly but also managed to hire top-tier talent in record time. No more manual scheduling or follow-ups; the platform handles it all seamlessly. It's a win-win for our organization.",
    "RecRoid is a must-have for efficient hiring. Our hiring speed improved by an impressive 10x, and we've seen a remarkable reduction in hiring costs. Our hiring process is not just faster but also more cost-effective, making it an indispensable tool for any organization.",
  ];

  return (
    <div style={{ zoom: "85%" }}>
      <div
        className={css`
          position: relative;
          background-color: var(--color-white);
          width: 100%;
          height: 5700px;
          overflow-y: auto;
          text-align: center;
          font-size: var(--font-size-xl);
          color: var(--color-gray-200);
          font-family: var(--font-montserrat-alternates);
          &::-webkit-scrollbar {
            width: 0em;
          }

          &::-webkit-scrollbar-track {
            background: transparent;
          }

          &::-webkit-scrollbar-thumb {
            background: transparent;
          }
        `}
      >
        <div
          className={css`
            position: absolute;
            width: 100%;
            top: 0px;
            right: 0px;
            left: 0px;
            height: 80px;
            overflow: hidden;
            font-size: 17.81px;
            color: var(--color-gray-100);
            font-family: var(--font-poppins);
          `}
        >
          <div
            className={css`
              position: absolute;
              width: calc(100% + 2px);
              top: px;
              right: -2px;
              left: 0px;
              background-color: var(--color-white);
              height: 80px;
            `}
          >
            <div
              className={css`
                position: absolute;
                top: 5px;
                left: 22px;
                width: 208.3px;
                height: 70px;
              `}
            >
              <div
                className={css`
                  position: absolute;
                  top: 35.2px;
                  left: 53.2px;
                  width: 203px;
                  height: 53px;
                  overflow: hidden;
                  font-size: 20.5px;
                `}
              >
                <div
                  className={css`
                    position: absolute;
                    top: 6.5px;
                    left: 10px;
                    line-height: 135.5%;
                    font-weight: 600;
                    color: rgb(49 76 163);
                  `}
                >
                  RecRoid
                </div>
              </div>
              <div
                className={css`
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  width: 67px;
                  height: 70px;
                  overflow: hidden;
                `}
              >
                <img
                  className={css`
                    position: absolute;
                    top: calc(50% - 29px);
                    left: calc(50% - 24.5px);
                    width: 53.5px;
                    height: 60.5px;
                    object-fit: cover;
                  `}
                  alt=""
                  src="/recroid.png"
                />
              </div>
            </div>
          </div>
          <HamburgerMenu />
        </div>
        <div
          className={css`
            position: absolute;
            width: 100%;
            top: 80px;
            right: 0px;
            left: 0px;
            // border-radius: 0px 0px var(--br-13xl-5) var(--br-13xl-5);
            border-radius: 0px 0px 32.5px 32.5px;
             background: linear-gradient(305deg,#ffffff 0%, rgba(190, 225, 236, 0.63)  46.49%, rgba(190, 225, 236, 0.63) 56.47%, rgba(5, 240, 253, 0.00) 121.12%);
            height: 459px;
            overflow: hidden;
            font-size: var(--font-size-13xl);
          `}
        >
          <div
            className={css`
              position: absolute;
              top: 165px;
              left: calc(50% - 148px);
              overflow: hidden;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              padding: 1.074803113937378px 5.374015808105469px;
            `}
          >
            <img
              className={css`
                position: relative;
                width: 284.8px;
                height: 270.9px;
                object-fit: cover;
              `}
              alt=""
              src="Opening_page_gif.gif"
            />
          </div>
          <div
            className={css`
              position: absolute;
              top: 28px;
              left: calc(50% - 264px);
              width: 513px;
              height: 59px;
            `}
          >
            <div
              className={css`
                position: absolute;
                top: 0px;
                left: calc(50% - 256.5px);
                width: 513px;
                height: 59px;
                overflow: hidden;
              `}
            >
              <b
                className={css`
                  position: absolute;
                  top: 4px;
                  left: calc(50% - 252.5px);
                  line-height: 25.01px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 511px;
                `}
              >
                Democratizing Recruitment
              </b>
            </div>
          </div>
          <div
            className={css`
              position: absolute;
              top: 12px;
              left: calc(50% - 264px);
              width: 528px;
              height: 219px;
              overflow: hidden;
              font-size: 29.08px;
              color: var(--color-indianred);
              font-family: var(--font-inter);
            `}
          >
            <b
              className={css`
                position: absolute;
                top: 39.1px;
                left: 19.5px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 493px;
                height: 134.9px;
              `}
            >
              <div>
                {currentSvg === 1 && (
                  <div className="svg-container-tab">
                    <img
                      className="animated-svg"
                      src="images/text_resume_builder.svg"
                      alt="resume"
                    />
                  </div>
                )}

                {currentSvg === 2 && (
                  <div className="svg-container-tab">
                    <img
                      className="animated-svg"
                      src="images/text_cover_letter.svg"
                      alt="resume"
                    />
                  </div>
                )}

                {currentSvg === 3 && (
                  <div className="svg-container-tab">
                    <img
                      className="animated-svg"
                      src="images/text_job_search.svg"
                      alt="resume"
                    />
                  </div>
                )}

                {currentSvg === 4 && (
                  <div className="svg-container-tab">
                    <img
                      className="animated-svg"
                      src="images/text_interview_prep.svg"
                      alt="resume"
                    />
                  </div>
                )}
                {currentSvg === 5 && (
                  <div className="svg-container-tab">
                    <img
                      className="animated-svg"
                      src="images/text_career_advisor.svg"
                      alt="resume"
                    />
                  </div>
                )}
                {currentSvg === 6 && (
                  <div className="svg-container-tab">
                    <img
                      className="animated-svg"
                      src="images/text_auto_job_apply.svg"
                      alt="resume"
                    />
                  </div>
                )}
                {currentSvg === 7 && (
                  <div className="svg-container-tab">
                    <img
                      className="animated-svg"
                      src="images/text_free_candidates_db.svg"
                      alt="resume"
                    />
                  </div>
                )}
                {currentSvg === 8 && (
                  <div className="svg-container-tab">
                    <img
                      className="animated-svg"
                      src="images/text_automated_assessments.svg"
                      alt="resume"
                    />
                  </div>
                )}
                {currentSvg === 9 && (
                  <div className="svg-container-tab">
                    <img
                      className="animated-svg"
                      src="images/text_auto_profile_shortlisting.svg"
                      alt="resume"
                    />
                  </div>
                )}
                {currentSvg === 10 && (
                  <div className="svg-container-tab">
                    <img
                      className="animated-svg"
                      src="images/text_auto_interview_scheduler.svg"
                      alt="resume"
                    />
                  </div>
                )}
              </div>
            </b>
          </div>
        </div>
        <div
          className={css`
            margin: 1rem;
            position: absolute;
            width: 100%;
            top: 539px;
            right: 0px;
            left: 0px;
            height: 464px;
            overflow: hidden;
            font-size: 29.61px;
          `}
        >
          <div
            id="scroll-to-products"
            className={css`
              position: absolute;
              top: 31.4px;
              left: calc(50% - 105px);
              line-height: 14.39px;
              font-weight: 600;
            `}
          >
            For Job Seekers
          </div>
          <div
            className={css`
              position: absolute;
              top: 92px;
              left: calc(50% - 309px);
              width: 620px;
              height: 360px;
              // overflow-x: auto;
              overflow-y: hidden;
              font-size: var(--font-size-base);
              ::-webkit-scrollbar {
                display: none;
              }
            `}
          >
            <div
              className={css`
                position: absolute;
                top: 0px;
                left: 0px;
                border-radius: 11.84px;
                background-color: var(--color-ghostwhite);
                width: 280px;
                height: 280px;
                overflow-x: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                padding: 2.368647813796997px 7.500718116760254px;
                box-sizing: border-box;
              `}
            >
              <div
                className={css`
                  margin: 0 !important;
                  position: absolute;
                  top: 155px;
                  left: calc(50% - 51px);
                  height: 101px;
                  overflow: hidden;
                  flex-shrink: 0;
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                  justify-content: flex-start;
                  padding: 6.047903537750244px 12.700597763061523px;
                  box-sizing: border-box;
                  z-index: 0;
                `}
              >
                <img
                  className={css`
                    position: relative;
                    width: 75.6px;
                    height: 87.1px;
                    object-fit: cover;
                  `}
                  alt=""
                  src="/resume-builder-image@2x.png"
                />
              </div>
              <div
                className={css`
                  margin: 0 !important;
                  position: absolute;
                  top: 22px;
                  left: calc(50% - 132px);
                  width: 263px;
                  height: 118px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  padding: 11.053689956665039px 1.5790985822677612px
                    13.027563095092773px;
                  box-sizing: border-box;
                  gap: 24.08px;
                  z-index: 1;
                `}
                data-scroll-to="Build&EnhanceResume"
              >
                <div
                  className={css`
                    position: relative;
                    line-height: 10.37px;
                    font-weight: 500;
                  `}
                >{`Build & Enahnce Resume`}</div>
                <div
                  className={css`
                    position: relative;
                    font-size: var(--font-size-sm);
                    line-height: 15.72px;
                    font-weight: 500;
                    font-family: var(--font-dm-sans);
                    color: var(--color-darkslategray-100);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 259px;
                  `}
                >
                  Create a personalized resume on a professional templates.
                  Build from scratch or various sources. Enahnce Resume using AI
                </div>
              </div>
            </div>
            <div
              className={css`
                position: absolute;
                top: 0px;
                left: 340px;
                border-radius: 11.84px;
                // background-color: var(--color-lightcyan);
                background-color: var(--color-ghostwhite);
                // background-color: #CAF5FA;

                width: 280px;
                height: 280px;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                padding: 2.368647813796997px 7.500718116760254px;
                box-sizing: border-box;
                cursor: pointer;
              `}
              onClick={onInterviewPrepContainerClick}
              data-scroll-to="interviewprepcontainer"
            >
              <div
                className={css`
                  margin: 0 !important;
                  position: absolute;
                  top: 155px;
                  left: calc(50% - 51px);
                  height: 101px;
                  overflow: hidden;
                  flex-shrink: 0;
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                  justify-content: flex-start;
                  padding: 0px 11.314813613891602px;
                  box-sizing: border-box;
                  z-index: 0;
                `}
              >
                <img
                  className={css`
                    position: relative;
                    width: 79.2px;
                    height: 94px;
                    object-fit: cover;
                  `}
                  alt=""
                  src="/interview-prep-image@2x.png"
                />
              </div>
              <div
                className={css`
                  margin: 0 !important;
                  position: absolute;
                  top: 22px;
                  left: calc(50% - 132px);
                  width: 263px;
                  height: 118px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  padding: 11.053689956665039px 1.5790985822677612px
                    13.027563095092773px;
                  box-sizing: border-box;
                  gap: 24.08px;
                  z-index: 1;
                `}
              >
                <div
                  className={css`
                    position: relative;
                    line-height: 10.37px;
                    font-weight: 500;
                  `}
                >
                  Interview Preparation
                </div>
                <div
                  className={css`
                    position: relative;
                    font-size: var(--font-size-sm);
                    line-height: 15.72px;
                    font-weight: 500;
                    font-family: var(--font-dm-sans);
                    color: var(--color-darkslategray-100);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 259px;
                  `}
                >{`Prepare for live interviews for jobs and academics and get a detailed analysis on your performance of your content, language & body language.`}</div>
              </div>
            </div>
            <div
              className={css`
                position: absolute;
                top: 0px;
                left: 680px;
                border-radius: 11.84px;
                // background-color: var(--color-ghostwhite);
                background-color: #caf5fa;

                width: 280px;
                height: 280px;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                padding: 2.368647813796997px 7.500718116760254px;
                box-sizing: border-box;
              `}
              onClick={onBuildCoverLetterClick}
            >
              <div
                className={css`
                  margin: 0 !important;
                  position: absolute;
                  top: 155px;
                  left: calc(50% - 51px);
                  height: 101px;
                  overflow: h den;
                  flex-shrink: 0;
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                  justify-content: flex-start;
                  z-index: 0;
                `}
              // onClick={onBuildCoverLetterClick}
              >
                <img
                  className={css`
                    position: relative;
                    width: 101.4px;
                    height: 103px;
                    object-fit: cover;
                  `}
                  alt=""
                  src="/cover-letter-image@2x.png"
                />
              </div>
              <div
                className={css`
                  margin: 0 !important;
                  position: absolute;
                  top: 22px;
                  left: calc(50% - 132px);
                  width: 263px;
                  height: 118px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  padding: 11.053689956665039px 1.5790985822677612px
                    13.027563095092773px;
                  box-sizing: border-box;
                  gap: 24.08px;
                  z-index: 1;
                `}
                data-scroll-to="BuildCoverLetter"
              // onClick={onBuildCoverLetterClick}
              >
                <div
                  className={css`
                    position: relative;
                    line-height: 10.37px;
                    font-weight: 500;
                  `}
                >
                  Build Cover Letter
                </div>
                <div
                  className={css`
                    position: relative;
                    font-size: var(--font-size-sm);
                    line-height: 15.72px;
                    font-weight: 500;
                    font-family: var(--font-dm-sans);
                    color: var(--color-darkslategray-100);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 259px;
                  `}
                >
                  Craft compelling cover letter with our AI powered technology.
                </div>
              </div>
            </div>
            <div
              className={css`
                position: absolute;
                top: 0px;
                left: 1020px;
                border-radius: 11.84px;
                // background-color: var(--color-lightcyan);
                background-color: #caf5fa;

                width: 280px;
                height: 280px;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                padding: 2.368647813796997px 7.500718116760254px;
                box-sizing: border-box;
                cursor: pointer;
              `}
              data-scroll-to="careerAdviseContainer"
              onClick={onCareerAdviseContainerClick}
            >
              <div
                className={css`
                  position: absolute;
                  margin: 0 !important;
                  top: 155px;
                  left: calc(50% - 51px);
                  width: 101.3px;
                  height: 101px;
                  overflow: hidden;
                  flex-shrink: 0;
                  z-index: 0;
                `}
              >
                <img
                  className={css`
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 101px;
                    height: 101px;
                    object-fit: cover;
                  `}
                  alt=""
                  src="/career-advise-image@2x.png"
                />
              </div>
              <div
                className={css`
                  margin: 0 !important;
                  position: absolute;
                  top: 22px;
                  left: calc(50% - 132px);
                  width: 263px;
                  height: 118px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  padding: 11.053689956665039px 1.5790985822677612px
                    13.027563095092773px;
                  box-sizing: border-box;
                  gap: 24.08px;
                  z-index: 1;
                `}
              >
                <div
                  className={css`
                    position: relative;
                    line-height: 10.37px;
                    font-weight: 500;
                  `}
                >
                  Career Advise
                </div>
                <div
                  className={css`
                    position: relative;
                    font-size: var(--font-size-sm);
                    line-height: 15.72px;
                    font-weight: 500;
                    font-family: var(--font-dm-sans);
                    color: var(--color-darkslategray-100);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 259px;
                  `}
                >{`Get career advise with jobs, research &college lists along with salary, stipend & expenses from our highly curated database with an AI powered counsellor.`}</div>
              </div>
            </div>
            <div
              className={css`
                position: absolute;
                top: 0px;
                left: 1360px;
                border-radius: 11.84px;
                background-color: var(--color-ghostwhite);

                width: 280px;
                height: 280px;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                padding: 2.368647813796997px 7.500718116760254px;
                box-sizing: border-box;
              `}
            >
              <div
                className={css`
                  margin: 0 !important;
                  position: absolute;
                  top: 154.8px;
                  left: calc(50% - 51px);
                  width: 101px;
                  height: 101px;
                  overflow: hidden;
                  flex-shrink: 0;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  padding: 11.342339515686035px 8.506755828857422px;
                  box-sizing: border-box;
                  z-index: 0;
                `}
                onClick={onJobSearchContainerClick}
              >
                <img
                  className={css`
                    position: relative;
                    width: 81.5px;
                    height: 80.8px;
                    object-fit: cover;
                  `}
                  alt=""
                  src="/job-search-image@2x.png"
                  onClick={onJobSearchContainerClick}
                />
              </div>
              <div
                className={css`
                  margin: 0 !important;
                  position: absolute;
                  top: 22px;
                  left: calc(50% - 132px);
                  width: 263px;
                  height: 118px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  padding: 11.053689956665039px 1.5790985822677612px
                    13.027563095092773px;
                  box-sizing: border-box;
                  gap: 24.08px;
                  z-index: 1;
                `}
                onClick={onJobSearchContainerClick}
              >
                <div
                  className={css`
                    position: relative;
                    line-height: 10.37px;
                    font-weight: 500;
                  `}
                >
                  Job Search
                </div>
                <div
                  className={css`
                    position: relative;
                    font-size: var(--font-size-sm);
                    line-height: 15.72px;
                    font-weight: 500;
                    font-family: var(--font-dm-sans);
                    color: var(--color-darkslategray-100);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 259px;
                  `}
                >
                  Look for jobs on 50 plus platforms and apply directly. Get
                  access to undisclosed jobs in market too.
                </div>
              </div>
            </div>
            <div
              className={css`
                position: absolute;
                top: 0px;
                left: 1700px;
                border-radius: 11.84px;
                // background-color: var(--color-lightcyan);
                background-color: var(--color-ghostwhite);

                width: 280px;
                height: 280px;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                padding: 2.368647813796997px 7.500718116760254px;
                box-sizing: border-box;
              `}
              data-scroll-to="autoJobApply"
              onClick={onAutoJobApplyContainerClick}
            >
              <div
                className={css`
                  margin: 0 !important;
                  position: absolute;
                  top: 155px;
                  left: calc(50% - 51px);
                  width: 101px;
                  height: 101px;
                  overflow: hidden;
                  flex-shrink: 0;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: flex-start;
                  padding: 0px 7.499999523162842px;
                  box-sizing: border-box;
                  z-index: 0;
                `}
              >
                <img
                  className={css`
                    position: absolute;
                    margin: 0 !important;
                    // top: 93px;
                    // left: 94px;
                    // width: 87px;
                    height: 84px;
                    object-fit: cover;
                    z-index: 0;
                  `}
                  alt=""
                  src="/autojob-apply-image@2x.png"
                />
              </div>
              <div
                className={css`
                  margin: 0 !important;
                  position: absolute;
                  top: 22px;
                  left: calc(50% - 132px);
                  width: 263px;
                  height: 118px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  padding: 11.053689956665039px 1.5790985822677612px
                    13.027563095092773px;
                  box-sizing: border-box;
                  gap: 24.08px;
                  z-index: 1;
                `}
              >
                <div
                  className={css`
                    position: relative;
                    line-height: 10.37px;
                    font-weight: 500;
                  `}
                >
                  Auto Job Apply
                </div>
                <div
                  className={css`
                    position: relative;
                    font-size: var(--font-size-sm);
                    line-height: 15.72px;
                    font-weight: 500;
                    font-family: var(--font-dm-sans);
                    color: var(--color-darkslategray-100);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 259px;
                  `}
                >
                  Relax, while our bots apply for relevant jobs for you to our
                  20+ auto jobs partners.
                </div>
              </div>
            </div>
            <div
              className={css`
                position: absolute;
                top: 309px;
                left: 243px;
                // border-radius: var(--br-45xl);
                // background-color: var(--color-gainsboro-100);
                // width: 134px;
                // height: 16px;
                overflow: hidden;
              `}
            >
              <svg
                width="100"
                height="16"
                viewBox="0 0 100 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="42" width="16" height="16" rx="8" fill="grey" />
                <rect width="16" height="16" rx="8" fill="#0095FF" />
                <rect x="84" width="16" height="16" rx="8" fill="grey" />
              </svg>
            </div>

            <div
              className={css`
                position: absolute;
                top: 309px;
                left: 924px;
                // border-radius: var(--br-45xl);
                // background-color: var(--color-gainsboro-100);
                // width: 134px;
                // height: 16px;
                overflow: hidden;
              `}
            >
              <svg
                width="100"
                height="16"
                viewBox="0 0 100 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="42" width="16" height="16" rx="8" fill="#0095FF" />
                <rect width="16" height="16" rx="8" fill="grey" />
                <rect x="84" width="16" height="16" rx="8" fill="grey" />
              </svg>
            </div>

            <div
              className={css`
                position: absolute;
                top: 309px;
                left: 1629px;
                // border-radius: var(--br-45xl);
                // background-color: var(--color-gainsboro-100);
                // width: 134px;
                // height: 16px;
                overflow: hidden;
              `}
            >
              <svg
                width="100"
                height="16"
                viewBox="0 0 100 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="42" width="16" height="16" rx="8" fill="grey" />
                <rect width="16" height="16" rx="8" fill="grey" />
                <rect x="84" width="16" height="16" rx="8" fill="#0095FF" />
              </svg>
            </div>
          </div>
        </div>
        <div
          className={css`
            position: absolute;
            width: calc(100% - 0.2px);
            top: 1003.5px;
            right: 0.2px;
            left: 0px;
            height: 443.1px;
            overflow: hidden;
            font-size: 29.61px;
          `}
        >
          <div
            id="Recruiters"
            className={css`
              position: absolute;
              top: 18.2px;
              left: calc(50% - 105.9px);
              line-height: 14.39px;
              font-weight: 600;
            `}
          >
            For Recruiters
          </div>
          <div
            className={css`
              position: absolute;
              top: 90.5px;
              left: calc(50% - 292px);
              width: 620px;
              height: 360px;
              // overflow-x: auto;
              overflow-y: hidden;
              ::-webkit-scrollbar {
                display: none;
              }
              font-size: var(--font-size-base);
            `}
          >
            <div
              className={css`
                position: absolute;
                top: 0px;
                left: 0px;
                border-radius: 11.84px;
                // background-color: var(--color-lightcyan);
                background-color: #caf5fa;

                width: 280px;
                height: 280px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                padding: 2.368647813796997px 7.500718116760254px;
                box-sizing: border-box;
              `}
              data-scroll-to="sendIntegrateEmailscontainer"
            >
              <div
                className={css`
                  position: absolute;
                  margin: 0 !important;
                  top: 155px;
                  left: calc(50% - 61px);
                  width: 122.2px;
                  height: 98px;
                  z-index: 0;
                `}
              >
                <img
                  className={css`
                    position: absolute;
                    top: 17.9px;
                    left: 13.4px;
                    width: 94.4px;
                    height: 62.1px;
                    object-fit: cover;
                  `}
                  alt=""
                  src="/integrate-emails-container@2x.png"
                />
              </div>
              <div
                className={css`
                  margin: 0 !important;
                  position: absolute;
                  top: 22px;
                  left: calc(50% - 132px);
                  width: 263px;
                  height: 118px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  padding: 11.053689956665039px 1.5790985822677612px
                    13.027563095092773px;
                  box-sizing: border-box;
                  gap: 24.08px;
                  z-index: 1;
                `}
              >
                <div
                  className={css`
                    position: relative;
                    line-height: 10.37px;
                    font-weight: 500;
                  `}
                >
                  Integrate Emails
                </div>
                <div
                  className={css`
                    position: relative;
                    font-size: var(--font-size-sm);
                    line-height: 15.72px;
                    font-weight: 500;
                    font-family: var(--font-dm-sans);
                    color: var(--color-darkslategray-100);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 259px;
                  `}
                >
                  Integrate Outlook, G-suite or any other professional email box
                  where you receive candidates profiles.
                </div>
              </div>
            </div>
            <div
              className={css`
                position: absolute;
                top: 0px;
                left: 340px;
                border-radius: 11.84px;
                // background-color: var(--color-ghostwhite);
                background-color: #caf5fa;
                width: 280px;
                height: 280px;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                padding: 2.368647813796997px 7.500718116760254px;
                box-sizing: border-box;
                cursor: pointer;
              `}
              onClick={onCentralisedDbContainerClick}
            >
              <div
                className={css`
                  position: absolute;
                  margin: 0 !important;
                  top: 155px;
                  left: calc(50% - 78px);
                  width: 156px;
                  height: 98px;
                  overflow: hidden;
                  flex-shrink: 0;
                  z-index: 0;
                `}
              >
                <img
                  className={css`
                    position: absolute;
                    top: 4px;
                    left: 14px;
                    width: 138px;
                    height: 92px;
                    object-fit: cover;
                  `}
                  alt=""
                  src="/centralised-database-image@2x.png"
                />
              </div>
              <div
                className={css`
                  margin: 0 !important;
                  position: absolute;
                  top: 22px;
                  left: calc(50% - 132px);
                  width: 263px;
                  height: 118px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  padding: 11.053689956665039px 1.5790985822677612px
                    13.027563095092773px;
                  box-sizing: border-box;
                  gap: 24.08px;
                  z-index: 1;
                `}
              >
                <div
                  className={css`
                    position: relative;
                    line-height: 10.37px;
                    font-weight: 500;
                  `}
                >
                  Centralised database
                </div>
                <div
                  className={css`
                    position: relative;
                    font-size: var(--font-size-sm);
                    line-height: 15.72px;
                    font-weight: 500;
                    font-family: var(--font-dm-sans);
                    color: var(--color-darkslategray-100);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 259px;
                  `}
                >
                  Maintain candidates resume, cover letters, their performance
                  on assignments and interviews.
                </div>
              </div>
            </div>
            <div
              className={css`
                position: absolute;
                top: 0px;
                left: 680px;
                border-radius: 11.84px;
                background-color: var(--color-ghostwhite);
                width: 280px;
                height: 280px;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                padding: 2.368647813796997px 7.500718116760254px;
                box-sizing: border-box;
              `}
            >
              <div
                className={css`
                  position: absolute;
                  margin: 0 !important;
                  top: 160px;
                  left: calc(50% - 61px);
                  width: 122.1px;
                  height: 97px;
                  z-index: 0;
                `}
                data-scroll-to="sendAutomatedAssessments"
              >
                <img
                  className={css`
                    position: absolute;
                    top: 12.7px;
                    left: 8.9px;
                    width: 101.3px;
                    height: 70.4px;
                    object-fit: cover;
                  `}
                  alt=""
                  src="/sends-assesment-image@2x.png"
                />
              </div>
              <div
                className={css`
                  margin: 0 !important;
                  position: absolute;
                  top: 22px;
                  left: calc(50% - 132px);
                  width: 263px;
                  height: 118px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  padding: 11.053689956665039px 1.5790985822677612px
                    13.027563095092773px;
                  box-sizing: border-box;
                  gap: 24.08px;
                  z-index: 1;
                `}
                data-scroll-to="sendAutomatedAssessmentcontainer"
                onClick={onSendAutomatedContainerClick}
              >
                <div
                  className={css`
                    position: relative;
                    line-height: 10.37px;
                    font-weight: 500;
                  `}
                >
                  Send Automated Assessments
                </div>
                <div
                  className={css`
                    position: relative;
                    font-size: var(--font-size-sm);
                    line-height: 15.72px;
                    font-weight: 500;
                    font-family: var(--font-dm-sans);
                    color: var(--color-darkslategray-100);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 259px;
                  `}
                >
                  Once the portfolio is shortlisted the smart Recruiter send an
                  assessment to the candidate for first round of test.
                </div>
              </div>
            </div>
            <div
              className={css`
                position: absolute;
                top: 0px;
                left: 1020px;
                border-radius: 11.84px;
                background-color: var(--color-ghostwhite);
                width: 280px;
                height: 280px;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                padding: 2.368647813796997px 7.500718116760254px;
                box-sizing: border-box;
                cursor: pointer;
              `}
              data-scroll-to="shortlistedProfilesContainer"
              onClick={onShortlistedProfilesContainerClick}
            >
              <div
                className={css`
                  position: absolute;
                  margin: 0 !important;
                  top: 155px;
                  left: calc(50% - 62px);
                  width: 125px;
                  height: 95px;
                  z-index: 0;
                `}
              >
                <img
                  className={css`
                    position: absolute;
                    top: 9.1px;
                    left: 13.7px;
                    width: 99.8px;
                    height: 79.7px;
                    object-fit: cover;
                  `}
                  alt=""
                  src="/shortlist-profiles-image@2x.png"
                />
              </div>
              <div
                className={css`
                  margin: 0 !important;
                  position: absolute;
                  top: 22px;
                  left: calc(50% - 132px);
                  width: 263px;
                  height: 118px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  padding: 11.053689956665039px 1.5790985822677612px
                    13.027563095092773px;
                  box-sizing: border-box;
                  gap: 24.08px;
                  z-index: 1;
                `}
              >
                <div
                  className={css`
                    position: relative;
                    line-height: 10.37px;
                    font-weight: 500;
                  `}
                >
                  Shortlisted Profiles
                </div>
                <div
                  className={css`
                    position: relative;
                    font-size: var(--font-size-sm);
                    line-height: 15.72px;
                    font-weight: 500;
                    font-family: var(--font-dm-sans);
                    color: var(--color-darkslategray-100);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 259px;
                  `}
                >
                  Profiles are shortlisted based on the performance of the
                  candidate on the assessment. Flexibility to set the exam
                  cutoff.
                </div>
              </div>
            </div>
            <div
              className={css`
                position: absolute;
                top: 0px;
                left: 1360px;
                border-radius: 11.84px;
                // background-color: var(--color-lightcyan);
                background-color: #caf5fa;
                width: 280px;
                height: 280px;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                padding: 2.368647813796997px 7.500718116760254px;
                box-sizing: border-box;
              `}
            >
              <div
                className={css`
                  position: absolute;
                  margin: 0 !important;
                  top: 155px;
                  left: calc(50% - 61px);
                  width: 122px;
                  height: 108.8px;
                  z-index: 0;
                `}
              >
                <img
                  className={css`
                    position: absolute;
                    top: 17.1px;
                    left: 6.5px;
                    width: 108.9px;
                    height: 74.7px;
                    object-fit: cover;
                  `}
                  alt=""
                  src="/schedule-interview-image@2x.png"
                />
              </div>
              <div
                className={css`
                  margin: 0 !important;
                  position: absolute;
                  top: 22px;
                  left: calc(50% - 132px);
                  width: 263px;
                  height: 118px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  padding: 11.053689956665039px 1.5790985822677612px
                    13.027563095092773px;
                  box-sizing: border-box;
                  gap: 24.08px;
                  z-index: 1;
                `}
                data-scroll-to="scheduleInterviewContainer"
                onClick={onScheduleInterviewContainerClick}
              >
                <div
                  className={css`
                    position: relative;
                    line-height: 10.37px;
                    font-weight: 500;
                  `}
                >
                  Schedule Interviews
                </div>
                <div
                  className={css`
                    position: relative;
                    font-size: var(--font-size-sm);
                    line-height: 15.72px;
                    font-weight: 500;
                    font-family: var(--font-dm-sans);
                    color: var(--color-darkslategray-100);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 259px;
                  `}
                >{`Interviewers profiles can be added & interviews are scheduled through mails automatically, after matching the available slots of the interviewers & the candidates.`}</div>
              </div>
            </div>
            <div
              className={css`
                position: absolute;
                top: 0px;
                left: 1700px;
                border-radius: 11.84px;
                // background-color: var(--color-ghostwhite);
                background-color: #caf5fa;
                width: 280px;
                height: 280px;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                padding: 2.368647813796997px 7.500718116760254px;
                box-sizing: border-box;
              `}
              data-scroll-to="sendOutResultContainer"
              onClick={onSendOutResultContainerClick}
            >
              <div
                className={css`
                  position: absolute;
                  margin: 0 !important;
                  top: 155px;
                  left: calc(50% - 61px);
                  width: 121.9px;
                  height: 85px;
                  z-index: 0;
                `}
                onClick={onSendOutResultContainerClick}
              >
                <img
                  className={css`
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 122.4px;
                    height: 85.1px;
                    object-fit: cover;
                  `}
                  alt=""
                  src="/send-results-image@2x.png"
                />
              </div>
              <div
                className={css`
                  margin: 0 !important;
                  position: absolute;
                  top: 22px;
                  left: calc(50% - 132px);
                  width: 263px;
                  height: 118px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  padding: 11.053689956665039px 1.5790985822677612px
                    13.027563095092773px;
                  box-sizing: border-box;
                  gap: 24.08px;
                  z-index: 1;
                `}
              >
                <div
                  className={css`
                    position: relative;
                    line-height: 10.37px;
                    font-weight: 500;
                  `}
                >
                  Send Out Results
                </div>
                <div
                  className={css`
                    position: relative;
                    font-size: var(--font-size-sm);
                    line-height: 15.72px;
                    font-weight: 500;
                    font-family: var(--font-dm-sans);
                    color: var(--color-darkslategray-100);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 259px;
                  `}
                >
                  After the feedback from the interviewers the feedback is
                  mailed to candidates.
                </div>
              </div>
            </div>
            <div
              className={css`
                position: absolute;
                top: 309px;
                left: 247px;
                // border-radius: var(--br-45xl);
                // background-color: var(--color-gainsboro-100);
                // width: 134px;
                // height: 16px;
                overflow: hidden;
              `}
            >
              <svg
                width="100"
                height="16"
                viewBox="0 0 100 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="42" width="16" height="16" rx="8" fill="#808080" />
                <rect width="16" height="16" rx="8" fill="#0095FF" />
                <rect x="84" width="16" height="16" rx="8" fill="#808080" />
              </svg>
            </div>

            <div
              className={css`
                position: absolute;
                top: 309px;
                left: 923px;
                // border-radius: var(--br-45xl);
                // background-color: var(--color-gainsboro-100);
                // width: 134px;
                // height: 16px;
                overflow: hidden;
              `}
            >
              <svg
                width="100"
                height="16"
                viewBox="0 0 100 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="42" width="16" height="16" rx="8" fill="#0095FF" />
                <rect width="16" height="16" rx="8" fill="#808080" />
                <rect x="84" width="16" height="16" rx="8" fill="#808080" />
              </svg>
            </div>

            <div
              className={css`
                position: absolute;
                top: 309px;
                left: 1609px;
                // border-radius: var(--br-45xl);
                // background-color: var(--color-gainsboro-100);
                // width: 134px;
                // height: 16px;
                overflow: hidden;
              `}
            >
              <svg
                width="100"
                height="16"
                viewBox="0 0 100 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="42" width="16" height="16" rx="8" fill="#808080" />
                <rect width="16" height="16" rx="8" fill="#808080" />
                <rect x="84" width="16" height="16" rx="8" fill="#0095FF" />
              </svg>
            </div>
          </div>
        </div>
        <div
          className={css`
            position: absolute;
            width: 100%;
            top: 1447px;
            right: 0px;
            left: 0px;
            height: 729px;
            overflow: hidden;
            text-align: left;
            color: var(--color-darkslategray-100);
            font-family: var(--font-dm-sans);
          `}
        >
          <div
            className={css`
              position: absolute;
              top: 16.2px;
              left: calc(50% - 282.9px);
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              justify-content: flex-start;
            `}
          >
            <img
              className={css`
                position: relative;
                width: 30.8px;
                height: 30.8px;
                overflow: hidden;
                flex-shrink: 0;
                object-fit: cover;
              `}
              alt=""
              src="/purpleball.png"
            />
          </div>
          <div
            className={css`
              position: absolute;
              top: 223px;
              left: calc(50% - 380px);
              width: 760px;
              height: 379px;
              // overflow: hidden;
              color: var(--color-gray-200);
            `}
          >
            <div
              className={css`
                position: absolute;
                top: 0px;
                left: calc(50% - 480px);
                border-radius: 2.92px;
                background-color: var(--color-white);
                border: 0.3px solid var(--color-gray-500);
                box-sizing: border-box;
                width: 330px;
                height: 379px;
              `}
            >
              <Link
                to="/login"
                className={css`
                  position: absolute;
                  top: 334.4px;
                  left: 20px;
                  border-radius: 51.42px;
                  background-color: var(--color-dodgerblue-100);
                  width: 143px;
                  height: 33.4px;
                  overflow: hidden;
                  text-align: center;
                  font-size: 16.15px;
                  color: var(--color-white);
                `}
              >
                <b
                  className={css`
                    position: absolute;
                    top: 9.3px;
                    left: calc(50% - 50.5px);
                    line-height: 15.42px;
                  `}
                >
                  {isToggled ? "Purchase Now" : "Start For Free"}
                </b>
              </Link>
              <div
                className={css`
                  position: absolute;
                  top: calc(50% - 158.5px);
                  left: calc(50% + 82px);
                  width: 45px;
                  height: 45px;
                `}
              >
                <div
                  className={css`
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: flex-start;
                  `}
                >
                  <img
                    className={css`
                      position: relative;
                      width: 45px;
                      height: 45px;
                      overflow: hidden;
                      flex-shrink: 0;
                    `}
                    alt=""
                    src="/comparepriceshapesvg.svg"
                  />
                </div>
                <div
                  className={css`
                    position: absolute;
                    top: calc(50% - 4.3px);
                    left: 16.7px;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: flex-start;
                  `}
                >
                  <img
                    className={css`
                      position: relative;
                      width: 11.5px;
                      height: 9.9px;
                      overflow: hidden;
                      flex-shrink: 0;
                    `}
                    alt=""
                    src="/crownsvg.svg"
                  />
                </div>
              </div>
              <div
                className={css`
                  position: absolute;
                  top: 48.1px;
                  left: 10px;
                  width: 236px;
                  height: 32.9px;
                  ${"" /* overflow: hidden; */}
                  font-family: var(--font-montserrat-alternates);
                `}
              >
                <b
                  className="strong-59"
                  style={{ top: "-9.5px", width: "236px", fontSize: "20.9px" }}
                >
                  {isInIndia ? (
                    isToggled ? (
                      <>
                        ₹ 7655 per year
                        <br />
                        (₹ 510 per month)
                      </>
                    ) : (
                      "₹ 799 per month"
                    )
                  ) : isToggled ? (
                    <>
                      $ 96 per year
                      <br />
                      ($ 8 per month)
                    </>
                  ) : (
                    "$ 9.99 per month"
                  )}
                </b>
              </div>
              <div
                className={css`
                  position: absolute;
                  top: 16px;
                  left: 24px;
                  line-height: 7.59px;
                  font-weight: 500;
                `}
              >
                For Job Seekers
              </div>
              <div
                className={css`
                  position: absolute;
                  top: calc(50% - 90.5px);
                  left: calc(50% - 156px);
                  width: 321px;
                  height: 208px;
                  overflow: hidden;
                  font-size: var(--font-size-lg);
                  color: var(--color-darkslategray-100);
                `}
              >
                <div
                  className={css`
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    line-height: 8.76px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    width: 286px;
                    height: 19px;
                  `}
                >
                  Unlimited Resume Builders
                </div>
                <div
                  className={css`
                    position: absolute;
                    top: 31px;
                    left: 0px;
                    line-height: 8.76px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    width: 286px;
                    height: 19px;
                  `}
                >
                  Unlimited Resume Enhancements
                </div>
                <div
                  className={css`
                    position: absolute;
                    top: 62px;
                    left: 0px;
                    line-height: 8.76px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    width: 286px;
                    height: 19px;
                  `}
                >
                  Unlimited Cover Letters
                </div>
                <div
                  className={css`
                    position: absolute;
                    top: 93px;
                    left: 0px;
                    line-height: 8.76px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    width: 286px;
                    height: 19px;
                  `}
                >
                  Unlimited automation templates
                </div>
                <div
                  className={css`
                    position: absolute;
                    top: 124px;
                    left: 0px;
                    line-height: 8.76px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    width: 321px;
                    height: 19px;
                  `}
                >
                  200mins of interview preparations
                </div>
                <div
                  className={css`
                    position: absolute;
                    top: 155px;
                    left: 0px;
                    line-height: 8.76px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    width: 286px;
                    height: 19px;
                  `}
                >
                  Unlimited job search
                </div>
                <div
                  className={css`
                    position: absolute;
                    top: 186px;
                    left: 0px;
                    line-height: 8.76px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    width: 286px;
                    height: 19px;
                  `}
                >
                  Unlimited auto job apply
                </div>
                <div
                  className={css`
                    position: absolute;
                    top: 217px;
                    left: 0px;
                    line-height: 8.76px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    width: 286px;
                    height: 19px;
                  `}
                >
                  Access to undisclosed jobs
                </div>
              </div>
            </div>
            <div
              className={css`
                position: absolute;
                top: 0px;
                left: calc(50% - 140px);
                border-radius: 2.92px;
                background-color: var(--color-white);
                border: 0.3px solid var(--color-gray-500);
                box-sizing: border-box;
                width: 330px;
                height: 379px;
                font-size: 18.14px;
              `}
            >
              <div
                className={css`
                  position: absolute;
                  top: calc(50% - 154.5px);
                  left: calc(50% + 84px);
                  width: 45px;
                  height: 45px;
                `}
              >
                <div
                  className={css`
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: flex-start;
                  `}
                >
                  <img
                    className={css`
                      position: relative;
                      width: 45px;
                      height: 45px;
                      overflow: hidden;
                      flex-shrink: 0;
                    `}
                    alt=""
                    src="/comparepriceshapesvg.svg"
                  />
                </div>
                <div
                  className={css`
                    position: absolute;
                    top: calc(50% - 4.3px);
                    left: 16.7px;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: flex-start;
                  `}
                >
                  <img
                    className={css`
                      position: relative;
                      width: 11.5px;
                      height: 9.9px;
                      overflow: hidden;
                      flex-shrink: 0;
                    `}
                    alt=""
                    src="/crownsvg.svg"
                  />
                </div>
              </div>
              <div
                className={css`
                  position: absolute;
                  // top: 42.6px;
                  top: 48.1px;
                  left: 10px;
                  width: 228px;
                  // left: 24px;
                  // width: 149px;
                  height: 29.8px;
                  ${"" /* overflow: hidden; */}
                  font-family: var(--font-montserrat-alternates);
                `}
              >
                <b className="strong-59" style={{ width: "233px" }}>
                  {isInIndia ? (
                    isToggled ? (
                      <>
                        ₹ 47280 per year
                        <br />
                        (₹ 3940 per month)
                      </>
                    ) : (
                      "₹ 4920 per month"
                    )
                  ) : isToggled ? (
                    <>
                      $ 566 per year
                      <br />
                      ($ 47.20 per month)
                    </>
                  ) : (
                    "$ 59 per month"
                  )}
                </b>
              </div>
              <div
                className={css`
                  position: absolute;
                  top: 16px;
                  left: 24px;
                  font-size: var(--font-size-xl);
                  line-height: 7.59px;
                  font-weight: 500;
                `}
              >
                For Recruiters
              </div>
              <div
                className={css`
                  position: absolute;
                  top: calc(50% - 92.5px);
                  left: calc(50% - 156px);
                  width: 314px;
                  height: 185px;
                  overflow: hidden;
                  font-size: var(--font-size-lg);
                  color: var(--color-darkslategray-100);
                `}
              >
                <div
                  className={css`
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    line-height: 8.76px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    width: 314px;
                    height: 19px;
                  `}
                >
                  Unlimited Resume Processing
                </div>
                <div
                  className={css`
                    position: absolute;
                    top: 31px;
                    left: 0px;
                    line-height: 8.76px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    width: 314px;
                    height: 19px;
                  `}
                >
                  Unlimited auto interview schedules
                </div>
                <div
                  className={css`
                    position: absolute;
                    top: 62px;
                    left: 0px;
                    line-height: 8.76px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    width: 314px;
                    height: 19px;
                  `}
                >
                  Free centralised databases
                </div>
                <div
                  className={css`
                    position: absolute;
                    top: 93px;
                    left: 0px;
                    line-height: 8.76px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    width: 314px;
                    height: 19px;
                  `}
                >
                  Access to 5K free candidate profiles
                </div>
                <div
                  className={css`
                    position: absolute;
                    top: 124px;
                    left: 0px;
                    line-height: 8.76px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    width: 314px;
                    height: 19px;
                  `}
                >
                  Great customer support
                </div>
                <div
                  className={css`
                    position: absolute;
                    top: 155px;
                    left: 0px;
                    line-height: 8.76px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    width: 314px;
                    height: 19px;
                  `}
                >{`Unlimited job uploads & shortlisting`}</div>
              </div>
              <div
                className={css`
                  position: absolute;
                  top: 334px;
                  left:19px;
                  border-radius: 51.42px;
                  background-color: var(--color-dodgerblue-100);
                  width: 143px;
                  height: 33.4px;
                  overflow: hidden;
                  text-align: center;
                  font-size: 16.15px;
                  color: var(--color-white);
                `}
              >
                <b
                  className={css`
                    position: absolute;
                    top: 9.3px;
                    left: calc(50% - 50.5px);
                    line-height: 15.42px;
                  `}
                >
                  {/* {isToggled ? "Purchase Now" : "Start For Free"} */}
                  Coming Soon
                </b>
              </div>
            </div>
            <div
              className={css`
                position: absolute;
                top: 0px;
                left: calc(50% + 200px);
                border-radius: 2.92px;
                background-color: var(--color-white);
                border: 0.3px solid var(--color-gray-500);
                box-sizing: border-box;
                width: 330px;
                height: 379px;
                font-size: 18.14px;
              `}
            >
              <div
                className={css`
                  position: absolute;
                  top: calc(50% - 154.5px);
                  left: calc(50% + 84px);
                  width: 45px;
                  height: 45px;
                `}
              >
                <div
                  className={css`
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: flex-start;
                  `}
                >
                  <img
                    className={css`
                      position: relative;
                      width: 45px;
                      height: 45px;
                      overflow: hidden;
                      flex-shrink: 0;
                    `}
                    alt=""
                    src="/comparepriceshapesvg.svg"
                  />
                </div>
                <div
                  className={css`
                    position: absolute;
                    top: calc(50% - 4.3px);
                    left: 16.7px;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: flex-start;
                  `}
                >
                  <img
                    className={css`
                      position: relative;
                      width: 11.5px;
                      height: 9.9px;
                      overflow: hidden;
                      flex-shrink: 0;
                    `}
                    alt=""
                    src="/crownsvg.svg"
                  />
                </div>
              </div>
              <div
                className={css`
                  position: absolute;
                  // top: 42.6px;
                  top: 48.1px;
                  left: 10px;
                  width: 228px;
                  // left: 24px;
                  // width: 149px;
                  height: 29.8px;
                  ${'' /* overflow: hidden; */}
                  font-family: var(--font-montserrat-alternates);
                `}
              >
                <b className="strong-59" style={{ width: "233px" }}>
                  {isInIndia ? (
                    isToggled ? (
                      <>
                        ₹ 47280 per year
                        <br />
                        (₹ 3940 per month)
                      </>
                    ) : (
                      "₹ 4920 per month"
                    )
                  ) : isToggled ? (
                    <>
                      $ 566 per year
                      <br />
                      ($ 47.20 per month)
                    </>
                  ) : (
                    "$ 59 per month"
                  )}
                </b>
              </div>
              <div
                className={css`
                  position: absolute;
                  top: 16px;
                  left: 24px;
                  font-size: var(--font-size-xl);
                  line-height: 7.59px;
                  font-weight: 500;
                `}
              >
                For University
              </div>
              <div
                className={css`
                  position: absolute;
                  top: calc(50% - 92.5px);
                  left: calc(50% - 156px);
                  width: 314px;
                  height: 185px;
                  overflow: hidden;
                  font-size: var(--font-size-lg);
                  color: var(--color-darkslategray-100);
                `}
              >
                <div
                  className={css`
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    line-height: 8.76px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    width: 314px;
                    height: 19px;
                  `}
                >
                  Unlimited Resume Processing
                </div>
                <div
                  className={css`
                    position: absolute;
                    top: 31px;
                    left: 0px;
                    line-height: 8.76px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    width: 314px;
                    height: 19px;
                  `}
                >
                  Unlimited auto interview schedules
                </div>
                <div
                  className={css`
                    position: absolute;
                    top: 62px;
                    left: 0px;
                    line-height: 8.76px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    width: 314px;
                    height: 19px;
                  `}
                >
                  Free centralised databases
                </div>
                <div
                  className={css`
                    position: absolute;
                    top: 93px;
                    left: 0px;
                    line-height: 8.76px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    width: 314px;
                    height: 19px;
                  `}
                >
                  Access to 5K free candidate profiles
                </div>
                <div
                  className={css`
                    position: absolute;
                    top: 124px;
                    left: 0px;
                    line-height: 8.76px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    width: 314px;
                    height: 19px;
                  `}
                >
                  Great customer support
                </div>
                <div
                  className={css`
                    position: absolute;
                    top: 155px;
                    left: 0px;
                    line-height: 8.76px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    width: 314px;
                    height: 19px;
                  `}
                >{`Unlimited job uploads & shortlisting`}</div>
              </div>
              <div
                className={css`
                  position: absolute;
                  top: 334px;
                  left:19px;
                  border-radius: 51.42px;
                  background-color: var(--color-dodgerblue-100);
                  width: 143px;
                  height: 33.4px;
                  overflow: hidden;
                  text-align: center;
                  font-size: 16.15px;
                  color: var(--color-white);
                `}
              >
                <b
                  className={css`
                    position: absolute;
                    top: 9.3px;
                    left: calc(50% - 50.5px);
                    line-height: 15.42px;
                  `}
                >
                  {/* {isToggled ? "Purchase Now" : "Start For Free"} */}
                  Coming Soon
                </b>
              </div>
            </div>
          </div>
          <div
            className={css`
              position: absolute;
              top: 649.4px;
              left: calc(50% - 229px);
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              justify-content: flex-start;
              padding: 8.403669357299805px 0px;
              gap: 9.8px;
              font-size: 14.01px;
            `}
          >
            <div
              className={css`
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                gap: var(--gap-4xs-4);
              `}
            >
              <img
                className={css`
                  position: relative;
                  width: 12.6px;
                  height: 12.6px;
                  overflow: hidden;
                  flex-shrink: 0;
                `}
                alt=""
                src="/img.svg"
              />
              <div
                className={css`
                  position: relative;
                  line-height: 8.63px;
                  font-weight: 500;
                  display: flex;
                  align-items: center;
                  width: 119.1px;
                  height: 15.4px;
                  flex-shrink: 0;
                `}
              >
                No cards required
              </div>
            </div>
            <div
              className={css`
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                gap: var(--gap-4xs-4);
              `}
            >
              <img
                className={css`
                  position: relative;
                  width: 12.6px;
                  height: 12.6px;
                  overflow: hidden;
                  flex-shrink: 0;
                `}
                alt=""
                src="/img.svg"
              />
              <div
                className={css`
                  position: relative;
                  line-height: 8.63px;
                  font-weight: 500;
                  display: flex;
                  align-items: center;
                  width: 119.1px;
                  height: 15.4px;
                  flex-shrink: 0;
                `}
              >
                7 days free trial
              </div>
            </div>
            <div
              className={css`
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                gap: var(--gap-4xs-4);
              `}
            >
              <img
                className={css`
                  position: relative;
                  width: 12.6px;
                  height: 12.6px;
                  overflow: hidden;
                  flex-shrink: 0;
                `}
                alt=""
                src="/img.svg"
              />
              <div
                className={css`
                  position: relative;
                  line-height: 8.63px;
                  font-weight: 500;
                  display: flex;
                  align-items: center;
                  width: 137.3px;
                  height: 15.4px;
                  flex-shrink: 0;
                `}
              >
                Get started in 2mins
              </div>
            </div>
          </div>
          <div
            className={css`
              position: absolute;
              top: 180px;
              left: calc(50% - 187px);
              width: 374.5px;
              height: 26px;
              text-align: center;
              font-size: var(--font-size-3xl);
            `}
          >
            <div
              className={css`
                display: flex;
                color: black;
                align-item: center;
                justify-content: center;
                position: absolute;
                left: 65px;
                top: -16px;
              `}
            >
              <p
                style={{
                  position: "relative",
                  fontSize: "22px",
                }}
              >
                Monthly
              </p>
              <div onClick={handleToggle}>
                <ToggleButton />
              </div>
              <p
                style={{
                  position: "relative",

                  fontSize: "22px",
                  width: "195px",
                }}
              >
                Yearly<span style={{ color: "#00CEC9", margin:0 }}>(20% Off)</span>{" "}
              </p>
            </div>
          </div>
          <div
            className={css`
              position: absolute;
              top: 25px;
              left: calc(50% - 238px);
              width: 477px;
              height: 115px;
              overflow: hidden;
              text-align: center;
              font-size: var(--font-size-7xl);
              color: var(--color-dodgerblue-100);
            `}
          >
            <b
              id="scroll-to-Pricing"
              className={css`
                position: absolute;
                top: 0px;
                left: calc(50% - 120.5px);
                letter-spacing: 0.74px;
                line-height: 6.16px;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 242px;
                height: 26px;
              `}
            >
              Pricing Table
            </b>
            <div
              className={css`
                position: absolute;
                top: 35px;
                left: calc(50% - 191.5px);
                width: 383px;
                height: 72px;
                overflow: hidden;
                font-size: var(--font-size-5xl);
                color: var(--color-gray-200);
                font-family: var(--font-montserrat-alternates);
              `}
            >
              <b
                className={css`
                  position: absolute;
                  top: 5px;
                  left: calc(50% - 163.5px);
                  line-height: 12.73px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 328px;
                  height: 31px;
                `}
              >
                Giving you good value
              </b>
              <div
                className={css`
                  position: absolute;
                  top: 38px;
                  left: 47px;
                  width: 291px;
                  height: 36px;
                `}
              >
                <b
                  className={css`
                    position: absolute;
                    top: 5px;
                    left: 0px;
                    line-height: 12.73px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 75px;
                    height: 22px;
                  `}
                >{`with `}</b>
                <div
                  className={css`
                    position: absolute;
                    top: 0px;
                    left: 91px;
                    border-radius: 0px 4.11px 0px 0px;
                    background-color: var(--color-goldenrod);
                    width: 200px;
                    height: 36px;
                  `}
                >
                  <b
                    className={css`
                      position: absolute;
                      top: calc(50% - 6px);
                      left: calc(50% - 75px);
                      line-height: 12.73px;
                    `}
                  >
                    best service
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={css`
            position: absolute;
            width: 100%;
            top: 2176px;
            right: 0px;
            left: 0px;
            background-color: var(--color-white);
            height: 604px;
            overflow: hidden;
            text-align: left;
            font-size: 19.02px;
            font-family: var(--font-dm-sans);
          `}
        >
          <div
            className={css`
              position: absolute;
              width: 100%;
              top: 0px;
              right: 0px;
              left: 0px;
              height: 604px;
              overflow: hidden;
            `}
          >
            <img
              className={css`
                position: absolute;
                width: 100%;
                top: 0px;
                right: 0px;
                left: 0px;
                max-width: 100%;
                overflow: hidden;
                height: 1110px;
                object-fit: cover;
              `}
              alt=""
              src="/bgractangleshapepng@2x.png"
            />
            <div
              className={css`
                position: absolute;
                top: 119px;
                left: calc(50% - 279px);
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-start;
              `}
            >
              <img
                className={css`
                  position: relative;
                  width: 57px;
                  height: 57px;
                  overflow: hidden;
                  flex-shrink: 0;
                  object-fit: cover;
                `}
                alt=""
                src="/ball2svg@2x.png"
              />
            </div>
            <div
              className={css`
                position: absolute;
                top: 180px;
                left: calc(50% - 348px);
                width: 504px;
                height: 334px;
              `}
            >
              <div
                className={css`
                  position: absolute;
                  right: 37.7px;
                  bottom: 5.1px;
                  width: 10.5px;
                  height: 10.5px;
                `}
              />
              <div
                className={css`
                  position: absolute;
                  top: 277px;
                  left: calc(50% - 261px);
                  width: 503px;
                  height: 52px;
                `}
              >
                <div
                  className={css`
                    position: absolute;
                    top: 33.3px;
                    left: 7.9px;
                    font-size: 15.85px;
                    line-height: 15.79px;
                    color: var(--color-darkslategray-100);
                    display: flex;
                    align-items: center;
                    width: 84px;
                    height: 11.1px;
                    font-size: large;
                  `}
                >
                  {reviewname[revstate]}
                </div>
                <b
                  className={css`
                    position: absolute;
                    top: 4.8px;
                    left: 7.9px;
                    line-height: 15.79px;
                    display: flex;
                    align-items: center;
                    width: 310px;
                  `}
                >
                  {reviewcompany[revstate]}
                </b>
                <div
                  className={css`
                    position: absolute;
                    top: 3px;
                    left: 383px;
                    width: 95.3px;
                    height: 21px;
                    overflow: hidden;
                  `}
                >
                  <div
                    className={css`
                      position: absolute;
                      top: 0px;
                      left: 0px;
                      width: 95.3px;
                      height: 21px;
                    `}
                  >
                    <div
                      className={css`
                        position: absolute;
                        top: 0px;
                        left: 27.7px;
                        letter-spacing: 1.58px;
                        line-height: 13.16px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        width: 39.6px;
                        height: 20.6px;
                      `}
                    >
                      {revstate + 1}/4
                    </div>
                    <img
                      className={css`
                        position: absolute;
                        right: 7.9px;
                        bottom: 0.3px;
                        width: 19.7px;
                        height: 19.7px;
                      `}
                      alt=""
                      src="/arrowrightblacksvg.svg"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        revstate === 3 ? "" : SetrevState(revstate + 1)
                      }
                    />
                    <img
                      className={css`
                        position: absolute;
                        top: calc(50% - 9.2px);
                        left: calc(50% - 47.65px);
                        width: 19.7px;
                        height: 19.7px;
                      `}
                      alt=""
                      src="/arrowrightblacksvg1.svg"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        revstate === 0 ? "" : SetrevState(revstate - 1)
                      }
                    />
                  </div>
                </div>
              </div>
              <div
                className={css`
                  position: absolute;
                  top: 62px;
                  left: calc(50% - 252px);
                  width: 482px;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                  font-size: var(--font-size-lg);
                `}
              >
                <div
                  className={css`
                    position: absolute;
                    margin: 0 !important;
                    top: 0px;
                    left: 0px;
                    line-height: 27.49px;
                    display: flex;
                    align-items: center;
                    width: 482px;
                    height: 170px;
                    flex-shrink: 0;
                    z-index: 0;
                  `}
                >
                  {reviewcontent[revstate]}
                </div>
              </div>
            </div>
            <div
              className={css`
                position: absolute;
                top: 219px;
                left: calc(50% + 141px);
                width: 263px;
                height: 263px;
              `}
            >
              <div
                className={css`
                  position: absolute;
                  top: 3.5px;
                  left: calc(50% - 161.9px);
                  width: 338.5px;
                  height: 281.5px;
                  overflow: hidden;
                `}
              >
                <div
                  className={css`
                    position: absolute;
                    top: calc(50% - 129.75px);
                    left: 0px;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: flex-start;
                  `}
                >
                  <img
                    className={css`
                      position: relative;
                      width: 275px;
                      height: 259.9px;
                      overflow: hidden;
                      flex-shrink: 0;
                    `}
                    alt=""
                    src="/emojisvg.svg"
                  />
                </div>
                <div
                  className={css`
                    position: absolute;
                    top: 22.2px;
                    left: 53.4px;
                    width: 285px;
                    height: 237px;
                    overflow: hidden;
                  `}
                >
                  <div
                    className={css`
                      position: absolute;
                      height: calc(100% - 3.3px);
                      top: 0px;
                      bottom: 3.3px;
                      left: calc(50% - 135px);
                      width: 274.6px;
                    `}
                  >
                    <div
                      className={css`
                        position: absolute;
                        top: 0px;
                        left: 102.8px;
                        border-radius: 29.79px;
                        width: 59.6px;
                        height: 59.6px;
                        overflow: hidden;
                      `}
                    >
                      <div
                        className={css`
                          position: absolute;
                          top: 1.2px;
                          left: 1.2px;
                          border-radius: 28.62px;
                          background-color: var(--color-whitesmoke);
                          width: 57.2px;
                          height: 57.2px;
                        `}
                      />
                      <img
                        className={css`
                          position: absolute;
                          top: calc(50% - 23.4px);
                          left: calc(50% - 23.4px);
                          border-radius: 23.37px;
                          width: 46.7px;
                          height: 46.7px;
                          object-fit: cover;
                        `}
                        alt=""
                        src="/happycustomers1png@2x.png"
                      />
                    </div>
                    <div
                      className={css`
                        position: absolute;
                        top: 71.3px;
                        left: 31.3px;
                        border-radius: 29.79px;
                        width: 59.6px;
                        height: 59.6px;
                        overflow: hidden;
                      `}
                    >
                      <div
                        className={css`
                          position: absolute;
                          height: 100%;
                          width: calc(100% - 29.8px);
                          top: 0px;
                          right: 0px;
                          bottom: 0px;
                          left: 29.8px;
                          overflow: hidden;
                        `}
                      >
                        <div
                          className={css`
                            position: absolute;
                            height: 100%;
                            width: calc(100% + 29.8px);
                            top: 29.8px;
                            right: 12.3px;
                            bottom: -29.8px;
                            left: -42.1px;
                            border-radius: 29.79px;
                            border: 14.6px solid var(--color-gray-200);
                            box-sizing: border-box;
                            transform: rotate(-45deg);
                            transform-origin: 0 0;
                          `}
                        />
                      </div>
                      <div
                        className={css`
                          position: absolute;
                          top: 1.2px;
                          left: 1.2px;
                          border-radius: 28.62px;
                          background-color: var(--color-whitesmoke);
                          width: 57.2px;
                          height: 57.2px;
                        `}
                      />
                      <img
                        className={css`
                          position: absolute;
                          top: calc(50% - 23.4px);
                          left: calc(50% - 23.4px);
                          border-radius: 23.37px;
                          width: 46.7px;
                          height: 46.7px;
                          object-fit: cover;
                        `}
                        alt=""
                        src="/happycustomers2png@2x.png"
                      />
                    </div>
                    <div
                      className={css`
                        position: absolute;
                        top: 71.3px;
                        left: 174.4px;
                        border-radius: 29.79px;
                        width: 59.6px;
                        height: 59.6px;
                        overflow: hidden;
                      `}
                    >
                      <div
                        className={css`
                          position: absolute;
                          top: 1.2px;
                          left: 1.2px;
                          border-radius: 28.62px;
                          background-color: var(--color-whitesmoke);
                          width: 57.2px;
                          height: 57.2px;
                        `}
                      />
                      <img
                        className={css`
                          position: absolute;
                          top: calc(50% - 23.4px);
                          left: calc(50% - 23.4px);
                          border-radius: 23.37px;
                          width: 46.7px;
                          height: 46.7px;
                          object-fit: cover;
                        `}
                        alt=""
                        src="/happycustomers3png@2x.png"
                      />
                    </div>
                    <div
                      className={css`
                        position: absolute;
                        top: 142.5px;
                        left: 102.8px;
                        border-radius: 29.79px;
                        width: 59.6px;
                        height: 59.6px;
                        overflow: hidden;
                      `}
                    >
                      <div
                        className={css`
                          position: absolute;
                          top: 1.2px;
                          left: 1.2px;
                          border-radius: 28.62px;
                          background-color: var(--color-whitesmoke);
                          width: 57.2px;
                          height: 57.2px;
                        `}
                      />
                      <img
                        className={css`
                          position: absolute;
                          top: calc(50% - 23.4px);
                          left: calc(50% - 23.4px);
                          border-radius: 23.37px;
                          width: 46.7px;
                          height: 46.7px;
                          object-fit: cover;
                        `}
                        alt=""
                        src="/happycustomers4png@2x.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={css`
                position: absolute;
                top: 119px;
                left: calc(50% - 174px);
                width: 349px;
                height: 84px;
                font-size: var(--font-size-5xl);
                font-family: var(--font-jost);
              `}
            >
              <b
                className={css`
                  position: absolute;
                  top: 0px;
                  left: 1px;
                  line-height: 11.99px;
                  display: flex;
                  align-items: center;
                  width: 348px;
                  height: 40px;
                `}
              >
                Happy customers
              </b>
              <div
                className={css`
                  position: absolute;
                  top: 46px;
                  left: 1px;
                  font-size: var(--font-size-xl);
                  line-height: 5.34px;
                  font-weight: 500;
                  font-family: var(--font-dm-sans);
                  color: var(--color-darkslategray-100);
                  display: flex;
                  align-items: center;
                  width: 348px;
                  height: 34px;
                `}
              >
                Worldwide 30,000+ customer
              </div>
            </div>
          </div>
        </div>
        <div
          className={css`
            position: absolute;
            width: 100%;
            top: 2780px;
            right: 0px;
            left: 0px;
            border-top: 0.2px solid var(--color-gray-300);
            border-bottom: 0.2px solid var(--color-gray-300);
            box-sizing: border-box;
            height: 90px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 9.649603843688965px 0px;
            text-align: left;
            color: var(--color-dodgerblue-100);
          `}
        >
          <div
            className={css`
              margin: 0 !important;
              position: absolute;
              top: 0px;
              left: calc(50% - 323px);
              width: 646px;
              height: 90px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              padding: 8.212428092956543px 3.2849717140197754px;
              box-sizing: border-box;
              z-index: 0;
            `}
          >
            <div
              className={css`
                position: absolute;
                margin: 0 !important;
                top: calc(50% - 26px);
                left: calc(50% - 323px);
                width: 646px;
                height: 51px;
                overflow: hidden;
                flex-shrink: 0;
                z-index: 0;
              `}
            >
              <div
                className={css`
                  position: absolute;
                  top: 0px;
                  left: 443px;
                  width: 203px;
                  height: 42px;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: flex-start;
                  gap: 3.58px;
                `}
              >
                <div
                  className={css`
                    position: relative;
                    width: 57.8px;
                    height: 16px;
                    z-index: 0;
                  `}
                >
                  <b
                    className={css`
                      position: absolute;
                      top: 0.8px;
                      left: -0.4px;
                      line-height: 16.07px;
                      display: flex;
                      align-items: center;
                      width: 39px;
                      height: 14px;
                    `}
                  >
                    23K
                  </b>
                </div>
                <div
                  className={css`
                    position: absolute;
                    margin: 0 !important;
                    top: 28.5px;
                    left: 0px;
                    font-size: var(--font-size-lg);
                    line-height: 11.01px;
                    font-weight: 500;
                    font-family: var(--font-dm-sans);
                    color: var(--color-gray-200);
                    display: flex;
                    align-items: center;
                    width: 203px;
                    height: 7px;
                    flex-shrink: 0;
                    z-index: 1;
                  `}
                >{`Small & Big Companies`}</div>
              </div>
              <div
                className={css`
                  position: absolute;
                  top: 3px;
                  left: 0px;
                  width: 200px;
                  height: 36px;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: flex-start;
                  padding: 1.015703797340393px 0px;
                  box-sizing: border-box;
                  gap: 4.57px;
                `}
              >
                <b
                  className={css`
                    position: relative;
                    line-height: 14.83px;
                    display: flex;
                    align-items: center;
                    width: 54px;
                    height: 13px;
                    flex-shrink: 0;
                    z-index: 0;
                  `}
                >
                  200
                </b>
                <div
                  className={css`
                    position: absolute;
                    margin: 0 !important;
                    top: 28.6px;
                    left: 0px;
                    font-size: var(--font-size-lg);
                    line-height: 10.16px;
                    font-weight: 500;
                    font-family: var(--font-dm-sans);
                    color: var(--color-gray-200);
                    display: flex;
                    align-items: center;
                    width: 200px;
                    height: 6px;
                    flex-shrink: 0;
                    z-index: 1;
                  `}
                >
                  Countries Worldwide
                </div>
              </div>
              <div
                className={css`
                  position: absolute;
                  top: 3px;
                  left: calc(50% - 100px);
                  width: 200px;
                  height: 48px;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: flex-start;
                  padding: 1.015703797340393px 0px;
                  box-sizing: border-box;
                  gap: 4.57px;
                `}
              >
                <b
                  className={css`
                    position: relative;
                    line-height: 14.83px;
                    display: flex;
                    align-items: center;
                    width: 54px;
                    height: 13px;
                    flex-shrink: 0;
                    z-index: 0;
                  `}
                >
                  156K
                </b>
                <div
                  className={css`
                    position: absolute;
                    margin: 0 !important;
                    top: 26.6px;
                    left: 0px;
                    font-size: var(--font-size-lg);
                    line-height: 10.16px;
                    font-weight: 500;
                    font-family: var(--font-dm-sans);
                    color: var(--color-gray-200);
                    z-index: 1;
                  `}
                >
                  Registered User
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          data-scroll-to="JobSeekersdemo"
          className={css`
            position: absolute;
            width: 100%;
            top: 2870px;
            right: 0px;
            left: 0px;
            height: 507px;
            overflow: hidden;
            font-size: var(--font-size-5xl);
          `}
        >
          <div
            className={css`
              position: absolute;
              top: 69px;
              left: calc(50% - 173px);
              overflow: hidden;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
            `}
          >
            <img
              className={css`
                position: relative;
                width: 347px;
                height: 334.7px;
                object-fit: cover;
              `}
              alt=""
              src="/video-recruiter-demo@2x.png"
            />
          </div>
          <div
            className={css`
              position: absolute;
              width: 3.02%;
              top: 364.5px;
              right: 5.21%;
              left: 91.77%;
              overflow: hidden;
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              justify-content: flex-start;
              // padding: 7.983580112457275px;
              box-sizing: border-box;
            `}
          >
            <img
              className={css`
                position: relative;
                width: 29px;
                height: 29px;
                overflow: hidden;
                flex-shrink: 0;
                object-fit: cover;
              `}
              alt=""
              src="/ball2svg1@2x.png"
            />
          </div>
          <div
            className={css`
              position: absolute;
              width: 4.05%;
              top: 27.6px;
              right: 88.7%;
              left: 7.25%;
              overflow: hidden;
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              justify-content: flex-start;
              // padding: 10.749124526977539px 16.661142349243164px;
              box-sizing: border-box;
            `}
          >
            <img
              className={css`
                position: relative;
                width: 26.9px;
                height: 26.9px;
                overflow: hidden;
                flex-shrink: 0;
                object-fit: cover;
              `}
              alt=""
              src="/ball3svg@2x.png"
            />
          </div>
          <div
            className={css`
              position: absolute;
              top: 24px;
              left: calc(50% - 155px);
              width: 310px;
              height: 39px;
              overflow: hidden;
            `}
          >
            <b
              className={css`
                position: absolute;
                top: calc(50% - 6.5px);
                left: calc(50% - 153px);
                line-height: 12.73px;
              `}
            >
              Smart Job Seeker Demo
            </b>
          </div>
          <div
            className={css`
              position: absolute;
              top: calc(50% + 176.5px);
              left: calc(50% - 99px);
              border-radius: 13.14px;
              border: 0.2px solid var(--color-gray-500);
              box-sizing: border-box;
              width: 198px;
              height: 26px;
              font-size: var(--font-size-base);
              color: var(--color-black);
              font-family: var(--font-inter);
            `}
          >
            <div
              className={css`
                position: absolute;
                top: 3px;
                left: 9px;
                width: 159px;
                height: 20px;
                overflow: hidden;
              `}
            >
              <div
                className={css`
                  // position: absolute;
                  top: 10px;
                  left: 0px;
                `}
              >
                To Smart Job Seeker
              </div>
            </div>
            <img
              className={css`
                position: absolute;
                top: 5px;
                left: 174px;
                width: 16px;
                height: 16.3px;
              `}
              alt=""
              src="/arrow-container.svg"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
          className={css`
            position: absolute;
            width: 100%;
            top: 3991px;
            right: 0px;
            left: 0px;
            background-color: rgba(255, 255, 255, 0.95);
            height: 150px;
            overflow: hidden;
            font-size: var(--font-size-5xl);
          `}
        >
          <div
            className={css`
              position: absolute;
              top: 0px;
              left: calc(50% - 121px);
              width: 292px;
              height: 35px;
            `}
          >
            <div
              className={css`
                position: absolute;
                top: 4px;
                left: 6px;
                width: 154px;
                height: 27px;
                overflow: hidden;
              `}
            >
              <b
                className={css`
                  position: absolute;
                  top: calc(50% - 6.5px);
                  left: calc(50% - 72px);
                  line-height: 12.79px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 145px;
                `}
              >
                Auto Apply
              </b>
            </div>
            <div
              className={css`
                position: absolute;
                top: calc(50% - 13.5px);
                left: calc(50% + 19px);
                border-radius: 0px 4.13px 0px 0px;
                background-color: var(--color-goldenrod);
                width: 120px;
                height: 30px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding: 0px 3.3018176555633545px;
                box-sizing: border-box;
              `}
            >
              <b
                className={css`
                  position: relative;
                  line-height: 12.79px;
                `}
              >
                partners
              </b>
            </div>
          </div>

          <TabCarousel />
        </div>
        <div
          className={css`
            position: absolute;
            width: 100%;
            top: 4141px;
            right: 0px;
            left: 0px;
            background-color: var(--color-white);
            height: 637px;
            overflow: hidden;
            font-size: var(--font-size-base);
            color: var(--color-black);
            font-family: var(--font-inter);
          `}
        >
          <div
            className={css`
              position: absolute;
              top: 73px;
              left: calc(50% - 388px);
              width: 363px;
              height: 505px;
            `}
          >
            <div
              className={css`
                position: absolute;
                top: 0px;
                left: 0px;
                background-color: var(--color-white);
                width: 363px;
                height: 505px;
              `}
            />
            <img
              className={css`
                position: absolute;
                top: 0px;
                left: 0px;
                width: 363px;
                height: 505px;
                object-fit: cover;
              `}
              alt=""
              src="/image-3@2x.png"
            />
          </div>
          <div
            className={css`
              position: absolute;
              top: 73px;
              left: calc(50% + 26px);
              width: 363px;
              height: 505px;
            `}
          >
            <div
              className={css`
                position: absolute;
                top: 0px;
                left: 0px;
                background-color: var(--color-white);
                width: 363px;
                height: 505px;
              `}
            />
            <img
              className={css`
                position: absolute;
                top: 0px;
                left: 0px;
                width: 363px;
                height: 505px;
                object-fit: cover;
              `}
              alt=""
              src="/image-3@2x.png"
            />
          </div>
          <Link to="/all-resumetemplates">
            <div
              className={css`
                position: absolute;
                top: 599px;
                left: calc(50% - 99px);
                border-radius: 13.14px;
                border: 0.2px solid var(--color-gray-500);
                box-sizing: border-box;
                width: 198px;
                height: 26px;
              `}
            >
              <div
                className={css`
                  position: absolute;
                  top: 3px;
                  left: 9px;
                  width: 159px;
                  height: 20px;
                  overflow: hidden;
                `}
              >
                <div
                  className={css`
                    position: absolute;
                    top: 0px;
                    left: -1px;
                  `}
                >
                  View more templates
                </div>
              </div>
              <img
                className={css`
                  position: absolute;
                  top: 5px;
                  left: 174px;
                  width: 16px;
                  height: 16.3px;
                `}
                alt=""
                src="/arrow-container.svg"
              />
            </div>
          </Link>
          <b
            className={css`
              position: absolute;
              top: 20px;
              left: calc(50% - 121px);
              font-size: var(--font-size-5xl);
              line-height: 12.63px;
              font-family: var(--font-montserrat-alternates);
              color: var(--color-gray-200);
            `}
          >
            Resume Templates
          </b>
          <div
            className={css`
              position: absolute;
              top: 15px;
              left: calc(50% - 294px);
              overflow: hidden;
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              justify-content: flex-start;
              padding: 6.377918720245361px 5.349221229553223px;
            `}
          >
            <img
              className={css`
                position: relative;
                width: 30.9px;
                height: 30.9px;
                overflow: hidden;
                flex-shrink: 0;
                object-fit: cover;
              `}
              alt=""
              src="/ball1svg3@2x.png"
            />
          </div>
        </div>
        <div
          className={css`
            position: absolute;
            width: 100%;
            top: 4778px;
            right: 0px;
            left: 0px;
            background-color: rgba(255, 255, 255, 0.9);
            border: 0.5px solid var(--color-white);
            box-sizing: border-box;
            height: 464px;
            overflow: hidden;
            font-size: 12.64px;
            color: var(--color-dodgerblue-100);
            font-family: var(--font-dm-sans);
          `}
        >
          <div
            className={css`
              position: absolute;
              width: calc(100% + 2px);
              top: 17px;
              right: -2px;
              left: 0px;
              height: 447px;
              overflow: hidden;
            `}
          >
            <div
              className={css`
                position: absolute;
                top: 61.7px;
                left: calc(50% - 386px);
                width: 771.1px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                gap: 16.15px;
              `}
            >
              <div
                className={css`
                  position: relative;
                  width: 765.5px;
                  height: 73.8px;
                  overflow: hidden;
                  flex-shrink: 0;
                `}
              >
                <b
                  className={css`
                    position: absolute;
                    top: 28.8px;
                    left: 103.9px;
                    letter-spacing: 2.53px;
                    line-height: 21.06px;
                    text-transform: uppercase;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 124.1px;
                    height: 16.2px;
                  `}
                >
                  JOB SEEKERS
                </b>
                <b
                  className={css`
                    position: absolute;
                    top: 28.8px;
                    left: 537.6px;
                    letter-spacing: 2.53px;
                    line-height: 21.06px;
                    text-transform: uppercase;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 124.1px;
                    height: 16.2px;
                  `}
                >
                  RECRUITERS
                </b>
              </div>
              <div
                className={css`
                  position: relative;
                  width: 760.3px;
                  height: 246.4px;
                  font-size: 12.98px;
                  color: var(--color-black);
                  font-family: var(--font-rubik);
                `}
              >
                <div
                  className={css`
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 296.3px;
                    height: 246.4px;
                    overflow: hidden;
                  `}
                >
                  <div
                    className={css`
                      position: absolute;
                      top: 0px;
                      left: 0px;
                      display: flex;
                      flex-direction: row;
                      align-items: flex-start;
                      justify-content: flex-start;
                      padding: 7.02038049697876px;
                    `}
                  >
                    <div
                      className={css`
                        position: absolute;
                        margin: 0 !important;
                        top: -14px;
                        left: 0px;
                        width: 296.3px;
                        height: 126.4px;
                        z-index: 0;
                      `}
                    >
                      <div
                        className={css`
                          position: absolute;
                          top: 77.2px;
                          left: 10.5px;
                          line-height: 21.64px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          width: 274.8px;
                          height: 37.1px;
                        `}
                      >
                        Our all the clients vacancy undisclosed in the market is
                        available to you.
                      </div>
                      <b
                        className={css`
                          position: absolute;
                          top: 25.3px;
                          left: 10.5px;
                          font-size: 17.85px;
                          line-height: 24.1px;
                          display: flex;
                          font-family: var(--font-plus-jakarta-sans);
                          align-items: center;
                          justify-content: center;
                          width: 274.5px;
                          height: 22.5px;
                        `}
                      >
                        Access to undisclosed Jobs
                      </b>
                    </div>
                  </div>
                  <div
                    className={css`
                      position: absolute;
                      top: 128.5px;
                      left: 0px;
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      justify-content: flex-start;
                    `}
                  >
                    <div
                      className={css`
                        position: relative;
                        width: 304px;
                        height: 112.3px;
                      `}
                    >
                      <div
                        className={css`
                          position: absolute;
                          top: 52.7px;
                          left: 7.7px;
                          line-height: 21.64px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          width: 274.8px;
                          height: 37.1px;
                        `}
                      >
                        Everything you require to get you hire is here at one
                        platform
                      </div>
                      <b
                        className={css`
                          position: absolute;
                          top: 2.8px;
                          left: 7.7px;
                          font-size: 17.85px;
                          line-height: 24.1px;
                          display: flex;
                          font-family: var(--font-plus-jakarta-sans);
                          align-items: center;
                          justify-content: center;
                          width: 274.5px;
                          height: 33.7px;
                        `}
                      >
                        Everything to get you hired
                      </b>
                    </div>
                  </div>
                </div>
                <div
                  className={css`
                    position: absolute;
                    top: -0.7px;
                    left: 433.9px;
                    width: 326.4px;
                    height: 225.9px;
                    overflow: hidden;
                    font-size: 17.85px;
                    font-family: var(--font-plus-jakarta-sans);
                  `}
                >
                  <div
                    className={css`
                      position: absolute;
                      top: 0px;
                      left: 0px;
                      width: 294.9px;
                      height: 113px;
                    `}
                  >
                    <div
                      className={css`
                        position: absolute;
                        top: 14.7px;
                        left: 7.7px;
                        width: 287.1px;
                        height: 97.6px;
                      `}
                    >
                      <b
                        className={css`
                          position: absolute;
                          top: 0.7px;
                          left: 0px;
                          line-height: 24.1px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          width: 271.7px;
                          height: 22.5px;
                        `}
                      >
                        Simple Fixed Pricing
                      </b>
                      <div
                        className={css`
                          position: absolute;
                          top: 37.2px;
                          left: 0px;
                          font-size: 12.98px;
                          line-height: 21.64px;
                          font-family: var(--font-rubik);
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          width: 271.3px;
                          height: 37.1px;
                        `}
                      >{`No waiting for quotes from the company. `}</div>
                    </div>
                  </div>
                  <div
                    className={css`
                      position: absolute;
                      top: 112.9px;
                      left: 0px;
                      width: 326.4px;
                      height: 113px;
                    `}
                  >
                    <div
                      className={css`
                        position: absolute;
                        top: 10.6px;
                        left: 0px;
                        width: 326.4px;
                        height: 90.6px;
                      `}
                    >
                      <b
                        className={css`
                          position: absolute;
                          top: 9.1px;
                          left: 0px;
                          line-height: 24.1px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          width: 326.4px;
                          height: 30.9px;
                        `}
                      >
                        Candidates Database at no cost
                      </b>
                      <div
                        className={css`
                          position: absolute;
                          top: 51.2px;
                          left: 0px;
                          font-size: 12.98px;
                          line-height: 21.64px;
                          font-family: var(--font-rubik);
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          width: 326.4px;
                          height: 49.1px;
                        `}
                      >
                        {" "}
                        In house Candidates database aviable at no extra cost
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={css`
                position: absolute;
                top: 0px;
                left: calc(50% - 290.8px);
                width: 638.1px;
                height: 54.6px;
                font-size: var(--font-size-5xl);
                color: var(--color-gray-200);
                font-family: var(--font-montserrat-alternates);
              `}
            >
              <div
                className={css`
                  position: absolute;
                  top: 8.1px;
                  left: calc(50% - 159.25px);
                  width: 317.4px;
                  height: 33px;
                  overflow: hidden;
                `}
              >
                <div
                  className={css`
                    position: absolute;
                    top: 0px;
                    left: calc(50% - 158.7px);
                    border-radius: 0px 10.91px 0px 0px;
                    background-color: var(--color-goldenrod);
                    width: 149px;
                    height: 33px;
                  `}
                />
                <b
                  className={css`
                    position: absolute;
                    top: 3px;
                    left: 6px;
                    line-height: 33.81px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 311.4px;
                    height: 26.7px;
                  `}
                >
                  Everything at One Place
                </b>
              </div>
            </div>
          </div>
          <img
            className={css`
              position: absolute;
              top: 36px;
              right: 111.4px;
              width: 69.6px;
              height: 69.6px;
              overflow: hidden;
              object-fit: cover;
            `}
            alt=""
            src="/yellow-ball-image-container@2x.png"
          />
        </div>
        <div
          data-scroll-to="recruiterdemo"
          className={css`
            position: absolute;
            width: calc(100% - 1px);
            top: 3376.8px;
            right: 1px;
            left: 0px;
            background-color: var(--color-white);
            height: 518.3px;
            font-size: var(--font-size-base);
            color: rgba(5, 0, 56, 0.6);
            font-family: var(--font-inter);
          `}
        >
          <div
            className={css`
              position: absolute;
              width: calc(100% + 1px);
              top: 0.2px;
              right: -1px;
              left: 0px;
              height: 614px;
              overflow: hidden;
            `}
          >
            <img
              className={css`
                position: absolute;
                width: 100%;
                top: 0px;
                right: 0px;
                left: 0px;
                max-width: 100%;
                overflow: hidden;
                height: 614px;
                object-fit: cover;
              `}
              alt=""
              src="/bgractangleshapepng1@2x.png"
            />
            <div
              className={css`
                position: absolute;
                top: calc(50% - 212.9px);
                left: calc(50% - 410px);
                width: 820px;
                overflow: hidden;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-start;
                padding: 12.786577224731445px 73.76870727539062px
                  12.786577224731445px 20px;
                box-sizing: border-box;
              `}
            >
              <div
                className={css`
                  position: relative;
                  width: 393.9px;
                  height: 349.2px;
                  overflow: hidden;
                  flex-shrink: 0;
                `}
              >
                <img
                  className={css`
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 393.9px;
                    height: 349.2px;
                    object-fit: cover;
                  `}
                  alt=""
                  src="/video-recruiter-demo1@2x.png"
                />
              </div>
              <div
                className={css`
                  position: relative;
                  width: 404px;
                  height: 247px;
                `}
              >
                <div
                  className={css`
                    position: absolute;
                    top: 88.5px;
                    left: 85.3px;
                    line-height: 17.3px;
                    text-align: left;
                    display: flex;
                    align-items: center;
                    width: 300px;
                    height: 68px;
                  `}
                >
                  Watch this demo to see how easily the Smart Recruiter
                  integrates with you Mail account and starts automating you
                  hiring pipeline.
                </div>
                <b
                  className={css`
                    position: absolute;
                    top: 48.5px;
                    left: 79.3px;
                    font-size: var(--font-size-5xl);
                    line-height: 30.49px;
                    display: flex;
                    font-family: var(--font-montserrat-alternates);
                    color: var(--color-gray-200);
                    align-items: center;
                    justify-content: center;
                    width: 296px;
                    height: 24px;
                  `}
                >
                  Smart Recruiter Demo
                </b>
                <div
                  className={css`
                    position: absolute;
                    top: calc(50% + 66px);
                    left: calc(50% - 81.7px);
                    border-radius: 13.14px;
                    border: 0.2px solid var(--color-gray-500);
                    box-sizing: border-box;
                    width: 186px;
                    height: 29px;
                    color: var(--color-black);
                  `}
                >
                  <div
                    className={css`
                      position: absolute;
                      top: 3px;
                      left: 9px;
                      width: 159px;
                      height: 20px;
                      overflow: hidden;
                    `}
                  >
                    <div
                      className={css`
                        position: absolute;
                        top: 2px;
                        left: 18px;
                        cursor: pointer;
                        font-size:15px;
                      `}
                      // onClick={onJobSeekerContainerClick}
                    >
                      Coming Soon
                    </div>
                  </div>
                  <img
                    className={css`
                      position: absolute;
                      top: 5px;
                      left: 140px;
                      width: 16px;
                      height: 16.3px;
                    `}
                    alt=""
                    src="/arrow-container.svg"
                  />
                </div>
              </div>
            </div>
            <div
              className={css`
                position: absolute;
                top: 9.2px;
                right: 70.9px;
                overflow: hidden;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-start;
                padding: 15.255860328674316px 18.88821029663086px;
              `}
            >
              <img
                className={css`
                  position: relative;
                  width: 36.3px;
                  height: 36.3px;
                  overflow: hidden;
                  flex-shrink: 0;
                  object-fit: cover;
                `}
                alt=""
                src="/ball3svg1@2x.png"
              />
            </div>
          </div>
        </div>
        <div
          id="scroll-to-About"
          className={css`
            position: absolute;
            width: 100%;
            top: 5242px;
            right: 0px;
            left: 0px;
            background-color: #e8e8e9;
            height: 450px;
            overflow: hidden;
            text-align: left;
            font-size: var(--font-size-lg-3);
            color: var(--color-gray-400);
            font-family: var(--font-dm-sans);
          `}
        >
          <div
            className={css`
              position: absolute;
              top: 59px;
              left: calc(60% - 360px);
              width: 449.9px;
              height: 363px;
              overflow: hidden;
            `}
          >
            <div
              className={css`
                position: absolute;
                bottom: 39.9px;
                left: calc(50% - 230px);
                width: 396.6px;
                height: 69.9px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
              `}
            >
              {/* <div className="flex-1 flex flex-row items-start justify-center pt-[6.2px] px-0 pb-[4.3px] gap-[22px]">
                <div className="flex flex-row items-start justify-start opacity-[0.7]">
                  <a
                    href="https://www.linkedin.com/company/101771162/"
                    target="_blank"
                  >
                    <img
                      className="h-[25px] w-[24px] relative  shrink-0 top-[-1.5px]"
                      loading="lazy"
                      alt=""
                      src="/linkedin_land.svg"
                    />
                  </a>
                </div>
                <div className="flex flex-col items-start justify-start opacity-[0.7]">
                  <a href="https://www.instagram.com/rec_roid/" target="_blank">
                    <img
                      className="h-[21px] w-[20px] relative  shrink-0"
                      loading="lazy"
                      alt=""
                      src="/instagramsvg.svg"
                    />
                  </a>
                </div>
                <div className="flex flex-row items-start justify-start opacity-[0.7]">
                  <a
                    href="https://x.com/rec_roid?t=207L9mMT-n7AQthCQ-vgrA&s=08"
                    target="_blank"
                  >
                    <img
                      className="h-[19px] w-[19px] relative  shrink-0"
                      loading="lazy"
                      alt=""
                      src="x-social-media-black-icon.svg"
                    />
                  </a>
                </div>
                <div className="flex flex-row items-start justify-start opacity-[0.7] ">
                  <a
                    href="https://www.facebook.com/profile.php?id=61560482639226"
                    target="_blank"
                  >
                    <img
                      className="h-[22px] w-[22px] relative  shrink-0 top-[-1px]"
                      loading="lazy"
                      alt=""
                      src="/facebooksvg.svg"
                    />
                  </a>
                </div>
              </div> */}
              <SocialButtons />
            </div>
            <div
              className={css`
                color: black;
                position: absolute;
                top: 20px;
                left: 0px;
                width: 449.9px;
                height: 234.9px;
                overflow: hidden;
              `}
            >
              <div
                className={css`
                  position: absolute;
                  top: 0px;
                  left: calc(50% - 224.95px);
                  overflow: hidden;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: flex-start;
                  gap: var(--gap-smi-2);
                `}
              >
                <div
                  id="productsredirect"
                  className={css`
                    position: relative;
                    font-size: 21.35px;
                    line-height: 6.8px;
                    text-transform: uppercase;
                    font-weight: 600;
                    color: var(--color-black);
                    display: flex;
                    align-items: center;
                    width: 128.1px;
                    height: 21.4px;
                    flex-shrink: 0;
                  `}
                >
                  Products
                </div>
                <div
                  className={css`
                    position: relative;
                    line-height: 36px;
                    display: flex;
                    align-items: center;
                    width: 180px;
                    height: 30.5px;
                    flex-shrink: 0;
                      cursor: pointer;
                  `}
                  onClick={handlerecruiterdemo}
                >
                  Smart Recruiter
                </div>
                <div
                  className={css`
                    position: relative;
                    line-height: 36px;
                    display: flex;
                    align-items: center;
                      cursor: pointer;
                    width: 180px;
                    height: 30.5px;
                    flex-shrink: 0;
                  `}
                  onClick={handleJobSeekersdemo}
                >
                  Smart JobSeeker
                </div>
                <a href="/book-demo" className="no-underline text-black-200">
                  <div
                    className={css`
                      position: relative;
                      line-height: 36px;
                      display: flex;
                      align-items: center;
                      width: 180px;
                      height: 30.5px;
                      flex-shrink: 0;
                    `}
                  >
                    Book a Demo
                  </div>
                </a>
              </div>
              <div
                className={css`
                  position: absolute;
                  top: 0px;
                  left: calc(50% + 23.65px);
                  overflow: hidden;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: flex-start;
                  gap: var(--gap-smi-2);
                `}
              >
                <div
                  className={css`
                    position: relative;
                    font-size: 21.35px;
                    line-height: 6.8px;
                    text-transform: uppercase;
                    font-weight: 600;
                    color: var(--color-black);
                    display: flex;
                    align-items: center;
                    width: 152.5px;
                    height: 21.4px;
                    flex-shrink: 0;
                  `}
                >
                  Company
                </div>
                <a href="/about-us">
                  <div
                    className={css`
                      position: relative;
                      line-height: 36px;
                      display: flex;
                      align-items: center;
                      width: 201.3px;
                      height: 30.5px;
                      flex-shrink: 0;
                    `}
                  >
                    About
                  </div>
                </a>
                <a href="/policies">
                  <div
                    className="relative flex items-center w-[201px] h-[30.5px] shrink-0"
                  >
                    Policy
                  </div>
                </a>
                <a href="/contact-us" className="no-underline text-black-200">
                  <div
                    className="relative flex items-center w-[201.3px] h-[30.5px] shrink-0 "
                  >
                    Contact Us
                  </div>
                </a>
                <a href="/careers">
                  <div
                    className={css`
                      position: relative;
                      line-height: 36px;
                      display: flex;
                      align-items: center;
                      width: 201.3px;
                      height: 30.5px;
                      flex-shrink: 0;
                    `}
                  >
                    Careers
                  </div>
                </a>
                <a href="/terms-of-use">
                  <div
                    className={css`
                      position: relative;
                      line-height: 36px;
                      display: flex;
                      align-items: center;
                      width: 201.3px;
                      height: 30.5px;
                      flex-shrink: 0;
                    `}
                  >
                    Terms Of Use
                  </div>
                </a>
              </div>
            </div>
            <div
              className={css`
                position: absolute;
                bottom: 0px;
                left: calc(50% - 120px);
                width: 400.7px;
                height: 30px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                padding: 0px 4.656825065612793px;
                box-sizing: border-box;
              `}
            >
              <div
                className={css`
                  position: relative;
                  left: -32px;
                  line-height: 9.31px;
                  font-weight: 500;
                  color: black;
                `}
              >
                2024. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MinWidth820AndMaxWidth12;