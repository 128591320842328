import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { useEffect, useMemo, useState } from 'react';

import { Button, Line, Text } from "components";
import BillingSubHeader from "pages/RecruiterDashBoardPages/BillingSubHeaderRecruiter";
import Sidebar1 from "pages/RecruiterDashBoardPages/RecruiterSidebar";
import TopHeader from "pages/RecruiterDashBoardPages/TopHeader";

const BillingHistoryRecruiter = () => {
  const invoicesData = useMemo(() => [
    { id: 1, status: "Paid", amount: 50.89, date: "19-Jan-2023" },
    { id: 2, status: "Paid", amount: 50.89, date: "9-Feb-2023" },
    { id: 3, status: "Paid", amount: 50.89, date: "13-Mar-2023" },
    { id: 4, status: "Paid", amount: 50.89, date: "2-Apr-2023" },
    { id: 5, status: "Paid", amount: 50.89, date: "2-Jun-2023" },
    { id: 6, status: "Paid", amount: 50.89, date: "2-Jul-2023" },
    { id: 7, status: "Paid", amount: 50.89, date: "2-Aug-2023" },
    { id: 8, status: "Paid", amount: 50.89, date: "2-Sep-2023" },
    { id: 9, status: "Paid", amount: 50.89, date: "2-Oct-2023" },
    { id: 10, status: "Paid", amount: 50.89, date: "2-Nov-2023" },
  ], []);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage=10;
  const [totalItems, setTotalItems] = useState(invoicesData.length);

  useEffect(() => {
    setTotalItems(invoicesData.length);
  }, [invoicesData]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedRows = invoicesData.slice(startIndex, endIndex);

  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <Sidebar1 clickedTab={"Billing"} />

          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
            <TopHeader header={"Billing"} />
            <div className="bg-white-A700 flex flex-col items-center justify-start p-7 sm:px-5 rounded-[15px] w-[94%] md:w-full">
              <div className="flex flex-col gap-[43px] justify-start mb-[199px] mt-2.5 w-[99%] md:w-full">
                <BillingSubHeader billingheader={3} />
                <div className="flex flex-col items-start justify-start md:ml-[0] ml-[3px]  px-[3px]">
                  <Text
                    className="text-gray-500 text-xl"
                    size="txtInterMedium20"
                  >
                    Showing invoices within the past 12 months
                  </Text>
                </div>
                <TableContainer component={Paper} className="w-[93%] md:w-full">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Text
                            className="text-black-900 text-lg"
                            size="txtInterMedium18"
                          >
                            Invoice
                          </Text>
                        </TableCell>
                        <TableCell>
                          <Text
                            className="pl-[30px] text-black-900 text-lg"
                            size="txtInterMedium18"
                          >
                            Status
                          </Text>
                        </TableCell>
                        <TableCell>
                          <Text
                            className="text-black-900 text-lg"
                            size="txtInterMedium18"
                          >
                            Amount
                          </Text>
                        </TableCell>
                        <TableCell>
                          <Text
                            className="text-black-900 text-lg"
                            size="txtInterMedium18"
                          >
                            Created
                          </Text>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {displayedRows.map((invoice) => (
                        <TableRow key={invoice.id}>
                          <TableCell>
                            <Text
                              className="text-blue_gray-400 text-lg"
                              size="txtInterMedium18Bluegray400"
                            >
                              Invoice #{invoice.id}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Button
                              style={{
                                color: "#45745a",
                                backgroundColor: "#b5e2b3",
                              }}
                              className="cursor-pointer  p-2 w-[130px] font-medium leading-[normal] text-center text-lg"
                            >
                              {invoice.status}
                            </Button>
                          </TableCell>
                          <TableCell>
                            <Text
                              className="text-black-900 text-lg"
                              size="txtInterMedium18"
                            >
                              $ {invoice.amount}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              className="text-black-900 text-lg"
                              size="txtInterMedium18"
                            >
                              {invoice.date}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Text
                              className="text-green-600 text-lg cursor-pointer"
                              size="txtInterMedium18Green600"
                            >
                              View
                            </Text>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Line className="bg-blue_gray-100 h-px w-full" />
                </TableContainer>
                <div className="w-full flex flex-row justify-center mt-[50px]">
                  <Pagination
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    page={currentPage}
                    count={Math.ceil(totalItems / itemsPerPage)}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingHistoryRecruiter;
