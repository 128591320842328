const initialState = {
    profilePreferenceData: [],
    isLoading: false,
    error: null
};

const profilePreferenceData = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_PROFILE_PREFERENCE_DATA_REQUEST":
            return {
                ...state,
                isLoading: true,
                error: null
            };
        case "FETCH_PROFILE_PREFERENCE_DATA_SUCCESS":
            return {
                ...state,
                profilePreferenceData: action.payload,
                isLoading: false,
                error: null
            };
        case "FETCH_PROFILE_PREFERENCE_DATA_FAILURE":
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
export default profilePreferenceData;