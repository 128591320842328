import CancelIcon from "@mui/icons-material/Cancel";
import {
  Card,
  CardContent,
  IconButton, Pagination, Tooltip
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Button, Text } from "components";
import MainLoader from "components/Loader";
import { ArrowRight } from "lucide-react";
import { ProfileHeader } from "pages/JobSeekerDashBoardPages/ProfileHeader";
import { SideBar1 } from "pages/JobSeekerDashBoardPages/SideBar1";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./index.css";
import Jobcard from "./job_card";

const JobSearch = () => {
  const [workLocations, setWorkLocations] = useState([]);
  const [expectedRoles, setExpectedRoles] = useState([]);
  const [jobSearchPreferences, setJobSearchPreferences] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [dataStatus,setDataStaus]=useState(false)
  const [formError, setFormError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const itemsPerPage = 8;

  const dispatch = useDispatch();
  const jobSearchData = useSelector((state) => state.jobSearchReducer.jobData);
  const loading = useSelector((state) => state.jobSearchReducer.loading);

  useEffect(() => {
    const fetchUserPreferences = async () => {
      try {
        const userPreferenceData = JSON.parse(
          localStorage.getItem(
            `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`,
          ),
        );
        const userId = userPreferenceData.user.id;
        const apiUrl = `${process.env.REACT_APP_API_URL}/get_preferences`;
        const requestOptions = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_id: userId }),
        };

        const userResponse = await fetch(apiUrl, requestOptions);
        if (userResponse.ok) {
          const userData = await userResponse.json();
          const userDetails = userData.data;
    
if (userDetails.work_location_1==true){
  setDataStaus(true)
}
          setDataFetched(true);
          if (userDetails) {
            setWorkLocations([
              userDetails.work_location_1,
              userDetails.work_location_2,
              userDetails.work_location_3,
              userDetails.work_location_4,
            ]);
            setExpectedRoles([
              userDetails.expected_role_1,
              userDetails.expected_role_2,
              userDetails.expected_role_3,
            ]);
            setJobSearchPreferences([
              userDetails.job_search_eng_1.pref_name,
              userDetails.job_search_engine_2.pref_name,
              userDetails.job_search_engine_3.pref_name,
              userDetails.job_search_engine_4.pref_name,
            ]);
            setFormError(false);
          } else {
            console.error("Unexpected data structure in API response");
            setFormError(true);
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setFormError(true);
      }
    };

    fetchUserPreferences();
  }, []);


  const handleOpen = () => {
    
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedRows =
    jobSearchData && jobSearchData.slice(startIndex, endIndex);

  return (
    <>
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <SideBar1 clickedTab="Job Search" />
          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full h-[100vh] overflow-hidden">
            <ProfileHeader clickedTab="Job Search" />
            <div className="h-[92vh]  sm:h-auto   overflow-scroll  w-[94%] md:w-full">
              <div className="bg-white-A700 flex flex-col inset-[0] items-center justify-start m-auto rounded-[15px] w-full">
                <div className="flex flex-col gap-[21px] items-start p-4 justify-start mb-[55px] w-full md:w-full mqtab1:p-2">

                  <div className="flex  flex-row sm:gap-10 items-center justify-between mb-1 w-full">
                    <div className="flex flex-row items-center  justify-start ">
                      <Text
                        className="pl-[5px] text-blue_gray-400 text-xl mqtab1:text-[1.2vw] mqlap:text-[1.15vw] mqtab1:p-3"
                        size="txtInterMedium20"
                      >
                        Job Feed
                      </Text>

                      <Tooltip
                        title={
                          <Typography fontSize={18}>
                            Job Feed is updated periodically, after entering Job Preferences.
                          </Typography>
                        }
                        placement="right"
                        arrow
                        className="w-[30px] h-[30px] relative flex flex-col items-center justify-start md:h-[84px] gap-2 md:w-[250px]   rounded-[15px] transition-colors hover:bg-gray-100 hover:text-[40px]"
                      >
                        <img
                          src="images/infoIcon.svg"
                          alt="infoIcon"
                          className="pl-[5px]"
                        />
                      </Tooltip>

                    </div>

                    <Dialog open={open} maxWidth="xl" disableBackdropClick={true}>

                      <Card
                        sx={{
                          width: { xs: "80vw", md: "40vw" }, // Responsive width
                          height: { xs: "auto", md: "30vh" }, // Responsive height
                          margin: "auto", // Center horizontally
                          padding: 2, // Padding inside the card
                          backgroundColor: "#f0f8ff", // Light blue background
                          border: "1px solid #ccc", // Border
                          boxShadow: 3, // Box shadow for depth
                          position: "relative", // For positioning the close button
                          textAlign: "center",
                        }}
                      >
                        {/* Close button */}
                        <IconButton
                          aria-label="close"
                          onClick={handleClose}
                          sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            color: "#999", // Grey color for the icon
                          }}
                        >
                          <CancelIcon />
                        </IconButton>

                        <CardContent className="flex flex-col justify-between h-full">
                          <Typography
                            variant="h6"
                            component="div"
                            gutterBottom
                            sx={{ color: "#333" }} // Darker text for contrast
                          >
                            Important Notice
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              color: "#555",
                              fontSize: { xs: "1rem", md: "1.25rem" },
                            }} // Responsive font size
                          >
                            Please update  your
                            preference details.
                          </Typography>
                          <Link
                            to="/profile-preferences"
                            className="w-full flex items-center justify-center"
                            style={{ textDecoration: "none", marginTop: 16 }}
                          >
                            <Button className="flex items-center justify-around w-[200px]">
                              <span>Go to Profile</span>
                              <ArrowRight />{" "}
                              {/* Use the correct icon component */}
                            </Button>
                          </Link>
                        </CardContent>
                      </Card>

                    </Dialog>


                    <div>
                      <Button
                        onClick={handleOpen}
                        className="cursor-pointer font-medium  leading-[normal] text-center text-lg  sm:text-sm mqtab1:text-[1.05vw] mqlap:text-[1vw] px-[1.4vw] py-[0.7vw] mqlap:px-[1.6vw] mqtab1:px-[1.6vw] mqlap:rounded-[13px] mqtab1:rounded-[11px]"
                        shape="round"
                      >
                        {!dataStatus ? "Add Preferences" : "Update Preferences"}
                      </Button>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-center w-full md:w-full flex-wrap">
                    <div className="md:gap-5 gap-x-[20px]   min-h-[auto] w-[97%] mqlap:gap-x-[18px]  grid grid-cols-4 mqtab1:gap-x-[14px] gap-y-[1.1vw] mqtab1:gap-y-[1.2vw]">
                      {!loading ? (
                        <Jobcard displayedRows={displayedRows} dataStatus={dataStatus}/>
                      ) : (
                        // displayedRows &&
                        // displayedRows.map((data, index) => (
                        //   <div key={index}>{job_card(data)}</div>
                        // ))
                        <MainLoader />
                      )}
                    </div>
                  </div>
                </div>
                <div className="mb-[70px] flex flex-col inset-x-[0] items-center justify-center mx-auto w-full">
                  <div className="flex flex-row items-center justify-end mt-[10px] w-[100%] md:w-full">
                    <div className="flex flex-row items-start justify-evenly w-full">
                      {displayedRows.length !== 0 && (
                        <Pagination
                          color="primary"
                          variant="outlined"
                          shape="rounded"
                          currentPage={currentPage}
                          count={Math.ceil(jobSearchData.length / itemsPerPage)}
                          onChange={(event, pageNumber) =>
                            handlePageChange(event, pageNumber)

                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobSearch;
