import { Tooltip } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { fetchCareerAdviceData } from "actions/CarrerAdvice";
import { fetchJobSeekerDashboarCounts } from "actions/JobSeekerDashboard";
import { Button, Text } from "components";
import countryStates from "components/CountriesStates";
import MainLoader from "components/Loader";
import PresubmitPopup from "./presubmitPopup";
import LogoutPopup from "components/LogoutPopup";
import { ProfileHeader } from "pages/JobSeekerDashBoardPages/ProfileHeader";
import { SideBar1 } from "pages/JobSeekerDashBoardPages/SideBar1";
import pdfMake from "pdfmake/build/pdfmake";
import { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import TextToSpeech from "../InterviewPreparation/textToSpeech";
import AdviceTable from "./adviceTable";
import "./index.css";
import InstructionPage from "./instructionsPage";
import logoBase64EncodedValue from "./report";
const CareerAdvise = () => {
  const [open, setOpen] = useState(false);
  const [reportLoad, setreportLoad] = useState(true);
  const [data, setData] = useState([]);
  const [oldResumeText, setOldResumeText] = useState("");
  const [goal, setGoal] = useState("");
  const [country, setCountry] = useState("");
  const [industry, setIndustry] = useState("");
  const [currentRole, setCurrentRole] = useState("");
  const [adviseMode, setAdviseMode] = useState("Text based");
  const [search, setSearch] = useState(false);
  const [questionsArray, setQuestionsArray] = useState([]);
  const [questionsAnswersArray, setQuestionsAnswersArray] = useState([]);
  const [advice, setAdvice] = useState([]);
  const [Stopen, setst] = useState(false);
  const [instruction, setInsruction] = useState(false);
  const [third, setst3] = useState(false);
  const [fourth, setst4] = useState(false);
  const [warning, setWarning] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answersArray, setAnswersArray] = useState([]);
  const [interviewId, setInterviewId] = useState("");

  const dispatch = useDispatch();

  const userData = JSON.parse(
    localStorage.getItem(
      `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`
    )
  );
  const user_id = userData.user.id;
  const fullName = userData.user.user_metadata.full_name;
  const careerData = useSelector((state) => state.careerReducer.careerData);
  const loading = useSelector((state) => state.careerReducer.loading);
  const [dataFetching, setFetchstatus] = useState(loading);
  // useEffect(() => {
  //   dispatch(fetchCareerAdviceData());
  // }, [dispatch]);
  const handleContinue = () => {
    setInsruction(false);
    //setTimeRemaining(selectedDuration * 60);
  };

  useEffect(() => {
    // Warn the user if they try to leave the page or reload
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // Most browsers will show a default message
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleOpen = () => {
    setGoal("");
    setCountry("");
    setAdviseMode("Text based");
    setCurrentRole("");
    setOpen(true);
    setInsruction(false);
  };
  const backToOptions = () => {
    setOpen(true);
    setst(false);
  };
  const handleClose = () => {
    setOpen(false);
    setWarning(false);
  };

  const handleStartOpen = () => {
    setSearch((prev) => !prev);
    if (
      currentRole !== "" &&
      goal !== "" &&
      country !== "" &&
      adviseMode !== ""
    ) {
      setreportLoad(true);
      setst3(false);
      handleClose();
      setFetchstatus(true);
      setCurrentQuestion(0);
      const apiUrl = `${process.env.REACT_APP_API_URL}/carrier_advice_questions`;
      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          old_resume_text: oldResumeText,
          current_role: currentRole,
          goal: goal,
          country: country,
          industry: industry,
        }),
      };

      fetch(apiUrl, requestOptions)
        .then((data) => data.json())
        .then((res) => {
          // //console.log(
          //   "Result from backend carre advice question",
          //   res["questions"],
          // );
          setQuestionsArray(res["questions"]);
          setWarning(false);
          setFetchstatus(false);
          setInsruction(true);
          setst(true);
          // //console.log(instruction);
        });
    } else {
      setWarning(true);
    }
  };

  // const handleStartClose = () => {
  //   setst(false);
  // };

  // const handleStartOpen3 = async () => {
  //   // //console.log(questionsAnswersArray);
  //   handleStartClose();
  //   setst3(true);
  // };

  const handleStartClose3 = () => {
    setst3(false);
  };
  const isAnswerEmpty = () => {
    const answer = answersArray[currentQuestion];
    return answer === undefined || answer.trim().length === 0;
  };

  const uploadReport = async (db_data, pdfBlob) => {
    try {
      var storedData = localStorage.getItem(
        `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`
      );
      var parsedData = JSON.parse(storedData);
      var userId = parsedData.user.id;
      const formData = new FormData();
      formData.append("int_report", pdfBlob, "InterviewPreparationReport.pdf");

      const apiUrl = `${process.env.REACT_APP_API_URL}/upload_career_report?user_id=${userId}&job_id=${db_data}`;
      const requestOptions = {
        method: "POST",
        body: formData,
      };

      const res = await fetch(apiUrl, requestOptions);

      if (!res.ok) {
        const errorText = await res.text();
        console.error("Error fetching data:", res.status, errorText);
        // Handle the error or throw an exception as needed
      } else {
        const data = await res.json();
        dispatch(fetchCareerAdviceData(user_id));
        dispatch(fetchJobSeekerDashboarCounts(user_id));
        //console.log("Data from resume parser", data);
      }
    } catch (error) {
      console.error("Error updating stored resume:", error);
    }
  };

  const buildPDF = async (db_data, advice_for_career) => {
    try {
      const date = new Date().toLocaleDateString();
      const time = new Date().toLocaleTimeString();

      const score = advice[0];
      const scorePercentage = score * 10;
      let fontColor = "";

      if (scorePercentage >= 60) {
        fontColor = "green";
      } else if (scorePercentage >= 30) {
        fontColor = "blue";
      } else {
        fontColor = "red";
      }

      const docData = {
        pageMargins: [40, 80, 40, 40],
        header: [
          {
            columns: [
              {
                image: logoBase64EncodedValue,
                cover: {
                  width: 50,
                  height: 50,
                  valign: "top",
                  align: "right",
                },
              },
              {
                text: "Career Advice Report",
                color: "#314CA3",
                fontSize: 18,
                bold: true,
                margin: [0, 15, 0, 0],
                alignment: "left",
              },
            ],
            columnGap: 180,
          },
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 10,
                x2: 800,
                y2: 10,
                lineWidth: 3,
              },
            ],
          },
        ],
        content: [
          {
            text: [
              "Full Name: ",
              { text: fullName, style: "bold" },
              "\n\n",
              "Selected Role: ",
              { text: currentRole, style: "bold" },
              "\n\n",
              "Interview Type: ",
              { text: adviseMode, style: "bold" },
              "\n\n",
              "Selected Country: ",
              { text: country, style: "bold" },
              "\n\n",
              "Date and Time: ",
              { text: date + " " + time, style: "bold" },
              "\n\n",
            ],
            margin: [0, 20, 0, 0],
          },
          {
            text: "Career Advice For You",
            style: "bold",
            margin: [0, 20, 0, 5],
          },
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 10,
                x2: 500,
                y2: 10,
                lineWidth: 3,
                margin: [0, 20, 0, 0],
              },
            ],
          },
          {
            text: advice_for_career,
            margin: [0, 20, 0, 0],
          },
        ],
        styles: {
          bold: {
            bold: true,
          },
          green: {
            color: "green",
          },
        },
      };

      // Creating PDF using pdfMake
      const pdfDoc = pdfMake.createPdf(docData);
      pdfDoc.getBlob(async (blob) => {
        await uploadReport(db_data, blob);
      });
    } catch (e) {
      //console.log("Error building PDF:", e);
    }
  };

  const handleDownload = async (job_id) => {
    try {
      var storedData = localStorage.getItem(
        `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`
      );
      var parsedData = JSON.parse(storedData);
      var userId = parsedData.user.id;
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/download_career_report?user_id=${userId}&job_id=${job_id}`
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "CareerAdviceReport.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      //console.log(e);
    }
  };

  const [answers, setAnswers] = useState({
    answer1: "",
    answer2: "",
    answer3: "",
    answer4: "",
    answer5: "",
  });

  // Handler for updating answers
  // const handleAnswerChange = (e, questionNumber) => {
  //   const answer = e.target.value;
  //   setAnswers((prevAnswers) => ({
  //     ...prevAnswers,
  //     [`answer${questionNumber}`]: answer,
  //   }));
  // };
  const [answeredText, setansweredText] = useState("");
  const initialCharCount = 1000;
  const numQuestions = 5; 
  const [charCount, setCharCount] = useState(Array(numQuestions).fill(initialCharCount));
  const handleAnswerChange = (event) => {
    const newAnsweredText = event.target.value;
    setansweredText(newAnsweredText); // Use functional update for more predictable behavior
    const newAnswersArray = [...answersArray];
    newAnswersArray[currentQuestion] = newAnsweredText;
    const newCharCount = [...charCount];
    newCharCount[currentQuestion] = initialCharCount - newAnswersArray[currentQuestion].length;
    setCharCount(newCharCount);
    setAnswersArray(newAnswersArray);
  };

  const [openSubmitTest, setpenSubmitTest] = useState(false);
  const SubmitTest = () => {
    setpenSubmitTest(true);
  };
  const SubmitTestClose = () => {
    setpenSubmitTest(false);
    setOpen(false);
  };
  // Handler for submitting answers
  const handleGetAdvice = async () => {
    setFetchstatus(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/carrier_advice_answer`; //backend-recruiterrobo-30fh.onrender.com
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        question_answer_list: questionsAnswersArray,
        old_resume_text: oldResumeText,
        current_role: currentRole,
        goal: goal,
        country: country,
        industry: industry,
        user_id: user_id,
      }),
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();
      const db_data = data.record; // Accessing unique ID from the API response
      const newInterviewId = db_data;
      setInterviewId(newInterviewId);
      const advice12 = data.advice.advice;
      setAdvice(advice12);
      await buildPDF(db_data, advice12);
      // //console.log(db_data)
      setAdvice(advice12);
      setreportLoad(false);
      setst3(true);
      setst(false);
      setFetchstatus(false);
      setInsruction(true);
      setpenSubmitTest(false);
    } catch (error) {
      console.error("Error fetching advice:", error);
    }
    setQuestionsAnswersArray([])
    setansweredText("")
    setCharCount(Array(numQuestions).fill(initialCharCount))
  };

  const getNextQuestion = async (currentQuestion) => {
    const question = questionsArray[currentQuestion];
    const answer = answersArray[currentQuestion];
    //console.log("Question :", question);
    //console.log("Answer:", answer);
    const currentQuestionAnswerPair = {
      question: question,
      answer: answer,
    };
    //console.log("Current Question Answer Pair:", currentQuestionAnswerPair);
    setQuestionsAnswersArray((prevQA) => [
      ...prevQA,
      currentQuestionAnswerPair,
    ]);
  };
  const handleNextQuestion = () => {
    handleAnswerChange(
      { target: { value: answersArray[currentQuestion] } },
      currentQuestion
    );

    getNextQuestion(currentQuestion);
    setCurrentQuestion(currentQuestion + 1);

    setansweredText("");

    setOpen(false);
  };
  const handleSubmitTest = () => {
    //console.log("Question :", questionsArray[currentQuestion]);
    //console.log("Answer:", answersArray[currentQuestion]);

    const question = questionsArray[currentQuestion];
    const answer = answersArray[currentQuestion];

    const currentQuestionAnswerPair = {
      question: question,
      answer: answer,
    };
    //console.log("Current Question Answer Pair:", currentQuestionAnswerPair);
    setQuestionsAnswersArray((prevQA) => [
      ...prevQA,
      currentQuestionAnswerPair,
    ]);
    //console.log("Questions Array:", questionsArray);
    //console.log("Answers Array:", answersArray);
    //console.log("Questions Answers Array:", questionsAnswersArray);
    //console.log("Current Question No:", currentQuestion);
    SubmitTest();
  };

  const [speechStatus, setSpeechComplete] = useState(false);
  const handleStartAnswer = () => {
    setSpeechComplete(true);
  };

  return (
    <>
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <SideBar1 clickedTab={"Career Advice"} />
          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full h-[100vh]">
            <ProfileHeader clickedTab={"Career Advice"} />

            <div className="h-[92vh] overflow-scroll  w-[94%] md:w-full">
              <div className=" bg-white-A700 flex flex-col md:gap-10 gap-[682px] h-full inset-[0] items-center justify-start m-auto rounded-[15px] w-full">
                <div className="flex flex-col items-center justify-start p-[30px] sm:px-5 w-full">
                  <div className="flex flex-row sm:gap-10 items-center justify-between mb-1 w-full">
                    <Text
                      className="text-blue_gray-400  sm:text-sm  text-xl mqtab1:text-[1.2vw] mqlap:text-[1.1vw]"
                      size="txtInterMedium20"
                    >
                      Career Advice Report
                    </Text>

                    <div>
                      <Button
                        onClick={handleOpen}
                        className="cursor-pointer font-medium  leading-[normal] text-center text-lg sm:text-sm  mqtab1:text-[1.05vw] mqlap:text-[1vw] px-[1.4vw] py-[0.7vw] mqlap:px-[1.6vw] mqtab1:px-[1.6vw] mqlap:rounded-[13px] mqtab1:rounded-[11px] w-fit"
                        shape="round"
                      >
                        Take Advice
                      </Button>
                    </div>
                  </div>
                  <div className="flex flex-col h-max inset-[0] items-center justify-center mt-[40px] py-[19px] w-full">
                    {dataFetching ? (
                      <MainLoader />
                    ) : (
                      <AdviceTable
                        careerData={careerData}
                        handleDownload={handleDownload}
                      />
                    )}
                  </div>
                </div>
                <Dialog open={open} disableBackdropClick={true} maxWidth="lg">
                  <div className="flex flex-col  w-[45rem] mqlap:w-[39.5vw] mqtab1:w-[42.8vw] mqtab2:w-[45vw] mqmob1:w-[48vw]">
                    <div className="h-[71px] mqlap:h-[4vw] mqtab1:h-[4.2vw] mqtab2:h-[4.1vw] flex justify-start items-center gap-[2px]">
                      <p className="text-start  font-semibold text-[20px]  text-xl sm:text-sm pl-[30px] mqlap:text-[1.1vw] mqtab1:text-[1.15vw] mqtab2:text-[1.4vw]">
                        Take Advice
                      </p>
                      <Tooltip
                        title={
                          <Typography>
                            {" "}
                            <p className="text-[16px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] mqtab2:text-[1.02vw]">
                            Enter Details to Start the session
                            </p>
                          </Typography>
                        }
                        placement="right"
                        arrow
                        className="relative flex flex-col items-center justify-start md:h-[84px] gap-2  md:w-[250px]   rounded-[15px] transition-colors  hover:bg-gray-100 hover:text-[40px]"
                      >
                        <img
                          className="sm:h-[25px] sm:w-[25px] h-[1.2rem] w-[1.25rem] mqlap:w-[1.1vw] mqlap:h-[1.05vw] mqlap:mt-[1px] mqtab1:mt-[0.8px] mqtab1:w-[1.02vw] mqtab1:h-[1rem] mqtab2:w-[1.3vw] mqtab2:h-[1.3rem]"
                          src="images/infoIcon.svg"
                          alt="infoIcon"
                        />
                      </Tooltip>
                    </div>
                    <hr
                      style={{
                        height: "1px",
                        backgroundColor: "#e3e4e8",
                        border: "none",
                        width: "100%",
                      }}
                    />

                    <div className="flex-wrap  flex flex-row justify-between ml-[80px] mr-[55px] mqlap:ml-[65px] mqlap:mr-[40px] mqtab1:ml-[65px] mqtab1:mr-[40px] gap-y-[22px] gap-x-[30px]  mqtab1:gap-y-[20px] mqtab1:gap-x-[28px] items-center sm:flex-col mt-[20px] flex-wrap">
                      <div className="w-[45%] sm:w-[90%] flex flex-col gap-[10px]">
                        <p className="sm:text-[10px] font-semibold mqlap:text-[0.9vw] mqtab1:text-[0.95vw]">
                          Select Role
                        </p>
                        <select
                          className="flex items-center  w-full h-[2.85rem]  mqlap:h-[2.45vw] mqlap:pt-[0.35vw] mqtab2:rounded-[8.5px] mqtab2:h-[2.5vw] mqtab2:text-[0.9vw] mqtab2:pt-[0.12vw]  mqtab1:h-[2.43vw] mqtab1:pt-[0.25vw] mqtab1:rounded-[10px]  rounded-[12px] border-[#e3e4e8] text-[15.5px] mqlap:text-[0.86vw] mqtab1:text-[0.9vw] "
                          onChange={(e) => setCurrentRole(e.target.value)}
                        >
                          <option value="" selected disabled>
                            Select role
                          </option>
                          <option value="Student">Student</option>
                          <option value="Fresher">Fresher</option>
                          <option value="Working Professional">
                            Working Professional
                          </option>
                        </select>
                      </div>
                      <div className="w-[45%] sm:w-[90%] flex flex-col gap-[10px]">
                        <p className="sm:text-[10px] font-semibold font-semibold mqlap:text-[0.9vw] mqtab1:text-[0.93vw] mqtab2:text-[1.02vw]">
                          {" "}
                          What is your goal?
                        </p>
                        <select
                          className="w-full h-[2.85rem]  mqlap:h-[2.45vw] mqlap:pt-[0.35vw] mqtab2:rounded-[8.5px] mqtab2:h-[2.5vw] mqtab2:text-[0.9vw] mqtab2:pt-[0.12vw]  mqtab1:h-[2.43vw] mqtab1:pt-[0.25vw] mqtab1:text-[0.75rem] mqtab1:rounded-[10px] rounded-[12px]  border-[#e3e4e8] text-[15.5px] mqlap:text-[0.86vw] mqtab1:text-[0.9vw]"
                          onChange={(e) => setGoal(e.target.value)}
                        >
                          <option value="" disabled selected>
                            Select goal
                          </option>
                          <option value="Student">
                            {" "}
                            Pursue higher studies
                          </option>
                          <option value="Fresher">
                            Switch jobs / find a job
                          </option>
                          <option value="Working Professional">
                            {" "}
                            not sure
                          </option>
                        </select>
                      </div>
                      <div className="w-[45%] sm:w-[90%] flex flex-col gap-[10px]">
                        <p className="sm:text-[10px] font-semibold font-semibold mqlap:text-[0.9vw] mqtab1:text-[0.93vw] mqtab2:text-[1.02vw] ">
                          {" "}
                          Select country
                        </p>
                        <select
                          className=" w-full h-[2.85rem] mqlap:h-[2.45vw] mqlap:pt-[0.35vw] mqtab2:rounded-[8.5px] mqtab2:h-[2.5vw] mqtab2:text-[0.9vw] mqtab2:pt-[0.12vw] mqtab1:h-[2.43vw] mqtab1:pt-[0.25vw]  mqtab1:rounded-[10px] rounded-[12px] sm:rounded-[8px] sm:text-[10px] border-[#e3e4e8] text-[15.5px] mqlap:text-[0.86vw] mqtab1:text-[0.9vw] "
                          onChange={(e) => setCountry(e.target.value)}
                        >
                          <option value="" selected disabled>
                            Select country
                          </option>
                          {countryStates.map((each) => (
                            <option key={each.country} value={each.country}>
                              {each.country}
                            </option>
                          ))}
                        </select>
                    </div>
                      {/* <div className="w-[45%] sm:w-[90%] flex flex-col gap-[10px]">
                      <p className="sm:text-[10px] font-semibold text-[16px]">
                        {" "}
                        Career advice type
                      </p>
                      <select
                        className="w-full h-[55px] sm:h-[35px] rounded-[12px] sm:rounded-[8px] sm:text-[10px] border-[#e3e4e8]"
                        onChange={(e) => setAdviseMode(e.target.value)}
                      >
                        <option value="" selected disabled>
                          Select mode
                        </option>
                        <option value="Text based">Text based</option>
                        <option value="Live based">Live based</option>
                      </select>
                    </div> */}

                     
                    </div>
                    <div className="flex flex-row gap-[18px] mqlap:gap-[15px] mqtab1:gap-[14.5px] mqtab2:gap-[13px] justify-end sm:justify-center items-center mt-[2.3rem] mb-[1.15rem] mqtab1:mb-[1.05rem] mqtab1:mt-[2.1rem] mqlap:mb-[1.07rem]">
                        <button
                          style={{ borderColor: "blue", color: "blue" }}
                          className=" mqtab1:border-[0.1vw] mqlap:border-[0.1vw]  mqtab2:border-[0.1vw] border-[1.8px] border-soild border-blue flex justify-center items-center h-[2.52rem] w-[7.5rem] mqlap:h-[2.1vw] mqlap:w-[6.8vw] mqtab1:h-[2.15vw] mqtab1:w-[6.9vw] mqtab2:h-[2.35vw] mqtab2:w-[7.4vw] rounded-[13px] sm:text-[14px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] mqtab2:text-[0.95vw] mqlap:rounded-[0.73vw] mqtab1:rounded-[0.73vw] mqtab2:rounded-[9px]"
                          onClick={handleClose}
                        >
                          Close
                        </button>
                        <Button
                          className="flex justify-center items-center mr-[30px] h-[2.55rem] w-[7.5rem] mqlap:h-[2.15vw] mqlap:w-[6.9vw] mqtab1:h-[2.2vw] mqtab1:w-[7vw] mqtab2:h-[2.36vw] mqtab2:w-[7.5vw] rounded-[12px] sm:text-[14px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] mqtab2:text-[0.95vw] mqlap:rounded-[0.73vw] mqtab1:rounded-[0.73vw] mqtab2:rounded-[9px]"
                          onClick={handleStartOpen}
                        >
                          Start
                        </Button>
                      </div>
                  </div>
                </Dialog>
                <Dialog fullScreen open={Stopen} disableBackdropClick={true}>
                  {!instruction ? (
                    <div className="flex justify-center">
                      <AppBar style={{ backgroundColor: "#314ca3" }}>
                        <Toolbar>
                          <Typography
                            sx={{ ml: 2, flex: 1 }}
                            variant="h6"
                            component="div"
                          >
                            Please answer all the five questions
                          </Typography>
                        </Toolbar>
                      </AppBar>
                      <div
                        style={{ backgroundColor: "#e5e8f3" }}
                        className="w-[80vw] h-auto pb-[40px] flex flex-col self-center mt-[120px] rounded-[14px]"
                      >
                        <div className="flex justify-end mr-[40px] mt-[20px]">
                          <Text>Attempted questions: {currentQuestion}/5</Text>
                        </div>
                        <TextToSpeech
                          text={questionsArray[currentQuestion]}
                          handleStartAnswer={handleStartAnswer}
                          index={currentQuestion}
                        />
                        <div className="w-[95%] mt-[40px] self-center">
                          <Text className="pb-[20px]">Your answer:</Text>
                          <textarea
                            className="w-full rounded-[10px] mt-[10px] sm:text-[14px] text-gray-500 text-md min-h-[200px] border-[#D0D1D4]"
                            value={answeredText}
                            onChange={handleAnswerChange}
                            maxLength={initialCharCount}
                          />
                          <div className="text-right text-gray-500 text-sm">
                            {charCount[currentQuestion]} characters remaining
                          </div>
                        </div>
                        <div className="flex justify-end w-[95%] self-center mt-[50px]">
                          {currentQuestion + 1 === 5 ? (
                            speechStatus ? (
                              <Button
                                className="w-[150px] h-[45px] text-center"
                                onClick={handleSubmitTest}
                              >
                                Submit
                              </Button>
                            ) : (
                              <button
                                style={{
                                  border: "solid 2px blue",
                                  color: "blue",
                                }}
                                className="h-[45px] flex justify-center items-center w-[150px] sm:h-[30px] sm:w-[70px] text-[18px] rounded-[14px] sm:text-[14px]"
                              >
                                Submit
                              </button>
                            )
                          ) : speechStatus && !isAnswerEmpty() ? (
                            <Button
                              className="w-[150px] h-[45px] text-center"
                              onClick={() => {
                                handleNextQuestion();
                                setSpeechComplete(false);
                              }}
                              //disabled={isAnswerEmpty()} // Disable if answer is empty
                            >
                              Next
                            </Button>
                          ) : (
                            <button
                              style={{
                                border: "solid 2px blue",
                                color: "blue",
                              }}
                              className="h-[45px] flex justify-center items-center w-[150px] sm:h-[30px] sm:w-[70x] text-[18px] rounded-[14px] sm:text-[14px] "
                            >
                              Next
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <InstructionPage
                      backToOptions={backToOptions}
                      handleContinue={handleContinue}
                    />
                  )}
                </Dialog>

                <Dialog open={third} disableBackdropClick={true} maxWidth="lg">
                  <div className="start-interview2">
                    <div className="group-wrapper3">
                      <div className="group">
                        <div className="overlap1">
                          <div className="index3">
                            <div className="div">
                              {/* <div className="text-wrapper">Career Advice for you</div> */}
                              <div className="first-name">
                                <div id="content" className="your-name">
                                  Career Advisor
                                </div>
                                <div className="frame">
                                  {/* <input type="text" className="charlene-reed" placeholder='Charlene'/> */}
                                  <div className="charlene-reed">
                                    {reportLoad ? (
                                      <BeatLoader />
                                    ) : (
                                      <textarea
                                        readOnly
                                        rows={23}
                                        cols={100}
                                        placeholder={"advice"}
                                        maxLength={300}
                                        className="charlene-reed"
                                      >
                                        {advice}
                                      </textarea>
                                    )}

                                    {/* </div> */}
                                  </div>
                                </div>
                              </div>

                              {/* <div
                                className="save-wrapper"
                                onClick={handleStartOpen}
                              >
                                <button className="save-2">Retake</button>
                              </div> */}
                              <div className="flex justify-center mt-4">
                                <div
                                  className="save-button"
                                  onClick={handleStartClose3}
                                >
                                  <button className="save">Exit</button>
                                </div>
                              </div>
                              <div className="group">
                                <div className="overlap-group">
                                  <button
                                    onClick={() => handleDownload(interviewId)}
                                    className="save-3"
                                  >
                                    Download
                                  </button>
                                  <img
                                    className="octicon-download"
                                    alt="Octicon download"
                                    src="https://cdn.animaapp.com/projects/65245b020103026f6d371b65/releases/6585a2cf79a36e6414abe303/img/octicon-download-16.svg"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p className="text-wrapper">Career Advice for you</p>
                        <div className="overlap">
                          <div className="div"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog>
              </div>
              {/* <LogoutPopup
                goTo="/login"
                isOpen={openSubmitTest}
                onClose={SubmitTestClose}
                onConfirmLogout={handleGetAdvice}
                mainHeadText=" Confirm submission of test?"
                hoverText=" Click submit button to submit test"
                subText=" Review your answers carefully before submitting. Ensure accuracy and completeness for the best outcome."
                cancelButton="Close"
                yesButton="Submit"
              /> */}

              
              <PresubmitPopup
                open={openSubmitTest}
                close={SubmitTestClose}
                handleGetAdvice={handleGetAdvice}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerAdvise;
