import Badge from "@mui/material/Badge";
import { Button, Img, Text } from "components";
import NotificationList from "components/Notifications/NotificationDialog";
import { Bell } from "lucide-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NavMobile from "./navbarMobile";
const ProfileHeader = (props) => {
  const { clickedTab } = props;
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [unread, setUnread] = useState(false);
  const [active, setActive] = useState(false);

  const user_id = useSelector(
    (state) => state.profilePersonalData?.personalData?.id,
  );
  const {profilePicture} = useSelector((state)=>state.profilePictureReducer)
  const [imgUrl, setImgUrl] = useState(profilePicture);
  // const storedImgUrl = localStorage.getItem("imgUrl");
  const [formError, setFormError] = useState(false);
  const [dataFetch, setDataFetch] = useState(false);
  let notifications = useSelector(
    (state) => state.notificationsReducer.notifications,
  );
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       if(!user_id){
  //         return;
  //       }
  //       const apiUrl = `${process.env.REACT_APP_API_URL}/get_profile_picture`;
  //       const requestOptions = {
  //         method: "POST",
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ user_id: user_id }),
  //       };

  //       const userResponse = await fetch(apiUrl, requestOptions);
  //       if (userResponse.ok) {
  //         const userData = await userResponse.json();
  //         setDataFetch(true);
  //         if (userData.picture_link !== null) {
  //           setImgUrl(userData.picture_link);
  //           localStorage.setItem("imgUrl", userData.picture_link);
  //         } else {
  //           setImgUrl("images/defaultprofilepicture.svg");
  //           localStorage.setItem("imgUrl", "images/defaultprofilepicture.svg");
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error fetching user data:", error);
  //       setFormError(true);
  //     }
  //   };

  //   fetchData();
  // }, [user_id]);

  useEffect(() => {
    if (profilePicture !== "images/defaultprofilepicture.svg") {
      setImgUrl(profilePicture);
    }
  }, [profilePicture]);

  useEffect(() => {
    const readNotificationsCount = notifications.filter(
      (each) => each.read,
    ).length;
    const calculateUnread = () => {
      if (notifications && notifications.length > 0) {
        const unread = notifications.some((notification) => !notification.read);
        setUnread(unread);
      }
    };

    calculateUnread();
  }, [notifications]);

  const handleNotificationClick = () => {
    setMenuOpen((prevState) => !prevState);
    setActive((prevState) => !prevState);
  };

  return (
    <div className="sticky realtiveflex flex-col overflow-hide bg-white-A700 border border-gray-300 border-solid flex flex-row md:gap-10 items-center justify-between p-5 w-full sm:mt-[15px] sm:p-3 sm:rounded-md ">
      <div className="flex flex-row w-full justify-between items-center">
        <div className="items-center sm: ml-[15px] justify-center ml-[37px] w-auto">
          <Text
            className="sm:text-lg mqtab1:text-[24px] mqlap:text-[26px] text-[28px] text-gray-900_01 w-auto sm:text-[22px]"
            size="txtInterSemiBold28"
          >
            {clickedTab}
          </Text>
        </div>
        <div className="flex flex-row gap-[39px] items-center justify-center">
          <Button
            className={`md:hidden sm:hidden  mqtab2:hidden mqmob1:hidden flex h-[50px] items-center justify-center my-[5px] w-[50px] absolute right-[7.5rem] ${
              active ? "z-20" : "z-10"
            }`}
            shape="circle"
            color="gray_100_01" // Check if this is the correct color prop, MUI buttons usually use a different color prop
            size="small" // Changed to "small" as "sm" might not be recognized by MUI
            variant="contained" // Ensure the button variant matches your UI requirement
          >
            <Badge
              color="primary"
              badgeContent={
                notifications.filter((notification) => !notification.read)
                  .length
              }
            >
              <Bell
                size={36}
                
                className=" mqtab1:h-[35px] mqtab1:w-[35px]" 
                onClick={handleNotificationClick}
                style={{ color: active ? "inherit" : "#314ca3" }}
              />
            </Badge>
          </Button>
          {active && (
            <>
              <NotificationList
                badgeContent={
                  notifications.filter((notification) => !notification.read)
                    .length
                }
                notifications={notifications}
                user_id={user_id}
                setUnread={setUnread}
                unread={unread}
                setMenuOpen={setMenuOpen}
                onClick={(e) => e.stopPropagation()}
              />
              {/* <div className="fixed inset-0 bg-black bg-opacity-50 w-[100%] h-[100%] z-10" style={{backgroundColor:"black", opacity: "50%"}} onClick={handleNotificationClick}></div> */}
            </>
          )}

          <div className="md:hidden  mqtab2:hidden mqmob1:hidden flex flex-col items-start justify-start w-[60px]">
            <Img
              className="h-[60px] mqtab1:h-[55px] rounded-[50%] w-[60px] "
              src={imgUrl}
              alt="profile"
            />
          </div>
          <div className="hidden mqtab2:block mqmob1:block z-1000">
            <NavMobile />
          </div>
        </div>
      </div>
    </div>
  );
};

export { ProfileHeader };
