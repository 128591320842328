import Dialog from "@mui/material/Dialog";
import { Tooltip, DialogActions } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Text } from "components";
import AlertModal from "components/AlertModal";
import { useState } from "react";
import "./index.css";
import websiteNames from "components/companyNames/companyNames";

const optionsList = [
  { label: "Resume", value: "Resume" },
  { label: "Website", value: "Website" },
  // { label: "Linkedin", value: "Linkedin" },
];

const ProfessionalExtractForm = ({
  headingtext,
  onDataFromChild,
  setUserPersonalData,
}) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [is_resume_uploaded, setIsResumeUploaded] = useState(false);

  const EmptyResumeData = {
    Name: undefined,
    "Phone number": undefined,
    Email: undefined,
    Designation: undefined,
    "Experience in years": undefined,
    "College or University 1 with degree": undefined,
    "College or University 2 with degree": undefined,
    "College or University 3 with degree": undefined,
    School: undefined,
    City: undefined,
    State: undefined,
    Country: undefined,
    "Technical Skills": undefined,
    "Interpersonal Skills": undefined,
    Languages: undefined,
    "Project Lists": [],
    GitHub: undefined,
    LinkedIn: undefined,
    Courses: undefined,
    Awards: undefined,
    Publication: undefined,
    Reference: undefined,
    Declaration: undefined,
    Certificates: undefined,
    "Role 1 with Organization Name": undefined,
    "Role 2 with Organization Name": undefined,
    "Role 3 with Organization Name": undefined,
    "Interests and hobbies": undefined,
  };

  const userData = JSON.parse(
    localStorage.getItem(
      `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`
    )
  );
  const user_id = userData.user.id;

  const handleSelectChange = (e) => {
    const selectedOption = e.target.value;
    setSelectedValue(selectedOption);
    onDataFromChild(selectedOption);
    setOpenDialog(true);
  };

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const [websiteURL, setWebsiteURL] = useState("");
  const [isValidURL, setIsValidURL] = useState(true);

  const handleURLChange = (e) => {
    const newURL = e.target.value;
    setWebsiteURL(newURL);
  };

  const handleStartClickWebsite = async () => {
    const urlRegex =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

    // Validate URL format
    if (urlRegex.test(websiteURL)) {
      let normalizedURL;

      try {
        // Attempt to normalize the URL
        normalizedURL = new URL(websiteURL).origin;
      } catch (error) {
        console.error("Invalid URL:", error);
        setIsValidURL(false);
        alert("The entered URL is invalid. Please enter a valid URL.");
        return; // Exit the function early
      }

      // Check if the entered URL contains any of the restricted website names
      const containsRestrictedName = websiteNames.some((domain) =>
        normalizedURL.includes(domain)
      );

      if (!containsRestrictedName) {
        setIsValidURL(true);
        setLoading(true);

        try {
          await extractFromWebsite();
          handleClose();
        } catch (error) {
          console.error("Error extracting from website:", error);
          alert("An error occurred while extracting data. Please try again.");
        } finally {
          setLoading(false);
        }
      } else {
        setIsValidURL(false);
        alert(
          "The entered URL contains a restricted website name. Please enter a different URL."
        );
      }
    } else {
      setIsValidURL(false);
      alert("Enter a valid URL");
    }
  };

  const handleExtractResumeData = async () => {
    setLoading(true);
    setUserPersonalData(EmptyResumeData);
    await extractResumeData();
    handleClose();
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  const handleExtractLinkedinData = () => {
    setLoading(true);
    setUserPersonalData(EmptyResumeData);
    extractLinkedinData();
    handleClose();
    setLoading(false);
  };

  const handleExtractFillInDetails = () => {
    handleClose();
  };

  const extractFromWebsite = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/website-parser`;
      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url: websiteURL }),
      };

      const res = await fetch(apiUrl, requestOptions);
      const resumeData = await res.json();
      // console.log("Website data : ", resumeData);
      setUserPersonalData(resumeData);
    } catch (error) {
      console.error("Error fetching data:", error);
      // console.log("Error fetching data:", error);
    }
  };

  const extractResumeData = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/resume_parser?user_id=${user_id}`;
      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: user_id }),
      };

      const res = await fetch(apiUrl, requestOptions);
      const resumeData = await res.json();
      setUserPersonalData(resumeData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const extractLinkedinData = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/fetch_user_linkedin_data`;
      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      const res = await fetch(apiUrl, requestOptions);
      const LinkedIndata = await res.json();
      setUserPersonalData(LinkedIndata);
      //console.log("response from linkedin", LinkedIndata);
      handleClose();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const renderDialogContent = () => {
    switch (selectedValue) {
      case "Linkedin":
        return (
          <div className="resume" style={{ height: "300px", width: "1000px" }}>
            <div className="mt-[20px] ">
              <div className="flex flex-row items-center mb-[20px]">
                <Text
                  className="pl-[3.5%] mt-0.5 text-black-900 text-xl sm:text-[12px]"
                  size="txtInterSemiBold18"
                >
                  Linkedin Details
                </Text>
                <Tooltip
                  title={
                    <Typography fontSize={18}>
                      Enter the Linkedin details to start
                    </Typography>
                  }
                  placement="right"
                  arrow
                  className="w-[40px] h-[40px] relative flex flex-col items-center justify-start md:h-[84px] gap-2 md:w-[250px]   rounded-[15px] transition-colors hover:bg-gray-100 hover:text-[40px]"
                >
                  <img
                    src="images/infoIcon.svg"
                    alt="infoIcon"
                    className="pl-[5px]"
                  />
                </Tooltip>
              </div>
              <hr
                style={{
                  height: "1px",
                  backgroundColor: "grey",
                  border: "none",
                  width: "100%",
                }}
              />
            </div>
            <div className="emailaddress">
              <input
                id="LinkedinUsername"
                placeholder="Username"
                type="text"
                className="emailaddress-child pl-3  placeholder-gray-300 rounded-[10px]"
              />
            </div>
            <div className="emailaddress1">
              <input
                id="Linkedinpassword"
                placeholder="Password"
                type="password"
                className="emailaddress-child placeholder-gray-300  rounded-[10px]"
              />
            </div>
            <div
              style={{ position: "absolute", top: "200px", left: "780px" }}
              onClick={handleExtractLinkedinData}
              className="savebutton cursor-pointer"
            >
              <div className="save">Start</div>
            </div>
            <div
              style={{ position: "absolute", top: "200px", left: "570px" }}
              className="savebutton1 cursor-pointer"
              onClick={handleClose}
            >
              <div className="save cursor-pointer">Cancel</div>
            </div>
          </div>
        );
      case "Resume":
        if (is_resume_uploaded === false) {
          fetch(
            `${process.env.REACT_APP_API_URL}/check_is_resume_uploaded?user_id=${user_id}`,
            {
              method: "POST",
            }
          )
            .then((res) => res.json())
            .then((result) => {
              if (result["is_resume_uploaded"] === true) {
                setIsResumeUploaded(true);
              }
            });
        }

        if (is_resume_uploaded == false) {
          return (
            <div className=" w-[32rem] mqlap:w-[30.5vw] mqtab1:w-[31vw]">
              <div className="">
                <div className="flex flex-row items-center">
                  <Text
                    className="pl-[3.5%] h-[4.4rem] mqlap:h-[3.8vw] mqtab1:h-[3.9vw] text-black-900 text-[1.3rem] mqlap:text-[1.11vw] mqtab1:text-[1.16vw] flex items-center"
                    size="txtInterSemiBold18"
                  >
                    Upload resume
                  </Text>
                  <Tooltip
                    title={
                      <Typography>
                      <p className="text-[16px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] mqtab2:text-[1.02vw]">
                        Please upload your resume in Upload Page.
                        </p>
                      </Typography>
                    }
                    placement="right"
                    arrow
                    className="relative flex flex-col items-center justify-start md:h-[84px] gap-2 md:w-[250px]   rounded-[15px] transition-colors hover:bg-gray-100 hover:text-[40px]"
                  >
                    <img
                      src="images/infoIcon.svg"
                      alt="infoIcon"
                      className="ml-[1.5px] sm:h-[25px] sm:w-[25px] h-[1.25rem] w-[1.25rem] mqlap:w-[1.1vw] mqlap:h-[1.1rem] mqtab1:w-[1.02vw] mqtab1:mt-[1px] mqtab1:h-[1rem] mqtab2:w-[1.3vw] mqtab2:h-[1.3rem]"
                    />
                  </Tooltip>
                </div>
                <hr
                  style={{
                    height: "1px",
                    backgroundColor: "grey",
                    border: "none",
                    width: "100%",
                  }}
                />
              </div>
              {/* <div
                onClick={handleExtractResumeData}
                className="savebutton cursor-pointer"
              >
                <div className="save">Start</div>
              </div>
              <div className="savebutton1 cursor-pointer" onClick={handleClose}>
                <div className="save cursor-pointer">Cancel</div>
              </div> */}
            </div>
          );
        }
      case "Fill in Details":
        return (
          <div className="w-[36rem] mqlap:w-[30.8vw] mqtab1:w-[32.6vw]">
            <div>
              {/* <Text
                className="pl-[3.5%] h-[4.4rem] mqlap:h-[3.8vw] mqtab1:h-[3.9vw] text-black-900 text-[1.3rem] mqlap:text-[1.11vw] mqtab1:text-[1.16vw] flex items-center"
                size="txtInterSemiBold18"
              >
                Start to continue
              </Text> */}
              <div className="flex flex-row items-center">
                  <Text
                    className="pl-[3.5%] h-[4.4rem] mqlap:h-[3.8vw] mqtab1:h-[3.9vw] text-black-900 text-[1.3rem] mqlap:text-[1.11vw] mqtab1:text-[1.16vw] flex items-center"
                    size="txtInterSemiBold18"
                  >
                     Start to continue
                  </Text>
                  <Tooltip
                    title={
                      <Typography fontSize={18}>
                        Build your Professional Profile from Uploaded Resume.
                      </Typography>
                    }
                    placement="right"
                    arrow
                    className="relative flex flex-col items-center justify-start md:h-[84px] gap-2 md:w-[250px]   rounded-[15px] transition-colors hover:bg-gray-100 hover:text-[40px]"
                  >
                    <img
                      src="images/infoIcon.svg"
                      alt="infoIcon"
                      // className="pl-[5px]"
                    className="ml-[1.5px] sm:h-[25px] sm:w-[25px] h-[1.25rem] w-[1.25rem] mqlap:w-[1.1vw] mqlap:h-[1.1rem] mqtab1:w-[1.02vw] mqtab1:mt-[1px] mqtab1:h-[1rem] mqtab2:w-[1.3vw] mqtab2:h-[1.3rem]"

                    />
                  </Tooltip>
                </div>
              
              <hr
                style={{
                  backgroundColor: "grey",
                  border: "none",
                  width: "100%",
                }}
                className="h-[1px] mqtab1:h-[0.8px]"
              />
            </div>
            <DialogActions>
              <div className="flex gap-[10px] py-[1.5rem] mqlap:py-[1.2vw] mqtab1:py-[1.23vw] px-[0.5rem]">
                <div
                  onClick={handleExtractResumeData}
                  className="cursor-pointer"
                >
                  <div
                    style={{ borderColor: "blue", color: "blue" }}
                    className="save flex justify-center items-center mqtab1:border-[0.1vw]  mqlap:border-[0.1vw] border-[1.9px] border-solid h-[2.42rem] w-[7.2rem] text-[17px] rounded-[11px] mqlap:rounded-[9px] mqlap:h-[2.02vw] mqlap:w-[6.1vw] mqlap:text-[0.9vw] mqtab1:rounded-[8px] mqtab1:text-[0.93vw] mqtab1:h-[2.05vw] mqtab1:w-[6.2vw]"
                  >
                    Start
                  </div>
                </div>
                <div className="cursor-pointer" onClick={handleClose}>
                  <div className="save flex justify-center items-center h-[2.42rem] w-[7.2rem] text-[17px] rounded-[9.9px] mqlap:rounded-[9.5px] mqlap:h-[2.02vw] mqlap:w-[6.1vw] mqlap:text-[0.85vw] mqtab1:rounded-[7.6px] mqtab1:text-[0.9vw]  mqtab1:h-[2.05vw] mqtab1:w-[6.2vw] bg-indigo-600 text-white-A700">
                    Cancel
                  </div>
                </div>
              </div>
            </DialogActions>
          </div>
        );
      case "Website":
        return (
          <div
            style={{
              fontFamily: "Inter",
              textAlign: "center",
              overflow: "hidden",
            }}
            className="flex flex-col w-[39rem] mqlap:w-[34vw] mqtab1:w-[35vw] gap-[32px] mqlap:gap-[30px] mqtab1:gap-[27px]"
          >
            <div>
              <div className="flex flex-row items-center">
                <Text
                  className="pl-[3.5%] h-[4.3rem] mqlap:h-[3.6vw] mqtab1:h-[3.7vw] text-black-900 text-[1.3rem] mqlap:text-[1.11vw] mqtab1:text-[1.16vw] flex items-center"
                  size="txtInterSemiBold18"
                >
                  Website
                </Text>
                <Tooltip
                  title={
                    <Typography>
                      <p className="text-[16px] mqlap:text-[0.9vw] mqtab1:text-[0.9vw] mqtab2:text-[1.02vw]">
                        Enter Website URL which contains your Professional
                        Portfolio
                      </p>
                    </Typography>
                  }
                  placement="right"
                  arrow
                  className="relative flex flex-col items-center justify-start rounded-[15px] transition-colors hover:bg-gray-100 hover:text-[40px]"
                >
                  <img
                    src="images/infoIcon.svg"
                    alt="infoIcon"
                    className="ml-[1.5px] sm:h-[25px] sm:w-[25px] h-[1.25rem] w-[1.25rem] mqlap:w-[1.1vw] mqlap:h-[1.1rem] mqtab1:w-[1.02vw] mqtab1:mt-[1px] mqtab1:h-[1rem] mqtab2:w-[1.3vw] mqtab2:h-[1.3rem]"
                  />
                </Tooltip>
              </div>
              <hr
                style={{
                  height: "0.5px",
                  backgroundColor: "#e3e4e8",
                  // backgroundColor: "grey",
                  border: "none",
                  width: "100%",
                }}
              />
            </div>
            <div className="ml-[3.5%]">
              <input
                id="WebsiteURL"
                placeholder="https://personal-website.com"
                type="url"
                // style={{ borderColor: "grey" }}
                className={` pl-3 rounded-[10px] placeholder-gray-300 w-[75%] flex pl-[3.5%] h-[2.85rem]  mqlap:h-[2.45vw] mqlap:pt-[0.35vw] mqtab2:rounded-[8.5px] mqtab2:h-[2.5vw] mqtab2:text-[0.9vw] mqtab2:pt-[0.12vw]  mqtab1:h-[2.43vw] mqtab1:pt-[0.25vw] mqtab1:text-[0.75rem] mqtab1:rounded-[10px] rounded-[12px]  border-[#e3e4e8] text-[15.5px] mqlap:text-[0.86vw] mqtab1:text-[0.9vw]  ${
                  isValidURL ? "" : "invalid"
                }`}
                value={websiteURL}
                onChange={handleURLChange}
              />
            </div>
            <div className="flex gap-[10px] pb-[1.5rem] mqlap:pb-[1.2vw] mqtab1:pb-[1.23vw] pt-[0.2rem] pr-[1rem] justify-end">
              <div
                onClick={handleStartClickWebsite}
                // style={{ position: "absolute", top: "200px" }}
                className=" cursor-pointer"
              >
                <div
                  style={{ borderColor: "blue", color: "blue" }}
                  className="save flex justify-center items-center mqtab1:border-[0.1vw]  mqlap:border-[0.1vw] border-[1.9px] border-solid h-[2.4rem] w-[7.2rem] text-[16px] rounded-[11px] mqlap:rounded-[9px] mqlap:h-[2.02vw] mqlap:w-[6.15vw]  mqtab1:rounded-[8px] mqlap:text-[0.89vw] mqtab1:text-[0.88vw] mqtab1:h-[2.05vw] mqtab1:w-[6.3vw]"
                >
                  Start
                </div>
              </div>
              <div
                // style={{ position: "absolute", top: "200px" }}
                className=" cursor-pointer"
                onClick={handleClose}
              >
                <div className="save flex justify-center items-center h-[2.4rem] w-[7.2rem] text-[15.8px] rounded-[9.9px] mqlap:rounded-[9.5px] mqlap:h-[2.02vw] mqlap:w-[6.15vw] mqlap:text-[0.82vw] mqtab1:rounded-[7.6px] mqtab1:text-[0.85vw]  mqtab1:h-[2.05vw] mqtab1:w-[6.3vw] bg-indigo-600 text-white-A700">
                  Cancel
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {success && <AlertModal msg="success" />}
      {loading && <AlertModal msg="loading" />}
      {error && <AlertModal msg="error" />}
      <div>
        <div>
          <Text
            className="sm:text-[12px] mqlap:text-[0.9vw] mqtab1:text-[1vw]"
            size="txtMontserratMedium16"
          >
            {headingtext}
          </Text>
        </div>
        <select
          className="form-select sm:text-[10px] sm:w-[200px] sm:h-[35px] mqlap:text-[0.9vw] mqtab1:text-[1vw] mqlap:w-[14vw] mqtab1:w-[15.5vw] mqlap:h-[40px] mqtab1:h-[37px]"
          value={selectedValue}
          onChange={handleSelectChange}
        >
          <option value="" disabled>
            Select
          </option>
          {optionsList.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
      <Dialog open={openDialog} onClose={handleClose} maxWidth="lg">
        <div className="content-type ">{renderDialogContent()}</div>
      </Dialog>
    </>
  );
};

ProfessionalExtractForm.defaultProps = {
  headingtext: "Select one option to extract from",
  setUserPersonalData: () => {},
  extractFromWebsite: () => {},
};

export default ProfessionalExtractForm;
