import Cookies from "js-cookie";
import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ element, isPaidReq }) => {
  const temp =
    Cookies.get("accessToken") || Cookies.get("access_token") || null;
  const accessToken = JSON.parse(temp);
  const accountType = useSelector(
    (state) => state.profileAccountType.accountType,
  );

  const storedUserData = JSON.parse(
    localStorage.getItem(
      `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`,
    ),
  );
  const userId = storedUserData?.user?.id;

  if (isPaidReq) {
    if (accountType === 0) {
      return <Navigate to="/billing-overview" />;
    }
  }
  if (!accessToken && userId === undefined) {
    if (!accessToken) {
      return <Navigate to="/login" />;
    }
    if (userId === undefined) {
      return <Navigate to="/login" />;
    }

    return <Navigate to="/login" />;
  }
  return React.cloneElement(element);
};

export default ProtectedRoute;
