export const getProfileUploadLinks = (user_id) => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/get_file_links?user_id=${user_id}`, {
                method: "POST",
            });
            const response2 = await fetch(`${process.env.REACT_APP_API_URL}/check_files?user_id=${user_id}`, {
                method: "POST",
            });
            const links = await response.json();
            const status = await response2.json();
            dispatch({ type: 'GET_PROFILE_UPLOAD_LINKS', payload: { links, status } });
        } catch (error) {
            console.error(error);
        }
    }
}
