import { Text } from "components";
import HamburgerMenu from "components/HamburgerMenu";
import { useCallback, useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import SocialButtons from "../Desktop/socialbuttons";
import ToggleButton from "../Desktop/toggle";
import MCarousel from "./company_animation/MApp";

const WebsiteName = styled.div`
  position: absolute;
  top: 2px;
  left: 7px;
  line-height: 135.5%;
  font-weight: 600;
`;

const WebsiteNameFrame = styled.div`
  position: absolute;
  top: 26.5px;
  left: 25px;
  width: 140.4px;
  height: 32px;
  overflow: hidden;
  font-size: 11.5px;
`;
const RecruiterRoboLogo31Icon = styled.img`
  position: absolute;
  top: calc(50% - 29px);
  left: calc(50% - 0);
  width: 31px;
  height: 37px;
  object-fit: cover;
`;
const LogoFrame = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 67px;
  height: 70px;
  overflow: hidden;
`;
const WebsiteNameFrameParent = styled.div`
  position: absolute;
  top: 5px;
  left: 22px;
  width: 207.4px;
  height: 70px;
`;
const Header = styled.div`
  position: absolute;
  width: calc(100% + 2px);
  top: 0px;
  right: -2px;
  left: 0px;
  background-color: #fff;
  height: 80px;
`;
// const MenuIcon = styled.img`
//   position: absolute;
//   top: 19px;
//   right: 22px;
//   width: 37px;
//   height: 37px;
//   object-fit: cover;
//   cursor: pointer;
// `;
const WebsiteHeader = styled.div`
  position: absolute;
  width: 100%;
  top: 0px;
  right: 0px;
  left: 0px;
  height: 80px;
  font-size: 15px;
  color: #161616;
  font-family: Poppins;
`;
const DemocratizingRecruitment = styled.b`
  position: absolute;
  top: 3px;
  left: calc(50% - 149px);
  line-height: 16.95px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 299px;
`;
const TextContainer = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 151px);
  width: 302px;
  height: 40px;
  overflow: hidden;
`;
const TextContainerWrapper = styled.div`
  position: relative;
  width: 302px;
  height: 40px;
`;
const Resumebuilder31AiRecruitern = styled.b`
  position: absolute;
  // top: 23.6px;
  // left: 11.8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 297.1px;
  height: 81.3px;
`;
const TextAnimation = styled.div`
  position: relative;
  width: 337px;
  height: 95px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: 17.53px;
  color: #e86767;
  font-family: Inter;
`;
const OpeningPageGifIcon = styled.img`
  position: relative;
  width: 281px;
  height: 285px;
  object-fit: cover;
`;
const ImageContainer2 = styled.div`
  width: 286px;
  height: 286px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 5px;
  box-sizing: border-box;
`;
const GroupParent = styled.div`
  position: absolute;
  top: 28px;
  left: calc(50% - 163px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 12px;
  gap: 5px;
`;
const OpeningPage = styled.div`
  position: absolute;
  width: 100%;
  top: 60px;
  right: 0px;
  left: 0;
  border-radius: 0px 0px 32.5px 32.5px;
    background: linear-gradient(305deg,#ffffff 0%, rgba(190, 225, 236, 0.63)  46.49%, rgba(190, 225, 236, 0.63) 56.47%, rgba(5, 240, 253, 0.00) 121.12%);
  height: 486px;
`;
const Heading2 = styled.div`
  position: absolute;
  top: 10px;
  left: calc(50% - 80.5px);
  line-height: 12.63px;
  font-weight: 600;
`;
const ResumeBuilderImageIcon = styled.img`
  position: relative;
  width: 75.6px;
  height: 87.1px;
  object-fit: cover;
`;
const ImageContainer = styled.div`
  margin: 0 !important;
  position: absolute;
  top: 155px;
  left: calc(50% - 51px);
  height: 101px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6.047903537750244px 12.700597763061523px;
  box-sizing: border-box;
  z-index: 0;
`;
const Heading5 = styled.div`
  position: relative;
  line-height: 10.37px;
  font-weight: 500;
`;
const CreateAPersonalized = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 15.72px;
  font-weight: 500;
  font-family: "DM Sans";
  color: #444;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 259px;
`;
const TextContainer1 = styled.div`
  margin: 0 !important;
  position: absolute;
  top: 22px;
  left: calc(50% - 132px);
  width: 263px;
  height: 118px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 11.053689956665039px 1.5790985822677612px 13.027563095092773px;
  box-sizing: border-box;
  gap: 24.08px;
  z-index: 1;
`;
const BuildAndEnhanceResume = styled.div`
  border-radius: 11.84px;
  background-color: #f9f1ff;
  width: 280px;
  height: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2.368647813796997px 7.500718116760254px;
  box-sizing: border-box;
  cursor: pointer;
  // overflow: none;
`;

const Jobseekerss = styled.div`
  position: absolute;
  top: 155px;
  left: calc(50% - 139.5px);
  width: 280px;
  height: 370px;
  overflow-x: auto;
  font-size: 16px;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;
const Heading2BestFeaturesThatParent = styled.div`
  position: absolute;
 
  width: 100%;
  top: 566px;
  right: 0px;
  left: 0px;
  height: 403px;
  overflow: hidden;
  &::-webkit-scrollbar {
    width: 0;
  }
 
  /* Optionally, style the track and thumb of the scrollbar */
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
has context menu
`;
const Heading21 = styled.div`
  position: absolute;
  top: 16px;
  left: calc(50% - 73.5px);
  line-height: 12.63px;
  font-weight: 600;
`;

const Heading2BestFeaturesThatGroup = styled.div`
  position: absolute;
  width: 100%;
  top: 969px;
  right: 0px;
  left: 0px;
  height: 422px;
  overflow: hidden;
`;
const PricingTable = styled.b`
  position: absolute;
  top: 0px;
  left: calc(50% - 57.5px);
  letter-spacing: 0.74px;
  line-height: 6.16px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 115px;
  height: 13px;
`;
const GivingYouGood = styled.b`
  position: absolute;
  top: 0px;
  left: calc(50% - 91.5px);
  line-height: 12.73px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 183px;
  height: 15px;
`;
const With = styled.b`
  position: absolute;
  top: 5px;
  left: 0px;
  line-height: 12.73px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 10px;
`;
const BestService = styled.b`
  position: absolute;
  top: calc(50% - 6px);
  left: calc(50% - 43.5px);
  line-height: 12.73px;
`;
const YellowBadge = styled.div`
  position: absolute;
  top: 0px;
  left: 44px;
  border-radius: 0px 4.11px 0px 0px;
  background-color: #fec458;
  width: 133px;
  height: 20px;
`;
const WithParent = styled.div`
  position: absolute;
  top: 20px;
  left: 3px;
  width: 177px;
  height: 20px;
`;
const GivingYouGoodValueParent = styled.div`
  position: absolute;
  top: 22px;
  left: 0px;
  width: 183px;
  height: 40px;
  overflow: hidden;
  color: #111;
  font-family: "Montserrat Alternates";
`;
const PricingTableParent = styled.div`
  position: relative;
  width: 183px;
  height: 62px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 0;
  text-align: center;
  color: #0095ff;
  top:20px`;

const ButtonMonthly = styled.div`
  position: absolute;
  top: calc(50% - 7.5px);
  left: calc(50% - 8px);
  line-height: 6.16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 15px;
`;

const ButtonMonthlyParent = styled.div`
  position: relative;
  width: 78px;
  height: 15px;
  overflow: hidden;
  flex-shrink: 0;
  left: 20px;
`;
const Yearly = styled.span`
margin:0`
;

const Off = styled.span`
  color: #00cec9;
  margin:0
`;
const Yearly20OffContainer1 = styled.span`
  line-break: anywhere;
  width: 100%;
  margin:0
`;
const Yearly20OffContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 10px;
  line-height: 6.16px;
  display: flex;
  align-items: center;
  width: 91px;
  height: 15px;
`;
const Yearly20OffWrapper = styled.div`
  position: relative;
  width: 101px;
  height: 25px;
  // top: 3px;
  overflow: hidden;
  flex-shrink: 0;
  right: 33px;
  top: 4px;
`;
const FrameGroup = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // gap: 24px;
  z-index: 1;
  text-align: center;
  font-size: 12px;
  postion:relative;
  top:12px;
`;
const Heading512 = styled.div`
  position: relative;
  font-size: 16px;
  line-height: 7.59px;
  font-weight: 500;
  
`;
const Strong19 = styled.b`
  position: absolute;
  top: 6px;
  left: 14px;
  font-size: 12.5px;
  line-height: 9.78px;
  display: flex;
  align-items: center;
  width: 209px;
  height: 14px;
`;

const Strong19Parent = styled.div`
  position: absolute;
  width: 209px;
  height: 40px;
  margin-top: 21px;
  overflow: hidden;
  flex-shrink: 0;
  font-family: "Montserrat Alternates";
`;
const ComparePriceShapesvgIcon = styled.img`
  position: relative;
  width: 45px;
  height: 45px;
  overflow: hidden;
  flex-shrink: 0;
`;
const ComparePriceShapesvg = styled.div`
  position: absolute;
  margin-left: 108px;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const CrownsvgIcon = styled.img`
  position: relative;
  width: 11.5px;
  height: 9.9px;
  overflow: hidden;
  flex-shrink: 0;
`;
const Crownsvg = styled.div`
  position: absolute;
  margin-left: 108px;
  top: calc(50% - 4.3px);
  left: 16.7px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Icons = styled.div`
  position: relative;
  width: 45px;
  height: 45px;
`;
const ItemUnlimited = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  line-height: 8.76px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 177px;
  height: 19px;
`;
const ItemUnlimited1 = styled.div`
  position: absolute;
  top: 27px;
  left: 0px;
  line-height: 8.76px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 249px;
  height: 19px;
`;
const ItemUnlimited2 = styled.div`
  position: absolute;
  top: 54px;
  left: 0px;
  line-height: 8.76px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 222px;
  height: 19px;
`;
const ItemUnlimited3 = styled.div`
  position: absolute;
  top: 81px;
  left: 0px;
  line-height: 8.76px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 222px;
  height: 19px;
`;
const ItemUnlimited4 = styled.div`
  position: absolute;
  top: 108px;
  left: 0px;
  line-height: 8.76px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 249px;
  height: 19px;
`;
const ItemUnlimited5 = styled.div`
  position: absolute;
  top: 135px;
  left: 0px;
  line-height: 8.76px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 177px;
  height: 19px;
`;
const ItemUnlimited6 = styled.div`
  position: absolute;
  top: 162px;
  left: 0px;
  line-height: 8.76px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 229px;
  height: 19px;
`;
const ItemUnlimited7 = styled.div`
  position: absolute;
  top: 189px;
  left: 0px;
  line-height: 8.76px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 229px;
  height: 19px;
`;
const ItemUnlimitedResumesParent = styled.div`
  position: relative;
  width: 249px;
  height: 208px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: 13px;
  color: #444;
  top:5px
`;
const StartForFree = styled.b`
  position: absolute;
  top: 8px;
  left: calc(50% - 47px);
  line-height: 13.38px;
  font-size:14px
`;
const Button = styled.div`
  position: relative;
  border-radius: 44.58px;
  background-color: #0095ff;
  width: 125px;
  height: 29px;
  overflow: hidden;
  flex-shrink: 0;
  text-align: center;
  color: #fff;
  left:-5px;
`;
const JobseekerPriceCard = styled.div`
  border-radius: 2.92px;
  background-color: #fff;
  border: 1px solid var(--color-gray-500);
  // border: 0.3px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  
  height: 370px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 19px 24px;
  // gap: 124px;
  z-index: 2;
  color: #111;
`;
const Strong191 = styled.b`
  position: absolute;
  top: 6px;
  left: 14px;
  font-size: 16px;
  line-height: 9.78px;
  display: flex;
  align-items: center;
  width: 209px;
  height: 14px;
`;

const Strong19Group = styled.div`
  position: absolute;
  margin-top: 20px;
  width: 209px;
  height: 50px;
  overflow: hidden;
  flex-shrink: 0;
  font-family: "Montserrat Alternates";
`;
const ItemUnlimited8 = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  line-height: 8.76px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 217px;
  height: 19px;
`;
const ItemUnlimited11 = styled.div`
  position: absolute;
  top: 81px;
  left: 0px;
  line-height: 8.76px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 249px;
  height: 19px;
`;
const ItemUnlimited13 = styled.div`
  position: absolute;
  top: 135px;
  left: 0px;
  line-height: 8.76px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 240px;
  height: 19px;
`;
const ItemUnlimitedResumesGroup = styled.div`
  position: relative;
  width: 249px;
  height: 154px;
  font-size: 13px;
  color: #444;
  
  
`;
const JobseekerPriceCard1 = styled.div`
  border-radius: 2.92px;
  background-color: #fff;
  // border: 0.3px solid rgba(0, 0, 0, 0.1);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  width: 322px;
  height: 370px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 11px 24px;
  // gap: 107px;
  z-index: 3;
  color: #111;
`;
const UniversityPriceCard1 = styled.div`
  border-radius: 2.92px;
  background-color: #fff;
  // border: 0.3px solid rgba(0, 0, 0, 0.1);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  width: 322px;
   height: 370px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 11px 24px;
  // gap: 107px;
  z-index: 3;
  color: #111;
`;
const ImgIcon = styled.img`
  position: relative;
  width: 9px;
  height: 9px;
  overflow: hidden;
  flex-shrink: 0;
`;
const NoCardsRequired = styled.div`
  position: relative;
  line-height: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 48px;
  height: 32px;
  flex-shrink: 0;
`;
const ImgParent = styled.div`
  width: 72px;
  height: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
`;
const DaysFreeTrial = styled.div`
  position: relative;
  line-height: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 40px;
  height: 30px;
  flex-shrink: 0;
`;
const ImgGroup = styled.div`
  width: 69px;
  height: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
`;
const GetStartedIn = styled.div`
  position: relative;
  line-height: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 57px;
  height: 31px;
  flex-shrink: 0;
`;
const ImgContainer = styled.div`
  width: 65px;
  height: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
`;
const FrameContainer = styled.div`
  margin: 0 !important;
  position: absolute;
  top:547px;
  left: 20.5px;
  width: 244px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6px 0px;
  box-sizing: border-box;
  gap: 7px;
  z-index: 4;
  font-size: 10px;
`;
const FrameParent = styled.div`
  position: absolute;
  top: 20px;
  left: calc(50% - 161px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 36px;
`;
const Pricing = styled.div`
  position: absolute;
  width: 100%;
  top: 1400px;
  right: 0px;
  left: 0px;
  height: 991px;
  text-align: left;
  font-size: 14px;
  color: #444;
  font-family: "DM Sans";
`;
const BgRactangleShapepngIcon = styled.img`
  position: absolute;
  width: 100%;
  top: 0px;
  right: 0px;
  left: 0px;
  max-width: 100%;
  overflow: hidden;
  height: 738px;
  object-fit: cover;
`;
const Heading22 = styled.b`
  position: absolute;
  top: 10px;
  left: calc(50% - 121px);
  line-height: 11.99px;
  display: flex;
  align-items: center;
  width: 190px;
  height: 12px;
`;
const Heading514 = styled.div`
  position: absolute;
  top: 36px;
  left: calc(50% - 121px);
  font-size: 16px;
  line-height: 5.34px;
  font-weight: 500;
  font-family: "DM Sans";
  color: #444;
  display: flex;
  align-items: center;
  width: 241px;
  height: 5px;
`;
const DivhappyCustomersTitle = styled.div`
  position: relative;
  width: 244px;
  height: 55px;
`;

const WeUseSmart = styled.div`
  position: relative;
  line-height: 21.44px;
  display: flex;
  align-items: center;
  width: 266px;
  flex-shrink: 0;
`;
const P = styled.div`
  width: 298px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-size: 14px;
`;
const Heading515 = styled.b`
  position: absolute;
  top: 3px;
  left: 5px;
  line-height: 9.96px;
  display: flex;
  align-items: center;
  width: 143px;
`;
const Div3 = styled.div`
  position: absolute;
  top: 0px;
  left: 32px;
  letter-spacing: 1px;
  line-height: 8.3px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 25px;
  height: 13px;
`;
const ArrowRightBlacksvgIcon = styled.img`
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 12.4px;
  height: 12.4px;
`;
const ArrowRightBlacksvgIcon1 = styled.img`
  position: absolute;
  top: calc(50% - 6.1px);
  left: calc(50% - 45.2px);
  width: 12.4px;
  height: 12.4px;
`;
const Parent1 = styled.div`
  position: absolute;
  top: 5px;
  left: 182px;
  width: 90.4px;
  height: 13.4px;
`;
const Heading6 = styled.div`
  position: absolute;
  top: 27px;
  left: 5px;
  font-size: 13px;
  line-height: 9.96px;
  color: #444;
  display: flex;
  align-items: center;
  width: 53px;
  height: 7px;
`;
const DivcustomersName = styled.div`
  position: absolute;
  margin: 0 !important;
  top: 231px;
  left: calc(50% - 139px);
  width: 272px;
  height: 49px;
  z-index: 2;
  font-size: 12px;
`;
const ArrowLeftBlacksvg = styled.div`
  position: absolute;
  margin: 0 !important;
  top: 217.9px;
  left: 351.4px;
  width: 6.6px;
  height: 6.6px;
  z-index: 3;
`;
const DivhappyCustomerLeft = styled.div`
  width: 318px;
  height: 218px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: 38px;
  font-size: 8px;
  font-family: "DM Sans";
`;
const EmojisvgIcon = styled.img`
  position: relative;
  width: 224.4px;
  height: 212px;
  overflow: hidden;
  flex-shrink: 0;
`;
const Pseudo1 = styled.div`
  position: absolute;
  top: 0.9px;
  left: 0.9px;
  border-radius: 22.72px;
  background-color: #eceff1;
  width: 45.4px;
  height: 45.4px;
`;
const HappyCustomers1pngIcon = styled.img`
  position: absolute;
  top: calc(50% - 18.55px);
  left: calc(50% - 18.55px);
  border-radius: 18.54px;
  width: 37.1px;
  height: 37.1px;
  object-fit: cover;
`;
const DivsliderImg = styled.div`
  position: absolute;
  top: 0px;
  left: 81.6px;
  border-radius: 23.64px;
  width: 47.3px;
  height: 47.3px;
  overflow: hidden;
`;
const Divhalfcircle = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% + 23.7px);
  top: 23.6px;
  right: 9.7px;
  bottom: -23.6px;
  left: -33.4px;
  border-radius: 23.64px;
  border: 11.6px solid #111;
  box-sizing: border-box;
  transform: rotate(-45deg);
  transform-origin: 0 0;
`;
const Divhalfclip = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 23.7px);
  top: 0px;
  right: 0.1px;
  bottom: 0px;
  left: 23.6px;
  overflow: hidden;
`;
const DivsliderImg1 = styled.div`
  position: absolute;
  top: 56.6px;
  left: 24.8px;
  border-radius: 23.64px;
  width: 47.3px;
  height: 47.3px;
  overflow: hidden;
`;
const DivsliderImg2 = styled.div`
  position: absolute;
  top: 56.6px;
  left: 138.4px;
  border-radius: 23.64px;
  width: 47.3px;
  height: 47.3px;
  overflow: hidden;
`;
const DivsliderImg3 = styled.div`
  position: absolute;
  top: 113.1px;
  left: 81.6px;
  border-radius: 23.64px;
  width: 47.3px;
  height: 47.3px;
  overflow: hidden;
`;
const DivslickTrack = styled.div`
  // position: absolute;
  height: calc(100% + 0.4px);
  top: 0px;
  bottom: -0.4px;
  left: calc(50% - 108.7px);
  width: 217.9px;
`;
const DivslickList = styled.div`
  position: absolute;
  top: 3px;
  left: calc(50% - 131px);
  width: 249px;
  height: 177px;
  overflow: hidden;
`;
const DivhappyCustomerRight = styled.div`
  position: relative;
  width: 224px;
  height: 180px;
`;
const DivhappyCustomersTitleParent = styled.div`
  position: absolute;
  top: 109px;
  left: calc(50% - 164.5px);
  width: 324px;
  height: 629px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 13px;
  box-sizing: border-box;
  gap: 88px;
`;
const RectangleImageContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0px;
  right: 0px;
  left: 0px;
  height: 738px;
  overflow: hidden;
`;
const HappyCustomers = styled.div`
  position: absolute;
  width: 100%;
  top: 2075px;
  right: 0px;
  left: 0px;
  background-color: #fff;
  height: 738px;
  text-align: left;
  font-family: Jost;
`;
const K = styled.b`
  position: absolute;
  top: 0.8px;
  left: -0.4px;
  line-height: 16.07px;
  display: flex;
  align-items: center;
  width: 39px;
  height: 14px;
`;
const Heading23 = styled.div`
  position: relative;
  width: 57.8px;
  height: 16px;
`;
const SmallBig = styled.div`
  position: relative;
  font-size: 10px;
  line-height: 11.01px;
  font-weight: 500;
  font-family: "DM Sans";
  color: #111;
  display: flex;
  align-items: center;
  width: 58px;
  height: 22px;
  flex-shrink: 0;
`;
const CompaniesContainer = styled.div`
  position: absolute;
  top: 31px;
  left: calc(50% + 89.5px);
  width: 58px;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
`;
const K1 = styled.b`
  position: absolute;
  top: 0.8px;
  left: 0.1px;
  line-height: 16.01px;
  display: flex;
  align-items: center;
  width: 37px;
  height: 14px;
`;
const Heading24 = styled.div`
  position: relative;
  width: 40.6px;
  height: 15.9px;
`;
const RegisteredUser = styled.div`
  position: relative;
  font-size: 10px;
  line-height: 10.96px;
  font-weight: 500;
  font-family: "DM Sans";
  color: #111;
  display: flex;
  align-items: center;
  width: 63px;
  height: 22px;
  flex-shrink: 0;
`;
const UsersContainer = styled.div`
  position: absolute;
  top: 31px;
  left: calc(50% - 20.5px);
  width: 63px;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
`;
const Heading25 = styled.b`
  position: relative;
  line-height: 14.83px;
  display: flex;
  align-items: center;
  width: 36px;
  height: 13px;
  flex-shrink: 0;
`;
const CountriesWorldwide = styled.div`
  position: relative;
  font-size: 10px;
  line-height: 10.16px;
  font-weight: 500;
  font-family: "DM Sans";
  color: #111;
  display: flex;
  align-items: center;
  width: 56px;
  height: 19px;
  flex-shrink: 0;
`;
const CountriesContainer = styled.div`
  position: absolute;
  top: 31px;
  left: calc(50% - 132.5px);
  width: 64px;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1.015703797340393px 0px;
  box-sizing: border-box;
  gap: 10px;
`;
const ContentContainer = styled.div`
  position: absolute;
  top: -11px;
  left: calc(50% - 164.5px);
  width: 329px;
  height: 90px;
`;
const CustomersStatsContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 2805px;
  right: 0px;
  left: 0px;
  border-top: 0.2px solid rgba(0, 0, 0, 0.1);
  border-bottom: 0.2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  height: 90px;
  text-align: left;
  font-size: 14px;
  color: #0095ff;
`;
const TakeMeTo = styled.div`
  position: absolute;
  top: -2px;
  left: 1px;
`;
const ButtonTextContainer = styled.div`
  position: absolute;
  top: 5px;
  left: 9px;
  width: 106px;
  height: 9px;
  overflow: hidden;
`;
const ArrowContainerIcon = styled.img`
  position: absolute;
  top: 4px;
  left: 115px;
  width: 10px;
  height: 10.2px;
`;
const ButtonContainer = styled.div`
  position: absolute;
  top: 366px;
  left: calc(50% - 64px);
  border-radius: 13.14px;
  border: 0.2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  width: 136px;
  height: 19px;
  cursor: pointer;
`;
const VideoRecruiterDemoIcon = styled.img`
  position: relative;
  width: 253px;
  height: 244px;
  object-fit: cover;
`;
const VideoContainer = styled.div`
  position: absolute;
  top: 79px;
  left: calc(50% - 131px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Heading26 = styled.b`
  position: absolute;
  top: 5px;
  left: calc(50% - 102px);
  line-height: 12.73px;
`;
const HeadingContainer = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 136px);
  width: 272px;
  height: 23px;
  overflow: hidden;
  font-size: 16px;
  color: #111;
  font-family: "Montserrat Alternates";
`;
const ButtonContainerParent = styled.div`
  position: absolute;
  top: 24px;
  left: calc(50% - 135.5px);
  width: 272px;
  height: 383px;
`;
const Smartjobseekersdemo = styled.div`
  position: absolute;
  width: 100%;
  top: 2949px;
  right: 0px;
  left: 0px;
  height: 435px;
  font-size: 10px;
  color: #000;
  font-family: Inter;
  z-index: 1;
`;
const AutoApply = styled.b`
  position: absolute;
  top: 3px;
  left: 13px;
  line-height: 12.79px;
`;
const TextContainer13 = styled.div`
  position: absolute;
  top: 9px;
  left: -0.4px;
  width: 123px;
  height: 20px;
  overflow: hidden;
`;
const Partners = styled.b`
  position: relative;
  line-height: 12.79px;
`;
const YellowBadge1 = styled.div`
  position: absolute;
  top: 10px;
  left: 122.6px;
  border-radius: 0px 4.13px 0px 0px;
  background-color: #fec458;
  width: 85px;
  height: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 3.3018176555633545px;
  box-sizing: border-box;
`;
const HeadingContainer1 = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 111.5px);
  width: 239px;
  height: 42px;
`;

const Company1ContainerParent = styled.div`
  position: absolute;
  top: 64px;
  left: calc(50% - 147.5px);
  width: 292px;
  height: 100px;
  overflow-x: auto;
`;
const HeadingContainerParent = styled.div`
  position: absolute;
  top: 13px;
  left: calc(50% - 164.5px);
  width: 329px;
  height: 129px;
  overflow: hidden;
`;
const Autoapply = styled.div`
  position: absolute;
  width: 100%;
  top: 3887px;
  right: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.95);
  height: 161px;
  overflow: hidden;
  font-size: 16px;
`;
const Heading27 = styled.b`
  position: absolute;
  top: 23px;
  left: calc(50% - 89px);
  line-height: 12.63px;
`;
const Resume3Child = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #fff;
  width: 312px;
  height: 434.1px;
`;
const Image3Icon = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 312px;
  height: 434.1px;
  object-fit: cover;
`;
const Resume3 = styled.div`
  position: absolute;
  top: 57px;
  left: calc(50% - 163px);
  width: 312px;
  height: 434.1px;
`;
const TakeMeTo1 = styled.div`
  // position: absolute;
  // top: 4px;
  // left: 13px;
`;
const ButtonTextContainer1 = styled.div`
  position: relative;
  width: 86px;
  height: 12px;
  overflow: hidden;
  flex-shrink: 0;
`;
const ButtonTextContainer2 = styled.div`
  position: relative;
  width: 95px;
  height: 12px;
  overflow: hidden;
  flex-shrink: 0;
`;
const ArrowContainerIcon1 = styled.img`
  position: relative;
  width: 10px;
  height: 10.2px;
  right:4px;
`;
const ButtonContainer1 = styled.div`
  position: absolute;
  top: 520px;
  // left: calc(50% - 62px);
  left: 111px;
  border-radius: 13.14px;
  border: 0.2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 3px 4px;
  font-size: 10px;
  color: #000;
  font-family: Inter;
`;
const Heading2BestFeaturesThatContainer = styled.div`
  position: absolute;
  top: 11px;
  left: calc(50% - 168.5px);
  width: 338px;
  height: 547px;
`;
const Resumetemplates = styled.div`
  position: absolute;
  width: 100%;
  top: 4081px;
  right: 0px;
  left: 0px;
  background-color: #fff;
  height: 558px;
  overflow: hidden;
  font-size: 16px;
`;
const Everything = styled.b`
  position: absolute;
  top: calc(50% - 17px);
  left: calc(50% - 69.5px);
  line-height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 139px;
  height: 34px;
`;
const YellowText = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 165px);
  border-radius: 0px 20px 0px 0px;
  background-color: #fec458;
  width: 163px;
  height: 44px;
`;
const AtOnePlace = styled.b`
  position: absolute;
  top: 4px;
  left: calc(50% - 2px);
  line-height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 159px;
  height: 36px;
`;
const YellowTextParent = styled.div`
  position: absolute;
  top: 24px;
  left: calc(50% - 164.5px);
  width: 330px;
  height: 44px;
`;
const Recruiters = styled.b`
  position: absolute;
  top: 0px;
  left: calc(50% - 87px);
  letter-spacing: 3.6px;
  line-height: 30px;
  text-transform: uppercase;
`;
const NoWaitingFor = styled.div`
  position: absolute;
  top: 39px;
  left: 0px;
  line-height: 25.33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 301px;
  height: 53px;
`;
const Heading61 = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 110.5px);
  font-size: 20px;
  line-height: 34.33px;
  font-weight: 600;
  font-family: "Plus Jakarta Sans";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 221px;
`;
const NoWaitingForQuotesFromTheParent = styled.div`
  position: absolute;
  top: 0px;
  left: 14px;
  width: 301px;
  height: 92px;
  overflow: hidden;
`;
const InHouseCandidates = styled.div`
  position: absolute;
  top: 39px;
  left: 19px;
  line-height: 25.33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 291px;
  height: 53px;
`;
const Heading62 = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 165px);
  font-size: 20px;
  line-height: 34.33px;
  font-weight: 600;
  font-family: "Plus Jakarta Sans";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 330px;
`;
const InHouseCandidatesDatabaseAParent = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 330px;
  height: 92px;
  overflow: hidden;
`;
const FrameWrapper = styled.div`
  position: absolute;
  top: 116px;
  left: 0px;
  width: 330px;
  height: 92px;
`;
const FrameParent2 = styled.div`
  position: absolute;
  top: 62px;
  left: 0px;
  width: 330px;
  height: 208px;
  overflow: hidden;
  font-size: 14px;
  color: #000;
  font-family: Rubik;
`;
const RecruitersParent = styled.div`
  position: absolute;
  top: 318px;
  left: calc(50% - 165px);
  width: 330px;
  height: 270px;
  overflow: hidden;
`;
const JobSeekers = styled.b`
  position: absolute;
  top: 0px;
  left: calc(50% - 95.5px);
  letter-spacing: 3.6px;
  line-height: 30px;
  text-transform: uppercase;
`;
const OurAllThe = styled.div`
  position: absolute;
  top: 39px;
  left: 27px;
  line-height: 25.33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 265px;
  height: 66px;
`;
const Heading63 = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 157px);
  font-size: 20px;
  line-height: 34.33px;
  font-weight: 600;
  font-family: "Plus Jakarta Sans";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 313px;
`;
const OurAllTheClientsVacancyUnParent = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 160px);
  width: 320px;
  height: 101px;
  overflow: hidden;
`;
const EverythingYouRequired = styled.div`
  position: absolute;
  top: 39px;
  left: calc(50% - 108.5px);
  line-height: 25.33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 217px;
  height: 53px;
`;
const Heading64 = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 124.5px);
  font-size: 20px;
  line-height: 34.33px;
  font-weight: 600;
  font-family: "Plus Jakarta Sans";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 249px;
`;
const EverythingYouRequiredToGetParent = styled.div`
  position: absolute;
  top: 125px;
  left: calc(50% - 124px);
  width: 249px;
  height: 92px;
`;
const GroupDiv = styled.div`
  position: absolute;
  top: 62px;
  left: calc(50% - 160.5px);
  width: 320px;
  height: 217px;
  font-size: 14px;
  color: #000;
  font-family: Rubik;
`;
const JobSeekersParent = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 161px);
  width: 321px;
  height: 279px;
  overflow: hidden;
`;
const FrameParent1 = styled.div`
  position: absolute;
  top: 102px;
  left: calc(50% - 164.5px);
  width: 330px;
  height: 612px;
  overflow: hidden;
  color: #0095ff;
  font-family: "DM Sans";
`;
const Whyus1 = styled.div`
  position: absolute;
  width: calc(100% - 10px);
  top: 30px;
  right: 2px;
  left: 8px;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #fff;
  box-sizing: border-box;
  height: 753px;
`;
const Whyus = styled.div`
  position: absolute;
  width: 100%;
  top: 4635px;
  right: 0px;
  left: 0px;
  height: 791px;
  overflow: hidden;
  font-size: 24px;
  color: #000;
`;
const BgRactangleShapepngIcon1 = styled.img`
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  z-index: 0;
`;
const Heading28 = styled.b`
  position: absolute;
  top: 5px;
  left: 41px;
  line-height: 12.73px;
`;
const HeadingContainer2 = styled.div`
  position: relative;
  width: 272px;
  height: 23px;
  overflow: hidden;
  flex-shrink: 0;
`;
const VideoContainer1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
const TakeMeTo2 = styled.div`
  // position: absolute;
  top: 4px;
  left: 6px;
  height: 1px;
`;
const ButtonContainer2 = styled.div`
  border-radius: 13.14px;
  border: 0.2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 3px 4px;
  font-size: 10px;
  color: #000;
  font-family: Inter;
  cursor: pointer;
`;
const HeadingContainerGroup = styled.div`
  margin: 0 !important;
  position: absolute;
  top: 47px;
  left: calc(50% - 151.5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 15px;
  gap: 31px;
  z-index: 1;
`;
const Smartjobseekersdemo1 = styled.div`
  position: absolute;
  width: 100%;
  top: 3400px;
  right: 0px;
  left: 0px;
  height: 440px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  font-size: 16px;
`;
// const FacebooksvgIcon = styled.img`
//   position: relative;
//   width: 32px;
//   height: 34px;
//   overflow: hidden;
//   flex-shrink: 0;
//   opacity: 0.7;
// `;
// const ItemLinkFacebooksvg = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: flex-start;
//   justify-content: flex-start;
//   opacity: 0.7;
// `;
// const Icons8Twitter1 = styled.img`
//   position: relative;
//   width: 22px;
//   height: 30px;
//   overflow: hidden;
//   flex-shrink: 0;
//   opacity: 0.7;
// `;
// const InstagramsvgIcon = styled.img`
//   position: relative;
//   width: 27px;
//   height: 33px;
//   overflow: hidden;
//   flex-shrink: 0;
//   opacity: 0.7;
// `;
// const LinkedinsvgIcon = styled.img`
//   position: relative;
//   width: 34.1px;
//   height: 34.9px;
//   overflow: hidden;
//   opacity: 0.7;
//   flex-shrink: 0;
// `;
const List1 = styled.div`
  position: absolute;
  top: 193px;
  left: 100px;
  width: 320px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6.072000503540039px 0px 4.194666385650635px;
  box-sizing: border-box;
  gap: 20.53px;
`;
const Heading65 = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 4.46px;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
  display: flex;
  align-items: center;
  width: 84px;
  height: 14px;
  flex-shrink: 0;
`;
const ItemLink = styled.div`
  position: relative;
  line-height: 23.61px;
  display: flex;
  align-items: center;
  width: 118px;
  height: 20px;
  flex-shrink: 0;
    cursor: pointer;
`;
const Products = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 147.5px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`;
const Heading66 = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 4.46px;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
  display: flex;
  align-items: center;
  width: 100px;
  height: 14px;
  flex-shrink: 0;
`;
const ItemLink4 = styled.div`
  position: relative;
  line-height: 23.61px;
  display: flex;
  align-items: center;
  width: 132px;
  height: 20px;
  flex-shrink: 0;
`;
const Heading6ProductsParent = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% + 15.5px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`;
const ProductsParent = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 142.5px);
  width: 295px;
  height: 154px;
  overflow: hidden;
`;
const AllRightsReserved1 = styled.div`
  position: relative;
  line-height: 6.11px;
  font-weight: 500;
`;
const AllRightsReserved = styled.div`
  position: absolute;
  top: 271px;
  left: calc(50% - 75.5px);
  width: 146px;
  height: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 3.0532350540161133px;
  box-sizing: border-box;
`;
const ListParent = styled.div`
  position: absolute;
  top: 30px;
  left: calc(50% - 230.5px);
  width: 461px;
  height: 295px;
  overflow: hidden;
`;
const WebsiteFooter = styled.div`
  position: absolute;
  width: 100%;
  top: 5370px;
  right: 0px;
  left: 0px;
  background-color: rgba(25, 28, 31, 0.1);
  height: 354px;
  text-align: left;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
  font-family: "DM Sans";
`;
const Finalmobile390To820Root = styled.div`
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 5649px;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  font-size: 20px;
  color: #111;
  font-family: "Montserrat Alternates";
  // zoom: 85%;

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const FinalMobile390To820 = () => {
  // const [isMobileSideBarOpen, setMobileSideBarOpen] = useState(false);
  const navigate = useNavigate();

  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [locationError, setLocationError] = useState(null);
  const [isInIndia, setIsInIndia] = useState(false);

  const isInIndiaCheck = (latitude, longitude) => {
    return (
      latitude >= 8.067 &&
      latitude <= 37.1 &&
      longitude >= 68.117 &&
      longitude <= 97.417
    );
  };
  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
            const inIndia = isInIndiaCheck(latitude, longitude);
            setIsInIndia(inIndia);
          },
          (error) => {
            setLocationError(error.message);
          }
        );
      } else {
        setLocationError("Geolocation is not supported by this browser.");
      }
    };

    getLocation();
  }, []);

  const onBuildAndEnhanceResumeContainer1Click = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='interviewPrepContainer']",
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "nearest", behavior: "smooth" });
    }
  }, []);

  const onInterviewPrepContainerClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='buildCoverLetter']",
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "nearest", behavior: "smooth" });
    }
  }, []);

  const onBuildCoverLetterClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='careerAdviseContainer']",
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "nearest", behavior: "smooth" });
    }
  }, []);

  const onCareerAdviseContainerClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='jobSearchContainer']",
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "nearest", behavior: "smooth" });
    }
  }, []);

  const onJobSearchContainerClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='autoJobApply']");
    if (anchor) {
      anchor.scrollIntoView({ block: "nearest", behavior: "smooth" });
    }
  }, []);
  const handleJobSeekersdemo = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='JobSeekersdemo']",
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const handlerecruiterdemo = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='recruiterdemo']",
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onButtonContainer2Click = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  // const navigate = useNavigate();

  const onJobSeekerContainerClick = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  const [revstate, SetrevState] = useState(0);

  const reviewname = ["Srinivas", "Shrashti", "Somesh", "Jatin"];

  const reviewcompany = [
    "HR Manger, Culinda Inc.",
    "Data Scientist, Culinda Inc.",
    "ML Engg. Culinda Inc.",
    "ML Intern, Magix DB",
  ];

  const reviewcontent = [
    "We use Smart Recruiter for hiring for all positions in our company.️ Smart recruiter has cut down our cost for recruiting team. We don't need a recruiting team to schedule interviews and follow ups. We are able to hire people 10X faster.",
    "RecRoid revolutionized our hiring process, cutting costs and accelerating recruitment by 10x. No need for a dedicated recruiting team; it's all automated and streamlined.",
    "RecRoid is a game-changer. With its advanced automation, we've not only cut recruitment costs significantly but also managed to hire top-tier talent in record time. No more manual scheduling or follow-ups; the platform handles it all seamlessly. It's a win-win for our organization.",
    "RecRoid is a must-have for efficient hiring. Our hiring speed improved by an impressive 10x, and we've seen a remarkable reduction in hiring costs. Our hiring process is not just faster but also more cost-effective, making it an indispensable tool for any organization.",
  ];

  const jobseekersDataLandingPage = [
    {
      onClick: "onBuildAndEnhanceResumeContainerClick",
      imageSrc: "/resume-builder-image@2x.png",
      heading: "Build & Enhance Resume",
      description:
        "Create a personalized resume on professional templates. Build from scratch or various sources. Enhance Resume using AI",
    },
    {
      onClick: "onInterviewPrepContainer1Click",
      imageSrc: "/interview-prep-image@2x.png",
      heading: "Interview Preparation",
      description:
        "Prepare for live interviews for jobs and academics and get a detailed analysis of your performance in content, language & body language.",
    },
    {
      onClick: "onBuildCoverLetter1Click",
      imageSrc: "/cover-letter-image@2x.png",
      heading: "Build Cover Letter",
      description:
        "Craft compelling cover letter with our AI powered technology.",
    },
    {
      onClick: "onCareerAdviseContainer1Click",
      imageSrc: "/career-advise-image@2x.png",
      heading: "Career Advise",
      description:
        "Get career advice with jobs, research & college lists along with salary, stipend & expenses from our highly curated database with an AI powered counsellor.",
    },
    {
      onClick: "onJobSearchContainer1Click",
      imageSrc: "/job-search-image@2x.png",
      heading: "Job Search",
      description:
        "Look for jobs on 50 plus platforms and apply directly. Get access to undisclosed jobs in the market too.",
    },
    {
      onClick: "onAutoJobApply1Click",
      imageSrc: "/autojob-apply-image@2x.png",
      heading: "Auto Job Apply",
      description:
        "Relax, while our bots apply for relevant jobs for you to our 20+ auto jobs partners.",
    },
  ];
  // const pricingDataLandingPage = [
  //   {
  //     onClick: "onBuildAndEnhanceResumeContainerClick",
  //     imageSrc: "/resume-builder-image@2x.png",
  //     heading: "Jobseekers",
  //     description:
  //       "Create a personalized resume on professional templates. Build from scratch or various sources. Enhance Resume using AI",
  //   },
  //   {
  //     onClick: "onInterviewPrepContainer1Click",
  //     imageSrc: "/interview-prep-image@2x.png",
  //     heading: "Interview Preparation",
  //     description:
  //       "Prepare for live interviews for jobs and academics and get a detailed analysis of your performance in content, language & body language.",
  //   },
  //   {
  //     onClick: "onBuildCoverLetter1Click",
  //     imageSrc: "/cover-letter-image@2x.png",
  //     heading: "Build Cover Letter",
  //     description:
  //       "Craft compelling cover letter with our AI powered technology.",
  //   },

  // ];

  const ForRecruiterData = [
    {
      component: "BuildAndEnhanceResume1",
      onClick: onBuildAndEnhanceResumeContainer1Click,
      imageSrc: "/integrate-emails-container@2x.png",
      heading: "Integrate Emails",
      description:
        "Integrate Outlook, G-suite or any other professional email box where you receive candidates profiles.",
    },
    {
      component: "InterviewPrep1",
      dataScrollTo: "interviewPrepContainer",
      onClick: onInterviewPrepContainerClick,
      imageSrc: "/centralised-database-image@2x.png",
      heading: "Centralised database",
      description:
        "Maintain candidates resume, cover letters, their performance on assignments and interviews.",
    },
    {
      component: "BuildCoverLetter1",
      dataScrollTo: "buildCoverLetter",
      onClick: onBuildCoverLetterClick,
      imageSrc: "/sends-assesment-image@2x.png",
      heading: "Send Automated Assessments",
      description:
        "Once the portfolio is shortlisted the smart Recruiter sends an assessment to the candidate for the first round of the test.",
    },
    {
      component: "CareerAdvise1",
      dataScrollTo: "careerAdviseContainer",
      onClick: onCareerAdviseContainerClick,
      imageSrc: "/shortlist-profiles-image@2x.png",
      heading: "Shortlisted Profiles",
      description:
        "Profiles are shortlisted based on the performance of the candidate on the assessment. Flexibility to set the exam cutoff.",
    },
    {
      component: "JobSearch1",
      dataScrollTo: "jobSearchContainer",
      onClick: onJobSearchContainerClick,
      imageSrc: "/schedule-interview-image@2x.png",
      heading: "Schedule Interviews",
      description:
        "Interviewers' profiles can be added & interviews are scheduled through mails automatically, after matching the available slots of the interviewers & the candidates.",
    },
    {
      component: "AutoJobApply1",
      dataScrollTo: "autoJobApply",
      imageSrc: "/send-results-image@2x.png",
      heading: "Send Out Results",
      description:
        "After the feedback from the interviewers, the feedback is mailed to candidates.",
    },
  ];

  const [currentSvg, setCurrentSvg] = useState(1);

  const toggleSVG = () => {
    setCurrentSvg((prev) => (prev < 10 ? prev + 1 : 1));
  };

  useEffect(() => {
    const interval = setInterval(toggleSVG, 2000); // Change SVG every 2 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Finalmobile390To820Root>
        <WebsiteHeader>
          <Header>
            <WebsiteNameFrameParent>
              <WebsiteNameFrame>
                <WebsiteName style={{ color: "rgb(49 76 163)" }}>
                  RecRoid
                </WebsiteName>
              </WebsiteNameFrame>
              <LogoFrame>
                <RecruiterRoboLogo31Icon alt="" src="/recroid.png" />
              </LogoFrame>
            </WebsiteNameFrameParent>
          </Header>
          <HamburgerMenu />
        </WebsiteHeader>
        <OpeningPage>
          <GroupParent>
            <TextContainerWrapper>
              <TextContainer>
                <DemocratizingRecruitment>
                  Democratizing Recruitment
                </DemocratizingRecruitment>
              </TextContainer>
            </TextContainerWrapper>
            <TextAnimation>
              <Resumebuilder31AiRecruitern>
                <div>
                  {currentSvg === 1 && (
                    <div className="svg-container-mobile">
                      <img
                        className="animated-svg"
                        src="images/text_resume_builder.svg"
                        alt="resume"
                      />
                    </div>
                  )}

                  {currentSvg === 2 && (
                    <div className="svg-container-mobile">
                      <img
                        className="animated-svg"
                        src="images/text_cover_letter.svg"
                        alt="resume"
                      />
                    </div>
                  )}

                  {currentSvg === 3 && (
                    <div className="svg-container-mobile">
                      <img
                        className="animated-svg"
                        src="images/text_job_search.svg"
                        alt="resume"
                      />
                    </div>
                  )}

                  {currentSvg === 4 && (
                    <div className="svg-container-mobile">
                      <img
                        className="animated-svg"
                        src="images/text_interview_prep.svg"
                        alt="resume"
                      />
                    </div>
                  )}
                  {currentSvg === 5 && (
                    <div className="svg-container-mobile">
                      <img
                        className="animated-svg"
                        src="images/text_career_advisor.svg"
                        alt="resume"
                      />
                    </div>
                  )}
                  {currentSvg === 6 && (
                    <div className="svg-container-mobile">
                      <img
                        className="animated-svg"
                        src="images/text_auto_job_apply.svg"
                        alt="resume"
                      />
                    </div>
                  )}
                  {currentSvg === 7 && (
                    <div className="svg-container-mobile">
                      <img
                        className="animated-svg"
                        src="images/text_free_candidates_db.svg"
                        alt="resume"
                      />
                    </div>
                  )}
                  {currentSvg === 8 && (
                    <div className="svg-container-mobile">
                      <img
                        className="animated-svg"
                        src="images/text_automated_assessments.svg"
                        alt="resume"
                      />
                    </div>
                  )}
                  {currentSvg === 9 && (
                    <div className="svg-container-mobile">
                      <img
                        className="animated-svg"
                        src="images/text_auto_profile_shortlisting.svg"
                        alt="resume"
                      />
                    </div>
                  )}
                  {currentSvg === 10 && (
                    <div className="svg-container-mobile">
                      <img
                        className="animated-svg"
                        src="images/text_auto_interview_scheduler.svg"
                        alt="resume"
                      />
                    </div>
                  )}
                </div>
              </Resumebuilder31AiRecruitern>
            </TextAnimation>
            <ImageContainer2>
              <OpeningPageGifIcon alt="" src="Opening_page_gif.gif" />
            </ImageContainer2>
          </GroupParent>
        </OpeningPage>
        <Heading2BestFeaturesThatParent id="scroll-to-products">
          <Heading2>For Job Seekers</Heading2>
          <Jobseekerss>
            <Carousel
              className=" flex flex-row justify-center items-center"
              // autoPlay
              // infiniteLoop
              showArrows={false}
              showStatus={false}
              showThumbs={false}
            >
              {jobseekersDataLandingPage.map((Item, index) => (
                <BuildAndEnhanceResume
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#f9f1ff" : "#caf5fa",
                  }}
                >
                  <ImageContainer>
                    <ResumeBuilderImageIcon alt="" src={Item.imageSrc} />
                  </ImageContainer>
                  <TextContainer1>
                    <Heading5>{Item.heading}</Heading5>
                    <CreateAPersonalized>
                      {Item.description}
                    </CreateAPersonalized>
                  </TextContainer1>
                </BuildAndEnhanceResume>
              ))}
            </Carousel>
          </Jobseekerss>
        </Heading2BestFeaturesThatParent>

        <Heading2BestFeaturesThatGroup>
          <Heading21>For Recruiters</Heading21>
          <Jobseekerss>
            <Carousel
              className=" flex flex-row justify-center items-center"
              // autoPlay
              // infiniteLoop
              showArrows={false}
              showStatus={false}
              showThumbs={false}
            >
              {ForRecruiterData.map((Item, index) => (
                <BuildAndEnhanceResume
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#caf5fa" : "#f9f1ff",
                  }}
                // onClick={() => window[Item.onClick]()}
                >
                  <ImageContainer>
                    <ResumeBuilderImageIcon alt="" src={Item.imageSrc} />
                  </ImageContainer>
                  <TextContainer1>
                    <Heading5>{Item.heading}</Heading5>
                    <CreateAPersonalized>
                      {Item.description}
                    </CreateAPersonalized>
                  </TextContainer1>
                </BuildAndEnhanceResume>
              ))}
            </Carousel>
          </Jobseekerss>
        </Heading2BestFeaturesThatGroup>

        <Pricing id="scroll-to-Pricing">
          <FrameParent id="target">
            <PricingTableParent>
              <PricingTable>Pricing Table</PricingTable>
              <GivingYouGoodValueParent>
                <GivingYouGood>Giving you good value</GivingYouGood>
                <WithParent>
                  <With>{`with `}</With>
                  <YellowBadge>
                    <BestService>best service</BestService>
                  </YellowBadge>
                </WithParent>
              </GivingYouGoodValueParent>
            </PricingTableParent>
            <FrameGroup>
              <ButtonMonthlyParent>
                <ButtonMonthly>Monthly</ButtonMonthly>

              </ButtonMonthlyParent>
              <div
                style={{
                  transform: "scale(0.5)",
                }}
              >
                <div onClick={handleToggle}>
                  <ToggleButton />
                </div>
              </div>

              <Yearly20OffWrapper>
                <Yearly20OffContainer>
                  <Yearly20OffContainer1>
                    <Yearly>{`Yearly `}</Yearly>
                    <Off>(20% Off)</Off>
                  </Yearly20OffContainer1>
                </Yearly20OffContainer>
              </Yearly20OffWrapper>
            </FrameGroup>

            <Jobseekerss>
              <Carousel
                className=" flex flex-row justify-center items-center"
                // autoPlay
                // infiniteLoop
                showArrows={false}
                showStatus={false}
                showThumbs={false}
              >
                <JobseekerPriceCard>
                  <Heading512>For Job Seekers</Heading512>
                  <Strong19Parent>
                    <Strong19>
                      {isInIndia ? (
                        isToggled ? (
                          // <>
                          //   ₹ 7655 per year
                          //   <br />
                          //   (₹ 510 per month)
                          // </>
                          <div className="relative top-[5px]">
                          <Text className="mb-[2px] text-[12.5px]">   ₹ 7655 per year</Text>
                          <Text className="font-medium text-[10.5px]">   (₹ 510 per month)</Text>
                        </div>
                        ) : (
                          "₹ 799 per month"
                        )
                      ) : isToggled ? (
                        // <>
                        //   $ 96 per year
                        //   <br />
                        //   ($ 8 per month)
                        // </>
                        <div className="relative top-[5px]">
                        <Text className="mb-[2px] text-[12.5px]">  $ 96 per year</Text>
                        <Text className="font-medium text-[10.5px]">  ($ 8 per month)</Text>
                      </div>
                      ) : (
                        <Text className="text-[12.5px]">"$ 9.99 per month"</Text>
                      )}
                    </Strong19>

                  </Strong19Parent>
                  <Icons>
                    <ComparePriceShapesvg>
                      <ComparePriceShapesvgIcon
                        alt=""
                        src="/comparepriceshapesvg.svg"
                      />
                    </ComparePriceShapesvg>
                    <Crownsvg>
                      <CrownsvgIcon alt="" src="/crownsvg.svg" />
                    </Crownsvg>
                  </Icons>
                  <ItemUnlimitedResumesParent>
                    <ItemUnlimited>Unlimited Resume Builders</ItemUnlimited>
                    <ItemUnlimited1>Unlimited Resume Enhancements</ItemUnlimited1>
                    <ItemUnlimited2>Unlimited Cover Letters</ItemUnlimited2>
                    <ItemUnlimited3>Unlimited automation templates</ItemUnlimited3>
                    <ItemUnlimited4>
                      200mins of interview preparations
                    </ItemUnlimited4>
                    <ItemUnlimited5>Unlimited job search</ItemUnlimited5>
                    <ItemUnlimited6>Unlimited auto job apply</ItemUnlimited6>
                    <ItemUnlimited7>Access to undisclosed jobs</ItemUnlimited7>
                  </ItemUnlimitedResumesParent>
                  <Link to="/login">
                    <Button>
                      <StartForFree>
                        {isToggled ? "Purchase Now" : "Start For Free"}
                      </StartForFree>
                    </Button>
                  </Link>
                </JobseekerPriceCard>
                <JobseekerPriceCard1>
                  <Heading512 className="top-[10px]">For Recruiters</Heading512>
                  <Strong19Group >
                    <Strong191 >
                      {isInIndia ? (
                        isToggled ? (
                          <div className=" mt-[10px] relative top-[10px]">
                            <Text className="text-[12.5px] mb-[2px]" >   ₹ 47280 per year</Text>
                            <Text className="font-medium text-[10.5px]">  (₹ 3940 per month)</Text>
                          </div>
                          // <>
                          //   ₹ 47280 per year
                          //   <br />
                          //   (₹ 3940 per month)
                          // </>
                        ) : (
                          <Text className="text-[12.5px]" > "₹ 4920 per month"</Text>
                        )
                      ) : isToggled ? (
                        <div className="mt-[10px] relative top-[10px]">

                          <Text className="text-[12.5px] mb-[2px]">    $ 566 per yearr</Text>

                          <Text className="font-medium text-[10.5px]"> ($ 47.20 per month)</Text>

                        </div>
                        // <>
                        //   $ 566 per year
                        //   <br />
                        //   ($ 47.20 per month)
                        // </>
                      ) : (
                        <Text className="text-[12.5px] mt-[16px]" > $ 59 per month</Text>
                      )}
                    </Strong191>

                  </Strong19Group>
                  <Icons>
                    <ComparePriceShapesvg>
                      <ComparePriceShapesvgIcon
                        alt=""
                        src="/comparepriceshapesvg.svg"
                      />
                    </ComparePriceShapesvg>
                    <Crownsvg>
                      <CrownsvgIcon alt="" src="/crownsvg.svg" />
                    </Crownsvg>
                  </Icons>
                  <ItemUnlimitedResumesGroup className="top-[-8px]">
                    <ItemUnlimited8>Unlimited Resume Processing</ItemUnlimited8>
                    <ItemUnlimited1>
                      Unlimited auto interview schedules
                    </ItemUnlimited1>
                    <ItemUnlimited2>Free centralised databases</ItemUnlimited2>
                    <ItemUnlimited11>
                      Access to 5K free candidate profiles
                    </ItemUnlimited11>
                    <ItemUnlimited4>Great customer support</ItemUnlimited4>
                    <ItemUnlimited13>{`Unlimited job uploads & shortlisting`}</ItemUnlimited13>
                  </ItemUnlimitedResumesGroup>
                  {/* <Link to="/recruiter-login"> */}
                    <Button>
                      <StartForFree>
                        {/* {isToggled ? "Purchase Now" : "Start For Free"} */}
                        Coming Soon
                      </StartForFree>
                    </Button>
                  {/* </Link> */}
                </JobseekerPriceCard1>
                <UniversityPriceCard1>
                  <Heading512 className="top-[10px]">For University</Heading512>
                  <Strong19Group>
                    <Strong191>
                      {isInIndia ? (
                        isToggled ? (
                          <div className=" mt-[10px] relative top-[10px]">

                            <Text className="text-[12.5px] mb-[2px]">    ₹ 47280 per year</Text>

                            <Text  className="font-medium text-[10.5px]">    (₹ 3940 per month)</Text>

                          </div>
                          // <>
                          //   ₹ 47280 per year
                          //   <br />
                          //   (₹ 3940 per month)
                          // </>
                        ) : (
                          "₹ 4920 per month"
                        )
                      ) : isToggled ? (
                        <div className=" mt-[10px] relative top-[10px]">

                          <Text className="text-[12.5px] mb-[2px]">   $ 566 per year</Text>

                          <Text  className="font-medium text-[10.5px]"> ($ 47.20 per month)</Text>

                        </div>
                        // <>
                        //   $ 566 per year
                        //   <br />
                        //   ($ 47.20 per month)
                        // </>
                      ) : (
                        "$ 59 per month"
                      )}
                    </Strong191>

                  </Strong19Group>
                  <Icons>
                    <ComparePriceShapesvg>
                      <ComparePriceShapesvgIcon
                        alt=""
                        src="/comparepriceshapesvg.svg"
                      />
                    </ComparePriceShapesvg>
                    <Crownsvg>
                      <CrownsvgIcon alt="" src="/crownsvg.svg" />
                    </Crownsvg>
                  </Icons>
                  <ItemUnlimitedResumesGroup>
                    <ItemUnlimited8>Unlimited Resume Processing</ItemUnlimited8>
                    <ItemUnlimited1>
                      Unlimited auto interview schedules
                    </ItemUnlimited1>
                    <ItemUnlimited2>Free centralised databases</ItemUnlimited2>
                    <ItemUnlimited11>
                      Access to 5K free candidate profiles
                    </ItemUnlimited11>
                    <ItemUnlimited4>Great customer support</ItemUnlimited4>
                    <ItemUnlimited13>{`Unlimited job uploads & shortlisting`}</ItemUnlimited13>
                  </ItemUnlimitedResumesGroup>
                  {/* <Link to="/university-login"> */}
                    <Button>
                      <StartForFree>
                        {/* {isToggled ? "Purchase Now" : "Start For Free"} */}
                        Coming Soon
                      </StartForFree>
                    </Button>
                  {/* </Link> */}
                </UniversityPriceCard1>
              </Carousel>
            </Jobseekerss>
            <FrameContainer>
              <ImgParent>
                <ImgIcon alt="" src="/img.svg" />
                <NoCardsRequired>No cards required</NoCardsRequired>
              </ImgParent>
              <ImgGroup>
                <ImgIcon alt="" src="/img.svg" />
                <DaysFreeTrial>7 days free trial</DaysFreeTrial>
              </ImgGroup>
              <ImgContainer>
                <ImgIcon alt="" src="/img.svg" />
                <GetStartedIn>Get started in 2 mins</GetStartedIn>
              </ImgContainer>
            </FrameContainer>
          </FrameParent>
        </Pricing>
        <HappyCustomers>
          <RectangleImageContainer>
            <BgRactangleShapepngIcon alt="" src="/bgractangleshapepng@2x.png" />
            <DivhappyCustomersTitleParent>
              <DivhappyCustomersTitle>
                <Heading22>Happy customers</Heading22>
                <Heading514>Worldwide 30,000+ customer</Heading514>
              </DivhappyCustomersTitle>
              <DivhappyCustomerLeft>
                <P>
                  <WeUseSmart>{reviewcontent[revstate]}</WeUseSmart>
                </P>
                <DivcustomersName>
                  <Heading515>{reviewcompany[revstate]}</Heading515>
                  <Parent1>
                    <Div3>{revstate + 1}/4</Div3>
                    <ArrowRightBlacksvgIcon
                      alt=""
                      src="/arrowrightblacksvg.svg"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        revstate === 3 ? "" : SetrevState(revstate + 1)
                      }
                    />
                    <ArrowRightBlacksvgIcon1
                      alt=""
                      src="/arrowrightblacksvg1.svg"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        revstate === 0 ? "" : SetrevState(revstate - 1)
                      }
                    />
                  </Parent1>
                  <Heading6>{reviewname[revstate]}</Heading6>
                </DivcustomersName>
                <ArrowLeftBlacksvg />
              </DivhappyCustomerLeft>
              <DivhappyCustomerRight>
                <ComparePriceShapesvg>
                  <EmojisvgIcon alt="" src="/emojisvg.svg" />
                </ComparePriceShapesvg>
                <DivslickList>
                  <DivslickTrack>
                    <DivsliderImg>
                      <Pseudo1 />
                      <HappyCustomers1pngIcon
                        alt=""
                        src="/happycustomers1png@2x.png"
                      />
                    </DivsliderImg>
                    <DivsliderImg1>
                      <Divhalfclip>
                        <Divhalfcircle />
                      </Divhalfclip>
                      <Pseudo1 />
                      <HappyCustomers1pngIcon
                        alt=""
                        src="/happycustomers2png@2x.png"
                      />
                    </DivsliderImg1>
                    <DivsliderImg2>
                      <Pseudo1 />
                      <HappyCustomers1pngIcon
                        alt=""
                        src="/happycustomers3png@2x.png"
                      />
                    </DivsliderImg2>
                    <DivsliderImg3>
                      <Pseudo1 />
                      <HappyCustomers1pngIcon
                        alt=""
                        src="/happycustomers4png@2x.png"
                      />
                    </DivsliderImg3>
                  </DivslickTrack>
                </DivslickList>
              </DivhappyCustomerRight>
            </DivhappyCustomersTitleParent>
          </RectangleImageContainer>
        </HappyCustomers>
        <CustomersStatsContainer>
          <ContentContainer>
            <CompaniesContainer>
              <Heading23>
                <K>23K</K>
              </Heading23>
              <SmallBig>{`Small & Big Companies`}</SmallBig>
            </CompaniesContainer>
            <UsersContainer>
              <Heading24>
                <K1>156K</K1>
              </Heading24>
              <RegisteredUser>Registered User</RegisteredUser>
            </UsersContainer>
            <CountriesContainer>
              <Heading25>200</Heading25>
              <CountriesWorldwide>Countries Worldwide</CountriesWorldwide>
            </CountriesContainer>
          </ContentContainer>
        </CustomersStatsContainer>
        <Smartjobseekersdemo data-scroll-to="JobSeekersdemo">
          <ButtonContainerParent>
            <ButtonContainer onClick={onButtonContainer2Click}>
              <ButtonTextContainer>
                <TakeMeTo>To Smart Job Seeker</TakeMeTo>
              </ButtonTextContainer>
              <ArrowContainerIcon alt="" src="/arrow-container.svg" />
            </ButtonContainer>
            <VideoContainer>
              <VideoRecruiterDemoIcon
                alt=""
                src="/video-recruiter-demo@2x.png"
              />
            </VideoContainer>
            <HeadingContainer>
              <Heading26>Smart Job Seeker Demo</Heading26>
            </HeadingContainer>
          </ButtonContainerParent>
        </Smartjobseekersdemo>
        <Autoapply>
          <HeadingContainerParent>
            <HeadingContainer1>
              <TextContainer13>
                <AutoApply>Auto Apply</AutoApply>
              </TextContainer13>
              <YellowBadge1>
                <Partners>partners</Partners>
              </YellowBadge1>
            </HeadingContainer1>
            <Company1ContainerParent>
              <MCarousel />
            </Company1ContainerParent>
          </HeadingContainerParent>
        </Autoapply>

        <Resumetemplates>
          <Heading2BestFeaturesThatContainer>
            <Heading27>Resume Templates</Heading27>
            <Resume3>
              <Resume3Child />
              <Image3Icon alt="" src="/image-3@2x.png" />
            </Resume3>
            <Link to="/all-resumetemplates">
              <ButtonContainer1>
                <ButtonTextContainer2>
                  <TakeMeTo1>More templates</TakeMeTo1>
                </ButtonTextContainer2>
                <ArrowContainerIcon1 alt="" src="/arrow-container.svg" />
              </ButtonContainer1>
            </Link>
          </Heading2BestFeaturesThatContainer>
        </Resumetemplates>
        <Whyus>
          <Whyus1>
            <YellowTextParent>
              <YellowText>
                <Everything>Everything</Everything>
              </YellowText>
              <AtOnePlace>at one place</AtOnePlace>
            </YellowTextParent>
            <FrameParent1>
              <RecruitersParent>
                <Recruiters>Recruiters</Recruiters>
                <FrameParent2>
                  <NoWaitingForQuotesFromTheParent>
                    <NoWaitingFor>
                      No waiting for quotes from the company.
                    </NoWaitingFor>
                    <Heading61>Simple fixed pricing</Heading61>
                  </NoWaitingForQuotesFromTheParent>
                  <FrameWrapper>
                    <InHouseCandidatesDatabaseAParent>
                      <InHouseCandidates>
                        In house candidates database available at no extra cost
                      </InHouseCandidates>
                      <Heading62>Candidates database at no cost</Heading62>
                    </InHouseCandidatesDatabaseAParent>
                  </FrameWrapper>
                </FrameParent2>
              </RecruitersParent>
              <JobSeekersParent>
                <JobSeekers>JOB SEEKERS</JobSeekers>
                <GroupDiv>
                  <OurAllTheClientsVacancyUnParent>
                    <OurAllThe>
                      Our all the clients vacancy undisclosed in the market is
                      available to you.
                    </OurAllThe>
                    <Heading63>Access to undisclosed Jobs</Heading63>
                  </OurAllTheClientsVacancyUnParent>
                  <EverythingYouRequiredToGetParent>
                    <EverythingYouRequired>
                      Everything you required to get you hire is here at one
                      platform.
                    </EverythingYouRequired>
                    <Heading64>Everything to get hired</Heading64>
                  </EverythingYouRequiredToGetParent>
                </GroupDiv>
              </JobSeekersParent>
            </FrameParent1>
          </Whyus1>
        </Whyus>
        <Smartjobseekersdemo1 >
          <BgRactangleShapepngIcon1 alt="" src="/bgractangleshapepng1@2x.png" />
          <HeadingContainerGroup>
            <HeadingContainer2 data-scroll-to="recruiterdemo" >
              <Heading28>Smart Recruiter Demo</Heading28>
            </HeadingContainer2>
            <VideoContainer1>
              <VideoRecruiterDemoIcon
                alt=""
                src="/video-recruiter-demo1@2x.png"
              />
            </VideoContainer1>
            <ButtonContainer2  style={{cursor:"unset"}}>
            {/* onClick={onJobSeekerContainerClick} */}
              <ButtonTextContainer1>
                <TakeMeTo2>Coming Soon</TakeMeTo2>
              </ButtonTextContainer1>
              <ArrowContainerIcon1 alt="" src="/arrow-container.svg" />
            </ButtonContainer2>
          </HeadingContainerGroup>
        </Smartjobseekersdemo1>
        <WebsiteFooter>
          <ListParent>
            <List1>
              <SocialButtons />
            </List1>
            <ProductsParent>
              <Products>
                <Heading65>PRODUCTS</Heading65>
                <ItemLink onClick={handlerecruiterdemo}>Smart Recruiter</ItemLink>
                <ItemLink onClick={handleJobSeekersdemo}>Smart JobSeeker</ItemLink>
                <a href="/book-demo" className="no-underline text-[#454545]">
                  <ItemLink>Book a Demo</ItemLink>
                </a>
              </Products>
              <Heading6ProductsParent id="scroll-to-About">
                <Link to="/recruiter-login">
                  {" "}
                  <Heading66>Company</Heading66>
                </Link>
                <a href="/about-us">
                  <ItemLink4>About</ItemLink4>
                </a>
                <a href="/policies">
                  <ItemLink4>Policy</ItemLink4>
                </a>
                <a href="/contact-us" className="no-underline text-[#454545]">
                  <ItemLink4>Contact Us</ItemLink4>
                </a>
                <a href="/careers">
                  <ItemLink4>Careers</ItemLink4>
                </a>
                <a href="/terms-of-use">
                  <ItemLink4>Terms Of Use</ItemLink4>
                </a>
              </Heading6ProductsParent>
            </ProductsParent>
            <AllRightsReserved>
              <AllRightsReserved1>
                2024. All rights reserved.
              </AllRightsReserved1>
            </AllRightsReserved>
          </ListParent>
        </WebsiteFooter>
      </Finalmobile390To820Root>
    </>
  );
};

export default FinalMobile390To820;