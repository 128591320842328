import axios from "axios";
import { Img, Radio, RadioGroup, Text } from "components";
import GaugeDashboard from "components/gaugeDashboard";
import supabase from "components/superBaseConfig";
import Sidebar1 from "pages/RecruiterDashBoardPages/RecruiterSidebar";
import TopHeader from "pages/RecruiterDashBoardPages/TopHeader";
import { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import LoadingSpin from "react-loading-spin";
import Modal from "react-modal";
import styled from "styled-components";

const BluePoint = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: blue;
  border-radius: 50%;
  margin-right: 5px;
  margin-bottom: 3px;
`;

const RecruiterDashboard = () => {
  const [Loading, setLoading] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const [userEmail, setUserEmail] = useState("");
  const userData = {
    user_email: userEmail,
    date_joined: "2024/02/01",
  };

  const handleStart = async () => {
    console.log(userData);
    setLoading(true);
    if (userData.user_email.includes("@gmail.com")) {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/token_generation/google`,
          userData,
        )
        .then((data) => {
          //console.log(data);
        })
        .catch((error) => console.error(error));
    } else {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/token_generation/microsoft`,
        userData,
      );

      if (response.data.access_token) {
        // If an access token is found in the response
        // const accessToken = response.data.access_token;

        // Call the /token_generation/microsoft/messagelist endpoint
        // const messagelistResponse = await axios.post(
        //   `${process.env.REACT_APP_API_URL}/token_generation/microsoft/messagelist`,
        //   {
        //     access_token: accessToken,
        //     date_joined: userData.date_joined,
        //     user_email: userData.user_email,
        //     flow: {},
        //   },
        // );

        // Handle the response as needed
        //console.log("Message list response:", messagelistResponse.data);
      } else if (response.data.flow) {
        const { flow } = response.data;
        const flowMessage = flow.message;

        // Create a new div element to hold the iframe
        const iframeContainer = document.createElement("div");
        iframeContainer.id = "iframe-alert";
        iframeContainer.style.position = "fixed";
        iframeContainer.style.top = "50%";
        iframeContainer.style.left = "50%";
        iframeContainer.style.transform = "translate(-50%, -50%)";
        iframeContainer.style.width = "400px";
        iframeContainer.style.padding = "20px";
        iframeContainer.style.backgroundColor = "#fff";
        iframeContainer.style.border = "1px solid #ccc";
        iframeContainer.style.borderRadius = "8px";
        iframeContainer.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
        iframeContainer.style.zIndex = "10000";

        // Create the iframe element
        const iframe = document.createElement("iframe");
        iframe.style.width = "100%";
        iframe.style.height = "auto";
        iframe.style.border = "none";

        // Append the iframe to the container
        iframeContainer.appendChild(iframe);
        document.body.appendChild(iframeContainer);

        // Set the iframe content
        const iframeDoc =
          iframe.contentDocument || iframe.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(`
        <html>
        <head>
        <style>
          body {
              font-family: Arial, sans-serif;
              padding: 10px;
              margin: 0;
          }
          .content {
              display: flex; /* Use flexbox layout */
              flex-direction: column; /* Arrange children in a column */
              justify-content: center; /* Center children horizontally */
              align-items: center; /* Center children vertically */
              height: 100vh; /* Set the height of the container to the full height of the viewport */
              text-align: center; /* Center the text of children */
          }
          button {
              padding: 8px 16px;
              margin: 10px;
              background-color: #5298fe;
              color: white;
              border: none;
              cursor: pointer;
              border-radius: 4px;
          }
          button:hover {
              background-color: #4177ce;
          }
        </style>
        </head>
        <body>
            <div class="content">
                <p id="flowMessage">${flowMessage}</p>
                <button id="cancelBtn">Cancel</button>
            </div>
        </body>
        </html>
        `);
        iframeDoc.close();

        // Attach event listeners to buttons within the iframe
        const cancelBtn = iframeDoc.getElementById("cancelBtn");

        const removeIframe = () => {
          closePopup();
          setLoading(false);
          document.body.removeChild(iframeContainer);
          setPopupOpen(false);
        };

        cancelBtn.addEventListener("click", removeIframe);

        const tokenCheckResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/token_received/microsoft`,
          {
            flow,
            date_joined: userData.date_joined,
            access_token: "",
            user_email: userData.user_email,
          },
        );
        //console.log(tokenCheckResponse);
        if (tokenCheckResponse.data) {
          // If the backend response is true (success), proceed with the same actions as before
          const paragraphElement = iframeDoc.getElementById("flowMessage");
          paragraphElement.innerHTML = "Downloading attachments ...";
          cancelBtn.remove();

          const combinedData = {
            flow,
            date_joined: userData.date_joined,
            access_token: "",
            user_email: userData.user_email,
          };

          // Send the combined data to another backend URL
          await axios.post(
            `${process.env.REACT_APP_API_URL}/token_generation/microsoft/messagelist`,
            combinedData,
          );

          // Remove the iframe and close the popup
          removeIframe();
        } else {
          //console.log("Token check failed.");
          // removeIframe();
        }
      } else {
        //console.log("here");
      }
    }
    //console.log("Start button clicked");
    closePopup();
    setLoading(false);
  };

  const handleCancel = () => {
    //console.log("Cancel button clicked");
    closePopup();
  };

  useEffect(() => {
    async function getUserData() {
      await supabase.auth.getUser().then((value) => {
        //console.log(value);
        setUserEmail(value.data.user.email);
        // const emailDomain = value.data.user.email.split("@")[1];
        // alert(value.data.user.email.split("@")[1]); // this is the user token, which can be used to set data in the user dashboard
        // if (emailDomain === "gmail.com" || emailDomain === "outlook.com") {
        //   openPopup();
        // } else {
        //   alert("Attachment download is only supported for gmail and outlook");
        // }
        openPopup();
      });
    }
    getUserData();
    // Open the popup immediately when the component mounts
  }, []); // Empty dependency array ensures that the effect runs only once on mount

  const data = [
    {
      title: "UI/UX Designer",
      subTitle: "Developer | On-site (Hyderabad)",
      content1: "New application in 1 hour",
      content2: "40",
      totalApplication: 55,
    },
    {
      title: "Frontend Developer",
      subTitle: "Developer | On-site (Pune)",
      content1: "New application in 1 hour",
      content2: "33",
      totalApplication: 30,
    },
    {
      title: "Backend Developer",
      subTitle: "Developer | On-site (Delhi)",
      content1: "New application in 1 hour",
      content2: "60",
      totalApplication: 79,
    },
    {
      title: "Fullstack Developer",
      subTitle: "Developer | On-site (Banglore)",
      content1: "New application in 1 hour",
      content2: "25",
      totalApplication: 15,
    },
  ];

  const PlacementData = [
    { value: "1414", label: "Total candidates" },
    { value: "678", label: "Candidates hired" },
    { value: "85", label: "Total Vacancies" },
    { value: "12", label: "Vacancies closed" },
  ];

  const [showDatePicker, setShowDatePicker] = useState(false);

  const handleButtonClick = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleCloseButtonClick = () => {
    setShowDatePicker(false);
  };
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  // var retrievedData = localStorage.getItem('resumeData');
  // const pdfUrl = retrievedData;
  return (
    <>
      {Loading && <LoadingSpin />}
      {!Loading && (
        <>
          <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
            <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
              <Sidebar1 clickedTab={"Dashboard"} />

              <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
                <TopHeader header={"Dashboard"} />

                <div
                  style={{ overflow: "scroll" }}
                  className="bg-white-A700 flex flex-col font-poppins items-center justify-start rounded-[15px] w-[94%] md:w-full h-[93vh]"
                >
                  <div className=" font-poppins md:h-[1103px] h-[1165px] relative w-[94%] md:w-full">
                    <div className=" bg-white-A700 flex flex-col inset-x-[0] justify-start mx-auto py-[23px] rounded-[15px] top-[0] w-full">
                      <div className="flex flex-col items-center justify-center md:ml-[0] ml-[82px] w-[275px]">
                        <Text
                          className="md:text-3xl sm:text-[28px] text-[32px] text-black-900_7f w-auto"
                          size="txtPoppinsMedium32"
                        >
                          Welcome, Robert
                        </Text>
                      </div>
                      <div className="font-inter md:h-[1011px] h-[523px] mb-[488px] relative w-full">
                        <div className="absolute flex flex-col inset-x-[0] items-start justify-start mx-auto p-[30px] sm:px-5 rounded-[12px]  top-[5%] w-full">
                          <div className="flex flex-col gap-[30px] items-start justify-start mb-1 ml-12 md:ml-[0] w-[88%] md:w-full">
                            <RadioGroup
                              selectedValue="Lifetime"
                              className="flex gap-[60px] items-start justify-start w-auto"
                              name="timeperiod"
                            >
                              <Radio
                                value="Lifetime"
                                className="font-medium leading-[normal] text-black-900_7f text-left text-lg"
                                inputClassName="bg-white-A700 border border-blue-A200 border-solid h-[17px] mr-[5px] rounded-[9px] w-[17px]"
                                checked={true}
                                name="timeperiod"
                                label="Lifetime"
                                id="Lifetime"
                              ></Radio>
                              <Radio
                                value="30days"
                                className="font-medium leading-[normal] text-black-900_7f text-left text-lg"
                                inputClassName="bg-white-A700 border border-blue-A200 border-solid h-[17px] mr-[5px] w-[17px]"
                                checked={false}
                                name="timeperiod"
                                label="30 days"
                                id="30days"
                              ></Radio>
                              <Radio
                                value="Custom"
                                className="font-medium leading-[normal] text-black-900_7f text-left text-lg"
                                inputClassName="bg-white-A700 border border-blue-A200 border-solid h-[17px] mr-[5px] w-[17px]"
                                checked={false}
                                name="timeperiod"
                                label="Custom"
                                id="Custom"
                                onClick={handleButtonClick}
                              ></Radio>
                              {showDatePicker && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    position: "absolute",
                                    left: "300px",
                                    top: "90px",
                                    zIndex: 900,
                                    backgroundColor: "#ede8e8",
                                    padding: "10px",
                                  }}
                                >
                                  <DateRange
                                    editableDateInputs={true}
                                    onChange={(item) =>
                                      setState([item.selection])
                                    }
                                    moveRangeOnFirstSelection={false}
                                    ranges={state}
                                  />

                                  <button
                                    style={{
                                      marginTop: "10px",
                                      backgroundColor: "#5298fe",
                                      color: "white",
                                      height: "30px",
                                    }}
                                    onClick={handleCloseButtonClick}
                                  >
                                    Close
                                  </button>
                                </div>
                              )}
                            </RadioGroup>
                            <div className="flex md:flex-col flex-row font-poppins md:gap-10 items-center justify-between w-full">
                              {PlacementData.map((item, index) => (
                                <div
                                  key={index}
                                  className="border border-gray-400_66 border-solid flex flex-col items-start justify-start sm:ml-[0] p-4 rounded-[15px] w-[253px]"
                                >
                                  <div className="flex flex-col gap-[13px] items-start justify-start w-full">
                                    <div className="flex flex-row items-start justify-between w-full">
                                      <Text
                                        className="sm:text-4xl md:text-[38px] text-[40px] text-center text-indigo-600 w-auto"
                                        size="txtPoppinsSemiBold40"
                                      >
                                        {item.value}
                                      </Text>
                                      <Img
                                        className="h-10 md:h-auto object-cover rounded-lg w-10"
                                        src="images/img_linechart.png"
                                        alt="linechart"
                                      />
                                    </div>
                                    <Text
                                      className="text-blue_gray-400 text-center text-xl w-auto"
                                      size="txtPoppinsMedium20"
                                    >
                                      {item.label}
                                    </Text>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div>
                              <Text
                                className="text-2xl md:text-[22px] text-black-900_7f sm:text-xl"
                                size="txtInterSemiBold24"
                              >
                                Ongoing jobs
                              </Text>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ flexWrap: "wrap" }}
                      className="absolute top-[450px] flex flex-row font-inter gap-[17px] items-start justify-around  h-auto w-[100%]"
                    >
                      {data.map((item, index) => (
                        <div key={index} className="h-[40%] relative w-[40%]">
                          <div className="md:h-[241px] h-auto mx-auto w-full">
                            <div className="border border-gray-400_66 border-solid flex flex-col items-start justify-start sm:px-5 rounded-lg w-full">
                              <div className="w-full pl-4 pt-4">
                                <Text
                                  style={{ fontSize: "18px" }}
                                  className="text-black-900_99"
                                  size="txtPoppinsMedium20Black90099"
                                >
                                  <BluePoint /> {item.title}
                                </Text>
                                <Text
                                  style={{ fontSize: "13px" }}
                                  className="text-gray-700 text-lg"
                                  size="txtPoppinsRegular18"
                                >
                                  {item.subTitle}
                                </Text>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  height: "60%",
                                  marginTop: "40px",
                                  fontPalette: "12px",
                                }}
                              >
                                <Text
                                  style={{ fontSize: "14px" }}
                                  className="text-gray-700 text-lg flex flex-row justify-center items-center"
                                  size="txtPoppinsRegular18"
                                >
                                  {item.content1}
                                </Text>
                                <Text
                                  style={{ fontSize: "14px" }}
                                  className="text-gray-700 text-lg flex flex-row justify-center items-center"
                                  size="txtPoppinsRegular18"
                                >
                                  {item.content2}
                                </Text>
                                <GaugeDashboard
                                  Applications={item.totalApplication}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            isOpen={isPopupOpen}
            onRequestClose={closePopup}
            contentLabel="Popup Modal"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.2)", // Adjust the opacity and color as needed
              },
              content: {
                backgroundColor: "white",
                width: "450px",
                height: "200px",
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              },
            }}
          >
            <div>
              <h1 style={{ fontSize: "18px" }}>
                We are about to sync your emails to the Recruiter Robo Platform.
                All candidate resumes will be synced to this dashboard.
              </h1>
            </div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <button
                onClick={handleStart}
                style={{
                  backgroundColor: "green",
                  color: "white",
                  padding: "10px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  width: "100px", // Set a specific width for both buttons
                  fontSize: "14px", // Set font size for the buttons
                }}
              >
                Start
              </button>
              <button
                onClick={handleCancel}
                style={{
                  backgroundColor: "red",
                  color: "white",
                  padding: "10px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  width: "100px", // Set a specific width for both buttons
                  fontSize: "14px", // Set font size for the buttons
                }}
              >
                Cancel
              </button>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default RecruiterDashboard;
