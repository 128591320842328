import { Img, Text } from "components";
import LogoutPopup from "components/LogoutPopup";
import { useState } from "react";
import { Menu, MenuItem, Sidebar, useProSidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import supabase from "components/superBaseConfig";
const Sidebar1 = (props) => {
  const [isLogoutPopupOpen, setIsLogoutPopupOpen] = useState(false);
  const openLogoutPopup = () => {
    setIsLogoutPopupOpen(true);
  };

  const closeLogoutPopup = () => {
    setIsLogoutPopupOpen(false);
  };


  const confirmLogout = () => {
    closeLogoutPopup();
    supabase.auth.signOut();
  };

  const activeSidebarText = {
    color: "#314ca3",
  };
  const inactiveSidebarText = {
    color: "#888EA2",
  };

  const { clickedTab } = props;
  const { collapseSidebar, collapsed } = useProSidebar();

  const sideBarMenu = [
    {
      icon: (
        <Img
          className="h-[25px] mb-0.5 w-[25px]"
          src="/images/img_home.svg"
          alt="home"
        />
      ),
      label: "Dashboard",
      to: "/University-dashboard",
    },
    {
      icon: (
        <Img
        className="h-8 w-8"
        src="images/img_icons8student.svg"
        alt="icons8student"
        />
      ),
      label: "Student Management",
      to: "/University-Student-Management",
    },
    {
      icon: (
        <Img
        className="h-6 w-6"
        src="images/img_materialsymbolsbookoutline.svg"
        alt="materialsymbols"
        />
      ),
      label: "Placement Records",
      to: "/University-Placement-Records",
    },
    {
      icon: (
        <Img
        className="h-[21px]"
        src="images/img_gridiconschat.svg"
        alt="gridiconschat"
        />
      ),
      label: "Interviewing Scheduling",
      to: "/University-Interviewing-Scheduling",
    },
    {
      icon: (
        <Img
        className="h-6 w-6"
        src="images/img_rihomeofficeline.svg"
        alt="rihomeofficelin"
        />
      ),
      label: "Company Profile",
      to: "/University-Company-Profile",
    },
    {
      icon: (
        <Img
        className="h-6 w-6"
        src="images/img_settings copy.svg"
        alt="settings"
        />
      ),
      label: "Communication",
      to: "/University-Communication",
    },
    {
      icon: (
        <Img
        className="h-6 w-6"
        src="images/img_materialsymbol copy.svg"
        alt="materialsymbol"
        />
      ),
      label: "Feedback",
      to: "/University-Feedback",
    },
    {
      icon: (
        <Img
        className="h-6 w-6"
        src="images/img_materialsymbolshelpoutline.svg"
        alt="materialsymbols_One"
        />
      ),
      label: "Help and Support",
      to: "/University-Help-And-Support",
    },
    {
      icon: (
        <Img
          className="h-8 md:h-auto object-cover w-8"
          src="/images/img_logout.png"
          alt="logout"
        />
      ),
      label: "Logout",
      to: "",
    },
  ];

  if (localStorage.getItem("imgUrl") === null) {
    localStorage.setItem("imgUrl", "images/img_ellipse28.png");
  }

  return (
    <Sidebar
    backgroundColor="#fff"

      className="sidebar-bg !sticky !w-[58px] border border-gray-300 border-solid flex h-full md:hidden justify-start md:px-5 top-[0]"
    >
      <div >
        {" "}
        <div
          onClick={() => collapseSidebar(!collapsed)}
          className="flex flex-col items-center justify-center py-[35px] w-full"
        >
          <div className="flex flex-row gap-[10px] items-center justify-center mb-[19px] px-5 w-[278px]">
            <div className="flex flex-col items-center justify-center px-1.5 py-[7px] w-auto">
              <Img
                className={`${!collapsed
                    ? "h-[74px] w-[81px] md:h-auto sm:w-full"
                    : "h-[40px] w-[46px] "
                  } `}
                src="images/img_recruiterrobologo3.png"
                alt="recruiterrobolo"
              />
            </div>
            <div className="flex flex-col items-center justify-center w-auto">
              {!collapsed && (
                <Text
                  className="leading-[135.50%] max-w-[83px] md:max-w-full text-center text-gray-900 text-sm"
                  size="txtPoppinsSemiBold14"
                >
                  Recruiter Robo
                </Text>
              )}
            </div>
          </div>
        </div>
      </div>

      <Menu
        menuItemStyles={{
          button: {
            padding: "22px 22px 22px 42px",
            gap: "10px",
            fontWeight: 500,
            fontSize: "15px",
          },
        }}
        className="flex flex-col font-inter items-center justify-center mb-[232px] md:pr-10 sm:pr-5  pt-0.5 w-[85%]"
      >
        {sideBarMenu?.map((menu, i) => (
          <Link
            onClick={() => menu.label === "Logout" && openLogoutPopup()}
            to={menu.to}
            key={menu.i}
          >
            <MenuItem
              {...menu}
              key={`sideBarMenu1Item${i}`}
              to={menu.to}
              className="text-decoration-none"
              style={
                clickedTab === menu.label
                  ? activeSidebarText
                  : inactiveSidebarText
              }
            >
              {!collapsed && menu.label}
            </MenuItem>
          </Link>
        ))}
      </Menu>
      <LogoutPopup
       goTo="/university-login"
        isOpen={isLogoutPopupOpen}
        onClose={closeLogoutPopup}
        onConfirmLogout={confirmLogout}
      />
    </Sidebar>
  );
};

export default Sidebar1;
