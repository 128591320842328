import { setAccountType } from "actions/AccountType";
import { getPaymentIntents } from "actions/PaymentIntent";
import { fetchSubscriptionData } from "actions/SubscriptionData";
import { Button, Text } from "components"; // Ensure these components are imported correctly
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom'; // Ensure useNavigate is imported

const PaymentMethods = () => {
  // State declarations
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [fetchStatus, setFetchStatus] = useState(false);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [validCode, setValidCode] = useState(null);
  const [trialUserCode, setTrialUserCode] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userId1 = JSON.parse(
    localStorage.getItem(`sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`)
  );
  const user_id = userId1.user.id;

  const subscriptionType = window.location.search.split("=")[1];

  const handlePaymentMethodSelect = (method) => {
    setSelectedPaymentMethod(method);
  };

  const handleTrialUser = async () => {
    setFetchStatus(true);
    try {
      let response = await fetch(
        `${process.env.REACT_APP_API_URL}/start-trial`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({ user_id }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update subscription");
      }
      dispatch(setAccountType(user_id))
      dispatch(fetchSubscriptionData(user_id))
      dispatch(getPaymentIntents(user_id))
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setFetchStatus(false);
    }
  };

  const handleCheckout = (subscriptionType) => {
    setFetchStatus(true);
    let data = {
      user_id,
      subscriptionType,
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/create-checkout-session-jobSeekers`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error creating checkout session");
        }
        setFetchStatus(false);
        return response.json();
      })
      .then((data) => {
        window.location.href = data.url;
        dispatch(setAccountType(user_id))
        dispatch(fetchSubscriptionData(user_id))
        dispatch(getPaymentIntents(user_id))
      })
      .catch((error) => {
        console.error("Error:", error);
        setFetchStatus(false);
      });
  };

  const handleBuyButtonClick = async (subscriptionType) => {
    if (!selectedPaymentMethod) {
      alert("Please select a payment method.");
      return;
    }

    setFetchStatus(true);
    let data = {
      user_id,
      subscriptionType,
    };

    if (validCode) {
      data = {
        ...data,
        discountPercent,
      };
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/create-checkout-session-jobSeekersRazorpay`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Error creating checkout session");
      }

      const responseData = await response.json();

      const options = {
        key: process.env.REACT_APP_RAZORPAY_API_KEY,
        amount: responseData.amount,
        currency: responseData.currency,
        order_id: responseData.id,
        name: "Recroid",
        image: "//recroid.png",
        handler: async function (response) {
          const body = {
            user_id: user_id,
            subscriptionType: subscriptionType,
            currency: responseData.currency,
            amount: responseData.amount,
            ...response,
          };
          const validateRes = await fetch(
            `${process.env.REACT_APP_API_URL}/verify-payment-jobSeekers`,
            {
              method: "POST",
              body: JSON.stringify(body),
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const jsonRes = await validateRes.json();
          // window.location.href = "/billing-overview";
          navigate("/billing-overview");
          dispatch(setAccountType(user_id))
          dispatch(fetchSubscriptionData(user_id))
          dispatch(getPaymentIntents(user_id))
        },
        prefill: {
          method: selectedPaymentMethod,
          name: responseData.customer_name,
          email: responseData.customer_email,
          // contact: String(responseData.customer_phone),
        },
        description: user_id,
        theme: {
          color: "#3399cc",
        },
        modal: {
          ondismiss: function () {
            alert("Payment failed");
          },
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setFetchStatus(false);
    }
  };

  return (
    <div className="shadow-2xl m-[40px] w-full max-md:w-full">
      <div className="flex justify-center w-full">
        <div className="mt-12 max-md:mt-10 w-[90%] max-md:max-w-full">
          <div className="flex flex-col mt-12 max-md:mt-10 max-md:max-w-full w-full gap-[10px]">
            <Text className="text-2xl font-medium font-bold mb-[20px]">
              Payment Method
            </Text>
            <div className="flex items-center justify-between border rounded-md p-3">
              <label className="flex items-center gap-2 text-xl">
                <input
                  type="radio"
                  value="upi"
                  checked={selectedPaymentMethod === "upi"}
                  onChange={() => handlePaymentMethodSelect("upi")}
                  className="form-radio h-6 w-6 text-blue-600"
                />
                Pay through UPI
              </label>
              <img
                src="https://logowik.com/content/uploads/images/upi-unified-payments-interface4949.logowik.com.webp"
                className="w-[100px] object-contain"
                alt="UPI"
              />
            </div>
            <div className="flex items-center justify-between border rounded-md p-3 mt-2">
              <label className="flex items-center gap-2 text-xl">
                <input
                  type="radio"
                  value="card"
                  checked={selectedPaymentMethod === "card"}
                  onChange={() => handlePaymentMethodSelect("card")}
                  className="form-radio h-6 w-6 text-blue-600"
                />
                Cards
              </label>
              <div className="flex">
                <img
                  src="https://getsby.com/wp-content/uploads/2023/01/Visa-Mastercard-1-1024x378.png"
                  className="w-[100px] object-contain"
                  alt="Visa and Mastercard"
                />
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/RuPay.svg/2560px-RuPay.svg.png"
                  className="w-[100px] object-contain"
                  alt="RuPay"
                />
              </div>
            </div>
            <div className="flex items-center justify-between border rounded-md p-3 mt-2">
              <label className="flex items-center gap-2 text-xl">
                <input
                  type="radio"
                  value="netbanking"
                  checked={selectedPaymentMethod === "netbanking"}
                  onChange={() => handlePaymentMethodSelect("netbanking")}
                  className="form-radio h-6 w-6 text-blue-600"
                />
                Netbanking
              </label>
              <img
                src="https://www.freeiconspng.com/thumbs/bank-icon/bank-icon-5.png"
                className="w-[50px] object-contain"
                alt="Netbanking"
              />
            </div>
            <div className="flex items-center justify-between border rounded-md p-3 mt-2">
              <label className="flex items-center gap-2 text-xl">
                <input
                  type="radio"
                  value="stripe"
                  checked={selectedPaymentMethod === "stripe"}
                  onChange={() => handleCheckout(subscriptionType)}
                  className="form-radio h-6 w-6 text-blue-600"
                />
                Stripe
              </label>
              <img
                src="images/stripeLogoIcon.png"
                className="w-[80px] h-[50px] object-contain"
                alt="Stripe"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end w-[100%]">
        {trialUserCode ? (
          <Button
            className="w-[350px] h-[60px] text-[24px] font-bold self-end mt-[50px]"
            onClick={handleTrialUser}
          >
            <div className="text-[#fff]">Start Trial</div>
          </Button>
        ) : (
          <Button
            className="w-[250px] h-[60px] text-2xl self-end font-semibold mt-[50px] mr-[40px] mb-[20px]"
            onClick={() => handleBuyButtonClick(subscriptionType)}
          >
            <div className="text-[#fff]">Proceed to Pay</div>
          </Button>
        )}
      </div>
    </div>
  );
};

export default PaymentMethods;
