import { Button, Text } from "components";
import BillingSubHeader from "pages/RecruiterDashBoardPages/BillingSubHeaderRecruiter";
import Sidebar1 from "pages/RecruiterDashBoardPages/RecruiterSidebar";
import TopHeader from "pages/RecruiterDashBoardPages/TopHeader";

const BillingOverviewRecruiter = () => {
  // const invoicesData = [
  //   { id: 1, status: "Paid", amount: 50.89, date: "2-Nov-2023" },
  //   { id: 2, status: "Paid", amount: 50.89, date: "2-Nov-2023" },
  //   { id: 3, status: "Paid", amount: 50.89, date: "2-Nov-2023" },
  //   { id: 4, status: "Paid", amount: 50.89, date: "2-Nov-2023" },
  //   { id: 5, status: "Paid", amount: 50.89, date: "2-Nov-2023" },
  //   { id: 6, status: "Paid", amount: 50.89, date: "2-Nov-2023" },
  //   { id: 7, status: "Paid", amount: 50.89, date: "2-Nov-2023" },
  //   { id: 8, status: "Paid", amount: 50.89, date: "2-Nov-2023" },
  //   { id: 9, status: "Paid", amount: 50.89, date: "2-Nov-2023" },
  // ];

  return (
    <>
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <Sidebar1 clickedTab={"Billing"} />

          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
            <TopHeader header={"Billing"} />

            <div
              style={{ overflow: "scroll" }}
              className="bg-white-A700 flex flex-col font-poppins items-center justify-start p-7 sm:px-5 rounded-[15px] w-[94%] md:w-full h-[93vh]"
            >
              <div className="flex flex-col gap-[43px] justify-start mb-[199px] mt-2.5 w-[99%] md:w-full">
                <BillingSubHeader billingheader={1} />
                <Text
                  style={{ color: "#0095ff" }}
                  className="mt-[39px] text-2xl md:text-[22px] text-light_blue-A700 sm:text-xl"
                  size="txtInterSemiBold24"
                >
                  You are active on Smart Recruiter Plan
                </Text>
                <div className="border border-blue_gray-100 border-solid flex flex-col items-start justify-center mt-10 p-[19px] rounded-lg">
                  <Text
                    className="mt-0.5 text-2xl md:text-[22px] text-gray-900_02 sm:text-xl"
                    size="txtInterSemiBold24Gray90002"
                  >
                    Auto recharge is on
                  </Text>
                  <Text
                    className="mt-[18px] text-black-900 text-lg"
                    size="txtInterRegular18"
                  >
                    On the 15th of each month, your credit card will be charged
                    to fund your subscription account.
                  </Text>
                  <Text
                    style={{ color: "#0095ff" }}
                    className="mb-2 mt-6 text-lg text-light_blue-A700"
                    size="txtInterSemiBold18"
                  >
                    Modify
                  </Text>
                </div>


                <div className="border border-blue_gray-100 border-solid flex flex-col items-start justify-center mt-[15px] p-[19px] rounded-lg">
                  <Text
                    className="mt-[5px] text-black-900 text-lg sm:text-sm"
                    size="txtInterRegular18"
                  >
                    For Recruiters
                  </Text>

                  <Text
                    className="mt-0.5 text-2xl md:text-[22px] text-gray-900_02  sm:text-sm"
                    size="txtInterSemiBold24Gray90002"
                  >
                    $ 59 Per Month/ $ 47.20 Per Year
                  </Text>

                  <ul className="list-disc pl-5 mt-4 gap-[35px] sm:text-sm">
                    <li>Unlimited Resumes Builders</li>
                    <li className="pt-[8px]">Unlimited Auto Interview Schedules</li>
                    <li className="pt-[8px]">Free Centralised Databases</li>
                    <li className="pt-[8px]">Access to 5000 free candidates profiles</li>
                    <li className="pt-[8px]">
                      Great Customer Support
                    </li>
                    <li className="pt-[8px]">Unlimited job Uploads and Shortlisting</li>

                  </ul>

                  <Text
                    style={{ color: "#0095ff" }}
                    className="cursor-pointer mb-2 mt-6 text-lg text-light_blue-A700 sm:text-sm"
                    size="txtInterSemiBold18"
                  >
                    Subscribe now
                  </Text>
                </div>



                <div className="flex sm:flex-col flex-row gap-10 items-start justify-start  mt-2 w-auto sm:w-full">
                  <Button
                    style={{ color: "#000000", backgroundColor: "#ececf1" }}
                    className="cursor-pointer font-medium leading-[normal] min-w-[233px] text-center text-lg"
                  >
                    Add to credit balance
                  </Button>
                  <Button
                    style={{ color: "#000000", backgroundColor: "#ececf1" }}
                    className="cursor-pointer font-medium leading-[normal] min-w-[149px] text-center text-lg"
                  >
                    Cancel plan

                  </Button>
                </div>




              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingOverviewRecruiter;
