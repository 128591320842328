const websiteNames = [
    "google",
    "youtube",
    "facebook",
    "twitter",
    "instagram",
    "baidu",
    "wikipedia",
    "amazon",
    "yahoo",
    "reddit",
    "netflix",
    "linkedin",
    "tencent",
    "tmall",
    "taobao",
    "sina",
    "weibo",
    "vk",
    "yandex",
    "microsoft",
    "live",
    "bing",
    "pinterest",
    "whatsapp",
    "twitch",
    "ebay",
    "mail",
    "imdb",
    "apple",
    "office",
    "samsung",
    "aliexpress",
    "wordpress",
    "blogspot",
    "tumblr",
    "paypal",
    "github",
    "stackoverflow",
    "vimeo",
    "adobe",
    "dropbox",
    "quora",
    "medium",
    "bbc",
    "cnn",
    "foxnews",
    "nytimes",
    "forbes",
    "huffpost",
    "wsj",
    "bloomberg",
    "bbc",
    "usatoday",
    "espn",
    "nba",
    "fifa",
    "olympics",
    "cnn",
    "nike",
    "adidas",
    "walmart",
    "target",
    "homedepot",
    "bestbuy",
    "costco",
    "macys",
    "kohls",
    "zillow",
    "realtor",
    "trulia",
    "airbnb",
    "uber",
    "lyft",
    "booking",
    "expedia",
    "tripadvisor",
    "yelp",
    "indeed",
    "glassdoor",
    "monster",
    "craigslist",
    "zoom",
    "slack",
    "skype",
    "discord",
    "telegram",
    "signal",
    "snapchat",
    "tiktok",
    "pinterest",
    "spotify",
    "soundcloud",
    "pandora",
    "shazam",
    "bitly",
    "goo",
    "tinyurl",
    "wetransfer",
    "onedrive",
    "googlephotos",
    "icloud",
  ];
  export default websiteNames