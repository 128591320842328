
import React from "react";
import { Button, Img, Input, Line, List, Text } from "components";
import Sidebar1 from "pages/UniversityDashBoardPages/UniversitySidebar";
import TopHeader from "pages/RecruiterDashBoardPages/TopHeader";

  const UniversityCompanyProfile = () => {
  return (
    <>
          <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
            <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
              <Sidebar1 clickedTab={"Company Profile"} />

              <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
                <TopHeader header={"Company Profile"} />


                <div className="bg-white-A700 flex flex-col items-center justify-start p-7 sm:px-5 rounded-[15px] w-[94%] md:w-full">
                  <div className=" font-poppins md:h-[1103px] h-[1165px] relative w-[94%] md:w-full">
                    



                  {/* <div className="flex flex-1 flex-col font-inter gap-[50px] items-center justify-start md:px-5 w-full">
            
            <div className="bg-white-A700 flex flex-col items-center justify-start p-[34px] sm:px-5 rounded-[15px] w-[95%] md:w-full"> */}

{/* 
<div className="flex flex-1 flex-col font-inter gap-[50px] items-center justify-start md:px-5 w-full">
            
            <div className="bg-white-A700 flex flex-col items-end justify-start sm:pl-5 pl-[35px] py-[35px] rounded-[15px] w-[95%] md:w-full"> */}
              <div className="flex flex-col gap-[39px] justify-start mb-[134px] w-[98%] md:w-full">
                <div className="border border-gray-400_7f border-solid bg-white-A700 flex md:flex-col flex-row md:gap-5 items-center justify-start mr-[78px] p-4 rounded-[15px] w-[95%] md:w-full">
                  
                  <Input
                    name="searchbar"
                    placeholder="Product Designer"
                    className="border border-gray-400_7f border-solid font-medium p-0 placeholder:text-indigo-600 text-left text-xl tracking-[-0.22px] w-full"
                    wrapClassName="flex ml-5 md:ml-[0] w-[34%] md:w-full"
                    prefix={
                      <Img
                        className="mt-auto mb-[7px] h-[41px] mr-[35px]"
                        src="images/img_rewind.svg"
                        alt="rewind"
                      />
                    }
                    suffix={
                      <Img
                        className="bg-blue-500mt-[3px] mb-auto h-[45px] ml-[35px]"
                        src="images/img_carbonaddfilled.svg"
                        alt="carbon:add-filled"
                      />
                    }
                  ></Input>
                  <Line className=" bg-blue_gray-400 md:h-0.5 h-[68px] mb-0.5 md:ml-[0] ml-[52px] w-0.5 md:w-full" />
                  <Input
                    name="searchbar_One"
                    placeholder="London, United Kingdom"
                    className="font-medium p-0 placeholder:text-indigo-600 text-left text-xl tracking-[-0.22px] w-full"
                    wrapClassName="flex md:ml-[0] ml-[42px] w-[34%] md:w-full"
                    prefix={
                      <Img
                        className="mt-auto mb-0.5 h-[46px] mr-8"
                        src="images/img_ionlocationsharp.svg"
                        alt="ion:location-sharp"
                      />
                    }
                    suffix={
                      <Img
                        className="mt-[3px] mb-auto h-[45px] ml-[35px]"
                        src="images/img_carbonaddfilled.svg"
                        alt="carbon:add-filled"
                      />
                    }
                  ></Input>
                  <Button className="cursor-pointer font-medium ml-24 md:ml-[0] md:mt-0 my-2.5 text-center text-xl tracking-[-0.22px] w-[220px]">
                    Find Job
                  </Button>
                </div>
                <List
                  className="flex flex-col gap-[49px] items-center ml-9 md:ml-[0] w-[98%]"
                  orientation="vertical"
                >
                  <div className="flex md:flex-col flex-row md:gap-5 items-start justify-start w-full">
                    <div className="bg-white-A700 border border-gray-400_7f border-solid flex md:flex-1 flex-col items-center justify-start md:mt-0 mt-2.5 p-1 rounded-[15px] w-[29%] md:w-full">
                      <div className="flex flex-col items-start justify-start my-1.5 w-[98%] md:w-full">
                        <div className="flex sm:flex-col flex-row sm:gap-5 items-start justify-start w-full">
                          <Img
                            className="sm:flex-1 h-[46px] md:h-auto object-cover w-[21%] sm:w-full"
                            src="images/img_image9 copy.png"
                            alt="imageNine"
                          />
                          <Text
                            className="ml-4 sm:ml-[0] sm:mt-0 mt-2 text-base text-blue_gray-400"
                            size="txtInterMedium16"
                          >
                            <>
                              Senior Product
                              <br />
                              Designer
                            </>
                          </Text>
                          <Button
                            className="cursor-pointer h-10 leading-[normal] sm:ml-[0] ml-[42px] sm:mt-0 my-[3px] text-base text-center w-[100px]"
                            color="blue_A200"
                          >
                            Remote
                          </Button>
                          <Img
                            className="h-5 sm:ml-[0] ml-[17px] sm:mt-0 mt-[13px] w-5"
                            src="images/img_notification.svg"
                            alt="notification"
                          />
                        </div>
                        <Text
                          className="md:ml-[0] ml-[98px] mt-1.5 text-base text-blue_gray-400"
                          size="txtInterRegular16"
                        >
                          Alibaba.com
                        </Text>
                        <Line className="bg-blue_gray-400 h-px mt-[18px] w-[97%]" />
                        <Text
                          className="mt-3 text-gray-800 text-xs w-[97%] sm:w-full"
                          size="txtInterLight12"
                        >
                          <>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Maxime mollitia, molestiae quas vel sint
                            commodi repudiandae consequuntur voluptatum laborum
                            numquam blanditiis harum quisquam eius sed odit
                            fugiat iusto fuga praesentium
                            <br />
                            optio, eaque rerum! Provident similique accusantium
                            nemo autem.
                          </>
                        </Text>
                        <Button
                          className="cursor-pointer font-semibold h-[50px] leading-[normal] ml-20 md:ml-[0] mt-[13px] text-base text-center w-[220px]"
                          size="sm"
                          variant="outline"
                        >
                          Apply Now
                        </Button>
                      </div>
                    </div>
                    <div className="bg-white-A700 border border-gray-400_7f border-solid flex md:flex-1 flex-col items-center justify-start md:ml-[0] ml-[50px] md:mt-0 mt-2.5 p-1 rounded-[15px] w-[29%] md:w-full">
                      <div className="flex flex-col items-start justify-start my-1.5 w-[98%] md:w-full">
                        <div className="flex sm:flex-col flex-row sm:gap-5 items-start justify-start w-full">
                          <Img
                            className="sm:flex-1 h-[46px] md:h-auto object-cover w-[16%] sm:w-full"
                            src="images/img_image12 copy.png"
                            alt="imageTwelve"
                          />
                          <Text
                            className="sm:ml-[0] ml-[39px] sm:mt-0 mt-2 text-base text-blue_gray-400"
                            size="txtInterMedium16"
                          >
                            <>
                              Senior Product
                              <br />
                              Designer
                            </>
                          </Text>
                          <Button
                            className="cursor-pointer h-10 leading-[normal] sm:ml-[0] ml-[42px] sm:mt-0 my-[3px] text-base text-center w-[100px]"
                            color="blue_A200"
                          >
                            Remote
                          </Button>
                          <Img
                            className="h-5 sm:ml-[0] ml-[17px] sm:mt-0 mt-[13px] w-5"
                            src="images/img_notification.svg"
                            alt="notification_One"
                          />
                        </div>
                        <Text
                          className="md:ml-[0] ml-[98px] mt-[7px] text-base text-blue_gray-400"
                          size="txtInterRegular16"
                        >
                          Wipro
                        </Text>
                        <Line className="bg-blue_gray-400 h-px mt-[17px] w-[97%]" />
                        <Text
                          className="mt-3 text-gray-800 text-xs w-[97%] sm:w-full"
                          size="txtInterLight12"
                        >
                          <>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Maxime mollitia, molestiae quas vel sint
                            commodi repudiandae consequuntur voluptatum laborum
                            numquam blanditiis harum quisquam eius sed odit
                            fugiat iusto fuga praesentium
                            <br />
                            optio, eaque rerum! Provident similique accusantium
                            nemo autem.
                          </>
                        </Text>
                        <Button
                          className="cursor-pointer font-semibold h-[50px] leading-[normal] ml-20 md:ml-[0] mt-[13px] text-base text-center w-[220px]"
                          size="sm"
                          variant="outline"
                        >
                          Apply Now
                        </Button>
                      </div>
                    </div>
                    <div className="bg-white-A700 border border-gray-400_7f border-solid flex md:flex-1 flex-col items-center justify-start md:ml-[0] ml-[50px] md:mt-0 mt-2.5 p-1 rounded-[15px] w-[29%] md:w-full">
                      <div className="flex flex-col items-start justify-start my-1.5 w-[98%] md:w-full">
                        <div className="flex sm:flex-col flex-row sm:gap-5 items-start justify-start w-full">
                          <Img
                            className="sm:flex-1 h-[37px] md:h-auto object-cover w-[24%] sm:w-full"
                            src="images/img_image10 copy.png"
                            alt="imageTen"
                          />
                          <Text
                            className="sm:ml-[0] ml-[5px] sm:mt-0 mt-2 text-base text-blue_gray-400"
                            size="txtInterMedium16"
                          >
                            <>
                              Senior Product
                              <br />
                              Designer
                            </>
                          </Text>
                          <Button
                            className="cursor-pointer h-10 leading-[normal] sm:ml-[0] ml-[42px] sm:mt-0 my-[3px] text-base text-center w-[100px]"
                            color="blue_A200"
                          >
                            Remote
                          </Button>
                          <Img
                            className="h-5 sm:ml-[0] ml-[17px] sm:mt-0 mt-[13px] w-5"
                            src="images/img_notification.svg"
                            alt="notification_Two"
                          />
                        </div>
                        <Text
                          className="md:ml-[0] ml-[98px] mt-1.5 text-base text-blue_gray-400"
                          size="txtInterRegular16"
                        >
                          TCS
                        </Text>
                        <Line className="bg-blue_gray-400 h-px mt-[18px] w-[97%]" />
                        <Text
                          className="mt-3 text-gray-800 text-xs w-[97%] sm:w-full"
                          size="txtInterLight12"
                        >
                          <>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Maxime mollitia, molestiae quas vel sint
                            commodi repudiandae consequuntur voluptatum laborum
                            numquam blanditiis harum quisquam eius sed odit
                            fugiat iusto fuga praesentium
                            <br />
                            optio, eaque rerum! Provident similique accusantium
                            nemo autem.
                          </>
                        </Text>
                        <Button
                          className="cursor-pointer font-semibold h-[50px] leading-[normal] ml-20 md:ml-[0] mt-[13px] text-base text-center w-[220px]"
                          size="sm"
                          variant="outline"
                        >
                          Apply Now
                        </Button>
                      </div>
                    </div>
                    <Line className="bg-blue-A200 h-[200px] md:h-[5px] mb-[73px] md:ml-[0] ml-[93px] rotate-[180deg] rounded-sm w-[5px] md:w-full" />
                  </div>
                  <div className="gap-[50px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 items-center justify-start w-[94%] md:w-full">
                    <div className="bg-white-A700 border border-gray-400_7f border-solid flex flex-col items-center justify-start p-1 rounded-[15px] w-full">
                      <div className="flex flex-col items-start justify-start mb-1.5 mt-[9px] w-[98%] md:w-full">
                        <div className="flex sm:flex-col flex-row sm:gap-5 items-start justify-start w-full">
                          <Img
                            className="sm:flex-1 h-[33px] md:h-auto sm:mt-0 mt-[5px] object-cover w-[24%] sm:w-full"
                            src="images/img_image11 copy.png"
                            alt="imageEleven"
                          />
                          <Text
                            className="ml-1.5 sm:ml-[0] sm:mt-0 mt-[5px] text-base text-blue_gray-400"
                            size="txtInterMedium16"
                          >
                            <>
                              Senior Product
                              <br />
                              Designer
                            </>
                          </Text>
                          <Button
                            className="cursor-pointer h-10 leading-[normal] mb-[3px] sm:ml-[0] ml-[42px] text-base text-center w-[100px]"
                            color="blue_A200"
                          >
                            Remote
                          </Button>
                          <Img
                            className="h-5 sm:ml-[0] ml-[17px] sm:mt-0 mt-2.5 w-5"
                            src="images/img_notification.svg"
                            alt="notification"
                          />
                        </div>
                        <Text
                          className="md:ml-[0] ml-[98px] mt-[7px] text-base text-blue_gray-400"
                          size="txtInterRegular16"
                        >
                          Infosys
                        </Text>
                        <Line className="bg-blue_gray-400 h-px mt-[17px] w-[97%]" />
                        <Text
                          className="mt-3 text-gray-800 text-xs w-[97%] sm:w-full"
                          size="txtInterLight12"
                        >
                          <>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Maxime mollitia, molestiae quas vel sint
                            commodi repudiandae consequuntur voluptatum laborum
                            numquam blanditiis harum quisquam eius sed odit
                            fugiat iusto fuga praesentium
                            <br />
                            optio, eaque rerum! Provident similique accusantium
                            nemo autem.
                          </>
                        </Text>
                        <Button
                          className="cursor-pointer font-semibold h-[50px] leading-[normal] ml-20 md:ml-[0] mt-[13px] text-base text-center w-[220px]"
                          size="sm"
                          variant="outline"
                        >
                          Apply Now
                        </Button>
                      </div>
                    </div>
                    <div className="bg-white-A700 border border-gray-400_7f border-solid flex flex-col items-center justify-start p-1 rounded-[15px] w-full">
                      <div className="flex flex-col items-start justify-start mb-1.5 mt-[9px] w-[98%] md:w-full">
                        <div className="flex sm:flex-col flex-row sm:gap-5 items-start justify-start w-full">
                          <Img
                            className="sm:flex-1 h-6 md:h-auto sm:mt-0 mt-[5px] object-cover w-[24%] sm:w-full"
                            src="images/img_image8 copy.png"
                            alt="imageEight"
                          />
                          <Text
                            className="ml-2 sm:ml-[0] sm:mt-0 mt-[5px] text-base text-blue_gray-400"
                            size="txtInterMedium16"
                          >
                            <>
                              Senior Product
                              <br />
                              Designer
                            </>
                          </Text>
                          <Button
                            className="cursor-pointer h-10 leading-[normal] mb-[3px] sm:ml-[0] ml-[42px] text-base text-center w-[100px]"
                            color="blue_A200"
                          >
                            Remote
                          </Button>
                          <Img
                            className="h-5 sm:ml-[0] ml-[17px] sm:mt-0 mt-2.5 w-5"
                            src="images/img_notification.svg"
                            alt="notification_One"
                          />
                        </div>
                        <Text
                          className="md:ml-[0] ml-[98px] mt-2 text-base text-blue_gray-400"
                          size="txtInterRegular16"
                        >
                          OpenAI
                        </Text>
                        <Line className="bg-blue_gray-400 h-px mt-[17px] w-[97%]" />
                        <Text
                          className="mt-3 text-gray-800 text-xs w-[97%] sm:w-full"
                          size="txtInterLight12"
                        >
                          <>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Maxime mollitia, molestiae quas vel sint
                            commodi repudiandae consequuntur voluptatum laborum
                            numquam blanditiis harum quisquam eius sed odit
                            fugiat iusto fuga praesentium
                            <br />
                            optio, eaque rerum! Provident similique accusantium
                            nemo autem.
                          </>
                        </Text>
                        <Button
                          className="cursor-pointer font-semibold h-[50px] leading-[normal] ml-20 md:ml-[0] mt-[13px] text-base text-center w-[220px]"
                          size="sm"
                          variant="outline"
                        >
                          Apply Now
                        </Button>
                      </div>
                    </div>
                    <div className="bg-white-A700 border border-gray-400_7f border-solid flex flex-col items-center justify-start p-1 rounded-[15px] w-full">
                      <div className="flex flex-col items-start justify-start my-1.5 w-[98%] md:w-full">
                        <div className="flex sm:flex-col flex-row sm:gap-5 items-start justify-start w-full">
                          <Img
                            className="sm:flex-1 h-[42px] md:h-auto mb-1 object-cover w-[15%] sm:w-full"
                            src="images/img_image2 copy 4.png"
                            alt="imageTwo"
                          />
                          <Text
                            className="sm:ml-[0] ml-[41px] sm:mt-0 mt-2 text-base text-blue_gray-400"
                            size="txtInterMedium16"
                          >
                            <>
                              Senior Product
                              <br />
                              Designer
                            </>
                          </Text>
                          <Button
                            className="cursor-pointer h-10 leading-[normal] sm:ml-[0] ml-[42px] sm:mt-0 my-[3px] text-base text-center w-[100px]"
                            color="blue_A200"
                          >
                            Remote
                          </Button>
                          <Img
                            className="h-5 sm:ml-[0] ml-[17px] sm:mt-0 mt-[13px] w-5"
                            src="images/img_notification.svg"
                            alt="notification_Two"
                          />
                        </div>
                        <Text
                          className="md:ml-[0] ml-[98px] mt-1.5 text-base text-blue_gray-400"
                          size="txtInterRegular16"
                        >
                          Meta
                        </Text>
                        <Line className="bg-blue_gray-400 h-px mt-[18px] w-[97%]" />
                        <Text
                          className="mt-3 text-gray-800 text-xs w-[97%] sm:w-full"
                          size="txtInterLight12"
                        >
                          <>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Maxime mollitia, molestiae quas vel sint
                            commodi repudiandae consequuntur voluptatum laborum
                            numquam blanditiis harum quisquam eius sed odit
                            fugiat iusto fuga praesentium
                            <br />
                            optio, eaque rerum! Provident similique accusantium
                            nemo autem.
                          </>
                        </Text>
                        <Button
                          className="cursor-pointer font-semibold h-[50px] leading-[normal] ml-20 md:ml-[0] mt-[13px] text-base text-center w-[220px]"
                          size="sm"
                          variant="outline"
                        >
                          Apply Now
                        </Button>
                      </div>
                    </div>
                  </div>
                </List>
              </div>
            </div>
          </div>


                 </div>
                </div>
              </div>
            {/* </div>
          </div> */}
        </>
  );
};

export default UniversityCompanyProfile;
