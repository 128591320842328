const FETCH_PROFILE_PICTURE_REQUEST = "FETCH_PROFILE_PICTURE_REQUEST";
const FETCH_PROFILE_PICTURE_SUCCESS = "FETCH_PROFILE_PICTURE_SUCCESS";
const FETCH_PROFILE_PICTURE_FAILURE = "FETCH_PROFILE_PICTURE_FAILURE";


export const fetchProfilePersonalData = (userId) => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_PROFILE_PERSONAL_DATA_REQUEST" });
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/get_personal_data`;
      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: userId }),
      };

      const userResponse = await fetch(apiUrl, requestOptions);

      if (userResponse.ok) {
        const userData = await userResponse.json();
        const userDetails = userData.data[1][0];
        dispatch({
          type: "FETCH_PROFILE_PERSONAL_DATA_SUCCESS",
          payload: userDetails,
        });
      } else {
        dispatch({
          type: "FETCH_PROFILE_PERSONAL_DATA_FAILURE",
          payload: "Failed to fetch personal data",
        });
        throw new Error("Failed to fetch personal data");
      }
    } catch (error) {
      dispatch({ type: "FETCH_PROFILE_PERSONAL_DATA_FAILURE", payload: error });
      throw error;
    }
  };
};

export const fetchProfilePicture = (userId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_PROFILE_PICTURE_REQUEST });
    try {
      if (!userId) {
        dispatch({ type: FETCH_PROFILE_PICTURE_FAILURE, payload: "User ID is required" });
        return;
      }
      const apiUrl = `${process.env.REACT_APP_API_URL}/get_profile_picture`;
      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: userId }),
      };

      const userResponse = await fetch(apiUrl, requestOptions);
      if (userResponse.ok) {
        const userData = await userResponse.json();
        const profilePicture = userData.picture_link || "images/defaultprofilepicture.svg";
        dispatch({ type: FETCH_PROFILE_PICTURE_SUCCESS, payload: profilePicture });
      } else {
        dispatch({ type: FETCH_PROFILE_PICTURE_FAILURE, payload: "Failed to fetch profile picture" });
        throw new Error("Failed to fetch profile picture");
      }
    } catch (error) {
      dispatch({ type: FETCH_PROFILE_PICTURE_FAILURE, payload: error.message });
      throw error;
    }
  };
};
