export const setAccountType = (user_id) => {
  return async (dispatch) => {
    try {
      const backendUrl = `${process.env.REACT_APP_API_URL}/get_account_type`; // Update the backend URL
      const response = await fetch(backendUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id }),
      });

      const response_json = await response.json();

      if (
        !response_json ||
        !response_json.data ||
        !response_json.data.account_type ||
        response_json == undefined ||
        response_json == null
      ) {
        dispatch({ type: "SET_ACCOUNT_TYPE", payload: 0 });
      }

      const accountType = response_json.data.account_type || 0; // Defaulting to 0 if accountType is not present

      dispatch({ type: "SET_ACCOUNT_TYPE", payload: accountType });
    } catch (error) {
      console.error("Error fetching highcharts data:", error.message);
      throw error;
    }
  };
};
