const countryStates = [
  {
    country: "United States",
    states: [
      "Alabama",
      "Alaska",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "Florida",
      "Georgia",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ],
  },
  {
    country: "Canada",
    states: [
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Nova Scotia",
      "Ontario",
      "Prince Edward Island",
      "Quebec",
      "Saskatchewan",
    ],
  },
  {

    country: "India",
    states: [
      "Andaman and Nicobar Islands",
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chandigarh",
      "Chhattisgarh",
      "Dadra and Nagar Haveli and Daman and Diu",
      "Delhi",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jammu and Kashmir",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "Ladakh",
      "Lakshadweep",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Odisha",
      "Puducherry",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Telangana",
      "Tripura",
      "Uttar Pradesh",
      "Uttarakhand",
      "West Bengal",




    ],
  },
  {
    country: "Australia",
    states: [
      "New South Wales",
      "Victoria",
      "Queensland",
      "South Australia",
      "Western Australia",
      "Tasmania",
      "Northern Territory",
      "Australian Capital Territory",
    ],
  },
  {
    country: "Germany",
    states: [
      "Bavaria",
      "Baden-Württemberg",
      "North Rhine-Westphalia",
      "Lower Saxony",
      "Hesse",
      "Rhineland-Palatinate",
      "Berlin",
      "Brandenburg",
      "Hamburg",
      "Schleswig-Holstein",
      "Saxony",
      "Thuringia",
      "Mecklenburg-Vorpommern",
      "Saarland",
      "Saxony-Anhalt",
      "Bremen",
    ],
  },
  {
    country: "United Kingdom",
    states: [
      "England",
      "London",
      "Greater Manchester",
      "West Midlands",
      "West Yorkshire",
      "Merseyside",
      "South Yorkshire",
      "Scotland",
      "Glasgow",
      "Edinburgh",
      "Aberdeen",
      "Wales",
      "Cardiff",
      "Swansea",
      "Newport",
      "Northern Ireland",
      "Belfast",
      "Derry",
      "Armagh",
    ],
  },
  {
    country: "China",
    states: [
      "Beijing",
      "Shanghai",
      "Tianjin",
      "Chongqing",
      "Hebei",
      "Shanxi",
      "Inner Mongolia",
      "Liaoning",
      "Jilin",
      "Heilongjiang",
      "Jiangsu",
      "Zhejiang",
      "Anhui",
      "Fujian",
      "Jiangxi",
      "Shandong",
      "Henan",
      "Hubei",
      "Hunan",
      "Guangdong",
      "Guangxi",
      "Hainan",
      "Sichuan",
      "Guizhou",
      "Yunnan",
      "Tibet",
      "Shaanxi",
      "Gansu",
      "Qinghai",
      "Ningxia",
      "Xinjiang",
    ],
  },
  {
    country: "France",
    states: [
      "Île-de-France",
      "Provence-Alpes-Côte d'Azur",
      "Occitanie",
      "Auvergne-Rhône-Alpes",
      "Nouvelle-Aquitaine",
      "Hauts-de-France",
      "Grand Est",
      "Normandy",
      "Brittany",
      "Pays de la Loire",
    ],
  },
  {
    country: "Japan",
    states: [
      "Tokyo",
      "Osaka",
      "Kyoto",
      "Hokkaido",
      "Aichi",
      "Fukuoka",
      "Kanagawa",
      "Chiba",
      "Saitama",
      "Hyogo",
      "Hiroshima",
    ],
  },
  {
    country: "Brazil",
    states: [
      "São Paulo",
      "Rio de Janeiro",
      "Minas Gerais",
      "Bahia",
      "Rio Grande do Sul",
      "Pará",
      "Amazonas",
      "Ceará",
      "Pernambuco",
      "Santa Catarina",
      "Goiás",
      "Espírito Santo",
      "Mato Grosso",
      "Tocantins",
      "Distrito Federal",
    ],
  },
  {
    country: "Mexico",
    states: [
      "Mexico City",
      "Jalisco",
      "Nuevo León",
      "Guanajuato",
      "Veracruz",
      "Puebla",
      "Chihuahua",
      "Baja California",
      "Michoacán",
      "Sonora",
      "Tamaulipas",
    ],
  },
  {
    country: "South Africa",
    states: [
      "Gauteng",
      "Western Cape",
      "KwaZulu-Natal",
      "Eastern Cape",
      "Free State",
      "North West",
      "Mpumalanga",
      "Limpopo",
      "Northern Cape",
    ],
  },
];


export default countryStates