import WebsiteFooter from "components/WebsiteFooter/WebsiteFooter";
import SearchContainer from "components/SearchContainer";
import Container from "components/Container/Container";

const Careers = () => {
  return (
    // <div className="w-full  relative bg-white overflow-hidden flex flex-col items-start justify-start  box-border gap-[64px] leading-[normal] tracking-[normal] mq825:gap-[32px] mq450:gap-[16px] mq1125:h-auto flex flex-row">
            <>
            <Container mainhead="Careers"  subtext=" Your Future Starts Here: Discover Opportunities and Unleash Your
        Potential with Us"/>

      <section className="self-stretch flex flex-row items-start justify-center pt-[150px] pb-[170px] px-[25px] mqtab2:px-[6rem] mqteb1:px-[4.7rem] mqmob1:px-[6rem] box-border max-w-full shrink-0 mq450:pb-[77px] mq450:box-border mq1125:pb-[119px] mq1125:box-border">
        <SearchContainer />
      </section>
      <section >
      <WebsiteFooter />
      </section>
      </>
      
    // </div>
  );
};

export default Careers;
