import {
  Dialog
} from "@mui/material";
import { Link } from "react-router-dom";

import { Button, Text } from "components";
import Sidebar1 from "pages/RecruiterDashBoardPages/RecruiterSidebar";
import TopHeader from "pages/RecruiterDashBoardPages/TopHeader";
import { useState } from "react";

const AfterUplaodOrFillJDPage = () => {

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const [Stopen, setst] = useState(false);

  const handleStartOpen = () => {

    setst(true);
  };

  // const handleStartClose = () => {
  //   setst(false);
  // };

  return (
    <>
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <Sidebar1 clickedTab={"Add Job"} />
          <div className="flex flex-1 flex-col font-inter gap-[50px] items-center justify-start md:px-5 w-full">
            <div className="bg-white-A700 flex flex-row md:gap-10 items-center justify-between p-[13px] w-full">
              <TopHeader header={"Add Job"} />
            </div>

            <div className="bg-white-A700 flex flex-col items-center justify-start p-[13px] rounded-[15px] w-[95%] md:w-full ">
              <div className="flex flex-col gap-2.5 items-center justify-start my-[22px] w-[97%] md:w-full">
                <div className="flex md:flex-col flex-row md:gap-5 items-end justify-end pb-[9px] pl-[9px] w-full">
                  {/* <Text
                    className="mb-[7px] md:mt-0 mt-5 text-2xl md:text-[22px] text-blue-A200 sm:text-xl"
                    size="txtInterSemiBold24"
                  >
                    Job Description
                  </Text> */}
                  <Text
                    className="mb-7 md:mt-0 mt-5 text-2xl md:text-22px text-blue-A200 sm:text-xl whitespace-wrap"
                    size="txtInterSemiBold24"
                  >
                    Job Description
                  </Text>

                  <Button onClick={handleOpen} className="cursor-pointer font-medium h-[50px] leading-[normal] mb-2 md:ml-[0] ml-[758px] text-center text-xl w-[220px]">
                    Preview
                  </Button>
                  <Dialog open={open} disableBackdropClick={true}
                    // onClose={handleClose} 
                    maxWidth="lg">

                    <div className="image">
                      <img className="preview-pop-up" alt="Preview pop up" src="https://c.animaapp.com/RqGm1aZY/img/previewpopup.png" />
                    </div>

                  </Dialog>
                  <Button onClick={handleStartOpen} className="cursor-pointer font-medium h-[50px] leading-[normal] mb-2 md:ml-[0] ml-[77px] text-center text-xl w-[211px]">
                    Post a job
                  </Button>
                  <Dialog open={Stopen} disableBackdropClick={true}
                    //  onClose={handleStartClose}
                    maxWidth="xl" >

                    <div className="job-submitted" style={{}} >


                      <div className="overflow-hidden start-interview" >
                        <div className="group-wrapper" style={{
                          height: '354px',
                          overflow: 'visible',
                          width: '764px',
                        }}>


                          <p className="your-job-is-posted" style={{
                            position: 'relative',
                            left: '14%'
                          }}>
                            Your Job Is Posted without the automated workflow. <br />
                            You can find it in “Jobs”
                            <br />
                            <br />
                            Add a workflow right now?
                          </p>
                          <div className="frame" style={{ left: "14%" }}>
                            <Link to='/addjob-workflow'>
                              <div className="text-wrapper">Yes</div>
                            </Link>
                          </div>
                          <div className="div-wrapper" style={{ left: "59%" }} >
                            <div className="text-wrapper-2">Skip</div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </Dialog>
                </div>
                <div className="flex md:flex-col flex-row md:gap-10 items-start justify-between w-[98%] md:w-full">
                  <div className="flex flex-col items-start justify-start w-[36%] md:w-full">
                    <Text
                      className="text-center text-gray-700 text-xl"
                      size="txtInterSemiBold20"
                    >
                      Job Details
                    </Text>
                    <div className="flex flex-col gap-[11px] items-start justify-start mt-[27px] w-full">
                      <Text
                        className="text-base text-gray-700"
                        size="txtInterMedium16"
                      >
                        Job title
                      </Text>
                      <input
                        name="frameFour"
                        placeholder="Project Manager"
                        className="leading-[normal] p-3  text-[15px] text-left w-full border border-gray-300 border-solid rounded-[12px]"
                      />
                    </div>
                    <div className="flex flex-col gap-2.5 items-start justify-start mt-6 w-full">
                      <Text
                        className="text-base text-gray-700"
                        size="txtInterMedium16"
                      >
                        Workplace type
                      </Text>
                      <input
                        name="frameFour_One"
                        placeholder="Hybrid"
                        className="leading-[normal] p-3  text-[15px] text-left w-full border border-gray-300 border-solid rounded-[12px]"

                      />
                    </div>
                    <div className="flex flex-col gap-[11px] items-start justify-start mt-[23px] w-full">
                      <Text
                        className="text-base text-gray-700"
                        size="txtInterMedium16"
                      >
                        Job Location
                      </Text>
                      <input
                        name="frameFour_Two"
                        placeholder="Hyderabad"
                        className="leading-[normal] p-3  text-[15px] text-left w-full border border-gray-300 border-solid rounded-[12px]"
                      />
                    </div>
                    <div className="flex flex-col gap-2.5 items-start justify-start mt-6 w-full">
                      <Text
                        className="text-base text-gray-700"
                        size="txtInterMedium16"
                      >
                        Employment type
                      </Text>
                      <input
                        name="frameFour_Three"
                        placeholder="Full-time"
                        className="leading-[normal] p-3  text-[15px] text-left w-full border border-gray-300 border-solid rounded-[12px]"
                      />
                    </div>
                    <div className="flex flex-col gap-2.5 items-start justify-start mt-6 w-full">
                      <Text
                        className="text-base text-gray-700"
                        size="txtInterMedium16"
                      >
                        <span className="text-gray-700 font-inter text-left font-medium">
                          Skills required{" "}
                        </span>
                        <span className="text-deep_orange-600 font-inter text-left font-medium">
                          *
                        </span>
                      </Text>
                      <input
                        name="frameFour_Four"
                        placeholder="Time management, Leadership, Critical thinking ..."
                        className="leading-[normal] p-3  text-[15px] text-left w-full border border-gray-300 border-solid rounded-[12px]"
                      />
                    </div>
                    <div className="flex flex-col gap-[11px] items-start justify-start mt-[23px] w-full">
                      <Text
                        className="text-base text-gray-700"
                        size="txtInterMedium16"
                      >
                        Set Threshold %
                      </Text>
                      <input
                        name="frameFour_Five"
                        placeholder="80%"
                        className="leading-[normal] p-3  text-[15px] text-left w-full border border-gray-300 border-solid rounded-[12px]"
                      />
                    </div>
                    <div className="flex flex-col gap-[11px] items-start justify-start mt-[23px] w-full">
                      <Text
                        className="text-base text-gray-700"
                        size="txtInterMedium16"
                      >
                        Recruitment valid till:
                      </Text>
                      <input
                        name="frameFour_Six"
                        placeholder="10 June, 2022"
                        className="leading-[normal] p-3  text-[15px] text-left w-full border border-gray-300 border-solid rounded-[12px]"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-2.5 items-start justify-start w-[58%] md:w-full">
                    <Text
                      className="text-base text-gray-700"
                      size="txtInterMedium16"
                    >
                      Job description
                    </Text>
                    <textarea
                      name="description"
                      placeholder={`Position Overview

The Project Manager plans and oversees one or multiple inter-dependent projects spanning multiple years that impact one or more business units or one larger project. He/She will oversee all aspects of assigned projects throughout project lifecycles to ensure completion within the defined scope, quality, time, and cost constraints. Also, to ensure accurate allocations of resources throughout the project. He/She will lead multi-disciplinary teams, composed of various levels of personnel, vendors, and clients to create and deploy successful projects.

You will need to be someone who is confident and decisive in leading projects, overseeing the completion and integration of interdependent projects and parts

Role & Responsibilities

Set project management framework

Provide inputs and support to develop the project management framework, templates, tools, and systems to deliver cost, time, and quality metrics
Design training to enable effective application of project management framework across the organisation
Support the Snr Project Manager in governance of project management framework through audits
Guide team in adoption of the set framework at the appropriate level

Develop project plan

Develop the integrated project plan including detailed activities, resource planning, cost estimation per project
Work with stakeholders to establish and gain consensus on project goals, objectives, and deliverables
Review project plan to determine time frame, funding limitations, procedures for accomplishing projects
Procure project requirements such as workforce, required information, various agreements, and material or technology needed
Guide team to deliver plans aligned to overall project objectives
Set expectations for periodic and milestone reviews including status reports, project risk identification, and other dashboards

Implement project

Drive project to meet schedules, budget, manpower, and technical quality targets
Drive project discipline to document scope changes, issues, and risks that affect implementation
Work with users, technical staff, and management to determine and resolve issues associated with project implementation
Conduct regular project reviews to recommend schedule changes, cost, or resource adjustments

Manage teams

Oversee team management including budgets, forecasting, work allocations, and staffing
Develop staff through ongoing coaching, mentoring, and career discussions
Define common goals, direction, and accountability among staff
Drive effective performance management practices within department in accordance with company policies and procedures

Requirements

Possess a Bachelor degree in Computer Science, Computer Engineering, Information Technology, or any other relevant discipline
Minimum 5 to 10 years in IT project management and familiar with ITIL
Excellent in both written and verbal communications skills and able to effectively influence various internal and external stakeholders
Demonstrated ability to work in a team with service centric mindset
Able to work in ambiguous situations with a can-do attitude
Attention to detail and able to multi-task and manage various project elements simultaneously
Certified in PMP, CITPM, or other similar PM certification
Preferably with good experience in turnkey projects and system integration
Fundamental knowledge in IT infrastructure (servers, storage, network, EUC) and IT security
Experience in bio-medical equipment systems and IoT systems are desirable but not necessary
Keep in tune with latest and advanced digital technologies
Sound understanding and hands-on experience in all phases of the project lifecycle`}
                      className="font-medium leading-normal md:h-auto p-0 placeholder:text-gray-700 sm:h-auto text-base text-left w-full outline outline-[1px] outline-gray-400_7f w-full"
                      cols={50}
                      rows={29}
                      style={{ fontSize: "16px", padding: "10px" }}
                    />

                    {/* <textarea
  name="description"
  placeholder="Position Overview..." // Use a concise placeholder
  className="font-medium leading-normal md:h-auto p-0 placeholder:text-gray-700 sm:h-auto text-base text-left w-full outline outline-[1px] outline-gray-400_7f w-full"
  rows={10} // Adjust the number of visible rows as needed
  value="fvdbrenenthntnviuejbejbieb"// Assuming you have a state variable for the description // Handle text changes
/> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AfterUplaodOrFillJDPage;
