export const fetchJobSeekerDashboarCounts = (user_id) => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_JOB_SEEKER_DASHBOARD_COUNTS_REQUEST" });
    try {
      const backendUrl = `${process.env.REACT_APP_API_URL}/get_dashboard_counts`;
      const response = await fetch(backendUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id }),
      });
      const data = await response.json();
      dispatch({
        type: "FETCH_JOB_SEEKER_DASHBOARD_COUNTS_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "FETCH_JOB_SEEKER_DASHBOARD_COUNTS_FAILURE",
        payload: error.message,
      });
      console.error("Error fetching dashboard counts:", error.message);
      throw error;
    }
  };
};
