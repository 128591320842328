import React from "react";
import Modal from "react-modal";
import { Button } from "components";

const InactivityPopup = ({ isOpen, onClose, heading, subtext }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          zIndex: 1000,
        },
        content: {
          backgroundColor: "white",
          width: "80%",
          maxWidth: "400px",
          height: "auto",
          maxHeight: "200px",
          margin: "auto",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          borderRadius: "10px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <div style={{ textAlign: "center" }}>
        <h2 style={{ fontSize: "22px", fontWeight: "bold", marginBottom: "10px" }}>
          {heading}
        </h2>
        <p style={{ fontSize: "16px", marginBottom: "20px", fontWeight: "normal" }}>
          {subtext}
        </p>
        <div className="flex flex-row justify-center items-center">
          <Button
            className="rounded-[12px] sm:text-[14px] w-[100px] h-[30px] flex flex-row justify-center items-center"
            onClick={onClose}
          >
            Okay
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default InactivityPopup;
