import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import Container from "components/Container/Container";
import WebsiteFooter from "components/WebsiteFooter/WebsiteFooter";
const AllResumeTemplates = () => {
  const resumeTemplatesList = [
    {
      id: 1,
      categeryType: "Creative",
      imageUrl: "resumeBuilder/Resume1.png",
    },
    {
      id: 2,
      categeryType: "Creative",
      imageUrl: "resumeBuilder/Resume2.png",
    },
    {
      id: 3,
      categeryType: "Creative",
      imageUrl: "resumeBuilder/Resume3.png",
    },
    {
      id: 4,
      categeryType: "Simple",
      imageUrl: "resumeBuilder/Resume4.png",
    },
    {
      id: 5,
      categeryType: "Simple",
      imageUrl: "resumeBuilder/Resume5.png",
    },
    {
      id: 6,
      categeryType: "Simple",
      imageUrl: "resumeBuilder/Resume6.png",
    },
    {
      id: 7,
      categeryType: "Modern",
      imageUrl: "resumeBuilder/Resume7.png",
    },
    {
      id: 8,
      categeryType: "Modern",
      imageUrl: "resumeBuilder/Resume8.png",
    },
    {
      id: 9,
      categeryType: "Modern",
      imageUrl: "resumeBuilder/Resume9.png",
    },
    {
      id: 10,
      categeryType: "Professional",
      imageUrl: "resumeBuilder/Resume10.png",
    },
    {
      id: 11,
      categeryType: "Professional",
      imageUrl: "resumeBuilder/Resume11.png",
    },
    {
      id: 12,
      categeryType: "Professional",
      imageUrl: "resumeBuilder/Resume12.png",
    },
    {
      id: 13,
      categeryType: "Professional",
      imageUrl: "resumeBuilder/Resume13.png",
    },
    {
      id: 14,
      categeryType: "Professional",
      imageUrl: "resumeBuilder/Resume14.png",
    },
    {
      id: 15,
      categeryType: "Professional",
      imageUrl: "resumeBuilder/Resume15.png",
    },
    {
      id: 16,
      categeryType: "Professional",
      imageUrl: "resumeBuilder/Resume16.png",
    },
    {
      id: 17,
      categeryType: "Professional",
      imageUrl: "resumeBuilder/Resume17.png",
    },
    {
      id: 18,
      categeryType: "Professional",
      imageUrl: "resumeBuilder/Resume18.png",
    },
    {
      id: 19,
      categeryType: "Professional",
      imageUrl: "resumeBuilder/Resume19.png",
    },
    {
      id: 20,
      categeryType: "Professional",
      imageUrl: "resumeBuilder/Resume20.png",
    },
  ];

  return (
    <>
    <Container mainhead=" All Resume Templates"/> 
      <div className="out-container">
        <div className="in-container">         
          <div className="template-grid">
            {resumeTemplatesList.map((template, index) => (
              <div key={index} className="template-item">
                <img src={template.imageUrl} alt="template" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className=" relative h-[200px] top-[380px] ">
         < WebsiteFooter />
</div>
    </>
  );
};
export default AllResumeTemplates;
