import AlertModal from 'components/AlertModal';
import supabase from "components/superBaseConfig";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getEnvURL } from "utils/getEnvURL";
const frontEndURL = getEnvURL();

const RecruiterRoboLogo31Icon = styled.img`
  position: absolute;
  top: 8.1px;
  left: 0px;
  width: 68.5px;
  height: 67.1px;
  object-fit: cover;
`;
const RecruiterRoboLogo31Wrapper = styled.div`
  position: relative;
  width: 68.5px;
  height: 82.5px;
  overflow: hidden;
  flex-shrink: 0;
`;
const WebsiteName = styled.div`
  position: relative;
  line-height: 135.5%;
  font-weight: 600;
  display: inline-block;
  width: 150.5px;
  height: 24.1px;
  color: #161616;
  flex-shrink: 0;
`;
const FrameParent = styled.div`
  position: absolute;
  top: 30px;
  left: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const SignInTo = styled.div`
  position: relative;
  font-size: 18.39px;
  line-height: 29.88px;
  font-weight: 600;
  text-align: center;
  z-index: 0;
`;
const InputLabelmedium = styled.div`
  position: absolute;
  top: 0%;
  left: calc(50% - 151.7px);
  line-height: 16.66px;
`;

const RectangleParent = styled.div`
  position: absolute;
  top: 17.2px;
  left: calc(50% - 151.7px);
  width: 303.4px;
  height: 29.9px;
  font-size: 9.19px;
  color: var(--color-darkgray-100);
`;
const Email = styled.div`
  position: relative;
  width: 303.4px;
  height: 47.1px;
  z-index: 1;
`;
const RectangleGroup = styled.div`
  position: absolute;
  top: 17.2px;
  left: calc(50% - 151.7px);
  width: 303.4px;
  height: 29.9px;
  font-size: 9.19px;
  color: var(--color-silver-100);
`;
const Email1 = styled.div`
  position: absolute;
  margin: 0 !important;
  height: 51.99%;
  top: 110.38%;
  bottom: -62.36%;
  left: calc(50% - 151.7px);
  width: 303.4px;
  z-index: 2;
`;
const SignInToDhiwiseParent = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 13.46px;
`;
const InputLabelsmall = styled.div`
  position: relative;
  font-size: 10.34px;
  line-height: 16.66px;
  text-align: right;
  cursor: pointer;
`;
const SubmitChild = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 151.7px);
  border-radius: 3.45px;
  background-color: var(--color-darkslateblue);
  width: 303.4px;
  height: 28.7px;
`;
const LogIn = styled.div`
  position: absolute;
  top: 6.9px;
  left: calc(50% - 13.2px);
  line-height: 14.94px;
`;
const Submit = styled.div`
  position: relative;
  width: 303.4px;
  height: 28.7px;
  color: var(--color-white);
`;
const OrContinueWith1 = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 34.5px);
  line-height: 14.94px;
`;
const OrContinueWithChild = styled.div`
  position: absolute;
  top: 7.2px;
  left: calc(50% + 43.9px);
  background-color: var(--color-silver-100);
  border-top: 0.6px solid var(--color-silver-100);
  box-sizing: border-box;
  width: 103.4px;
  height: 0.6px;
`;
const OrContinueWithItem = styled.div`
  position: absolute;
  top: 7.2px;
  left: calc(50% - 147.4px);
  background-color: var(--color-silver-100);
  border-top: 0.6px solid var(--color-silver-100);
  box-sizing: border-box;
  width: 104px;
  height: 0.6px;
`;
const OrContinueWith = styled.div`
  position: relative;
  width: 294.2px;
  height: 15px;
  text-align: left;
  color: var(--color-silver-100);
`;
const LoginChild = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 151.7px);
  border-radius: 3.45px;
  border: 0.6px solid var(--color-gray-400);
  box-sizing: border-box;
  width: 303.4px;
  height: 28.7px;
`;
const LoginParent = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 151.7px);
  width: 303.4px;
  height: 28.7px;
`;
const Google1Icon = styled.img`
  position: relative;
  width: 13.8px;
  height: 13.8px;
  overflow: hidden;
  flex-shrink: 0;
`;
const SignInWith = styled.div`
  position: relative;
  line-height: 14.94px;
`;
const Google1Parent = styled.div`
  position: absolute;
  top: 6.9px;
  left: calc(50% - 56.1px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 3.45px;
`;
const Facebook1Parent = styled.div`
  position: absolute;
  top: 6.9px;
  left: calc(50% - 55.8px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 3.45px;
`;
const LoginGroup = styled.div`
  position: absolute;
  top: 37.9px;
  left: calc(50% - 151.7px);
  width: 303.4px;
  height: 28.7px;
`;
const Linkedin11Parent = styled.div`
  position: absolute;
  top: 6.9px;
  left: calc(50% - 56.1px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 3.45px;
`;
const LoginContainer = styled.div`
  position: absolute;
  top: 75.8px;
  left: calc(50% - 151.7px);
  width: 303.4px;
  height: 28.7px;
`;
const GroupParent = styled.div`
  position: relative;
  width: 303.4px;
  height: 104.6px;
`;
const InputLabelsmallParent = styled.div`
  position: absolute;
  top: 151.7px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 13.46px;
  text-align: center;
  font-size: 9.19px;
  color: var(--color-gray-400);
`;
const SignUp = styled.span`
  text-decoration: underline;
`;
const DontHaveAnContainer = styled.div`
  position: absolute;
  top: 372.3px;
  left: 58.8px;
  font-size: 11.69px;
  line-height: 135.5%;
  font-family: var(--font-poppins);
  color: var(--color-black);
  cursor: pointer;
`;
const FrameGroup = styled.div`
  position: absolute;
  top: 164px;
  left: calc(50% - 151.5px);
  width: 303.4px;
  height: 388px;
  font-size: 10.34px;
  color: var(--color-darkslategray-200);
  font-family: var(--font-inter);
`;
const RecruiterRoboSignInlogInRoot = styled.div`
  position: fixed;
  background-color: var(--color-whitesmoke);
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: left;
  font-size: 18.39px;
  color: var(--color-gray-300);
  font-family: var(--font-poppins);
`;

const UniversityMobSignInlogIn = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onInputLabelSmallTextClick = useCallback(() => {
    navigate("/university-forgotpassword");
  }, [navigate]);

  const onDontHaveAnClick = useCallback(() => {
    navigate("/university-signup");
  }, [navigate]);

  const onGmailLogin = useCallback(async () => {
    const {  error } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        queryParams: {
          access_type: "offline",
          prompt: "consent",
        },
        redirectTo: `${frontEndURL}/jobseeker-dashboard`,
      },
    });

    const receivedError =  error;

    if (receivedError) {
      throw receivedError;
    }

  },[]);

  const onLinkedinLogin = useCallback(() => {
    window.location.replace("https://www.linkedin.com/login/");
  }, []);

  const onFacebookLogin = useCallback(() => {
    window.location.replace("https://www.facebook.com/login/");
  }, []);
  const onLogo = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const [error, setError] = useState(false);
  const [loadingVal, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      // alert(email);
      // alert(password);

      try {
        const { error } = await supabase.auth.signInWithPassword({
          email: email,
          password: password,
        });

        // alert("Data returned is" + JSON.stringify(data));
        // alert("User data is " + JSON.stringify(supabase.auth.getUser()));
        if (!error) {
          // alert("Login Successful");
          setLoading(false);
          //console.log("loading is set false");
          navigate("/University-dashboard");
        } else {
          //console.log("loading is set false");
          setLoading(false);
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 3000)
          // alert("Please try again");
        }
      } catch (error) {
        setLoading(false);
        //console.log("loading is set false");
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000)
        // alert(error);
      }
    },
    [email,navigate, password],
  );

  const onLogin = useCallback(
    (e) => {
      setLoading(true);
      handleSubmit(e);
    },
    [handleSubmit],
  );

  return (
    <RecruiterRoboSignInlogInRoot>
      {loadingVal && (<AlertModal msg="loading" />)}
      {error && (<AlertModal msg="error" />)}
      <FrameParent>
        <RecruiterRoboLogo31Wrapper>
          <RecruiterRoboLogo31Icon
            alt=""
            src="/recruiter-robo-logo-3-1@2x.png"
            onClick={onLogo}
            style={{ cursor: "pointer" }}
          />
        </RecruiterRoboLogo31Wrapper>
        <WebsiteName onClick={onLogo} style={{ cursor: "pointer" }}>
          Recruiter Robo
        </WebsiteName>
      </FrameParent>
      <FrameGroup>
        <SignInToDhiwiseParent>
          <SignInTo>Sign in to Recruiter Robo</SignInTo>
          <Email>
            <InputLabelmedium>Email</InputLabelmedium>
            <RectangleParent>
              <input
                style={{
                  fontSize: "12px",
                  position: "absolute",
                  top: "0px",
                  left: "calc(50% - 151.7px)",
                  borderRadius: "var(--br-8xs-6)",
                  backgroundColor: "var(--color-white)",
                  border: "0.6px solid var(--color-gainsboro)",
                  boxSizing: "border-box",
                  width: "303.4px",
                  height: "29.9px",
                }}
                type="email"
                placeholder="Enter Email"
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                value={email}
              />
            </RectangleParent>
          </Email>
          <Email1>
            <InputLabelmedium>Password</InputLabelmedium>
            <RectangleGroup>
              <input
                style={{
                  fontSize: "12px",
                  position: "absolute",
                  top: "0px",
                  left: "calc(50% - 151.7px)",
                  borderRadius: "var(--br-8xs-6)",
                  backgroundColor: "var(--color-white)",
                  border: "0.6px solid var(--color-gainsboro)",
                  boxSizing: "border-box",
                  width: "303.4px",
                  height: "29.9px",
                }}
                type="password"
                placeholder="Enter Password"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                value={password}
              />
            </RectangleGroup>
          </Email1>
        </SignInToDhiwiseParent>
        <InputLabelsmallParent>
          <InputLabelsmall
            onClick={onInputLabelSmallTextClick}
            style={{ cursor: "pointer" }}
          >
            Forgot Password?
          </InputLabelsmall>
          <Submit onClick={onLogin} style={{ cursor: "pointer" }}>
            <SubmitChild />
            <LogIn>Log in</LogIn>
          </Submit>
          <OrContinueWith>
            <OrContinueWith1>Or continue with</OrContinueWith1>
            <OrContinueWithChild />
            <OrContinueWithItem />
          </OrContinueWith>
          <GroupParent>
            <LoginParent style={{ cursor: "pointer" }}>
              <LoginParent>
                <LoginChild />
              </LoginParent>
              <Google1Parent
                onClick={onGmailLogin}
                style={{ cursor: "pointer" }}
              >
                <Google1Icon alt="" src="/google-1.svg" />
                <SignInWith>Sign in with Google</SignInWith>
              </Google1Parent>
            </LoginParent>
            <LoginGroup style={{ cursor: "pointer" }}>
              <LoginParent>
                <LoginChild />
              </LoginParent>
              <Facebook1Parent
                onClick={onFacebookLogin}
                style={{ cursor: "pointer" }}
              >
                <Google1Icon alt="" src="/facebook-1.svg" />
                <SignInWith>Sign in with Facebook</SignInWith>
              </Facebook1Parent>
            </LoginGroup>
            <LoginContainer style={{ cursor: "pointer" }}>
              <LoginParent>
                <LoginChild />
              </LoginParent>
              <Linkedin11Parent
                onClick={onLinkedinLogin}
                style={{ cursor: "pointer" }}
              >
                <Google1Icon alt="" src="/linkedin-1-1.svg" />
                <SignInWith>Sign in with Linkedin</SignInWith>
              </Linkedin11Parent>
            </LoginContainer>
          </GroupParent>
        </InputLabelsmallParent>
        <DontHaveAnContainer
          onClick={onDontHaveAnClick}
          style={{ cursor: "pointer" }}
        >
          {`Don’t have an account? `}
          <SignUp>Sign up</SignUp>
        </DontHaveAnContainer>
      </FrameGroup>
    </RecruiterRoboSignInlogInRoot>
  );
};

export default UniversityMobSignInlogIn;
