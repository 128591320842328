import {
  fetchProfilePersonalData,
  fetchProfilePicture,
} from "actions/ProfilePersonal.jsx";
import { Button, Img, Text } from "components";
import countryStates from "components/CountriesStates/index.jsx";
import MainLoader from "components/Loader/index.jsx";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ProfileHeader } from "pages/JobSeekerDashBoardPages/ProfileHeader/index.jsx";
import { ProfileHeader2 } from "pages/JobSeekerDashBoardPages/ProfileHeader2/index.jsx";
import { SideBar1 } from "pages/JobSeekerDashBoardPages/SideBar1/index.jsx";
import { useEffect, useState } from "react";
import { WarnigAlertPopup } from "components/InputField";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import AlertModal from "../../../components/AlertModal/index.jsx";

const ProfilepersonalShrashtiPage = () => {
  const dispatch = useDispatch();
  const { personalData, isLoading } = useSelector(
    (state) => state.profilePersonalData
  );
  const { profilePicture } = useSelector(
    (state) => state.profilePictureReducer
  );
  const [imgUrl, setImgUrl] = useState(profilePicture);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [changeFirstName, setChangeFirstName] = useState(false);
  const [changeLastName, setChangeLastName] = useState(false);
  const [changeEmail, setChangeEmail] = useState(false);

  // console.log("Personal Data:", personalData);

  const initialValues = {
    first_name: personalData?.first_name || "",
    last_name: personalData?.last_name || "",
    gender: personalData?.gender || "",
    dob: personalData?.date_of_birth || "",
    email_address: personalData?.email_address || "",
    current_role: personalData?.current_role_ || "",
    countries: personalData?.country || "",
    state: personalData?.state || "",
    city: personalData?.city || "",
    phone_number: personalData?.phone_number || "",
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    dob: Yup.date().required("Date of Birth is required"),
    current_role: Yup.string().required("Current Role is required"),
    email_address: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
    phone_number: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must be digits only")
      .min(10, "Phone number must be at least 10 digits")
      .required("Phone number is required"),
    countries: Yup.string().required("Country is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    gender: Yup.string().required("Gender is required"),
  });

  useEffect(() => {
    if (personalData) {
      setImgUrl(profilePicture);
    } else {
      console.error("Personal data is null");
    }
  }, [personalData, profilePicture]);

  // const userData = JSON.parse(
  //   localStorage.getItem(
  //     `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`
  //   )
  // );

  const userDataString = localStorage.getItem(
    `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`
  );
  const userData = userDataString ? JSON.parse(userDataString) : null;

  if (!userData || !userData.user) {
    window.location.href = "/login"; // Redirect to login page
    return null;
  }

  const user_id = userData.user.id;

  const handleFileUpload = async (event) => {
    setLoading(true);
    const file = event.target.files[0];

    if (file.size > 1 * 1024 * 1024) {
      alert("File size exceeds 1MB limit.");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("profile_picture", file);

    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/save_profile_picture?user_id=${user_id}`;

      const response = await fetch(apiUrl, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      if (response.ok) {
        const imageUrl = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/profile-pictures/${user_id}/${data.filename}`;
        dispatch(fetchProfilePicture(user_id));
      } else {
        console.error("Failed to upload profile picture:", data.message);
      }
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    } finally {
      setLoading(false);
    }
  };

  const savePesonalData = async (values) => {
    // console.log("Submitting form with values:", values);
    setLoading(true);
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/save_personal_data`;
      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...values, user_id }),
      };

      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to save personal data");
      }

      // Handle success
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 2000);

      // Fetch updated personal data
      dispatch(fetchProfilePersonalData(user_id));
    } catch (error) {
      console.error("Error saving personal data:", error);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 2000);
    } finally {
      setLoading(false);
    }
  };

  const currentDate = new Date().toISOString().split("T")[0];

  return (
    <>
      {changeFirstName && <WarnigAlertPopup nameField="first name" />}
      {changeLastName && <WarnigAlertPopup nameField="last name" />}
      {changeEmail && <WarnigAlertPopup nameField="email" />}
      {/* {console.log("Initial Values:", initialValues)} */}

      {success && <AlertModal msg="success" />}
      {error && <AlertModal msg="profile-error" />}
      {loading && <AlertModal msg="loading" />}
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <SideBar1 clickedTab={"Profile"} />
          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full h-[100vh]">
            <ProfileHeader clickedTab={"Profile"} />

            <div className="overflow-auto h-[92vh] bg-white-A700 flex flex-col items-center justify-start p-8 mqtab2:px-9 sm:px-5 rounded-[15px] w-[94%] md:w-full">
              <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={savePesonalData}
              >
                {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  values,
                  isSubmitting,
                }) => {
                  // console.log("Errors:", errors);
                  // console.log("Touched:", touched);
                  // console.log("Is Submitting:", isSubmitting);
                  // console.log("Submitting form with values 3333333:", values);
                  return (
                    <Form className="flex flex-col gap-[3rem] justify-start mt-2.5 w-[99%] md:w-full">
                      <ProfileHeader2 activeTab={"personal"} />
                      {isLoading && <MainLoader />}
                      <div className="flex   flex-row sm:gap-[10px] md:gap-[55px] items-start justify-between w-full gap-[9.6rem] mqlap:gap-[7.4rem] mqtab1:gap-[7rem]">
                        <div>
                          <h1
                            style={{ color: "#6f7074" }}
                            className="font-semibold pb-[15px] pt-[10px] text-[0.98rem] mqtab1:text-[0.84vw] mqlap:text-[0.82vw]"
                          >
                            PROFILE IMAGE
                          </h1>
                          <img
                            src={imgUrl}
                            className="rounded-[50%] w-[8.1rem] h-[8.1rem] mqlap:w-[6.8vw] mqlap:h-[6.8vw] mqtab1:w-[6.8vw] mqtab1:h-[6.8vw]"
                          />
                          <div className="flex justify-center items-center text-[12px] mt-[15px]">
                            <label
                              htmlFor="profileDp"
                              className="flex justify-center items-center"
                            >
                              <Img
                                className="cursor-pointer h-[15px] sm:h-[8px] "
                                src="images/img_ticket.svg"
                                alt="ticket"
                              />

                              <Text
                                style={{ color: "#314ca3" }}
                                className="cursor-pointer pl-[8px] font-medium mqlap:text-[0.73vw] mqtab1:text-[0.75vw]"
                              >
                                Change profile picture
                              </Text>
                              
                            </label>

                            <input
                              id="profileDp"
                              name="profileDp"
                              onChange={handleFileUpload}
                              type="file"
                              className={`w-0 h-0 opacity-0 absolute top-[-9999px]`}
                              accept="image/jpeg, image/png, image/gif"
                            />
                          </div>
                        </div>
                        <div className="sm:gap-[5px] placeholder-[red] flex flex-1 flex-col items-center justify-start w-[80%]  rounded-[15px] w-full mqtab2:w-[100%] mqtab2:flex mqtab2:justify-center mqtab2:items-center">
                          <div className="flex flex-col items-start justify-start gap-[30px] mqtab1:gap-[26px] w-full sm:w-[99%]">
                            <div className="w-full flex justify-between items-center gap-[10px] mqtab1:gap-[15px]">
                              <div className="flex md:flex-1 flex-col gap-2 items-start justify-start w-[49%] md:w-full">
                                <h1
                                  style={{ color: "#6f7074" }}
                                  className="text-base tsm:text-[12px] font-semibold mqlap:text-[0.91vw] mqtab1:text-[0.91vw] "
                                >
                                  First Name *
                                </h1>
                                <Field
                                  className={`cursor-default placeholder-gray-300 leading-[normal] p-3 mqtab1:p-[0.7rem] sm:h-[30px] sm:w-[75vw] sm:text-[10px] text-[15px] text-left w-full border border-gray-300 border-solid rounded-[12px]  mqtab1:text-[0.82vw] mqlap:text-[0.8vw] ${
                                    touched.first_name && errors.first_name
                                      ? "border-indigo-600"
                                      : "border-gray-300"
                                  }`}
                                  name="first_name"
                                  placeholder="Enter First Name"
                                  value={values.first_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  readonly
                                  onClick={() => {
                                    setChangeFirstName(true);
                                    setTimeout(() => {
                                      setChangeFirstName(false);
                                    }, 1500);
                                  }}
                                />
                                <ErrorMessage
                                  name="first_name"
                                  component="div"
                                  className="text-indigo-600 text-xs"
                                />
                              </div>

                              <div className="flex md:flex-1 flex-col gap-2 items-start justify-start w-[49%] md:w-full">
                                <h1
                                  style={{ color: "#6f7074" }}
                                  className="text-base tsm:text-[12px] font-semibold mqlap:text-[0.91rem] mqtab1:text-[0.91vw]"
                                >
                                  Last Name *
                                </h1>
                                <Field
                                  className={`cursor-default placeholder-gray-300 leading-[normal] p-3 mqtab1:p-[0.7rem] sm:h-[30px] sm:w-[75vw] sm:text-[10px] text-[15px] text-left w-full border border-gray-300 border-solid rounded-[12px]  mqtab1:text-[0.82vw] mqlap:text-[0.8vw] ${
                                    touched.last_name && errors.last_name
                                      ? "border-indigo-600"
                                      : "border-gray-300"
                                  } mqlap:text-[0.85rem]`}
                                  name="last_name"
                                  value={values.last_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter Last Name"
                                  onClick={() => {
                                    setChangeLastName(true);
                                    setTimeout(() => {
                                      setChangeLastName(false);
                                    }, 1500);
                                  }}
                                  readonly
                                />
                                <ErrorMessage
                                  className="text-indigo-600 text-xs"
                                  name="last_name"
                                  component="div"
                                />
                              </div>
                            </div>

                            <div className="w-full flex justify-between items-center gap-[10px] mqtab1:gap-[15px]">
                              <div className="flex md:flex-1 flex-col gap-2 items-start justify-start w-[49%] md:w-full">
                                <h1
                                  style={{ color: "#6f7074" }}
                                  className="text-base tsm:text-[12px] font-semibold mqlap:text-[0.91rem] mqtab1:text-[0.91vw]"
                                >
                                  Gender *
                                </h1>
                                <Field
                                  as="select"
                                  className={`cursor-default leading-[normal] placeholder-gray-300  p-3 mqtab1:p-[0.7rem] sm:h-[30px] sm:w-[75vw] sm:text-[10px] text-[15px] text-left w-full border border-gray-300 border-solid rounded-[12px]  mqtab1:text-[0.82vw] mqlap:text-[0.8vw] ${
                                    touched.gender && errors.gender
                                      ? "border-indigo-600"
                                      : "border-gray-300"
                                  } mqlap:text-[0.85rem]`}
                                  name="gender"
                                  value={values.gender}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <option className="text-gray-300" value="" >Select Gender</option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                  <option value="Other">Other</option>
                                </Field>
                                <ErrorMessage
                                  className="text-indigo-600 text-xs"
                                  name="gender"
                                  component="div"
                                />
                              </div>
                              <div className="flex md:flex-1 flex-col gap-2 items-start justify-start w-[49%] md:w-full">
                                <h1
                                  style={{ color: "#6f7074" }}
                                  className="text-base tsm:text-[12px] font-semibold mqlap:text-[0.91rem] mqtab1:text-[0.91vw]"
                                >
                                  Date of Birth *
                                </h1>
                                <Field
                                  type="date"
                                  className={`cursor-default placeholder-gray-300 leading-[normal] p-3 mqtab1:p-[0.7rem] sm:h-[30px] sm:w-[75vw] sm:text-[10px] text-[15px] text-left w-full border border-gray-300 border-solid rounded-[12px]  mqtab1:text-[0.82vw] mqlap:text-[0.8vw] ${
                                    touched.dob && errors.dob
                                      ? "border-indigo-600"
                                      : "border-gray-300"
                                  } mqlap:text-[0.85rem]`}
                                  name="dob"
                                  max={currentDate}
                                  value={values.dob}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage
                                  className="text-indigo-600 text-xs"
                                  name="dob"
                                  component="div"
                                />
                              </div>
                            </div>

                            <div className="w-full flex justify-between items-center gap-[10px] mqtab1:gap-[15px]">
                              <div className="flex md:flex-1 flex-col gap-2 items-start justify-start w-[49%] md:w-full">
                                <h1
                                  style={{ color: "#6f7074" }}
                                  className="text-base tsm:text-[12px] font-semibold mqlap:text-[0.91rem] mqtab1:text-[0.91vw]"
                                >
                                  Email *
                                </h1>
                                <Field
                                  className={`cursor-default leading-[normal] placeholder-gray-300 p-3 mqtab1:p-[0.7rem] sm:h-[30px] sm:w-[75vw] sm:text-[10px] text-[15px] text-left w-full border border-gray-300 border-solid rounded-[12px]  mqtab1:text-[0.82vw] mqlap:text-[0.8vw] ${
                                    touched.email_address &&
                                    errors.email_address
                                      ? "border-indigo-600"
                                      : "border-gray-300"
                                  } mqlap:text-[0.85rem]`}
                                  name="email_address"
                                  value={values.email_address}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter Email Address"
                                  onClick={() => {
                                    setChangeEmail(true);
                                    setTimeout(() => {
                                      setChangeEmail(false);
                                    }, 1500);
                                  }}
                                />
                                <ErrorMessage
                                  className="text-indigo-600 text-xs"
                                  name="email_address"
                                  component="div"
                                />
                              </div>

                              <div className="flex md:flex-1 flex-col gap-2 items-start justify-start w-[49%] md:w-full">
                                <h1
                                  style={{ color: "#6f7074" }}
                                  className="text-base tsm:text-[12px] font-semibold mqlap:text-[0.91rem] mqtab1:text-[0.91vw]"
                                >
                                  Phone Number *
                                </h1>
                                <Field
                                  className={`cursor-default placeholder-gray-300 leading-[normal] p-3 mqtab1:p-[0.7rem] sm:h-[30px] sm:w-[75vw] sm:text-[10px] text-[15px] text-left w-full border border-gray-300 border-solid rounded-[12px]  mqtab1:text-[0.82vw] mqlap:text-[0.8vw] ${
                                    touched.phone_number && errors.phone_number
                                      ? "border-indigo-600"
                                      : "border-gray-300"
                                  } mqlap:text-[0.85rem]`}
                                  name="phone_number"
                                  value={values.phone_number}
                                  onChange={handleChange}
                                   placeholder="Enter phone number"
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage
                                  className="text-indigo-600 text-xs"
                                  name="phone_number"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="w-full flex justify-between items-center gap-[10px] mqtab1:gap-[15px]">
                              <div className="flex md:flex-1 flex-col gap-2 items-start justify-start w-[49%] md:w-full">
                                <h1
                                  style={{ color: "#6f7074" }}
                                  className="text-base tsm:text-[12px] font-semibold mqlap:text-[0.91rem] mqtab1:text-[0.91vw]"
                                >
                                  Current Role *
                                </h1>
                                <Field
                                  className={`cursor-default placeholder-gray-300 leading-[normal] p-3 mqtab1:p-[0.7rem] sm:h-[30px] sm:w-[75vw] sm:text-[10px] text-[15px] text-left w-full border border-gray-300 border-solid rounded-[12px]  mqtab1:text-[0.82vw] mqlap:text-[0.8vw] ${
                                    touched.current_role && errors.current_role
                                      ? "border-indigo-600"
                                      : "border-gray-300"
                                  } mqlap:text-[0.85rem]`}
                                  name="current_role"
                                  value={values.current_role}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter Current role"
                                />
                                <ErrorMessage
                                  className="text-indigo-600 text-xs"
                                  name="current_role"
                                  component="div"
                                />
                              </div>

                              <div className="flex md:flex-1 flex-col gap-2 items-start justify-start w-[49%] md:w-full">
                                <h1
                                  style={{ color: "#6f7074" }}
                                  className="text-base tsm:text-[12px] font-semibold mqlap:text-[0.91rem] mqtab1:text-[0.91vw]"
                                >
                                  Country *
                                </h1>
                                <Field
                                  as="select"
                                  className={`cursor-default placeholder-gray-300 leading-[normal] p-3 mqtab1:p-[0.7rem] sm:h-[30px] sm:w-[75vw] sm:text-[10px] text-[15px] text-left w-full border border-gray-300 border-solid rounded-[12px]  mqtab1:text-[0.82vw] mqlap:text-[0.8vw] ${
                                    touched.countries && errors.countries
                                      ? "border-indigo-600"
                                      : "border-gray-300"
                                  } mqlap:text-[0.85rem]`}
                                  name="countries"
                                  value={values.countries}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <option className="text-gray-300" value="">Select Country</option>
                                  {countryStates.map((country, index) => (
                                    <option key={index} value={country.country}>
                                      {country.country}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  className="text-indigo-600 text-xs"
                                  name="countries"
                                  component="div"
                                />
                              </div>
                            </div>
                            <div className="w-full flex justify-between items-center gap-[10px] mqtab1:gap-[15px]">
                              <div className="flex md:flex-1 flex-col gap-2 items-start justify-start w-[49%] md:w-full">
                                <h1
                                  style={{ color: "#6f7074" }}
                                  className="text-base tsm:text-[12px] font-semibold mqlap:text-[0.91rem] mqtab1:text-[0.91vw]"
                                >
                                  State *
                                </h1>
                                <Field
                                  as="select"
                                  className={`cursor-default placeholder-gray-300 leading-[normal] p-3 mqtab1:p-[0.7rem] sm:h-[30px] sm:w-[75vw] sm:text-[10px] text-[15px] text-left w-full border border-gray-300 border-solid rounded-[12px]  mqtab1:text-[0.82vw] mqlap:text-[0.8vw] ${
                                    touched.state && errors.state
                                      ? "border-indigo-600"
                                      : "border-gray-300"
                                  } mqlap:text-[0.85rem]`}
                                  name="state"
                                  value={values.state}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled={!values.countries}
                                >
                                  <option  className="text-gray-300" value="">Select State</option>
                                  {countryStates
                                    .find(
                                      (country) =>
                                        country.country === values.countries
                                    )
                                    ?.states.map((state, index) => (
                                      <option key={index} value={state}>
                                        {state}
                                      </option>
                                    ))}
                                </Field>
                                <ErrorMessage
                                  className="text-indigo-600 text-xs"
                                  name="state"
                                  component="div"
                                />
                              </div>

                              <div className="flex md:flex-1 flex-col gap-2 items-start justify-start w-[49%] md:w-full">
                                <h1
                                  style={{ color: "#6f7074" }}
                                  className="text-base tsm:text-[12px] font-semibold mqlap:text-[0.91rem] mqtab1:text-[0.91vw]"
                                >
                                  City *
                                </h1>
                                <Field
                                  className={`cursor-default placeholder-gray-300 leading-[normal] p-3 mqtab1:p-[0.7rem] sm:h-[30px] sm:w-[75vw] sm:text-[10px] text-[15px] text-left w-full border border-gray-300 border-solid rounded-[12px]  mqtab1:text-[0.82vw] mqlap:text-[0.8vw] ${
                                    touched.city && errors.city
                                      ? "border-indigo-600"
                                      : "border-gray-300"
                                  } mqlap:text-[0.85rem]`}
                                  name="city"
                                   placeholder="Enter City"
                                  value={values.city}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                 
                                />
                                <ErrorMessage
                                  name="city"
                                  component="div"
                                  className="text-indigo-600 text-xs"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row justify-end">
                        <Button
                          onClick={savePesonalData}
                          type="submit"
                          className=" sm:text-[12px]  px-[3.4rem] py-[0.9rem] text-[1.2rem]  mqlap:px-[2.85vw] mqlap:rounded-[12px] mqtab1:rounded-[12px]   mqlap:py-[0.7vw] mqlap:text-[1vw] mqtab1:px-[2.9vw]  mqtab1:py-[0.7vw]  mqtab1:text-[1vw] bg-gray-100 text-indigo-600  border-indigo-600 cursor-pointer font-medium  leading-[normal] md:ml-0 text-center text-lg  flex flex-row justify-center items-center "
                          size="md"
                        >
                          Save
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilepersonalShrashtiPage;