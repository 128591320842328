const initialState={
    paymentIntent:[],
    loading:true,
    error:null
};

const paymentIntentReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_PAYMENT_INTENTS_REQUEST":
            return {
                ...state,
                loading: true,
                error: null
            };
        case "FETCH_PAYMENT_INTENTS_SUCCESS":
            return {
                ...state,
                loading: false,
                paymentIntent: action.payload
            };
        case "FETCH_PAYMENT_INTENTS_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
export default paymentIntentReducer;