import React from 'react';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

const BlurredBackground = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.1)', // Adjust the alpha value for the desired blur effect
  zIndex: theme.zIndex.modal + 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const MainLoader = () => {
  return (
    <BlurredBackground>
      <CircularProgress />
    </BlurredBackground>
  );
};

export default MainLoader;
