import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { Img, Text } from "components";
import PopupModal from "components/Filterpopup";
import MainLoader from "components/Loader";
import { useEffect, useState } from "react";

import { Button } from "components";
import Sidebar1 from "pages/RecruiterDashBoardPages/RecruiterSidebar";
import TopHeader from "pages/RecruiterDashBoardPages/TopHeader";
import { Link } from "react-router-dom";

const CandidatesPage = () => {
  const [dataFetching, setFetchstatus] = useState(true);
  const [candidateData, setCandidateData] = useState([]);
  const userId1 = JSON.parse(
    localStorage.getItem(
      `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`,
    ),
  );
  const user_id = userId1.user.id;

  useEffect(() => {
    setFetchstatus(true);
    const apiUrl = `${process.env.REACT_APP_API_URL}/get_candidate_data`;
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user_id }),
    };

    fetch(apiUrl, requestOptions)
      .then((data) => data.json())
      .then((res) => {
        //console.log("candidate data", res.data[1]);

        let myData = res.data[1];
        setCandidateData(myData);
        setFetchstatus(false);
      });
  }, [user_id]);
  const [isModalOpen, setModalOpen] = useState(false);

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedRows = candidateData.slice(startIndex, endIndex);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  return (
    <>
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <Sidebar1 clickedTab={"Candidates"} />
          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
            <TopHeader header={"Candidates"} />
            <div
              style={{ overflow: "scroll" }}
              className="bg-white-A700 flex flex-col font-poppins items-center justify-start rounded-[15px] w-[94%] md:w-full h-[93vh]"
            >
              <div className="flex flex-col items-end justify-start mt-[21px] md:pl-10 pl-14 pr-14 sm:pl-5 w-full">
                <div className="flex sm:flex-col flex-row sm:gap-5 items-start justify-end w-full md:w-full gap-20">
                  <Link to="/candidates-addcandidate">
                    <Button
                      className="border border-gray-700 border-solid cursor-pointer flex items-center justify-center p-2 w-[220px] h-[50px] sm:ml-[0] ml-[29px]" // Adjusted width and height
                      leftIcon={
                        <Img
                          className="h-6 mr-4 my-px"
                          src="images/img_materialsymbolspersonaddoutlinerounded.svg"
                          alt="material-symbols:person-add-outline-rounded"
                        />
                      }
                      shape="round"
                    >
                      <div className="font-medium leading-[normal] text-left text-xl">
                        Add candidate
                      </div>
                    </Button>
                  </Link>
                  <Button
                    onClick={openModal}
                    className="border border-gray-700 border-solid cursor-pointer flex items-center justify-center p-2 w-[220px] h-[50px] sm:ml-[0] ml-[29px]" // Adjusted width and height
                    leftIcon={
                      <Img
                        className="h-6 mr-4 my-px"
                        src="images/img_materialsymbol_white_700.svg"
                        alt="material-symbols:person-add-outline-rounded"
                      />
                    }
                    shape="round"
                  >
                    <div className="font-medium leading-[normal] text-left text-xl">
                      Filter
                    </div>
                  </Button>
                  <PopupModal isOpen={isModalOpen} onClose={closeModal} />
                  <Button
                    className="border border-gray-700 border-solid cursor-pointer flex items-center justify-center w-[220px] h-[50px] p-2 sm:ml-[0] ml-[29px]" // Adjusted width and height
                    leftIcon={
                      <Img
                        className="h-6 mr-4 my-px"
                        src="images/img_materialsymbolssimcarddownloadoutlinesharp.svg"
                        alt="material-symbols:sim-card-download-outline-sharp"
                      />
                    }
                    shape="round"
                  >
                    <div className="font-medium leading-[normal] text-left text-xl">
                      Download CSV
                    </div>
                  </Button>
                </div>
                <div className="flex flex-col items-center justify-start mt-[30px] w-full ">
                  <div className="flex flex-col gap-3.5 items-center justify-start w-full">
                    {dataFetching ? (
                      <MainLoader />
                    ) : (
                      <div className="overflow-auto w-full">
                        <TableContainer
                          className="overflow-auto "
                          component={Paper}
                        >
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Text
                                    className="   sm:ml-[0] sm:mt-0 my-0.5 text-base text-blue_gray-400 "
                                    size="txtInterSemiBold16"
                                  >
                                    S. No.
                                  </Text>
                                </TableCell>
                                <TableCell className="p-3">
                                  <Text
                                    className="  sm:ml-[0] sm:mt-0 my-0.5 text-base text-blue_gray-400"
                                    size="txtInterSemiBold16"
                                  >
                                    Name
                                  </Text>
                                </TableCell>
                                <TableCell className="p-3  ">
                                  <Text
                                    className="sm:ml-[0] sm:mt-0 my-0.5 text-base text-blue_gray-400"
                                    size="txtInterSemiBold16"
                                  >
                                    Designation
                                  </Text>
                                </TableCell>
                                <TableCell>
                                  <Text
                                    className="sm:ml-[0]  sm:mt-0 my-0.5 text-base text-blue_gray-400"
                                    size="txtInterSemiBold16"
                                  >
                                    Technical Skills
                                  </Text>
                                </TableCell>
                                <TableCell>
                                  <Text
                                    className="sm:ml-[0]  sm:mt-0 my-0.5 text-base text-blue_gray-400"
                                    size="txtInterSemiBold16"
                                  >
                                    Interpersonal Skills
                                  </Text>
                                </TableCell>
                                <TableCell>
                                  <Text
                                    className="sm:ml-[0] sm:mt-0 my-0.5 text-base text-blue_gray-400"
                                    size="txtInterSemiBold16"
                                  >
                                    School Name
                                  </Text>
                                </TableCell>
                                <TableCell>
                                  <Text
                                    className="sm:ml-[0]  sm:mt-0 my-0.5 text-base text-blue_gray-400"
                                    size="txtInterSemiBold16"
                                  >
                                    College/University
                                  </Text>
                                </TableCell>
                                <TableCell>
                                  <Text
                                    className="sm:ml-[0]   sm:mt-0 my-0.5 text-base text-blue_gray-400"
                                    size="txtInterSemiBold16"
                                  >
                                    Degree
                                  </Text>
                                </TableCell>
                                <TableCell>
                                  <Text
                                    className="sm:ml-[0] sm:mt-0 my-0.5 text-base text-blue_gray-400"
                                    size="txtInterSemiBold16"
                                  >
                                    City
                                  </Text>
                                </TableCell>
                                <TableCell>
                                  <Text
                                    className="sm:ml-[0]   sm:mt-0 my-0.5 text-base text-blue_gray-400"
                                    size="txtInterSemiBold16"
                                  >
                                    State
                                  </Text>
                                </TableCell>
                                <TableCell>
                                  <Text
                                    className="sm:ml-[0] sm:mt-0 my-0.5 text-base text-blue_gray-400"
                                    size="txtInterSemiBold16"
                                  >
                                    Country
                                  </Text>
                                </TableCell>
                                <TableCell>
                                  <Text
                                    className="sm:ml-[0]   sm:mt-0 my-0.5 text-base text-blue_gray-400"
                                    size="txtInterSemiBold16"
                                  >
                                    Phone Number
                                  </Text>
                                </TableCell>
                                <TableCell>
                                  <Text
                                    className="sm:ml-[0]   sm:mt-0 my-0.5 text-base text-blue_gray-400"
                                    size="txtInterSemiBold16"
                                  >
                                    Email
                                  </Text>
                                </TableCell>
                                <TableCell>
                                  <Text
                                    className="sm:ml-[0]   sm:mt-0 my-0.5 text-base text-blue_gray-400"
                                    size="txtInterSemiBold16"
                                  >
                                    Social Links
                                  </Text>
                                </TableCell>
                                <TableCell>
                                  <Text
                                    className="sm:ml-[0]  sm:mt-0 my-0.5 text-base text-blue_gray-400"
                                    size="txtInterSemiBold16"
                                  >
                                    Years of Experience
                                  </Text>
                                </TableCell>
                                <TableCell>
                                  <Text
                                    className="sm:ml-[0]   sm:mt-0 my-0.5 text-base text-blue_gray-400"
                                    size="txtInterSemiBold16"
                                  >
                                    Projects
                                  </Text>
                                </TableCell>
                                <TableCell>
                                  <Text
                                    className="sm:ml-[0]   sm:mt-0 my-0.5 text-base text-blue_gray-400"
                                    size="txtInterSemiBold16"
                                  >
                                    Project Tech
                                  </Text>
                                </TableCell>
                                <TableCell>
                                  <Text
                                    className="sm:ml-[0]   sm:mt-0 my-0.5 text-base text-blue_gray-400"
                                    size="txtInterSemiBold16"
                                  >
                                    Project Description
                                  </Text>
                                </TableCell>
                                <TableCell>
                                  <Text
                                    className="sm:ml-[0]   sm:mt-0 my-0.5 text-base text-blue_gray-400"
                                    size="txtInterSemiBold16"
                                  >
                                    Project Link
                                  </Text>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {displayedRows.map((row, index) => (
                                <TableRow key={index}>
                                  <TableCell className=" w-auto ">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell className=" w-auto ">
                                    {row.name}
                                  </TableCell>
                                  <TableCell className=" w-auto ">
                                    {row.designation}
                                  </TableCell>
                                  <TableCell className="w-auto">
                                    {row.tech_skills}
                                  </TableCell>
                                  <TableCell className=" w-auto ">
                                    {row.interpersonal_skills}
                                  </TableCell>
                                  <TableCell className=" w-auto ">
                                    {row.school_name}
                                  </TableCell>
                                  <TableCell className="  w-auto ">
                                    {row.coll_name}
                                  </TableCell>
                                  <TableCell className="w-auto">
                                    {row.degree}
                                  </TableCell>
                                  <TableCell className="w-auto">
                                    {row.city}
                                  </TableCell>
                                  <TableCell className="w-auto">
                                    {row.state}
                                  </TableCell>
                                  <TableCell className="w-auto">
                                    {row.country}
                                  </TableCell>
                                  <TableCell className="w-auto">
                                    {row.phone_no}
                                  </TableCell>
                                  <TableCell className="w-auto">
                                    {row.email_id}
                                  </TableCell>

                                  <TableCell className="w-auto">
                                    <div className=" social_links flex flex-col gap-[2px] ">
                                      <a
                                        href={row.linkedin_link}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <Img
                                          className="mt-px ml-4 cursor-pointer w-[20px]"
                                          src="images/linkedin.svg"
                                          alt="img1"
                                          style={{ width: 19, height: 19 }}
                                        />
                                      </a>

                                      <a
                                        href={row.github_link}
                                        rel="noreferrer"
                                        target="_blank"
                                      >
                                        <Img
                                          className="mt-px ml-4 cursor-pointer img w-[20px]"
                                          src="/images/github.svg"
                                          alt="img1"
                                        />
                                      </a>

                                      <a
                                        href={row.website_link}
                                        rel="noreferrer"
                                        target="_blank"
                                      >
                                        <Img
                                          className="mt-px ml-4 cursor-pointer w-[20px]"
                                          src="/images/website.svg"
                                          alt="img1"
                                        />
                                      </a>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    className="w-auto"
                                    style={{ textAlign: "center" }}
                                  >
                                    {row.YOE}{" "}
                                  </TableCell>
                                  <TableCell className="w-auto">
                                    {row.project_name.map((element) => {
                                      return (
                                        <div className="project">{element}</div>
                                      );
                                    })}
                                  </TableCell>
                                  <TableCell className="w-auto">
                                    {row.project_tech_used.map((element) => {
                                      return (
                                        <div className="project">{element}</div>
                                      );
                                    })}
                                  </TableCell>
                                  <TableCell className="w-auto">
                                    {row.project_description.map((element) => {
                                      return (
                                        <div className="project">{element}</div>
                                      );
                                    })}
                                  </TableCell>
                                  <TableCell className="w-auto">
                                    {" "}
                                    {row.project_link.map((element, index) => {
                                      return (
                                        <div className="project">
                                          <a
                                            href={element}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {" "}
                                            project Link{index + 1}
                                          </a>
                                        </div>
                                      );
                                    })}{" "}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-full flex flex-row justify-center mt-[50px]">
                  <Pagination
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    page={currentPage}
                    count={Math.ceil(candidateData.length / itemsPerPage)}
                    onChange={(event, pageNumber) =>
                      handlePageChange(event, pageNumber)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidatesPage;
