import React, { useEffect, useRef } from 'react';
import './MApp.css';
import comp1 from "./comp_1.svg";
import comp2 from './comp_2.svg';
import comp3 from './comp_3.svg';
import comp4 from './comp_4.svg';
import comp5 from './comp_5.svg';
import comp6 from './comp_6.svg';

const CarouselScroll = () => {
  const textArray = [comp1, comp2, comp3, comp4, comp5, comp6];
  const intervalRef = useRef(null);

  useEffect(() => {
    // Function to start carousel
    const startCarousel = () => {
      intervalRef.current = setInterval(() => {
        // No action needed here since we are not using currentIndex
      }, 2000);
    };

    startCarousel();

    // Clear interval on component unmount
    return () => clearInterval(intervalRef.current);
  }, [textArray.length]);

  useEffect(() => {
    const scrollers = document.querySelectorAll(".scroller");

    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        scroller.setAttribute("data-animated", true);
        const scrollerInner = scroller.querySelector(".scroller__inner");
        const scrollerContent = Array.from(scrollerInner.children);
        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true);
          duplicatedItem.setAttribute("aria-hidden", true);
          scrollerInner.appendChild(duplicatedItem);
        });
      });
    }
  }, []);

  return (
    <div className="scroller" data-speed="fast" data-animated="true">
      <ul className="tag-list scroller__inner">
      {textArray.map((each, index) => (
          <li key={index}>{<img src={each} alt={`carousel-item-${index}`} />}</li>
        ))}
      </ul>
    </div>
  );
};

export default CarouselScroll;
