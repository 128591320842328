export const fetchSubscriptionData = (user_id) => {
    return async (dispatch) => {
        dispatch({ type: "FETCH_SUBSCRIPTION_REQUEST" });
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/get_subscription_data`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify({ user_id }),
                }
            );
            if (!response.ok) {
                throw new Error("Failed to update subscription");
            }
            const data = await response.json();
            dispatch({ type: 'FETCH_SUBSCRIPTION_DATA', payload: data });
        } catch (error) {
            dispatch({ type: 'FETCH_SUBSCRIPTION_ERROR', error: error.message });
            console.error(error);
        }
    };
};
