const initialState = {
    links: {},
    isLoading: false,
    error: null
};

const profileUploadLinks = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PROFILE_UPLOAD_LINKS':
            return {
                ...state,
                links: action.payload,
                isLoading: false,
                error: null
            };
        case 'PROFILE_UPLOAD_LINKS_LOADING':
            return {
                ...state,
                isLoading: true,
                error: null
            };
        case 'PROFILE_UPLOAD_LINKS_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
export default profileUploadLinks;