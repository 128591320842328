import supabase from "components/superBaseConfig";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";


const RecruiterRoboLogo31Icon = styled.img`
  position: absolute;
  top: 11.5px;
  left: 0px;
  width: 97.2px;
  height: 95.1px;
  object-fit: cover;
`;
const RecruiterRoboLogo31Wrapper = styled.div`
  position: relative;
  width: 97.2px;
  height: 117px;
  overflow: hidden;
  flex-shrink: 0;
`;
const WebsiteName = styled.div`
  position: relative;
  line-height: 135.5%;
  font-weight: 600;
  display: inline-block;
  width: 213.3px;
  height: 34.2px;
  flex-shrink: 0;
  color: #161616;
`;
const FrameParent = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const TopNav = styled.div`
  position: absolute;
  top: 54px;
  left: 71px;
  width: 310.4px;
  height: 117px;
`;
const SignInTo = styled.div`
  position: relative;
  font-size: 37.94px;
  line-height: 61.65px;
  font-weight: 600;
  text-align: center;
`;
const InputLabelmedium = styled.div`
  position: absolute;
  top: 0%;
  left: calc(50% - 313px);
  line-height: 34.38px;
`;
const GroupChild = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 313px);
  border-radius: 9.48px;
  background-color: var(--color-white);
  border: 1.2px solid var(--color-gainsboro);
  box-sizing: border-box;
  width: 626px;
  height: 61.7px;
`;

const RectangleParent = styled.div`
  position: absolute;
  top: 35.6px;
  left: calc(50% - 313px);
  width: 626px;
  height: 61.7px;
  font-size: 18.97px;
  color: var(--color-darkgray-100);
`;
const Email = styled.div`
  position: relative;
  width: 626px;
  height: 97.2px;
`;
const RectangleGroup = styled.div`
  position: absolute;
  top: 35.6px;
  left: calc(50% - 313px);
  width: 626px;
  height: 61.7px;
  font-size: 18.97px;
  color: var(--color-silver-100);
`;
const SubmitChild = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 313px);
  border-radius: 7.11px;
  background-color: var(--color-darkslateblue);
  width: 626px;
  height: 59.3px;
`;
const LogIn = styled.div`
  position: absolute;
  top: calc(50% - 15.45px);
  left: calc(50% - 33.2px);
  line-height: 30.83px;
`;
const Submit = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 313px);
  width: 626px;
  height: 59.3px;
`;
const SignIn = styled.span`
  text-decoration: underline;
`;
const AlreadyHaveAnContainer = styled.div`
  position: absolute;
  font-family: var(--font-poppins);
  color: var(--color-gray-400);
  top: 86.5px;
  left: 164.8px;
  line-height: 30.83px;
  text-align: left;
`;
const SubmitParent = styled.div`
  position: relative;
  width: 626px;
  height: 117.4px;
  text-align: center;
  font-size: 18.97px;
  color: var(--color-white);
`;
const SignInToDhiwiseParent = styled.div`
  position: absolute;
  top: 215px;
  left: calc(50% - 313px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 28.45px;
  font-size: 21.34px;
  color: var(--color-gray-200);
  font-family: var(--font-inter);
`;
const SignupTabletRoot = styled.div`
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  height: 1080px;
  overflow: hidden;
  text-align: left;
  font-size: 26.06px;
  color: var(--color-gray-300);
  font-family: var(--font-poppins);
`;

const UniversitySignupTablet = () => {
  const navigate = useNavigate();

  const OnSignInContainerClick = useCallback(() => {
    navigate("/university-login");
  }, [navigate]);

  const [fullName, setfullName] = useState("");
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async (e) => {
    //alert("Entered");
    e.preventDefault();

    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    try {
      // const { error } = await supabase.auth.signUp({
        const {  error } = await supabase.auth.signUp({
        email: email,
        password: password,
        options: {
          data: { full_name: fullName },
        },
      });
      if (error) {
        throw error;
      }
      alert("Check your email for confirmation!");
    } catch (error) {
      alert("Please try again");
    }

    await insertIntoDB();
    //alert("Inserted into DB");
  };

  const insertIntoDB = async () => {
    try {
      await supabase.from("UserProfile").upsert(
        {
          username: fullName,
          password: password,
          email: email,
          linkedin: null,
        },
        { returning: "minimal" },
      );
    } catch (error) {
      alert("Please try again later");
    }
  };

  const onLogo = useCallback(() => {
    navigate("/");
  }, [navigate]);
  return (
    <SignupTabletRoot>
      <TopNav>
        <FrameParent>
          <RecruiterRoboLogo31Wrapper>
            <RecruiterRoboLogo31Icon
              alt=""
              src="/recruiter-robo-logo-3-1@2x.png"
              onClick={onLogo}
              style={{ cursor: "pointer" }}
            />
          </RecruiterRoboLogo31Wrapper>
          <WebsiteName onClick={onLogo} style={{ cursor: "pointer" }}>
            Recruiter Robo
          </WebsiteName>
        </FrameParent>
      </TopNav>
      <SignInToDhiwiseParent>
        <SignInTo>Sign up to Robo Recruiter</SignInTo>
        <Email>
          <InputLabelmedium>Full name</InputLabelmedium>
          <RectangleParent>
            <GroupChild />
            <input
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                borderRadius: "9.61px",
                backgroundColor: "var(--color-white)",
                border: "1.2px solid var(--color-gainsboro)",
                boxSizing: "border-box",
                width: "100%",
                height: "62.5px",
              }}
              onChange={(e) => setfullName(e.target.value)}
              type="text"
              value={fullName}
              placeholder="Enter Full name"
            />
          </RectangleParent>
        </Email>
        <Email>
          <InputLabelmedium>Email address</InputLabelmedium>
          <RectangleGroup>
            <GroupChild />
            <input
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                borderRadius: "9.61px",
                backgroundColor: "var(--color-white)",
                border: "1.2px solid var(--color-gainsboro)",
                boxSizing: "border-box",
                width: "100%",
                height: "62.5px",
              }}
              type="email"
              placeholder="Enter Email address"
              value={email}
              onChange={(e) => setemail(e.target.value)}
            />
          </RectangleGroup>
        </Email>
        <Email>
          <InputLabelmedium>Enter password</InputLabelmedium>
          <RectangleGroup>
            <GroupChild />
            <input
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                borderRadius: "9.61px",
                backgroundColor: "var(--color-white)",
                border: "1.2px solid var(--color-gainsboro)",
                boxSizing: "border-box",
                width: "100%",
                height: "62.5px",
              }}
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </RectangleGroup>
        </Email>
        <Email>
          <InputLabelmedium>Confirm password</InputLabelmedium>
          <RectangleGroup>
            <GroupChild />
            <input
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                borderRadius: "9.61px",
                backgroundColor: "var(--color-white)",
                border: "1.2px solid var(--color-gainsboro)",
                boxSizing: "border-box",
                width: "100%",
                height: "62.5px",
              }}
              type="password"
              placeholder="Enter Confirm password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </RectangleGroup>
        </Email>
        <SubmitParent>
          <Submit style={{ cursor: "pointer" }} onClick={handleSubmit}>
            <SubmitChild />
            <LogIn>Sign up</LogIn>
          </Submit>
          <AlreadyHaveAnContainer>
            {`Already have an account? `}
            <SignIn
              onClick={OnSignInContainerClick}
              style={{ cursor: "pointer" }}
            >
              Sign in
            </SignIn>
          </AlreadyHaveAnContainer>
        </SubmitParent>
      </SignInToDhiwiseParent>
    </SignupTabletRoot>
  );
};

export default UniversitySignupTablet;
