import { Link } from "react-router-dom";


import { Button, Img, Text } from "components";
import Sidebar1 from "pages/RecruiterDashBoardPages/RecruiterSidebar";
import TopHeader from "pages/RecruiterDashBoardPages/TopHeader";

const CandidatesAddCandidate = () => {
  // const handleFileUpload = (files) => {
  //   // Handle the uploaded files here (e.g., send them to a server)
  //   //console.log('Uploaded files:', files);
  // };
  return (
    <>
      <div className="bg-gray-100 flex flex-col font-poppins items-center justify-start mx-auto w-full">
        <div className="flex md:flex-col flex-row gap-1.5 items-start justify-between w-full">
          <Sidebar1 clickedTab={"Candidates"} />
          <div className="flex flex-1 flex-col font-inter gap-[37px] items-center justify-start md:px-5 w-full">
            <TopHeader header={"Candidates"} />

            <div
              style={{ overflow: "scroll" }}
              className="bg-white-A700 flex flex-col font-poppins items-center justify-start p-7 sm:px-5 rounded-[15px] w-[94%] md:w-full h-[93vh]"
            >

              {/* <div className="bg-white-A700 flex flex-col items-center justify-start p-[35px] sm:px-5 rounded-[15px] w-[95%] md:w-full"> */}
              <div className="flex md:flex-col flex-row md:gap-10 items-start justify-between mb-[194px] w-[94%] md:w-full">
                <div className="flex flex-col items-start justify-start w-[33%] md:w-full">
                  <div className="flex flex-row font-inter gap-4 items-start justify-start w-[39%] md:w-full">
                    <Link to="/candidates">
                      <Img
                        className="h-6 w-6"
                        src="images/img_arrowleft.svg"
                        alt="arrowleft"
                      />
                    </Link>
                    <Text
                      className="text-2xl md:text-[22px] text-gray-700 sm:text-xl"
                      size="txtInterMedium24"
                    >
                      File Upload
                    </Text>
                  </div>
                  <div className="bg-gray-400_14 flex flex-col font-inter gap-[31px] items-center justify-start mt-[109px] outline-[0.5px] outline-dashed outline-gray-500 md:px-10 sm:px-5 px-[111px] py-40 w-auto sm:w-full">
                    <Img
                      className="h-12 w-12"
                      src="images/uploadicon.svg"
                      alt="twitter"
                    />
                    <Text
                      className="text-2xl md:text-[22px] text-gray-700 sm:text-xl w-auto"
                      size="txtInterRegular24"
                    >
                      Drag files to upload
                    </Text>
                    {/* <FileUpload onFileUpload={handleFileUpload} /> */}
                  </div>

                  <Button className="cursor-pointer font-medium font-poppins h-[50px] leading-[normal] ml-32 md:ml-[0] mt-[49px] text-center text-xl w-[220px]">
                    <label htmlFor="addCandidate">  Choose folder</label>
                  </Button>


                  <input
                    id="addCandidate"
                    // onChange={handleFile1}
                    type="file"
                    style={{ display: "none" }}
                    accept="image/jpeg, image/png, image/gif"
                    required
                  />

                </div>
                <div className="flex flex-col gap-[46px] items-start justify-start md:mt-0 mt-[140px] w-[61%] md:w-full">
                  <Text
                    className="ml-1 md:ml-[0] text-2xl md:text-[22px] text-gray-700 sm:text-xl"
                    size="txtInterMedium24"
                  >
                    Uploading
                  </Text>
                  <div className="flex md:flex-col flex-row gap-[30px] items-start justify-between w-full">
                    <Img
                      className="h-12 md:h-auto object-cover w-12"
                      src="images/img_happyfile.png"
                      alt="happyfile"
                    />
                    <div className="flex flex-col items-start justify-start w-full">
                      <Text
                        className="text-gray-700 text-[20px] mb-4"
                        size="txtInterRegular20"
                      >
                        {/* {files?.length>0?files?.[0]?.name:"Project manager JD.doc"} */}
                        Candidate Resume 32MB
                      </Text>
                      <progress className="flex w-full" id="file" max="100" value="70">70%</progress>


                      <div className="h-2 mt-[13px] overflow-hidden relative w-full">

                        <div className="w-full h-full absolute bg-gray_400_30 rounded-[4px]"></div>
                        <div
                          className="h-full absolute bg-blue_A200  rounded-[4px]"
                          style={{ width: "43%" }}
                        ></div>
                      </div>
                      <div className="flex flex-row sm:gap-10 items-start justify-between mt-[15px] w-full">
                        <Text
                          className="text-gray-500 text-xl"
                          size="txtInterRegular20Gray500"
                        >
                          37% completed
                        </Text>
                        <Text
                          className="text-gray-500 text-xl"
                          size="txtInterRegular20Gray500"
                        >
                          90KB/sec
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

    </>
  );
};

export default CandidatesAddCandidate;
