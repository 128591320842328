const initialState = {
    subscriptionData: [],
    isLoading: false,
    error: null,
};

const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_SUBSCRIPTION_REQUEST':
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case 'FETCH_SUBSCRIPTION_DATA':
            return {
                ...state,
                isLoading: false,
                subscriptionData: action.payload,
            };
        case 'FETCH_SUBSCRIPTION_ERROR':
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default subscriptionReducer;
