import { Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Img, Text } from "components";
import LogoutPopup from "components/LogoutPopup";
import supabase from "components/superBaseConfig";
import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import { Menu, MenuItem, Sidebar, useProSidebar } from "react-pro-sidebar";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const SideBar1 = (props) => {
  const [isLogoutPopupOpen, setIsLogoutPopupOpen] = useState(false);
  const [showCountdown, setShowCountdown] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const accountType = useSelector(
    (state) => state.profileAccountType.accountType,
  );
  const inactivityTimerRef = useRef(null);
  const countdownTimerRef = useRef(null);

  const openLogoutPopup = () => {
    setIsLogoutPopupOpen(true);
  };

  const closeLogoutPopup = () => {
    setIsLogoutPopupOpen(false);
  };

  useEffect(() => {
    const validateSession = async () => {
      const sessionId = Cookies.get("sessionId");
      if (!sessionId) return;

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/validate-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ sessionId }),
        },
      );

      const data = await response.json();
      if (!data.valid) {
        localStorage.removeItem("lastActivityTime");
        confirmInactivityLogout("loggedOutDueToSessionInvalidity=true");
      }
    };

    const interval = setInterval(validateSession, 2 * 60 * 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleSession = async () => {
      const sessionId = Cookies.get("sessionId");
      if (sessionId) return;
      const { data, error } = await supabase.auth.getSession();

      if (error || !data.session || !data.session.user) {
        window.location.href = "/login"; 
        return;
      }

      const User = data.session.user;

      if (User.app_metadata.provider === "google" || User.app_metadata.provider === "linkedin_oidc" || User.app_metadata.provider === "facebook") {
        const userId = User.id;
        console.log("User logged in:", userId);

        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/create-session`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ userId }),
            },
          );

          const sessionData = await response.json();
          const sessionId = sessionData.sessionId;
          Cookies.set("sessionId", sessionId, { expires: 7 });
        } catch (error) {
          console.error("Error creating session:", error);
        }
      } else {
        console.log("here");
      }
    };

    handleSession();
  }, []);

  const confirmLogout = () => {
    //console.log("logout successful");
    Cookies.remove("accessToken");
    Cookies.remove("sessionId");
    localStorage.removeItem("lastActivityTime");
    localStorage.removeItem(
      `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`,
    );
    closeLogoutPopup();
    supabase.auth.signOut();
    window.location.href = "/login";
  };

  const confirmInactivityLogout = (queryParam) => {
    Cookies.remove("accessToken");
    Cookies.remove("sessionId");
    localStorage.removeItem(
      `sb-${process.env.REACT_APP_SUPABASE_STRING}-auth-token`,
    );
    supabase.auth.signOut();
    window.location.href = `/login?${queryParam}`;
  };

  const activeSidebarText = {
    color: "#314ca3",
    gap: 1,
    backgroundColor: "#f4f6f9",
    transform: "scale(1.08)",
  };

  const inactiveSidebarText = {
    color: "#888EA2",
    gap: 1,
    backgroundColor: "white",
  };

  const disabledSidebarText = {
    color: "#cdcdcd",
    gap: 1,
    backgroundColor: "white",
  };

  const { clickedTab } = props;
  const { collapseSidebar, collapsed } = useProSidebar();

  const sideBarMenu = [
    {
      label: "Dashboard",
      to: "/jobseeker-dashboard",
      icon: (
        <Img
          className="h-[28px] w-[28px] "
          src={
            clickedTab === "Dashboard"
              ? "images/dashboard_blue.svg"
              : "images/dashboard.svg"
          }
          alt="home"
        />
      ),
    },
    {
      label: "Profile",
      to: "/profile-personal",
      icon: (
        <Img
          className={
            clickedTab === "Profile" ? "h-[45px} w-[32px]" : "h-[38px] w-[27px]"
          }
          src={
            clickedTab === "Profile"
              ? "images/profile_blue.svg"
              : "images/profile.svg"
          }
          alt="carbonuserprofi"
        />
      ),
    },
    {
      label: "Resume Builder",
      to: "/resumebuilder",
      isPaidReq: true,
      icon: (
        <Img
          className={
            clickedTab === "Resume Builder"
              ? "h-[50px} w-[35px]"
              : "h-[25px] w-[25px]"
          }
          src={
            clickedTab === "Resume Builder"
              ? "images/resume_builder_blue.svg"
              : "images/resume_builder.svg"
          }
          alt="userThirtyOne"
        />
      ),
    },
    {
      label: "Cover Letters",
      to: "/coverletter",
      isPaidReq: true,
      icon: (
        <Img
          className={
            clickedTab === "Cover Letters"
              ? "h-[30px} w-[30px]"
              : "h-[25px] w-[26px]"
          }
          src={
            clickedTab === "Cover Letters"
              ? "images/cv_blue.svg"
              : "images/cv.svg"
          }
          alt="group"
        />
      ),
    },
    {
      label: "Job Search",
      to: "/jobsearch",
      isPaidReq: true,
      icon: (
        <Img
          className="h-[33px] w-[33px]"
          src={
            clickedTab === "Job Search"
              ? "images/job_search_blue.svg"
              : "images/job_search.svg"
          }
          alt="frame1000004265"
        />
      ),
    },
    {
      label: "Auto Job",
      to: "/autojob",
      isPaidReq: true,
      icon: (
        <Img
          className={
            clickedTab === "Auto Job"
              ? "h-[45px] mb-1 w-[32px]"
              : "h-[41px] mb-1 w-[25px]"
          }
          src={
            clickedTab === "Auto Job"
              ? "images/auto_job_blue.svg"
              : "images/auto_job.svg"
          }
          alt="question"
        />
      ),
    },
    {
      label: "Interview Prep",
      to: "/interview-preparation",
      isPaidReq: true,
      icon: (
        <Img
          className="h-[32px] mb-1.5 w-[32px]"
          src={
            clickedTab === "Interview Prep"
              ? "images/int_prep_blue.svg"
              : "images/int_prep.svg"
          }
          alt="serviceOne"
        />
      ),
    },
    {
      label: "Career Advice",
      to: "/careeradvice",
      isPaidReq: true,
      icon: (
        <Img
          className="h-[40px] mb-1.5 w-[28px]"
          src={
            clickedTab === "Career Advice"
              ? "images/career_blue.svg"
              : "images/career.svg"
          }
          alt="econometricsOne"
        />
      ),
    },
    {
      label: "Billing",
      to: "/billing-overview",
      icon: (
        <Img
          className="h-[30px] mb-1.5 w-[30px]"
          src={
            clickedTab === "Billing"
              ? "images/bill_blue.svg"
              : "images/bill.svg"
          }
          alt="settings"
        />
      ),
    },
    {
      label: "Logout",
      to: "",
      icon: (
        <Img
          className="h-[30px] w-[30px]"
          src="/images/logout-box.svg"
          alt="logout"
        />
      ),
    },
  ];

  // if (localStorage.getItem("imgUrl") === null) {
  //   localStorage.setItem("imgUrl", "images/img_ellipse28.png");
  // }

  const resetTimer = () => {
    const currentTime = new Date().getTime();
    localStorage.setItem("lastActivityTime", currentTime);

    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
    }
    if (countdownTimerRef.current) {
      clearInterval(countdownTimerRef.current);
      setShowCountdown(false);
    }

    inactivityTimerRef.current = setTimeout(
      () => {
        setShowCountdown(true);
        startCountdown();
      },
      9 * 60 * 1000,
    );
  };

  const startCountdown = () => {
    setCountdown(60);
    countdownTimerRef.current = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(countdownTimerRef.current);
          localStorage.removeItem("lastActivityTime");
          confirmInactivityLogout("loggedOutDueToInactivity=true");
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleUserActivity = () => {
    resetTimer();
  };

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "lastActivityTime") {
        resetTimer();
      }
    };

    const checkLastActivity = () => {
      const lastActivityTime = localStorage.getItem("lastActivityTime");
      const currentTime = new Date().getTime();

      if (lastActivityTime && currentTime - lastActivityTime > 10 * 60 * 1000) {
        localStorage.removeItem("lastActivityTime");
        confirmInactivityLogout("loggedOutDueToInactivity=true");
      } else {
        resetTimer();
      }
    };

    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keypress", handleUserActivity);
    window.addEventListener("click", handleUserActivity);

    checkLastActivity();

    return () => {
      clearTimeout(inactivityTimerRef.current);
      clearInterval(countdownTimerRef.current);
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keypress", handleUserActivity);
      window.removeEventListener("click", handleUserActivity);
    };
  }, []);

  return (
    <>
      <Sidebar
       backgroundColor="#fff"
       width="none"
        className="!sticky  border border-gray-300 border-solid flex h-[100vh] mqtab2:hidden mqmob1:hidden justify-start md:px-5 top-[0] mqtab1:w-[235px] mqlap:w-[250px]"
      >
        <div
          onClick={() => collapseSidebar(!collapsed)}
          className="flex flex-col items-center justify-center py-[35px] w-full"
        >
          <div className="cursor-pointer flex flex-row gap-[10px] items-center justify-center mb-[19px] px-5 w-[278px] relative right-[2px] top-[10px] ">
            <div className="flex flex-col items-center justify-center px-1.5 py-[7px] w-auto">
              <Img
                className={`${
                  !collapsed
                    ? "h-[78px] w-[73px] md:h-auto sm:w-full relative mqtab1:w-[4.9vw] mqtab1:h-[5.2vw] "
                    : "h-[49px] w-[45px] relative left-[5px]"
                }`}
                src="/recroid.png"
                alt="recruiterrobolo"
              />
            </div>
            <div className="flex flex-col items-center justify-center w-auto">
              {!collapsed && (
                <Text
                  className="leading-[135.50%] max-w-[83px] md:max-w-full text-center text-[#314CA3] text-[19.5px] mqlap:text-[19px] mqtab1:text-[17.2px] relative right-[15.5px] top-[26.8px] mqtab1:top-[1.78vw]"
                  size="txtPoppinsSemiBold14"
                >
                  RecRoid
                </Text>
              )}
            </div>
          </div>
        </div>

        <Menu
          menuItemStyles={{
            button: {
              padding: "22px 44px 22px 22px",
              gap: "20px",
              fontWeight: 500,
              // fontSize: "16px",
            },
          }}
          className="flex flex-col font-inter items-center justify-center md:pr-10 sm:pr-5 pt-0.5 w-[100%] text-[18px] mqtab1:text-[16.5px] mqtab1:mb-[3vw]  "
        >
          {sideBarMenu.map((menu, i) => {
            const toolTip = (
              <Tooltip
                title={<Typography>{menu.label}</Typography>}
                placement="right-start"
              >
                <MenuItem
                  {...menu}
                  to={menu.to}
                  className={`text-decoration-none ${
                    accountType === 1
                      ? "text-black"
                      : "text-red-400 pointer-events-none"
                  }`}
                  style={
                    menu.isPaidReq && accountType === 0
                      ? disabledSidebarText // If both conditions are true
                      : clickedTab === menu.label
                      ? activeSidebarText // If the first condition is false and this condition is true
                      : inactiveSidebarText // If both conditions are false
                  }
                >
                  {!collapsed && menu.label}
                </MenuItem>
              </Tooltip>
            );

            return (
              <>
                {menu.isPaidReq ? (
                  <>
                    {accountType === 1 ? (
                      <>
                        <Link
                          onClick={() =>
                            menu.label === "Logout" && openLogoutPopup()
                          }
                          to={menu.to}
                          className="text-decoration-none text-[18px] mqtab1:text-[16.5px]"
                        >
                          {toolTip}
                        </Link>
                      </>
                    ) : (
                      <>{toolTip}</>
                    )}
                  </>
                ) : (
                  // Render a regular link for non-paid menu items
                  <Link
                    onClick={() => menu.label === "Logout" && openLogoutPopup()}
                    to={menu.to}
                    className="text-decoration-none text-[18px] text-[18px] mqtab1:text-[16.5px]"
                  >
                    <Tooltip
                      title={
                        <Typography fontSize={16}>{menu.label}</Typography>
                      }
                      placement="right-start"
                    >
                      <MenuItem
                        {...menu}
                        to={menu.to}
                        className="text-decoration-none text-[18px] mqtab1:text-[16.5px]"
                        style={
                          clickedTab === menu.label
                            ? activeSidebarText
                            : inactiveSidebarText
                        }
                      >
                        {!collapsed && menu.label}
                      </MenuItem>
                    </Tooltip>
                  </Link>
                )}
              </>
            );
          })}
        </Menu>

        <LogoutPopup
          goTo="/login"
          isOpen={isLogoutPopupOpen}
          onClose={closeLogoutPopup}
          onConfirmLogout={confirmLogout}
          mainHeadText="Logout"
          hoverText="Click the logout button to sign out of your account."
          subText="Would you like to sign off from your account?"
          cancelButton="Close"
          yesButton="Logout"
        />
      </Sidebar>

      <Modal
        open={showCountdown}
        onClose={() => setShowCountdown(false)}
        aria-labelledby="countdown-modal-title"
        aria-describedby="countdown-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Typography id="countdown-modal-title" variant="h6" component="h2">
            Inactivity Warning
          </Typography>
          <Typography id="countdown-modal-description" sx={{ mt: 2 }}>
            You will be logged out in {countdown} seconds due to inactivity.
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export { SideBar1 };