import { Line, Text } from "components";
import { Link } from "react-router-dom";

const SettingsSubHeader = (props) => {
  const { billingheader } = props;

  return (
    <div  className="flex sm:flex-col flex-row sm:gap-10 items-start justify-between pr-3 w-[20%] md:w-full">
      <div>
        <Text
          className={
            billingheader === 1
              ? "text-indigo-600 text-xl"
              : "text-blue_gray-400 text-xl w-auto"
          }
          size={
            billingheader === 1
              ? "txtInterMedium20"
              : "txtInterMedium20Indigo600"
          }
        >
          <Link to="/settings-team">Team</Link>
        </Text>
        {billingheader === 1 && (
          <Line className="bg-indigo-600 h-[3px] rounded-tl-[10px] rounded-tr-[10px] w-full" />
        )}
      </div>
      <div className="flex flex-col gap-2 items-center justify-start w-auto">
        <Text
          className={
            billingheader === 2
              ? "text-indigo-600 text-xl"
              : "text-blue_gray-400 text-xl w-auto"
          }
          size={
            billingheader === 2
              ? "txtInterMedium20"
              : "txtInterMedium20Indigo600"
          }
        >
          <Link to="/settings-workflow">Work Flow</Link>
        </Text>
        {billingheader === 2 && (
          <Line className="bg-indigo-600 h-[3px] rounded-tl-[10px] rounded-tr-[10px] w-full" />
        )}
      </div>
     
    </div>
  );
};

export default SettingsSubHeader;
