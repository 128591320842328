import { useCallback, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import supabase from "components/superBaseConfig";
import AlertModal from "../../../components/AlertModal/index.jsx";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { getEnvURL } from "utils/getEnvURL.js";

const frontEndURL = getEnvURL();
const WorkingDealOfRobotAndMan = styled.img`
  position: absolute;
  top: 360px;
  left: calc(50% - 664px);
  width: 608px;
  height: 456px;
  object-fit: cover;
`;
const RecruiterRoboLogo31Icon = styled.img`
  position: absolute;
  top: 11.5px;
  left: 0px;
  width: 97.2px;
  height: 95.1px;
  object-fit: cover;
`;
const RecruiterRoboLogo31Wrapper = styled.div`
  position: relative;
  width: 97.2px;
  height: 117px;
  overflow: hidden;
  flex-shrink: 0;
`;
const WebsiteName = styled.div`
  position: relative;
  line-height: 135.5%;
  font-weight: 600;
  display: inline-block;
  width: 213.3px;
  height: 34.2px;
  flex-shrink: 0;
  color: #161616;
`;
const FrameParent = styled.div`
  position: absolute;
  top: 50px;
  left: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const SignInTo = styled.div`
  position: relative;
  font-size: 40.99px;
  line-height: 66.61px;
  font-weight: 600;
  text-align: center;
  z-index: 0;
`;
const InputLabelmedium = styled.div`
  position: absolute;
  top: 0%;
  left: calc(50% - 338.15px);
  line-height: 37.15px;
`;
// const GroupChild = styled.div`
//   position: absolute;
//   top: 0px;
//   left: calc(50% - 338.15px);
//   border-radius: 10.25px;
//   background-color: var(--color-white);
//   border: 1.3px solid var(--color-gainsboro);
//   box-sizing: border-box;
//   width: 676.3px;
//   height: 66.6px;
// `;

const RectangleParent = styled.div`
  position: absolute;
  top: 38.4px;
  left: calc(50% - 338.15px);
  width: 676.3px;
  height: 66.6px;
  font-size: 20.49px;
  color: var(--color-darkgray-100);
`;
const Email = styled.div`
  position: relative;
  width: 676.3px;
  height: 105px;
  z-index: 1;
`;
const RectangleGroup = styled.div`
  position: absolute;
  top: 38.4px;
  left: calc(50% - 338.15px);
  width: 676.3px;
  height: 66.6px;
  font-size: 20.49px;
  //color: var(--color-silver-100);
`;
const Email1 = styled.div`
  position: absolute;
  margin: 0 !important;
  height: 51.98%;
  top: 110.35%;
  bottom: -62.33%;
  left: calc(50% - 338.15px);
  width: 676.3px;
  z-index: 2;
`;
const SignInToDhiwiseParent = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-11xl);
`;
const InputLabelsmall = styled.div`
  position: relative;
  font-size: 23.06px;
  line-height: 37.15px;
  text-align: right;
  cursor: pointer;
  color: #060606;
`;
const SubmitChild = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 338.15px);
  border-radius: 7.69px;
  background-color: var(--color-darkslateblue);
  width: 676.3px;
  height: 64px;
`;
const LogIn = styled.div`
  position: absolute;
  top: 15.4px;
  left: calc(50% - 29.45px);
  line-height: 33.3px;
`;
const Submit = styled.div`
  position: relative;
  width: 676.3px;
  height: 64px;
  color: var(--color-white);
`;
const OrContinueWith1 = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 76.8px);
  line-height: 33.3px;
`;
const OrContinueWithChild = styled.div`
  position: absolute;
  top: 16px;
  left: calc(50% + 98px);
  background-color: var(--color-silver-100);
  border-top: 1.3px solid var(--color-silver-100);
  box-sizing: border-box;
  width: 230.6px;
  height: 1.3px;
`;
const OrContinueWithItem = styled.div`
  position: absolute;
  top: 16px;
  left: calc(50% - 328.5px);
  background-color: var(--color-silver-100);
  border-top: 1.3px solid var(--color-silver-100);
  box-sizing: border-box;
  width: 231.8px;
  height: 1.3px;
`;
const OrContinueWith = styled.div`
  position: relative;
  width: 655.8px;
  height: 34px;
  text-align: left;
  color: var(--color-silver-100);
`;
const LoginChild = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 338.15px);
  border-radius: 7.69px;
  border: 1.3px solid var(--color-gray-400);
  box-sizing: border-box;
  width: 676.3px;
  height: 64px;
`;
const LoginParent = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 338.15px);
  width: 676.3px;
  height: 64px;
`;
const Google1Icon = styled.img`
  position: relative;
  width: 30.7px;
  height: 30.7px;
  overflow: hidden;
  flex-shrink: 0;
`;
const SignInWith = styled.div`
  position: relative;
  line-height: 33.3px;
`;
const Google1Parent = styled.div`
  position: absolute;
  top: 15.4px;
  left: calc(50% - 111.45px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7.69px;
`;
const Facebook1Parent = styled.div`
  position: absolute;
  top: 15.4px;
  left: calc(50% - 111.25px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7.69px;
`;
const LoginGroup = styled.div`
  position: absolute;
  top: 84.5px;
  left: calc(50% - 338.15px);
  width: 676.3px;
  height: 64px;
`;
const Linkedin11Parent = styled.div`
  position: absolute;
  top: 15.4px;
  left: calc(50% - 111.25px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7.69px;
`;
const LoginContainer = styled.div`
  position: absolute;
  top: 169.1px;
  left: calc(50% - 338.15px);
  width: 676.3px;
  height: 64px;
`;
const GroupParent = styled.div`
  position: relative;
  width: 676.3px;
  height: 233.1px;
`;
const InputLabelsmallParent = styled.div`
  position: absolute;
  top: 338.2px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: var(--gap-11xl);
  text-align: center;
  font-size: 20.49px;
  color: var(--color-gray-400);
`;
const SignUp = styled.span`
  text-decoration: underline;
`;
const DontHaveAnContainer = styled.div`
  position: absolute;
  top: 830px;
  left: 131px;
  font-size: 26.06px;
  line-height: 135.5%;
  font-family: var(--font-poppins);
  color: var(--color-black);
  cursor: pointer;
`;
const FrameGroup = styled.div`
  position: absolute;
  top: 156px;
  left: calc(50% - 0px);
  width: 676.3px;
  height: 865px;
  font-size: 23.06px;
  color: var(--color-darkslategray-200);
  font-family: var(--font-inter);
`;
const RecruiterRoboSignInlogInRoot = styled.div`
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  height: 100vh;
  overflow: hidden;
  text-align: left;
  font-size: 26.06px;
  color: var(--color-gray-300);
  font-family: var(--font-poppins);
`;

const DesktopRecruiterlogInPage = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState(false);
  const [loadingVal, setLoading] = useState(false);

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const changeVisibility = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const onInputLabelSmallTextClick = useCallback(() => {
    navigate("/recruiter-forgotpassword");
  }, [navigate]);

  const onDontHaveAnClick = useCallback(() => {
    navigate("/recruiter-signup");
  }, [navigate]);

  const onGmailLogin = useCallback(async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        queryParams: {
          access_type: "offline",
          prompt: "consent",
        },
        redirectTo:
          `${frontEndURL}/jobseeker-dashboard`,
      },
    });

    // const receivedData = await data;
    const receivedError = await error;

    if (receivedError) {
      // alert("Error is " + receivedError);
      throw receivedError;
    }

    ////console.log("Data is " + JSON.stringify(receivedData));
    // alert("Data is " + JSON.stringify(receivedData));
  },[]);

  const onLinkedinLogin = useCallback(() => {
    window.location.replace("https://www.linkedin.com/login/");
    // navigate("https://www.linkedin.com/login/");
  }, []);

  const onFacebookLogin = useCallback(() => {
    window.location.replace("https://www.facebook.com/login/");
    // navigate("https://www.linkedin.com/login/");
  }, []);
  const onLogo = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      // alert(email);
      // alert(password);

      try {
        const {  error } = await supabase.auth.signInWithPassword({
          email: email,
          password: password,
        });

        // alert("Data returned is" + JSON.stringify(data));
        // alert("User data is " + JSON.stringify(supabase.auth.getUser()));
        if (!error) {
          // alert("Login Successful");
          setLoading(false);
          ////console.log("loading is set false");
          navigate("/recruiter-dashboard");
        } else {
          ////console.log("loading is set false");
          setLoading(false);
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 3000);
          // alert("Please try again");
        }
      } catch (error) {
        setLoading(false);
        ////console.log("loading is set false");
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
        // alert(error);
      }
    },
    [email, navigate,password],
  );

  const onLogin = useCallback(
    (e) => {
      setLoading(true);
      ////console.log("loading is set true");
      handleSubmit(e);
    },
    [ handleSubmit],
  );

  return (
    <RecruiterRoboSignInlogInRoot>
      {/* <h1 style={{color:'black'}} >{"loading is : "+loading}</h1> */}
      {loadingVal && <AlertModal msg="loading" />}
      {error && <AlertModal msg="error" />}
      <WorkingDealOfRobotAndMan
        alt=""
        src="/working-deal-of-robot-and-man@2x.png"
      />
      <FrameParent>
        <RecruiterRoboLogo31Wrapper>
          <RecruiterRoboLogo31Icon
            alt=""
            src="/recruiter-robo-logo-3-1@2x.png"
            onClick={onLogo}
            style={{ cursor: "pointer" }}
          />
        </RecruiterRoboLogo31Wrapper>
        <WebsiteName onClick={onLogo} style={{ cursor: "pointer", color: "rgb(90, 198, 200)" }}>
          Recruiter Robo
        </WebsiteName>
      </FrameParent>
      <FrameGroup>
        <SignInToDhiwiseParent>
          <SignInTo>Sign in to Recruiter  Robo</SignInTo>
          <Email>
            <InputLabelmedium>Email</InputLabelmedium>
            <RectangleParent>
              <input
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "calc(50% - 317.15px)",
                  borderRadius: "9.61px",
                  backgroundColor: "var(--color-white)",
                  border: "1.2px solid var(--color-gainsboro)",
                  boxSizing: "border-box",
                  width: "634.3px",
                  height: "62.5px",
                }}
                type="email"
                placeholder="Enter Email"
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                value={email}
              />
            </RectangleParent>
          </Email>
          <Email1>
            <InputLabelmedium>Password</InputLabelmedium>
            <RectangleGroup>
              <div className="pass">
              <input
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "calc(50% - 317.15px)",
                  borderRadius: "9.61px",
                  backgroundColor: "var(--color-white)",
                  border: "1.2px solid var(--color-gainsboro)",
                  boxSizing: "border-box",
                  width: "634.3px",
                  height: "62.5px",
                  color:"grey"
                }}
                type={type}
                placeholder="Enter Password"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                value={password}
              />
            
            
               <div
               style={{position:"absolute",
                       left:599, 
                       top:16 
                      }}
                className="flex  justify-end items-center pl-3 !border-colors !rounded-[12px] "
                onClick={changeVisibility}
              >
               <Icon icon={icon} size={23} style={{color:"grey"}}/>
              </div>
              </div>
              
              
             
            </RectangleGroup>

           
          </Email1>
        </SignInToDhiwiseParent>
        <InputLabelsmallParent>
          <InputLabelsmall
            onClick={onInputLabelSmallTextClick}
            style={{ cursor: "pointer" }}
          >
            Forgot Password?
          </InputLabelsmall>
          <Submit onClick={onLogin} style={{ cursor: "pointer" }}>
            <SubmitChild />
            <LogIn>Log in</LogIn>
          </Submit>
          <OrContinueWith>
            <OrContinueWith1>Or continue with</OrContinueWith1>
            <OrContinueWithChild />
            <OrContinueWithItem />
          </OrContinueWith>
          <GroupParent>
            <LoginParent style={{ cursor: "pointer" }} onClick={onGmailLogin}>
              <LoginParent>
                <LoginChild />
              </LoginParent>
              <Google1Parent style={{ cursor: "pointer" }}>
                <Google1Icon alt="" src="/google-1.svg" />
                <SignInWith>Sign in with Google</SignInWith>
              </Google1Parent>
            </LoginParent>
            <LoginGroup style={{ cursor: "pointer" }} onClick={onFacebookLogin}>
              <LoginParent>
                <LoginChild />
              </LoginParent>
              <Facebook1Parent style={{ cursor: "pointer" }}>
                <Google1Icon alt="" src="/facebook-1.svg" />
                <SignInWith>Sign in with Facebook</SignInWith>
              </Facebook1Parent>
            </LoginGroup>
            <LoginContainer
              style={{ cursor: "pointer" }}
              onClick={onLinkedinLogin}
            >
              <LoginParent>
                <LoginChild />
              </LoginParent>
              <Linkedin11Parent style={{ cursor: "pointer" }}>
                <Google1Icon alt="" src="/linkedin-1-1.svg" />
                <SignInWith>Sign in with Linkedin</SignInWith>
              </Linkedin11Parent>
            </LoginContainer>
          </GroupParent>
        </InputLabelsmallParent>
        <DontHaveAnContainer
          onClick={onDontHaveAnClick}
          style={{ cursor: "pointer" }}
        >
          {`Don’t have an account? `}
          <SignUp>Sign up</SignUp>
        </DontHaveAnContainer>
      </FrameGroup>
    </RecruiterRoboSignInlogInRoot>
  );
};

export default DesktopRecruiterlogInPage;
